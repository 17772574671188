import { LockIcon } from 'components/icons'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Stack } from './layout'
import { Tooltip } from './Tooltip'

interface ContentLockedProps {
    isLocked?: boolean
    iconSize?: Size
    message?: React.ReactNode
    className?: string
    tooltip?: string
    dialog?: (props: { open: boolean; onClose: VoidFunction }) => React.ReactElement
}

export const ContentLocked: React.FC<ContentLockedProps> = ({
    isLocked,
    message,
    iconSize = 'small',
    className,
    children,
    tooltip,
    dialog,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false)

    const wrappedChildren = (
        <Wrapper
            className={className}
            onClick={() => {
                setIsDialogOpen(true)
            }}
        >
            <Overlay>
                <Stack spacing={3} textAlign="center">
                    <StyledLockedIcon $size={iconSize} />
                    {message}
                </Stack>
            </Overlay>
            {children}
        </Wrapper>
    )

    if (isLocked)
        return (
            <>
                {tooltip ? <Tooltip title={tooltip}>{wrappedChildren}</Tooltip> : wrappedChildren}
                {dialog &&
                    dialog({
                        open: isDialogOpen,
                        onClose: () => {
                            setIsDialogOpen(false)
                        },
                    })}
            </>
        )

    return <>{children}</>
}

const Overlay = styled.div`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(11, 18, 34, 0.03);
    color: #fff;
    padding: ${props => props.theme.spacing(4)};
`

const Wrapper = styled.button`
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0;
    background: none;
    border: none;
    > *:not(${Overlay}) {
        filter: grayscale(1) contrast(0.4);
    }
`

const StyledLockedIcon = styled(LockIcon)<{ $size: Size }>`
    width: ${props => sizeMap[props.$size]};
    height: auto;
`

type Size = 'tiny' | 'small' | 'medium' | 'big' | 'large' | 'fullHeight'

const sizeMap: Record<Size, string> = {
    tiny: '1.75rem',
    small: '3rem',
    medium: '6rem',
    big: '7.5rem',
    large: '8rem',
    fullHeight: '100%',
}

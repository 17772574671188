import React from 'react'
import { Button } from 'components/Button'
import { WarningIcon, MoreIcon, EditIcon, ViewIcon } from 'components/icons'
import { PopupMenu, PopupMenuButton, PopupMenuItem, PopupMenuList } from 'components/popup-menu'
import { useProjectModalContext } from '../modal/projectModalContext'
import { ProjectActionsMenu } from '../ProjectActionsMenu'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { useTranslate } from 'lib/i18n/useTranslate'
import { ListItemIcon } from 'components/list'
import { ReportProjectModal } from '../report'
import { DialogController } from 'components/dialog'
import { WrappingInline } from 'components/layout/WrappingInline'
import { useIncrementActivityScore } from 'hooks/useIncrementActivityScore'
import { Project } from '../store'
import { useUserState } from 'contexts/UserContext'
import { Link } from 'react-router-dom'
import { getProjectEditLink } from '../projectHelpers'

export const ProjectDetailsActions: React.FC = () => {
    const { project, isOwnProject } = useProjectModalContext()

    if (!project) {
        return null
    }

    return (
        <WrappingInline>
            <ScoolcodeButton project={project} />
            <ErrorBoundary silent>{isOwnProject ? <OwnProjectActions /> : <ProjectActions />}</ErrorBoundary>
        </WrappingInline>
    )
}

const MoreButton: React.FC = () => {
    const t = useTranslate()

    return (
        <PopupMenuButton endIcon={<MoreIcon />} color="default">
            {t('general::more')}
        </PopupMenuButton>
    )
}

export const ScoolcodeButton: React.FC<{
    project: Project
    variant?: 'contained' | 'text'
}> = ({ project, variant }) => {
    const user = useUserState()
    const isOwnProject = project.file.owner === user.account.id
    const t = useTranslate()
    const incrementActivityScore = useIncrementActivityScore()
    const editLink = getProjectEditLink(project, user)

    if (!isOwnProject && project.metadata.isMiniQuiz) {
        return null
    }

    return (
        <>
            {editLink.type === 'external' ? (
                <Button
                    variant={variant}
                    component="a"
                    target="_blank"
                    href={editLink.href}
                    onClick={() => {
                        if (isOwnProject) {
                            incrementActivityScore('classroom:owned-project:open')
                        } else {
                            incrementActivityScore('community:project:view-code')
                        }
                        return true
                    }}
                    startIcon={isOwnProject ? <EditIcon /> : <ViewIcon />}
                >
                    {isOwnProject ? t('general::edit') : t('projects::codeView')}
                </Button>
            ) : (
                <Button
                    variant={variant}
                    component={Link}
                    to={editLink.to}
                    onClick={() => {
                        if (isOwnProject) {
                            incrementActivityScore('classroom:owned-project:open')
                        } else {
                            incrementActivityScore('community:project:view-code')
                        }
                        return true
                    }}
                    startIcon={isOwnProject ? <EditIcon /> : <ViewIcon />}
                >
                    {isOwnProject ? t('general::edit') : t('projects::codeView')}
                </Button>
            )}
        </>
    )
}

const OwnProjectActions: React.FC = () => {
    const { id, close, isPendingProject } = useProjectModalContext()

    return (
        <ProjectActionsMenu
            location="details"
            isPending={isPendingProject}
            id={id}
            onDelete={close}
            onDuplicate={close}
            popupMenuButton={<MoreButton />}
        />
    )
}

const ProjectActions: React.FC = () => {
    const t = useTranslate()

    return (
        <PopupMenu>
            <MoreButton />
            <PopupMenuList>
                <DialogController dialog={({ isOpen, close }) => <ReportProjectModal isOpen={isOpen} close={close} />}>
                    {open => (
                        <PopupMenuItem onClick={open}>
                            <ListItemIcon>
                                <WarningIcon />
                            </ListItemIcon>
                            {t('projects::report::label')}
                        </PopupMenuItem>
                    )}
                </DialogController>
            </PopupMenuList>
        </PopupMenu>
    )
}

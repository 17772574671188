import { Button } from 'components/Button'
import { SelectBox, SelectBoxItem, TextField } from 'components/form'
import { Stack } from 'components/layout'
import { useTranslate } from 'lib/i18n/useTranslate'
import { QUIZ_SESSION_READY } from 'lib/liveops/constants'
import { storeActionListener } from 'lib/store/store-action-listener'
import React, { useState } from 'react'
import { useQueryCache } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useHasGrant } from 'lib/grants'
import { useNotifications } from 'features/notifications'
import {
    GeneratedQuizDifficulty,
    GeneratedQuizMode,
    GeneratedQuizSpeed,
    createGeneratedQuizRoom,
} from './generatedQuizHelpers'
import { Dialog, DialogForm, DialogPrimaryButton, DialogSecondaryButton, DialogTitle } from '../../../components/dialog'

export const CreateMultiplayerGeneratedQuizButton: React.FC = () => {
    const hasGrant = useHasGrant()
    const t = useTranslate()
    const [open, setOpen] = useState(false)

    if (!hasGrant('generate-multiplayer-quiz')) {
        return null
    }

    return (
        <>
            <Button
                onClick={() => {
                    if (!open) {
                        setOpen(true)
                    }
                }}
            >
                {t('generatedQuizzes::createMultiPlayer')}
            </Button>
            <CreateMultiplayerGeneratedQuizDialog open={open} onClose={() => setOpen(false)} />
        </>
    )
}

interface CreateMultiplayerGeneratedQuizDialogProps {
    open: boolean
    onClose: VoidFunction
}

const CreateMultiplayerGeneratedQuizDialog: React.FC<CreateMultiplayerGeneratedQuizDialogProps> = ({
    open,
    onClose,
}) => {
    const t = useTranslate()

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('generatedQuizzes::createMultiPlayer')}</DialogTitle>
            <MultiplayerCreator onClose={onClose} />
        </Dialog>
    )
}

const MultiplayerCreator: React.FC<{ onClose: VoidFunction }> = ({ onClose }) => {
    const [numberOfQuestions, setNumberOfQuestions] = useState(10)
    const [difficulty, setDifficulty] = useState<GeneratedQuizDifficulty>('medium')
    const [speed, setSpeed] = useState<GeneratedQuizSpeed>('normal')
    const { isLoading, generateQuiz } = useGeneratedQuizCreator()
    const t = useTranslate()

    return (
        <DialogForm
            onSubmit={e => {
                e.preventDefault()
                generateQuiz(numberOfQuestions, difficulty, 'multi-player', speed)
                setTimeout(onClose, 1000)
            }}
            actions={
                <>
                    <DialogSecondaryButton onClick={onClose}>{t('general::cancel')}</DialogSecondaryButton>
                    <DialogPrimaryButton isLoading={isLoading} type="submit">
                        {t('generatedQuizzes::start')}
                    </DialogPrimaryButton>
                </>
            }
        >
            <Stack>
                <div>
                    <SelectBox
                        label={t('general::difficulty')}
                        value={difficulty}
                        onChange={value => {
                            setDifficulty(value as GeneratedQuizDifficulty)
                        }}
                    >
                        <SelectBoxItem fullWidth value="easy">
                            {t('general::easy')}
                        </SelectBoxItem>
                        <SelectBoxItem fullWidth value="medium">
                            {t('general::medium')}
                        </SelectBoxItem>
                        <SelectBoxItem fullWidth value="hard">
                            {t('general::hard')}
                        </SelectBoxItem>
                    </SelectBox>
                </div>
                <div>
                    <SelectBox
                        label={t('general::speed')}
                        value={speed}
                        onChange={value => {
                            setSpeed(value as GeneratedQuizSpeed)
                        }}
                    >
                        <SelectBoxItem fullWidth value="normal">
                            {t('general::normalSpeed')}
                        </SelectBoxItem>
                        <SelectBoxItem fullWidth value="fast">
                            {t('general::fastSpeed')}
                        </SelectBoxItem>
                    </SelectBox>
                </div>
                <TextField
                    type="number"
                    label={t('general::numberOfQuestions')}
                    value={numberOfQuestions}
                    inputProps={{ min: 5, max: 20 }}
                    onChange={e => {
                        setNumberOfQuestions(Number.parseInt(e.target.value))
                    }}
                />
            </Stack>
        </DialogForm>
    )
}

function useGeneratedQuizCreator() {
    const [isLoading, setIsLoading] = useState(false)
    const { notify } = useNotifications()
    const t = useTranslate()
    const history = useHistory()
    const queryCache = useQueryCache()
    const dispatch = useDispatch()

    const generateQuiz = (
        numberOfQuestions: number,
        difficulty: GeneratedQuizDifficulty,
        mode: GeneratedQuizMode,
        speed?: GeneratedQuizSpeed,
    ) => {
        if (!isLoading) {
            const timeout = setTimeout(() => {
                storeActionListener.removeListener(QUIZ_SESSION_READY, onSessionReady)
                setIsLoading(false)
                notify(t('error::somethingWentWrong'), { variant: 'error' })
            }, 15000)

            const onSessionReady = ({ payload }: any) => {
                clearTimeout(timeout)
                history.push(
                    mode === 'single-player' ? `/app/quiz-mania/${payload.slug}` : `/app/host-quiz/${payload.slug}`,
                )
                queryCache.invalidateQueries('latest-generated-quiz-session')
                setIsLoading(false)
            }

            setIsLoading(true)
            dispatch(createGeneratedQuizRoom(numberOfQuestions, difficulty, mode, speed))
            storeActionListener.once(QUIZ_SESSION_READY, onSessionReady)
        }
    }

    return { isLoading, generateQuiz }
}

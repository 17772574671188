import dayjs, { Dayjs } from 'dayjs'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useEffect, useState } from 'react'

export const useCountdown = (date: Dayjs, endDate?: Dayjs) => {
    const [remaining, setRemaining] = useState(() => calculateRemainingTime(date, endDate))

    useEffect(() => {
        const tick = () => {
            setRemaining(calculateRemainingTime(date, endDate))
        }
        const interval = setInterval(tick, 1000)

        tick()

        return () => {
            clearInterval(interval)
        }
    }, [date, endDate])

    const isEnd = remaining.days <= 0 && remaining.hours <= 0 && remaining.minutes <= 0 && remaining.seconds <= 0

    return {
        remaining,
        isEnd,
    }
}

export const useWaitForEnd = (date: Dayjs, endDate?: Dayjs) => {
    const [isEnd, setIsEnd] = useState(false)

    useEffect(() => {
        const tick = () => {
            const remaining = calculateRemainingTime(date, endDate);
            const isEnd = remaining.days <= 0 && remaining.hours <= 0 && remaining.minutes <= 0 && remaining.seconds <= 0
            if(isEnd) {
                setIsEnd(true)
            }
        }
        const interval = setInterval(tick, 1000)

        tick()
        setIsEnd(false)

        return () => {
            clearInterval(interval)
        }
    }, [date, endDate])

    return isEnd
}

export const useHumanReadableCountdown = (date: Dayjs, endText?: string, endDate?: Dayjs) => {
    const t = useTranslate()
    const { remaining, isEnd } = useCountdown(date, endDate)

    const isLastMinute = isEnd === false && remaining.days <= 0 && remaining.hours <= 0 && remaining.minutes <= 0

    const getLabel = () => {
        if (isEnd) {
            return endText ?? t('general::finished')
        }
        if (isLastMinute) {
            return t('general::nSecond', { count: remaining.seconds })
        }
        const timeValues: { value: number; text: LocalisationKey }[] = [
            { value: remaining.days, text: 'general::nDay' },
            { value: remaining.hours, text: 'general::nHour' },
        ]
        if (remaining.days < 1) {
            timeValues.push({ value: remaining.minutes, text: 'general::nMinute' })
        }
        return timeValues
            .filter(item => item.value > 0)
            .map(item => t(item.text, { count: item.value }))
            .join(', ')
    }

    return {
        label: getLabel(),
        remaining,
        isEnd,
    }
}

function calculateRemainingTime(date: Dayjs, endDate = dayjs()) {
    const totalSeconds = Math.ceil(date.diff(endDate, 'seconds', true))

    const days = Math.max(Math.floor(totalSeconds / 60 / 60 / 24), 0)
    const hours = Math.max(Math.floor(totalSeconds / 60 / 60 - days * 24), 0)
    const minutes = Math.max(Math.floor(totalSeconds / 60 - (hours * 60 + days * 60 * 24)), 0)
    const seconds = Math.max(totalSeconds - (hours * 60 + days * 60 * 24 + minutes * 60), 0)
    return {
        days,
        hours,
        minutes,
        seconds,
    }
}

import React, {useCallback, useReducer, useState} from 'react'
import {managementContext, ManagementModalEntry} from '../helpers/managementContext'
import {registerManagementModal} from "./ManagementModal";
import {MultiEditStepManagementModalContent} from "./MultiEditStep";
import {MutationTaskManagementModalContent} from "./MutationTask";

export type ManagementVariables = Record<string, any>;
export interface SetManagementVariableAction {
    type: 'set'
    key: string
    value: any
}
export interface SetManagementVariablesAction {
    type: 'setAll'
    value?: any
}
export interface ResetManagementVariablesAction {
    type: 'reset'
}

export type ManagementVariableAction = SetManagementVariableAction | SetManagementVariablesAction | ResetManagementVariablesAction;

const variablesReducer: React.Reducer<ManagementVariables, ManagementVariableAction> = (state, action) => {
    switch (action.type) {
        case 'set':
            return {
                ...state,
                [action.key]: action.value
            }
        case 'setAll':
            return {
                ...state,
                ...action.value
            }
        case 'reset':
            return {}
        default:
            throw new Error(`Unknown action type: ${(action as any).type}`)
    }
}

interface OpenOrUpdateManagementModalAction {
    type: 'open'|'update'
    key: string
    kind: string
    data: any
}

interface CloseOrWidenManagementModalAction {
    type: 'close'|'widen'
    key: string
}

export type ManagementModalAction = OpenOrUpdateManagementModalAction | CloseOrWidenManagementModalAction;

const modalStackReducer: React.Reducer<ManagementModalEntry<any>[], ManagementModalAction> = (state, action) => {
    switch (action.type) {
        case 'open':
            return [
                ...state,
                {
                    key: action.key,
                    kind: action.kind,
                    data: action.data
                }
            ]
        case 'update':
            return state.map(entry => {
                if (entry.key === action.key) {
                    return {
                        ...entry,
                        kind: action.kind,
                        data: action.data,
                        wide: false
                    }
                }
                return entry
            })
        case 'widen':
            return state.map(entry => {
                if (entry.key === action.key) {
                    return {
                        ...entry,
                        wide: true
                    }
                }
                return entry
            })
        case 'close':
            return state.filter(entry => entry.key !== action.key)
        default:
            throw new Error(`Unknown action type: ${(action as any).type}`)
    }
}

export const ManagementContextProvider: React.FC = ({children}) => {
    const [enabled, setEnabled] = useState(false)
    const [variables, dispatchVariablesAction] = useReducer(variablesReducer, {})
    const [modalStack, dispatchModalStackAction] = useReducer(modalStackReducer, [])

    const openModal = useCallback((key: string, kind: string, data: any) => {
        dispatchModalStackAction({
            type: 'open',
            key,
            kind,
            data
        })
    }, [dispatchModalStackAction])
    const updateModal = useCallback((key: string, kind: string, data: any) => {
        dispatchModalStackAction({
            type: 'update',
            key,
            kind,
            data
        })
    }, [dispatchModalStackAction])
    const closeModal = useCallback((key: string) => {
        dispatchModalStackAction({
            type: 'close',
            key
        })
    }, [dispatchModalStackAction])
    const widenModal = useCallback((key: string) => {
        dispatchModalStackAction({
            type: 'widen',
            key
        })
    }, [dispatchModalStackAction])

    return (
        <managementContext.Provider
            value={{
                enabled,
                setEnabled,
                variables,
                dispatchVariablesAction,
                modalStack,
                openModal,
                updateModal,
                widenModal,
                closeModal
            }}
        >
            {children}
        </managementContext.Provider>
    )
}

registerManagementModal('multiedit-step', MultiEditStepManagementModalContent);
registerManagementModal('mutation-task', MutationTaskManagementModalContent);
import { Button } from 'components/Button'
import { FormControl, FormHelperText, FormLabel } from 'components/form'
import { CameraIcon, ImageIcon } from 'components/icons'
import { Box, Inline } from 'components/layout'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { useProjectModalContext } from './modal/projectModalContext'

interface ProjectThumbnailInputProps {
    value?: string
    onChange?: (value: string) => void
    embedIframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    helperText?: string
    errorText?: string
    isLoading?: boolean
    buttonText: string
    buttonRef?: React.MutableRefObject<any>
}

export const ProjectThumbnailInput: React.FC<ProjectThumbnailInputProps> = ({
    value,
    onChange,
    isLoading,
    helperText,
    errorText,
    embedIframeRef,
    buttonText,
    buttonRef,
}) => {
    const [status, setStatus] = useState<'idle' | 'loading'>('idle')
    const t = useTranslate()
    const { embedLoaded, embedOrigin } = useProjectModalContext()
    const getThumbnail = () => {
        if (status === 'idle') {
            const onMessage = (event: MessageEvent) => {
                if (event.origin === embedOrigin && event.data.type === 'screenshot') {
                    onChange?.(event.data.data)
                    setStatus('idle')
                    window.removeEventListener('message', onMessage)
                }
            }

            if (embedIframeRef.current) {
                setStatus('loading')
                window.addEventListener('message', onMessage)
                const embedWindow = embedIframeRef.current.contentWindow
                if (embedWindow) {
                    embedWindow.postMessage({ type: 'getScreenshot' }, embedOrigin)
                }
            }
        }
    }

    return (
        <FormControl>
            <FormLabel>{t('projects::thumbnail')}</FormLabel>
            <Inline spacing={3} display="flex">
                <Box maxWidth={250} flex="1">
                    {value ? <Thumbnail width={640} height={360} src={value} /> : <ThumbnailPlaceholder />}
                </Box>
                <Box flex="1">
                    <Button
                        ref={buttonRef}
                        isLoading={isLoading}
                        startIcon={<CameraIcon />}
                        variant="outlined"
                        onClick={getThumbnail}
                        disabled={!embedLoaded}
                    >
                        {buttonText}
                    </Button>
                    {errorText && <FormHelperText error>{errorText}</FormHelperText>}
                    {helperText && <FormHelperText>{helperText}</FormHelperText>}
                </Box>
            </Inline>
        </FormControl>
    )
}

const Thumbnail = styled.img`
    max-width: 100%;
    height: auto;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    background-color: ${props => props.theme.palette.action.hover};
`

const ThumbnailPlaceholderRoot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    background-color: ${props => props.theme.palette.action.hover};
    color: ${props => props.theme.palette.common.white};
    &:before {
        content: '';
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 56.25%;
    }
    &:after {
        content: '';
        display: table;
        clear: both;
    }
    > svg {
        width: 40%;
        height: auto;
    }
`

const ThumbnailPlaceholder: React.FC = () => {
    return (
        <ThumbnailPlaceholderRoot>
            <ImageIcon />
        </ThumbnailPlaceholderRoot>
    )
}

import React, { createContext, forwardRef, useContext } from 'react'
import {
    Checkbox,
    MenuItem,
    MenuItemProps,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@material-ui/core'
import styled from 'styled-components/macro'
import { FormControl, FormLabel } from 'components/form'
import { FormHelperText } from './FormHelperText'
import { Paper } from 'components/Paper'
import { Inline } from 'components/layout'
import { Text } from 'components/Text'

const selectContext = createContext<{ value: unknown; multiple?: boolean } | undefined>(undefined)

interface SelectProps extends MuiSelectProps {
    errorText?: string
}

export const Select = forwardRef<any, SelectProps>(
    ({ label, fullWidth = true, errorText, className, ...rest }, ref) => {
        return (
            <selectContext.Provider value={{ value: rest.value, multiple: rest.multiple }}>
                <FormControl fullWidth={fullWidth} className={className}>
                    {label && <FormLabel htmlFor={rest.id}>{label}</FormLabel>}
                    <StyledSelect
                        MenuProps={{ PaperProps: { component: MenuPaper } }}
                        error={!!errorText}
                        variant="outlined"
                        inputRef={ref}
                        {...rest}
                    />
                    {errorText && <FormHelperText error>{errorText}</FormHelperText>}
                </FormControl>
            </selectContext.Provider>
        )
    },
)

export const Option: React.FC<MenuItemProps> = ({ children, button, ...rest }) => {
    const context = useContext(selectContext)

    if (!context) {
        throw new Error('Option must be used within a Select.')
    }

    return (
        <StyledMenuItem {...rest}>
            {context.multiple && <StyledCheckbox color="primary" checked={rest.selected} />} {children}
        </StyledMenuItem>
    )
}

export const ImageSelectOptionWrap: React.FC<{ label: string; image: React.ReactNode }> = ({ label, image }) => {
    return (
        <Inline display="flex" alignItems="center">
            {image}
            <Text>{label}</Text>
        </Inline>
    )
}

const StyledCheckbox = styled(Checkbox)`
    padding: 0;
    margin-inline-end: ${props => props.theme.spacing(2)};
`

const MenuPaper = styled(Paper)`
    box-shadow: ${props => props.theme.shadows[3]};
    padding: ${props => props.theme.spacing(2)};
`

const StyledSelect = styled(MuiSelect)`
    background-color: ${props => props.theme.palette.background.paper};
    .MuiSelect-root {
        background: none;
    }
`

const StyledMenuItem = styled(MenuItem)`
    padding: ${props => props.theme.spacing(3)};
    margin: ${props => props.theme.spacing(1, 0)};
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

export const ImageSelect = styled(Select)`
    .MuiSelect-select {
        padding: ${props => props.theme.spacing(2)};
    }
`

export const ImageSelectOption = styled(Option)`
    padding: 0.5rem;
`

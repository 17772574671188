import React from 'react'
import { NotificationCTA, NotificationStatTypes } from '../../lib/liveops/models/notification'
import { Notification } from 'lib/liveops/models/notification'
import { NotificationImage, NotificationUserAvatar } from './NotificationImages'
import { Key, TypedTFunction, useTranslate } from 'lib/i18n/useTranslate'
import { useDispatch } from 'react-redux'
import { LocalisationKey } from 'lib/i18n'
import GoldTrophy from './media/goldtrophy.svg'
import SilverTrophy from './media/silvertrophy.svg'
import BronzeTrophy from './media/bronzetrophy.svg'
import PlatinumTrophy from './media/platinumtrophy.svg'
import { useUserState } from 'contexts/UserContext'
import { highScoreGames } from 'features/high-score/highScoreGames'
import { dm2021UnlockAcihevementNotificationHandler } from 'features/events/pages/digital-mission-2021'
import { Dispatch } from 'redux'
import { onboardingUnlockAcihevementNotificationHandler } from 'features/events/pages/onboarding'

export interface NotificationView {
    img: React.ReactNode
    title: string
    body?: string
    cta?: NotificationCTA
    variables?: Record<string, any>
}

export type UnlockAchievementNotificationHandler = (context: {
    notification: Notification
    t: TypedTFunction
    dispatch: Dispatch
}) => NotificationView | null

export const useNotificationToNotificationView = (notification: Notification): NotificationView | null => {
    const { type, payload } = notification.message.data
    const t = useTranslate()
    const dispatch = useDispatch()
    const { account } = useUserState()

    switch (type) {
        case 'NEW_PROJECT_SHARED':
            return {
                img: <NotificationUserAvatar userId={payload.userId} />,
                title: payload.project
                    ? `${t(
                          payload.project.isMiniQuiz
                              ? 'notification::shareNewQuizProject::titleWithName'
                              : 'notification::shareNewCodingProject::titleWithName',
                          {
                              name: payload.username || payload.name,
                              title: payload.project.title,
                          },
                      )}`
                    : `${t('notification::shareNewProject::title', { name: payload.username || payload.name })}`,
                body: t('notification::shareNewProject::body'),
                cta: {
                    type: 'internal-link',
                    link: { pathname: `/app/project/${payload.projectId}` },
                },
            }

        case 'PROJECT_UPDATED':
            return {
                img: <NotificationUserAvatar userId={payload.userId} />,
                title: payload.project
                    ? `${t(
                          payload.project.isMiniQuiz
                              ? 'notification::updatedQuizProject::titleWithName'
                              : 'notification::updatedCodingProject::titleWithName',
                          {
                              name: payload.username || payload.name,
                              title: payload.project.title,
                          },
                      )}`
                    : `${t('notification::shareNewProject::title', { name: payload.username || payload.name })}`,
                body: t('notification::updatedProject::body'),
                cta: {
                    type: 'internal-link',
                    link: { pathname: `/app/project/${payload.projectId}` },
                },
            }

        case 'UNLOCK_ACHIEVEMENT': {
            return (
                dm2021UnlockAcihevementNotificationHandler({ notification, t, dispatch }) ??
                onboardingUnlockAcihevementNotificationHandler({ notification, t, dispatch })
            )
        }

        case 'WEEKLY_TOP_100_PRESENCE': {
            const trophies = {
                platinum: PlatinumTrophy,
                gold: GoldTrophy,
                silver: SilverTrophy,
                bronze: BronzeTrophy,
            }

            const validStats = {
                Easy: `- ${t('scChallengeLevel::easy')}`,
                Medium: `- ${t('scChallengeLevel::medium')}`,
                Hard: `- ${t('scChallengeLevel::hard')}`,
            }

            const getProjectTitle = t(
                highScoreGames.find(game => game.projectId === payload.projectId)?.title as LocalisationKey,
            )

            const getTranslation =
                payload.stat && NotificationStatTypes.includes(payload.stat)
                    ? t('notification::unlockTrophy:title02', {
                          trophytype: t(`tooltip::${payload.trophy}`),
                          highscoregamename: getProjectTitle,
                          highscoregamelevel: validStats[payload.stat!],
                      })
                    : t('notification::unlockTrophy:title', {
                          trophytype: t(`tooltip::${payload.trophy}`),
                          highscoregamename: getProjectTitle,
                      })

            return {
                img: <NotificationImage notiMediaSrc={trophies[payload.trophy!]} />,
                title: getTranslation,
                body: t('notification::unlockAchievement::body'),
                cta: {
                    type: 'internal-link',
                    link: { pathname: `/app/user/${account.username}` },
                },
            }
        }

        case 'DM2021_WINNER':
            return {
                img: <NotificationImage notiMediaSrc={GoldTrophy} />,
                title: t('notification::wonDMdraw::title'),
                body: t('notification::unlockAchievement::body'),
                cta: {
                    type: 'internal-link',
                    link: {
                        pathname: `/app/events/digital-mission-2021`,
                    },
                },
            }

        case 'DM2021_CERT':
            return {
                img: <NotificationImage notiMediaSrc={'/certificate/thumbnails/dm2021.png'} />,
                title: t('notification::newCert::title'),
                body: t('notification::newCert::body'),
                cta: {
                    type: 'internal-link',
                    link: { pathname: `/app/user/${account.username}`, state: { fromNotification: true } },
                },
            }

        case 'GENERAL':
            const variables: Record<string, string> = {}
            for (const key in notification.message.data.payload.variables) {
                variables[key] = t(notification.message.data.payload.variables[key])
            }
            return {
                img: <NotificationImage notiMediaSrc={payload?._image} />,
                title: t(notification.message.title as Key, {
                    ...notification.message.data.payload?.title,
                    ...variables,
                }),
                variables: notification.message.data.payload.variables,
                body: t(notification.message.body as Key, { ...notification.message.data.payload?.body, ...variables }),
                cta:
                    // fix extra share slot notifications accidentally sent out without a cta
                    // in the long run these should be migrated or ignored so we can remove this hack
                    notification.message.title === 'notification::getExtraSlot::title'
                        ? { type: 'internal-link', link: '/app/community/share-project' }
                        : payload?._cta,
            }

        default:
            return null
    }
}

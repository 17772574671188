import { css } from 'styled-components/macro'
import { createTheme } from '../createTheme'

export const light6 = createTheme(
    { base: '#fafafa', aside: '#388e3c', primary: '#388e3c', asideContent: '#61b865' },
    {
        palette: {
            background: {
                default: '#f5f5fb',
            },
            primary: {
                main: '#388e3c',
            },
            text: {
                disabled: '#a2a2a3',
            },
        },
        extras: {
            themeColorMeta: '#256d35',
            logoType: 'white',

            pageHeader: {
                root: css`
                    background-color: #fff;
                `,
            },
            mobileHeader: {
                root: css`
                    background-color: #256d35;
                    color: white;
                `,
            },
            notificationCount: {
                root: css`
                    background-color: ${props => props.theme.palette.primary.light};
                `,
            },
            pageAside: {
                paper: css`
                    background: linear-gradient(#16904c, #256d35);
                    color: white;
                    border-right: none;
                `,
                secondary: css`
                    color: #7ab387;
                `,
                listItemAndNotificationButton: css`
                    &:hover,
                    &:focus-visible {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    &.Mui-selected,
                    &.Mui-selected:hover {
                        background-color: rgba(0, 0, 0, 0.1);
                        color: #7dd9a5;
                    }
                `,
            },
        },
    },
)

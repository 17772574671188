import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { createMuiTheme } from '@material-ui/core'
import { ThemeProvider as ScThemeProivder } from 'styled-components/macro'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles'
import * as themes from './themes'
import { i18n } from 'lib/i18n'
import { create } from 'jss'
import rtl from 'jss-rtl'
import { StylesProvider, jssPreset } from '@material-ui/core/styles'
import { ThemeThumbnail } from './createTheme'
import { Grant, GrantFeature, hasGrant } from 'lib/grants'
import { UserState } from 'contexts/UserContext'

const jss = create({ plugins: [...jssPreset().plugins, rtl()] })

export type ThemeName = keyof typeof themes

type ThemeState = {
    theme: ThemeName
    themes: {
        name: ThemeName
        thumbnail: ThemeThumbnail
        grantRequiredToDisplay?: (Grant | GrantFeature)[]
    }[]
    setTheme: (theme: ThemeName) => void
    setThemeFromUserSettings: (user: UserState) => void
}

const themeContext = createContext<ThemeState | undefined>(undefined)

export const ThemeProvider: React.FC = ({ children }) => {
    const [themeName, setThemeName] = useState<ThemeName>(getInitialTheme)
    const [direction, setDirection] = useState<'rtl' | 'ltr'>('ltr')
    const theme = useMemo(() => createMuiTheme({ ...themes[themeName].data, direction }), [themeName, direction])

    useEffect(() => {
        const metaTag = document.querySelector('meta[name="theme-color"]')
        if (metaTag) {
            metaTag.setAttribute('content', theme.extras?.themeColorMeta ?? '#ffffff')
        }
    }, [theme])

    useEffect(() => {
        function onDirectionChange(direction: 'rtl' | 'ltr') {
            setDirection(direction)
        }

        i18n.addDirectionChangeListener(onDirectionChange)

        return () => {
            i18n.removeDirectionChangeListener(onDirectionChange)
        }
    }, [])

    useEffect(() => {
        /**
         * We store the last used theme in localStorage
         * to avoid flicker after user settings loaded.
         */
        window.localStorage.setItem(THEME_STORAGE_KEY, themeName)
    }, [themeName])

    const setTheme = useCallback((theme: ThemeName) => {
        if (doesThemeExist(theme)) {
            setThemeName(theme)
        } else {
            console.warn(`Theme '${theme}' not found.\n Available themes: ${Object.keys(themes).join(', ')}`)
        }
    }, [])

    useEffect(() => {
        ;(window as any).setTheme = setTheme
    }, [setTheme])

    function setThemeFromUserSettings(user: UserState) {
        let themeName: ThemeName = 'light1'

        const themeSetting = user.settings['mylogiscool-theme']

        if (themeSetting && doesThemeExist(themeSetting)) {
            const theme = themes[themeSetting]
            const hasAccess = theme.grantRequiredToDisplay
                ? theme.grantRequiredToDisplay.some(grant => hasGrant(user.grants)(grant))
                : true

            if (hasAccess) {
                themeName = themeSetting
            }
        }

        setTheme(themeName)
    }

    return (
        <themeContext.Provider
            value={{
                theme: themeName,
                setTheme,
                themes: Object.entries(themes).map(([key, theme]) => ({
                    name: key as ThemeName,
                    thumbnail: theme.thumbnail,
                    grantRequiredToDisplay: theme.grantRequiredToDisplay,
                })),
                setThemeFromUserSettings,
            }}
        >
            <StylesProvider jss={jss}>
                <MuiThemeProvider theme={theme}>
                    <ScThemeProivder theme={theme}>{children}</ScThemeProivder>
                </MuiThemeProvider>
            </StylesProvider>
        </themeContext.Provider>
    )
}

const THEME_STORAGE_KEY = '@my-logiscool/theme'

function getInitialTheme(): ThemeName {
    const storedTheme = window.localStorage.getItem(THEME_STORAGE_KEY)
    if (storedTheme && doesThemeExist(storedTheme)) {
        return storedTheme
    }
    return 'light1'
}

export function useTheming() {
    const context = React.useContext(themeContext)
    if (context === undefined) {
        throw new Error('useTheming must be used within a ThemeProvider.')
    }
    return context
}

function doesThemeExist(theme: string): theme is ThemeName {
    return theme in themes
}

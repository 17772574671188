import React from 'react'
import { ProjectModalBase, ProjectModalDescription } from 'features/projects'
import { Stack } from 'components/layout'
import { ProjectModalContent } from 'features/projects/modal/ProjectModalContent'
import { ProjectModalHeader } from 'features/projects/modal/ProjectModalHeader'
import styled from 'styled-components/macro'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Loader } from 'components/Loader'
import { useMyOFCsets } from 'features/scoolcode-challenge/data/scoolcodeChallengeQueries'
import { useParams } from 'react-router-dom'
import { ErrorMessage } from 'components/ErrorMessage'

export const OnlinefirstChallengeDialog = () => {
    return (
        <ProjectModalBase projectVariant={'normal'} isNotFound={() => false} embedOwnerInFileRequest={false}>
            <OfcChallengeDialogDetails />
        </ProjectModalBase>
    )
}

const OfcChallengeDialogDetails: React.FC = () => {
    const t = useTranslate()
    const { setId } = useParams<{ setId: string }>()

    const { data, status } = useMyOFCsets()

    if (status === 'error') {
        return <ErrorMessage />
    }

    if (!data) {
        return <Loader />
    }

    const game = data.find(item => item.id === setId)

    return (
        <>
            <ProjectModalHeader>
                <Title variant="h3">{t(`ofc::${game?.internalName}::title`)}</Title>
            </ProjectModalHeader>
            <ProjectModalContent>
                <Stack spacing={5}>
                    <ProjectModalDescription>{t(`ofc::${game?.internalName}::description`)}</ProjectModalDescription>
                </Stack>
            </ProjectModalContent>
        </>
    )
}

const Title = styled(Text)`
    line-height: 1.2;
    word-break: break-all;
` as typeof Text

import React from 'react'
import { IconButton as MuiIconButton, IconButtonTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import styled from 'styled-components/macro'

export const ICON_BUTTON_SMALL_PADDING = '0.4rem'

export type IconButtonProps = IconButtonTypeMap['props'] & { style?: React.CSSProperties }

export const IconButton: OverridableComponent<IconButtonTypeMap> = (React.forwardRef as any)(
    (props: IconButtonProps, ref: any) => {
        return <Root color="inherit" ref={ref} {...props} />
    },
)

const Root = styled(MuiIconButton)`
    padding: ${props => (props.size === 'small' ? ICON_BUTTON_SMALL_PADDING : undefined)};
    &:focus-visible {
        outline: solid 1px ${props => props.theme.palette.primary.main};
    }
`

import { useEffect } from 'react'
import { useIncrementActivityScore } from './useIncrementActivityScore'

export function useRegisterActivityOnLoad(activity: string) {
    const incrementActivityScore = useIncrementActivityScore()
    useEffect(() => {
        if (incrementActivityScore && activity) {
            incrementActivityScore(activity)
        }
    }, [incrementActivityScore, activity])
}

import React from 'react'
import { ProjectModalContent } from './ProjectModalContent'
import { ProjectModalHeader } from './ProjectModalHeader'
import { Text } from 'components/Text'
import { ErrorMessage } from 'components/ErrorMessage'
import { NBSP } from 'utils/styleUtils'
interface ProjectModalErrorProps {
    error: 'GENERAL' | 'NOT_FOUND'
}

export const ProjectModalError: React.FC<ProjectModalErrorProps> = ({ error }) => {
    if (error === 'NOT_FOUND') {
        return (
            <>
                <ProjectModalHeader>
                    <Text variant="h3">Project not found</Text>
                </ProjectModalHeader>
                <ProjectModalContent></ProjectModalContent>
            </>
        )
    }

    return (
        <>
            <ProjectModalHeader>
                <Text variant="h3">{NBSP}</Text>
            </ProjectModalHeader>
            <ProjectModalContent>
                <ErrorMessage />
            </ProjectModalContent>
        </>
    )
}

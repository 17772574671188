import { useHasGrant } from 'lib/grants'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useProjectModalContext } from '../modal/projectModalContext'
import { deserializeProjectLabel, isCustomProjectLabel } from '../projectHelpers'
import { ProjectLabelSelect } from '../ProjectLabelSelect'
import { useProjectActions } from '../useProjectActions'

export const ProjectDetailsLabels: React.FC = () => {
    const { project, isOwnProject } = useProjectModalContext()
    const { updateProjectLabels } = useProjectActions()
    const hasGrant = useHasGrant()
    const { enqueueSnackbar } = useSnackbar()
    const t = useTranslate()

    if (!hasGrant('project-labels') || !project || !isOwnProject || project.metadata.isMiniQuiz) {
        return null
    }

    return (
        <ProjectLabelSelect
            variant="edit"
            onChange={async values => {
                try {
                    await updateProjectLabels(project.file.id, values)
                } catch (e) {
                    console.error(e)
                    enqueueSnackbar(t('error::somethingWentWrong'), { variant: 'error' })
                }
            }}
            defaultValue={project.file.labels?.filter(isCustomProjectLabel).map(deserializeProjectLabel) ?? []}
        />
    )
}

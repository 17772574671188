import React from 'react'
import styled from 'styled-components/macro'
import image from '../media/birthday-footer.webp'

export const BirthdayFooter: React.FC = () => {
    return <Root width="3644" height="587" src={image} />
}
const Root = styled.img`
    display: block;
    bottom: 0;
    max-width: 100%;
    height: auto;
`

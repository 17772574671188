import {
    onboardingAchievements,
    ONBOARDING_REACHED_3_POINTS_CATALOG_ITEM,
} from 'features/events/pages/onboarding/data/onboardingData'
import { Notification } from 'lib/liveops/models/notification'
// import { ATTENDED_OPEN_DAY_ITEM_ID } from 'lib/liveops/selectors'
import { QueuedDialogData } from '../store/dialogQueueReducer'

export const dialogFromNotification = (notification: Notification): QueuedDialogData | null => {
    const cta = notification.message.data.payload._cta
    if (cta?.type === 'dialog' && (cta.data.type === 'general' ? !cta.data.disablePopup : true)) {
        return {
            ...cta.data,
            variables: notification.message.data.payload.variables,
            notificationId: notification.id,
        }
    }

    if (notification.message.data.type === 'UNLOCK_ACHIEVEMENT') {
        if (notification.message.data.payload.achievementId === ONBOARDING_REACHED_3_POINTS_CATALOG_ITEM) {
            return {
                type: 'onboarding-points-reached',
                notificationId: notification.id,
            }
        }

        const achievement = onboardingAchievements.find(
            achi => achi.catalogItem === notification.message.data.payload.achievementId,
        )
        if (achievement) {
            return {
                type: 'onboarding-achievement-unlocked',
                notificationId: notification.id,
                achievement: achievement,
            }
        }
    }

    return null
}

import { Inline } from 'components/layout'
import React from 'react'
import styled from 'styled-components/macro'

interface ProjectListTabProps {
    isActive?: boolean
    onClick?: VoidFunction
    icon?: React.ReactNode
}

export const ProjectListTab: React.FC<ProjectListTabProps> = ({ isActive, onClick, children, icon }) => {
    return (
        <Root isActiva={isActive} onClick={onClick}>
            <StyledInline component="span" spacing={1}>
                {icon}
                <span>{children}</span>
            </StyledInline>
        </Root>
    )
}

const StyledInline = styled(Inline)`
    display: flex;
    align-items: center;
`

const Root = styled.button<{ isActiva?: boolean }>`
    font-family: inherit;
    color: ${props => (props.isActiva ? props.theme.palette.primary.main : 'inherit')};
    text-decoration: none;
    font-weight: 600;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    font-size: ${props => props.theme.typography.body2.fontSize};
    transition: color ${props => props.theme.transitions.duration.shorter}ms;
    line-height: 1;
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: solid 1px ${props => props.theme.palette.primary.main};
    }
    &:hover,
    &:focus-visible {
        color: ${props => props.theme.palette.primary.main};
    }
`

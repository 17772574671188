import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { incrementActivityScore } from '../lib/liveops'

export function useIncrementActivityScore() {
    const dispatch = useDispatch()
    const increment = useCallback(
        (activity: string) => {
            if (dispatch) {
                dispatch(incrementActivityScore(activity))
            }
        },
        [dispatch],
    )
    return increment
}

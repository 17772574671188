import { css } from 'styled-components/macro'
import { createTheme } from '../createTheme'

export const dark1 = createTheme(
    { base: '#14181f', aside: '#14181f', primary: '#1a9ff0', asideContent: '#333', baseContent: '#333' },
    {
        palette: {
            text: { primary: '#c0c2c9', secondary: '#868992' },
            type: 'dark',
            background: {
                default: '#14181f',
                paper: '#24243a',
            },
            primary: { main: '#1a9ff0', dark: '#0816a9', contrastText: '#fff' },
        },
        extras: {
            logoType: 'white',
            themeColorMeta: '#06090f',
            pageAside: {
                paper: css`
                    background: #14181f;
                `,
            },
            dialog: {
                backdrop: css`
                    background: rgb(34 44 52 / 87%);
                `,
                projectDialogPaper: css`
                    background: #06090f;
                `,
            },
            actionablePaper: {
                root: css`
                    &:hover,
                    &:focus-visible {
                        background-color: ${props => props.theme.palette.background.paper};
                        filter: brightness(1.1);
                    }
                `,
            },
            containedButton: color => {
                if (color === 'default') {
                    return css`
                        background-color: #1d1d23;
                        color: #95949d;
                        &:hover,
                        &:focus-visible {
                            background-color: #19191b;
                        }
                    `
                }
                if (color === 'primary') {
                    return css`
                        &.Mui-disabled {
                            background: #212121;
                        }
                    `
                }
            },
        },
    },
)

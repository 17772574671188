import { Card } from 'components/card'
import { Inline } from 'components/layout'
import { Text } from 'components/Text'
import { ellipsis } from 'polished'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'
import { actionablePaperStyles } from 'utils/styleUtils'

interface ProjectStripeProps {
    title: string
    thumbnailUrl: string
    metadata?: React.ReactNode
    actions?: React.ReactNode
    tags?: React.ReactNode
    to?: LinkProps['to']
    favorite?: React.ReactNode
}

export const ProjectStripe: React.FC<ProjectStripeProps> = ({
    title,
    actions,
    to,
    metadata,
    tags,
    thumbnailUrl,
    favorite,
}) => {
    return (
        <Root $hasLink={!!to}>
            <Inner>
                <InnerWrap spacing={3}>
                    <Thumbnail loading="lazy" width="640" height="360" src={thumbnailUrl} />
                    {favorite}
                    <Title>
                        <strong>{title}</strong>
                    </Title>
                    {tags}
                </InnerWrap>
                {metadata && (
                    <MetaWrap $hideBorder={!actions} spacing={4}>
                        {metadata}
                    </MetaWrap>
                )}
                {to === undefined ? null : <StyledLink to={to} />}
            </Inner>
            <Inline spacing={4} display="flex" alignItems="center" textAlign="right">
                {actions}
            </Inline>
        </Root>
    )
}

const MetaWrap = styled(Inline)<{ $hideBorder?: boolean }>`
    display: flex;
    align-items: center;
    text-align: right;
    padding-right: ${props => props.theme.spacing(6)};
    border-right: ${props => (props.$hideBorder ? undefined : `1px solid ${props.theme.palette.divider}`)};
`

const Title = styled(Text)`
    ${ellipsis()};
`

const InnerWrap = styled(Inline)`
    display: flex;
    align-items: center;
`

const Inner = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-gap: ${props => props.theme.spacing(4)};
    justify-content: space-between;
    align-items: center;
    ${props => props.theme.breakpoints.down('xs')} {
        grid-gap: ${props => props.theme.spacing(2)};
    }
`

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`

const Thumbnail = styled.img`
    max-width: 7rem;
    height: auto;
    background-color: ${props => props.theme.palette.divider};
    ${props => props.theme.breakpoints.down('xs')} {
        max-width: 5.5rem;
    }
`

const Root = styled(Card)<{ $hasLink: boolean }>`
    ${props => (props.$hasLink ? actionablePaperStyles : undefined)}

    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    padding: ${props => props.theme.spacing(0, 4, 0, 0)};
    grid-gap: ${props => props.theme.spacing(6)};
    ${props => props.theme.breakpoints.down('xs')} {
        grid-gap: ${props => props.theme.spacing(2)};
    }
    position: relative;
`

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { pauseQueue, resumeQueue } from '..'

export const usePauseDialogQueue = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(pauseQueue())

        return () => {
            dispatch(resumeQueue())
        }
    }, [dispatch])
}

import { useState, useRef, useCallback } from 'react'

export function useIsElementFullyVisible() {
    const [isFullyVisible, setIsFullyVisible] = useState<boolean | undefined>()
    const observer = useRef<IntersectionObserver>()
    const ref = useCallback((node: HTMLDivElement) => {
        if (!node) {
            observer.current?.disconnect()
            return
        }
        observer.current = new IntersectionObserver(
            entries => {
                if (entries[0]?.intersectionRatio === 1) {
                    setIsFullyVisible(true)
                } else {
                    setIsFullyVisible(false)
                }
            },
            { threshold: 1 },
        )
        observer.current.observe(node)
    }, [])

    return {
        ref,
        isFullyVisible,
    }
}

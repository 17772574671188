import { EmptyState } from 'components/EmptyState'
import { CertificateIcon, FavoriteIcon, TrophyIcon } from 'components/icons'
import { Stack } from 'components/layout'
import { useTranslate } from 'lib/i18n/useTranslate'
import { opacify, transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'

interface CertificateListEmptyStateProps {}

export const CertificateListEmptyState: React.FC<CertificateListEmptyStateProps> = () => {
    const t = useTranslate()

    return (
        <Stack>
            <EmptyState>{t('certificates::noResults')}</EmptyState>
            <Grid>
                <Item icon={<CertificateIcon />} />
                <Item icon={<FavoriteIcon />} />
                <Item icon={<TrophyIcon />} />
            </Grid>
        </Stack>
    )
}

const Item: React.FC<{ icon: React.ReactNode }> = ({ icon }) => {
    return (
        <ItemRoot>
            {icon}
            <ItemText />
        </ItemRoot>
    )
}

const ItemText = styled.span`
    font-size: 1rem;
    width: 15ch;
    height: 1rem;
    background-color: ${props => transparentize(0.92, opacify(1, props.theme.palette.text.primary))};
    margin-inline-start: ${props => props.theme.spacing(4)};
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

const ItemRoot = styled.div`
    display: flex;
    align-items: center;
    height: 4.5rem;
    font-size: 2.5rem;
    padding: ${props => props.theme.spacing(4)};
    border: solid 2px ${props => transparentize(0.96, opacify(1, props.theme.palette.text.primary))};
    color: ${props => transparentize(0.88, opacify(1, props.theme.palette.text.primary))};
    background-color: ${props => transparentize(0.96, opacify(1, props.theme.palette.text.primary))};
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: ${props => props.theme.spacing(6)};
`

import React from 'react'
import { HighScoreGame } from './highScoreGames'
import styled, { css } from 'styled-components/macro'
import { useTranslate } from 'lib/i18n/useTranslate'

interface HighScoreGameThumbnailProps {
    game: HighScoreGame
    className?: string
}

export const HighScoreGameThumbnail: React.FC<HighScoreGameThumbnailProps> = ({ game, className }) => {
    const t = useTranslate()

    return (
        <>
            {game.thumbnail.type === 'video' ? (
                <Video playsInline autoPlay muted loop src={game.thumbnail.src} className={className} />
            ) : (
                <Image width="586" height="324" src={game.thumbnail.src} alt={t(game.title)} className={className} />
            )}
        </>
    )
}

const mediaStyles = css`
    width: 100%;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    transition: all ${props => props.theme.transitions.duration.standard}ms;
    background-color: ${props => props.theme.palette.divider};
    filter: grayscale(0.2);
`

const Image = styled.img`
    ${mediaStyles}
    height: auto;
`

const Video = styled.video`
    ${mediaStyles}
    aspect-ratio: 640 / 360;
`

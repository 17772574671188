import { DownloadIcon } from 'components/icons'
import { Stack } from 'components/layout'
import { Panel, PanelHeader, PanelTitle } from 'components/panel'
import { Text } from 'components/Text'
import { api } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { Wallpaper } from '../types/wallpaperTypes'

interface WallpaperListProps {
    title: string
    wallpapers: Wallpaper[]
    imageWidth: number
    imageHeight: number
}

export const WallpaperList: React.FC<WallpaperListProps> = ({ wallpapers, title, imageWidth, imageHeight }) => {
    const t = useTranslate()

    return (
        <Panel>
            <PanelHeader>
                <PanelTitle>{title}</PanelTitle>
            </PanelHeader>
            <Grid>
                {wallpapers.map(wallpaper => (
                    <ImageLink
                        key={wallpaper.id}
                        href={wallpaper.image}
                        download
                        target="_blank"
                        onClick={() => {
                            api.wallpapers.incrementWallpaperDownloadCount(wallpaper.id)
                        }}
                    >
                        <Image width={imageWidth} height={imageHeight} src={wallpaper.image} alt="" />
                        <ImageOverlay spacing={1}>
                            <DownloadIcon fontSize="1.5rem" />
                            <Text>{t('download')}</Text>
                        </ImageOverlay>
                    </ImageLink>
                ))}
            </Grid>
        </Panel>
    )
}

const ImageOverlay = styled(Stack)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => transparentize(0.2, props.theme.palette.primary.main)};
    transition: ${props => props.theme.transitions.duration.standard}ms;
    p {
        font-weight: 600;
    }
`

const ImageLink = styled.a`
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    &:hover,
    &:focus-visible {
        ${ImageOverlay} {
            opacity: 1;
        }
    }
`

const Image = styled.img`
    max-width: 100%;
    height: auto;
    display: block;
    background-color: ${props => props.theme.palette.divider};
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
    gap: ${props => props.theme.spacing(6)};

    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: repeat(2, 1fr);
    }
`

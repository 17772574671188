import React, { useState } from 'react'
import { api } from 'lib/api'
import { useQuery } from 'react-query'
import { SelectableAvatar } from './SelectableAvatar'
import { Loader } from 'components/Loader'
import { createAvatarUrl } from './avatarHelpers'
import styled from 'styled-components/macro'
import { ListItem } from 'components/list'
import { Flex } from 'components/layout'
import { Select, Option } from 'components/form'
import { DialogForm } from 'components/dialog'
import { ErrorMessage } from 'components/ErrorMessage'
import { Hidden } from 'components/layout'
import { List } from 'components/list'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useUserActions, useUserState } from 'contexts/UserContext'
import { ButtonProps } from 'components/Button'
import { useNotifications } from 'features/notifications'
import { LocalisationKey } from 'lib/i18n'
import { CookieHunt } from 'features/cookie-hunt'
import { useDispatch } from 'react-redux'
import { changedProfilePicture } from 'lib/liveops'

interface AvatarBrowserProps {
    onClose: VoidFunction
    isOnboarding: boolean
    renderActions: (props: { secondaryButtonProps: ButtonProps; primaryButtonProps: ButtonProps }) => React.ReactNode
}

export const AvatarBrowser: React.FC<AvatarBrowserProps> = ({ onClose, isOnboarding, renderActions, children }) => {
    const { account } = useUserState()
    const { updateUser } = useUserActions()
    const [value, setValue] = useState(account.profileImage || '')
    const { data: profilePictures, status: queryStatus } = useQuery(['profile-pictures', isOnboarding], () =>
        api.accounts.getProfilePictures({ isOnboarding }),
    )
    const [selectedCategory, setSelectedCategory] = useState<string>('')
    const t = useTranslate()
    const { notify } = useNotifications()
    const [status, setStatus] = useState<'idle' | 'loading'>('idle')
    const dispatch = useDispatch()

    if (queryStatus === 'error') {
        return <ErrorMessage />
    }

    if (!profilePictures || queryStatus === 'loading') {
        return (
            <Flex height="100%" justify="center" align="center">
                <Loader />
            </Flex>
        )
    }

    const save = async () => {
        if (status === 'idle') {
            setStatus('loading')
            try {
                await updateUser({ profileImage: value })
                notify(t('settings::saveSuccess'), { variant: 'success' })
                dispatch(changedProfilePicture())
                onClose()
            } catch (e) {
                console.error(e)
                notify(t('error::somethingWentWrong'), { variant: 'error' })
            } finally {
                setStatus('idle')
            }
        }
    }

    const categories = Array.from(new Set(profilePictures.data.flatMap(picture => picture.tags || [])))

    const filteredProfilePictures = profilePictures.data.filter(picture =>
        selectedCategory === '' ? true : picture.tags?.includes(selectedCategory),
    )

    return (
        <DialogForm
            actions={renderActions({
                primaryButtonProps: { isLoading: status === 'loading', onClick: save },
                secondaryButtonProps: { onClick: onClose },
            })}
        >
            {children}
            <Root isOnboarding={isOnboarding}>
                {isOnboarding !== true && (
                    <Hidden smUp>
                        <Select
                            displayEmpty
                            value={selectedCategory}
                            onChange={e => {
                                setSelectedCategory(e.target.value as any)
                            }}
                        >
                            <Option value="">{t('general::all')}</Option>
                            {categories.map(category => (
                                <Option value={category} key={category}>
                                    {t(category.replaceAll('.', '::') as LocalisationKey)}
                                </Option>
                            ))}
                        </Select>
                    </Hidden>
                )}
                {isOnboarding !== true && (
                    <Hidden xsDown>
                        <StyledList>
                            <ListItem
                                selected={selectedCategory === ''}
                                onClick={() => {
                                    setSelectedCategory('')
                                }}
                            >
                                {t('general::all')}
                            </ListItem>
                            {categories.map(category => (
                                <ListItem
                                    selected={selectedCategory === category}
                                    onClick={() => {
                                        setSelectedCategory(category)
                                    }}
                                    key={category}
                                >
                                    {t(category.replaceAll('.', '::') as LocalisationKey)}
                                </ListItem>
                            ))}
                        </StyledList>
                    </Hidden>
                )}
                <AvatarGrid>
                    {filteredProfilePictures.map((picture, i) => (
                        <CookieHunt key={picture.id} name="avatar-list" index={i}>
                            <SelectableAvatar
                                selected={value === picture.id}
                                locked={!picture.usable}
                                lockedMessage={picture.description}
                                size="fullWidth"
                                onChange={checked => {
                                    if (checked && picture.usable) {
                                        setValue(picture.id)
                                    }
                                }}
                                src={createAvatarUrl(picture.id)}
                            />
                        </CookieHunt>
                    ))}
                </AvatarGrid>
            </Root>
        </DialogForm>
    )
}

const StyledList = styled(List)`
    height: 100%;
    overflow-y: auto;
    padding-right: ${props => props.theme.spacing(2)};
`

const Root = styled.div<{ isOnboarding?: boolean }>`
    display: ${props => (props.isOnboarding === true ? 'block' : 'grid')};
    ${props => props.theme.breakpoints.down('xs')} {
        display: block;
    }
    grid-template-columns: minmax(16rem, auto) 1fr;
    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: minmax(10rem, auto) 1fr;
    }
    gap: ${props => props.theme.spacing(1)};
    height: ${props => (props.isOnboarding === true ? undefined : '100%')};
`

const AvatarGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(6.5rem, 1fr));
    gap: ${props => props.theme.spacing(4)};
    align-content: flex-start;
    overflow-y: auto;
    padding: ${props => props.theme.spacing(4)};
`

import * as React from 'react'
import ReactDOM from 'react-dom'
import './certificates.css'
import { useSelector } from 'lib/store'
import { isDmWinner } from './certificateHelpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import { LocalisationKey } from 'lib/i18n'
import { api } from 'lib/api'
export interface PdfTemplateProps {
    school: string
    finishedAt: string
    name: string
    locale: string
    id: string
    translations: {
        name: string
        items: string
        blurb: string
        duringThisCourse: string
        duringThisWorkshop: string
    }
}
export const PdfTemplateBlox: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="blox" {...props}></TemplateBase>
}

export const PdfTemplateMix: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="mix" {...props}></TemplateBase>
}

export const PdfTemplateUnity: React.FC<PdfTemplateProps> = props => {
    return <TemplateBaseText variant="unity" {...props} />
}

export const PdfTemplatePython: React.FC<PdfTemplateProps> = props => {
    return <TemplateBaseText variant="python" {...props} />
}

export const PdfTemplateGodot: React.FC<PdfTemplateProps> = props => {
    return <TemplateBaseText variant="godot" {...props} />
}

export const PdfTemplateRoblox: React.FC<PdfTemplateProps> = props => {
    return <TemplateBaseText variant="roblox" {...props} />
}

export const PdfTemplateDevMaster: React.FC<PdfTemplateProps> = props => {
    return <TemplateBaseText variant="devMaster" {...props} />
}

export const PdfTemplateRobot: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="robot" {...props} />
}

export const PdfTemplateRobotPrime: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="robotPrime" {...props} />
}

export const PdfTemplateVideo: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="video" {...props} />
}

export const PdfTemplateDesign: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="design" {...props} />
}

export const PdfTemplateMinecraft: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="minecraft" {...props} />
}

export const PdfTemplateRpg: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="rpg" {...props} />
}

export const PdfTemplateAi: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="ai" {...props} />
}

export const PdfTemplateDigitalDiscovery: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="digitalDiscovery" {...props} />
}

export const PdfTemplateAiPython: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="aiPython" {...props} />
}

export const PdfTemplateAiBasic: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="aiBasic" {...props} />
}

export const PdfTemplateOpenDay: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="openday" {...props} />
}

export const PdfTemplateDigitalMission: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="digitalmission" {...props} />
}

export const PdfTemplateDigitalMission2021: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="digitalMission2021" {...props} />
}

export const PdfTemplateDigitalMission2022: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="digitalMission2022" {...props} />
}

export const PdfTemplateDigitalMission2023: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="digitalMission2023" {...props} />
}

export const PdfTemplateDigitalMission2024: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="digitalMission2024" {...props} />
}

export const PdfTemplateScoolerMission: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="scoolerMission" {...props} />
}

export const PdfTemplateWorkshop3d: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateWorkshop variant="workshop3d" {...props}></PdfTemplateWorkshop>
}

export const PdfTemplate3d: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="workshop3d" {...props}></TemplateBase>
}

export const PdfTemplateWorkshopApp: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateWorkshop variant="workshopApp" {...props}></PdfTemplateWorkshop>
}

export const PdfTemplateWorkshopIllustration: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateWorkshop variant="workshopIllustration" {...props}></PdfTemplateWorkshop>
}

export const PdfTemplateWorkshopMinecraft: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateWorkshop variant="workshopMinecraft" {...props}></PdfTemplateWorkshop>
}

export const PdfTemplateWorkshopImagicharm: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateWorkshop variant="workshopImagicharm" {...props}></PdfTemplateWorkshop>
}

export const PdfTemplateApp: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="workshopApp" {...props}></TemplateBase>
}

export const PdfTemplateIllustration: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="workshopIllustration" {...props}></TemplateBase>
}
export const PdfTemplateImagicharm: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="workshopImagicharm" {...props}></TemplateBase>
}

export const PdfTemplateCampChallengeDay: React.FC<PdfTemplateProps> = props => {
    return <PdfTemplateSpecial variant="campChallengeDay" {...props}></PdfTemplateSpecial>
}

export const PdfTemplateExplorerJuniorSpike: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="explorerJuniorSpike" {...props} />
}

export const PdfTemplateExplorerJuniorWedo: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="explorerJuniorWedo" {...props} />
}

export const PdfTemplateExplorerJuniorBeebot: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="explorerJuniorBeebot" {...props} />
}

export const PdfTemplateExplorerCreative: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="explorerCreative" {...props} />
}

export const PdfTemplateExplorerGamer: React.FC<PdfTemplateProps> = props => {
    return <TemplateBase variant="explorerGamer" {...props} />
}

const TemplateBase: React.FC<PdfTemplateProps & { variant: FrameType }> = ({
    translations,
    school,
    name,
    finishedAt,
    variant,
    locale,
    id,
}) => {
    const bottom = (() => {
        if (variant === 'blox') {
            return '75mm'
        }
        if (variant === 'aiBasic' || variant === 'aiPython') {
            return '90mm'
        }
        return '85mm'
    })()
    return ReactDOM.createPortal(
        <div className="templateWrap">
            <div id={id} className="templateRoot" style={{ fontFamily: getFontFamily(locale) }}>
                <img className="templateFrame" src={getFrameSrc(variant, locale)} alt="" />
                <div className="templateText">
                    <div
                        className="templateName"
                        style={{ width: variant === 'blox' ? '260mm' : '400mm', ...nameStyles(name) }}
                    >
                        {name}
                    </div>
                    <div
                        className="templateBlurp"
                        dangerouslySetInnerHTML={{ __html: translations.blurb }}
                        style={{ marginTop: variant === 'aiPython' ? '20mm' : undefined }}
                    ></div>
                    <div className="templateListWrap">
                        <img src={`/certificate/lsc_line.png`} alt="lsc_line" className="templateListLine" />
                        <div className="templateList">
                            <div>{translations.duringThisCourse}</div>
                            <ul dangerouslySetInnerHTML={{ __html: translations.items }}></ul>
                        </div>
                        <img
                            src={`/certificate/lsc_line.png`}
                            alt="lsc_line"
                            className="templateListLine"
                            style={{ marginTop: '10mm' }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        bottom: bottom,
                        ...dateStyles(school, finishedAt),
                    }}
                    className="templateDate"
                >
                    {school}, {finishedAt}
                </div>
            </div>
        </div>,
        document.body,
    )
}

const TemplateBaseText: React.FC<
    PdfTemplateProps & { variant: 'unity' | 'godot' | 'roblox' | 'python' | 'devMaster' }
> = ({ translations, school, name, finishedAt, variant, locale, id }) => {
    return ReactDOM.createPortal(
        <div className="templateWrap">
            <div
                id={id}
                className="templateRoot"
                style={{ color: variant === 'roblox' ? '#004069' : '#030402', fontFamily: getFontFamily(locale) }}
            >
                <img
                    className="templateFrame"
                    src={getFrameSrc(variant === 'roblox' ? 'roblox' : 'text', locale)}
                    alt=""
                />
                <div className="templateText" style={{ top: variant === 'roblox' ? '180mm' : '160mm' }}>
                    <div className="templateName" style={nameStyles(name)}>
                        {name}
                    </div>
                    <div
                        className="templateBlurp"
                        style={{ marginTop: '10pt' }}
                        dangerouslySetInnerHTML={{ __html: translations.blurb }}
                    ></div>
                    <img src={`/certificate/${variant}.png`} style={{ height: '30mm', marginTop: '10mm' }} alt="" />
                    <div className="templateListWrap">
                        <img src={`/certificate/lsc_line.png`} alt="lsc_line" className="templateListLine" />
                        <div className="templateList">
                            <div>{translations.duringThisCourse}</div>
                            <ul dangerouslySetInnerHTML={{ __html: translations.items }}></ul>
                        </div>
                        <img
                            src={`/certificate/lsc_line.png`}
                            alt="lsc_line"
                            className="templateListLine"
                            style={{ marginTop: '10mm' }}
                        />
                    </div>
                </div>
                <div
                    style={{ bottom: variant === 'roblox' ? '75mm' : '85mm', ...dateStyles(school, finishedAt) }}
                    className="templateDate"
                >
                    {school}, {finishedAt}
                </div>
            </div>
        </div>,
        document.body,
    )
}

export const PdfTemplateA0: React.FC<PdfTemplateProps> = ({ translations, finishedAt, school, name, locale, id }) => {
    return ReactDOM.createPortal(
        <div className="templateWrap">
            <div id={id} className="templateRoot" style={{ fontFamily: getFontFamily(locale) }}>
                <img className="templateFrame" src={getFrameSrc('a0', locale)} alt="" />
                <div style={{ top: '140mm' }} className="templateText">
                    <div className="templateName" style={nameStyles(name)}>
                        {name}
                    </div>
                    <div className="templateBlurp" dangerouslySetInnerHTML={{ __html: translations.blurb }}></div>
                    <div className="templateListWrap">
                        <img src={`/certificate/lsc_line.png`} alt="lsc_line" className="templateListLine" />
                        <div className="templateList">
                            <div>{translations.duringThisCourse}</div>
                            <ul dangerouslySetInnerHTML={{ __html: translations.items }}></ul>
                        </div>
                        <img
                            src={`/certificate/lsc_line.png`}
                            alt="lsc_line"
                            className="templateListLine"
                            style={{ marginTop: '10mm' }}
                        />
                    </div>
                </div>
                <div
                    className="templateDate"
                    style={{ left: '210mm', width: 'auto', bottom: '100mm', ...dateStyles(school, finishedAt) }}
                >
                    {school}, <div>{finishedAt}</div>
                </div>
            </div>
        </div>,
        document.body,
    )
}

export const PdfTemplateKodu: React.FC<PdfTemplateProps> = ({ translations, finishedAt, school, name, locale, id }) => {
    return ReactDOM.createPortal(
        <div className="templateWrap">
            <div id={id} className="templateRoot" style={{ fontFamily: getFontFamily(locale) }}>
                <img className="templateFrame" src={getFrameSrc('kodu', locale)} alt="" />
                <div style={{ top: '140mm' }} className="templateText">
                    <div className="templateName" style={nameStyles(name)}>
                        {name}
                    </div>
                    <div className="templateBlurp" dangerouslySetInnerHTML={{ __html: translations.blurb }}></div>
                    <div className="templateListWrap">
                        <img src={`/certificate/lsc_line.png`} alt="lsc_line" className="templateListLine" />
                        <div className="templateList">
                            <div>{translations.duringThisCourse}</div>
                            <ul dangerouslySetInnerHTML={{ __html: translations.items }}></ul>
                        </div>
                        <img
                            src={`/certificate/lsc_line.png`}
                            alt="lsc_line"
                            className="templateListLine"
                            style={{ marginTop: '10mm' }}
                        />
                    </div>
                </div>
                <div
                    className="templateDate"
                    style={{ left: '210mm', width: 'auto', bottom: '100mm', ...dateStyles(school, finishedAt) }}
                >
                    {school}, <div>{finishedAt}</div>
                </div>
            </div>
        </div>,
        document.body,
    )
}

export const PdfTemplateBizniscool: React.FC<PdfTemplateProps> = ({
    translations,
    school,
    name,
    finishedAt,
    locale,
    id,
}) => {
    const t = useTranslate()

    const blurb = t('certificate::blurb::bizniscool', { name })

    return ReactDOM.createPortal(
        <div className="templateWrap">
            <div id={id} className="templateRoot" style={{ fontFamily: getFontFamily(locale) }}>
                <img className="templateFrame" src={getFrameSrc('bizniscool', locale)} alt="" />
                <div className="templateText" style={{ top: '115mm' }}>
                    <div
                        className={`templateBlurp bizniscoolBlurb ${name.length > 22 && 'bizniscoolBlurbLongName'}`}
                        dangerouslySetInnerHTML={{ __html: blurb }}
                    ></div>
                    <div className="templateListWrap">
                        <img src={`/certificate/lsc_line.png`} alt="lsc_line" className="templateListLine" />
                        <div className="templateList">
                            <div>{translations.duringThisCourse}</div>
                            <ul dangerouslySetInnerHTML={{ __html: translations.items }}></ul>
                        </div>
                        <img
                            src={`/certificate/lsc_line.png`}
                            alt="lsc_line"
                            className="templateListLine"
                            style={{ marginTop: '10mm' }}
                        />
                    </div>
                </div>
                <div className="templateDate" style={dateStyles(school, finishedAt)}>
                    {school}, {finishedAt}
                </div>
            </div>
        </div>,
        document.body,
    )
}

export const PdfTemplateSpecial: React.FC<
    PdfTemplateProps & {
        variant:
            | 'openday'
            | 'digitalmission'
            | 'digitalMission2021'
            | 'digitalMission2022'
            | 'digitalMission2023'
            | 'digitalMission2024'
            | 'scoolerMission'
            | 'campChallengeDay'
    }
> = ({ translations, finishedAt, school, variant, name, locale, id }) => {
    const t = useTranslate()

    const isWinnerDetails = useSelector(isDmWinner)

    const blurb =
        variant === 'digitalmission' && isWinnerDetails
            ? t(`certificate::blurb::digitalmissionWinners::${isWinnerDetails.metadata.prize}` as LocalisationKey)
            : translations.blurb

    return ReactDOM.createPortal(
        <div className="templateWrap">
            <div
                id={id}
                className="templateRoot"
                style={{ color: variant === 'openday' ? 'black' : undefined, fontFamily: getFontFamily(locale) }}
            >
                <img
                    className="templateFrame"
                    src={getFrameSrc(variant === 'digitalmission' ? 'digitalmissionWinners' : variant, locale)}
                    alt=""
                />
                <div style={{ top: variant === 'openday' ? '210mm' : '240mm' }} className="templateText">
                    <div className="templateName" style={nameStyles(name)}>
                        {name}
                    </div>
                    <div
                        style={{ marginTop: '15pt', maxWidth: '250mm' }}
                        className="templateBlurp"
                        dangerouslySetInnerHTML={{ __html: blurb }}
                    ></div>
                </div>
                {variant === 'openday' && (
                    <div
                        className="templateDate"
                        style={{ bottom: '170mm', color: 'black', ...dateStyles(school, finishedAt) }}
                    >
                        {finishedAt}
                    </div>
                )}
                {!isWinnerDetails && variant === 'digitalmission' && (
                    <div className="templateDate" style={{ bottom: '150mm', fontSize: '40pt' }}>
                        Logiscool, {finishedAt}
                    </div>
                )}
            </div>
        </div>,
        document.body,
    )
}

const PdfTemplateWorkshop: React.FC<PdfTemplateProps & { variant: FrameType }> = ({
    translations,
    school,
    name,
    finishedAt,
    variant,
    locale,
    id,
}) => {
    return ReactDOM.createPortal(
        <div className="templateWrap">
            <div id={id} className="templateRoot" style={{ fontFamily: getFontFamily(locale) }}>
                <img className="templateFrame" src={getFrameSrc(variant, locale)} alt="" />
                <div className="templateText" style={{ top: '180mm' }}>
                    <div className="templateName" style={nameStyles(name)}>
                        {name}
                    </div>
                    <div
                        className="templateBlurp"
                        style={{ maxWidth: '240mm' }}
                        dangerouslySetInnerHTML={{ __html: translations.blurb }}
                    ></div>
                    <div className="templateListWrap" style={{ marginTop: '20mm' }}>
                        <img src={`/certificate/lsc_line.png`} alt="lsc_line" className="templateListLine" />
                        <div className="templateList">
                            <div>{translations.duringThisWorkshop}</div>
                            <ul dangerouslySetInnerHTML={{ __html: translations.items }}></ul>
                        </div>
                        <img
                            src={`/certificate/lsc_line.png`}
                            alt="lsc_line"
                            className="templateListLine"
                            style={{ marginTop: '10mm' }}
                        />
                    </div>
                </div>
                <div className="templateDate" style={dateStyles(school, finishedAt)}>
                    {school}, {finishedAt}
                </div>
            </div>
        </div>,
        document.body,
    )
}

export const dateStyles = (school: string, finishedAt: string): React.CSSProperties => {
    const isLong = school.length + finishedAt.length > 38
    return {
        fontSize: isLong ? '32pt' : '40pt',
    }
}

export const nameStyles = (name: string): React.CSSProperties => {
    const isLong = name.length > 22
    return {
        fontSize: isLong ? '55pt' : '74pt',
    }
}

export const getFontFamily = (locale: string) => {
    try {
        if (new Intl.Locale(locale).language === 'bg') {
            return 'Roboto, sans-serif'
        }
    } catch (e) {
        console.error(e)
    }
    return 'Raleway, sans-serif'
}

type FrameType =
    | 'blox'
    | 'mix'
    | 'a0'
    | 'text'
    | 'robot'
    | 'robotPrime'
    | 'video'
    | 'design'
    | 'minecraft'
    | 'kodu'
    | 'ai'
    | 'aiPython'
    | 'aiBasic'
    | 'rpg'
    | 'bizniscool'
    | 'roblox'
    | 'openday'
    | 'digitalmission'
    | 'digitalmissionWinners'
    | 'workshop3d'
    | 'workshopApp'
    | 'workshopIllustration'
    | 'workshopMinecraft'
    | 'digitalDiscovery'
    | 'digitalMission2021'
    | 'digitalMission2022'
    | 'digitalMission2023'
    | 'digitalMission2024'
    | 'scoolerMission'
    | 'workshopImagicharm'
    | 'campChallengeDay'
    | 'explorerJuniorSpike'
    | 'explorerJuniorWedo'
    | 'explorerJuniorBeebot'
    | 'explorerCreative'
    | 'explorerGamer'

const getFrameSrc = (type: FrameType, locale: string) => {
    return `${api.baseUrl}/certificates/frame-image-for-kind?kind=${type}&locale=${locale}`
}

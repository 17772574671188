import { Reducer } from 'redux'
import { CAST_VOTE_REQUEST } from '../../constants'
import produce from 'immer'

export interface VoteState {
    phase: 'idle' | 'begin' | 'done' | 'error'
    target: string | null
}

export const voteReducer: Reducer<VoteState> = (state = { phase: 'idle', target: null }, action) => {
    switch (action.type) {
        case CAST_VOTE_REQUEST:
            return produce(state, draft => {
                if (action.payload && action.payload.target) {
                    draft.target = action.payload.target
                    draft.phase = 'begin'
                }
                if (action.payload && action.payload.status) {
                    draft.phase = action.payload.status
                    if (action.payload.status === 'done') {
                        //draft.target = action.payload.target
                    }
                }
            })
        default:
            return state
    }
}

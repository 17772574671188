import { Text } from 'components/Text'
import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { useAchievementCardContext } from './AchievementCard'

interface AchievementCardTitleProps {}

export const AchievementCardTitle: React.FC<AchievementCardTitleProps> = ({ children }) => {
    const { isAcquired } = useAchievementCardContext()
    return (
        <Root variant="h3" $isAcquired={isAcquired}>
            {children}
        </Root>
    )
}

const Root = styled(Text)<{ $isAcquired: boolean }>`
    margin-block-start: ${props => props.theme.spacing(4)};
    color: ${props =>
        props.$isAcquired
            ? props.theme.palette.text.primary
            : transparentize(props.theme.palette.type === 'light' ? 0.3 : 0.6, props.theme.palette.text.primary)};
`

import React, {useCallback} from "react";
import {runtimeEnv} from "../../../../utils/env";
import {FormControl, FormLabel, TextField} from "components/form";
import {Box} from "components/layout";
import {ImageZoom} from "components/ImageZoom";
import {ImageUploadButton} from "./ImageUploadButton";

export interface ImageFieldProps {
    label: string,
    value?: string,
    onChange?: (e: any, newValue: string) => any,
    width?: number,
    disabled?: boolean
}

export const ImageField: React.FC<ImageFieldProps & any> = ({ label, width, value, onChange, disabled, children, endAdornment, ...rest }) => {
    const id = value ? value.split('/')[4] : '';

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        const realValue = value ? value.replace(id, e.target.value) : `${runtimeEnv.REACT_APP_API_URL}/files/${e.target.value}/data`;
        if(onChange) onChange(e, realValue);
    }

    const handleUpload = useCallback((value: string) => {
        onChange(null, `${runtimeEnv.REACT_APP_API_URL}/files/${value}/data`);
    }, [ onChange ]);

    return (
        <FormControl fullWidth>
            <FormLabel>{label}</FormLabel>
            <Box margin={2}>
                <ImageZoom src={value} width={width || 300} />
            </Box>
            <TextField value={id} onChange={handleChange} disabled={disabled}
                       endAdornment={<>
                           <ImageUploadButton disabled={disabled} onUpload={handleUpload} />
                           {endAdornment}
                       </>}
                       {...rest} />
        </FormControl>
    )
}
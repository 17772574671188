import React from 'react'

interface CertificateRibbonProps {
    color: string
    highlightColor: string
    className?: string
}

export const CertificateRibbon: React.FC<CertificateRibbonProps> = ({ color, highlightColor, ...rest }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 121 261.32" xmlSpace="preserve" {...rest}>
            <path
                d="M108.5.5h-96C5.9.5.5 5.9.5 12.5v248l60-31.24 60 31.24v-248c0-6.6-5.4-12-12-12z"
                fill={color}
                stroke={color}
            />
            <path
                fill={highlightColor}
                d="M120.5 54.5V30.05L.5 110.28v46.92l120-80.24zM120.5 100.41L.5 180.64v17.5l120-80.23z"
            />
        </svg>
    )
}

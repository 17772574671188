import { useUserActions, useUserState } from 'contexts/UserContext'
import { UserSettingKey } from 'lib/api/AccountsApi'
import { useEffect } from 'react'

export const useUpdateUserSettingOnChange = (key: UserSettingKey, nextValue: string) => {
    const { updateUserSettings } = useUserActions()
    const { settings } = useUserState()

    const currentValue = settings[key]

    useEffect(() => {
        if (currentValue !== nextValue) {
            updateUserSettings({ [key]: nextValue })
        }
    }, [key, currentValue, nextValue, updateUserSettings])
}

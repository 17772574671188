import { auth } from 'lib/auth'
import React, { Suspense } from 'react'
import { ErrorBoundary } from './ErrorBoundary'
import logiscoolLogo from 'media/images/logiscool-logo.png'
import { useTranslate } from 'lib/i18n/useTranslate'
import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
    body {
        background: #fff;
        color: #000;
        font-family: sans-serif;
    }
`

export const TopLevelErrorBoundary: React.FC = ({ children }) => {
    return (
        <ErrorBoundary
            fallback={
                <Suspense fallback={null}>
                    <Fallback />
                </Suspense>
            }
        >
            {children}
        </ErrorBoundary>
    )
}

const baseButtonStyles = {
    padding: '12px 20px',
    cursor: 'pointer',
    borderRadius: 10,
    fontWeight: 600,
    marginTop: 4,
}

// We can't use theming  here because this is rendered outside of providers.
const Fallback: React.FC = () => {
    const t = useTranslate()

    return (
        <>
            <GlobalStyles />
            <div style={{ textAlign: 'center', marginTop: 24 }}>
                <img style={{ maxWidth: 220 }} src={logiscoolLogo} alt="Logiscool" />
                <p style={{ marginTop: 16 }}>{t('error::somethingWentWrong')}</p>
                <button
                    style={{
                        color: '#fff',
                        background: '#1f9bde',
                        border: 'none',
                        ...baseButtonStyles,
                    }}
                    onClick={() => {
                        window.location.reload()
                    }}
                >
                    {t('general::refresh')}
                </button>
                <button
                    style={{
                        color: '#1f9bde',
                        background: 'none',
                        border: 'solid 1px #1f9bde',
                        marginLeft: 16,
                        ...baseButtonStyles,
                    }}
                    onClick={() => {
                        auth.logout()
                    }}
                >
                    {t('general::logout')}
                </button>
            </div>
        </>
    )
}

import { css } from 'styled-components/macro'
import { createTheme } from '../createTheme'

export const light1 = createTheme(
    { base: '#fafafa', aside: '#243070', primary: '#2878f0', asideContent: '#5160b0' },
    {
        palette: {
            background: {
                default: '#f5f5fb',
            },
            primary: {
                main: '#2878f0',
            },
            text: {
                disabled: '#a2a2a3',
            },
        },
        extras: {
            themeColorMeta: '#243070',
            logoType: 'white',

            pageHeader: {
                root: css`
                    background-color: #fff;
                `,
            },
            mobileHeader: {
                root: css`
                    background-color: #243070;
                    color: white;
                `,
            },
            pageAside: {
                paper: css`
                    background: linear-gradient(#243070, #242e6d);
                    color: white;
                    border-right: none;
                `,
                secondary: css`
                    color: #6d7ac2;
                `,
                listItemAndNotificationButton: css`
                    &:hover,
                    &:focus-visible {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    &.Mui-selected,
                    &.Mui-selected:hover {
                        color: #00bcff;
                    }
                `,
            },
        },
    },
)

import styled, { css } from 'styled-components/macro'
import { createTheme } from '../../createTheme'
import React from 'react'
import footerImg from './ninja-footer.png'

const FooterImage = styled.img`
    display: block;
    bottom: 0;
    max-width: 100%;
    height: auto;
`

export const ninja = createTheme(
    { base: '#00b3c2', aside: '#d3f5fe', primary: '#05557d', asideContent: '#555e66', baseContent: '#d3f5fe' },
    {
        palette: {
            type: 'light',
            background: {
                default: '#00b3c2',
                paper: '#d3f5fe',
            },
            divider: '#0497a3',
            primary: { main: '#05557d', contrastText: '#fff' },
        },
        extras: {
            logoType: 'default',
            themeColorMeta: '#00b3c2',
            pageHeader: {
                root: css`
                    background-color: #04c1d2;
                `,
                stickyRoot: css`
                    background-color: #04c1d2;
                `,
            },
            page: {
                footerImage: <FooterImage width="3645" height="1200" src={footerImg} />,
            },
            pageAside: {
                dividers: css`
                    background-color: #aecfd8;
                `,
            },
            dialog: {
                backdrop: css`
                    background: rgb(34 44 52 / 87%);
                `,
                projectDialogPaper: css`
                    background: #06090f;
                `,
            },
            actionablePaper: {
                root: css`
                    &:hover,
                    &:focus-visible {
                        background-color: ${props => props.theme.palette.background.paper};
                        filter: brightness(0.9);
                    }
                `,
            },
        },
    },
    ['content.scooler-mission-2022-themes', 'content.scooler-mission-2022-themes-ro'],
)

import React from 'react'
import { CommunityProjectDetails, LimitedCommunityProjectDetails, ProjectModalBase } from 'features/projects'
import { LimitedProjectModalContent } from 'features/limited-accounts'
import { useHasGrant } from 'lib/grants'

export const LimitedCommunityProjectDetailsPage = () => {
    const hasGrant = useHasGrant()
    const canViewProject = hasGrant('limited-app.view-community-projects')

    return (
        <ProjectModalBase
            embedOwnerInFileRequest={true}
            projectVariant={project => {
                if (project.file.shared) {
                    return 'production'
                }
                return 'normal'
            }}
            isNotFound={() => false}
            renderMainContent={canViewProject ? undefined : id => <LimitedProjectModalContent id={id} />}
        >
            {canViewProject ? <CommunityProjectDetails /> : <LimitedCommunityProjectDetails />}
        </ProjectModalBase>
    )
}

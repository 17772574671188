import { Button } from 'components/Button'
import { RestoreIcon } from 'components/icons'
import dayjs from 'dayjs'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import {
    createProjectThumbnailUrl,
    Project,
    ProjectCard,
    ProjectCardBadge,
    ProjectCardButtonBadge,
    ProjectCardContent,
    ProjectCardImage,
} from '..'
import { ProjectStripe } from '../stripe'
import { useProjectActions } from '../useProjectActions'

interface MyDeletedProjectCardProps {
    project: Project
    displayType: 'grid' | 'list'
}

export const MyDeletedProjectCard: React.FC<MyDeletedProjectCardProps> = ({ project, displayType }) => {
    const t = useTranslate()
    const { restoreProject } = useProjectActions()
    const remainingDays = dayjs(project.file.deletedAt).diff(dayjs(), 'days') + 30
    const title = project.file.name
    const thumbnailUrl = createProjectThumbnailUrl(project.file.id, 'normal')

    if (displayType === 'list') {
        return (
            <ProjectStripe
                thumbnailUrl={thumbnailUrl}
                metadata={
                    <>
                        {project.file.deletedAt && (
                            <span>{t('general::nDaysRemaining', { count: remainingDays })}</span>
                        )}
                    </>
                }
                title={title}
                actions={
                    <>
                        <Button
                            size="small"
                            startIcon={<RestoreIcon />}
                            onClick={() => {
                                restoreProject(project.file.id)
                            }}
                        >
                            {t('general::restore')}
                        </Button>
                    </>
                }
            />
        )
    }

    return (
        <ProjectCard key={project.file.id}>
            <ProjectCardImage
                src={thumbnailUrl}
                badges={
                    <>
                        {project.file.deletedAt && (
                            <ProjectCardBadge>
                                {t('general::nDaysRemaining', { count: remainingDays })}
                            </ProjectCardBadge>
                        )}
                        <ProjectCardButtonBadge
                            size="small"
                            startIcon={<RestoreIcon />}
                            position="top-right"
                            onClick={() => {
                                restoreProject(project.file.id)
                            }}
                        >
                            {t('general::restore')}
                        </ProjectCardButtonBadge>
                    </>
                }
            />
            <ProjectCardContent title={{ label: title }} />
        </ProjectCard>
    )
}

import { DynamicEventDefinition, EventDefinition } from '../types/eventTypes'
import drawingContestBannerImg from './media/drawing-contest-2020/images/drawing-contest-banner.jpg'
import drawingContest2020ThumbnailImg from './media/drawing-contest-2020/images/drawing-contest-2020-thumbnail.jpg'
import drawingContest2019ThumbnailImg from './media/drawing-contest-2019/images/drawing-contest-2019-thumbnail.jpg'
import digitalMissionBannerImg from '../pages/digital-misson-2020/images/digital-mission-banner.jpg'
import digitalMission2021BannerImg from '../pages/digital-mission-2021/images/digital-mission-banner.png'
import digitalMission2021ThumbnailImg from '../pages/digital-mission-2021/images/thumbnail.jpg'
import digitalMissionThumbnailImg from '../pages/digital-misson-2020/images/digital-mission-thumbnail.jpg'
import digigirlz2020BannerImg from '../pages/digigirlz-2020/images/digigirlz-banner.jpg'
import digigirlz2020ThmubnailImg from '../pages/digigirlz-2020/images/thumbnail.jpg'
import trainerContest2020BannerImg from '../pages/trainer-contest-2020/images/trainer-contest-2020-banner.jpg'
import trainerContest2020ThumbnailImg from '../pages/trainer-contest-2020/images/trainer-contest-2020-thumbnail.jpg'
import trainerContest2021ThumbnailImg from '../pages/trainer-contest-2021/images/trainer-contest-2021-thumbnail.png'
import trainerContest2021BannerImg from '../pages/trainer-contest-2021/images/trainer-contest-2021-banner.png'
import onBoardingBannerImg from '../pages/onboarding/media/onboarding-banner.jpg'
import onBoardingThumbnailImg from '../pages/onboarding/media/onboarding-thumbnail.jpg'
import drawingContest2021Img from './media/drawing-contest-2021/images/drawingContest2021.jpg'
import drawingContest2022Img from './media/drawing-contest-2022/images/drawing-contest-2022-banner.jpg'
import drawingContest2023Img from './media/drawing-contest-2023/images/drawing-contest-2023-banner.jpg'
import drawingContest2024Img from './media/drawing-contest-2024/drawing-contest-2024-banner.jpg'
import drawingContest2023ThumbnailImg from './media/drawing-contest-2023/images/drawing-contest-2023-thumbnail.png'
import drawingContest2022ThumbnailImg from './media/drawing-contest-2022/images/drawing-contest-2022-thumbnail.jpg'
import drawingContest2021ThumbnailImg from './media/drawing-contest-2021/images/drc2021thumb.jpg'

import { ONBOARDING_ENABLED } from '../pages/onboarding/data/onboardingData'
import { isGuestOrRegisteredGuestUser } from 'contexts/UserContext'
import { getCampChallengeData } from '../camp-challenge/data/campChallengeData'
import dayjs from 'dayjs'
import { DynamicManagableEventDefinition, ManagableEventDefinition } from '../types/eventTypesV2'
import { images as images2023 } from './media/drawing-contest-2023/images'
import { images as images2022 } from './media/drawing-contest-2022/images'
import { images as images2021 } from './media/drawing-contest-2021/images'
import { images as images2020 } from './media/drawing-contest-2020/images'
import { images as images2019 } from './media/drawing-contest-2019/images'
import { createEnvironmentVariable } from '../../../utils/env'
import { EventsCampaigns } from './EventsCampaign'

const DRAWING_CONTEST_2021_VOTE_CAMPAIGN_ID = createEnvironmentVariable(EventsCampaigns['DRAWING_CONTEST_2021'])
const DRAWING_CONTEST_2020_VOTE_CAMPAIGN_ID = createEnvironmentVariable(EventsCampaigns['DRAWING_CONTEST_2020'])
const DRAWING_CONTEST_2019_VOTE_CAMPAIGN_ID = createEnvironmentVariable(EventsCampaigns['DRAWING_CONTEST_2019'])

export const eventsV2: (ManagableEventDefinition | DynamicManagableEventDefinition)[] = [
    {
        slug: 'drawing-contest-2024',
        title: 'drawingContest2024::name',
        ongoing: true,
        startDate: '2024-10-07',
        thumbnail: drawingContest2023ThumbnailImg,
        detailsBanner: drawingContest2024Img,
        banner: {
            text: 'drawingContest2024::shortname',
            image: drawingContest2024Img,
        },
        template: 'drawing-contest',
        templateData: {
            winnersDescription: 'drawingContest2024Summary::description',
            votingDescription: 'DrawingContest2022NewsfeedPost05::description',
            detailsDescription: 'drawingContest2023::description01',
            campaignId: '66fdafc3ff66bcce21aaf607',
            votingEndDate: dayjs('2024-12-16T00:00:00Z'),
            resultImages: images2023,
        },
        state: [
            {
                state: 'preparation',
                title: 'Preparation',
                until: dayjs('2024-12-02'),
            },
            {
                state: 'voting',
                title: 'Voting',
                until: dayjs('2024-12-16'),
            },
            {
                state: 'preparation',
                title: 'Waiting for results',
                until: dayjs('2024-12-17'),
            },
            {
                state: 'winners',
                title: 'Winners',
            },
        ],
        timeline: {
            items: [
                {
                    title: 'drawingContest2024::timeline01',
                    startDate: dayjs('2024-10-07'),
                    endDate: dayjs('2024-10-27'),
                },
                {
                    title: 'drawingContest2024::timeline02',
                    startDate: dayjs('2024-10-30'),
                    endDate: dayjs('2024-11-13'),
                },
                {
                    title: 'drawingContest2022::timeline03',
                    startDate: dayjs('2024-11-14'),
                    endDate: dayjs('2024-11-27'),
                },
                {
                    title: 'drawingContest2024::timeline04',
                    startDate: dayjs('2024-12-02'),
                    endDate: dayjs('2024-12-15'),
                },
                {
                    title: 'drawingContest2024::timeline05',
                    startDate: dayjs('2024-12-16'),
                    endDate: dayjs('2024-12-17'),
                },
                {
                    title: 'drawingContest2022::timeline06',
                    startDate: dayjs('2024-12-18'),
                    endDate: dayjs('2025-03-01'),
                },
                {
                    title: 'drawingContest2024::timeline07',
                    startDate: dayjs('2025-03-02'),
                    endDate: dayjs('2025-03-02'),
                },
            ],
        },
    },
    {
        slug: 'drawing-contest-2023',
        title: 'drawingContest2023::name',
        ongoing: false,
        startDate: '2023-10-09',
        thumbnail: drawingContest2023ThumbnailImg,
        detailsBanner: drawingContest2023Img,
        banner: {
            text: 'drawingContest2023::shortname',
            image: drawingContest2023Img,
        },
        template: 'drawing-contest',
        templateData: {
            winnersDescription: 'drawingContest2023Summary::description',
            votingDescription: 'DrawingContest2022NewsfeedPost05::description',
            detailsDescription: 'drawingContest2023::description01',
            campaignId: '6519d4c448ba95d029faa1f1',
            votingEndDate: dayjs('2023-12-14T00:00:00Z'),
            resultImages: images2023,
        },
        state: [
            {
                state: 'preparation',
                title: 'Preparation',
                until: dayjs('2023-12-06'),
            },
            {
                state: 'voting',
                title: 'Voting',
                until: dayjs('2023-12-14'),
            },
            {
                state: 'preparation',
                title: 'Waiting for results',
                until: dayjs('2023-12-15'),
            },
            {
                state: 'winners',
                title: 'Winners',
            },
        ],
        timeline: {
            items: [
                {
                    title: 'drawingContest2023::timeline01',
                    startDate: dayjs('2023-10-09'),
                    endDate: dayjs('2023-10-27'),
                },
                {
                    title: 'drawingContest2023::timeline02',
                    startDate: dayjs('2023-10-27'),
                    endDate: dayjs('2023-11-13'),
                },
                {
                    title: 'drawingContest2022::timeline03',
                    startDate: dayjs('2023-11-14'),
                    endDate: dayjs('2023-12-05'),
                },
                {
                    title: 'drawingContest2023::timeline04',
                    startDate: dayjs('2023-12-06'),
                    endDate: dayjs('2023-12-13'),
                },
                {
                    title: 'drawingContest2023::timeline05',
                    startDate: dayjs('2023-12-14'),
                    endDate: dayjs('2023-12-15'),
                },
                {
                    title: 'drawingContest2022::timeline06',
                    startDate: dayjs('2023-12-16'),
                    endDate: dayjs('2024-02-01'),
                },
                {
                    title: 'drawingContest2023::timeline07',
                    startDate: dayjs('2024-02-02'),
                    endDate: dayjs('2024-02-02'),
                },
            ],
        },
    },
    {
        slug: 'drawing-contest-2022',
        startDate: '2022-10-09',
        title: 'drawingContest2022::name',
        ongoing: false,
        thumbnail: drawingContest2022ThumbnailImg,
        detailsBanner: drawingContest2022Img,
        banner: {
            text: 'drawingContest2022::shortname',
            image: drawingContest2022Img,
        },
        template: 'drawing-contest',
        templateData: {
            winnersDescription: 'drawingContest2022Summary::description',
            campaignId: '63888df8968834a04212e798',
            votingEndDate: dayjs('2022-12-16T07:00:00Z'),
            resultImages: images2022,
        },
        state: [
            {
                state: 'winners',
                title: 'Winners',
            },
        ],
        timeline: {
            items: [
                {
                    title: 'drawingContest2022::timeline01',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2022::timeline02',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2022::timeline03',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2022::timeline04',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2022::timeline05',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2022::timeline06',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2022::timeline07',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
            ],
        },
    },
    {
        slug: 'drawing-contest-2021',
        startDate: '2021-10-09',
        title: 'drawingContest2021::name',
        ongoing: false,
        thumbnail: drawingContest2021ThumbnailImg,
        detailsBanner: drawingContest2021Img,
        banner: {
            image: drawingContest2021Img,
        },
        template: 'drawing-contest',
        templateData: {
            winnersDescription: 'drawingContest2021Summary::description',
            winnersDetails: 'drawingcontest2021NewCharactersClosing::description',
            campaignId: DRAWING_CONTEST_2021_VOTE_CAMPAIGN_ID,
            votingEndDate: dayjs('2022-12-16T07:00:00Z'),
            resultImages: images2021,
        },
        state: [
            {
                state: 'winners',
                title: 'Winners',
            },
        ],
        timeline: {
            items: [
                {
                    title: 'drawingContest2021::timeline01',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2021::timeline02',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2021::timeline03',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2021::timeline04',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2021::timeline05',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2021::timeline06',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2021::timeline07',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
            ],
        },
    },
    {
        slug: 'drawing-contest-2020',
        startDate: '2020-10-09',
        title: 'drawingContest2020::name',
        ongoing: false,
        thumbnail: drawingContest2020ThumbnailImg,
        detailsBanner: drawingContestBannerImg,
        template: 'drawing-contest',
        templateData: {
            winnersDescription: 'drawingContest2020Summary::description',
            winnersDetails: 'drawingcontest2020NewCharacters::description',
            campaignId: DRAWING_CONTEST_2020_VOTE_CAMPAIGN_ID,
            votingEndDate: dayjs('2022-12-16T07:00:00Z'),
            resultImages: images2020,
        },
        state: [
            {
                state: 'winners',
                title: 'Winners',
            },
        ],
        timeline: {
            items: [
                {
                    title: 'drawingContest2020Summary::timeline01',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline02',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline03',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline04',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline05',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline06',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline07',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
            ],
        },
    },
    {
        slug: 'drawing-contest-2019',
        startDate: '2019-10-09',
        title: 'drawingContest2019::name',
        ongoing: false,
        thumbnail: drawingContest2019ThumbnailImg,
        detailsBanner: drawingContestBannerImg,
        template: 'drawing-contest',
        templateData: {
            winnersDescription: 'drawingContest2019Summary::description',
            winnersDetails: 'drawingcontest2020NewCharacters::description',
            campaignId: DRAWING_CONTEST_2019_VOTE_CAMPAIGN_ID,
            votingEndDate: dayjs('2022-12-16T07:00:00Z'),
            resultImages: images2019,
        },
        state: [
            {
                state: 'winners',
                title: 'Winners',
            },
        ],
        timeline: {
            items: [
                {
                    title: 'drawingContest2020Summary::timeline01',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline02',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline03',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline04',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline05',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline06',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
                {
                    title: 'drawingContest2020Summary::timeline07',
                    startDate: dayjs('2022-01-01'),
                    endDate: dayjs('2022-01-01'),
                },
            ],
        },
    },
]

export const events: (EventDefinition | DynamicEventDefinition)[] = [
    user => {
        const data = getCampChallengeData(user, 2023)

        if (!data) {
            return null
        }

        return {
            slug: 'camp-challenge-2023',
            title: 'campPromoMission2023::pageTitle',
            banner: {
                text: 'campPromoMission2023::pageTitle',
                image: 'https://api.prod.scoolcode.com/files/64537ec44bec3ad17f768241/data',
                background: 'linear-gradient(to right, rgb(51, 110, 166), rgba(51, 110, 166, 0))',
            },
            ongoing: dayjs(data.endDate).isAfter(dayjs()),
            thumbnail: 'https://api.dev.scoolcode.com/files/64538769e1fa64f959ea6ce0/data',
            startDate: data.startDate,
            filters: {
                grant: { product: 'edup.universe', feature: 'content.camp-challenge-2023' },
            },
        }
    },

    user => ({
        slug: 'mylogiscool-mission',
        title: 'OnboardingMission::title',
        ongoing: isGuestOrRegisteredGuestUser(user),
        thumbnail: onBoardingThumbnailImg,
        banner: {
            image: onBoardingBannerImg,
            text: 'OnboardingMission::title',
        },
        enabled: ONBOARDING_ENABLED,
        filters: {
            grant: { product: 'edup.universe', feature: 'content.onboarding-event' },
        },
        hideBreadCrumb: true,
    }),
    {
        slug: 'digital-mission-2021',
        title: 'Digital Mission 2021',
        ongoing: false,
        thumbnail: digitalMission2021ThumbnailImg,
        detailsBanner: digitalMission2021BannerImg,
        banner: {
            image: digitalMission2021BannerImg,
        },
    },
    {
        slug: 'trainer-contest-2021',
        title: 'trainerContest2021::title',
        ongoing: false,
        thumbnail: trainerContest2021ThumbnailImg,
        detailsBanner: trainerContest2021BannerImg,
        banner: {
            image: trainerContest2021BannerImg,
        },
        timeline: {
            items: [
                { title: 'trainerContest2021::Timeline01', status: 'past' },
                { title: 'trainerContest2021::Timeline02', status: 'past' },
                {
                    title: 'trainerContest2021::Timeline03',
                    status: 'past',
                },
                {
                    title: 'trainerContest2021::Timeline04',
                    status: 'past',
                },
            ],
        },
    },
    {
        slug: 'digigirlz-2020',
        title: 'DigiGirlz',
        ongoing: false,
        thumbnail: digigirlz2020ThmubnailImg,
        detailsBanner: digigirlz2020BannerImg,
        timeline: {
            items: [
                {
                    status: 'past',
                    title: 'Elérhetővé válik a MyLogiscool-on az első forduló 3 kvíze a mesterséges intelligencia és a fenntarthatóság témakörében.',
                },
                {
                    status: 'past',
                    title: 'Első forduló lezárása, továbbjutók kiválasztása. Továbbjutók: akik mind a 3 kvízt kitöltötték, és legalább a kérdések harmadára jól válaszoltak.',
                },
                {
                    status: 'past',
                    title: 'A továbbjutók meghívást kapnak a második fordulóba, ahol egy olyan ötletet, találmányt kell benyújtaniuk pályamunka formájában 2021. május 2-ig, melyben bemutatják, miként tudja a mesterséges intelligencia segíteni a gyermekek életét.',
                },
                {
                    status: 'past',
                    title: 'Pályaművek zsűrizése: mindkét korcsoport 3 legjobbját és az összes pályamunkát benyújtó közül a 30 legjobbat választják ki. Ők kapnak értékes ajándékcsomagot.',
                },
                {
                    status: 'past',
                    title: 'Online élménynap a második fordulóba bejutottak számára 2021. május 29-én, ennek keretében történik a hivatalos eredményhirdetés. ',
                },
            ],
        },
        filters: {
            countries: ['HU'],
            gender: 'f',
        },
    },
    {
        slug: 'drawing-contest-2020',
        title: 'drawingContest2020::name',
        ongoing: false,
        thumbnail: drawingContest2020ThumbnailImg,
        detailsBanner: drawingContestBannerImg,
        timeline: {
            items: [
                { title: 'drawingContest2020Summary::timeline01', status: 'past' },
                { title: 'drawingContest2020Summary::timeline02', status: 'past' },
                {
                    title: 'drawingContest2020Summary::timeline03',
                    status: 'past',
                },
                {
                    title: 'drawingContest2020Summary::timeline04',
                    status: 'past',
                },
                { title: 'drawingContest2020Summary::timeline05', status: 'past' },
                {
                    title: 'drawingContest2020Summary::timeline06',
                    status: 'past',
                },
                {
                    title: 'drawingContest2020Summary::timeline07',
                    status: 'past',
                },
            ],
        },
    },
    {
        slug: 'digital-mission-2020',
        title: 'digitalMission2020::title',
        ongoing: false,
        thumbnail: digitalMissionThumbnailImg,
        detailsBanner: digitalMissionBannerImg,
    },
    {
        slug: 'trainer-contest-2020',
        title: 'trainerContest2020::title',
        ongoing: false,
        thumbnail: trainerContest2020ThumbnailImg,
        detailsBanner: trainerContest2020BannerImg,
        timeline: {
            items: [
                { title: 'trainerContest::Timeline01', status: 'past' },
                { title: 'trainerContest::Timeline02', status: 'past' },
                {
                    title: 'trainerContest::Timeline03',
                    status: 'past',
                },
            ],
        },
    },
]

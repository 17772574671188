import { ErrorBoundary } from 'components/ErrorBoundary'
import { Stack } from 'components/layout'
import { Skeleton } from 'components/Skeleton'
import React from 'react'
import { ProjectModalContent } from '../modal/ProjectModalContent'
import { useProjectModalContext } from '../modal/projectModalContext'
import { ProjectModalError } from '../modal/ProjectModalError'
import { ProjectModalHeader } from '../modal/ProjectModalHeader'
import { useClassroomProjectDetailsQuery } from '../projectQueries'
import { ProjectDetailsActions } from './ProjectDetailsActions'
import { ProjectDetailsEditableDescription } from './ProjectDetailsEditableDescription'
import { ProjectDetailsLabels } from './ProjectDetailsLabels'
import { ProjectDetailsStats } from './ProjectDetailsStats'
import { ProjectDetailsThumbnail } from './ProjectDetailsThumbnail'
import { ProjectDetailsTitle } from './ProjectDetailsTitle'

export const ClassroomProjectDetails: React.FC = () => {
    const { project, error, id } = useProjectModalContext()
    const { status } = useClassroomProjectDetailsQuery(id, !!project)

    if (error) {
        return <ProjectModalError error={error} />
    }

    if (status === 'error') {
        return <ProjectModalError error="GENERAL" />
    }

    return (
        <>
            <ProjectModalHeader>
                <ProjectDetailsActions />
            </ProjectModalHeader>
            <ProjectModalContent>
                {!project || status === 'loading' ? (
                    <ClassroomProjectDetailsSkeleton />
                ) : (
                    <ErrorBoundary>
                        <Stack>
                            <ProjectDetailsTitle>{project.file.name}</ProjectDetailsTitle>
                            <ProjectDetailsEditableDescription />
                            <ProjectDetailsThumbnail />
                            <ProjectDetailsLabels />
                            <ProjectDetailsStats />
                        </Stack>
                    </ErrorBoundary>
                )}
            </ProjectModalContent>
        </>
    )
}

const ClassroomProjectDetailsSkeleton: React.FC = () => {
    return (
        <Stack>
            <Skeleton variant="rect" height="3rem" />
            <Skeleton variant="rect" height="3rem" />
            <Skeleton variant="rect" width="12rem" height="8rem" />
            <Skeleton variant="rect" height="1rem" />
            <Skeleton variant="rect" height="1rem" />
            <Skeleton variant="rect" height="1rem" />
            <Skeleton variant="rect" height="1rem" />
        </Stack>
    )
}

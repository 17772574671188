import React from 'react'
import { Tab as MuiTab, TabProps as MuiTabProps, TabTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import styled from 'styled-components/macro'
import { LockIcon } from 'components/icons'
import { Tooltip } from 'components/Tooltip'

type ExtraProps = {
    locked?: boolean
    tooltip?: string
}

export type TabProps = MuiTabProps<any, ExtraProps>

export const Tab: OverridableComponent<TabTypeMap<ExtraProps>> = ({
    locked,
    label,
    disabled,
    tooltip,
    ...props
}: TabProps) => {
    return (
        <Tooltip title={tooltip || ''}>
            <Root
                style={{ pointerEvents: 'auto' }}
                disabled={locked ? true : disabled}
                label={
                    <>
                        {locked ? (
                            <span>
                                <LockIcon /> {label}
                            </span>
                        ) : (
                            <span>{label}</span>
                        )}
                    </>
                }
                tabIndex={0}
                {...props}
            />
        </Tooltip>
    )
}

const Root = styled(MuiTab)`
    opacity: 1;
    min-width: auto;
    text-transform: none;
    font-weight: 600;
    transition: ${props =>
        props.theme.transitions.create('background-color', { duration: props.theme.transitions.duration.shortest })};
    border-radius: ${props => props.theme.shape.borderRadius}px ${props => props.theme.shape.borderRadius}px 0 0;
    &:focus-visible,
    &:hover {
        background-color: ${props => props.theme.palette.action.hover};
    }
    &.Mui-selected {
        color: ${props => props.theme.palette.primary.main};
    }
    &.Mui-disabled {
        opacity: 1;
        color: ${props => props.theme.palette.text.disabled};
    }
    & + * {
        margin: ${props => props.theme.spacing(0, 2)};
    }

    ${props => props.theme.extras?.tabs?.tab}
`

import { Text } from 'components/Text'
import { darken, transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { useAchievementCardContext } from './AchievementCard'

interface AchievementCardPointsProps {
    bgColor: string
}

export const AchievementCardPoints: React.FC<AchievementCardPointsProps> = ({ bgColor, children }) => {
    const { isAcquired } = useAchievementCardContext()

    return (
        <Root $isAcquired={isAcquired} $bgColor={bgColor}>
            {children}
        </Root>
    )
}

const Root = styled(Text)<{ $isAcquired: boolean; $bgColor: string }>`
    font-weight: 800;
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: ${props => props.theme.spacing(1, 4)};
    background-color: ${props =>
        props.$isAcquired
            ? props.theme.palette.type === 'light'
                ? darken(0.2, props.$bgColor)
                : props.theme.palette.primary.dark
            : props.theme.palette.background.paper};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    transform: translate(-50%, 50%);
    color: ${props =>
        props.$isAcquired
            ? props.theme.palette.text.primary
            : transparentize(props.theme.palette.type === 'light' ? 0.3 : 0.6, props.theme.palette.text.primary)};
    box-shadow: ${props =>
        props.$isAcquired && props.theme.palette.type === 'light' ? props.theme.shadows[1] : undefined};
`

import { FavoriteIcon, FavoriteOutlineIcon } from 'components/icons'
import { Text } from 'components/Text'
import React from 'react'
import { useProjectModalContext } from '../modal/projectModalContext'
import { useProjectActions } from '../useProjectActions'
import styled from 'styled-components/macro'
import { Inline } from 'components/layout'
import { useUserState } from 'contexts/UserContext'
import { Tooltip } from 'components/Tooltip'
import { IconButton } from 'components/IconButton'
import { useTranslate } from 'lib/i18n/useTranslate'

export const ProjectDetailsTitle: React.FC = ({ children }) => {
    return (
        <Root spacing={2}>
            <ProjectFavoriteButton />
            <Title variant="h3">{children}</Title>
        </Root>
    )
}

const ProjectFavoriteButton: React.FC = () => {
    const { project } = useProjectModalContext()
    const { toggleProjectFavorite } = useProjectActions()
    const { account } = useUserState()
    const t = useTranslate()

    if (!project) {
        return null
    }

    const isFavorited = project.file.owner === account.id ? project.file.favorite : project.metadata.favorited

    return (
        <Tooltip title={t('projects::favorite')}>
            <ProjectFavoriteButtonRoot
                onClick={() => {
                    toggleProjectFavorite(project)
                }}
            >
                {isFavorited ? <FavoriteIcon /> : <FavoriteOutlineIcon />}
            </ProjectFavoriteButtonRoot>
        </Tooltip>
    )
}

const Root = styled(Inline)`
    display: flex;
    align-items: flex-start;
    line-height: 1.2;
`

const Title = styled(Text)`
    line-height: 1.2;
    word-break: break-all;
` as typeof Text

const ProjectFavoriteButtonRoot = styled(IconButton)`
    padding: 0;
    vertical-align: text-top;
    color: ${props => props.theme.palette.primary.main};
    &:hover {
        background: none;
    }
`

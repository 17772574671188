import { UserState, useUserState } from 'contexts/UserContext'
import { api } from 'lib/api'
import { useMemo } from 'react'
import { useQuery, useQueryCache } from 'react-query'
import { QuizSetWithMetadata } from '..'
import { QuizSetWithEmbeddedTemplates } from '../QuizApi'
import { QuizCustomIds, customQuizTransformer } from './customHomeQuizTransformer'

const applyCustomTransform = (quizSet: QuizSetWithEmbeddedTemplates | QuizSetWithMetadata, user: UserState) => {
    if (QuizCustomIds.includes(quizSet.id)) {
        const transformer = customQuizTransformer[quizSet.id]
        if (!transformer) {
            return quizSet
        }
        return transformer(quizSet, user)
    }

    return quizSet
}

export const useQuizSetsQuery = () => {
    const user = useUserState()

    return useQuery('quiz-sets', async () => {
        const res = await api.quizzes.getQuizSets()

        return res.data.map(set => applyCustomTransform(set, user)) as QuizSetWithEmbeddedTemplates[]
    })
}

export const useRecommendedQuizSetsQuery = () => {
    const user = useUserState()

    return useQuery(
        'recommended-quiz-sets',
        async () => {
            const res = await api.quizzes.getRecommendedQuizSets()

            return res.data.map(set => applyCustomTransform(set, user)) as QuizSetWithMetadata[]
        },
        { staleTime: Infinity },
    )
}

export const useQuizSetSessionsQuery = (quizSets?: (QuizSetWithEmbeddedTemplates | QuizSetWithMetadata)[]) => {
    return useQuery(
        ['quiz-sets-sessions', quizSets?.map(set => set.id)],
        () => {
            return api.quizzes.getQuizSetsSessions(quizSets!)
        },
        { enabled: !!quizSets, staleTime: Infinity },
    )
}

export const useQuizTemplatesSessionQuery = (templates: string[]) => {
    return useQuery(
        ['quiz-template-sessions', templates],
        () => {
            return api.quizzes.getQuizSessionsForTemplates(templates)
        },
        { staleTime: Infinity },
    )
}

export const useQuizSetResultsQuery = (sessionIds?: string[]) => {
    return useQuery(
        ['quiz-set-results', sessionIds],
        () => {
            return api.quizzes.getQuizResultsForSessions(sessionIds!)
        },
        { staleTime: Infinity, enabled: !!sessionIds },
    )
}

export const useInvalidateQuizResults = () => {
    const queryCache = useQueryCache()

    return useMemo(
        () => ({
            invalidateQuizSetResults: async () => {
                await queryCache.invalidateQueries('quiz-sets-sessions')
                queryCache.invalidateQueries('quiz-set-results')
                queryCache.invalidateQueries('quiz-template-sessions')
            },
        }),
        [queryCache],
    )
}

import { usePrevious } from 'hooks/usePrevious'
import React, { useEffect, useState } from 'react'
import { FieldError, useForm, ValidationRules } from 'react-hook-form'
import { ButtonProps } from './Button'
import { Dialog } from './dialog'
import { TextFieldProps } from './form'

type PromptStatus = 'closed' | 'open' | 'loading' | 'error'

export interface PromptDialogContext {
    positiveButtonProps: Pick<ButtonProps, 'isLoading' | 'type'>
    negativeButtonProps: Pick<ButtonProps, 'onClick' | 'disabled'>
    inputProps: Pick<TextFieldProps, 'errorText' | 'ref' | 'readOnly' | 'name' | 'autoFocus'>
    formProps: Pick<React.InsHTMLAttributes<HTMLFormElement>, 'onSubmit'>
    status: PromptStatus
    error: FieldError | undefined
}

export type PromptActionResponse = { success: true } | { success: false; error: FieldError }

interface PromptProps {
    action: (value: string) => Promise<PromptActionResponse> | PromptActionResponse
    initialValue: string
    dialog: (context: PromptDialogContext) => React.ReactNode
    children: (Prompt: () => void, rest: any) => React.ReactNode
    rules?: ValidationRules
}

export const Prompt: React.FC<PromptProps> = ({ children, dialog, action, initialValue, rules, ...rest }) => {
    const [status, setStatus] = useState<PromptStatus>('closed')
    const { register, getValues, handleSubmit, errors, setError, reset } = useForm<{ value: string }>({
        defaultValues: {
            value: initialValue ?? '',
        },
    })
    const previousInitialValue = usePrevious(initialValue)

    useEffect(() => {
        if (previousInitialValue !== undefined && previousInitialValue !== initialValue) {
            reset({ value: initialValue ?? '' })
        }
    }, [reset, initialValue, previousInitialValue])

    const open = () => {
        if (status === 'closed') {
            setStatus('open')
        }
    }

    const onSubmit = handleSubmit(async () => {
        if (status !== 'open' && status !== 'error') {
            return
        }
        setStatus('loading')
        try {
            const value = getValues('value')
            const actionResult = await action(value)
            if (actionResult.success) {
                setStatus('closed')
            } else {
                setError('value', actionResult.error)
                setStatus('open')
            }
        } catch (e) {
            console.error(e)
            setStatus('error')
        }
    })

    const cancel = () => {
        if (status === 'open' || status === 'error') {
            setStatus('closed')
        }
    }

    const context: PromptDialogContext = {
        positiveButtonProps: {
            isLoading: status === 'loading',
            type: 'submit',
        },

        negativeButtonProps: {
            onClick: cancel,
            disabled: status === 'loading',
        },

        error: errors.value,

        inputProps: {
            ref: register(rules),
            readOnly: status === 'loading',
            name: 'value',
            autoFocus: true,
        },

        formProps: {
            onSubmit,
        },

        status,
    }

    return (
        <>
            <Dialog onClose={cancel} open={status !== 'closed'}>
                {dialog(context)}
            </Dialog>
            {children(open, rest)}
        </>
    )
}

import { Reducer } from 'redux'
import { PRESENT } from 'redux-socket-client'
import { InventoryItem } from '../../models'
import { produce } from 'immer'
import * as InventoryActions from '../../actions/inventory'
import { ActionType } from 'typesafe-actions'
import { CAST_VOTE_REQUEST, ADD_TO_INVENTORY, REMOVE_FROM_INVENTORY, INVENTORY } from 'lib/liveops/constants'

export type InventoryAction = ActionType<typeof InventoryActions>
export type InventoryState = InventoryItem[] | null
const transactionToItem: Record<string, string> = {}
type InventoryTransactionAction = {
    payload: {
        target: string
        status?: 'done' | 'error'
    }
    meta: {
        context: any
        transaction: string
    }
}

function handleInventoryTransaction(
    state: InventoryState,
    action: InventoryTransactionAction,
    sucessRecipe: (item: string) => (state: InventoryState) => void,
    errorRecipe: (item: string) => (state: InventoryState) => void,
) {
    let { target, status } = action.payload
    const { transaction, context } = action.meta
    if (status) {
        target = transactionToItem[transaction]

        if (target) {
            if (status === 'done') {
                delete transactionToItem[transaction]
            } else if (status === 'error') {
                delete transactionToItem[transaction]
                console.error(CAST_VOTE_REQUEST, context.error)

                //TODO: Handle errors
                return produce(state, errorRecipe(target))
            }
        }
    } else {
        transactionToItem[transaction] = target
        return produce(state, sucessRecipe(target))
    }
    return state
}

export const inventoryReducer: Reducer<InventoryState, InventoryAction> = (state = null, action) => {
    switch (action.type) {
        case CAST_VOTE_REQUEST: {
            return handleInventoryTransaction(
                state,
                action,
                inventoryItem => draft => {
                    if (draft) {
                        draft.push({
                            id: 'CLIENT_ONLY',
                            item: 'CLIENT_ONLY',
                            kind: 'vote',
                            metadata: {
                                target: inventoryItem,
                            },
                            name: 'CLIENT_ONLY',
                            catalog: 'CLIENT_ONLY',
                            createdAt: new Date(),
                        })
                    }
                },
                inventoryItem => draft => {
                    if (draft) {
                        const index = draft.findIndex(
                            item =>
                                item.id === 'CLIENT_ONLY' && item.metadata && item.metadata.target === inventoryItem,
                        )
                        if (index !== -1) {
                            draft.splice(index, 1)
                        }
                    }
                },
            )
        }
        case ADD_TO_INVENTORY:
            if (state) {
                return [...state, action.payload.item]
            }
            return state
        case REMOVE_FROM_INVENTORY:
            if (state) {
                return state.filter(item => item.id !== action.payload.item.id)
            }
            return state
        case INVENTORY:
            return action.payload.inventory
        case PRESENT:
            return null
        default:
            return state
    }
}

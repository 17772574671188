import { Inline, Stack } from 'components/layout'
import React, {useEffect} from 'react'
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Tooltip } from 'components/Tooltip'
import { Dialog, DialogContent, DialogTitle } from 'components/dialog'
import { TextField } from 'components/form'
import { Button } from 'components/Button'
import { InfoMessage } from 'components/InfoMessage'
import { Collapse } from 'components/Collapse'
import { Text } from 'components/Text'
import { useHasGrant } from 'lib/grants'
import {runtimeEnv} from "../../../utils/env";

interface SocialSharingProps {
    shareUrl: string
    buttonText: string
    helperText?: string
}

export const SocialSharing: React.FC<SocialSharingProps> = ({ shareUrl, buttonText, helperText }) => {
    const t = useTranslate()
    const [isOpenCopyLinkDialog, setOpenCopyLinkDialog] = React.useState(false)
    const [isLinkCopied, setLinkCopied] = React.useState(false)
    const hasGrant = useHasGrant()
    const hasSocialSharingAccess = hasGrant({ product: 'edup.universe', feature: 'social-sharing' })
    const isProxied = shareUrl.startsWith("https://proxy")
        || (runtimeEnv.REACT_APP_DEPLOY === 'dev' && shareUrl.includes("/post/"));
    const [ requestedOpen, setRequestedOpen ] = React.useState(false);
    const [ url, setUrl ] = React.useState<string>(isProxied ? '' : shareUrl);

    useEffect(() => {
        const onMessage = (event: MessageEvent<{ type?: string, redirectUri?: string }>) => {
            if (runtimeEnv.REACT_APP_SHARE_URL.startsWith(event.origin) && event.data?.type === 'redirect-uri') {
                setUrl(event.data?.redirectUri || shareUrl)
            }
        }

        window.addEventListener('message', onMessage)
        return () => {
            window.removeEventListener('message', onMessage)
        }
    }, [ shareUrl ])

    useEffect(() => {
        if(requestedOpen && url) {
            setOpenCopyLinkDialog(true);
            setRequestedOpen(false)
        }
    }, [ requestedOpen, url ]);

    const copyLink = () => {
        navigator.clipboard.writeText(url).then(() => setLinkCopied(true))
    }

    return (
        <>
            {isProxied && <iframe title="Share URL" src={shareUrl} style={{ width: 0, height: 0, opacity: 0, position: 'absolute', top: -1000, left: -1000 }} />}
            <Button isLoading={requestedOpen}
                    onClick={async () => {
                        if(isProxied) {
                            setRequestedOpen(true)
                        }
                        else {
                            setOpenCopyLinkDialog(true)
                        }
                    }}>
                {buttonText}
            </Button>
            <Dialog
                fullWidth
                open={isOpenCopyLinkDialog}
                onClose={() => {
                    setOpenCopyLinkDialog(false)
                    setLinkCopied(false)
                }}
                maxWidth={'sm'}
            >
                <DialogTitle>{t('projects::share')}</DialogTitle>
                <DialogContent>
                    <Stack spacing={6}>
                        <Collapse unmountOnExit in={isLinkCopied}>
                            <InfoMessage>{t('general::copyLink')}</InfoMessage>
                        </Collapse>
                        <Text>{helperText}</Text>
                        <Inline display="flex">
                            <TextField readOnly value={url} />
                            <Button onClick={() => copyLink()}>{t('general::copy')}</Button>
                        </Inline>
                        {hasSocialSharingAccess && <SocialmediaSharing shareUrl={url} />}
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    )
}

const SocialmediaSharing: React.FC<{ shareUrl: string }> = ({ shareUrl }) => {
    const t = useTranslate()

    return (
        <Inline display="flex" justifyContent="flex-start">
            <Tooltip title={t('tooltip::shareFacebook')}>
                <FacebookShareButton url={shareUrl} hashtag="#logiscool">
                    <FacebookIcon size="2rem" round />
                </FacebookShareButton>
            </Tooltip>
            <Tooltip title={t('tooltip::shareTwitter')}>
                <TwitterShareButton url={shareUrl}>
                    <TwitterIcon size="2rem" round />
                </TwitterShareButton>
            </Tooltip>
            <Tooltip title={t('tooltip::shareViber')}>
                <ViberShareButton url={shareUrl}>
                    <ViberIcon size="2rem" round />
                </ViberShareButton>
            </Tooltip>
            <Tooltip title={t('tooltip::shareWhatsapp')}>
                <WhatsappShareButton url={shareUrl}>
                    <WhatsappIcon size="2rem" round />
                </WhatsappShareButton>
            </Tooltip>
            <Tooltip title={t('tooltip::sendEmail')}>
                <EmailShareButton url={shareUrl}>
                    <EmailIcon size="2rem" round />
                </EmailShareButton>
            </Tooltip>
        </Inline>
    )
}

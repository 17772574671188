import ae0ba45f80533ebd13ca2a from './61ae0ba45f80533ebd13ca2a.png'
import ae0bc55f805340c513ca4f from './61ae0bc55f805340c513ca4f.png'
import ae0bd7ae44152d5a5547c0 from './61ae0bd7ae44152d5a5547c0.png'
import ae0becae441518f05547de from './61ae0becae441518f05547de.png'
import ae0bff9403ab463a848676 from './61ae0bff9403ab463a848676.png'
import ae0c145f805313fb13ca9f from './61ae0c145f805313fb13ca9f.png'
import ae0c2a9403abf79b8486a8 from './61ae0c2a9403abf79b8486a8.png'
import ae0c36ae44158de655483e from './61ae0c36ae44158de655483e.png'
import ae0c4814bc24630aeaea50 from './61ae0c4814bc24630aeaea50.png'
import ae0c54ae44151c31554857 from './61ae0c54ae44151c31554857.png'

type ImagesRecord = Record<string, string>

export const images: ImagesRecord = {
    '61ae0ba45f80533ebd13ca2a': ae0ba45f80533ebd13ca2a,
    '61ae0bc55f805340c513ca4f': ae0bc55f805340c513ca4f,
    '61ae0bd7ae44152d5a5547c0': ae0bd7ae44152d5a5547c0,
    '61ae0becae441518f05547de': ae0becae441518f05547de,
    '61ae0bff9403ab463a848676': ae0bff9403ab463a848676,
    '61ae0c145f805313fb13ca9f': ae0c145f805313fb13ca9f,
    '61ae0c2a9403abf79b8486a8': ae0c2a9403abf79b8486a8,
    '61ae0c36ae44158de655483e': ae0c36ae44158de655483e,
    '61ae0c4814bc24630aeaea50': ae0c4814bc24630aeaea50,
    '61ae0c54ae44151c31554857': ae0c54ae44151c31554857,
}

import React from 'react'
import { Alert } from '@material-ui/lab'
import { WarningIcon } from 'components/icons'
import { useTranslate } from 'lib/i18n/useTranslate'

interface ErrorMessageProps {}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ children }) => {
    const t = useTranslate()

    return (
        <Alert icon={<WarningIcon />} severity="error">
            {children ?? t('error::somethingWentWrong')}
        </Alert>
    )
}

import { Reducer } from 'redux'
import { Notification, NOTIFICATION_TYPES } from 'lib/liveops/models/notification'
import {
    READ_NOTIFICATION,
    NOTIFICATIONS,
    PUSH_NOTIFICATION,
    MARK_ALL_NOTIFICATION_AS_DISPLAYED,
    MARK_NOTIFICATION_AS_DISPLAYED,
} from 'lib/liveops/constants'
import produce from 'immer'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export interface NotificationsState {
    byId: Record<string, Notification>
    allIds: string[] | undefined
    welcomeStats: {
        views?: number
        cookies?: number
        goldenCookies?: number
        likes?: number
        follows?: number
    } | null
}

const InitialNotificationsState: NotificationsState = {
    byId: {},
    allIds: undefined,
    welcomeStats: null,
}

const GENERATED = 'generated-'

export function isGeneratedId(id: string) {
    return id.startsWith(GENERATED)
}

export const generateId = () => GENERATED + Math.random().toString(36).substr(2, 9)

const isValidNotificationType = (notification: Notification) =>
    NOTIFICATION_TYPES.includes(notification.message.data.type)

let requestedNotifications = false

export const notificationsReducer: Reducer<NotificationsState> = (
    state = InitialNotificationsState,
    { type, payload = {} },
) => {
    switch (type) {
        case NOTIFICATIONS: {
            if (!payload.notifications || requestedNotifications) {
                return state
            }
            requestedNotifications = true
            return produce(state, draft => {
                // for some reason sometimes there's an empty array in the payload
                const notifications: Notification[] = payload.notifications
                    .filter((noti: Notification) => Array.isArray(noti) === false)
                    .filter(isValidNotificationType)

                notifications.forEach(noti => {
                    draft.byId[noti.id] = noti
                })

                draft.allIds
                    ? draft.allIds.unshift(...notifications.map(noti => noti.id))
                    : (draft.allIds = notifications.map(noti => noti.id))
            })
        }

        case PUSH_NOTIFICATION: {
            return produce(state, draft => {
                if (isValidNotificationType(payload.notification)) {
                    const id = payload.notification.id || generateId()
                    draft.byId[id] = payload.notification
                    draft.allIds?.unshift(id)
                }
                if (payload.notification.message.data.type === 'WELCOME_STATS') {
                    draft.welcomeStats = payload.notification.message.data.payload
                }
            })
        }

        case READ_NOTIFICATION: {
            return produce(state, draft => {
                const notification = draft.byId[payload.id]
                if (notification) {
                    notification.read = true
                }
            })
        }

        case MARK_NOTIFICATION_AS_DISPLAYED:
            return produce(state, draft => {
                const notification = draft.byId[payload.id]
                if (notification) {
                    notification.displayed = true
                }
            })

        case MARK_ALL_NOTIFICATION_AS_DISPLAYED: {
            return produce(state, draft => {
                draft.allIds?.forEach(id => {
                    draft.byId[id].displayed = true
                })
            })
        }

        default:
            return state
    }
}

import { requestStats, StatisticName } from 'lib/liveops'
import { useSelector } from 'lib/store'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { NormalizedActivityEvent, NormalizedActivityEventSubEvent } from '../types/activityEventTypes'
import { getTotalStatGroupForActivityEvent } from './activityEventHelpers'
import { storeActionListener } from 'lib/store/store-action-listener'
import { PRESENT } from 'redux-socket-client'

const STAT = '@TOTAL'

const subscribed = new Set<string>()

export const useSubscribeToActivityEventPoints = (event: NormalizedActivityEvent, fakePoints: number|null) => {
    const dispatch = useDispatch()
    const total = useSelector(
        state =>
            state.client.statistics[
                `${getTotalStatGroupForActivityEvent(event.slug)}:${STAT}` as unknown as StatisticName
            ],
    )
    const ready = useSelector(state => state.client.ready.app)
    const subEventPoints = useSelector(state =>
        event.subEvents.reduce<Record<string, number | undefined>>((obj, subEvent) => {
            obj[subEvent.key] =
                state.client.statistics[`${subEvent.scoreStatGroup}:${STAT}` as unknown as StatisticName]
            return obj
        }, {}),
    )

    useEffect(() => {
        // handle reconnects
        if (ready) {
            const handlePresent = () => {
                subscribed.clear()
            }
            storeActionListener.on(PRESENT, handlePresent)
            return () => {
                storeActionListener.removeListener(PRESENT, handlePresent)
            }
        }
    }, [ready])

    useEffect(() => {
        if (ready && !subscribed.has(event.slug)) {
            const subEventGroups = event.subEvents.map(subEvent => subEvent.scoreStatGroup)
            const uniqueGroups = Array.from(new Set([getTotalStatGroupForActivityEvent(event.slug), ...subEventGroups]))
            dispatch(requestStats(uniqueGroups.map(group => ({ group, stat: STAT }))))
            subscribed.add(event.slug)
        }
    }, [dispatch, event, ready])

    const getSubEventPoint = useCallback(
        (subEvent: NormalizedActivityEventSubEvent) => {
            if(typeof fakePoints === 'number') {
                return Math.min(subEvent.requiredPoints, fakePoints)
            }

            const points = subEventPoints[subEvent.key]
            if (points === undefined) {
                return points
            }
            return Math.min(subEvent.requiredPoints, points)
        },
        [subEventPoints, fakePoints],
    )

    return {
        total: typeof fakePoints === 'number' ? fakePoints : total,
        getSubEventPoint
    }
}

import { Button, ButtonProps } from 'components/Button'
import React from 'react'

import styled from 'styled-components/macro'
import { ProjectCardBadge, ProjectCardBadgeProps } from './ProjectCardBadge'

interface ProjectCardButtonBadgeProps extends ProjectCardBadgeProps, Omit<ButtonProps, 'color'> {}

export const ProjectCardButtonBadge: React.FC<ProjectCardButtonBadgeProps> = props => {
    return <ProjectCardBadge component={StyledButton} {...props}></ProjectCardBadge>
}

const StyledButton = styled(Button)`
    padding: ${props => props.theme.spacing(1, 2)};
`

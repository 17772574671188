import { Panel, PanelHeader, PanelTitle } from 'components/panel'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useEffect, useState } from 'react'
import { StatisticName, requestStats, useHasInventoryItem } from 'lib/liveops'
import { CheckCircleIcon } from 'components/icons'
import { Text } from 'components/Text'
import { useHumanReadableCountdown } from 'hooks/useCountdown'
import { Stack } from 'components/layout'
import { ActivityEventPointIcon } from './ActivityEventPointIcon'
import styled, { css } from 'styled-components/macro'
import { Avatar } from 'features/avatar'
import { Button } from 'components/Button'
import { Link } from 'react-router-dom'
import { Card, CardContent } from 'components/card'
import { darken, lighten, mix } from 'polished'
import { Divider } from 'components/Divider'
import { Tooltip } from 'components/Tooltip'
import { DigitalMission2023WeeklyTask, getActiveDigitalMissionWeeklyTask } from '../data/digitalMission2023Data'
import { useDispatch } from 'react-redux'
import { useSelector } from 'lib/store'
import { ActivityEvent, NormalizedActivityEvent } from '../types/activityEventTypes'

interface ActivityEventWeeklyMissionsProps {
    variant: 'feed' | 'event-details'
    event: ActivityEvent | NormalizedActivityEvent
}

export const ActivityEventWeeklyMissions: React.FC<ActivityEventWeeklyMissionsProps> = ({ event, variant }) => {
    const weeklyTasks = event.templateData?.weeklyTasks
    const [activeTask, setActiveTask] = useState(getActiveDigitalMissionWeeklyTask(weeklyTasks))

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveTask(getActiveDigitalMissionWeeklyTask(getActiveDigitalMissionWeeklyTask(weeklyTasks)))
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [weeklyTasks])

    if (!activeTask) {
        return null
    }

    return <ActivityEventWeeklyMissionsInner key={activeTask.stat} task={activeTask} variant={variant} />
}

interface ActivityEventActiveWeeklyMissionsInnerProps {
    task: DigitalMission2023WeeklyTask
    variant: 'feed' | 'event-details'
}

const ActivityEventWeeklyMissionsInner: React.FC<ActivityEventActiveWeeklyMissionsInnerProps> = ({ task, variant }) => {
    const t = useTranslate()
    const { label: remainingTimeLabel, isEnd } = useHumanReadableCountdown(task.activeTo)
    const isCompleted = useHasInventoryItem(task.catalogItemId)
    const dispatch = useDispatch()
    const value = useSelector(
        state => state.client.statistics[`digital-mission-2024-weekly-task:${task.stat}` as unknown as StatisticName],
    )

    useEffect(() => {
        if (task.requiredValue > 1) {
            dispatch(requestStats([{ group: 'digital-mission-2024-weekly-task', stat: task.stat }]))
        }
    }, [task, dispatch])

    const displayProgress = task.requiredValue > 1 && value !== undefined && value > 0 && value <= task.requiredValue
    const hideCta = task.cta.hideInFeed && variant === 'feed'
    const hideWhenCompleted = variant === 'feed'

    if (isCompleted === undefined || (isCompleted && hideWhenCompleted)) {
        return null
    }

    return (
        <Panel>
            <PanelHeader id="weekly-missions">
                <PanelTitle>{t('digitalMission2023::weeklyTaskTitle01')}</PanelTitle>
            </PanelHeader>
            <Card>
                <Grid>
                    {isCompleted ? (
                        <Completed>
                            <CheckCircleIcon />
                        </Completed>
                    ) : (
                        <Rewards>
                            <Text variant="h3">
                                <strong>{t('activityEvents::milestones')}</strong>
                            </Text>
                            <Tooltip title={t('settings::profilPicture')}>
                                <AvatarWrap>
                                    <StyledAvatar src={task.image} />
                                </AvatarWrap>
                            </Tooltip>
                            <Divider />
                            <Tooltip title={t('digitalMission2022::points')}>
                                <span>
                                    <Points>
                                        +100 <ActivityEventPointIcon />
                                    </Points>
                                </span>
                            </Tooltip>
                        </Rewards>
                    )}
                    <CardContent>
                        <Stack>
                            <Text variant="h3">
                                <strong>
                                    {isCompleted
                                        ? t('digitalMission2023::weeklyTaskCongrats')
                                        : `${t(task.name)}${
                                              displayProgress ? ` (${value}/${task.requiredValue})` : ''
                                          }`}
                                </strong>
                            </Text>
                            <Text>
                                {t(
                                    isCompleted
                                        ? 'digitalMission2023::weeklyTaskCompletedDescription'
                                        : task.description,
                                )}
                            </Text>
                            {!isCompleted && task.cta && !hideCta && (
                                <Button component={Link} to={task.cta.to}>
                                    {t(task.cta.label)}
                                </Button>
                            )}
                            {!isEnd && (
                                <Text>
                                    {t(
                                        isCompleted
                                            ? 'digitalMission2023::nextTaskRemainingTime'
                                            : 'general::remainingTime',
                                    )}
                                    : <strong>{remainingTimeLabel}</strong>
                                </Text>
                            )}
                        </Stack>
                    </CardContent>
                </Grid>
            </Card>
        </Panel>
    )
}

const Completed = styled.div`
    ${props =>
        props.theme.palette.type === 'light'
            ? css`
                  background: #d7e8d8;
                  color: #4caf50;
              `
            : css`
                  background: #24353a;
                  color: #549a6a;
              `}
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.spacing(6)};
    svg {
        font-size: 4rem;
    }
`

const Rewards = styled(CardContent)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${props => props.theme.spacing(4)};
    ${props => {
        const backgroundColor = mix(0.8, props.theme.palette.background.paper, props.theme.palette.primary.main)
        return css`
            color: ${props.theme.palette.type === 'light'
                ? darken(0.7, backgroundColor)
                : lighten(0.5, backgroundColor)};
            background: ${backgroundColor};
        `
    }}
    hr {
        width: 50%;
        background-color: ${props => mix(0.7, props.theme.palette.background.paper, props.theme.palette.primary.main)};
    }
`

const AvatarWrap = styled.div`
    display: flex;
    gap: ${props => props.theme.spacing(2)};
    flex-direction: column;
    align-items: center;
    p {
        line-height: 1;
    }
`

const StyledAvatar = styled(Avatar)`
    img {
        display: block;
    }
`

const Points = styled.div`
    background: ${props => props.theme.palette.background.paper};
    color: ${props => props.theme.palette.text.primary};
    padding: ${props => props.theme.spacing(2, 4)};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    flex-shrink: 0;
    font-weight: 700;
    font-size: 1rem;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    ${props => props.theme.breakpoints.down('xs')} {
        grid-template-columns: auto;
    }
`

import { InputAdornment, Option, Select, SelectBox, SelectBoxItem, TextField } from 'components/form'
import { CloseIcon, FilterIcon, GridIcon, ListIcon, Searchicon } from 'components/icons'
import React, { useEffect, useState } from 'react'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { MyProjectsFilterState, ProjectListLayout } from './MyProjects'
import { useDebounce } from 'use-debounce'
import { MyProjectsQuery } from '../ProjectsApi'
import styled from 'styled-components/macro'
import { useBlockGalleriesQuery } from '../projectQueries'
import { Button } from 'components/Button'
import { Collapse, Popover, Theme, useMediaQuery } from '@material-ui/core'
import { Hidden, Inline, Stack } from 'components/layout'
import { Authorize } from 'components/Authorize'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Card, CardContent } from 'components/card'
import { IconButton } from 'components/IconButton'
import { LocalisationKey } from 'lib/i18n'
import { useHasGrant } from 'lib/grants'
import { ProjectLabelSelect } from '../ProjectLabelSelect'

interface MyProjectsFiltersProps {
    filters: DecodedValueMap<MyProjectsFilterState>
    setFilters: SetQuery<MyProjectsFilterState>
    listLayout: ProjectListLayout
    onListLayoutChange: (layout: ProjectListLayout) => void
    hideSort: boolean
}

const sorts: MyProjectsQuery['sort'][] = ['-name', 'name', '-updatedAt', 'updatedAt', '-createdAt', 'createdAt']
const sortLabels: Record<MyProjectsQuery['sort'], LocalisationKey> = {
    '-createdAt': 'general::sort::recentlyCreated',
    createdAt: 'general::sort::lastCreated',
    '-name': 'general::sort::abcDesc',
    name: 'general::sort::abcAsc',
    '-updatedAt': 'general::sort::recentlyModified',
    updatedAt: 'general::sort::lastModified',
}

export const MyProjectsFilters: React.FC<MyProjectsFiltersProps> = ({
    filters,
    setFilters,
    listLayout,
    onListLayoutChange,
    hideSort,
}) => {
    const t = useTranslate()

    const advancedFilters = (
        <Authorize grant={{ product: 'edup.scoolcode.ide', feature: 'advanced-filtering' }}>
            <AdvancedFilters filters={filters} setFilters={setFilters} />
        </Authorize>
    )

    return (
        <Card elevation={1}>
            <CardContent>
                <Grid>
                    <SearchWrap>
                        <Search filters={filters} setFilters={setFilters} />
                        <Hidden smUp>{advancedFilters}</Hidden>
                    </SearchWrap>
                    <InnerGrid spacing={3}>
                        <Hidden xsDown>{advancedFilters}</Hidden>

                        {!hideSort && (
                            <SortSelect
                                value={filters.sort}
                                fullWidth={false}
                                onChange={e => {
                                    setFilters({ sort: e.target.value as MyProjectsQuery['sort'] }, 'replaceIn')
                                }}
                            >
                                {sorts.map(sort => (
                                    <Option key={sort} value={sort}>
                                        {t(sortLabels[sort])}
                                    </Option>
                                ))}
                            </SortSelect>
                        )}

                        <Inline spacing={2} display="flex" alignItems="center">
                            <IconButton
                                color={listLayout === 'grid' ? 'primary' : 'inherit'}
                                size="small"
                                onClick={() => {
                                    onListLayoutChange('grid')
                                }}
                                title={t('general::grid-view')}
                            >
                                <GridIcon />
                            </IconButton>
                            <IconButton
                                color={listLayout === 'list' ? 'primary' : 'inherit'}
                                size="small"
                                onClick={() => {
                                    onListLayoutChange('list')
                                }}
                                title={t('general::list-view')}
                            >
                                <ListIcon />
                            </IconButton>
                        </Inline>
                    </InnerGrid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const Search: React.FC<Pick<MyProjectsFiltersProps, 'filters' | 'setFilters'>> = ({ filters, setFilters }) => {
    const t = useTranslate()
    const [search, setSearch] = useState(filters.search)
    const [debouncedSearch] = useDebounce(search, 500)

    useEffect(() => {
        setSearch(filters.search)
    }, [filters.search])

    useEffect(() => {
        setFilters({ search: debouncedSearch }, 'replaceIn')
    }, [debouncedSearch, setFilters])

    return (
        <TextField
            fullWidth={false}
            placeholder={t('general::search')}
            type="search"
            startAdornment={
                <InputAdornment position="start">
                    <Searchicon />
                </InputAdornment>
            }
            value={search}
            onChange={e => {
                setSearch(e.target.value)
            }}
        />
    )
}

const AdvancedFilters: React.FC<Pick<MyProjectsFiltersProps, 'filters' | 'setFilters'>> = ({ filters, setFilters }) => {
    const { data: blockGalleries } = useBlockGalleriesQuery()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const t = useTranslate()
    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'))
    const hasGrant = useHasGrant()
    const isLanguageFilterEnabled =
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-python' }) &&
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-stagescript' });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }
    const isOpen = !!anchorEl
    const isFiltering = filters.kind !== '' || filters.language !== '' || filters.palette !== '' || (filters.labels && filters.labels.length > 0)
    const filterString = (() => {
        if (!isFiltering) {
            return t('general::filters')
        }
        let filterString = [
            capitalizeFirstLetter(filters.kind || ''),
            (filters.language === 'stagescript' ? 'StageScript' : filters.language === 'python' ? 'Python' : ''),
            ...(filters.labels ?? []),
            blockGalleries?.data.find(gallery => gallery.id === filters.palette)?.name,
        ]
            .filter(field => !!field)
            .join(', ')

        if (filterString.length > 50) {
            filterString = filterString.slice(0, 50) + '…'
        }

        return filterString
    })()

    return (
        <>
            {isMobile ? (
                <IconButton size="small" onClick={handleClick} color={isFiltering ? 'primary' : 'inherit'}>
                    <FilterIcon />
                </IconButton>
            ) : (
                <Button
                    onClick={handleClick}
                    color={isFiltering ? 'primary' : 'default'}
                    startIcon={<FilterIcon />}
                    variant="text"
                >
                    {filterString}
                </Button>
            )}

            <Popover PaperProps={{ style: { width: 360 } }} anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
                <Stack p={4}>
                    <SelectBox
                        label={t('projects::type')}
                        value={filters.kind}
                        onChange={value => {
                            setFilters({ kind: value as MyProjectsQuery['kind'] }, 'replaceIn')
                        }}
                    >
                        <SelectBoxItem fullWidth value="blox">
                            Blox
                        </SelectBoxItem>
                        <SelectBoxItem fullWidth value="mix">
                            Mix
                        </SelectBoxItem>
                        <SelectBoxItem fullWidth value="">
                            {t('general::all')}
                        </SelectBoxItem>
                    </SelectBox>
                    {isLanguageFilterEnabled && <SelectBox
                        label={t('projects::language::label')}
                        value={filters.language}
                        onChange={value => {
                            setFilters({ language: value as MyProjectsQuery['language'] }, 'replaceIn')
                        }}
                    >
                        <SelectBoxItem value="stagescript">
                            StageScript
                        </SelectBoxItem>
                        <SelectBoxItem fullWidth value="python">
                            Python
                        </SelectBoxItem>
                        <SelectBoxItem fullWidth value="">
                            {t('general::all')}
                        </SelectBoxItem>
                    </SelectBox>}
                    {hasGrant('project-labels') && (
                        <ProjectLabelSelect
                            variant="filter"
                            onChange={value => {
                                setFilters({ labels: value }, 'replaceIn')
                            }}
                            value={filters.labels}
                        />
                    )}
                    <Select
                        onChange={e => {
                            setFilters({ palette: e.target.value as string }, 'replaceIn')
                        }}
                        displayEmpty
                        value={filters.palette}
                        label={t('projects::palette')}
                    >
                        <OptionAll value="">
                            <em>{t('general::all')}</em>
                        </OptionAll>
                        {blockGalleries?.data.map(gallery => (
                            <Option key={gallery.id} value={gallery.id}>
                                {gallery.name}
                            </Option>
                        ))}
                    </Select>
                    <Collapse in={isFiltering}>
                        <Button
                            onClick={() => {
                                setFilters({ kind: '', palette: '', language: '', labels: [] }, 'replaceIn')
                            }}
                            startIcon={<CloseIcon />}
                            variant="text"
                        >
                            {t('general::resetFilters')}
                        </Button>
                    </Collapse>
                </Stack>
            </Popover>
        </>
    )
}

function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const SortSelect = styled(Select)`
    ${props => props.theme.breakpoints.down('sm')} {
        width: 100%;
    }
`

const OptionAll = styled(Option)`
    font-weight: 600;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(max-content, 1fr);
    gap: ${props => props.theme.spacing(6)};
    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: auto;
    }
`

const InnerGrid = styled(Inline)`
    display: flex;
    justify-content: flex-end;
`

const SearchWrap = styled(Inline)`
    display: flex;
    align-items: center;
    > .MuiFormControl-root {
        flex-grow: 1;
    }
`

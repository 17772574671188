import { TrainerIcon } from 'components/icons'
import { Tooltip } from 'components/Tooltip'
import { ApiTypes } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'

interface UserBadgeProps {
    user: ApiTypes['account']
    className?: string
}

export const UserBadge: React.FC<UserBadgeProps> = ({ user, ...rest }) => {
    const t = useTranslate()

    if (user.primaryKind === 'developer' || user.primaryKind === 'trainer' || user.primaryKind === 'contributor') {
        return (
            <Tooltip title={t('general::instructor')}>
                <span>
                    <StyledTrainerIcon {...rest} />
                </span>
            </Tooltip>
        )
    }

    return null
}

const StyledTrainerIcon = styled(TrainerIcon)`
    vertical-align: middle;
    margin: 0;
    /* color: ${props => props.theme.palette.primary.main}; */
`

import React from 'react'
import { Link } from 'react-router-dom'
import { newProjetDialogTo } from './NewProjectDialog'
import { AddIcon } from 'components/icons'
import { Button } from 'components/Button'
import { useBlockGalleriesQuery } from './projectQueries'
import { useUserState, canUserCreateProject, canUnlockSecretCode } from 'contexts/UserContext'
import { useMiniQuizAccessLevel } from 'features/mini-quizzes/helpers/miniQuizHelpers'
import { CookieHunt } from 'features/cookie-hunt'

interface NewProjectButtonProps {
    disabled?: boolean
}

export const NewProjectButton: React.FC<NewProjectButtonProps> = ({ children, disabled }) => {
    // prefetch block galleries
    // TODO: do we have any reason to use queryCache instead?
    useBlockGalleriesQuery()

    const user = useUserState()
    const canCreateProject = canUserCreateProject(user)
    const canUnlockSecret = canUnlockSecretCode(user, 'project')
    const miniQuizAccessLevel = useMiniQuizAccessLevel()

    if (!(canCreateProject || miniQuizAccessLevel === 'full' || canUnlockSecret)) {
        return null
    }

    return (
        <CookieHunt name="new-project">
            <Button
                disabled={disabled}
                data-testid="new-project-button"
                startIcon={<AddIcon />}
                component={Link}
                to={newProjetDialogTo}
            >
                {children}
            </Button>
        </CookieHunt>
    )
}

import { List, ListItem, ListItemIcon, ListItemText } from 'components/list'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { DiceIcon, TshirtIcon } from 'components/icons'
import { useUserState } from 'contexts/UserContext'

export const DigitalMission2021Prizes: React.FC = () => {
    const t = useTranslate()
    const user = useUserState()

    const sweepstake01Prize = sweepstake01[user.i18n.country] ?? ''
    const sweepstake02Prize = sweepstake02[user.i18n.country] ?? ''
    const sweepstake03Prize = sweepstake03[user.i18n.country] ?? ''

    return (
        <>
            <Text variant="h3">{t('digitalMission2021::prizes::title')}</Text>
            <StyledList>
                <StyledListItem button={false}>
                    <ListItemIcon>
                        <DiceIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Text variant="body1">
                                {t('digitalMission2021::prizes::sweepstake01')}
                                {sweepstake01Prize && ': '}
                                <strong>{sweepstake01Prize}</strong>
                            </Text>
                        }
                        secondary={t('digitalMission2021::prizes::description01postevent')}
                    />
                </StyledListItem>
                <StyledListItem button={false}>
                    <ListItemIcon>
                        <DiceIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Text variant="body1">
                                {t('digitalMission2021::prizes::sweepstake02')}
                                {sweepstake02Prize && ': '}
                                <strong>{sweepstake02Prize}</strong>
                            </Text>
                        }
                        secondary={t('digitalMission2021::prizes::description02postevent')}
                    />
                </StyledListItem>
                <StyledListItem button={false}>
                    <ListItemIcon>
                        <TshirtIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Text variant="body1">
                                <strong>{t('digitalMission2021::prizes::sweepstake04')}</strong>
                            </Text>
                        }
                        secondary={t('digitalMission2021::prizes::description04postevent')}
                    />
                </StyledListItem>
                <StyledListItem button={false}>
                    <ListItemIcon>
                        <DiceIcon />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Text variant="body1">
                                {t('digitalMission2021::prizes::sweepstake03')}
                                {sweepstake03Prize && ': '}
                                <strong>{sweepstake03Prize}</strong>
                            </Text>
                        }
                        secondary={t('digitalMission2021::prizes::description03postevent')}
                    />
                </StyledListItem>
            </StyledList>
        </>
    )
}

const StyledList = styled(List)`
    padding: 0;
`

const StyledListItem = styled(ListItem)`
    padding: 0;
`

const sweepstake01: Record<string, string> = {
    HU: '5 db Rocketbook okosfüzet',
    BE: '2 casques audio JBL sans fil',
    BG: 'слушалки HAMA HS-P350 (2 броя)',
    CZ: 'Rocketbook',
    DE: '5x VR Brillen',
    MX: 'Auriculares',
    RO: '5 boxe portabile JBL',
    SA: '3 Rocketbook',
    RS: 'Genius Scorpion Spear Pro 3200dpi Gaming Mouse  (6)',
    SG: '2* Bluetooth Speaker',
    SK: '3 x VR okuliare',
    ZA: '2 *Rocket book',
    ES: 'VR Glasses',
    CH: '3x Rocketbook',
    TW: 'Minecraft帳號',
    TR: '2x Lego',
}

const sweepstake02: Record<string, string> = {
    HU: '5 db JBL fejhallgató',
    BE: '2 enceintes portable JBL Clip 4',
    BG: 'геймърска мишка Xtrfy M3 HeatoN Edition (2 броя)',
    CZ: 'JBL Headphone',
    DE: '5x Bluetooth Lautsprecher ',
    MX: 'Auriculares inalámbricos',
    RO: '5 perechi de căști JBL',
    SA: '3 JBL Bluetooth headphone',
    RS: 'Genius HS-610 Headset  (3)',
    SG: '2* Headphone',
    SK: '3 x Protex bluetoth sluchátka',
    ZA: '2 * JBL Headphone',
    ES: 'Wireless Earphone Bluetooth',
    CH: '3x JBL Go2 Bluetooth Speaker',
    TW: '小米TWS耳機',
    TR: '2x JBL headphone',
}

const sweepstake03: Record<string, string> = {
    HU: '1 db Samsung Galaxy tablet',
    BE: '1 tablette Samsung Galaxy',
    BG: 'таблет Samsung Galaxy Tab A7 (1 брой)',
    CZ: 'Samsung Galaxy Tablet',
    DE: '1x Samsung Galaxy Tablet',
    MX: 'Bocina Portable 5.0 Bluetooth Stereo, resistente al agua inalámbrica',
    RO: '1 tabletă Samsung Galaxy',
    SA: '1 Samsung Tablet',
    RS: 'Gaming chair Spawn Hero Series Red (1)',
    SG: '1* Smart Watch',
    SK: '1 x JBL herné slúchadlo',
    ZA: '1x Samsung Galaxy Tablet',
    ES: 'JBL Headphone',
    CH: '1x Huawei MatePad',
    TW: '多功能午睡枕',
    TR: '1x JBL bluetooth speaker',
}

// English (India)	    en-IN
// Arabic (Saudi Arabia)	ar-SA
// español (CO)	        es-CO
// Bulgarian	            bg-BG
// čeština	                cs-CZ
// Deutsch (AT)	        de-AT
// Deutsch (CH)	        de-CH
// Deutsch (DE)	        de-DE
// English (US)	        en-US
// English (demo)	        en-US-demo
// español (ES)	        es-ES
// español (MX)	        es-MX
// Français (Belgian)	    fr-BE
// Français (CH)	        fr-CH
// Français	            fr-FR
// hrvatski	            hr-HR
// magyar	                hu-HU
// Indonesian	            id-ID
// română	                ro-RO
// slovenčina	            sk-SK
// serbian	                sr-RS
// Turkish	                tr-TR
// Taiwan	                zh-TW
// South-africa                ZA

import { FloatingBadge } from 'components/FloatingBadge'
import { PinIcon } from 'components/icons'
import { Tooltip } from 'components/Tooltip'
import dayjs from 'dayjs'
import { FeedEntryWithMetada } from 'features/feed/types/feedTypes'
import { useDateFormat } from 'hooks/useDateFormat'
import { useRelativeTimeFormat } from 'hooks/useRelativeTimeFormat'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { FeedEntryCaption } from '../FeedEntryCaption'

interface FeedEntryBaseProps {
    entry: FeedEntryWithMetada
}

export const FeedEntryBase: React.FC<FeedEntryBaseProps> = ({ children, entry }) => {
    const { formatDate } = useDateFormat()
    const { getRelativeTime } = useRelativeTimeFormat()
    const dayjsDate = dayjs(entry.activeFrom)
    const t = useTranslate()

    return (
        <Root $isPinned={!!entry.isPinned}>
            <Tooltip title={formatDate(dayjsDate.toDate())}>
                <span>
                    <FeedEntryCaption component="time" dateTime={dayjsDate.format('YYYY-MM-DDTHH:mm')}>
                        {getRelativeTime(dayjsDate)} {entry.isPinned && <PinIcon />}
                    </FeedEntryCaption>
                </span>
            </Tooltip>
            <FloatingBadge title={t('general::new')} active={entry.metadata.unread}>
                <div>{children}</div>
            </FloatingBadge>
        </Root>
    )
}

const Root = styled.div<{ $isPinned: boolean }>`
    ${props =>
        props.$isPinned &&
        css`
            ${props => props.theme.breakpoints.up('lg')} {
                margin-left: -75px;
                margin-right: -75px;
            }
        `}
`

import { api } from 'lib/api'
import * as React from 'react'

const TIMEOUT = 3000

export const useIncrementHGViewCount = (projectId: string) => {
    React.useEffect(() => {
        let ignore = false
        setTimeout(() => {
            if (!ignore) {
                api.projects.incrementProjectViewCount(projectId)
            }
        }, TIMEOUT)

        return () => {
            ignore = true
        }
    }, [projectId])
}

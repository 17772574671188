import { AxiosError } from 'axios'
import { getUserProjectLanguages, useUserState } from 'contexts/UserContext'
import { api } from 'lib/api'
import { useLanguagesQuery } from 'lib/i18n'
import { PROJECT_SHARE_APPROVED } from 'lib/liveops/constants'
import { storeActionListener } from 'lib/store/store-action-listener'
import { useCallback, useEffect, useMemo } from 'react'
import { useInfiniteQuery, useQuery, useQueryCache } from 'react-query'
import { useDispatch } from 'react-redux'
import { UnwrapPromise } from 'utils/typeUtils'
import { ProjectVariant } from './projectHelpers'
import { DiscoverProjectsQuery, FilterSharedByOwnerOrTitleResponse, MyProjectsQuery } from './ProjectsApi'
import { fetchProjectsSucces, updateMetadata } from './store'

export const KEYS = {
    MY_PROJECTS: 'my-projects',
    SHARED_PROJECTS: 'shared-projects',
    MY_SHARED_PROJECTS: 'my-shared-projects',
    DISCOVER_PROJECTS: 'disover-projects',
    MY_SHAREABLE_PROJECTS: 'my-shareable-projects',
    PROJECT: 'project',
    MY_LATEST_PROJECTS: 'my-latest-projects',
    BLOCK_GALLERIES: 'block-galleries',
    COMMUNITY_PROJECT_DATA: 'community-project-data',
    CLASSROOM_PROJECT_DATA: 'classroom-project-data',
    MY_FAVORITE_PROJECTS: 'my-favorite-projects',
    MY_PENDING_PROJECTS: 'my-pending-projects',
    //eventsQuery keys is also defined here for query invalidation
    MY_SHAREABLE_CONTEST_PROJECTS: 'my-shareable-contest-projects',
    MY_SHARED_CONTEST_PROJECTS: 'my-shared-contest-projects',
    CONTESTPROJECTS_FOR_VOTING: 'contest-projects-for-voting',
    TRAINER_CONTEST_2021_RESULTS: 'trainer-contest-2021-results',
    MY_MODERATION_REPORTS: 'my-moderation-reports',
    RECOMMENDED_PROJECTS: 'recommended-projects',
    MY_CUSTOM_PROJECT_LABELS: 'my-custom-project-labels',
} as const

export function useSharedProjectsQuery(userId: string) {
    const dispatch = useDispatch()
    const { account } = useUserState()

    function normalizeProjects(projects: NonNullable<ReturnType<typeof useSharedProjectsQuery>['data']>) {
        return projects.data.map(p => {
            const { cookied, ...file } = p
            return { file: file, metadata: { cookied: p.cookied } }
        })
    }

    const query = useQuery(
        [userId === account.id ? KEYS.MY_SHARED_PROJECTS : KEYS.SHARED_PROJECTS, userId],
        async () => {
            const res = await api.projects.getSharedProjectsOfUser(userId)
            dispatch(fetchProjectsSucces(normalizeProjects(res)))
            return res
        },
        { staleTime: 1000 * 60 * 5 },
    )
    return query
}

export function useMyProjectsQuery(apiCallQuery: Omit<MyProjectsQuery, 'skip'>, skipCorrection: number) {
    const dispatch = useDispatch()

    function normalizeProjects(projects: NonNullable<ReturnType<typeof useMyProjectsQuery>['data']>[0]) {
        return projects.response.data.map(project => {
            return {
                file: project,
                metadata: {},
            }
        })
    }

    return useInfiniteQuery(
        [KEYS.MY_PROJECTS, apiCallQuery],
        async (_, __, skip: number = 0) => {
            const res = await api.projects.getMyProjects({ skip, ...apiCallQuery })
            const normalizedProjects = normalizeProjects(res)
            dispatch(fetchProjectsSucces(normalizedProjects))
            return res
        },
        {
            getFetchMore: lastPage => {
                return lastPage.nextSkip === null ? null : lastPage.nextSkip + skipCorrection
            },
            refetchOnMount: false,
            cacheTime: 60 * 1000,
        },
    )
}

export function useMyFavoriteProjectsQuery(enabled: boolean) {
    return useQuery(KEYS.MY_FAVORITE_PROJECTS, api.projects.getMyFavoriteProjects, {
        enabled,
        staleTime: Infinity,
    })
}

export function useProjectDiscoverQuery({
    query,
    enabled,
}: {
    query: Omit<DiscoverProjectsQuery, 'skip'>
    enabled: boolean
}) {
    const dispatch = useDispatch()

    return useInfiniteQuery(
        [KEYS.DISCOVER_PROJECTS, query],
        async (_, __, skip: number = 0) => {
            const res = await api.projects.getProjectsForDisovery({ ...query, skip })
            dispatch(fetchProjectsSucces(normalizeSharedProjects(res.response.data)))
            return res
        },
        {
            getFetchMore: lastPage => {
                return lastPage.nextSkip
            },
            refetchOnMount: false,
            staleTime: 5 * 60 * 1000,
            enabled: enabled,
        },
    )
}

export function useProjectQuery(id: string, embedOwner: boolean) {
    const dispatch = useDispatch()

    return useQuery(
        [KEYS.PROJECT, id],
        async () => {
            if (embedOwner) {
                const res = await api.projects.getProjectWithEmbeddedOwner(id)
                dispatch(
                    fetchProjectsSucces([
                        {
                            file: { ...res.data, owner: res.data.owner.id },
                            metadata: { owner: res.data.owner },
                        },
                    ]),
                )
                return res
            } else {
                const res = await api.projects.getProject(id)
                dispatch(fetchProjectsSucces([{ file: res.data, metadata: {} }]))
                return res
            }
        },
        {
            retry: (retryCount, error: AxiosError) =>
                error.response?.status === 400 || error.response?.status === 404 ? false : retryCount <= 2,
        },
    )
}

export function useCommunityProjectDetailsQuery(id: string, variant: ProjectVariant | null, enabled: boolean) {
    const dispatch = useDispatch()

    function normalizeProject(project: UnwrapPromise<ReturnType<typeof api.projects.getCommunityProjectDetails>>) {
        return {
            details: {
                ...(variant === 'normal' && { normal: project.fileData.data.details }),
                ...(variant === 'staging' && { staging: project.fileData.data.details }),
                ...(variant === 'production' && { production: project.fileData.data.details }),
            },
            cookied: project.cookied.normal,
            goldenCookied: project.cookied.golden,
            favorited: project.favorited,
        }
    }

    return useQuery(
        [KEYS.COMMUNITY_PROJECT_DATA, id, variant],
        async () => {
            // query is not enabled while variant is null, so we are sure it's defined here
            const res = await api.projects.getCommunityProjectDetails(id, variant!)
            dispatch(updateMetadata(id, normalizeProject(res)))
            return res
        },
        { enabled: variant !== null && enabled, staleTime: 1000 * 60 * 5 },
    )
}

export function useClassroomProjectDetailsQuery(id: string, enabled: boolean) {
    const dispatch = useDispatch()

    function normalizeProject(project: UnwrapPromise<ReturnType<typeof api.projects.getClassromProjectDetails>>) {
        return {
            details: {
                normal: project.fileData.data.details,
            },
        }
    }

    return useQuery(
        [KEYS.CLASSROOM_PROJECT_DATA, id],
        async () => {
            const res = await api.projects.getClassromProjectDetails(id)
            dispatch(updateMetadata(id, normalizeProject(res)))
            return res
        },
        { enabled: enabled, staleTime: 1000 * 60 * 5 },
    )
}

export function useMyShareableProjectsQuery(type: 'normal' | 'mini-quiz') {
    const dispatch = useDispatch()
    return useQuery(
        [KEYS.MY_SHAREABLE_PROJECTS, type],
        async () => {
            const res = await api.projects.getMyShareableProjects(type)
            dispatch(fetchProjectsSucces(res.data.map(p => ({ file: p, metadata: {} }))))
            return res
        },
        { cacheTime: 0 },
    )
}

export function useRecommendedProjectsQuery(type: 'coding' | 'mini-quiz') {
    const user = useUserState()
    const { data: allLanguages } = useLanguagesQuery()
    const languages = allLanguages ? getUserProjectLanguages(user, allLanguages.data) : undefined
    const dispatch = useDispatch()

    return useQuery(
        [KEYS.RECOMMENDED_PROJECTS, { languages, type }],
        async () => {
            const res = await api.projects.getRecommendedProjects(type, languages!)
            dispatch(fetchProjectsSucces(normalizeSharedProjects(res.data)))
            return res
        },
        { staleTime: Infinity, enabled: !!languages },
    )
}

export function useInvalidatePendingProjectsOnApprove() {
    const invalidate = useProjectQueryInvalidation()

    useEffect(() => {
        const onProjectShareApproved = () => {
            invalidate('MY_PENDING_PROJECTS')
            invalidate('MY_SHARED_PROJECTS')
            invalidate('MY_PROJECTS')
        }

        storeActionListener.on(PROJECT_SHARE_APPROVED, onProjectShareApproved)

        return () => {
            storeActionListener.removeListener(PROJECT_SHARE_APPROVED, onProjectShareApproved)
        }
    }, [invalidate])
}

export function useInvalidateProjectData() {
    const queryCache = useQueryCache()

    const invalidate = useCallback(
        (id: string, variant: ProjectVariant) => {
            queryCache.invalidateQueries([KEYS.COMMUNITY_PROJECT_DATA, id, variant])
        },
        [queryCache],
    )

    return invalidate
}

export function useProjectQueryInvalidation() {
    const queryCache = useQueryCache()

    return useCallback(
        (key: keyof typeof KEYS, remove = false) => {
            if (remove) {
                queryCache.removeQueries(KEYS[key])
            } else {
                return queryCache.invalidateQueries(KEYS[key])
            }
        },
        [queryCache],
    )
}

export function useBlockGalleriesQuery() {
    return useQuery([KEYS.BLOCK_GALLERIES], () => api.projects.getBlockGalleries(), {
        staleTime: Infinity,
    })
}

function normalizeSharedProjects(data: FilterSharedByOwnerOrTitleResponse) {
    return data.map(project => ({
        file: project,
        metadata: {
            cookied: project.cookied,
            goldenCookied: project.goldenCookied,
            owner: {
                id: project.owner!,
                username: project.owner_username,
                profileImage: project.owner_profileImage,
                primaryKind: project.owner_primaryKind,
                alias: project.owner_alias,
            },
        },
    }))
}

type PendingProjects = string[]

export function useMyPendingProjectsQuery(enabled = true) {
    const dispatch = useDispatch()

    return useQuery<PendingProjects>(
        KEYS.MY_PENDING_PROJECTS,
        async () => {
            const res = await api.projects.getMyPendingProjects()
            dispatch(
                fetchProjectsSucces(
                    res.data.map(project => {
                        return { file: project, metadata: {} }
                    }),
                ),
            )
            return res.data.map(project => project.id)
        },
        { enabled, staleTime: Infinity },
    )
}

export function usePendingProjectCache() {
    const queryCache = useQueryCache()

    return useMemo(
        () => ({
            add: (id: string) => {
                queryCache.setQueryData<PendingProjects | undefined>(KEYS.MY_PENDING_PROJECTS, data => [
                    ...(data || []),
                    id,
                ])
            },
            remove: (id: string) => {
                queryCache.setQueryData<PendingProjects | undefined>(KEYS.MY_PENDING_PROJECTS, data =>
                    data?.filter(projectId => projectId !== id),
                )
            },
        }),
        [queryCache],
    )
}

export function useMyActiveIncorrectyTaggedModerationReportsQuery() {
    return useQuery(KEYS.MY_MODERATION_REPORTS, () => api.projects.getMyActiveIncorrectlyTaggedModerationReports(), {
        cacheTime: 0,
    })
}

export function useMyCustomProjectLabelsQuery() {
    return useQuery(KEYS.MY_CUSTOM_PROJECT_LABELS, () => api.projects.getMyCustomProjectLabels(), {
        staleTime: Infinity,
    })
}

import styled from 'styled-components/macro'
import React from 'react'

interface EventDetailsBannerProps {
    src?: string
}

export const EventDetailsBanner: React.FC<EventDetailsBannerProps> = ({ src }) => {
    return <Root width={1600} height={400} src={src} />
}

export const Root = styled.img`
    border-radius: ${props => props.theme.shape.borderRadius}px;
    max-width: 100%;
    height: auto;
    background-color: ${props => props.theme.palette.divider};
`

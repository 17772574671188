import React from 'react'
import { Badge } from 'components/Badge'
import { MinimalAccount } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import { CommunityIcon } from 'components/icons'
import { Project, doesProjectHaveNewVersion, createProjectThumbnailUrl } from 'features/projects'
import { ProjectCardFavoriteBadge } from 'features/projects/card/ProjectCardFavoriteBadge'
import { MyCustomRightClickMenu } from 'features/projects/my/MyCustomRightClickMenu'
import { MyProjectStripeActions } from 'features/projects/my/MyProjectStripeActions'
import { ProjectActionsMenu } from 'features/projects/ProjectActionsMenu'
import { ProjectStripe } from 'features/projects/stripe'
import { MiniQuizCardMetadata } from './MiniQuizCardMetadata'
import { Theme, useMediaQuery } from '@material-ui/core'

interface MyMiniQuizCardProps {
    project: Project
    user: MinimalAccount
    isPending?: boolean
}

export const MyMiniQuizCard: React.FC<MyMiniQuizCardProps> = ({ project, isPending }) => {
    const t = useTranslate()
    const hasNewVersion = doesProjectHaveNewVersion(project)
    const title =
        project.file.shared || isPending === true ? project.file.title || project.file.name : project.file.name

    const newVersion = hasNewVersion && isPending === false
    const thumbnail = createProjectThumbnailUrl(project.file.id, project.file.shared ? 'production' : 'normal')
    const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), { noSsr: true })

    return (
        <MyCustomRightClickMenu
            menu={<ProjectActionsMenu location="stripe" isPending={isPending} id={project.file.id} isInContextMenu />}
        >
            <ProjectStripe
                thumbnailUrl={thumbnail}
                metadata={
                    isSmallScreen ? null : (
                        <MiniQuizCardMetadata
                            project={project}
                            variant={project.file.shared ? 'production' : 'normal'}
                        />
                    )
                }
                tags={
                    <>
                        {project.file.shared && (
                            <Badge
                                color="#2d3057"
                                backgroundColor={newVersion ? '#f4a103' : '#f4d003'}
                                icon={<CommunityIcon />}
                            >
                                {newVersion ? t('projects::newVersionAvailable') : t('projects::shared')}
                            </Badge>
                        )}
                        {isPending && <Badge>{t('projects::pendingApproval')}</Badge>}
                    </>
                }
                title={title}
                to={`/app/project/${project.file.id}`}
                actions={<MyProjectStripeActions project={project} isPending={isPending} />}
                favorite={<ProjectCardFavoriteBadge project={project} inList />}
            />
        </MyCustomRightClickMenu>
    )
}

import { Card, CardContent } from 'components/card'
import { FollowIcon, ViewIcon } from 'components/icons'
import { Stat } from 'components/stat'
import { currencyStyles } from 'features/projects/currencyStyles'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useSelector } from 'lib/store'
import React from 'react'
import styled from 'styled-components/macro'
import { FeedEntryCaption } from './FeedEntryCaption'

interface WelcomeStatsProps extends PoorMansSuspenseTicker {}

export const WelcomeStats: React.FC<WelcomeStatsProps> = ({ onReady }) => {
    const stats = useSelector(state => state.client.notifications.welcomeStats)
    const t = useTranslate()

    usePoorMansSuspenseTick(stats !== null, onReady)

    if (!stats || [stats.cookies, stats.views, stats.goldenCookies, stats.follows].every(stat => !stat || stat <= 0)) {
        return null
    }

    const createStatValue = (value: number | undefined) => (value && value > 0 ? `+${value}` : undefined)

    return (
        <div>
            <FeedEntryCaption>{t('feed::welcomeStats::label')}</FeedEntryCaption>
            <Card>
                <CardContent>
                    <Grid>
                        <Stat
                            title={t('profile::receivedCookies')}
                            backgroundColor={currencyStyles.cookie.colors.background.light}
                            value={createStatValue((stats.goldenCookies ?? 0) + (stats.cookies ?? 0))}
                            icon={<CookieImage src={currencyStyles.cookiesIcon} />}
                        />
                        <Stat
                            title={t('profile::projectViews')}
                            color="#2cc51e"
                            value={createStatValue(stats.views)}
                            icon={<ViewIcon fontSize="1.5em" />}
                        />
                        <Stat
                            title={t('profile::followers')}
                            color="#2d2add"
                            value={createStatValue(stats.follows)}
                            icon={<FollowIcon fontSize="1.5em" />}
                        />
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

const CookieImage = styled.img`
    width: 1.5em;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${props => props.theme.spacing(2)};
`

import { Button } from 'components/Button'
import { ForwardIcon } from 'components/icons'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'
import blob1Img from '../media/blob-1.png'
import blob2Img from '../media/blob-2.png'
import blob3Img from '../media/blob-3.png'

export const GuestWelcomeHero: React.FC = () => {
    const t = useTranslate()

    return (
        <Root>
            <Blob
                title={t('guestWelcomeCtas::title01')}
                img={blob1Img}
                to="/app/events/mylogiscool-mission"
                button={<BlobButton backgroundColor="#a50050" color="#fff" top="60%" />}
            />
            <Blob
                title={t('guestWelcomeCtas::title02')}
                img={blob2Img}
                to="/app/challenges"
                button={<BlobButton backgroundColor="#c4d600" color="#000" top="30%" />}
            />
            <Blob
                title={t('guestWelcomeCtas::title03')}
                img={blob3Img}
                to="/app/community"
                button={<BlobButton backgroundColor="#009add" color="#fff" top="60%" />}
            />
        </Root>
    )
}

interface BlobProps {
    title: string
    img: string
    button: React.ReactNode
    to: LinkProps['to']
}

const Blob: React.FC<BlobProps> = ({ title, img, button, to }) => {
    return (
        <BlobRoot to={to}>
            <BlobInner>
                <BlobImgWrap>
                    {button}
                    <BlobImg src={img} />
                </BlobImgWrap>
                <Title variant="h3">{title}</Title>
            </BlobInner>
        </BlobRoot>
    )
}

interface BlobButtonProps {
    top: string
    backgroundColor: string
    color: string
}

const BlobButton: React.FC<BlobButtonProps> = ({ top, backgroundColor, color }) => {
    const t = useTranslate()

    return (
        <BlobButtonRoot style={{ top }} endIcon={<ForwardIcon />} $backgroundColor={backgroundColor} $color={color}>
            {t('guestWelcomeCtas::button')}
        </BlobButtonRoot>
    )
}

const Title = styled(Text)`
    line-height: 1.3;
    max-width: 15ch;
    ${props => props.theme.breakpoints.down('sm')} {
        max-width: none;
    }
`

const BlobButtonRoot = styled(Button)<{ $backgroundColor: string; $color: string }>`
    position: absolute;
    right: 0;
    font-weight: 800;
    &,
    &:hover,
    &:focus-visible {
        filter: none;
        box-shadow: ${props => props.theme.shadows[4]};
        background: ${props => props.$backgroundColor};
        color: ${props => props.$color};
    }
`

const BlobImgWrap = styled.div`
    position: relative;
`

const BlobImg = styled.img`
    max-width: 100%;
`

const Root = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    align-items: flex-start;
    grid-column-gap: ${props => props.theme.spacing(4)};
    grid-row-gap: ${props => props.theme.spacing(10)};
`

const BlobInner = styled(Stack)`
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
`

const BlobRoot = styled(Link)`
    display: block;
    color: inherit;
    text-decoration: none;
    transition: filter ${props => props.theme.transitions.duration.standard}ms;
    &:hover,
    &:focus-visible {
        filter: brightness(0.9);

        ${BlobButtonRoot} {
            transform: translateX(5px);
        }
    }
`

import React from 'react'
import styled from 'styled-components/macro'
import { DialogActions as MuiDialogActions, DialogActionsProps as MuiDialogActionsProps } from '@material-ui/core'

type DialogActionsProps = MuiDialogActionsProps

export const DialogActions: React.FC<DialogActionsProps> = props => {
    return <StyledDialogActions {...props} />
}

const StyledDialogActions = styled(MuiDialogActions)`
    padding: ${props => props.theme.spacing(2, 5, 5, 5)};
`

import React from 'react'
import styled from 'styled-components/macro'
import { Text } from 'components/Text'
import { transparentize } from 'polished'

export interface StatProps {
    title: string
    icon?: React.ReactNode
    value?: number | string
    color?: string
    backgroundColor?: string
}

export const Stat: React.FC<StatProps> = ({ title, icon, value, color, backgroundColor }) => {
    return (
        <Root>
            <Title>
                {icon && (
                    <IconWrap $backgroundColor={backgroundColor} $color={color}>
                        {icon}
                    </IconWrap>
                )}{' '}
                <span>{title}</span>
            </Title>
            <Value>{value === undefined ? '–' : value}</Value>
        </Root>
    )
}

const IconWrap = styled.span<{ $color?: string; $backgroundColor?: string }>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${props =>
        props.$backgroundColor ?? transparentize(0.7, props.$color ?? props.theme.palette.text.primary)};
    padding: 4px;
    border-radius: 50%;
    color: ${props => props.$color};
`

const Title = styled(Text)`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing(2)};
    line-height: 1.1;
    ${props => props.theme.breakpoints.down('sm')} {
        flex-direction: column;
        text-align: center;
    }
`

const Value = styled(Text)`
    font-weight: 800;
    font-size: ${props => props.theme.typography.h5.fontSize};
    line-height: 1;
`

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    > * + * {
        margin-top: ${props => props.theme.spacing(2)};
    }
`

import { Button } from 'components/Button'
import { RightAngleIcon } from 'components/icons'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { useIncrementActivityScore } from '../../hooks/useIncrementActivityScore'

interface PanelMoreButtonProps {
    to: string
    activity?: string
}

export const PanelMoreButton: React.FC<PanelMoreButtonProps> = ({ to, activity, children }) => {
    const incrementActivityScore = useIncrementActivityScore()
    return (
        <StyledButton
            endIcon={<RightAngleIcon />}
            component={Link}
            to={to}
            color="primary"
            variant="outlined"
            onClick={() => {
                if (activity) incrementActivityScore(activity)
                return true
            }}
        >
            {children}
        </StyledButton>
    )
}

const StyledButton = styled(Button)`
    text-align: right;
    line-height: 1.3;
` as typeof Button

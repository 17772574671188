import React from 'react'
import { useQuery } from 'react-query'
import { api } from 'lib/api'
import { Loader } from 'components/Loader'
import { Stack } from 'components/layout'
import styled from 'styled-components/macro'
import { ErrorMessage } from 'components/ErrorMessage'
import { ChoiceResponse, ImageDialogue, NumberResponse } from 'features/quiz'
import { useDateFormat } from 'hooks/useDateFormat'
import { ListItemText } from 'components/list'
import { Text } from 'components/Text'
import { Card, CardHeader, CardContent } from 'components/card'
import { useLocalisedQuizValue } from './quizHelpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import { CheckIcon, FavoriteIcon, TrophyIcon } from 'components/icons'
import { StatCard } from 'components/stat'
import { transparentize } from 'polished'
import { QuizResult } from './QuizApi'

type Props = {
    sessionId: string
}

export const DetailedQuizResult: React.FC<Props> = ({ sessionId }) => {
    const t = useTranslate()
    const { formatDate } = useDateFormat('long')
    const { translateQuizLocaleString } = useLocalisedQuizValue()

    const { data: detailedQuizResult, status: detailedQuizStatus } = useQuery(
        ['detailedQuizResult', sessionId],
        () => api.quizzes.getDetailedQuizResult(sessionId),
        { staleTime: Infinity },
    )

    const { data: quizResult, status: quizResultStatus } = useQuery(
        ['quizResult', sessionId],
        () => api.quizzes.getQuizResult(sessionId),
        { staleTime: Infinity },
    )

    const isLoading = detailedQuizStatus === 'loading' || quizResultStatus === 'loading'

    const isError = detailedQuizStatus === 'error' || quizResultStatus === 'error'

    if (isLoading) {
        return <Loader />
    }

    if (isError || !quizResult || !detailedQuizResult) {
        return <ErrorMessage />
    }

    const result = { ...quizResult, ...detailedQuizResult }

    return (
        <Stack spacing={6}>
            <ListItemText
                primary={<Text variant="h2">{translateQuizLocaleString(result.name, result.nameKeys)}</Text>}
                secondary={`${t('general::completedAt')}: ${formatDate(new Date(quizResult.createdAt || ''))}`}
            />
            <Stack spacing={10}>
                <StatsWrap>
                    {result.nParticipants && result.nParticipants > 1 && (
                        <StatCard
                            icon={<TrophyIcon />}
                            title={t('quiz::placement')}
                            value={`${result.finalRank} / ${result.nParticipants}`}
                        />
                    )}
                    <StatCard icon={<FavoriteIcon />} title={t('quiz::score')} value={result.finalScore} />
                    <StatCard
                        icon={<CheckIcon />}
                        title={t('quiz::correctAnswers')}
                        value={`${result.nCorrectAnswers} / ${result.nQuestions}`}
                    />
                </StatsWrap>
                <Questions result={result} />
            </Stack>
        </Stack>
    )
}

const StatsWrap = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
    gap: ${props => props.theme.spacing(6)};
`

const Header = styled.header`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: ${props => props.theme.spacing(6)};
    padding: ${props => props.theme.spacing(6)};
    padding-bottom: ${props => props.theme.spacing(2)};
    .MuiCardHeader-root {
        padding: 0;
    }
    ${props => props.theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
        grid-gap: ${props => props.theme.spacing(2)};
    }
`

const StyledTypoTitle = styled(Text).attrs(() => ({
    variant: 'h3',
}))`
    font-weight: 600;
`

type QuestionsProps = {
    result: QuizResult
}

const Questions: React.FC<QuestionsProps> = ({ result }) => {
    const t = useTranslate()
    const { translateQuizLocaleString } = useLocalisedQuizValue()

    return (
        <QuestionsRoot spacing={8}>
            {result.questions.map((question, i) => {
                const userResponse = result.userResponses.find(response => response.question === question._id)

                const hasImage = !!(question?.primaryArt?.uri?.values && question.primaryArt.uri.values.length !== 0)

                const hasOldImage = !!(question?.primaryArt?.uri && !question?.primaryArt?.uri?.values)

                let imgSrc = hasOldImage ? question.primaryArt && question.primaryArt.uri : ''

                if (hasImage) {
                    imgSrc = question.primaryArt && translateQuizLocaleString(question?.primaryArt?.uri)
                }

                const responseTimeInSeconds = ((userResponse?.time || 0) / 1000).toFixed(2)
                const isCorrect = userResponse !== undefined && userResponse.score !== 0

                return (
                    <StyledCard $isCorrect={isCorrect} key={question._id}>
                        <Header>
                            {imgSrc && <ImageDialogue src={imgSrc as string} size={'6rem'} />}
                            <CardHeader
                                title={
                                    <StyledTypoTitle>
                                        {`${i + 1}. ${translateQuizLocaleString(question.description)}`}
                                    </StyledTypoTitle>
                                }
                                subheader={
                                    userResponse ? (
                                        <>
                                            {t('quiz::earnedScore')}: <strong>{userResponse?.score ?? 0}</strong>,{' '}
                                            {t('quiz::answerTime')}:{' '}
                                            <strong>{t('quiz::nSeconds', { value: responseTimeInSeconds })}</strong>
                                        </>
                                    ) : (
                                        <NoAnswer text={t('quiz::noAnswer')} />
                                    )
                                }
                            />
                        </Header>
                        <CardContent>
                            <Stack>
                                {question.kind === 'single-choice' && (
                                    <ChoiceResponse
                                        options={question?.data?.options}
                                        response={userResponse?.data?.selectedIndex}
                                        correctIndices={
                                            result.validationInfo && result.validationInfo[i].correctIndices
                                        }
                                    />
                                )}

                                {question.kind === 'multiple-choice' && (
                                    <ChoiceResponse
                                        options={question?.data?.options}
                                        response={userResponse?.data?.selectedIndices}
                                        correctIndices={
                                            result.validationInfo && result.validationInfo[i].correctIndices
                                        }
                                    />
                                )}
                                {question.kind === 'number' && (
                                    <NumberResponse
                                        response={userResponse?.data?.value}
                                        correctValue={result.validationInfo && result.validationInfo[i].correctValue}
                                    />
                                )}
                            </Stack>
                        </CardContent>
                    </StyledCard>
                )
            })}
        </QuestionsRoot>
    )
}

type NoAnswerProps = {
    text: string
}

const NoAnswer: React.FC<NoAnswerProps> = ({ text }) => <Container>😟 {text}</Container>

const QuestionsRoot = styled(Stack)`
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
`

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: ${props => props.theme.palette.text.primary};
`

const StyledCard = styled(Card)<{ $isCorrect: boolean }>`
    border: solid 2px
        ${props => (props.$isCorrect ? 'transparent' : transparentize(0.3, props.theme.palette.error.main))};
`

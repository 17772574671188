import { useUserState } from 'contexts/UserContext'
import { CookieHunt } from 'features/cookie-hunt'
import { LockedChallengeOverlayWithBorderRadius } from 'features/limited-accounts'
import { grantToString } from 'lib/grants'
import React from 'react'
import styled from 'styled-components/macro'
import { getHighscoreGames } from './highScoreGames'
import { HighScoreGameThumbnail } from './HighScoreGameThumbnail'
import { HighScoreGameThumbnailLink } from './HighScoreGameThumbnailLink'

export const HighScoreGamesList: React.FC = () => {
    const user = useUserState()
    const games = getHighscoreGames(user)

    return (
        <Grid>
            {games.slice(1).map((game, i) => (
                <LockedChallengeOverlayWithBorderRadius
                    isLocked={game.locked}
                    key={game.projectId}
                    grant={game.grantRequiredToUnlock ? grantToString(game.grantRequiredToUnlock) : undefined}
                >
                    <CookieHunt name="high-score-game-list" index={i}>
                        {game.locked ? (
                            <HighScoreGameThumbnail game={game} />
                        ) : (
                            <HighScoreGameThumbnailLink game={game} />
                        )}
                    </CookieHunt>
                </LockedChallengeOverlayWithBorderRadius>
            ))}
        </Grid>
    )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    grid-gap: ${props => props.theme.spacing(8)};
`

import { AxiosResponse } from 'axios'
import { UserState } from 'contexts/UserContext'
import { ApiTypes } from 'lib/api'
import { Grant } from '../grants'
import { preDefinedUserSchemas, userPreSets } from './userSchemas'

export const isGrantDevToolEnabled = (user: UserState) => user.account.primaryKind === 'developer'

export function processGrants(
    res: AxiosResponse<{
        grants: Grant[]
    }>,
) {
    const grantDevtoolState = localStorage.getItem('grantdevtool')
    if (grantDevtoolState) {
        try {
            const savedUserSchema: userPreSets = JSON.parse(grantDevtoolState)
            const schema = preDefinedUserSchemas.find(schema => schema.title === savedUserSchema.title)
            if (schema?.neededGrants) {
                res.data.grants = schema.neededGrants
            } else if (savedUserSchema.neededGrants) {
                res.data.grants = Object.entries(savedUserSchema.neededGrants)
                    .filter(([_, value]) => value)
                    .map(([grant]) => {
                        const [product, feature] = grant.split('/')
                        return {
                            product,
                            feature,
                        } as any
                    })
            }
            console.warn('Grant override is enabled!')
        } catch (e) {}
    }
}

export function processMe(res: AxiosResponse<ApiTypes['account']>) {
    const grantDevtoolState = localStorage.getItem('grantdevtool')
    if (grantDevtoolState) {
        try {
            const savedUserSchema: userPreSets = JSON.parse(grantDevtoolState)
            const schema = preDefinedUserSchemas.find(schema => schema.title === savedUserSchema.title)
            if (schema?.userStage) {
                res.data.stage = schema.userStage
            } else if (savedUserSchema.userStage) {
                res.data.stage = savedUserSchema.userStage
            }
            if (schema?.userSubStage) {
                res.data.subStage = schema.userSubStage
            }
            console.warn('Grant override is enabled!')
        } catch (e) {}
    }
}

import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'

interface BaseProps {
    src: string
    alt?: string
    badges?: React.ReactNode
    outerBadges?: React.ReactNode
    turnsGray?: boolean
    onClick?: any
}

// 'href' or 'to', but not both of them
type ProjectCardImageProps =
    | (BaseProps & {
          to?: LinkProps['to']
          href?: never
      })
    | (BaseProps & {
          href?: string
          to?: never
      })

export const ProjectCardImage: React.FC<ProjectCardImageProps> = ({
    src,
    alt,
    badges,
    to,
    href,
    onClick,
    outerBadges: clickableBadges,
    turnsGray,
}) => {
    const body = (
        <>
            {badges}
            <Img loading="lazy" width="640" height="360" src={src} alt={alt} $turnsGray={turnsGray} />
        </>
    )

    const container = (() => {
        if (href) {
            return (
                <ProjectCardImageRoot as="a" href={href} onClick={onClick} target="_blank">
                    {body}
                </ProjectCardImageRoot>
            )
        }

        if (to) {
            return (
                <ProjectCardImageRoot as={Link} to={to} onClick={onClick}>
                    {body}
                </ProjectCardImageRoot>
            )
        }

        return <ProjectCardImageRoot>{body}</ProjectCardImageRoot>
    })()

    return (
        <ProjectCardImageWrapper>
            {clickableBadges}
            {container}
        </ProjectCardImageWrapper>
    )
}

const RATIO = 360 / 640

export const ProjectCardImageRoot = styled.div`
    display: block;
    position: relative;
    height: 0;
    padding-bottom: ${RATIO * 100}%;
    overflow: hidden;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    will-change: transform;
    &:hover {
        > img {
            transform: scale(1.05);
            filter: brightness(1.05);
        }
    }
`

const Img = styled.img<{ $turnsGray: boolean | undefined }>`
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    max-width: 100%;
    background-color: ${props => props.theme.palette.divider};
    transition: all ${props => props.theme.transitions.duration.standard}ms cubic-bezier(0.68, -0.6, 0.32, 1.6);
    ${props => (props.$turnsGray ? 'filter:grayscale(1)' : undefined)}
`

const ProjectCardImageWrapper = styled.div`
    position: relative;
`

import { Theme, useMediaQuery } from '@material-ui/core'
import { Stack } from 'components/layout'
import { Panel, PanelTitle } from 'components/panel'
import React from 'react'
import { EventDefinition } from '../types/eventTypes'
import { OngoingEventBanner } from './OngoingEventBanner'
import { EventGrid } from './EventGrid'
import { EventThumbnail } from './EventThumbnail'
import { useTranslate } from 'lib/i18n/useTranslate'
import {ManagableEventDefinition} from "../types/eventTypesV2";

interface OngoingEventListProps {
    events: (EventDefinition | ManagableEventDefinition)[]
}

export const OngoingEventList: React.FC<OngoingEventListProps> = ({ events }) => {
    const isSmallDevice = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), { noSsr: true })
    const t = useTranslate()

    if (events.length === 0) {
        return null
    }

    return (
        <Panel>
            <PanelTitle>{events.length > 1 ? t('events::currentEvents') : t('events::currentEvent')}</PanelTitle>
            {isSmallDevice ? (
                <EventGrid size="big">
                    {events.map(event => {
                        return <EventThumbnail key={event.slug} event={event} />
                    })}
                </EventGrid>
            ) : (
                <Stack>
                    {events.map(event => {
                        return <OngoingEventBanner key={event.slug} event={event} />
                    })}
                </Stack>
            )}
        </Panel>
    )
}

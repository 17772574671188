import React from 'react'
import { EventDetailsBase } from 'features/events/components/EventDetailsBase'
import { EventDefinition } from 'features/events/types/eventTypes'
import { OnboardingAchievementList } from './components/OnboardingAchievementList'

interface OnboardingPageProps {
    event: EventDefinition
}

export const OnboardingPage: React.FC<OnboardingPageProps> = ({ event }) => {
    return (
        <EventDetailsBase event={event}>
            <OnboardingAchievementList />
        </EventDetailsBase>
    )
}

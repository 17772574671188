import { Box } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'

interface TextBodyProps {
    dangerouslySetInnerHTML?: { __html: string }
}

export const TextBody: React.FC<TextBodyProps> = ({ children, dangerouslySetInnerHTML }) => {
    // eslint-disable-next-line react/no-danger-with-children
    return <Root dangerouslySetInnerHTML={dangerouslySetInnerHTML}>{children}</Root>
}

const Root = styled(Box)`
    font-size: ${props => props.theme.typography.body1.fontSize};
    font-weight: ${props => props.theme.typography.body1.fontWeight};
    line-height: ${props => props.theme.typography.body1.lineHeight};

    h3 {
        font-size: ${props => props.theme.typography.h3.fontSize};
        font-weight: ${props => props.theme.typography.h3.fontWeight};
        line-height: ${props => props.theme.typography.h3.lineHeight};
    }

    h4 {
        font-size: ${props => props.theme.typography.h4.fontSize};
        font-weight: ${props => props.theme.typography.h4.fontWeight};
        line-height: ${props => props.theme.typography.h4.lineHeight};
    }

    > * {
        margin-top: 0;
        margin-bottom: 0;
    }

    > * + * {
        margin-block-start: 1rem;
    }

    > * + p,
    > * + ul,
    > * + li {
        margin-block-start: 0.75rem;
    }

    > * + h3 {
        margin-block-start: 2rem;
    }

    a {
        color: ${props => props.theme.palette.primary.main};
    }

    img {
        border-radius: ${props => props.theme.shape.borderRadius}px;
        max-width: 100%;
    }
`

import { Flex, Stack } from 'components/layout'
import React, { useEffect } from 'react'

import { PoorMansSuspenseRenderer, usePoorMansSuspense } from 'hooks/usePoorMansSuspense'
import { Loader } from 'components/Loader'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { MeetingList } from 'features/meetings'
import { SurveyList } from 'features/surveys'
import { FeedList } from './FeedList'
import { QueryParamProvider } from 'use-query-params'
import { Route } from 'react-router-dom'
import { ActivityEventFeedWeeklyMission } from 'features/events/activity-events/components/ActivityEventFeedWeeklyMission'

interface FeedProps {
    hideNoMoreEntriesText?: boolean
}

export const Feed: React.FC<FeedProps> = ({ hideNoMoreEntriesText }) => {
    const { tick, isReady } = usePoorMansSuspense(4)

    useEffect(() => {
        const hash = window.location.hash
        if (isReady && hash) {
            const id = hash.replace('#', '')
            const element = document.getElementById(id)
            if (element) element.scrollIntoView({ behavior: 'smooth' })
        }
    }, [isReady])

    return (
        <PoorMansSuspenseRenderer
            loader={
                <Flex justify="center">
                    <Loader />
                </Flex>
            }
            isReady={isReady}
        >
            <Stack spacing={8}>
                <Stack spacing={5}>
                    <ErrorBoundary silent>
                        <ActivityEventFeedWeeklyMission onReady={tick} />
                    </ErrorBoundary>
                    <ErrorBoundary silent>
                        <SurveyList onReady={tick} />
                    </ErrorBoundary>
                    <ErrorBoundary silent>
                        <MeetingList onReady={tick} />
                    </ErrorBoundary>
                    <ErrorBoundary silent>
                        <QueryParamProvider ReactRouterRoute={Route}>
                            <FeedList onReady={tick} />
                        </QueryParamProvider>
                    </ErrorBoundary>
                </Stack>
            </Stack>
        </PoorMansSuspenseRenderer>
    )
}

import React from 'react'
import { PublicProjectCard } from '../card'
import { useSelector } from 'lib/store'
import styled from 'styled-components/macro'
import { useProjectDiscoverQuery } from '../projectQueries'
import { projectListGridStyles } from '../commonProjectStyles'
import { DecodedValueMap } from 'serialize-query-params'
import { DiscoverProjectsFilterState } from './DiscoverProjects'
import { CookieHunt } from 'features/cookie-hunt'
import { PublicMiniQuizCard } from 'features/mini-quizzes'
import { Stack } from 'components/layout'

type DiscoverProjectsListProps = {
    query: ReturnType<typeof useProjectDiscoverQuery>
    filters: DecodedValueMap<DiscoverProjectsFilterState>
}

export const DiscoverProjectsList: React.FC<DiscoverProjectsListProps> = ({ query, filters }) => {
    const projects = useSelector(state => state.projects.all)

    return (
        <Grid>
            {query.data?.map((group, groupIndex) =>
                group.response.data.map((result, index) => {
                    const project = projects[result.id]
                    if (!project) {
                        return null
                    }
                    return (
                        <CookieHunt
                            key={project.file.id}
                            name="discover-projects-list"
                            disabled={!(groupIndex === 0 && index === 11)}
                        >
                            <PublicProjectCard project={project} user={project.metadata.owner} filters={filters} />
                        </CookieHunt>
                    )
                }),
            )}
        </Grid>
    )
}

export const DiscoverMiniQuizzesList: React.FC<DiscoverProjectsListProps> = ({ query }) => {
    const projects = useSelector(state => state.projects.all)

    return (
        <Stack>
            {query.data?.map(group =>
                group.response.data.map(result => {
                    const project = projects[result.id]
                    if (!project) {
                        return null
                    }
                    return <PublicMiniQuizCard key={project.file.id} project={project} user={project.metadata.owner} />
                }),
            )}
        </Stack>
    )
}

const Grid = styled.div`
    ${projectListGridStyles}
`

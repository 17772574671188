import { createContext, useContext } from 'react'

type ProjectDetailsState = {
    open: (event: any) => void
    close: () => void
    anchorEl: HTMLElement | null
}

export const popupMenuContext = createContext<ProjectDetailsState | undefined>(undefined)

export const usePopupMenuContext = () => {
    const context = useContext(popupMenuContext)

    if (context === undefined) {
        throw new Error('usePopupMenuContext must be used within a PopupMenuProvider')
    }

    return context
}

import React from 'react'
import styled from 'styled-components/macro'

export const ProgressBarIndicatorText: React.FC = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled.div`
    position: absolute;
    z-index: 2;
    right: ${props => props.theme.spacing(2)};
`

import React from 'react'
import { ViewIcon } from 'components/icons'
import { Inline } from 'components/layout'
import { ProjectCardBadge } from './ProjectCardBadge'
import styled from 'styled-components/macro'

interface ProjectCardViewCountProps {
    count: number
}

export const ProjectCardViewCount: React.FC<ProjectCardViewCountProps> = ({ count }) => {
    if (count === 0) {
        return null
    }

    return (
        <ProjectCardBadge position="bottom-left">
            <Inner spacing={1}>
                <span>{count}</span>
                <ViewIcon />
            </Inner>
        </ProjectCardBadge>
    )
}

const Inner = styled(Inline)`
    display: flex;
    align-items: center;
    color: ${props => props.theme.palette.common.white};
`

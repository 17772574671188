import { Card } from 'components/card'
import React from 'react'
import styled from 'styled-components/macro'
import { GeneratedQuizCardContent } from './GeneratedQuizCardContent'

interface GenerateQuizCardProps {}

export const GenerateQuizCard: React.FC<GenerateQuizCardProps> = () => {
    const [isTouchMode, setIsTouchMode] = React.useState(false)
    const [isMobileTapOverlayVisible, setIsMobileTapOverlayVisible] = React.useState(true)

    if (isTouchMode) {
        return (
            <MobileRoot>
                <Bg />
                <GeneratedQuizCardContent />
            </MobileRoot>
        )
    }

    return (
        <Root>
            <Bg />
            <GeneratedQuizCardContent />
            {isMobileTapOverlayVisible && (
                <MobileTapOverlay
                    onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setIsTouchMode(true)
                    }}
                    onTouchEnd={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        setIsTouchMode(true)
                    }}
                    onMouseEnter={() => {
                        setIsMobileTapOverlayVisible(false)
                    }}
                />
            )}
        </Root>
    )
}

const Bg = styled.span`
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #01659d;
    color: #d0e6f3;
    text-decoration: none;
    transition: filter ${props => props.theme.transitions.duration.standard}ms;
    background-image: url('https://api.prod.scoolcode.com/files/645e283e7964b331298be623/data');
    background-size: cover;
    background-position: center;
`

const MobileTapOverlay = styled.article`
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: red;
    opacity: 0;
`

const Root = styled(Card)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    position: relative;
    min-height: 300px;
    > div {
        opacity: 0;
        transition: opacity ${props => props.theme.transitions.duration.standard}ms;
    }
    &:hover > div,
    &:focus-visible > div {
        opacity: 1;
        transition: opacity ${props => props.theme.transitions.duration.standard}ms;
    }
    &:hover > span,
    &:focus-visible > span {
        filter: brightness(0.6);
        transition: filter ${props => props.theme.transitions.duration.standard}ms;
    }
` as typeof Card

const MobileRoot = styled(Card)`
    text-align: center;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: center;
    position: relative;
    min-height: 300px;
    > div {
        opacity: 1;
        transition: opacity ${props => props.theme.transitions.duration.standard}ms;
    }
    > span {
        filter: brightness(0.6);
        transition: filter ${props => props.theme.transitions.duration.standard}ms;
    }
` as typeof Card

import { StoreState } from 'lib/store'
import { FeedType } from '../types/feedTypes'

export function getUnreadFeedEntryCountByType(state: StoreState, feedType: FeedType) {
    return state.feed[feedType].entries.filter(entry => entry.metadata.unread).length
}

export function getUnreadFeedEntryCount(state: StoreState) {
    return getUnreadFeedEntryCountByType(state, 'community') + getUnreadFeedEntryCountByType(state, 'trainer')
}

export function getFeedEntryById(state: StoreState, id: string) {
    return (
        state.feed.community.entries.find(entry => entry._id === id) ??
        state.feed.trainer.entries.find(entry => entry._id === id)
    )
}

import { CurrencyBadge } from 'components/CurrencyBadge'
import { Tooltip } from 'components/Tooltip'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useMemo } from 'react'
import { useActivityEventContext } from '../helpers/activityEventContext'
import { ActivityEventContextProvider } from './ActivityEventContextProvider'
import dayjs from 'dayjs'
import { useEventsData } from '../../useEventsData'

export const ActivityEventPageCurrency: React.FC = () => {
    const events = useEventsData()

    const event = useMemo(() => {
        const now = dayjs()
        return events?.activityEvents.find(
            event =>
                dayjs(event.startDate).isBefore(now) &&
                dayjs(event.endDate).isAfter(now) &&
                !(event as any).managableEvent,
        )
    }, [events])

    if (!events || !event) {
        return null
    }

    return (
        <ActivityEventContextProvider fallback={null} event={event} allActivityEvents={events.activityEvents}>
            <ActivityEventPageCurrencyInner />
        </ActivityEventContextProvider>
    )
}

const ActivityEventPageCurrencyInner: React.FC = () => {
    const t = useTranslate()
    const { event, points } = useActivityEventContext()

    return (
        <Tooltip title={t(event.pointTooltipKey ?? 'digitalMission2022::missionPointsCounterTooltip')}>
            <CurrencyBadge
                backgroundColor={{ light: '#b7dae3', dark: '#005368' }}
                color={{ light: '#2b464d', dark: '#a8d9e5' }}
                iconUrl={event.pointImage}
                to={`/app/events/${event.slug}`}
                value={points.total ?? 0}
            />
        </Tooltip>
    )
}

import { Inline } from 'components/layout'
import { Text } from 'components/Text'
import { Project } from 'features/projects'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { MiniQuizCardCookieCount } from './MiniQuizCardCookieCount'

interface MiniQuizCardMetadataProps {
    project: Project
    variant: 'normal' | 'production'
}

export const MiniQuizCardMetadata: React.FC<MiniQuizCardMetadataProps> = ({ project, variant }) => {
    const t = useTranslate()

    return (
        <Root>
            <Text>{t('general::nQuestions', { count: project.metadata.numberOfQuestions?.[variant] ?? 0 })}</Text>
            {project.file.shared && <MiniQuizCardCookieCount project={project} />}
        </Root>
    )
}

const Root = styled(Inline)`
    display: flex;
    align-items: center;
`

import { TypographyTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'

export const FeedEntryCaption: OverridableComponent<TypographyTypeMap> = (props: TypographyTypeMap['props']) => {
    return <Root variant="caption" {...props} />
}

const Root = styled(Text)`
    display: inline-flex;
    align-items: center;
    margin-bottom: ${props => props.theme.spacing(1)};
    gap: ${props => props.theme.spacing(1)};
    opacity: 0.7;
`

import React from 'react'
import { Drawer } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Stack } from 'components/layout'
import {
    CloseIcon,
    ManageIcon,
} from 'components/icons'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { Divider } from 'components/Divider'
import { customScrollbar } from 'utils/styleUtils'
import {IconButton} from "components/IconButton";
import {PageTitle} from "components/page/PageTitle";
import {useManagementContext} from "../index";

export const MANAGEMENT_PANE_WIDTH = '25rem'

export const ManagementPane: React.FC = ({ children }) => {
    return (
        <StyledDrawer variant="permanent" anchor="right">
            <ManagementPaneHeader />
            <StyledDivider />
            <Stack spacing={5} mb={4}>
                {children}
            </Stack>
        </StyledDrawer>
    )
}

const ManagementPaneHeader: React.FC = () => {
    const { setEnabled } = useManagementContext()

    return (
        <Stack spacing={5} mb={4}>
            <HeaderWrap>
                <PageTitle icon={<ManageIcon />}>Manage Mode</PageTitle>
                <ErrorBoundary silent>
                    <CloseButtonWrap>
                        <IconButton onClick={() => setEnabled(false)}>
                            <CloseIcon />
                        </IconButton>
                    </CloseButtonWrap>
                </ErrorBoundary>
            </HeaderWrap>
        </Stack>
    )
}

const CloseButtonWrap = styled.div`
    position: relative;
    padding-left: ${props => props.theme.spacing(4)};
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 35px;
        background-color: ${props => props.theme.palette.divider};
        ${props => props.theme.extras?.pageAside?.dividers}
    }
`

const HeaderWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.spacing(2, 4, 0, 6)};
`

const StyledDrawer = styled(Drawer)`
    width: ${MANAGEMENT_PANE_WIDTH};
    & .MuiPaper-root {
        width: ${MANAGEMENT_PANE_WIDTH};
        ${customScrollbar}
    }
`

const StyledDivider = styled(Divider)`
    ${props => props.theme.extras?.pageAside?.dividers}
`

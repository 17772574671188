import { css } from 'styled-components/macro'
import { createTheme } from '../createTheme'

export const light5 = createTheme(
    { base: '#fafafa', aside: '#920b4c', primary: '#dc076f', asideContent: '#b56d6d' },
    {
        palette: {
            background: {
                default: '#f5f5fb',
            },
            primary: {
                main: '#dc076f',
            },
            text: {
                disabled: '#a2a2a3',
            },
        },
        extras: {
            themeColorMeta: '#a50050',
            logoType: 'white',

            pageHeader: {
                root: css`
                    background-color: #fff;
                `,
            },
            mobileHeader: {
                root: css`
                    background-color: #a50050;
                    color: white;
                `,
            },
            pageAside: {
                paper: css`
                    background: linear-gradient(#844242, #920b4c);
                    color: white;
                    border-right: none;
                `,
                secondary: css`
                    color: #c26d86;
                `,
                listItemAndNotificationButton: css`
                    &:hover,
                    &:focus-visible {
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                    &.Mui-selected,
                    &.Mui-selected:hover {
                        color: #ff63af;
                    }
                `,
            },
        },
    },
)

import { canUserCreateProject, UserState } from 'contexts/UserContext'
import { getXmasProjectId } from 'features/xmas-projects'
import { FeedEntry, FeedEntryCustomIds } from '../types/feedTypes'

type CustomFeedTransformer = (entry: FeedEntry, user: UserState) => FeedEntry

export const customFeedTransformers: Record<FeedEntryCustomIds, CustomFeedTransformer> = {
    'xmas-project-2021': (entry, user) => {
        return {
            ...entry,
            body: canUserCreateProject(user)
                ? 'Xmas2021NewsfeedPostPersonalizeQuiz::description'
                : 'Xmas2021NewsfeedPostQuiz::description',
            action: {
                actionType: 'link',
                to: `/app/xmas-game/${getXmasProjectId(user)}`,
                label: 'XmasGiftBanner::QuizProject::button',
            },
        }
    },
    'show-new-features-onboarding': entry => {
        return {
            ...entry,
            action: {
                ...entry.action,
                actionType: 'open-onboarding',
                to: '2022-APR',
            },
        }
    },
    'show-mini-quiz-onboarding': entry => {
        return {
            ...entry,
            action: {
                ...entry.action,
                actionType: 'open-onboarding',
                to: 'MINI-QUIZ',
            },
        }
    },
}

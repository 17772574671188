import { CardContent } from 'components/card'
import React from 'react'
import styled from 'styled-components/macro'

interface AchievementCardContentProps {}

export const AchievementCardContent: React.FC<AchievementCardContentProps> = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled(CardContent)`
    text-align: center;
`

import { Box } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'
import { BoxProps } from './'

interface WrappingInlineProps extends BoxProps {
    spacing?: number
}

export const WrappingInline: React.FC<WrappingInlineProps> = ({ spacing = 4, ...rest }) => {
    return <Root spacing={spacing} {...rest} />
}

const Root = styled(Box)<{ spacing: number }>`
    display: flex;
    flex-wrap: wrap;
    margin: calc(${props => props.theme.spacing(props.spacing)} / -2);
    > * {
        margin: calc(${props => props.theme.spacing(props.spacing)} / 2);
    }
`

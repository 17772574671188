import fcb3ffd4a7e0a00092cb468 from './5fcb3ffd4a7e0a00092cb468.png'
import fcb40ac355ce60009048388 from './5fcb40ac355ce60009048388.png'
import fcb40bf4a7e0a00092cb4cd from './5fcb40bf4a7e0a00092cb4cd.png'
import fcb405c355ce60009048350 from './5fcb405c355ce60009048350.png'
import fcb409b4a7e0a00092cb4b4 from './5fcb409b4a7e0a00092cb4b4.png'
import fcb40704a7e0a00092cb492 from './5fcb40704a7e0a00092cb492.png'
import fcb4010355ce60009048330 from './5fcb4010355ce60009048330.png'
import fcb4026355ce60009048336 from './5fcb4026355ce60009048336.png'
import fcb4045355ce60009048343 from './5fcb4045355ce60009048343.png'
import fcb4088355ce60009048363 from './5fcb4088355ce60009048363.png'

type ImagesRecord = Record<string, string>

export const images: ImagesRecord = {
    '5fcb3ffd4a7e0a00092cb468': fcb3ffd4a7e0a00092cb468,
    '5fcb40ac355ce60009048388': fcb40ac355ce60009048388,
    '5fcb40bf4a7e0a00092cb4cd': fcb40bf4a7e0a00092cb4cd,
    '5fcb405c355ce60009048350': fcb405c355ce60009048350,
    '5fcb409b4a7e0a00092cb4b4': fcb409b4a7e0a00092cb4b4,
    '5fcb40704a7e0a00092cb492': fcb40704a7e0a00092cb492,
    '5fcb4010355ce60009048330': fcb4010355ce60009048330,
    '5fcb4026355ce60009048336': fcb4026355ce60009048336,
    '5fcb4045355ce60009048343': fcb4045355ce60009048343,
    '5fcb4088355ce60009048363': fcb4088355ce60009048363,
}

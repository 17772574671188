import React from 'react'
import { ListItemIcon as MuiListItemIcon, ListItemIconProps as MuiListItemIconProps } from '@material-ui/core'
import styled from 'styled-components/macro'

interface ListItemIconProps extends MuiListItemIconProps {}

export const ListItemIcon: React.FC<ListItemIconProps> = props => {
    return <Root {...props} />
}

const Root = styled(MuiListItemIcon)`
    min-width: 2.5rem;
`

import { ErrorBoundary } from 'components/ErrorBoundary'
import { Stack } from 'components/layout'
import { Panel, PanelHeader, PanelTitle } from 'components/panel'
import { CookieHunt } from 'features/cookie-hunt'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { EventDefinition } from '../types/eventTypes'
import { EventGrid } from './EventGrid'
import { EventThumbnail } from './EventThumbnail'
import { OngoingEventList } from './OngoingEventList'
import {ManagableEventDefinition} from "../types/eventTypesV2";

interface EventListProps {
    events: (EventDefinition | ManagableEventDefinition)[]
}

export const EventList: React.FC<EventListProps> = ({ events }) => {
    const t = useTranslate()

    const onGoingEvents = events.filter(event => event.ongoing === true)
    const pastEvents = events.filter(event => event.ongoing === false)

    return (
        <Stack spacing={10}>
            <ErrorBoundary silent>
                <OngoingEventList events={onGoingEvents} />
            </ErrorBoundary>
            <ErrorBoundary silent>
                <Panel>
                    <PanelHeader>
                        <PanelTitle>{t('events::previousEvents')}</PanelTitle>
                    </PanelHeader>
                    <EventGrid size="small">
                        {pastEvents.map((event, i) => (
                            <CookieHunt name="prev-event-list" index={i} key={event.slug}>
                                <EventThumbnail event={event} />
                            </CookieHunt>
                        ))}
                    </EventGrid>
                </Panel>
            </ErrorBoundary>
        </Stack>
    )
}

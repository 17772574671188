import React from 'react'
import { Box } from 'components/layout'
import styled from 'styled-components/macro'
import { PageContainer } from './PageContainer'

export const PageContent: React.FC = ({ children }) => {
    return (
        <Root>
            <PageContainer>{children}</PageContainer>
        </Root>
    )
}

const Root = styled(Box)`
    padding-top: ${props => props.theme.spacing(8)};
    padding-bottom: ${props => props.theme.spacing(8)};
`

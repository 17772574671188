import { UserState } from 'contexts/UserContext'
import { hasGrant } from 'lib/grants'

export const isRecordingDevToolEnabled = (user: UserState) => {
    return (
        hasGrant(user.grants)('liveops-recorder') || window.localStorage.getItem('liveops-recording-enabled') === 'true'
    )
}

export function downloadFile(filename: string, text: string) {
    var element = document.createElement('a')
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
    element.setAttribute('download', filename)

    element.style.display = 'none'
    document.body.appendChild(element)

    element.click()

    document.body.removeChild(element)
}

export function isLiveopsRecording() {
    return window.localStorage.getItem('liveops-is-recording') === 'true'
}

export function setLiveopsRecording(value: boolean) {
    window.localStorage.setItem('liveops-is-recording', `${value}`)
}

import { useUserState } from 'contexts/UserContext'
import dayjs from 'dayjs'
import { CrmUserData } from 'lib/api'

export const getUserAge = (crmData: CrmUserData) =>
    crmData.birthDate ? dayjs().diff(dayjs(crmData.birthDate), 'years') : undefined

export const useGetUserAge = () => {
    const { crm } = useUserState()

    return getUserAge(crm)
}

import React, { useState } from 'react'
import { Button, ButtonProps } from 'components/Button'
import { useTranslate } from 'lib/i18n/useTranslate'
import { createPrivateHighScoreRoom } from '../projects'
import { useDispatch } from 'react-redux'
import { Stack } from '../../components/layout'
import { useSelector } from '../../lib/store'
import { setLeaderboardLength } from 'lib/liveops'
import { Dialog, DialogForm, DialogPrimaryButton, DialogSecondaryButton, DialogTitle } from 'components/dialog'
import { Collapse } from 'components/Collapse'
import { ErrorMessage } from 'components/ErrorMessage'
import {
    FormControl,
    FormHelperText,
    FormLabel,
    ImageSelect,
    ImageSelectOption,
    ImageSelectOptionWrap,
    InputAdornment,
    TextField,
} from 'components/form'
import { storeActionListener } from 'lib/store/store-action-listener'
import { ROOM_CREATED } from 'lib/liveops/constants'
import styled from 'styled-components/macro'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { HighScoreGameThumbnail } from './HighScoreGameThumbnail'
import { getHighscoreGames, HighScoreGameWithLockedFlag } from './highScoreGames'
import { useUserState } from 'contexts/UserContext'

type Status =
    | { name: 'idle' }
    | { name: 'open' }
    | { name: 'loading'; values: FormValues }
    | { name: 'error' }
    | { name: 'success' }

type ButtonMode = { name: 'list-games' } | { name: 'preselected-game'; projectId: string }
interface CreateHighScoreRoomButtonProps {
    mode: ButtonMode
    buttonProps?: ButtonProps
}

export const CreateHighScoreRoomButton: React.FC<CreateHighScoreRoomButtonProps> = ({ mode, buttonProps }) => {
    const t = useTranslate()
    const dispatch = useDispatch()
    const user = useUserState()
    const games = React.useMemo(() => getHighscoreGames(user).filter(game => !game.locked), [user])

    const [projectId, setProjectId] = React.useState(() =>
        mode.name === 'preselected-game' ? mode.projectId : games[0].projectId,
    )
    const projectStats = useSelector(state => state.shared.stats.scoreList[projectId])
    const [status, setStatus] = useState<Status>({ name: 'idle' })
    const history = useHistory()
    const isCreateDialogOpen = status.name === 'open' || status.name === 'loading' || status.name === 'error'

    useEffect(() => {
        if (status.name === 'loading') {
            const { name, days, hours, minutes } = status.values

            const totalHours = parseInt(days) * 24 + parseInt(hours) + parseInt(minutes) / 60
            dispatch(createPrivateHighScoreRoom(projectId, name || t('highScoreRoom::defaultRoomName'), totalHours))

            const onSuccess = ({ payload }: any) => {
                if (projectStats && payload.id) {
                    projectStats.forEach(stat => {
                        dispatch(setLeaderboardLength(projectId, stat, 100, payload.id))
                    })
                }

                setStatus({ name: 'success' })
                history.push(`/app/high-score-room-results/${projectId}/${payload.id}`)
            }

            const timeout = setTimeout(() => {
                setStatus({ name: 'error' })
            }, 15000)

            storeActionListener.once(ROOM_CREATED, onSuccess)

            return () => {
                clearTimeout(timeout)
                storeActionListener.removeListener(ROOM_CREATED, onSuccess)
            }
        }
    }, [status, dispatch, projectId, t, projectStats, history])

    const createRoom = (values: FormValues) => {
        if (status.name !== 'loading') {
            setStatus({ name: 'loading', values })
        }
    }

    return (
        <>
            <CreateHighScoreRoomDialog
                open={isCreateDialogOpen}
                createRoom={createRoom}
                isLoading={status.name === 'loading'}
                isError={status.name === 'error'}
                onClose={() => {
                    if (status.name !== 'loading') {
                        setStatus({ name: 'idle' })
                    }
                }}
                projectId={projectId}
                setProjectId={setProjectId}
                mode={mode}
                games={games}
            />
            <Stack>
                <Button
                    onClick={() => {
                        if (!isCreateDialogOpen) {
                            setStatus({ name: 'open' })
                        }
                    }}
                    {...buttonProps}
                >
                    {t('highScoreRoom::create')}
                </Button>
            </Stack>
        </>
    )
}

interface FormValues {
    name: string
    days: string
    hours: string
    minutes: string
}

interface CreateHighScoreRoomDialogProps {
    open: boolean
    createRoom(values: FormValues): void
    isLoading: boolean
    isError: boolean
    onClose: VoidFunction
    projectId?: string
    setProjectId: (id: string) => void
    mode: ButtonMode
    games: HighScoreGameWithLockedFlag[]
}

const CreateHighScoreRoomDialog: React.FC<CreateHighScoreRoomDialogProps> = ({
    open,
    isLoading,
    onClose,
    isError,
    createRoom,
    projectId,
    setProjectId,
    mode,
    games,
}) => {
    const t = useTranslate()
    const { register, handleSubmit, setError, errors } = useForm<FormValues>({
        defaultValues: { name: t('highScoreRoom::defaultRoomName'), days: '0', hours: '0', minutes: '0' },
    })

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{t('highScoreRoom::create')}</DialogTitle>
            <DialogForm
                onSubmit={handleSubmit(values => {
                    if (parseInt(values.days) <= 0 && parseInt(values.hours) <= 0 && parseInt(values.minutes) <= 0) {
                        setError('days', { message: t('highScoreRoom::invalidDuration') })
                        return
                    }
                    createRoom(values)
                })}
                actions={
                    <>
                        <DialogSecondaryButton onClick={onClose}>{t('general::cancel')}</DialogSecondaryButton>
                        <DialogPrimaryButton isLoading={isLoading} type="submit">
                            {t('highScoreRoom::create')}
                        </DialogPrimaryButton>
                    </>
                }
            >
                <Stack>
                    {mode.name === 'list-games' && (
                        <ImageSelect
                            label={t('challenges::highScoreGames')}
                            value={projectId}
                            onChange={e => {
                                setProjectId(e.target.value as string)
                            }}
                        >
                            {games.map(game => {
                                return (
                                    <ImageSelectOption value={game.projectId} key={game.projectId}>
                                        <ImageSelectOptionWrap
                                            label={t(game.title)}
                                            image={<StyleHighscoreThumbnail game={game} />}
                                        />
                                    </ImageSelectOption>
                                )
                            })}
                        </ImageSelect>
                    )}
                    <TextField
                        label={t('highScoreRoom::roomName')}
                        name="name"
                        inputProps={{ maxLength: 50 }}
                        ref={register({ maxLength: 50 })}
                    />
                    <StyledFieldSet>
                        <FormControl>
                            <FormLabel as="legend">{t('general::duration')}</FormLabel>
                            {errors.days && <FormHelperText error>{errors.days.message}</FormHelperText>}
                            <TimeWrap>
                                <TextField
                                    endAdornment={<InputAdornment position="end">{t('general::days')}</InputAdornment>}
                                    type="number"
                                    name="days"
                                    inputProps={{ min: 0, max: 30 }}
                                    ref={register({ min: 0, max: 30 })}
                                />
                                <TextField
                                    endAdornment={<InputAdornment position="end">{t('general::hours')}</InputAdornment>}
                                    type="number"
                                    name="hours"
                                    inputProps={{ min: 0, max: 23 }}
                                    ref={register({ min: 0, max: 23 })}
                                />
                                <TextField
                                    endAdornment={
                                        <InputAdornment position="end">{t('general::minutes')}</InputAdornment>
                                    }
                                    type="number"
                                    name="minutes"
                                    inputProps={{ min: 0, max: 59 }}
                                    ref={register({ min: 0, max: 59 })}
                                />
                            </TimeWrap>
                        </FormControl>
                    </StyledFieldSet>
                    <Collapse in={isError}>
                        <ErrorMessage />
                    </Collapse>
                </Stack>
            </DialogForm>
        </Dialog>
    )
}

const StyledFieldSet = styled.fieldset`
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    border: none;
`

const TimeWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${props => props.theme.spacing(4)};
`

const StyleHighscoreThumbnail = styled(HighScoreGameThumbnail)`
    max-width: 5rem;
`

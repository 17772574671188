import React, { useState } from 'react'
import { SecretCodeInput } from './SecretCodeInput'
import { useForm } from 'react-hook-form'
import { Collapse } from '@material-ui/core'
import { DialogForm, DialogSecondaryButton, DialogPrimaryButton } from 'components/dialog'
import { ErrorMessage } from 'components/ErrorMessage'
import { Stack } from 'components/layout'
import { api } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useProcessSecretCodeRedeemResponse } from '.'
import { SecretCodeRedeemResponse } from './SecretCodesApi'
import { LocalisationKey } from 'lib/i18n'
import { CookieHunt } from 'features/cookie-hunt'

interface SecretCodeUnlockerProps {
    close: VoidFunction
    primaryLabel: string
    customProcessor?: (response: SecretCodeRedeemResponse) => Promise<boolean>
    helperText?: string
}

const incorrectLengthMessage: LocalisationKey = 'secretCode::incorrectLength'

export const SecretCodeUnlocker: React.FC<SecretCodeUnlockerProps> = ({
    close,
    primaryLabel,
    customProcessor,
    helperText,
}) => {
    const [status, setStatus] = useState<'idle' | 'loading' | 'error'>('idle')
    const { register, handleSubmit, errors, setError } = useForm<{ value: string }>()
    const t = useTranslate()
    const processSecretCodeRedeemResponse = useProcessSecretCodeRedeemResponse()

    const processSecretCode = async (data: SecretCodeRedeemResponse) => {
        if (customProcessor) {
            const success = await customProcessor(data)
            if (!success) {
                const message: LocalisationKey = 'secretCode::invalidCode'
                setError('value', { message })
            }
        } else {
            processSecretCodeRedeemResponse(data, close)
        }
    }

    const onSubmit = handleSubmit(async values => {
        if (status !== 'loading') {
            setStatus('loading')
            try {
                const { data } = await api.secretCodes.redeem(values.value)
                if (data.success) {
                    await processSecretCode(data)
                } else {
                    const message: LocalisationKey = 'secretCode::invalidCode'
                    setError('value', { message })
                }
                setStatus('idle')
            } catch (e) {
                console.error(e)
                setStatus('error')
            }
        }
    })

    return (
        <DialogForm
            onSubmit={onSubmit}
            actions={
                <>
                    <DialogSecondaryButton onClick={close}>{t('general::cancel')}</DialogSecondaryButton>
                    <CookieHunt name="secret-code-unlocker">
                        <DialogPrimaryButton isLoading={status === 'loading'} type="submit">
                            {primaryLabel}
                        </DialogPrimaryButton>
                    </CookieHunt>
                </>
            }
        >
            <Stack>
                <Collapse unmountOnExit in={status === 'error'}>
                    <ErrorMessage />
                </Collapse>
                <SecretCodeInput
                    name="value"
                    errorText={errors.value ? t(errors.value.message as LocalisationKey) : undefined}
                    ref={register({
                        required: { value: true, message: incorrectLengthMessage },
                        minLength: { value: 6, message: incorrectLengthMessage },
                        maxLength: { value: 6, message: incorrectLengthMessage },
                    })}
                    helperText={helperText}
                />
            </Stack>
        </DialogForm>
    )
}

import { UserState } from 'contexts/UserContext'

const data: Record<string, { secretCode: string; projectId: string }> = {
    'en-US': { secretCode: 'XMASUS', projectId: '5fd347fd0e706e0008d4c2f0' },
    'hu-HU': { secretCode: 'XMASHU', projectId: '5fd34ede41a27f000938d842' },
    'ro-RO': { secretCode: 'XMASRO', projectId: '5fd34eef41a27f000938d845' },
    'cs-CZ': { secretCode: 'XMASCZ', projectId: '5fd34f460e706e0008d4c366' },
    'de-DE': { secretCode: 'XMASDE', projectId: '5fd34f5541a27f000938d84f' },
    'de-CH': { secretCode: 'XMASCH', projectId: '5fd34f640e706e0008d4c368' },
    'es-ES': { secretCode: 'XMASES', projectId: '5fd34f720e706e0008d4c36d' },
    'es-MX': { secretCode: 'XMASMX', projectId: '5fd34f7c41a27f000938d853' },
    'es-CO': { secretCode: 'XMASES', projectId: '5fd34f720e706e0008d4c36d' },
    'fr-BE': { secretCode: 'XMASBE', projectId: '5fd34fbf0e706e0008d4c37c' },
    'fr-CA': { secretCode: 'XMASBE', projectId: '5fd34fbf0e706e0008d4c37c' },
    'fr-CH': { secretCode: 'XMASBE', projectId: '5fd34fbf0e706e0008d4c37c' },
    'fr-FR': { secretCode: 'XMASBE', projectId: '5fd34fbf0e706e0008d4c37c' },
    'en-IN': { secretCode: 'XMASIN', projectId: '5fd34f9c0e706e0008d4c376' },
    'bg-BG': { secretCode: 'XMASBG', projectId: '5fd34fb441a27f000938d857' },
    'sk-SK': { secretCode: 'XMASSK', projectId: '5fd34f880e706e0008d4c370' },
    'zh-TW': { secretCode: 'XMASTW', projectId: '5fd38e0b0e706e0008d4f202' },
    'nl-BE': { secretCode: 'XMASNL', projectId: '61c1a56c96c1558ea7287fc6' },
    'vi-VN': { secretCode: 'XMASVN', projectId: '61c1a8a5289ead043ac73767' },
}

export const isValidXmasProjectId = (id: string) => {
    return !!Object.entries(data).find(([_, value]) => value.projectId === id)
}

export const getXmasProjectSecretCode = ({ i18n }: UserState) => {
    return data[i18n.locale]?.secretCode ?? data['en-US'].secretCode
}

export const getXmasProjectId = ({ i18n }: UserState) => {
    return data[i18n.locale]?.projectId ?? data['en-US'].projectId
}

import React, { useCallback } from 'react'
import { popupMenuContext } from './popupMenuContext'

interface PopupMenuProps {
    onOpened?: () => void
    onClosed?: () => void
}

export const PopupMenu: React.FC<PopupMenuProps> = ({ children, onOpened, onClosed }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const open = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        if(onOpened) onOpened()
    }, [ onOpened ])

    const close = useCallback(() => {
        setAnchorEl(null);
        if(onClosed) onClosed()
    }, [ onClosed ])

    return <popupMenuContext.Provider value={{ open, close, anchorEl }}>{children}</popupMenuContext.Provider>
}

import { ProjectShare } from 'features/projects'
import { ProjectModalBase } from 'features/projects'
import React from 'react'

export const ShareProjectPage = () => {
    return (
        <ProjectModalBase
            projectVariant="normal"
            asideWidth="600px"
            isNotFound={({ project, isOwnProject }) => {
                if (project) {
                    if (!isOwnProject) {
                        return true
                    }
                    return !project.metadata.shareable
                }
                return false
            }}
        >
            <ProjectShare />
        </ProjectModalBase>
    )
}

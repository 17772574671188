import { AxiosInstance } from 'axios'
import { CrmGroup, FinishedCourse } from 'lib/api'
import { ApiBase } from 'lib/api/ApiBase'
import { CertInventoryItem } from './certificateTypes'

export class CertificatesApi extends ApiBase {
    constructor(client: AxiosInstance, private getUserId: () => string | null) {
        super(client)
    }

    getCrmGroup = async (id: string, subjectId: string | null) => {
        const res = await this.client.get<CrmGroup>(
            `accounts/crm-group?group_id=${id}${subjectId ? '&subject_id=' + subjectId : ''}`,
        )
        res.data.lastDay = new Date(res.data.lastDay)
        res.data.firstDay = new Date(res.data.firstDay)
        return res.data
    }

    getFinishedGroups = async () => {
        const userId = this.getUserId()
        const res = await this.client.get<FinishedCourse[]>(`accounts/${userId}/finished-groups`)

        const filteredGroups = res.data
            .map(course => ({ ...course, lastDay: new Date(course.lastDay), firstDay: new Date(course.firstDay) }))
            .filter(
                course =>
                    course.coursetype_code &&
                    course.school_name &&
                    course.lastDay &&
                    course.firstDay &&
                    course.lastDay.getUTCFullYear() >= 2020,
            )
            .sort((a, b) => (a.lastDay > b.lastDay ? -1 : 1))

        return filteredGroups
    }
    getCrmCertificates = async () => {
        return await this.client.get<CertInventoryItem[]>(`inventory-items/my-certificates`)
    }
}

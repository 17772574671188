import React, { forwardRef } from 'react'
import { InputProps } from '@material-ui/core'
import styled from 'styled-components/macro'
import { FormControl, FormHelperText, FormLabel } from 'components/form'
import { visuallyHidden } from 'utils/styleUtils'
import { Input } from './Input'

export interface TextFieldProps extends InputProps {
    label?: React.ReactNode
    helperText?: React.ReactNode
    visuallyHiddenLabel?: React.ReactNode
    helperTextBefore?: React.ReactNode
    errorText?: React.ReactNode
}

export const TextField = forwardRef<any, TextFieldProps>(
    (
        {
            label,
            value,
            helperText,
            errorText,
            fullWidth = true,
            error,
            helperTextBefore,
            visuallyHiddenLabel,
            ...rest
        },
        ref,
    ) => {
        return (
            <FormControl fullWidth={fullWidth}>
                {label && <FormLabel htmlFor={rest.name}>{label}</FormLabel>}
                {visuallyHiddenLabel && (
                    <VisuallyHiddenLabel htmlFor={rest.name}>{visuallyHiddenLabel}</VisuallyHiddenLabel>
                )}
                {helperTextBefore && <FormHelperText>{helperTextBefore}</FormHelperText>}
                <Input error={!!errorText} inputRef={ref} value={value} id={rest.name} {...rest} />
                {errorText && <FormHelperText error>{errorText}</FormHelperText>}
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        )
    },
)

const VisuallyHiddenLabel = styled(FormLabel)`
    ${visuallyHidden}
`

import { ActionCardContent } from 'components/action-card'
import { Card } from 'components/card'
import { Img } from 'components/Img'
import { Text } from 'components/Text'
import { createFeedImageUrl } from 'features/feed/data/feedhelper'
import { FeedEntryComponent } from 'features/feed/types/feedTypes'
import React from 'react'
import styled from 'styled-components/macro'
import { actionablePaperStyles } from 'utils/styleUtils'
import { FeedEntryMetaAction } from '../FeedEntryMetaAction'
import { FeedEntryBase } from './FeedEntryBase'

export const MinorEntry: FeedEntryComponent = ({ entry }) => {
    return (
        <FeedEntryBase entry={entry}>
            <FeedEntryMetaAction entry={entry}>
                <StyledCard>
                    <Inner>
                        <Icon src={createFeedImageUrl(entry.image)} alt="" />
                        <Text>{entry.body}</Text>
                    </Inner>
                </StyledCard>
            </FeedEntryMetaAction>
        </FeedEntryBase>
    )
}

const StyledCard = styled(Card)`
    ${actionablePaperStyles}
`

const Icon = styled(Img)`
    width: 1.75rem;
    height: 1.75rem;
    object-fit: cover;
    border-radius: 5px;
`

const Inner = styled(ActionCardContent)`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: stretch;
    grid-gap: ${props => props.theme.spacing(4)};
    padding: ${props => props.theme.spacing(2)};
`

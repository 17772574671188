import React from 'react'
import { Dialog } from 'components/dialog'
import { useModal } from 'lib/router/modals/modalContext'
import { Redirect, useParams } from 'react-router-dom'

import { highScoreGames } from 'features/high-score/highScoreGames'
import { HighScoreRoomResults } from 'features/high-score/HighScoreRoomResults'

export const HighScoreRoomResultsPage = () => {
    const { closeModal } = useModal()
    const { id, roomId } = useParams<{ id: string; roomId: string }>()
    const game = highScoreGames.find(game => game.projectId === id)

    if (!game) {
        return <Redirect to="/app" />
    }

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            PaperProps={{ style: { height: '100%' } }}
            open={true}
            onClose={() => {
                closeModal()
            }}
        >
            <HighScoreRoomResults game={game} roomId={roomId} />
        </Dialog>
    )
}

import { CertInventoryItem } from 'features/certificates/certificateTypes'
import { useHasGrant } from 'lib/grants'
import { StoreState } from 'lib/store'

export function useHasDigitalMission2020Access() {
    const hasGrant = useHasGrant()
    return hasGrant({ product: 'edup.universe', feature: 'content.digital-mission' })
}

const DIGITAL_MISSION_2021_INV_ITEM_ID = '60ffb062f2ba06a9fd717fd9' //this is dm 2020 inv item id for test

export function getDigitalMission2021Cert(store: StoreState) {
    return store.client.inventory?.find(item => item.item === DIGITAL_MISSION_2021_INV_ITEM_ID) as CertInventoryItem
}

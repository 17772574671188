import React from 'react'
import styled from 'styled-components/macro'

export const AvatarBadge: React.FC = ({ children }) => {
    return <AvatarBadgeRoot>{children}</AvatarBadgeRoot>
}

const AvatarBadgeRoot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.spacing(0.2)};
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.contrastText};
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    box-shadow: ${props => props.theme.shadows[5]};
`

import { ApiBase } from 'lib/api/ApiBase'
import { Wallpaper } from '../types/wallpaperTypes'

export class WallpapersApi extends ApiBase {
    getWallpapers = () => {
        return this.client.get<Wallpaper[]>('wallpapers/my')
    }

    incrementWallpaperDownloadCount = (id: string) => {
        return this.client.patch(`wallpapers/${id}/increment-download-count`)
    }
}

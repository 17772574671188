import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components/macro'
import { useTranslate } from 'lib/i18n/useTranslate'
import img1 from '../media/slider/1.jpg'
import img2 from '../media/slider/2.jpg'
import img3 from '../media/slider/3.jpg'
import img4 from '../media/slider/4.jpg'
import img5 from '../media/slider/5.jpg'
import img6 from '../media/slider/6.jpg'
import { Text } from 'components/Text'
import { LeftAngleIcon, RightAngleIcon } from 'components/icons'
import { Paper } from 'components/Paper'

export const OpenDayCarousel: React.FC = () => {
    const t = useTranslate()

    return (
        <Root>
            <Slider
                autoplaySpeed={4000}
                speed={1000}
                autoplay
                prevArrow={
                    <Arrow>
                        <LeftAngleIcon />
                    </Arrow>
                }
                nextArrow={
                    <Arrow>
                        <RightAngleIcon />
                    </Arrow>
                }
            >
                <CarouselItem
                    backgroundColor="#043556"
                    color="#fff"
                    text={t('OpendayWhatsnew::Whyus::content2')}
                    image={img2}
                />
                <CarouselItem
                    backgroundColor="#fff"
                    color="#000"
                    text={t('OpendayWhatsnew::Whyus::content1')}
                    image={img1}
                />
                <CarouselItem
                    backgroundColor="#91b329"
                    color="#fff"
                    text={t('OpendayWhatsnew::Whyus::content6')}
                    image={img6}
                />
                <CarouselItem
                    backgroundColor="#dee6e7"
                    color="#000"
                    text={t('OpendayWhatsnew::Whyus::content4')}
                    image={img4}
                />
                <CarouselItem
                    backgroundColor="#043556"
                    color="#fff"
                    text={t('OpendayWhatsnew::Whyus::content3')}
                    image={img3}
                />
                <CarouselItem
                    backgroundColor="#fff"
                    color="#000"
                    text={t('OpendayWhatsnew::Whyus::content5')}
                    image={img5}
                />
            </Slider>
        </Root>
    )
}

interface CarouselItemProps {
    text: string
    image: string
    backgroundColor: string
    color: string
}

const CarouselItem: React.FC<CarouselItemProps> = ({ text, image, color, backgroundColor }) => {
    return (
        <ItemRoot color={color} backgroundColor={backgroundColor}>
            <ItemImgWrap backgroundColor={backgroundColor}>
                <ItemImg src={image} alt="" />
            </ItemImgWrap>
            <ItemText variant="h1">{text}</ItemText>
        </ItemRoot>
    )
}

const ItemImgWrap = styled.div<{ backgroundColor: string }>`
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 20%;
        height: 100%;
        background: linear-gradient(to right, transparent, ${props => props.backgroundColor});
    }
`

const Arrow = styled.button`
    background: none;
    border: none;
    font-size: ${props => props.theme.typography.h1.fontSize};
    color: ${props => props.theme.palette.text.primary};
`

const ItemImg = styled.img`
    max-width: 100%;
`

const ItemText = styled(Text)`
    padding: ${props => props.theme.spacing(4, 10, 4, 0)};
    text-align: right;
`

const ItemRoot = styled(Paper)<{ backgroundColor: string; color: string }>`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    background-color: ${props => props.backgroundColor};
    color: ${props => props.color};
    overflow: hidden;
    margin-left: ${props => props.theme.spacing(4)};
    margin-right: ${props => props.theme.spacing(4)};
`

const Root = styled.div`
    .slick-slider {
        margin-left: -${props => props.theme.spacing(4)};
        margin-right: -${props => props.theme.spacing(4)};
        position: relative;
        display: block;
        box-sizing: border-box;
        user-select: none;
        touch-action: pan-y;
    }

    .slick-list {
        position: relative;
        display: block;
        overflow: hidden;
        margin: 0;
        padding: 0;
        border-radius: ${props => props.theme.shape.borderRadius}px;
        padding-bottom: 2px;
    }
    .slick-list:focus {
        outline: none;
    }
    .slick-list.dragging {
        cursor: pointer;
        cursor: hand;
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .slick-track:before,
    .slick-track:after {
        display: table;
        content: '';
    }
    .slick-track:after {
        clear: both;
    }
    .slick-loading .slick-track {
        visibility: hidden;
    }
    .slick-slide {
        display: none;
        float: left;
        height: 100%;
        min-height: 1px;
    }
    .slick-slide img {
        display: block;
    }
    .slick-slide.slick-loading img {
        display: none;
    }
    .slick-slide.dragging img {
        pointer-events: none;
    }
    .slick-initialized .slick-slide {
        display: block;
    }
    .slick-loading .slick-slide {
        visibility: hidden;
    }
    .slick-arrow.slick-hidden {
        display: none;
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        top: 50%;
        display: block;
        cursor: pointer;
        ${props => props.theme.breakpoints.down('md')} {
            display: none !important;
        }
    }
    .slick-prev {
        left: 0;
        transform: translate(-100%, -50%);
    }

    .slick-next {
        right: 0;
        transform: translate(100%, -50%);
    }
`

import React from 'react'
import ReactMarkdown from 'react-markdown'

interface MarkdownProps {
    children: string
}

export const Markdown: React.FC<MarkdownProps> = ({ children }) => {
    // TODO: use <Text />
    return <ReactMarkdown>{children}</ReactMarkdown>
}

import { Reducer } from 'redux'
import { PRESENT } from 'redux-socket-client'
import { FetchedLeaderboard, LeaderboardRank } from '../../models'
import {
    LEADERBOARD_FETCHED,
    SCORE_LIST,
    LEADERBOARD_RANK,
    LEADERBOARD_VERSION,
    ROOMS,
    SHARED_STATE,
} from '../../constants'
import produce from 'immer'
import dayjs from 'dayjs'
import { HighScoreGame, highScoreGames } from 'features/high-score/highScoreGames'
import { filterNulls } from 'utils/arrayUtilts'

export interface HighScoreRoom {
    id: string
    secretCode: string
    name: string
    owner: string
    expireAt: string
    kind: string
    target: string
    game: HighScoreGame
}

export interface LeaderboardState {
    scoreList: Partial<Record<string, string[]>>
    leaderboard: Partial<Record<string, FetchedLeaderboard>>
    ranks: Partial<Record<string, LeaderboardRank>>
    nextIncrements: Partial<Record<string, string>>
    highScoreRooms: {
        byId: Record<string, HighScoreRoom>
        list: string[] | null
    }
}

export const leaderboardReducer: Reducer<LeaderboardState> = (
    state = {
        scoreList: {},
        leaderboard: {},
        ranks: {},
        nextIncrements: {},
        highScoreRooms: { byId: {}, list: null },
    },
    { type, payload = {} },
) => {
    switch (type) {
        case ROOMS:
            return produce(state, draft => {
                const roomsWithGame = payload.rooms
                    .map((room: any) => {
                        const game = highScoreGames.find(game => game.projectId === room.target)
                        if (!game) {
                            return null
                        }
                        return {
                            ...room,
                            game,
                        }
                    })
                    .filter(filterNulls)
                roomsWithGame.forEach((room: any) => {
                    draft.highScoreRooms.byId[room.id] = room
                })
                draft.highScoreRooms.list = roomsWithGame
                    .sort((a: HighScoreRoom, b: HighScoreRoom) => (dayjs(a.expireAt).isAfter(b.expireAt) ? -1 : 1))
                    .map((room: any) => room.id)
            })
        case SHARED_STATE:
            return produce(state, draft => {
                const game = highScoreGames.find(game => game.projectId === payload.state.persistent.target)
                if (!game) {
                    return
                }
                draft.highScoreRooms.byId[payload.room] = {
                    id: payload.room,
                    secretCode: payload.state.persistent.secretCode,
                    name: payload.state.persistent.name,
                    owner: payload.state.persistent.owner,
                    expireAt: payload.state.persistent.roomExpireAt,
                    kind: payload.state.persistent.kind,
                    target: payload.state.persistent.target,
                    game,
                }
            })
        case LEADERBOARD_FETCHED:
            const versionName =
                payload.leaderboard.version === 'latest' ? 'latest' : payload.leaderboard.version ? 'prev' : 'all-time'
            const key = `${payload.leaderboard.group}:${payload.leaderboard.stat}:${versionName}`
            return {
                ...state,
                leaderboard: {
                    ...state.leaderboard,
                    [key]: payload.leaderboard,
                },
            }
        case SCORE_LIST:
            return {
                ...state,
                scoreList: {
                    ...state.scoreList,
                    [payload.project]: payload.scores,
                },
            }
        case LEADERBOARD_RANK: {
            const versionName = payload.version === 'latest' ? 'latest' : payload.version ? 'prev' : 'all-time'
            const key = `${payload.group}:${payload.stat}:${versionName}`
            return {
                ...state,
                ranks: {
                    ...state.ranks,
                    [key]: {
                        rank: payload.rank,
                        value: payload.value,
                    },
                },
            }
        }
        case LEADERBOARD_VERSION: {
            const key = `${payload.group}:${payload.stat}`

            return {
                ...state,
                nextIncrements: {
                    ...state.nextIncrements,
                    [key]: payload.nextIncrementAt,
                },
            }
        }
        case PRESENT:
        default:
            return state
    }
}

import React from 'react'
import { SecretCodeUnlocker } from 'features/secret-code'
import { Dialog, DialogErrorMessage, DialogTitle } from 'components/dialog'
import { createHashDialog } from 'hooks/createHashDialog'
import { useTranslate } from 'lib/i18n/useTranslate'
import { ErrorBoundary } from 'components/ErrorBoundary'

const [joinQuizDialogTo, JoinQuizDialogController] = createHashDialog('join-quiz')

export { joinQuizDialogTo }

export const JoinQuizDialog: React.FC = () => {
    const t = useTranslate()

    return (
        <JoinQuizDialogController>
            {({ onClose, open }) => (
                <Dialog onClose={onClose} open={open}>
                    <DialogTitle>{t('quiz::join')}</DialogTitle>
                    <ErrorBoundary fallback={<DialogErrorMessage close={onClose} />}>
                        <SecretCodeUnlocker close={onClose} primaryLabel={t('quiz::join')} />
                    </ErrorBoundary>
                </Dialog>
            )}
        </JoinQuizDialogController>
    )
}

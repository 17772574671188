import { allGrants, Grant, NormalizedGrantList } from 'lib/grants'
import * as grantDevTool from 'lib/grants/grant-dev-tool'
import { ApiBase } from './ApiBase'
import { ApiTypes } from './types'
import {
    CrmUserData,
    FollowUserData,
    MinimalAccount,
    UserStatistics,
    DefaultSchools,
    UserActivity,
} from './staticTypes'
import { NotFoundError } from '../../utils/errors'
import { getNRandomElements } from 'utils/arrayUtilts'
import { UserActivityForAllUsers } from '.'
import { UserStatisticSummary, YearInReviewYear } from 'features/year-in-review/types/yearInReviewTypes'

export const settingKeys = [
    'mylogiscool-theme',
    'universe-project-languages',
    'mylogiscool-project-list-layout',
    'mylogiscool-project-list-sort',
    'mylogiscool-newsfeed',
    'mylogiscool-show-advanced-statistics',
    'mylogiscool-onboardings',
] as const
export type UserSettingKey = typeof settingKeys[number]
export type UserSettings = Partial<Record<UserSettingKey, string>>
export type NormalizedFollowers = {
    followers: string[]
    following: string[]
    usersData: Record<string, MinimalAccount>
}

const userCache: Record<string, MinimalAccount> = {}
export class AccountsApi extends ApiBase {
    getMe = async () => {
        const res = await this.client.get<ApiTypes['account']>('/accounts/me')

        grantDevTool.processMe(res)

        return res
    }

    getCrmData = async (userId?: string) => {
        const crmUserData = await this.client
            .get<CrmUserData>(`accounts/${userId ? userId : 'me'}/crm-fields`)
            .catch(() => {
                const fallbackCrmUserData: { data: CrmUserData } = {
                    data: {
                        birthDate: undefined,
                        gender: undefined,
                        fullName: undefined,
                        lastSchool: undefined,
                        lastSchoolId: undefined,
                        schools: undefined,
                    },
                }
                return fallbackCrmUserData
            })
        if (crmUserData.data.lastSchool === 'Logiscool Teszt Iskola') {
            crmUserData.data.lastSchool = 'Logiscool (HQ) Hungary'
            crmUserData.data.lastSchoolId = 'LSCHQ-HUN'
        }
        return crmUserData
    }

    getCrmLink = async (key: string) => {
        return this.client.get<{ key: string; value: string } | null>(`/accounts/me/get-crm-link?key=${key}`)
    }

    setCrmLink = async (key: string, value: string) => {
        return this.client.patch<{ key: string; value: string } | null>(`/accounts/me/set-crm-link?key=${key}`, {
            value,
        })
    }

    getMyLocale = () => {
        return this.client.get<{ country: string; language: string; locale: string }>('/accounts/me/locale')
    }

    getOrGenerateAppScopedId = (account: string, client_id: string) => {
        return this.client.post<{ appScopedId: string }>(
            '/app-scoped-ids/get-or-generate',
            {},
            {
                params: {
                    account,
                    client_id,
                },
            },
        )
    }

    getMySetting = async (key: UserSettingKey) => {
        return this.client.get<{ key: UserSettingKey; value: string }>(`accounts/me/settings/${key}`)
    }

    getMySettings = async () => {
        const res = await this.client.get<{ key: UserSettingKey; value: string }[]>(`accounts/me/settings`, {
            params: {
                'where[key][in]': settingKeys.join(','),
            },
        })
        const normalizedData = res.data.reduce<UserSettings>((prev, current) => {
            return {
                [current.key]: current.value,
                ...prev,
            }
        }, {})

        return {
            ...res,
            data: normalizedData,
        }
    }

    setMySetting = async (key: UserSettingKey, value: string) => {
        return this.client.patch<{ value: string }>(`accounts/me/settings/${key}`, { value })
    }

    getMyGrants = async () => {
        const res = await this.client.post<{ grants: Grant[] }>('/accounts/me/check-grants', { grants: allGrants })

        grantDevTool.processGrants(res)

        const normalizedData = res.data.grants.reduce<NormalizedGrantList>((acc, current) => {
            const { product, feature, ...rest } = current // Destructure to exclude product and feature
            if (!acc[product]) {
                acc[product] = { [feature]: [rest] } // Push the rest of the object
            } else {
                const currentProduct = acc[product]
                const currentFeature = currentProduct![feature]
                if (!currentFeature) {
                    currentProduct![feature] = [rest] // Push the rest of the object
                } else {
                    currentFeature.push(rest) // Push the rest of the object
                }
            }
            return acc
        }, {})

        return {
            ...res,
            data: normalizedData,
        }
    }

    getProfilePictures = async ({ isOnboarding }: { isOnboarding: boolean }) => {
        const ONBOARDING_LIMIT = 21

        type ProfilePicture = Omit<ApiTypes['profile-picture'], 'usable' | 'description'> & {
            usable?: boolean
            description?: string[]
        }
        const res = await this.client.get<ProfilePicture[]>('profile-pictures/my', { params: { sort: '-createdAt' } })
        if (isOnboarding) {
            res.data = getNRandomElements(
                res.data.filter(picture => picture.usable),
                ONBOARDING_LIMIT,
            )
        }
        return res
    }

    updateMe = async (updates: Partial<ApiTypes['account']>) => {
        const res = await this.client.patch<ApiTypes['account']>('accounts/me', { ...updates })

        grantDevTool.processMe(res)

        return res
    }

    getUserByUsername = async (username: string) => {
        const accountRes = await this.client.get<MinimalAccount[]>(`accounts`, {
            params: {
                username,
            },
        })

        if (accountRes.data.length === 0) {
            throw new NotFoundError()
        }

        const account = accountRes.data[0]

        const crmRes = await this.getCrmData(account.id)

        return {
            ...accountRes,
            data: { account, crmData: crmRes.data },
        }
    }

    isAliasUnique = async (alias: string) => {
        const res = await this.client.get<ApiTypes['account-name'][]>('account-names', {
            params: {
                name: alias.toLowerCase().trim(),
            },
        })
        return res.data.length === 0
    }

    getRecommendedAlias = async (originalAlias: string) => {
        const res = await this.client.get<string>('accounts/recommend-alias', {
            params: {
                originalAlias,
            },
        })
        return res.data
    }

    getFollowers = async (userId: string) => {
        const res = await this.client.get<FollowUserData[]>('account-followers/both', {
            params: {
                user: userId,
            },
        })

        const normalizedUserData = (data: FollowUserData[]) => {
            return data.reduce<Record<string, MinimalAccount>>((result, current) => {
                if (!result[current.target.id]) {
                    result[current.target.id] = current.target
                }

                if (!result[current.user.id]) {
                    result[current.user.id] = current.user
                }

                return result
            }, {})
        }

        const normalizedFollowers = {
            followers: Array.from(new Set(res.data.filter(item => item.target.id === userId))).map(
                item => item.user.id,
            ),
            following: Array.from(new Set(res.data.filter(item => item.user.id === userId))).map(
                item => item.target.id,
            ),
            usersData: normalizedUserData(res.data),
        }

        return normalizedFollowers
    }

    getUsers = async (ids: string[]) => {
        const cachedUsers = ids.filter(id => userCache[id]).map(id => userCache[id])
        const uncachedIds = ids.filter(id => !userCache[id])

        if (uncachedIds.length === 0) {
            return cachedUsers
        }

        const res = await this.client.get<MinimalAccount[]>('/accounts', {
            params: {
                'where[id][in]': uncachedIds.join(','),
            },
        })
        res.data.forEach(user => {
            userCache[user.id] = user
        })
        return [...res.data, ...cachedUsers]
    }

    getUser = async (id: string) => {
        return userCache[id] ?? (userCache[id] = (await this.client.get<MinimalAccount>(`accounts/${id}`)).data)
    }

    getUserDefaultSchools = async (userId: string) => {
        return (
            await this.client.get<DefaultSchools>(`accounts/default-school`, {
                params: {
                    owner: userId,
                },
            })
        ).data
    }

    getUserStatistics = async (userId: string) => {
        return (
            await this.client.get<UserStatistics>('/user-statistics/public-profile-stats', {
                params: {
                    owner: userId,
                },
            })
        ).data
    }

    saveDefaultSchool = async (schoolId: string) => {
        return await this.client.post('accounts/default-school', { school: schoolId })
    }

    connectDiscordAccount = async (discordId: string, discordUsername: string) => {
        return this.client.patch('/accounts/me/update-discord-account', {
            discordId,
            discordUsername,
        })
    }

    disconnectDiscordAccount = async () => {
        return this.client.delete('/accounts/me/update-discord-account')
    }

    getUserActivity = async (userId: string) => {
        return (
            await this.client.get<UserActivity>('/user-statistics/activity-score', {
                params: {
                    owner: userId,
                },
            })
        ).data
    }

    getUsertatisticSummaries = async (userId: string, startDate: string, endDate: string) => {
        return this.client.get<{ id: string; stat: string; value: number }[]>(`/user-statistic-summaries`, {
            params: {
                owner: userId,
                startDate: startDate,
                endDate: endDate,
                sort: 'stat',
            },
        })
    }

    getAllUserTotalActivity = async ({ country, kind, last }: { country?: string; kind?: string; last?: number }) => {
        return (
            await this.client.get<UserActivityForAllUsers>('/user-statistics/activity-score-for-all-v2', {
                params: {
                    country,
                    kind,
                    last,
                },
            })
        ).data
    }

    updateLeadEmail = (email: string) => {
        return this.client.patch(`/accounts/update-lead-email`, { email })
    }

    getYearInReviewData = async (year: YearInReviewYear, userId: string) => {
        const res = await this.client.get<UserStatisticSummary[]>('/user-statistic-summaries', {
            params: {
                owner: userId,
                group: 'ActivityScore',
                startDate: `${year}-01-01T00:00:00.000Z`,
                endDate: `${year + 1}-01-01T00:00:00.000Z`,
            },
        })

        const findValue = (stat: UserStatisticSummary['stat']) => {
            return res.data.find(summary => summary.stat === stat)?.value ?? 0
        }

        return {
            projectsCreated: findValue('owned-project:create'),
            projectsShared: findValue('community:owned-project:share'),
            projectViews: findValue('total-view-received'),
            cookiesCollected: findValue('community:project:receive-currency'),
            cookiesGiven: findValue('community:project:give-currency'),
        }
    }

    getActiveUserCount = () => {
        return this.client.get<{ activeUsers: number }>('/accounts/active-users')
    }
}

import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { Inline } from 'components/layout'

export interface BadgeProps {
    className?: string
    backgroundColor?: string
    color?: string
    icon?: React.ReactElement
    forceNoTopMargin?: boolean
}

export const Badge: React.FC<BadgeProps> = ({ forceNoTopMargin, className, backgroundColor, color, icon, children }) => {
    return (
        <Root forceNoTopMargin={forceNoTopMargin} className={className} color={color} backgroundColor={backgroundColor}>
            {icon ? (
                <StyledInline spacing={1}>
                    {icon}
                    <span>{children}</span>
                </StyledInline>
            ) : (
                children
            )}
        </Root>
    )
}

export const Root = styled.div<{ backgroundColor?: string; color?: string, forceNoTopMargin?: boolean }>`
    background-color: ${props => props.backgroundColor ?? transparentize(0.3, props.theme.palette.common.black)};
    color: ${props => props.color ?? props.theme.palette.common.white};
    padding: ${props => props.theme.spacing(1, 2)};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    font-weight: 700;
    transition: opacity ${props => props.theme.transitions.duration.standard}ms;
    white-space: pre;
    margin-top: ${props => props.forceNoTopMargin ? 0 : 'inherit'};
`

const StyledInline = styled(Inline)`
    display: flex;
    align-items: center;
`

import { Text } from 'components/Text'
import { transparentize } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'

export const AchievementCardFooterText: React.FC = ({ children }) => {
    return <Root variant="subtitle2">{children}</Root>
}

const Root = styled(Text)`
    margin-block-start: auto;
    color: ${props =>
        transparentize(props.theme.palette.type === 'light' ? 0.3 : 0.6, props.theme.palette.text.primary)};
    font-weight: 800;
    padding-bottom: ${props => props.theme.spacing(4)};
`

import { Button } from 'components/Button'
import { useUserState } from 'contexts/UserContext'
import { useNotifications } from 'features/notifications'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { CertificateDialog } from './CertifiateDialog'
import { createSubjectToCertificateFunction, downloadFile, formatDate } from './certificateHelpers'
import { CertInventoryItem } from './certificateTypes'
import { PdfGenerator } from './PdfGenerator'

interface GetCustomCertButtonProps {
    courseItem: CertInventoryItem
}

export const GetCustomCertButton: React.FC<GetCustomCertButtonProps> = ({ courseItem, children }) => {
    const t = useTranslate()
    const certInfo = createSubjectToCertificateFunction(t)(courseItem)

    const { i18n, crm } = useUserState()
    const { notify } = useNotifications()
    const [status, setStatus] = React.useState<'idle' | 'generating-pdf' | 'open-dialog'>('idle')
    const [imgData, setImgData] = React.useState<{ img: string; blob: Blob }>()

    if (!certInfo) {
        return null
    }

    const { subjectInfo, translations } = certInfo

    const handleClick = () => {
        if (status === 'idle') {
            setStatus('generating-pdf')
        }
    }

    const handleGenerateSucces = (blob: Blob, imgData: string) => {
        setImgData({ img: imgData, blob })
        setStatus('open-dialog')
    }

    const handleDownload = () => {
        if (!imgData) return
        let filename = `Logiscool ${t('certificate::simpletitle')}`

        const finishedAt = formatDate(subjectInfo.lastDay, i18n.language)
        filename = `Logiscool ${t('certificate::simpletitle')} – ${t(subjectInfo.key as LocalisationKey)} ${finishedAt}`

        filename = filename.replace(/\./g, '')
        downloadFile(imgData.blob, filename)
    }

    return (
        <>
            <Button onClick={() => handleClick()} isLoading={status === 'generating-pdf'}>
                {children}
            </Button>
            {status === 'generating-pdf' && (
                <PdfGenerator
                    subject={subjectInfo}
                    fullName={crm.fullName ?? ''}
                    locale={i18n.locale}
                    language={i18n.language}
                    onSuccess={handleGenerateSucces}
                    onError={() => {
                        setStatus('idle')
                        notify(t('error::somethingWentWrong'), { variant: 'error' })
                    }}
                />
            )}
            {imgData && (
                <CertificateDialog
                    open={status === 'open-dialog'}
                    close={() => {
                        setStatus('idle')
                    }}
                    imgData={imgData.img}
                    title={translations.name}
                    download={handleDownload}
                />
            )}
        </>
    )
}

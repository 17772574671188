import React from 'react'
import styled from 'styled-components/macro'

interface ProgressBarTrackProps {
    className?: string
}

export const ProgressBarTrack: React.FC<ProgressBarTrackProps> = ({ children, className }) => {
    return <Root className={className}>{children}</Root>
}

const Root = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    height: 3rem;
    border: ${props => `solid 2px ${props.theme.extras?.progressBar?.borderColor ?? props.theme.palette.divider}`};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    background-color: ${props => props.theme.palette.background.paper};
`

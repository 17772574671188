import React from 'react'
import styled from 'styled-components/macro'

const Confetti = React.lazy(() => import('react-confetti'))

export const BirthdayConfetti: React.FC = () => {
    return (
        <ConfettiWrap>
            <React.Suspense fallback={null}>
                <Confetti
                    recycle={false}
                    numberOfPieces={150}
                    initialVelocityY={10}
                    colors={['#009cdf', '#e94e1b', '#e5007d']}
                />
            </React.Suspense>
        </ConfettiWrap>
    )
}

const ConfettiWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${props => props.theme.zIndex.drawer + 1};
    pointer-events: none;
`

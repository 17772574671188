import React from "react";
import {TextField} from "components/form";

export interface TextMultiEditFieldProps {
    label: string,
    value?: string,
    onChange?: (e: any, newValue: string) => any
}
export const TextMultiEditField: React.FC<TextMultiEditFieldProps & any> = ({ value, onChange, children, multiline, ...rest }) => {
    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if(onChange) onChange(e, e.target.value);
    }

    return (
        <TextField value={value} type="text" onChange={handleChange} {...rest} />
    )
}
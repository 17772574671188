import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'

interface EventDetailsTitleProps {}

export const EventDetailsTitle: React.FC<EventDetailsTitleProps> = ({ children }) => {
    return <Root variant="h2">{children}</Root>
}

const Root = styled(Text)`
    text-align: center;
`

import React, { useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { projectModalContext, ProjectModalState } from './projectModalContext'
import styled from 'styled-components/macro'
import { ProjectModalEmbed } from './ProjectModalEmbed'
import { Dialog } from 'components/dialog'
import { useSelector } from 'lib/store'
import { useMyPendingProjectsQuery, useProjectQuery } from '../projectQueries'
import { useUserState } from 'contexts/UserContext'
import { Project } from '../store'
import { AxiosError } from 'axios'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { ProjectModalError } from './ProjectModalError'
import { ProjectVariant } from '../projectHelpers'
import { Theme, useMediaQuery } from '@material-ui/core'
import { Paper } from 'components/Paper'
import { runtimeEnv } from 'utils/env'
import { api } from 'lib/api'
import { useModal } from 'lib/router/modals/modalContext'
import { usePauseDialogQueue } from 'features/dialog-queue'

interface ProjectModalBaseProps {
    projectVariant: ProjectVariant | ((project: Project) => ProjectVariant)
    asideWidth?: string
    embedOwnerInFileRequest?: boolean
    isNotFound: (context: Pick<ProjectModalState, 'project' | 'query' | 'isOwnProject' | 'id'>) => boolean
    transformIframeSrc?: (originalSrc: string) => string
    renderMainContent?: (id: string) => React.ReactNode
}

export const PROJECT_MODAL_MOBILE_BREAKPOINT = 'sm'

export const ProjectModalBase: React.FC<ProjectModalBaseProps> = ({
    children,
    asideWidth = '400px',
    projectVariant,
    isNotFound,
    embedOwnerInFileRequest = false,
    transformIframeSrc,
    renderMainContent,
}) => {
    const { id } = useParams<{ id: string }>()
    const query = useProjectQuery(id, embedOwnerInFileRequest)
    const { closeModal } = useModal()
    const project = useSelector(state => state.projects.all[id]) as Project | undefined
    const iframeRef = useRef<HTMLIFrameElement>(null)
    const { account, i18n } = useUserState()
    const { data: pendingProjects } = useMyPendingProjectsQuery(project?.metadata.shareable === true)
    const isPendingProject = pendingProjects?.includes(id)
    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down(PROJECT_MODAL_MOBILE_BREAKPOINT), {
        noSsr: true,
    })
    const [embedLoaded, setEmbedLoaded] = useState<boolean>(false)
    const [embedOrigin, setEmbedOrigin] = useState<string | null>(null)

    usePauseDialogQueue()

    const isOwnProject = project ? project.file.owner === account.id : undefined
    const computedProjectVariant = (() => {
        if (typeof projectVariant === 'string') {
            return projectVariant
        }
        if (!project) {
            return null
        }
        return projectVariant(project)
    })()

    const error = (() => {
        if (query.status === 'error') {
            const statusCode = (query.error as AxiosError)?.response?.status
            if (statusCode === 400 || statusCode === 404) {
                return 'NOT_FOUND'
            } else {
                return 'GENERAL'
            }
        }
        if (project?.file.deleted) {
            return 'NOT_FOUND'
        }
        if (isNotFound({ project, query, isOwnProject, id })) {
            return 'NOT_FOUND'
        }
        return undefined
    })()

    React.useEffect(() => {
        const onMessage = (event: MessageEvent<{ type?: string }>) => {
            if (event.origin.indexOf(".scoolcode.") !== -1 && event.data?.type === 'ready') {
                setEmbedOrigin(event.origin);
                setEmbedLoaded(true)
            }
        }

        window.addEventListener('message', onMessage)
        return () => {
            window.removeEventListener('message', onMessage)
        }
    }, [])

    const iframeSrc = (() => {
        let src = `${runtimeEnv.REACT_APP_PLAY_URL}/${id}?version=next&access_token=${api.accessToken}&lang=${i18n.locale}`
        if (computedProjectVariant !== 'normal') {
            src = src + `&variant=${computedProjectVariant}`
        }
        if (transformIframeSrc) {
            src = transformIframeSrc?.(src)
        }
        return src
    })()

    return (
        <Dialog
            open
            hideCloseButton={isMobile}
            fullWidth
            fullScreen={isMobile}
            maxWidth="xl"
            PaperComponent={DialogPaper}
            onClose={closeModal}
        >
            <projectModalContext.Provider
                value={{
                    id,
                    query,
                    project,
                    close: closeModal,
                    iframeRef,
                    isOwnProject,
                    isPendingProject,
                    error,
                    variant: computedProjectVariant,
                    embedLoaded,
                    embedOrigin: embedOrigin || ''
                }}
            >
                <Grid asideWidth={asideWidth}>
                    {renderMainContent ? (
                        renderMainContent(id)
                    ) : (
                        <ProjectModalEmbed
                            key={iframeSrc}
                            projectVariant={computedProjectVariant}
                            src={iframeSrc}
                            ref={iframeRef}
                        />
                    )}
                    <Aside>
                        <ErrorBoundary fallback={<ProjectModalError error="GENERAL" />}>{children}</ErrorBoundary>
                    </Aside>
                </Grid>
            </projectModalContext.Provider>
        </Dialog>
    )
}

const DialogPaper = styled(Paper)`
    height: 100%;
    overflow-y: hidden;
    background: #1b1c26;
    ${props => props.theme.extras?.dialog?.projectDialogPaper}
`

const Aside = styled.aside`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    background-color: ${props => props.theme.palette.background.default};
`

const Grid = styled.div<{ asideWidth: string }>`
    display: grid;
    grid-template-columns: 1fr ${props => props.asideWidth};
    height: 100%;
    ${props => props.theme.breakpoints.down(PROJECT_MODAL_MOBILE_BREAKPOINT)} {
        display: flex;
        flex-direction: column;
        @media (orientation: landscape) {
            display: block;
        }
    }
`

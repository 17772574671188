import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'

export const ProjectModalDescription: React.FC = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled(Text)`
    white-space: pre-line;
    word-break: break-word;
`

import { Divider } from 'components/Divider'
import React from 'react'
import styled from 'styled-components/macro'

export const PopupMenuDivider: React.FC = () => {
    return <Root />
}

const Root = styled(Divider)`
    margin: ${props => props.theme.spacing(2)} -${props => props.theme.spacing(2)};
`

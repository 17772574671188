import React, { forwardRef } from 'react'
import { Card as MuiCard, CardProps as MuiCardProps } from '@material-ui/core'
import styled from 'styled-components/macro'

export interface CardProps extends MuiCardProps {}

export const Card = forwardRef<any, CardProps>((props, ref) => {
    return <Root elevation={1} {...props} ref={ref} />
})

const Root = styled(MuiCard)`
    border: none;
`

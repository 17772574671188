import { Card, CardContent } from 'components/card'
import { DialogContent, DialogTitle } from 'components/dialog'
import { Stack } from 'components/layout'
import { Skeleton } from 'components/Skeleton'
import React from 'react'
import { NBSP } from 'utils/styleUtils'

export const MiniQuizEditorSkeleton: React.FC = () => {
    return (
        <>
            <DialogTitle>{NBSP}</DialogTitle>
            <DialogContent>
                <Stack>
                    {new Array(3).fill(true).map((_, i) => (
                        <Card key={i}>
                            <CardContent>
                                <Stack>
                                    <Skeleton width="100%" height={50}></Skeleton>
                                    <Skeleton width="100%" height={50}></Skeleton>
                                    <Skeleton width="100%" height={50}></Skeleton>
                                    <Skeleton width="100%" height={50}></Skeleton>
                                </Stack>
                            </CardContent>
                        </Card>
                    ))}
                </Stack>
            </DialogContent>
        </>
    )
}

import { LinearProgress } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'

interface LinearLoaderProps {
    className?: string
}

export const LinearLoader: React.FC<LinearLoaderProps> = props => {
    return <Root variant="indeterminate" color="primary" {...props} />
}

const Root = styled(LinearProgress)`
    border-radius: 5px;
`

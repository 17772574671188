import React, { useEffect, useState } from 'react'
import { Dialog } from 'components/dialog'

const RecordingDevTool = React.lazy(() => import('./RecordingDevTool'))

export const RecordingDevToolDialog: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        window.openRecordingDevtool = () => {
            setIsOpen(true)
        }
    }, [])

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={() => {
                setIsOpen(false)
            }}
        >
            <RecordingDevTool />
        </Dialog>
    )
}

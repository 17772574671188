import React from 'react'
import { Box, Stack } from 'components/layout'
import { ProjectDetailsActions } from './ProjectDetailsActions'
import { ProjectModalContent, ProjectModalHeader } from '../modal'
import { useProjectModalContext } from '../modal/projectModalContext'
import { ProjectDetailsUser, ProjectDetailsUserAvatarWrap } from './ProjectDetailsUser'
import { ProjectDetailsTitle } from './ProjectDetailsTitle'
import { Skeleton } from 'components/Skeleton'
import { ProjectDetailsDescription } from './ProjectDetailsDescription'
import { ProjectDetailsStats, SharedProjectDetailsStats } from './ProjectDetailsStats'
import { ProjectDetailsThumbnail } from './ProjectDetailsThumbnail'
import { useTitle } from 'hooks/useTitle'
import { ProjectModalError } from '../modal/ProjectModalError'
import { useCommunityProjectDetailsQuery } from '../projectQueries'
import { useIncrementProjectViewCount } from '../useIncrementProjectViewCount'
import { ProjectDetailsEditableDescription } from './ProjectDetailsEditableDescription'
import { Locked } from 'components/Locked'
import { Button } from 'components/Button'
import { useTranslate } from 'lib/i18n/useTranslate'
import { ViewIcon } from 'components/icons'
import { Project } from '../store'
import { SocialSharing } from '..'
import { runtimeEnv } from 'utils/env'
import { useUserState } from 'contexts/UserContext'
import styled from 'styled-components/macro'
import { CommunityProjectCookies } from './CommunityProjectCookies'
import { motion } from 'framer-motion'
import { ProjectDetailsLabels } from './ProjectDetailsLabels'

interface CommunityProjectDetailsBaseProps {
    children: (project: Project) => React.ReactNode
}

const CommunityProjectDetailsBase: React.FC<CommunityProjectDetailsBaseProps> = ({ children }) => {
    const { project, error, id, variant, isOwnProject } = useProjectModalContext()
    const { status } = useCommunityProjectDetailsQuery(id, variant, !!project)

    useTitle(project?.file?.title || project?.file?.name)

    if (error) {
        return <ProjectModalError error={error} />
    }

    if (status === 'error') {
        return <ProjectModalError error="GENERAL" />
    }

    if (!project || isOwnProject === undefined || status === 'loading') {
        return <ProjectDetailsSkeleton />
    }

    return <>{children(project)}</>
}

export const CommunityProjectDetails: React.FC = () => {
    const { project, isOwnProject, isPendingProject, id } = useProjectModalContext()
    useIncrementProjectViewCount(project)
    const { i18n } = useUserState()
    const t = useTranslate()

    const shareUrl = `${runtimeEnv.REACT_APP_SHARE_URL}/${id}?lang=${i18n.locale}&variant=production`

    return (
        <CommunityProjectDetailsBase>
            {project => {
                const editableProject = isOwnProject && !project.file.shared && isPendingProject === false
                const title = project.file.shared || isPendingProject === true ? project.file.title : project.file.name
                const displayThumbnail = isOwnProject && !project.file.shared && !isPendingProject
                const displayStats = isOwnProject && !project.file.shared

                return (
                    <>
                        <ProjectModalHeader>
                            <ProjectDetailsActions />
                        </ProjectModalHeader>
                        <ProjectModalContent>
                            <Stack spacing={5}>
                                <ProjectDetailsUser />
                                <ProjectDetailsTitle>{title}</ProjectDetailsTitle>
                                {project.file.shared && <CommunityProjectCookies />}
                                <motion.div layout>
                                    <Stack spacing={5}>
                                        {editableProject ? (
                                            <ProjectDetailsEditableDescription />
                                        ) : (
                                            <ProjectDetailsDescription />
                                        )}
                                        {displayThumbnail && <ProjectDetailsThumbnail />}
                                        <ProjectDetailsLabels />
                                        {displayStats && <ProjectDetailsStats />}
                                        {project.file.shared && <SharedProjectDetailsStats />}
                                    </Stack>
                                </motion.div>
                            </Stack>
                        </ProjectModalContent>
                        {project.file.shared && (
                            <StyledBox>
                                <SocialSharing
                                    shareUrl={shareUrl}
                                    buttonText={t('projects::shareWithLink')}
                                    helperText={t('projects::shareWithLinkHelper')}
                                />
                            </StyledBox>
                        )}
                    </>
                )
            }}
        </CommunityProjectDetailsBase>
    )
}

export const LimitedCommunityProjectDetails: React.FC = () => {
    const t = useTranslate()

    return (
        <CommunityProjectDetailsBase>
            {project => (
                <>
                    <ProjectModalHeader>
                        <Box display="flex">
                            <Locked>
                                <Button startIcon={<ViewIcon />} disabled>
                                    {t('projects::codeView')}
                                </Button>
                            </Locked>
                        </Box>
                    </ProjectModalHeader>
                    <ProjectModalContent>
                        <Stack spacing={5}>
                            <ProjectDetailsUser />
                            <ProjectDetailsTitle>{project.file.title}</ProjectDetailsTitle>
                            {project.file.shared && <CommunityProjectCookies canBeCookied={false} />}
                            {project.file.shared && <SharedProjectDetailsStats />}
                        </Stack>
                    </ProjectModalContent>
                </>
            )}
        </CommunityProjectDetailsBase>
    )
}

const StyledBox = styled(Box)`
    padding: ${props => props.theme.spacing(4)};
    border-top: 1px solid ${props => props.theme.palette.divider};
    background-color: ${props => props.theme.palette.background.paper};
`

const ProjectDetailsSkeleton: React.FC = () => {
    return (
        <>
            <ProjectModalHeader>
                <Skeleton variant="rect" width="6rem" height="2.5rem" />
            </ProjectModalHeader>
            <ProjectModalContent>
                <Stack>
                    <ProjectDetailsUserAvatarWrap>
                        <Skeleton variant="rect" width="3rem" height="3rem" />
                        <Skeleton width="6rem" />
                    </ProjectDetailsUserAvatarWrap>
                    <Skeleton height="2rem" />
                    <div>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </div>
                </Stack>
            </ProjectModalContent>
        </>
    )
}

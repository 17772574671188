import React from 'react'
import { createProjectThumbnailUrl } from '../projectHelpers'
import { Project } from '../store'
import { ProjectCard, ProjectCardImage, ProjectCardContent, ProjectCardBadge } from '../card'
import { useBlockGalleriesQuery } from '../projectQueries'
import { MyProjectBlockGallery } from './MyProjectBlockGallery'
import { MyProjectCardActionsMenu } from './MyProjectCardActionsMenu'
import { ProjectStripe } from '../stripe'
import { MyProjectStripeActions } from './MyProjectStripeActions'
import { MyCustomRightClickMenu } from './MyCustomRightClickMenu'
import { ProjectActionsMenu } from '../ProjectActionsMenu'
import { ProjectCardFavoriteBadge } from '../card/ProjectCardFavoriteBadge'
import { useTranslate } from 'lib/i18n/useTranslate'
import { ClassroomIcon } from 'components/icons'
import { ProjectListLayout } from './MyProjects'
import { Badge } from 'components/Badge'
import { MyProjectLanguage } from './MyProjectLanguage'
import { useHasGrant } from '../../../lib/grants'

interface MyClassroomProjectCardProps {
    project: Project
    blockGalleriesQuery?: ReturnType<typeof useBlockGalleriesQuery>
    layout: 'list' | 'grid'
}

export const MyClassroomProjectCard: React.FC<MyClassroomProjectCardProps> = ({
    project,
    blockGalleriesQuery,
    layout,
}) => {
    const to = `/app/project/${project.file.id}`
    const title = project.file.name
    const thumbnailUrl = createProjectThumbnailUrl(project.file.id, 'normal')

    const hasGrant = useHasGrant()
    const classroomBadge = hasGrant('share-project-user-consent') ? null : <ClassroomBadge layout={layout} />
    const languageBadge = <MyProjectLanguage project={project} layout={layout} />
    const blockGalleryBadge = (
        <MyProjectBlockGallery layout={layout} project={project} blockGalleriesQuery={blockGalleriesQuery} />
    )

    if (layout === 'list') {
        return (
            <MyCustomRightClickMenu
                menu={<ProjectActionsMenu location="stripe" isPending={false} id={project.file.id} isInContextMenu />}
            >
                <ProjectStripe
                    thumbnailUrl={thumbnailUrl}
                    tags={classroomBadge}
                    metadata={
                        <>
                            {languageBadge}
                            {blockGalleryBadge}
                        </>
                    }
                    title={title}
                    to={to}
                    actions={<MyProjectStripeActions isPending={false} project={project} />}
                    favorite={<ProjectCardFavoriteBadge project={project} inList />}
                />
            </MyCustomRightClickMenu>
        )
    }

    return (
        <ProjectCard>
            <MyCustomRightClickMenu
                menu={<ProjectActionsMenu location="card" isPending={false} id={project.file.id} isInContextMenu />}
            >
                <ProjectCardImage
                    to={to}
                    src={thumbnailUrl}
                    badges={
                        <>
                            {classroomBadge}
                            {blockGalleryBadge}
                            {languageBadge}
                        </>
                    }
                    outerBadges={<ProjectCardFavoriteBadge project={project} />}
                />
            </MyCustomRightClickMenu>
            <ProjectCardContent
                title={{ label: project.file.name, to }}
                action={
                    <MyProjectCardActionsMenu
                        location="card"
                        isPending={false}
                        compensatePadding
                        id={project.file.id}
                    />
                }
            ></ProjectCardContent>
        </ProjectCard>
    )
}

const ClassroomBadge: React.FC<{ layout: ProjectListLayout }> = ({ layout }) => {
    const t = useTranslate()

    const badgeProps = {
        backgroundColor: '#395167',
        icon: <ClassroomIcon />,
        children: t('classroom::title'),
    }

    switch (layout) {
        case 'list':
            return <Badge {...badgeProps} />
        case 'grid':
            return <ProjectCardBadge position="top-right" {...badgeProps} />
    }
}

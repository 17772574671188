import { fetchProjectsSucces } from 'features/projects'
import { useMyProjectsQuery } from 'features/projects/projectQueries'
import { api } from 'lib/api'
import { useInfiniteQuery, useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { MyMiniQuizzesQuery } from './MiniQuizApi'

export function useMiniQuizQuery(id: string) {
    const query = useQuery(['mini-quiz', id], () => api.miniQuizzes.getMiniQuiz(id), {
        // https://github.com/TanStack/query/issues/1019
        cacheTime: 1,
    })

    return query
}

export function useMiniQuizTemplatesQuery() {
    return useQuery(['mini-quiz-templates'], () => api.miniQuizzes.getTemplates())
}

export function useMyMiniQuizzesQuery(apiCallQuery: Omit<MyMiniQuizzesQuery, 'skip'>, skipCorrection: number) {
    const dispatch = useDispatch()

    function normalizeProjects(projects: NonNullable<ReturnType<typeof useMyProjectsQuery>['data']>[0]) {
        return projects.response.data.map(project => {
            return {
                file: project,
                metadata: {},
            }
        })
    }

    return useInfiniteQuery(
        ['my-mini-quizzes', apiCallQuery],
        async (_, __, skip: number = 0) => {
            const res = await api.miniQuizzes.getMyMiniQuizzes({ skip, ...apiCallQuery })
            const normalizedProjects = normalizeProjects(res)
            dispatch(fetchProjectsSucces(normalizedProjects))
            return res
        },
        {
            getFetchMore: lastPage => {
                return lastPage.nextSkip === null ? null : lastPage.nextSkip + skipCorrection
            },
            refetchOnMount: false,
            cacheTime: 60 * 1000,
        },
    )
}

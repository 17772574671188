import { Button } from 'components/Button'
import { Card, CardContent } from 'components/card'
import { Inline } from 'components/layout'
import { Text } from 'components/Text'
import dayjs from 'dayjs'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { Meeting } from '../types/meetingTypes'

interface MeetingItemProps {
    meeting: Meeting
}

export const MeetingItem: React.FC<MeetingItemProps> = ({ meeting }) => {
    const t = useTranslate()

    return (
        <Card data-testid="meeting-item">
            <StyledCardContent>
                <Inner spacing={6}>
                    <Inline display="flex">
                        {meeting.time && (
                            <Text component="time">
                                <strong>{dayjs.utc(meeting.time).format('HH:mm')}</strong>
                            </Text>
                        )}
                        <Label>{meeting.label || t('meeting::label')}</Label>
                    </Inline>
                    <ButtonWrap>
                        <Button component="a" href={meeting.url} target="_blank" rel="noopener noreferrer">
                            {t('meeting::join')}
                        </Button>
                    </ButtonWrap>
                </Inner>
            </StyledCardContent>
        </Card>
    )
}

const ButtonWrap = styled.div`
    flex-shrink: 0;
    ${props => props.theme.breakpoints.down('xs')} {
        margin-top: ${props => props.theme.spacing(4)};
        margin-left: 0;
        margin-right: 0;
        > * {
            width: 100%;
        }
    }
`

const Label = styled(Text)`
    word-break: break-all;
`

const StyledCardContent = styled(CardContent)`
    display: flex;
    align-items: center;
    height: 100%;
`

const Inner = styled(Inline)`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${props => props.theme.breakpoints.down('xs')} {
        display: block;
    }
`

import { UserState, useUserState } from 'contexts/UserContext'
import dayjs, { Dayjs } from 'dayjs'
import { api } from 'lib/api'
import { hasGrant } from 'lib/grants'
import { useSelector } from 'lib/store'
import { useQuery } from 'react-query'
import { useStore } from 'react-redux'
import { filterNulls } from 'utils/arrayUtilts'
import { customFeedTransformers } from './data/customFeedTransformers'
import { loadFeedPage, NewsFeedEntriesWithMetaData } from './store/feedActions'
import { FeedEntryCustomIds, FeedEntry, NewsFeeds, FeedType } from './types/feedTypes'

const NEWSFEED_ENTRIES_PER_PAGE = 15

export function useFeed() {
    const store = useStore()
    const feedState = useSelector(state => state.feed)

    const initFeed = async (user: UserState) => {
        const lastRead = getLastReadDates(user)

        const getCommunityEntries = async () => {
            const entries = await api.feed.getFeedEntries('community', NEWSFEED_ENTRIES_PER_PAGE, 0)
            store.dispatch(
                loadFeedPage({
                    data: normalizedPayload(entries, lastRead, true, user),
                    feedType: 'community',
                }),
            )
        }

        const getTrainerEntries = async () => {
            if (!hasGrant(user.grants)({ product: 'edup.trainer-portal', feature: 'news' })) {
                return
            }

            const entries = await api.feed.getFeedEntries('trainer', NEWSFEED_ENTRIES_PER_PAGE, 0)
            store.dispatch(
                loadFeedPage({
                    data: normalizedPayload(entries, lastRead, true, user),
                    feedType: 'trainer',
                }),
            )
        }

        try {
            await Promise.all([getCommunityEntries(), getTrainerEntries()])
        } catch (e) {
            console.error(e)
        }
    }

    const loadNextFeedPage = async (user: UserState, feedType: FeedType) => {
        const lastRead = getLastReadDates(user)
        try {
            const data = await api.feed.getFeedEntries(
                feedType,
                NEWSFEED_ENTRIES_PER_PAGE,
                feedState[feedType].nextSkip,
            )
            store.dispatch(loadFeedPage({ data: normalizedPayload(data, lastRead, false, user), feedType }))
        } catch (error) {
            console.log('Failed to load feed page.')
            console.error(error)
        }
    }

    return {
        initFeed,
        loadNextFeedPage,
    }
}

export function useFeedEntryQuery(id: string, enabled: boolean) {
    const user = useUserState()

    return useQuery(
        ['feed-entry', id],
        async () => {
            const res = await api.feed.getFeedEntry(id)
            const transformedEntry = applyCustomTransform(res.data, user)
            if (transformedEntry === null) {
                throw new Error('Failed to apply custom transform.')
            }
            return transformedEntry
        },
        { staleTime: Infinity, enabled },
    )
}

interface NormalizedNewsFeedSetting {
    communityLastReadAt: Dayjs | null
    trainerLastReadAt: Dayjs | null
}

export function getLastReadDates(user: UserState): NormalizedNewsFeedSetting {
    let communityLastReadAt: Dayjs | null = null
    let trainerLastReadAt: Dayjs | null = null
    const setting = user.settings['mylogiscool-newsfeed']
    if (setting) {
        try {
            const parsedSetting = JSON.parse(setting)
            if (parsedSetting.lastReadAt) {
                communityLastReadAt = dayjs(parsedSetting.lastReadAt)
            }
            if (parsedSetting.trainerLastReadAt) {
                trainerLastReadAt = dayjs(parsedSetting.trainerLastReadAt)
            }
        } catch (e) {}
    }

    return { communityLastReadAt, trainerLastReadAt }
}

const applyCustomTransform = (entry: FeedEntry, user: UserState) => {
    if (entry.isCustom) {
        const transformer = customFeedTransformers[entry?.customId as FeedEntryCustomIds]

        if (!transformer) return null

        return {
            ...transformer(entry, user),
        }
    }
    return entry
}

const normalizedPayload = (
    data: NewsFeeds,
    newsFeedSetting: NormalizedNewsFeedSetting,
    isInital: boolean = false,
    user: UserState,
): NewsFeedEntriesWithMetaData => {
    const entries = data.entries
        .map(entry => {
            return applyCustomTransform(entry, user)
        })
        .filter(filterNulls)
        .map(entry => {
            const date = entry.isTrainerOnly ? newsFeedSetting.trainerLastReadAt : newsFeedSetting.communityLastReadAt
            return {
                ...entry,
                metadata: {
                    unread: isInital ? dayjs(entry.activeFrom).isAfter(date ?? dayjs().subtract(7, 'days')) : false,
                },
            }
        })

    return {
        entries: entries,
        hasMore: data.hasMore,
        nextSkip: data.nextSkip,
    }
}

import React from 'react'
import styled from 'styled-components/macro'
import { DialogContent as MuiDialogContent, DialogContentProps as MuiDialogContentProps } from '@material-ui/core'

type DialogProps = MuiDialogContentProps

export const DialogContent: React.FC<DialogProps> = props => {
    return <Root {...props} />
}

const Root = styled(MuiDialogContent)`
    padding: ${props => props.theme.spacing(4, 5)};
    &:first-child {
        padding-top: ${props => props.theme.spacing(4)};
    }
`

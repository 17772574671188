import dee3fdbb2dc0a00124753e3 from './5dee3fdbb2dc0a00124753e3.png'
import dee3ff4d33f4c002b30de16 from './5dee3ff4d33f4c002b30de16.png'
import dee4011d33f4c002b30de1a from './5dee4011d33f4c002b30de1a.png'
import dee4023b1a7b90011a38132 from './5dee4023b1a7b90011a38132.png'
import dee4036d33f4c002b30de21 from './5dee4036d33f4c002b30de21.png'
import dee4049b2dc0a00124753ec from './5dee4049b2dc0a00124753ec.png'
import dee4059d33f4c002b30de25 from './5dee4059d33f4c002b30de25.png'
import dee4068b1a7b90011a38135 from './5dee4068b1a7b90011a38135.png'
import dee4077d33f4c002b30de28 from './5dee4077d33f4c002b30de28.png'
import dee40040c59430012766bdc from './5dee40040c59430012766bdc.png'

type ImagesRecord = Record<string, string>

export const images: ImagesRecord = {
    '5dee3fdbb2dc0a00124753e3': dee3fdbb2dc0a00124753e3,
    '5dee3ff4d33f4c002b30de16': dee3ff4d33f4c002b30de16,
    '5dee4011d33f4c002b30de1a': dee4011d33f4c002b30de1a,
    '5dee4023b1a7b90011a38132': dee4023b1a7b90011a38132,
    '5dee4036d33f4c002b30de21': dee4036d33f4c002b30de21,
    '5dee4049b2dc0a00124753ec': dee4049b2dc0a00124753ec,
    '5dee4059d33f4c002b30de25': dee4059d33f4c002b30de25,
    '5dee4068b1a7b90011a38135': dee4068b1a7b90011a38135,
    '5dee4077d33f4c002b30de28': dee4077d33f4c002b30de28,
    '5dee40040c59430012766bdc': dee40040c59430012766bdc,
}

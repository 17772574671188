import { createOauthClient } from '@logiscool/react-oauth'
import { isGuestUser, UserState } from 'contexts/UserContext'
import { runtimeEnv } from 'utils/env'

export const auth = createOauthClient({
    apiUrl: runtimeEnv.REACT_APP_API_URL as string,
    oauthUrl: runtimeEnv.REACT_APP_OAUTH_URL as string,
    clientId: 'universe-profile',
    clientSecret: 'dLe1jekyHvWhTGOCKaPYpS2nLl53QMZYPQVajYFAIcQSCtdOFMZmJi8qPJbG',
    redirectUrl: window.location.origin + '/auth/scoolcode',
    redirectToAuthorizeUrl: false,
})

export const getPasswordChangeOrCreateUrl = (user: UserState) => {
    const aliasQueryParam = user.account.alias ? `&alias=${user.account.alias}` : ''

    if (isGuestUser(user)) {
        return `${runtimeEnv.REACT_APP_OAUTH_URL}/create-password?id=${user.account.id}&returnTo=${window.location}${aliasQueryParam}`
    }
    return `${runtimeEnv.REACT_APP_OAUTH_URL}/change-password?returnTo=${window.location}${aliasQueryParam}`
}

export type AuthStatus = 'loading' | 'logged-in' | 'logged-out' | 'error'

import { client } from 'redux-socket-client'
import {
    CREATE_SUBSCRIPTION,
    READ_NOTIFICATIONS,
    REQUEST_NOTIFICATIONS,
    REQUEST_WELCOME,
    READ_NOTIFICATION,
    MARK_NOTIFICATION_AS_DISPLAYED,
    MARK_ALL_NOTIFICATION_AS_DISPLAYED,
} from '../constants'

export const subscribeToWebPush = client((subscriptionObject: any, channel: string = 'universe_launch_2019') => ({
    type: CREATE_SUBSCRIPTION,
    payload: { provider: 'webpush', channel, subscriptionObject },
    subscribe: true,
}))

export const loadNotifications = client((channel: string = 'universe_launch_2019') => ({
    type: REQUEST_NOTIFICATIONS,
    payload: { channel },
    subscribe: true,
}))

export const requestWelcomeNotification = client(() => ({
    type: REQUEST_WELCOME,
    payload: {
        sendEmpty: true,
    },
}))

export const readNotifications = client((channel: string = 'universe_launch_2019') => ({
    type: READ_NOTIFICATIONS,
    payload: { channel },
}))

export const readNotification = client((id: string) => ({
    type: READ_NOTIFICATION,
    payload: { id },
}))

export const markNotificationAsDisplayed = client((id: string) => ({
    type: MARK_NOTIFICATION_AS_DISPLAYED,
    payload: { id },
}))

export const markAllNotificationAsDisplayed = client((channel: string = 'universe_launch_2019') => ({
    type: MARK_ALL_NOTIFICATION_AS_DISPLAYED,
    payload: { channel },
}))

import React from 'react'
import { SecretCodeUnlocker } from 'features/secret-code'
import { Dialog, DialogErrorMessage, DialogTitle } from 'components/dialog'
import { createHashDialog } from 'hooks/createHashDialog'
import { useTranslate } from 'lib/i18n/useTranslate'
import { ErrorBoundary } from 'components/ErrorBoundary'

const [joinHighScoreRoomDialogTo, JoinHighScoreRoomDialogController] = createHashDialog('join-high-score-room')

export { joinHighScoreRoomDialogTo }

export const JoinHighScoreRoomDialog: React.FC = () => {
    const t = useTranslate()

    return (
        <JoinHighScoreRoomDialogController>
            {({ onClose, open }) => (
                <Dialog onClose={onClose} open={open} maxWidth="sm">
                    <DialogTitle>{t('highScoreRoom::join')}</DialogTitle>
                    <ErrorBoundary fallback={<DialogErrorMessage close={onClose} />}>
                        <SecretCodeUnlocker
                            close={onClose}
                            primaryLabel={t('highScoreRoom::join')}
                            helperText={t('highScoreRoom::description')}
                        />
                    </ErrorBoundary>
                </Dialog>
            )}
        </JoinHighScoreRoomDialogController>
    )
}

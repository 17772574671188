import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
    *, *:before, *:after {
        box-sizing: border-box;
    }
    html {
        overflow-y: scroll;
    }
    html, body, #root {
        height: 100%;
    }
    body {
        ${props => props.theme.extras?.global?.body};
        cursor: default;
    }
    html {
        ${props => props.theme.breakpoints.down('xs')} {
            font-size: 14px;
        }
    }
    /* fix weird i18n values used on open day pages */
    h3 b {
        font-weight: 800;
    }
`

import React from 'react'
import { DialogContent, DialogActions } from '.'
import styled from 'styled-components/macro'

interface DialogFormProps {
    actions: React.ReactNode
    onSubmit?: JSX.IntrinsicElements['form']['onSubmit']
}

export const DialogForm: React.FC<DialogFormProps> = ({ children, actions, onSubmit }) => {
    return (
        <Form onSubmit={onSubmit}>
            <DialogContent>{children}</DialogContent>
            <DialogActions>{actions}</DialogActions>
        </Form>
    )
}

const Form = styled.form`
    display: contents;
`

import * as React from 'react'
import { UnlockAchievementNotificationHandler } from 'features/notifications'
import { NotificationImage } from 'features/notifications'
import { LocalisationKey } from 'lib/i18n'
import { StoreState } from 'lib/store'
import { digitalMission2021achievementDataList } from '../data/digitalMission2021Data'

export const getDigitalMission2021SeaonsPoints = (state: StoreState) =>
    state.client.statistics['Seasons/DigitalMission:2021:season-point']

export const getDigitalMission2021AchievementI18nKeys = (internalName: string) => {
    const wrongAchivementsKeys = [
        'marvel',
        'projectCreator',
        'geographyexplorer',
        'inventionmaster',
        'CookieHunter',
        'TrainerContestVoter',
        'TrainerFollower',
        'GamingExpert',
        'PlanetBuilder',
    ]

    const typofix = wrongAchivementsKeys.includes(internalName) ? 'achievement' : 'achivements'

    return {
        name: `digitalMission2021::${typofix}::${internalName}::name` as LocalisationKey,
        description: `digitalMission2021::${typofix}::${internalName}::description` as LocalisationKey,
    }
}

export const dm2021UnlockAcihevementNotificationHandler: UnlockAchievementNotificationHandler = ({
    notification,
    t,
}) => {
    const achievementData = digitalMission2021achievementDataList.find(
        item => item.catalogItem === notification.message.data.payload.achievementId,
    )

    if (!achievementData) {
        return null
    }

    return {
        img: <NotificationImage notiMediaSrc={achievementData.imageUrl} />,
        title: t('notification::unlockAchievement::title', {
            name: t(getDigitalMission2021AchievementI18nKeys(achievementData.internalName).name),
        }),
        body: t('notification::unlockAchievement::body'),
        cta: {
            type: 'internal-link',
            link: {
                pathname: `/app/events/digital-mission-2021`,
                hash: achievementData.catalogItem,
            },
        },
    }
}

import React from 'react'
import { FormControlProps as MuiFormControlProps, FormControl as MuiFormControl } from '@material-ui/core'
import styled from 'styled-components/macro'

interface FormControlProps extends MuiFormControlProps {}

export const FormControl: React.FC<FormControlProps> = props => {
    return <StyledFormControl {...props} />
}

const StyledFormControl = styled(MuiFormControl)`
    .MuiOutlinedInput-notchedOutline {
        border-color: ${props => props.theme.palette.divider};
    }
    .MuiOutlinedInput-multiline,
    input,
    .MuiSelect-root {
        padding: ${props => props.theme.spacing(4, 5)};
    }
    .MuiSelect-root {
        padding-right: ${props => props.theme.spacing(8)};
    }
    > * + * {
        margin-top: ${props => props.theme.spacing(1)};
    }
`

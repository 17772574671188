import React from 'react'
import styled from 'styled-components/macro'
import { useActivityEventContext } from '../helpers/activityEventContext'

interface ActivityEventCurrencyProps {
    className?: string
}

export const ActivityEventPointIcon: React.FC<ActivityEventCurrencyProps> = props => {
    const { event } = useActivityEventContext()

    return <Root src={event.pointImage} {...props} />
}

const Root = styled.img`
    vertical-align: text-bottom;
    width: 1.5em;
`

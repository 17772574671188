import { createContext, useContext } from 'react'
import { ProjectVariant } from '../projectHelpers'
import { useProjectQuery } from '../projectQueries'
import { Project } from '../store'

export type ProjectModalState = {
    id: string
    query: ReturnType<typeof useProjectQuery>
    project: Project | undefined
    variant: ProjectVariant | null
    close: () => void
    iframeRef: React.MutableRefObject<HTMLIFrameElement | null>
    isOwnProject?: boolean
    isPendingProject?: boolean
    error: 'NOT_FOUND' | 'GENERAL' | undefined
    embedLoaded: boolean
    embedOrigin: string
}

export const projectModalContext = createContext<ProjectModalState | undefined>(undefined)

export const useProjectModalContext = () => {
    const context = useContext(projectModalContext)

    if (context === undefined) {
        throw new Error('useProjectModalContext must be used within a ProjectModalProvider')
    }

    return context
}

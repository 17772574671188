import { Button } from 'components/Button'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { canUserCreateProject, useUserState } from 'contexts/UserContext'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { newProjetDialogTo } from '..'

export const CreateYourFirstProject: React.FC = () => {
    const t = useTranslate()
    const user = useUserState()
    const canCreateProject = canUserCreateProject(user)

    return (
        <Stack textAlign="center" spacing={6}>
            <Title variant="h3">{t('projects::noProjects')}</Title>
            <Button size="large" component={Link} to={newProjetDialogTo}>
                {t(canCreateProject ? 'projects::createFirst' : 'projects::unlockProject')}
            </Button>
        </Stack>
    )
}

const Title = styled(Text)`
    color: ${props => props.theme.palette.text.hint};
    font-weight: 400;
`

import React from 'react'
import { ProjectModalBase } from 'features/projects'
import { HighScoreDetails } from 'features/high-score'
import { useLocation } from 'react-router-dom'

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const HighScorePage = () => {
    const query = useQuery()
    const room = query.get('room') || ''

    return (
        <ProjectModalBase
            projectVariant="normal"
            isNotFound={() => false}
            transformIframeSrc={src => {
                if (!room) {
                    return src
                }
                return src + `&room=${room}`
            }}
        >
            <HighScoreDetails room={room} />
        </ProjectModalBase>
    )
}

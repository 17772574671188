import { Card } from 'components/card'
import { Img } from 'components/Img'
import { Box, Stack } from 'components/layout'
import { Text } from 'components/Text'
import { FeedEntryComponent } from 'features/feed/types/feedTypes'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components/macro'
import { FeedEntryCta } from '../FeedEntryCta'
import { FeedEntryBase } from './FeedEntryBase'
import { createFeedImageUrl } from 'features/feed/data/feedhelper'
import { FeedEntryMetaAction } from '../FeedEntryMetaAction'
import { PlayIcon } from 'components/icons'
import { TextBody } from 'components/layout/TextBody'

export const GeneralEntry: FeedEntryComponent = ({ entry }) => {
    const t = useTranslate()

    const isPinnedLongform = !!(entry.isPinned && entry.action?.actionType === 'longform' && entry.action.details)

    return (
        <FeedEntryBase entry={entry}>
            <Card>
                {entry.image && (
                    <FeedEntryMetaAction entry={entry}>
                        <ImageWrap hasAction={entry.action !== undefined}>
                            <Image loading="lazy" src={createFeedImageUrl(entry.image)} alt={entry.title} />
                            {entry.action?.actionType === 'vimeo' && (
                                <VideoOverlay>
                                    <VideoIconWrap>
                                        <PlayIcon />
                                    </VideoIconWrap>
                                </VideoOverlay>
                            )}
                        </ImageWrap>
                    </FeedEntryMetaAction>
                )}
                <Inner>
                    <Stack>
                        <div>
                            <FeedEntryMetaAction entry={entry}>
                                <Text
                                    style={{ fontWeight: isPinnedLongform ? 800 : undefined }}
                                    variant={isPinnedLongform ? 'h2' : 'h3'}
                                >
                                    {t(entry.title as LocalisationKey)}
                                </Text>
                            </FeedEntryMetaAction>
                            {isPinnedLongform ? (
                                <Box mt={4}>
                                    <TextBody dangerouslySetInnerHTML={{ __html: entry.action!.details! }} />
                                </Box>
                            ) : (
                                <Body>
                                    <Trans i18nKey={entry.body} />
                                </Body>
                            )}
                        </div>
                        {!isPinnedLongform && <FeedEntryCta entry={entry} />}
                    </Stack>
                </Inner>
            </Card>
        </FeedEntryBase>
    )
}

const Body = styled(Text)`
    li::marker {
        color: ${props => props.theme.palette.text.hint};
    }
`

const VideoIconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${props => props.theme.spacing(4)};
    border: solid 3px #fff;
    background-color: rgba(255, 255, 255, 0.23);
    color: #fff;
    border-radius: 50%;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.25));
    transition: transform ${props => props.theme.transitions.duration.standard}ms;
`

const VideoOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 3rem;
`

const Inner = styled.div`
    padding: ${props => props.theme.spacing(3, 4, 4, 4)};
`

const Image = styled(Img)`
    position: absolute;
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: transform ${props => props.theme.transitions.duration.standard}ms;
    background-color: ${props => props.theme.palette.divider};
`

const ImageWrap = styled.div<{ hasAction: boolean }>`
    position: relative;
    height: 0;
    padding-bottom: 33.33%;
    overflow: hidden;
    &:hover {
        ${Image} {
            transform: ${props => (props.hasAction ? 'scale(1.06) rotate(1deg)' : undefined)};
        }
        ${VideoIconWrap} {
            transform: scale(1.13);
        }
    }
`

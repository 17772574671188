import React from 'react'
import styled from 'styled-components/macro'

export const PageContainer: React.FC = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled.div`
    margin-left: auto;
    margin-right: auto;
    box-sizing: content-box;
    padding-right: ${props => props.theme.spacing(8)};
    padding-left: ${props => props.theme.spacing(8)};
    max-width: 70rem;
    ${props => props.theme.breakpoints.down('xs')} {
        padding-right: ${props => props.theme.spacing(3)};
        padding-left: ${props => props.theme.spacing(3)};
    }
`

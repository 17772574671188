import { Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { BirthdayConfetti, BirthdayFooter, BirthdayGerland, BIRTHDAY_ENABLED } from 'features/birthday'
import { ScrollRestoration } from 'lib/router/Scrollrestoration'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { PageAside, ASIDE_WIDTH } from './PageAside'
import { PageMobileHeader } from './PageMobileHeader'
import {MANAGEMENT_PANE_WIDTH, ManagementPane} from "../../features/management/components/ManagementPane";
import {ManagementModal} from "../../features/management/components/ManagementModal";
import {useManagementContext} from "../../features/management";

export const MOBILE_MENU_BREAKPOINT = 'sm'

interface PageProps {
    hideFooter?: boolean
    managementChildren?: React.ReactNode
}

export const Page: React.FC<PageProps> = ({ hideFooter, children, managementChildren }) => {
    const { enabled: managementModeEnabled, modalStack: managementModalStack} = useManagementContext();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const isMobileMenu = useMediaQuery<Theme>(theme => theme.breakpoints.down(MOBILE_MENU_BREAKPOINT), { noSsr: true })
    const theme = useTheme()

    return (
        <>
            <ScrollRestoration />
            <Root isMobileMenu={isMobileMenu} isManagementMode={managementModeEnabled}>
                {isMobileMenu && (
                    <PageMobileHeader
                        onMenuButtonClick={() => {
                            setIsMobileMenuOpen(flag => !flag)
                        }}
                    />
                )}
                <PageAside
                    isMobileMenuOpen={isMobileMenuOpen}
                    isMobile={isMobileMenu}
                    onCloseMobileMenu={() => {
                        setIsMobileMenuOpen(false)
                    }}
                />
                <main>{children}</main>
                {managementModeEnabled && <>
                    <ManagementPane>{managementChildren}</ManagementPane>
                    {managementModalStack.map((modal, i) => (
                        <ManagementModal key={modal.key} modal={modal} level={i} />
                    ))}
                </>}
                {!hideFooter && (
                    <Footer>{BIRTHDAY_ENABLED ? <BirthdayFooter /> : theme.extras?.page?.footerImage}</Footer>
                )}
                {BIRTHDAY_ENABLED && (
                    <ErrorBoundary silent>
                        <BirthdayConfetti />
                        <BirthdayGerland />
                    </ErrorBoundary>
                )}
            </Root>
        </>
    )
}

const Root = styled.div<{ isMobileMenu: boolean, isManagementMode: boolean }>`
    display: ${props => (props.isMobileMenu ? 'flex' : 'grid')};
    flex-direction: ${props => (props.isMobileMenu ? 'column' : undefined)};
    grid-template-columns: ${ASIDE_WIDTH} minmax(0, 1fr) ${props => props.isManagementMode ? MANAGEMENT_PANE_WIDTH : 0};
    min-height: 100%;
`

const Footer = styled.footer`
    grid-column: 2;
    display: flex;
    align-items: flex-end;
    flex: 1;
`

import React from 'react'
import { Modal as MuiModal, ModalProps as MuiModalProps, Backdrop as MuiBackdrop } from '@material-ui/core'
import styled from 'styled-components/macro'

interface ModalProps extends MuiModalProps {}

export const Modal: React.FC<ModalProps> = props => {
    return <MuiModal BackdropComponent={Backdrop} BackdropProps={{ transitionDuration: 0 }} {...props} />
}

const Backdrop = styled(MuiBackdrop)`
    ${props => props.theme.extras?.dialog?.backdrop}
`

import React from 'react'
import { TextField } from 'components/form'

export interface JSONMultiEditFieldProps {
    label: string
    value?: string
    onChange?: (e: any, newValue: string) => any
}
export const JSONMultiEditField: React.FC<JSONMultiEditFieldProps & any> = ({
    value,
    onChange,
    children,
    multiline,
    ...rest
}) => {
    const [text, setText] = React.useState(JSON.stringify(value, null, 2))
    const [error, setError] = React.useState(false)
    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setText(e.target.value)
        try {
            const parsed = JSON.parse(e.target.value)
            if (onChange) onChange(e, parsed)
            setError(false)
        } catch (e) {
            setError(true)
        }
    }
    function handleBlur(e: React.ChangeEvent<HTMLTextAreaElement>) {
        try {
            const parsed = JSON.parse(e.target.value)
            setText(JSON.stringify(parsed, null, 2))
            setError(false)
        } catch (e) {
            setError(true)
        }
    }

    return (
        <TextField
            error={error}
            errorText={error ? 'Invalid JSON' : undefined}
            value={text}
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            rows={4}
            {...rest}
        />
    )
}

import { Box, Stack } from 'components/layout'
import React from 'react'
import { EventDefinition } from '../types/eventTypes'
import { EventDetailsBreadcrumb } from './details/EventDetailsBreadcrumb'
import { EventDetailsBanner } from './details/EventDetailsBanner'
import { useTitle } from 'hooks/useTitle'
import { useTranslate } from 'lib/i18n/useTranslate'
import { EventDetailsTitle } from './details/EventDetailsTitle'
import { ScrollRestoration } from 'lib/router/Scrollrestoration'
import { EventDetailsContent } from './details/EventDetailsContent'
import { PageContainer } from 'components/page/PageContainer'
import styled from 'styled-components/macro'
import { LocalisationKey } from 'lib/i18n'
import {ManagableEventDefinition} from "../types/eventTypesV2";

interface EventsDetailsBaseProps {
    event: EventDefinition|ManagableEventDefinition
    tabs?: React.ReactNode
}

export const EventDetailsBase: React.FC<EventsDetailsBaseProps> = ({ children, event, tabs }) => {
    const t = useTranslate()

    useTitle(t(event.title as LocalisationKey))

    return (
        <Box pt={8} pb={8}>
            <ScrollRestoration />
            <Stack>
                <PageContainer>
                    <Stack>
                        {event.hideBreadCrumb ? null : <EventDetailsBreadcrumb />}
                        <Stack spacing={6}>
                            <EventDetailsTitle>{t(event.title as LocalisationKey)}</EventDetailsTitle>
                            {event.detailsBanner && <EventDetailsBanner src={event.detailsBanner} />}
                        </Stack>
                    </Stack>
                </PageContainer>

                {tabs && (
                    <TabsWrap>
                        <PageContainer>
                            <EventDetailsContent>{tabs}</EventDetailsContent>
                        </PageContainer>
                    </TabsWrap>
                )}

                <div>
                    <PageContainer>
                        <Stack spacing={6}>{children}</Stack>
                    </PageContainer>
                </div>
            </Stack>
        </Box>
    )
}

const TabsWrap = styled.div`
    border-bottom: solid 1px ${props => props.theme.palette.divider};
`

import { ContentLocked } from 'components/ContentLocked'
import { DialogPrimaryButton, Dialog, DialogTitle, DialogContent, DialogActions } from 'components/dialog'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { PartialRecord } from 'utils/typeUtils'
import { AskForOpenDayButton } from './AskForOpenDayButton'

interface LockedChallengeOverlayProps {
    isLocked: boolean
    grant: string | undefined
    className?: string
}

export const LockedChallengeOverlay: React.FC<LockedChallengeOverlayProps> = ({
    children,
    className,
    grant,
    isLocked,
}) => {
    const t = useTranslate()
    const tooltip = grant ? t(grantDefintions[grant]?.tooltip) : undefined
    const dialogVariant = grant ? grantDefintions[grant]?.dialogVariant : undefined

    return (
        <ContentLocked
            isLocked={isLocked}
            className={className}
            tooltip={tooltip}
            dialog={dialogVariant ? props => <LockedChallengeDialog variant={dialogVariant} {...props} /> : undefined}
        >
            {children}
        </ContentLocked>
    )
}

const grantDefintions: PartialRecord<string, { tooltip: string; dialogVariant: DialogVariant }> = {
    'edup.universe/content.challenges.onboarding-points': {
        tooltip: 'onboardingMission::achievements::unlockContent',
        dialogVariant: 'onboarding-mission',
    },
    'edup.universe/content.challenges.open-day-participation': {
        tooltip: 'onboardingMission::achievements::unlockText',
        dialogVariant: 'open-day',
    },
}

type DialogVariant = 'onboarding-mission' | 'open-day'

interface LockedChallengeDialogProps {
    open: boolean
    onClose: VoidFunction
    variant: DialogVariant
}

export const LockedChallengeDialog: React.FC<LockedChallengeDialogProps> = ({ open, onClose, variant }) => {
    const t = useTranslate()

    const textByVariant: Record<DialogVariant, string> = {
        'onboarding-mission': 'onboardingMission::achievements::unlockContent',
        'open-day': 'onboardingMission::achievements::unlockText',
    }

    const actionByVariant: Record<DialogVariant, React.ReactNode> = {
        'onboarding-mission': (
            <DialogPrimaryButton component={Link} to="/app/events/mylogiscool-mission" fullWidth>
                {t('OnboardingMission::GoToMissionButton')}
            </DialogPrimaryButton>
        ),
        'open-day': <AskForOpenDayButton buttonProps={{ fullWidth: true }} onClose={onClose} />,
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>{t('general::contentLocked')}</DialogTitle>
            <DialogContent>
                <Text>{t(textByVariant[variant])}</Text>
            </DialogContent>
            <DialogActions>{actionByVariant[variant]}</DialogActions>
        </Dialog>
    )
}

export const LockedChallengeOverlayWithBorderRadius = styled(LockedChallengeOverlay)`
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

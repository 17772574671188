import React, { createContext, useContext } from 'react'
import { UserState, useUserActions, useUserState } from 'contexts/UserContext'
import { Dialog, DialogContent, DialogTitle } from 'components/dialog'
import { Flex } from 'components/layout'
import { Loader } from 'components/Loader'
import dayjs from 'dayjs'
import {
    OnboardingDefinition,
    onboardingDefinitionsByVariant,
    OnboardingUserSettings,
    OnboardingVariant,
} from '../data/onboardingData'
import { isGuestOrRegisteredGuestUser } from '../../../contexts/UserContext'
import { NBSP } from 'utils/styleUtils'
import { hasGrant } from 'lib/grants'

interface OnboardingManagerState {
    isOpen: boolean
    closeCurrent: VoidFunction
    markCurrentAsDisplayed: VoidFunction
    open: (variant: OnboardingVariant) => void
}

const onboardingManagerContext = createContext<OnboardingManagerState | undefined>(undefined)

export const OnboardingManagerProvider: React.FC = ({ children }) => {
    const user = useUserState()
    const [currentOnboarding, setCurrentOnboarding] = React.useState<OnboardingDefinition<any> | null>(
        getInitialOnboarding(user),
    )
    const { updateUserSettings } = useUserActions()

    const open = React.useCallback((variant: OnboardingVariant) => {
        const onboarding = onboardingDefinitionsByVariant[variant]

        if (onboarding) {
            setCurrentOnboarding(onboarding)
        }
    }, [])

    const markCurrentAsDisplayed = React.useCallback(() => {
        if (!currentOnboarding) return

        const values = {
            ...getOnboardingUserSetting(user),
            [currentOnboarding.variant]: true,
        }

        updateUserSettings({ 'mylogiscool-onboardings': JSON.stringify(values) }).catch(e => {
            console.error(e)
        })
    }, [user, updateUserSettings, currentOnboarding])

    const closeCurrent = React.useCallback(() => {
        setCurrentOnboarding(null)
    }, [])

    return (
        <onboardingManagerContext.Provider
            value={{
                isOpen: !!currentOnboarding,
                markCurrentAsDisplayed,
                closeCurrent,
                open,
            }}
        >
            {children}
            {currentOnboarding ? (
                <React.Suspense fallback={<OnboardingLoader />}>
                    <currentOnboarding.Component />
                </React.Suspense>
            ) : null}
        </onboardingManagerContext.Provider>
    )
}

function getOnboardingUserSetting(user: UserState): OnboardingUserSettings | null {
    try {
        const raw = user.settings['mylogiscool-onboardings']
        return raw ? JSON.parse(raw) : null
    } catch (error) {
        console.error(error)
        return null
    }
}

function getInitialOnboarding(user: UserState): OnboardingDefinition<any> | null {
    try {
        const onboardingSetting = getOnboardingUserSetting(user)

        const availableOnboardings = Object.values(onboardingDefinitionsByVariant)
            .filter(details => details.enabled)
            .filter(details =>
                details.userCreatedBefore ? dayjs(details.userCreatedBefore).isAfter(user.account.createdAt!) : true,
            )
            .filter(details => (details.requiredGrant ? hasGrant(user.grants)(details.requiredGrant) : true))
            .filter(details => (onboardingSetting ? onboardingSetting[details.variant] !== true : true))
            .filter(details => (isGuestOrRegisteredGuestUser(user) ? details.enabledForGuest : true))

        return availableOnboardings[0] || null
    } catch (e) {
        console.error(e)
        return null
    }
}

export const useOnboardingManager = () => {
    const context = useContext(onboardingManagerContext)

    if (context === undefined) {
        throw new Error(`useOnboardingManager must be used within OnboardingManagerProvider`)
    }

    return context
}

const OnboardingLoader = () => {
    return (
        <Dialog open maxWidth="sm" fullWidth hideCloseButton>
            <DialogTitle>{NBSP}</DialogTitle>
            <DialogContent>
                <Flex justify="center">
                    <Loader />
                </Flex>
            </DialogContent>
        </Dialog>
    )
}

import React from 'react'
import styled from 'styled-components/macro'
import { ProjectCardBadgeRoot } from './ProjectCardBadge'

type ProjectCardProps = {
    className?: string
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ children, className }) => {
    return <Root className={className}>{children}</Root>
}

const Root = styled.div`
    border-radius: ${props => props.theme.shape.borderRadius}px;
    &:hover {
        ${ProjectCardBadgeRoot} {
            opacity: 1;
        }
    }
`

import { AxiosInstance } from 'axios'
import { ApiTypes } from 'lib/api'
import { ApiBase } from 'lib/api/ApiBase'

export type MiniChallengeSet = ApiTypes['challenge-set'] & { rate: number; locked: boolean }
export interface ChallengeSetGroup {
    id: string
    name: string
    description: string
    grantRequiredToUnlock: string
    published: boolean
    thumbnail: string
    allCount: number
    completedCount: number
    newCount: number
}

export type OfcSetWithChallenges = ApiTypes['challenge-set'] & { challenges: ApiTypes['challenge'][] }
export class ScoolcodeChallengeApi extends ApiBase {
    constructor(client: AxiosInstance, private getUserId: () => string | null) {
        super(client)
    }

    getMyChallengeSets() {
        return this.client.get<ApiTypes['challenge-set'][]>('challenge-sets/my')
    }

    getMyChallengeSetGroups() {
        return this.client.get<ChallengeSetGroup[]>('challenge-set-groups/my')
    }

    getMiniChallengeSets(groupId?: string) {
        return this.client.get<MiniChallengeSet[]>('challenge-sets/my-mini', {
            params: {
                groupId,
            },
        })
    }

    getRecommendedMiniChallengeSets() {
        return this.client.get<MiniChallengeSet[]>('challenge-sets/my-recommended-mini')
    }

    resetChallengeSet(id: string) {
        return this.client.post('/challenge-results/reset-account-challenge', { challengeSetId: id })
    }

    async getMyOFCsets() {
        const res = await this.client.get<ApiTypes['challenge-set'][]>('challenge-sets', {
            params: {
                kind: 'ofc',
            },
        })

        const sets: OfcSetWithChallenges[] = []

        for await (const set of res.data) {
            const challenges = await this.client.get<ApiTypes['challenge'][]>(`challenge-sets/${set.id}/challenges`, {
                params: {
                    sort: 'index',
                    published: true,
                },
            })

            sets.push({ ...set, challenges: challenges.data })
        }
        return sets
    }

    async getMyChallengeSetResultById(challengeSetId: string) {
        const res = await this.client.get<ApiTypes['challenge-result'][]>(`challenge-sets/${challengeSetId}/results`, {
            params: {
                owner: this.getUserId(),
            },
        })

        return res.data
    }
}

import { Flex } from 'components/layout'
import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'

type PageTitleProps = {
    icon: React.ReactNode
}

export const PageTitle: React.FC<PageTitleProps> = ({ children, icon }) => {
    return (
        <Root variant="h3" component="h2">
            <Inner align="center">{icon}</Inner>
            {children}
        </Root>
    )
}

const Root = styled(Text)`
    display: flex;
    align-items: center;
    align-self: center;
    ${props => props.theme.breakpoints.down('xs')} {
        font-size: ${props => props.theme.typography.body1.fontSize};
    }
` as typeof Text

const Inner = styled(Flex)`
    margin-inline-end: ${props => props.theme.spacing(2)};
`

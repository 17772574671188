import cookieImg from './media/cookie.svg'
import cookieSolidImg from './media/cookie-solid.svg'
import goldenCookieImg from './media/golden-cookie.svg'
import goldenCookieSolidImg from './media/golden-cookie-solid.svg'
import cookiesIcon from './media/cookies.svg'

export const currencyStyles = {
    cookiedColor: '#ffaa00',
    cookiesIcon: cookiesIcon,
    cookie: {
        icon: {
            colored: cookieImg,
            solid: cookieSolidImg,
        },
        colors: {
            background: { light: '#f3e1b9', dark: '#262b3a' },
            color: { light: '#322c21', dark: '#f0e0bb' },
        },
    },
    'golden-cookie': {
        icon: {
            colored: goldenCookieImg,
            solid: goldenCookieSolidImg,
        },
        colors: {
            background: { light: '#333529', dark: '#2d2a20' },
            color: { light: '#ffaa00', dark: '#ffaa00' },
        },
    },
}

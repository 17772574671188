import { useEffect } from 'react'

const PREFIX = `MyLogiscool – `

export function useTitle(title?: string) {
    useEffect(() => {
        if (title) {
            document.title = ''
            document.title = PREFIX + title
        }
    }, [title])
}

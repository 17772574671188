import Axios from 'axios'
import { AccountsApi } from './AccountsApi'
import { LanguagesApi } from 'lib/i18n'
import { SecretCodesApi } from 'features/secret-code'
import { ProjectsApi } from 'features/projects'
import { CertificatesApi } from 'features/certificates'
import { EventsApi } from 'features/events/data/eventsApi'
import { MeetingsApi } from 'features/meetings'
import { ScoolcodeChallengeApi } from 'features/scoolcode-challenge'
import { runtimeEnv } from 'utils/env'
import { QuizApi } from 'features/quiz'
import { FeedApi } from 'features/feed/data/feedApi'
import { SurveysApi } from 'features/surveys'
import { MiniQuizApi } from 'features/mini-quizzes'
import { WallpapersApi } from 'features/wallpapers/data/WallpapersApi'
import {ManagementApi} from "../../features/management/ManagementApi";

class Api {
    readonly baseUrl = runtimeEnv.REACT_APP_API_URL
    readonly oauthBaseUrl = runtimeEnv.REACT_APP_OAUTH_URL
    readonly client = Axios.create({ baseURL: this.baseUrl, timeout: 15000 })
    accessToken: string | null = null

    private userId: string | null = null

    init(token: string) {
        this.accessToken = token
        this.client.defaults.params = {
            ...this.client.defaults.params,
            access_token: token,
        }
        this.client.interceptors.response.use(
            response => {
                return response
            },
            error => {
                if (error.response && error.response.status === 403 && error.response.data === 'Invalid Token') {
                    window.location.reload()
                }
                return Promise.reject(error)
            },
        )
    }

    setUserId = (userId: string) => {
        this.userId = userId
    }

    private getUserId = () => {
        return this.userId
    }

    readonly accounts = new AccountsApi(this.client)
    readonly languages = new LanguagesApi(this.client)
    readonly secretCodes = new SecretCodesApi(this.client)
    readonly projects = new ProjectsApi(this.client, this.getUserId)
    readonly certificates = new CertificatesApi(this.client, this.getUserId)
    readonly events = new EventsApi(this.client, this.getUserId)
    readonly quizzes = new QuizApi(this.client)
    readonly meetings = new MeetingsApi(this.client)
    readonly surveys = new SurveysApi(this.client)
    readonly scoolcodeChallenges = new ScoolcodeChallengeApi(this.client, this.getUserId)
    readonly feed = new FeedApi(this.client)
    readonly miniQuizzes = new MiniQuizApi(this.client, this.getUserId)
    readonly wallpapers = new WallpapersApi(this.client)
    readonly management = new ManagementApi(this.client)
}

export const removeTokenFromParams = (params?: Record<any, any>) => {
    return {
        ...params,
        access_token: undefined,
    }
}

export const api = new Api()

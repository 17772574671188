import React, { createContext, useEffect, useMemo } from 'react'
import { api, ApiTypes, CrmUserData } from 'lib/api'
import { UserSettingKey, UserSettings } from 'lib/api/AccountsApi'
import { i18n } from 'lib/i18n'
import { getGrants, hasGrant, NormalizedGrantList } from 'lib/grants'
import { useDispatch } from 'react-redux'
import { completeProfile } from 'lib/liveops'
import { storeActionListener } from 'lib/store/store-action-listener'
import { GRANTS_CHANGED, USER_CHANGED } from 'lib/liveops/constants'

export type UserState = {
    account: ApiTypes['account']
    crm: CrmUserData
    settings: Partial<Record<UserSettingKey, string>>
    i18n: { country: string; language: string; locale: string }
    grants: NormalizedGrantList
}

export const userStateContext = createContext<UserState | undefined>(undefined)
export const userDispatchContext = createContext<React.Dispatch<ReducerAction> | undefined>(undefined)

type ReducerAction =
    | {
          type: 'INIT'
          payload: UserState
      }
    | {
          type: 'UPDATE'
          payload: Partial<UserState>
      }

const reducer: React.Reducer<UserState | undefined, ReducerAction> = (state, action) => {
    if (!state) {
        return action.type === 'INIT' ? action.payload : state
    }
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                account: { ...state.account, ...action.payload.account },
                i18n: { ...state.i18n, ...action.payload.i18n },
                settings: { ...state.settings, ...action.payload.settings },
                grants: { ...state.grants, ...action.payload.grants },
                crm: { ...state.crm, ...action.payload.crm },
            }
        default:
            return state
    }
}

export const UserProvider: React.FC = ({ children }) => {
    const [state, dispatch] = React.useReducer(reducer, undefined)
    const locale = state?.i18n.locale

    useRefetchGrantsOnChange(dispatch)
    useRefetchUserOnChange(dispatch)

    useEffect(() => {
        i18n.setLocale(locale)
    }, [locale])

    return (
        <userStateContext.Provider value={state}>
            <userDispatchContext.Provider value={dispatch}>{children}</userDispatchContext.Provider>
        </userStateContext.Provider>
    )
}

export const useUserState = () => {
    const context = React.useContext(userStateContext)
    if (context === undefined) {
        throw new Error('useUserState is either used without UserProvider or before user is initialized.')
    }
    return context
}

export const useUserActions = () => {
    const reduxDispatch = useDispatch()
    const dispatch = React.useContext(userDispatchContext)

    if (dispatch === undefined) {
        throw new Error('useUserActions must be used within a UserProvider')
    }

    return useMemo(
        () => ({
            init: async (): Promise<UserState> => {
                const [account, crmData, settings, locale, grants] = await Promise.all([
                    api.accounts.getMe(),
                    api.accounts.getCrmData(),
                    api.accounts.getMySettings(),
                    api.accounts.getMyLocale(),
                    api.accounts.getMyGrants(),
                ])
                dispatch({
                    type: 'INIT',
                    payload: {
                        account: account.data,
                        settings: settings.data,
                        i18n: locale.data,
                        grants: grants.data,
                        crm: crmData.data,
                    },
                })
                return {
                    account: account.data,
                    crm: crmData.data,
                    settings: settings.data,
                    i18n: locale.data,
                    grants: grants.data,
                }
            },
            updateUser: async (updates: Partial<ApiTypes['account']>) => {
                if (Object.keys(updates).length > 0) {
                    const user = await api.accounts.updateMe(updates)

                    handleProfileCompleteAction(updates, user.data, reduxDispatch)

                    // the api won't return an alies field if set to an
                    // empty string so we have to handle this case
                    if (updates.aliasPending === '') {
                        user.data.aliasPending = ''
                        user.data.alias = ''
                    }
                    if (updates.descriptionPending === '') {
                        user.data.descriptionPending = ''
                    }

                    if (updates.uiLanguage) {
                        const locale = await api.accounts.getMyLocale()
                        dispatch({ type: 'UPDATE', payload: { account: user.data, i18n: locale.data } })
                    } else {
                        dispatch({ type: 'UPDATE', payload: { account: user.data } })
                    }
                }
            },
            updateUserSettings: async (settings: UserSettings) => {
                for (const [key, value] of Object.entries(settings)) {
                    await api.accounts.setMySetting(key as any, value as any)
                }
                dispatch({ type: 'UPDATE', payload: { settings } })
            },
            updateJSONUserSetting: async (key: UserSettingKey, field: string, value: string) => {
                let newValue
                try {
                    const setting = await api.accounts.getMySetting(key)
                    const parsedValue = JSON.parse(setting.data.value)
                    newValue = {
                        ...parsedValue,
                        [field]: value,
                    }
                } catch (e: any) {
                    if (e.response && e.response.status === 404) {
                        newValue = {
                            [field]: value,
                        }
                    } else {
                        throw e
                    }
                }
                const res = await api.accounts.setMySetting(key, JSON.stringify(newValue))
                dispatch({ type: 'UPDATE', payload: { settings: { [key]: res.data.value } } })
            },
            updateCrmFields: (fields: Partial<CrmUserData>) => {
                dispatch({ type: 'UPDATE', payload: { crm: fields } })
            },
        }),
        [dispatch, reduxDispatch],
    )
}

const handleProfileCompleteAction = (
    updates: Partial<ApiTypes['account']>,
    user: ApiTypes['account'],
    dispatch: ReturnType<typeof useDispatch>,
) => {
    let shouldSendAction = false

    if (updates.aliasPending) {
        if (user.descriptionPending || user.description) {
            shouldSendAction = true
        }
    }
    if (updates.descriptionPending) {
        if (user.aliasPending || user.alias) {
            shouldSendAction = true
        }
    }

    if (shouldSendAction) {
        dispatch(completeProfile())
    }
}

export const canUserCreateShareableProject = (user: UserState) => {
    return !!(
        hasGrant(user.grants)('share-project') &&
        user.account.sharedProjectLimit &&
        user.account.sharedProjectLimit > 0
    )
}

export const canUserMoveToProduction = (user: UserState) => {
    return hasGrant(user.grants)({ product: 'edup.scoolcode.ide', feature: 'move-to-production' })
}

export const hasUserShowAdvancedStats = (user: UserState) => {
    return hasGrant(user.grants)({ product: 'edup.universe', feature: 'view-stats' })
}

export const isLicensedUser = (user: UserState) => {
    return hasGrant(user.grants)({ product: 'edup', feature: 'license-user' })
}

export const isTrainerUser = (user: UserState) => {
    return hasGrant(user.grants)({ product: 'edup', feature: 'trainer-user' })
}

export const useHasShowAdvancedStats = () => {
    const user = useUserState()
    return user.settings['mylogiscool-show-advanced-statistics'] === 'true' && hasUserShowAdvancedStats(user)
}

export const useHasUserShowActivityScore = () => {
    const user = useUserState()
    const hasShowAdvancedStats = useHasShowAdvancedStats()
    return hasGrant(user.grants)({ product: 'edup.activity-score', feature: 'advanced-read' }) && hasShowAdvancedStats
}

export const canUserCreateProject = (user: UserState) => canUserCreateBloxProject(user) || canUserCreateMixProject(user)

export const canUserCreateBloxProject = (user: UserState) => {
    return hasGrant(user.grants)({ product: 'edup.scoolcode.ide', feature: 'create-project.blox' })
}

export const canUserCreateMixProject = (user: UserState) => {
    return (
        hasGrant(user.grants)({ product: 'edup.scoolcode.ide', feature: 'offboarding.mix' }) ||
        hasGrant(user.grants)({ product: 'edup.scoolcode.ide', feature: 'create-project.mix' })
    )
}

export const canUserCreatePythonProject = (user: UserState) => {
    return hasGrant(user.grants)({ product: 'edup.scoolcode.ide', feature: 'language-python' })
}

export const canUserCreateStageScriptProject = (user: UserState) => {
    return hasGrant(user.grants)({ product: 'edup.scoolcode.ide', feature: 'language-stagescript' })
}

export const canUnlockSecretCode = (user: UserState, secretCodeKind?: string) => {
    const redeemGrants = getGrants(user.grants)('redeem-secret-code')

    const hasValidRedeemGrant =
        redeemGrants.filter((grant: any) =>
            secretCodeKind
                ? !grant.data?.kinds || grant.data.kinds.includes(secretCodeKind) // if secretcode kind given, check if it's in the grant data kinds
                : !!!grant.data?.kinds,
        ).length > 0 // if secretcode kind not given, check if grant has kind specified, if yes, return false.
    return hasValidRedeemGrant
}

export const getUserPrivateName = (account: ApiTypes['account']) => {
    return account.aliasPending || account.alias || account.username
}

export const getUserPublicName = (account: ApiTypes['account']) => {
    return account?.alias || account.username
}

export const getUserProjectLanguages = (user: UserState, languages: ApiTypes['language'][]) => {
    let langs = [user.account.language!, 'en']
    if (user.settings['universe-project-languages']) {
        try {
            langs = JSON.parse(user.settings['universe-project-languages'])
        } catch (e) {}
    }

    function filterValidLanguages(language: string) {
        return !!languages.find(lang => lang.code === language)
    }

    return Array.from(new Set(langs)).filter(filterValidLanguages)
}

export const isGuestUser = (user: UserState) => {
    return user.account.stage === 'lead'
}

export const isRegisteredGuestUser = (user: UserState) => {
    return user.account.stage === 'registered-lead'
}

export const isGuestOrRegisteredGuestUser = (user: UserState) => {
    return isGuestUser(user) || isRegisteredGuestUser(user)
}

export const isOpenDayUser = (user: UserState) => {
    return user.account.subStage === 'open-day'
}

const useRefetchGrantsOnChange = (dispatch: React.Dispatch<ReducerAction>) => {
    useEffect(() => {
        const onGrantsChanged = () => {
            setTimeout(() => {
                api.accounts
                    .getMyGrants()
                    .then(grants => {
                        dispatch({ type: 'UPDATE', payload: { grants: grants.data } })
                    })
                    .catch(e => {
                        console.error(e)
                    })
            }, 150)
        }

        storeActionListener.on(GRANTS_CHANGED, onGrantsChanged)

        return () => {
            storeActionListener.removeListener(GRANTS_CHANGED, onGrantsChanged)
        }
    }, [dispatch])
}

const useRefetchUserOnChange = (dispatch: React.Dispatch<ReducerAction>) => {
    useEffect(() => {
        const onGrantsChanged = () => {
            setTimeout(() => {
                api.accounts
                    .getMe()
                    .then(account => {
                        dispatch({ type: 'UPDATE', payload: { account: account.data } })
                    })
                    .catch(e => {
                        console.error(e)
                    })
            }, 150)
        }

        storeActionListener.on(USER_CHANGED, onGrantsChanged)

        return () => {
            storeActionListener.removeListener(USER_CHANGED, onGrantsChanged)
        }
    }, [dispatch])
}

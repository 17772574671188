import { COMPLETE_PROFILE, CHANGED_PROFILE_PICTURE } from '../constants'
import { client } from 'redux-socket-client'

export const completeProfile = client(() => ({
    type: COMPLETE_PROFILE,
}))

export const changedProfilePicture = client(() => ({
    type: CHANGED_PROFILE_PICTURE,
}))

import React from 'react'
import { ProjectCard, ProjectCardContent, ProjectCardCookieCount, ProjectCardImage, ProjectCardUser } from '.'
import { createProjectThumbnailUrl } from '../projectHelpers'
import { Project } from '../store'
import { MinimalAccount } from 'lib/api'
import { ProjectCardViewCount } from './ProjectCardViewCount'
import { DecodedValueMap } from 'serialize-query-params'
import { DiscoverProjectsFilterState } from '../discover/DiscoverProjects'
import { useIncrementActivityScore } from '../../../hooks/useIncrementActivityScore'

interface PublicProjectCardProps {
    project: Project
    user?: MinimalAccount
    filters?: DecodedValueMap<DiscoverProjectsFilterState>
}

const getViewCount = (project: Project, dayFilter: number) => {
    const record = dayFilter.toString()

    const daysRecord: Record<string, number | undefined> = {
        '1': project.file.daysViewedCount_1,
        '7': project.file.daysViewedCount_7,
        '30': project.file.daysViewedCount_30,
        '-1': project.file.viewedCount,
    }

    return daysRecord[record] ?? 0
}

export const PublicProjectCard: React.FC<PublicProjectCardProps> = ({ project, user, filters }) => {
    const incrementActivityScore = useIncrementActivityScore()
    const to = `/app/project/${project.file.id}`

    return (
        <ProjectCard>
            <ProjectCardImage
                onClick={() => {
                    incrementActivityScore('community:project:open-details')
                    return true
                }}
                to={to}
                src={createProjectThumbnailUrl(project.file.id, 'production')}
                badges={
                    <>
                        <ProjectCardCookieCount
                            goldenCount={project.file.goldenCookieCount}
                            count={project.file.cookieCount}
                            cookied={!!project.metadata.cookied}
                            goldenCookied={!!project.metadata.goldenCookied}
                        />
                        {filters && filters?.sort === '-daysViewedCount' && (
                            <ProjectCardViewCount count={getViewCount(project, filters.days)} />
                        )}
                    </>
                }
            />
            <ProjectCardContent title={{ label: project.file.title, to }}>
                <ProjectCardUser user={user} />
            </ProjectCardContent>
        </ProjectCard>
    )
}

import { UserState } from 'contexts/UserContext'

type AgeGroup = 'small' | 'big'

const AGE_GROUP_MARGIN = 12

export const isUserHunGirl = (user: UserState) => {
    return user.account.country === 'HU' && user.crm.gender === 'f'
}

export function getAgeGroupFromAge(age?: number): AgeGroup {
    if (age === undefined) {
        return 'big'
    }
    if (age < AGE_GROUP_MARGIN) {
        return 'small'
    }
    return 'big'
}

export const templateIdAgeGroupDict: Record<AgeGroup, string[]> = {
    small: ['5f845c80c84e58000abc6d2a', '5f85a7ecd69a4d000979ae74', '5f86b44cc84e58000abc8780'],
    big: ['5f8560b6c84e58000abc75a8', '5f85bc4ac84e58000abc7db1', '5f86c9dfd69a4d000979becc'],
}

import React from 'react'
import styled from 'styled-components/macro'
import { LockIcon } from './icons'
import { Tooltip } from './Tooltip'

interface LockedProps {
    disabled?: boolean
    tooltip?: string
}

export const Locked: React.FC<LockedProps> = ({ children, tooltip, disabled = false }) => {
    if (disabled) {
        return <>{children}</>
    }

    const body = (
        <Root>
            <Icon /> {children}
        </Root>
    )

    return tooltip ? <Tooltip title={tooltip}>{body}</Tooltip> : body
}

const Icon = styled(LockIcon)`
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    transform: translate(35%, -35%) rotate(15deg);
    font-size: 1.15rem;
    color: ${props => props.theme.palette.text.disabled};
`

const Root = styled.span`
    position: relative;
    > *:not(${Icon}) {
        width: 100%;
    }
`

import { Card } from 'components/card'
import { Loader } from 'components/Loader'
import { useUserState } from 'contexts/UserContext'
import { isInventoryReady } from 'lib/liveops'
import { useSelector } from 'lib/store'
import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components/macro'
import { QueryParamProvider, StringParam, useQueryParam, withDefault } from 'use-query-params'
import { getOnboardingAchievements, OnboardingAchievementData } from '../data/onboardingData'
import { OnboardingAchievement } from './OnboardingAchievement'
import { OnboardingAchievementDetails } from './OnboardingAchievementDetails'

import { InventoryState } from 'lib/liveops/reducers/client/inventory'
import { getFirstNotCompletedAchievement, getOnboadingPoints } from '../helpers/onboardingHelpers'
import { AvatarBrowserDialog } from 'features/avatar'
import { OnboardingProgress } from './OnboardingProgress'
import { Hidden, Stack } from 'components/layout'
import { Dialog } from 'components/dialog'
import { Theme, useMediaQuery } from '@material-ui/core'

export const OnboardingAchievementList: React.FC = () => {
    const user = useUserState()
    const points = useSelector(getOnboadingPoints)
    const isReady = useSelector(isInventoryReady) && points !== undefined
    const inventory = useSelector(state => state.client.inventory)

    if (!isReady) {
        return <Loader />
    }

    const onboardingAchievements = getOnboardingAchievements(user, inventory)

    return (
        <QueryParamProvider ReactRouterRoute={Route}>
            <Inner achievements={onboardingAchievements} inventory={inventory} />
            <AvatarBrowserDialog />
        </QueryParamProvider>
    )
}

const Inner: React.FC<{ achievements: OnboardingAchievementData[]; inventory: InventoryState }> = ({
    achievements,
    inventory,
}) => {
    const firstNotCompleted = getFirstNotCompletedAchievement(achievements, inventory)
    const [selected, setSelected] = useQueryParam('selected', withDefault(StringParam, firstNotCompleted.catalogItem))
    const [isDialogOpen, setDialogOpen] = React.useState(false)
    const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'), { noSsr: true })

    const selectedAchievement = achievements.find(({ catalogItem }) => catalogItem === selected) || achievements[0]

    return (
        <Stack spacing={6}>
            <Card>
                <Grid>
                    <Wrap>
                        <InnerGrid>
                            {achievements.map(achievement => (
                                <OnboardingAchievement
                                    key={achievement.catalogItem}
                                    achievement={achievement}
                                    isSelected={achievement.catalogItem === selectedAchievement?.catalogItem}
                                    onClick={() => {
                                        setSelected(achievement.catalogItem, 'replaceIn')
                                        if (isMobile) {
                                            setDialogOpen(true)
                                        }
                                    }}
                                />
                            ))}
                        </InnerGrid>
                    </Wrap>
                    <Hidden xsDown>
                        <OnboardingAchievementDetails
                            key={selectedAchievement.catalogItem}
                            achievement={selectedAchievement}
                        />
                    </Hidden>
                    <Hidden smUp>
                        <DetailsDialog open={isDialogOpen} close={() => setDialogOpen(false)}>
                            <OnboardingAchievementDetails
                                key={selectedAchievement.catalogItem}
                                achievement={selectedAchievement}
                            />
                        </DetailsDialog>
                    </Hidden>
                </Grid>
            </Card>
            <OnboardingProgress achievements={achievements} />
        </Stack>
    )
}

interface DetailsDialogProps {
    open: boolean
    close: VoidFunction
}

const DetailsDialog: React.FC<DetailsDialogProps> = ({ children, open, close }) => {
    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={close}>
            {children}
        </Dialog>
    )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 3fr 2fr;
    align-items: flex-start;

    ${props => props.theme.breakpoints.down('xs')} {
        display: block;
    }
`

const Wrap = styled(Stack)`
    padding: ${props => props.theme.spacing(6, 4)};
`

const InnerGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
    grid-gap: ${props => props.theme.spacing(6)};
`

import React from 'react'
import { OnboardingAchievementUnlockedDialogData } from '../store/dialogQueueReducer'
import { useTranslate } from 'lib/i18n/useTranslate'
import { QueuedDialog } from './QueuedDialog'

interface OnboardingAchievementUnlockedDialogProps {
    data: OnboardingAchievementUnlockedDialogData
    open: boolean
    onClose: VoidFunction
}

export const OnboardingAchievementUnlockedDialog: React.FC<OnboardingAchievementUnlockedDialogProps> = ({
    onClose,
    open,
    data,
}) => {
    const t = useTranslate()
    const { imageUrl, color, title } = data.achievement

    return (
        <QueuedDialog
            open={open}
            onClose={onClose}
            image={{ url: imageUrl, backgroundColor: color }}
            title={t('onboardingMission::achievementPopUp::title')}
            subtitle={t(title)}
            description={t('onboardingMission::achievementPopUp::description', {
                MissionTaskName: t(title),
                MissionName: t('OnboardingMission::title'),
            })}
            button={{
                label: t('onboardingMission::achievementPopUp::button'),
                to: '/app/events/mylogiscool-mission#missions',
            }}
        />
    )
}

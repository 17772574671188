import React, { useState } from 'react'
import { Dialog, DialogProps } from 'components/dialog'
import styled from 'styled-components/macro'

interface ImageZoomProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    DialogProps?: Partial<DialogProps>
}

export const ImageZoom: React.FC<ImageZoomProps> = ({ onClick, src, alt, DialogProps, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <>
            <Image
                src={src}
                alt={alt}
                onClick={e => {
                    onClick?.(e)
                    setIsOpen(true)
                }}
                {...rest}
            />
            <Dialog
                transitionDuration={200}
                maxWidth={false}
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                }}
                {...DialogProps}
            >
                <DialogImg src={src} alt={alt} />
            </Dialog>
        </>
    )
}

const Image = styled.img`
    cursor: zoom-in;
`

const DialogImg = styled.img`
    max-height: calc(100vh - 64px);
`

import React from 'react'
import styled from 'styled-components/macro'
import footerImg from './halloween_footer.webp'
import {CookieHunt} from "../../../../features/cookie-hunt";

interface HalloweenFooterProps {}

export const HalloweenFooter: React.FC<HalloweenFooterProps> = () => {
    return (
        <Root>
            <PotWrap>
                <CookieHunt name="halloween-footer" />
            </PotWrap>
            <Image width="2644" height="1038" src={footerImg} />
        </Root>
    )
}

const PotWrap = styled.div`
    position: absolute;
    top: 67.8%;
    left: 66.8%;
`

const Root = styled.div`
    position: relative;
`

const Image = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
`

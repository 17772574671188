import { action } from 'typesafe-actions'
import { CAST_VOTE_REQUEST } from '../constants'
import { client } from 'redux-socket-client'

export const castVote = client((campaign: string, target: string, count: number = 1, skipToken?: boolean) =>
    action(CAST_VOTE_REQUEST, {
        campaign,
        target,
        count,
        skipToken,
    }),
)

import React from 'react'
import styled from 'styled-components/macro'

interface LeaderboardProps {}

export const Leaderboard: React.FC<LeaderboardProps> = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled.ol`
    padding: ${props => props.theme.spacing(0, 3)};
    margin-left: 0;
    margin-right: 0;
`

import { UserState } from 'contexts/UserContext'
import { isUserHunGirl, getAgeGroupFromAge, templateIdAgeGroupDict } from './helpers/aiQuizHelper'
import { getUserAge } from 'hooks/useGetUserAge'
import { QuizSetWithEmbeddedTemplates } from '../QuizApi'
import { isQuizsetWithEmbeddedTemplates, QuizSetWithMetadata } from '..'

export const QuizCustomIds = ['5f84619fc84e58000abc6ed6']

export type QuizCustomIdKey = typeof QuizCustomIds[number]

type CustomQuizTransformer = (
    quizSet: QuizSetWithEmbeddedTemplates | QuizSetWithMetadata,
    user: UserState,
) => QuizSetWithEmbeddedTemplates | QuizSetWithMetadata

export const customQuizTransformer: Record<QuizCustomIdKey, CustomQuizTransformer> = {
    '5f84619fc84e58000abc6ed6': (set, user) => {
        if (isUserHunGirl(user)) {
            set.name = 'Digigirlz - MI és fenntarthatóság kvíz'
            set.description =
                'Digigirlzösöknek: Érdekel a mesterséges intelligencia? Tudod, hogy mire képes ma egy AI? Készen állsz egy újabb digitális kihívásra? Próbáld ki magad legújabb Digigirlz kvízsorozatunkon!'
        }

        const age = getUserAge(user.crm)

        const AgeGroup = getAgeGroupFromAge(age)

        const filteredTemplates = isQuizsetWithEmbeddedTemplates(set)
            ? set.templates.filter(template => templateIdAgeGroupDict[AgeGroup].includes(template.id))
            : set.templates.filter(template => templateIdAgeGroupDict[AgeGroup].includes(template))

        set.templates = filteredTemplates

        return set
    },
}

import { StoreState, useSelector } from 'lib/store'
import { HighScoreRoom } from './reducers/shared/leaderboard'

export function getScoreList(state: StoreState, projectId: string): string[] | null {
    return state.shared.stats.scoreList[projectId] ?? null
}

export function getHighScoreRoom(state: StoreState, roomId: string): HighScoreRoom | undefined {
    return state.shared.stats.highScoreRooms.byId[roomId]
}

export function isLiveopsReady(state: StoreState) {
    return state.client.ready.app
}

export function isInventoryReady(state: StoreState) {
    return state.client.inventory !== null
}

export function useHasInventoryItem(itemId?: string) {
    const inventory = useSelector(state => state.client.inventory)

    if (!inventory || !itemId) {
        return undefined
    }

    return !!inventory.find(item => item.item === itemId)
}

import { Button } from 'components/Button'
import { FormControl, FormLabel } from 'components/form'
import { useUserState } from 'contexts/UserContext'
import { useTranslate } from 'lib/i18n/useTranslate'
import { isGrantDevToolEnabled } from './grantDevToolUtilts'
import React from 'react'

export const GrantDevToolButton: React.FC = () => {
    const user = useUserState()
    const t = useTranslate()
    const isEnabled = isGrantDevToolEnabled(user)

    if (!isEnabled) {
        return null
    }

    return (
        <FormControl>
            <FormLabel>{t('User schemas')}</FormLabel>
            <div>
                <Button onClick={() => window.openGrantDevtool?.()}>Open grant tool</Button>
            </div>
        </FormControl>
    )
}

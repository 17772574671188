import {useWaitForEnd} from 'hooks/useCountdown'
import { isInventoryReady } from 'lib/liveops'
import { useSelector } from 'lib/store'
import React, {useEffect, useMemo} from 'react'
import {activityEventContext, FakeDateInfo} from '../helpers/activityEventContext'
import { normalizeActivityEvent } from '../helpers/normalizeActivityEvent'
import { useSubscribeToActivityEventPoints } from '../helpers/useSubscribeToActivityEventPoints'
import { ActivityEvent } from '../types/activityEventTypes'
import {useManagementVariable} from "../../../management";

interface ActivityEventContextProviderProps {
    event: ActivityEvent
    allActivityEvents: ActivityEvent[]
    fallback: React.ReactNode
    onReady?: VoidFunction
}

export const ActivityEventContextProvider: React.FC<ActivityEventContextProviderProps> = ({
    event,
    allActivityEvents,
    children,
    fallback,
    onReady,
}) => {
    const [ fakeDate, setFakeDate ] = useManagementVariable<FakeDateInfo|null>(`${event.group}-fakeDate`)
    const [ fakePoints, setFakePoints ] = useManagementVariable<number|null>(`${event.group}-fakePoints`)
    const normalizedEvent = useMemo(() => normalizeActivityEvent(event), [event])
    const points = useSubscribeToActivityEventPoints(normalizedEvent, fakePoints)
    const isEnd = useWaitForEnd(normalizedEvent.endDate)
    const inventoryReady = useSelector(isInventoryReady)
    const pointsReady = points.total !== undefined
    const certificateInventoryItem = useSelector(state =>
        state.client.inventory?.find(item => item.item === event.certificate?.catalogItem),
    )
    const hideSubEventRibbons = normalizedEvent.subEvents.every(subEvent => subEvent.kind === 'guaranteed')
    const relatedEvents = React.useMemo(() => event.group
        ? allActivityEvents
              .filter(e => e.group === event.group)
        : [], [event.group, allActivityEvents]);
    const relatedEventSlugs = React.useMemo(() => event.group
        ? allActivityEvents
              .filter(e => e.group === event.group)
              .map(event => event.slug)
              .sort((a, b) => a.localeCompare(b))
        : [], [ event.group, allActivityEvents ]);

    useEffect(() => {
        if (onReady && pointsReady && inventoryReady) {
            onReady()
        }
    }, [onReady, pointsReady, inventoryReady])

    if (!pointsReady || !inventoryReady) {
        return <>{fallback}</>
    }

    return (
        <activityEventContext.Provider
            value={{
                event: normalizedEvent,
                rawEvent: event,
                relatedEvents,
                points,
                hasEnded: fakeDate ? false : isEnd,
                certificateInventoryItem,
                hideSubEventRibbons,
                relatedEventSlugs,
                fakeDate,
                setFakeDate,
                currentFakeDate: fakeDate?.[1],
                fakePoints,
                setFakePoints
            }}
        >
            {children}
        </activityEventContext.Provider>
    )
}

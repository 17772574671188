import React from 'react'
import styled from 'styled-components/macro'

interface EmbeddedVideoProps {
    type: 'youtube' | 'vimeo'
    id: string
    autoPlay?: boolean
}

export const EmbeddedVideo = React.forwardRef<any, EmbeddedVideoProps>(({ id, autoPlay, type }, ref) => {
    const body = (() => {
        switch (type) {
            case 'youtube':
                return (
                    <iframe
                        ref={ref}
                        src={`https://www.youtube.com/embed/${id}${autoPlay ? '?autoplay=1' : ''}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    />
                )
            case 'vimeo':
                return (
                    <iframe
                        ref={ref}
                        src={`https://player.vimeo.com/video/${id}${autoPlay ? '?autoplay=1' : ''}`}
                        title="Vimeo video player"
                        frameBorder="0"
                        allow="autoplay; fullscreen; picture-in-picture"
                        allowFullScreen
                    />
                )
        }
    })()

    return <Root>{body}</Root>
})

const Root = styled.div`
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    background-color: ${props => props.theme.palette.divider};
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

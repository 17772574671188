import { InventoryItem } from 'lib/liveops/models'
import { createContext, useContext } from 'react'
import {ActivityEvent, NormalizedActivityEvent} from '../types/activityEventTypes'
import { useSubscribeToActivityEventPoints } from './useSubscribeToActivityEventPoints'
import {Dayjs} from "dayjs";

export type FakeDateInfo = [string, Dayjs]

interface ActivityEventContextState {
    event: NormalizedActivityEvent
    rawEvent: ActivityEvent
    relatedEvents: ActivityEvent[]
    points: ReturnType<typeof useSubscribeToActivityEventPoints>
    hasEnded: boolean
    certificateInventoryItem: InventoryItem | undefined
    hideSubEventRibbons: boolean
    relatedEventSlugs: string[]
    fakeDate: FakeDateInfo|null
    setFakeDate: (date: FakeDateInfo|null) => void
    fakePoints: number|null
    setFakePoints: (value: number|null) => void
    currentFakeDate: Dayjs|undefined
}

export const activityEventContext = createContext<ActivityEventContextState | undefined>(undefined)

export const useActivityEventContext = () => {
    const state = useContext(activityEventContext)

    if (state === undefined) {
        throw new Error("'useActivityEventContext' must be used within an 'ActivityEventDetails'.")
    }

    return state
}

import React from 'react'
import { ProjectModalBase, ProjectModalContent, ProjectModalHeader } from 'features/projects'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import { isValidXmasProjectId } from '../data/xmasData'
import { XmasProjectDetails } from './XmasProjectDetails'

export const XmasProjectDetailsPage = () => {
    const t = useTranslate()

    return (
        <ProjectModalBase projectVariant="normal" isNotFound={({ id }) => !isValidXmasProjectId(id)}>
            <ProjectModalHeader>
                <Text variant="h3">{t('XmasGiftBanner::QuizProject::title')}</Text>
            </ProjectModalHeader>
            <ProjectModalContent>
                <XmasProjectDetails />
            </ProjectModalContent>
        </ProjectModalBase>
    )
}

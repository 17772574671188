import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import { api } from '../api'

type directionChangeHandler = (direction: 'rtl' | 'ltr') => void

class I18n {
    public isInitialized = false
    private rtlLocales: string[] = []
    private directionChangeHandlers: directionChangeHandler[] = []

    private init = async (locale: string) => {
        await i18next
            .use(Backend)
            .use(initReactI18next)
            .init({
                ns: ['general'],
                returnEmptyString: false,
                load: 'currentOnly',
                keySeparator: '::',
                nsSeparator: '$',
                defaultNS: 'general',
                backend: {
                    loadPath: `${api.baseUrl}/language-files/my-next/{{lng}}.json`,
                    crossDomain: true,
                    parse: (response: string) => ({ ...JSON.parse(response).data }),
                },
                fallbackLng: 'en',
                lng: locale,
                react: {
                    transKeepBasicHtmlNodesFor: ['li', 'strong', 'em'],
                },
            })
        this.isInitialized = true
    }

    private setDirection = (locale: string) => {
        const direction = this.rtlLocales.includes(locale) ? 'rtl' : 'ltr'
        document.documentElement.setAttribute('dir', direction)
        this.directionChangeHandlers.forEach(handler => {
            handler(direction)
        })
    }

    addDirectionChangeListener = (handler: directionChangeHandler) => {
        this.directionChangeHandlers.push(handler)
    }

    removeDirectionChangeListener = (handler: directionChangeHandler) => {
        this.directionChangeHandlers = this.directionChangeHandlers.filter(h => h !== handler)
    }

    setLocale = async (locale?: string) => {
        if (!locale) {
            return
        }
        this.setDirection(locale)
        document.documentElement.lang = locale
        if (this.isInitialized) {
            await i18next.changeLanguage(locale)
        } else {
            await this.init(locale)
        }
    }
}

export const i18n = new I18n()

import { api } from 'lib/api'
import { useQuery, useQueryCache } from 'react-query'
import { useCallback } from 'react'
import { VoteTarget } from 'features/events/data/eventsApi'

export function useGetVoteTargetsForCampign(id?: string) {
    return useQuery(['vote-targets', id], () => api.feed.getVoteTargetsForCampiagn(id!), {
        enabled: !!id,
    })
}

export function useVoteTargetsInvalidation() {
    const queryCache = useQueryCache()

    const invalidate = useCallback(
        (id: string) => {
            queryCache.invalidateQueries(['vote-targets', id])
        },
        [queryCache],
    )

    return invalidate
}

export function useSetVoteCountforCampaign() {
    const queryCache = useQueryCache()

    const setVoteCount = useCallback(
        (champaignId: string, targetId) => {
            queryCache.setQueryData<VoteTarget[]>(['vote-targets', champaignId], data => {
                if (!data) return []

                return data.map(vote =>
                    vote.id === targetId ? { ...vote, voteCount: (vote.voteCount ?? 0) + 1 } : vote,
                )
            })
        },
        [queryCache],
    )

    return setVoteCount
}

/* export function useGetFeedEntries() {
    return useQuery('news-feed', () => api.feed.getFeedEntries())
} */

import React from 'react'
import { MyClassroomProjectCard } from './MyClassroomProjectCard'
import styled, { css } from 'styled-components/macro'
import { EmptyState } from 'components/EmptyState'
import { useBlockGalleriesQuery, useMyPendingProjectsQuery } from '../projectQueries'
import { MyCommunityProjectCard } from './MyCommunityProjectCard'
import { useUserState } from 'contexts/UserContext'
import { useTranslate } from 'lib/i18n/useTranslate'
import { CreateYourFirstProject } from './CreateYourFirstProject'
import { projectListGridStyles } from '../commonProjectStyles'
import { Project } from '../store'
import { MyProjectsQuery } from '../ProjectsApi'
import { MyDeletedProjectCard } from './MyDeletedProjectCard'
import { ProjectListLayout } from './MyProjects'
import { CookieHunt } from 'features/cookie-hunt'

interface MyProjectsListProps {
    isFiltering: boolean
    projects: Project[] | undefined
    view: MyProjectsQuery['view']
    listLayout: ProjectListLayout
}

export const MyProjectList: React.FC<MyProjectsListProps> = ({ isFiltering, projects, view, listLayout }) => {
    const blockGalleriesQuery = useBlockGalleriesQuery()
    const { account } = useUserState()
    const { data: pendingProjects } = useMyPendingProjectsQuery()
    const t = useTranslate()

    if (!projects) {
        return null
    }

    if (projects.length === 0) {
        if (isFiltering || view !== 'all') {
            return <EmptyState>{t('general::noResults')}</EmptyState>
        }
        return <CreateYourFirstProject />
    }

    return (
        <Grid listLayout={listLayout} data-testid={`myprojects-container-${listLayout}`}>
            {projects.map((project, i) => {
                if (view === 'deleted') {
                    return <MyDeletedProjectCard displayType={listLayout} project={project} key={project.file.id} />
                }

                if (project.metadata.shareable) {
                    return (
                        <MyCommunityProjectCard
                            user={account}
                            blockGalleriesQuery={blockGalleriesQuery}
                            key={project.file.id}
                            project={project}
                            isPending={pendingProjects?.includes(project.file.id)}
                            layout={listLayout}
                        />
                    )
                }

                return (
                    <CookieHunt name="my-projects-list" index={i} key={project.file.id}>
                        <MyClassroomProjectCard
                            layout={listLayout}
                            blockGalleriesQuery={blockGalleriesQuery}
                            project={project}
                        />
                    </CookieHunt>
                )
            })}
        </Grid>
    )
}

const projectListStyles = css`
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto;
    > * + * {
        margin-top: ${props => props.theme.spacing(4)};
    }
`

const Grid = styled.div<{ listLayout: 'grid' | 'list' }>`
    ${props => (props.listLayout === 'grid' ? projectListGridStyles : projectListStyles)}
`

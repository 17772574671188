import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { Stack } from 'components/layout'
import { Loader } from 'components/Loader'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { useParams } from 'react-router-dom'
import { DetailedQuizResult } from '../DetailedQuizResult'
import { useQuizSetsQuery } from './homeQuizQueries'
import { Link } from 'react-router-dom'
import { LocalisationKey } from 'lib/i18n'

interface HomeQuizAnswersProps {}

export const HomeQuizAnswers: React.FC<HomeQuizAnswersProps> = () => {
    const { sessionId, setId } = useParams<{ sessionId: string; setId: string }>()
    const { data: quizSets } = useQuizSetsQuery()
    const set = quizSets?.find(set => set.id === setId)
    const t = useTranslate()

    if (!set) {
        return <Loader />
    }

    return (
        <Stack>
            <Breadcrumbs>
                <Button variant="text" component={Link} to="/app/challenges/quizzes">
                    All Quizzes
                </Button>
                <Button variant="text" component={Link} to={`/app/challenges/quizzes/${set.id}`}>
                    {t(set.name as LocalisationKey)}
                </Button>
            </Breadcrumbs>
            <ErrorBoundary>
                <DetailedQuizResult sessionId={sessionId} />
            </ErrorBoundary>
        </Stack>
    )
}

import { Chip } from '@material-ui/core'
import { Autocomplete, createFilterOptions } from '@material-ui/lab'
import { FormControl, FormLabel, Input } from 'components/form'
import { AddIcon, TagIcon } from 'components/icons'
import { Paper } from 'components/Paper'
import { useTranslate } from 'lib/i18n/useTranslate'
import { transparentize } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useMyCustomProjectLabelsQuery } from './projectQueries'

const MAX_LABEL_LENGTH = 50
const MAX_NUMBER_OF_LABELS = 20

type Value = { value: string; label: string } | string

const filter = createFilterOptions<Value>()

type Variant = 'edit' | 'filter'

interface ProjectLabelSelectProps {
    value?: string[]
    onChange?: (value: string[]) => void
    defaultValue?: string[]
    variant: Variant
}

export const ProjectLabelSelect: React.FC<ProjectLabelSelectProps> = ({
    defaultValue,
    onChange,
    variant,
    value: controlledValue,
}) => {
    const [uncontrolledValue, setUncontrolledValue] = React.useState<string[]>(defaultValue ?? [])
    const { data: options } = useMyCustomProjectLabelsQuery()
    const t = useTranslate()

    const value = controlledValue ?? uncontrolledValue
    const isFull = value.length >= MAX_NUMBER_OF_LABELS

    return (
        <Autocomplete<Value, true>
            options={options ?? []}
            // @ts-ignore something is weird with mui typing
            disableClearable={variant === 'edit'}
            // @ts-ignore something is weird with mui typing
            freeSolo={variant === 'edit' && !isFull}
            multiple
            noOptionsText={t(!options || options.length === 0 ? 'projects::noTags' : 'general::noResults')}
            getOptionDisabled={() => variant === 'edit' && isFull}
            openOnFocus
            renderInput={params => {
                return (
                    <FormControl id={params.id} fullWidth>
                        <FormLabel {...params.InputLabelProps}>{t('projects::tags')}</FormLabel>
                        <StyledInput
                            $variant={variant}
                            $isEmpty={value.length === 0}
                            placeholder={
                                value.length === 0
                                    ? t(variant === 'edit' ? 'projects::addTagsPlaceholder' : 'general::all')
                                    : undefined
                            }
                            fullWidth
                            inputProps={{ maxLength: MAX_LABEL_LENGTH, ...params.inputProps }}
                            {...params.InputProps}
                        />
                    </FormControl>
                )
            }}
            renderTags={(values, getProps) =>
                values.map((value, i) => <StyledChip label={value} {...getProps({ index: i })} />)
            }
            value={value}
            onChange={(_, newValue) => {
                const normalizedValues = newValue.map(value => (typeof value === 'string' ? value : value.value))
                if (!controlledValue) {
                    setUncontrolledValue(normalizedValues)
                }
                onChange?.(normalizedValues)
            }}
            renderOption={option => {
                if (typeof option === 'string') {
                    return (
                        <OptionRoot>
                            <TagIcon />
                            <span>{option}</span>
                        </OptionRoot>
                    )
                }
                return (
                    <OptionRoot style={{ fontStyle: 'italic' }}>
                        <AddIcon />
                        <span>{option.label}</span>
                    </OptionRoot>
                )
            }}
            PaperComponent={StyledPaper}
            filterOptions={(options, params) => {
                const filtered = filter(options, params).filter(
                    option => !value.includes(typeof option === 'string' ? option : option.value),
                )

                if (
                    variant === 'edit' &&
                    params.inputValue !== '' &&
                    !options.includes(params.inputValue) &&
                    !value.includes(params.inputValue)
                ) {
                    filtered.push({
                        label: t('general::createTransitive', {
                            value: params.inputValue,
                            interpolation: { escapeValue: false },
                        }),
                        value: params.inputValue,
                    })
                }

                return filtered
            }}
        />
    )
}

const StyledInput = styled(Input)<{ $variant: Variant; $isEmpty: boolean }>`
    ${props =>
        props.$variant === 'filter' &&
        css`
            > input::placeholder {
                opacity: 1;
                font-style: italic;
            }
        `}
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: ${props => (props.$isEmpty ? '1rem' : '0.5rem')} !important;
    input {
        padding: 0.625rem 0.25rem !important;
    }
`

const OptionRoot = styled.span`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing(2)};
    word-break: break-word;
    svg {
        flex-shrink: 0;
        color: ${props => props.theme.palette.text.secondary};
    }
`

const StyledPaper = styled(Paper)`
    box-shadow: ${props => props.theme.shadows[3]};

    ul {
        padding: ${props => props.theme.spacing(2)};
    }

    .MuiAutocomplete-option {
        padding: ${props => props.theme.spacing(3)};
        border-radius: ${props => props.theme.shape.borderRadius}px;
    }
`

const StyledChip = styled(Chip)`
    font-size: ${props => props.theme.typography.body1.fontSize};
    background-color: ${props => transparentize(0.9, props.theme.palette.primary.main)};
    color: ${props => props.theme.palette.primary.main};
    svg {
        color: ${props => props.theme.palette.primary.main};
        &:hover,
        &:focus-visible {
            color: ${props => props.theme.palette.primary.main};
        }
    }
`

import { Flex, Stack } from 'components/layout'
import { Leaderboard, LeaderboardItem } from 'components/leaderboard'
import { Loader } from 'components/Loader'
import { Tab, Tabs } from 'components/tabs'
import { getUserPrivateName, getUserPublicName, useHasShowAdvancedStats, useUserState } from 'contexts/UserContext'
import { createAvatarUrl } from 'features/avatar'
import {
    disableAllLeaderboardsForProject,
    enableProjectLeaderboard,
    getScoreList,
    isLiveopsReady,
    requestScoreList,
} from 'lib/liveops'
import { useSelector } from 'lib/store'
import React, { useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { EmptyState } from 'components/EmptyState'
import { TypedTFunction, useTranslate } from 'lib/i18n/useTranslate'
import { UserBadge } from 'features/profile'
import styled from 'styled-components/macro'
import { FetchedLeaderboard, LeaderboardRank } from '../../lib/liveops/models'
import { LocalisationKey } from 'lib/i18n'
import { Select, Option } from 'components/form'
import { HighScoreGame, highScoreGames } from './highScoreGames'
import { useCountdown } from 'hooks/useCountdown'
import dayjs from 'dayjs'
import { Text } from 'components/Text'
import { ApiTypes } from 'lib/api'
import { joinHighScoreRoom, leaveHighScoreRoom } from '../projects'

const Trophy: React.FC<{ color: string }> = ({ color }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" viewBox="0 0 158.47 172.12">
            <g>
                <path
                    d="M157.24 29a6.2 6.2 0 00-5-2.48h-26q.15-3.52.16-7.13h6.62a4.31 4.31 0 004.3-4.31V4.3a4.31 4.31 0 00-4.3-4.3H25.41a4.31 4.31 0 00-4.31 4.3v10.8a4.31 4.31 0 004.31 4.31H32q0 3.6.16 7.13h-26a6.19 6.19 0 00-5.91 7.94c.54 1.82 13.07 43 48.22 56.08 5.57 9.6 12.27 16.63 19.65 20.2l-4 33h-8.86a2.76 2.76 0 00-2.76 2.77v8.15H47a3.19 3.19 0 00-3.19 3.19v11.08a3.19 3.19 0 003.19 3.17h64.57a3.19 3.19 0 003.19-3.19v-11.08a3.19 3.19 0 00-3.19-3.19H106v-8.15a2.77 2.77 0 00-2.77-2.77h-8.9l-4-33c7.38-3.57 14.08-10.6 19.65-20.2 35.15-13.13 47.68-54.26 48.22-56.08a6.2 6.2 0 00-.96-5.46zm-142 9.9h17.9a155.56 155.56 0 007.12 33.13c-13.18-9.68-21.13-24.19-25.07-33.11zm103.01 33.18a155.09 155.09 0 007.13-33.16h17.92c-3.92 8.93-11.86 23.45-25.05 33.16z"
                    fill={color}
                />
            </g>
        </svg>
    )
}

const PlatinumTrophy = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1rem" viewBox="0 0 118.69 200">
            <g>
                <path
                    d="M91.21 180.28h-5.35v-9.2A3 3 0 0083.1 168h-.3l-6.51-24.17H77v-4.36h-.5l42.19-81.85-.55-5.32-12.36-6.87v-1.22-.63c0-.53-.07-1-.11-1.57l-.06-.62c-.15-1.4-.36-2.78-.64-4.14l-.12-.6c-.11-.5-.22-1-.35-1.5 0-.19-.09-.39-.15-.59a46.83 46.83 0 00-1.21-3.92l-.21-.56c-.18-.47-.37-.94-.56-1.4-.07-.19-.15-.37-.23-.56-.53-1.24-1.12-2.46-1.75-3.64l-.29-.52c-.24-.44-.49-.87-.75-1.3l-.3-.51a37.4 37.4 0 00-1.09-1.69A46.31 46.31 0 0013.2 44.92L0 52.26l.1 5.32 42.36 81.85h-.94v4.36h1c-1.52 5.45-5.69 19.56-7.04 24.21h-.33a3 3 0 00-2.77 3.12v9.2H27a3.39 3.39 0 00-3.39 3.39v12.94A3.39 3.39 0 0027 200h64.21a3.39 3.39 0 003.39-3.39v-12.94a3.39 3.39 0 00-3.39-3.39z"
                    fill="#a3cacc"
                />
            </g>
        </svg>
    )
}

const trophies = [
    { minRank: 3, icon: <PlatinumTrophy /> },
    { minRank: 10, icon: <Trophy color="#f6ce17" /> },
    { minRank: 50, icon: <Trophy color="#c1c1c1" /> },
    { minRank: 100, icon: <Trophy color="#d87856" /> },
]

const getTrophyIcon = (rank: number) => trophies.find(trophy => rank <= trophy.minRank)?.icon

interface HighScoreGameLeaderboardProps {
    projectId: string
    room?: string
}

export const HighScoreGameLeaderboard: React.FC<HighScoreGameLeaderboardProps> = ({ projectId, room }) => {
    const game = highScoreGames.find(game => game.projectId === projectId)
    const { leaderboards, forceRefetch } = useHighScoreLeaderboard(projectId, room, game, true)

    if (!leaderboards) {
        return (
            <Flex justify="center">
                <Loader />
            </Flex>
        )
    }

    if (!game || leaderboards.length === 0) {
        return null
    }

    return (
        <HighscoreGameLeaderBoardInner
            game={game}
            room={room}
            leaderboards={leaderboards}
            forceRefetch={forceRefetch}
        />
    )
}

interface HighscoreGameLeaderBoardInnerProps {
    leaderboards: NonNullable<ReturnType<typeof useHighScoreLeaderboard>['leaderboards']>
    forceRefetch: VoidFunction
    game: HighScoreGame
    room?: string
}

function getLeaderboard(
    name: string,
    results: FetchedLeaderboard,
    myResult: LeaderboardRank,
    account: ApiTypes['account'],
    t: TypedTFunction,
    displayTrophies: boolean,
) {
    if (!results || results.items.length === 0) {
        return <EmptyState key={name}>{t('challenges::highScoreEmptyState')}</EmptyState>
    }

    const myTrophy = myResult.rank ? getTrophyIcon(myResult.rank) : undefined
    const isMyResultIncluedInResults = results.items.find(item => item.owner.id === account.id)

    return (
        <Leaderboard key={name}>
            {results.items.map((item, i) => {
                const isOwn = item.owner.id === account.id
                const avatarSrc = createAvatarUrl(isOwn ? account.profileImage : item.owner.profileImage)
                const rank = i + 1

                const trophy = getTrophyIcon(rank)

                return (
                    <LeaderboardItem
                        highlighted={isOwn}
                        place={rank}
                        score={item.value}
                        icon={displayTrophies ? trophy : undefined}
                        key={item.owner.id}
                        to={`/app/user/${item.owner.username}`}
                        avatarSrc={avatarSrc}
                        label={
                            <>
                                {isOwn ? getUserPrivateName(account) : getUserPublicName(item.owner)}
                                <StyledUserBadge user={item.owner} />
                            </>
                        }
                    />
                )
            })}

            {myResult.rank && !isMyResultIncluedInResults && (
                <LeaderboardItem
                    highlighted
                    place={myResult.rank}
                    icon={displayTrophies ? myTrophy : undefined}
                    score={myResult.value}
                    to={`/app/user/${account.username}`}
                    label={getUserPrivateName(account)}
                    avatarSrc={createAvatarUrl(account.profileImage)}
                />
            )}
        </Leaderboard>
    )
}

const HighscoreGameLeaderBoardInner: React.FC<HighscoreGameLeaderBoardInnerProps> = ({
    leaderboards,
    game,
    forceRefetch,
    room,
}) => {
    const [tab, setTab] = useState<null | string>(leaderboards[0].name)
    const [subTab, setSubTab] = useState<null | string>('weekly')
    const { account } = useUserState()
    const t = useTranslate()
    const displayCount = useHasShowAdvancedStats()

    const makeSubleaderboardLabel = (key: LocalisationKey, count: number) =>
        displayCount && count !== undefined ? `${t(key)} (${count})` : t(key)

    return (
        <Stack>
            {leaderboards.length > 1 && (
                <Tabs
                    value={tab}
                    // TODO: types
                    onChange={(_: any, value: any) => {
                        setTab(value)
                    }}
                >
                    {leaderboards.map(({ name }) => {
                        const stat = game.stats?.find(stat => stat.name === name)
                        return <Tab value={name} key={name} label={stat ? t(stat.i18nKey) : name} />
                    })}
                </Tabs>
            )}
            {leaderboards.map(leaderboard => {
                if (leaderboard.name !== tab) {
                    return null
                }

                if (room) {
                    if (!leaderboard.allTimeResults || !leaderboard.myAllTimeResult) {
                        return (
                            <Flex key={leaderboard.name} justify="center">
                                <Loader />
                            </Flex>
                        )
                    }

                    return getLeaderboard(
                        leaderboard.name,
                        leaderboard.allTimeResults,
                        leaderboard.myAllTimeResult,
                        account,
                        t,
                        false,
                    )
                } else {
                    if (!leaderboard.results || !leaderboard.myResult) {
                        return (
                            <Flex key={leaderboard.name} justify="center">
                                <Loader />
                            </Flex>
                        )
                    }

                    if (leaderboard.allTimeResults && leaderboard.myAllTimeResult) {
                        const subLeaderboards = [
                            {
                                name: 'weekly',
                                label: makeSubleaderboardLabel('general::currentWeek', leaderboard.results.count),
                                results: leaderboard.results,
                                myResult: leaderboard.myResult,
                            },
                        ]

                        if (leaderboard.prevResults && leaderboard.myPrevResult) {
                            subLeaderboards.push({
                                name: 'prev',
                                label: makeSubleaderboardLabel('general::lastWeek', leaderboard.prevResults.count),
                                results: leaderboard.prevResults,
                                myResult: leaderboard.myPrevResult,
                            })
                        }

                        subLeaderboards.push({
                            name: 'all-time',
                            label: makeSubleaderboardLabel('general::allTime', leaderboard.allTimeResults.count),
                            results: leaderboard.allTimeResults,
                            myResult: leaderboard.myAllTimeResult,
                        })

                        return (
                            <Stack key={leaderboard.name}>
                                <Select
                                    value={subTab}
                                    onChange={e => {
                                        setSubTab(e.target.value as string)
                                    }}
                                >
                                    {subLeaderboards.map(({ name, label }) => (
                                        <Option value={name} key={name}>
                                            {label}
                                        </Option>
                                    ))}
                                </Select>

                                {leaderboard.nextIncrement && (
                                    <HighScoreGameLeaderboardCountdown
                                        hide={subTab !== 'weekly'}
                                        date={leaderboard.nextIncrement}
                                        forceRefetch={forceRefetch}
                                    />
                                )}

                                {subLeaderboards.map(subLeaderboard => {
                                    if (subLeaderboard.name !== subTab) {
                                        return null
                                    }

                                    if (!subLeaderboard.results || !subLeaderboard.myResult) {
                                        return (
                                            <Flex key={subLeaderboard.name} justify="center">
                                                <Loader />
                                            </Flex>
                                        )
                                    }

                                    return getLeaderboard(
                                        subLeaderboard.name,
                                        subLeaderboard.results,
                                        subLeaderboard.myResult,
                                        account,
                                        t,
                                        subLeaderboard.name !== 'all-time',
                                    )
                                })}
                            </Stack>
                        )
                    } else {
                        return getLeaderboard(
                            leaderboard.name,
                            leaderboard.results,
                            leaderboard.myResult,
                            account,
                            t,
                            false,
                        )
                    }
                }
            })}
        </Stack>
    )
}

const HighScoreGameLeaderboardCountdown: React.FC<{ date: string; forceRefetch: VoidFunction; hide?: boolean }> = ({
    date,
    forceRefetch,
    hide,
}) => {
    const t = useTranslate()
    const dayjsDate = useMemo(() => dayjs(date), [date])
    const { remaining, isEnd } = useCountdown(dayjsDate)

    useEffect(() => {
        if (isEnd) {
            const timeout = setTimeout(() => {
                forceRefetch()
            }, 5000)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [isEnd, forceRefetch])

    if (hide) {
        return null
    }

    return (
        <div>
            <Text>{t('challenges::weeklyScoreboardCloses' as LocalisationKey)} </Text>
            <Text>
                <strong>
                    {t('general::nDay', { count: remaining.days })}, {t('general::nHour', { count: remaining.hours })},{' '}
                    {} {t('general::nMinute', { count: remaining.minutes })}
                </strong>
            </Text>
        </div>
    )
}

const StyledUserBadge = styled(UserBadge)`
    font-size: ${props => props.theme.typography.body1.fontSize};
    margin-inline-start: ${props => props.theme.spacing(2)};
`

const leaderboardCountByProject: Record<string, number> = {}
export function useHighScoreLeaderboard(projectId: string, room?: string, game?: HighScoreGame, joinRoom?: boolean) {
    const scoreList = useSelector(state => getScoreList(state, projectId))
    const leaderboards = useSelector(state => state.shared.stats.leaderboard)
    const ranks = useSelector(state => state.shared.stats.ranks)
    const nextIncrements = useSelector(state => state.shared.stats.nextIncrements)

    const ready = useSelector(isLiveopsReady)
    const dispatch = useDispatch()

    useEffect(() => {
        if (ready && scoreList === null) {
            dispatch(requestScoreList(projectId))
        }
    }, [dispatch, projectId, ready, scoreList])

    useEffect(() => {
        if (scoreList) {
            if (projectId in leaderboardCountByProject) {
                leaderboardCountByProject[projectId]++
            } else {
                leaderboardCountByProject[projectId] = 1
            }
            scoreList.forEach(score => dispatch(enableProjectLeaderboard(projectId, score, room)))
            return () => {
                if (projectId in leaderboardCountByProject) {
                    leaderboardCountByProject[projectId]--
                }
                setTimeout(() => {
                    if (leaderboardCountByProject[projectId] <= 0) {
                        dispatch(disableAllLeaderboardsForProject(projectId))
                    }
                }, 200)
            }
        }
    }, [scoreList, dispatch, projectId, room])

    useEffect(() => {
        if (joinRoom && ready && room) {
            dispatch(joinHighScoreRoom(room))
            return () => {
                dispatch(leaveHighScoreRoom(room))
            }
        }
    }, [room, dispatch, ready, joinRoom])

    const forceRefetch = React.useCallback(() => {
        if (scoreList) {
            dispatch(disableAllLeaderboardsForProject(projectId))
            scoreList.forEach(score => dispatch(enableProjectLeaderboard(projectId, score, room)))
        }
    }, [scoreList, dispatch, projectId, room])

    let scoreListCopy = scoreList ? [...scoreList] : undefined

    if (game) {
        scoreListCopy = scoreListCopy
            ?.filter(score => {
                if (!game.hiddenStats) {
                    return true
                }
                return game.hiddenStats.includes(score) ? false : true
            })
            ?.sort((a, b) => {
                if (game.stats === undefined) {
                    return 0
                }
                const aStat = game.stats.findIndex(stat => stat.name === a)
                const bStat = game.stats.findIndex(stat => stat.name === b)
                if (aStat === -1 || bStat === -1) {
                    return 0
                }
                return aStat > bStat ? 1 : -1
            })
    }

    const roomStatPrefix = room ? `@ROOM/${room}:` : ''
    return {
        forceRefetch,
        leaderboards: scoreListCopy?.map(score => {
            return {
                name: score,
                results: leaderboards[`${roomStatPrefix}@PROJECT/${projectId}:score:${score}:latest`],
                prevResults: leaderboards[`${roomStatPrefix}@PROJECT/${projectId}:score:${score}:prev`],
                allTimeResults: leaderboards[`${roomStatPrefix}@PROJECT/${projectId}:score:${score}:all-time`],
                myResult: ranks[`${roomStatPrefix}@PROJECT/${projectId}:score:${score}:latest`],
                myPrevResult: ranks[`${roomStatPrefix}@PROJECT/${projectId}:score:${score}:prev`],
                myAllTimeResult: ranks[`${roomStatPrefix}@PROJECT/${projectId}:score:${score}:all-time`],
                nextIncrement: nextIncrements[`${roomStatPrefix}@PROJECT/${projectId}:score:${score}`],
            }
        }),
    }
}

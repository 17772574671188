import ShareProjectPage from 'pages/share-project'
import HighScorePage from 'pages/high-score'
import HighScoreRoomResultsPage from 'pages/high-score-room-results'
import { ClassroomQuizIframe, SelfHostQuizIframe, GeneratedQuizIframe, HostQuizIframe } from 'features/quiz'
import { XmasProjectDetailsPage } from 'features/xmas-projects'
import React from 'react'
import { LimitedCommunityProjectDetailsPage } from 'pages/community-project-details'
import { AppVariantRecord } from 'lib/app-variant'
import { ProjectDetailsPage } from 'pages/project-details/ProjectDetailsPage'
import { OnlinefirstChallengeDialog } from 'features/online-first-challenge'
import { MiniQuizEditorModal } from 'features/mini-quizzes/editor/components/MiniQuizEditorModal'
import { LongformDialog } from 'features/feed/components/LongformDialog'
import { WallpapersDialog } from 'features/wallpapers/components/WallpapersDialog'

interface ModalRoute {
    path: string
    Component: React.FC | AppVariantRecord<React.FC>
}

export const modalRoutes: ModalRoute[] = [
    {
        path: '/app/project/:id',
        Component: {
            normal: ProjectDetailsPage,
            'open-day': LimitedCommunityProjectDetailsPage,
            guest: LimitedCommunityProjectDetailsPage,
        },
    },
    { path: '/app/high-score/:id', Component: HighScorePage },
    { path: '/app/high-score-room-results/:id/:roomId', Component: HighScoreRoomResultsPage },
    { path: '/app/share-project/:id', Component: ShareProjectPage },
    { path: '/app/quiz/:id', Component: SelfHostQuizIframe },
    { path: '/app/classroom-quiz/:id', Component: ClassroomQuizIframe },
    { path: '/app/quiz-mania/:id', Component: GeneratedQuizIframe },
    { path: '/app/host-quiz/:id', Component: HostQuizIframe },
    { path: '/app/xmas-game/:id', Component: XmasProjectDetailsPage },
    { path: '/app/coding-challenge/:setId/:id', Component: OnlinefirstChallengeDialog },
    { path: '/app/quiz-editor/:id', Component: MiniQuizEditorModal },
    { path: '/app/post/:id', Component: LongformDialog },
    { path: '/app/wallpapers', Component: WallpapersDialog },
]

import React, { createContext, useContext, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { cookieHuntSpawnPoints, generateRandomEnabledSpawnPoints } from '../data/cookieHuntData'
import { collectCookieTreasure, collectEasterEgg } from '../store/cookieHuntActions'
import { CookieHuntSpawnPoint, CookieHuntSpawnPointName } from '../types/cookieHuntTypes'

const actionByType: Record<CookieHuntSpawnPoint['type'], VoidFunction> = {
    cookie: collectCookieTreasure,
    'easter-egg': collectEasterEgg,
}

interface CookieHuntState {
    isSpawnPointVisible: (name: CookieHuntSpawnPointName) => boolean
    collect: (key: CookieHuntSpawnPointName) => void
}

const cookieHuntContext = createContext<CookieHuntState | undefined>(undefined)

const enabledSpawnPoints = generateRandomEnabledSpawnPoints()

console.log(enabledSpawnPoints)

export const CookieHuntProvider: React.FC = ({ children }) => {
    const [collected, setCollected] = useState<Array<CookieHuntSpawnPointName>>([])
    const dispatch = useDispatch()

    const isSpawnPointVisible = useCallback(
        (name: CookieHuntSpawnPointName) => {
            return enabledSpawnPoints.has(name) && !collected.includes(name)
        },
        [collected],
    )

    const collect = useCallback(
        (name: CookieHuntSpawnPointName) => {
            if (isSpawnPointVisible(name)) {
                const action = actionByType[cookieHuntSpawnPoints[name].type]
                dispatch(action())
                setCollected(places => [...places, name])
            }
        },
        [dispatch, isSpawnPointVisible],
    )

    return <cookieHuntContext.Provider value={{ isSpawnPointVisible, collect }}>{children}</cookieHuntContext.Provider>
}

export const useCookieHunt = () => {
    const context = useContext(cookieHuntContext)

    if (context === undefined) {
        throw new Error(`'useCookieHunt' must be used within 'CookieHuntProvider'`)
    }

    return context
}

import React, { useContext } from 'react'
import { Drawer, Theme, useMediaQuery, useTheme } from '@material-ui/core'
import { auth } from 'lib/auth'
import { Link, NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { getUserPrivateName, useUserState } from 'contexts/UserContext'
import { CurrentUserAvatar } from 'features/avatar'
import { Flex, Inline, Stack } from 'components/layout'
import {
    ProjectIcon,
    HomeIcon,
    LogOutIcon,
    SettingsIcon,
    TrophyIcon,
    UserIcon,
    CommunityIcon,
    EventsIcon,
    GamePadIcon,
    ClassroomIcon,
} from 'components/icons'
import LogiscoolLogo from 'media/images/logiscool-logo.png'
import LogiscoolLogoWhite from 'media/images/logiscool-logo-white.png'
import { List, ListItem, ListItemIcon } from 'components/list'
import { Text } from 'components/Text'
import { Box } from 'components/layout'
import { useTranslate } from 'lib/i18n/useTranslate'
import { NotificationButton } from 'features/notifications'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { FloatingBadge } from 'components/FloatingBadge'
import { useSelector } from 'lib/store'
import { getUnreadFeedEntryCount } from 'features/feed'
import { Divider } from 'components/Divider'
import { UserBadge } from 'features/profile'
import { customScrollbar } from 'utils/styleUtils'
import { CookieHunt } from 'features/cookie-hunt'
import { useAppVariantContent } from 'lib/app-variant'
import { runtimeEnv } from 'utils/env'
import { AvatarSize } from 'features/avatar/Avatar'
import { useEventsData } from 'features/events/useEventsData'

export const ASIDE_WIDTH = '16rem'

type PageAsideProps = {
    isMobileMenuOpen: boolean
    onCloseMobileMenu: () => void
    isMobile: boolean
}
type HeightVariant = 'normal' | 'small' | 'extra-small'
const asideContext = React.createContext<{ height: HeightVariant }>({ height: 'normal' })

export const PageAside: React.FC<PageAsideProps> = ({ isMobileMenuOpen, onCloseMobileMenu, isMobile }) => {
    const isSmallHeight = useMediaQuery<Theme>('@media (min-height: 601px) and (max-height: 700px)', { noSsr: true })
    const isExtraSmallHeight = useMediaQuery<Theme>('@media (max-height: 600px)', { noSsr: true })

    const height: HeightVariant = React.useMemo(() => {
        if (!isMobile) {
            if (isSmallHeight) {
                return 'small'
            }
            if (isExtraSmallHeight) {
                return 'extra-small'
            }
        }
        return 'normal'
    }, [isMobile, isExtraSmallHeight, isSmallHeight])

    const theme = useTheme()

    return (
        <StyledDrawer
            variant={isMobile ? 'temporary' : 'permanent'}
            open={isMobileMenuOpen}
            onClose={onCloseMobileMenu}
        >
            <asideContext.Provider value={{ height }}>
                <PageAsideHeader isMobile={isMobile} />
                <PageAsideMenu />
            </asideContext.Provider>
            <ErrorBoundary silent>{theme.extras?.pageAside?.extraComponent}</ErrorBoundary>
        </StyledDrawer>
    )
}

const avatarHeightMap: Record<HeightVariant, AvatarSize> = {
    normal: 'big',
    small: 'medium',
    'extra-small': 'small-plus',
}

const spacingHeightMap: Record<HeightVariant, number> = {
    normal: 5,
    small: 3,
    'extra-small': 2,
}

const marginHeightMap: Record<HeightVariant, number> = {
    normal: 4,
    small: 2,
    'extra-small': 1,
}

const PageAsideHeader: React.FC<{ isMobile: boolean }> = ({ isMobile }) => {
    const { account } = useUserState()
    const theme = useTheme()
    const { height } = useContext(asideContext)

    return (
        <Stack spacing={spacingHeightMap[height]} mb={marginHeightMap[height]}>
            <PageAsideLogoWrap>
                {!isMobile && (
                    <>
                        <Link to="/app">
                            <Logo
                                src={theme.extras?.logoType === 'white' ? LogiscoolLogoWhite : LogiscoolLogo}
                                alt="Logiscool"
                            />
                        </Link>
                        <ErrorBoundary silent>
                            <NotificationButtonWrap>
                                <NotificationButton />
                            </NotificationButtonWrap>
                        </ErrorBoundary>
                    </>
                )}
            </PageAsideLogoWrap>

            <Flex direction="column" align="center" pl={4} pr={4}>
                <CookieHunt name="aside-avatar">
                    <Link to={`/app/user/${account.username}`}>
                        <CurrentUserAvatar size={avatarHeightMap[height] ?? 'big'} />
                    </Link>
                </CookieHunt>
                <Box textAlign="center" mt={2}>
                    <StyledLink to={`/app/user/${account.username}`}>
                        <Username variant={height === 'extra-small' ? 'h4' : 'h3'} component="span">
                            <Inline spacing={2}>
                                <span>{getUserPrivateName(account)}</span>
                                <UserBadge user={account} />
                            </Inline>
                        </Username>
                    </StyledLink>
                </Box>
                {account.userNbr && (
                    <Box textAlign="center">
                        <UserNumber component="span">@{account.userNbr}</UserNumber>
                    </Box>
                )}
            </Flex>
        </Stack>
    )
}

const PageAsideMenu = () => {
    const t = useTranslate()
    const { appVariantContent } = useAppVariantContent()

    return (
        <>
            <StyledList style={{ flex: 1 }}>
                {appVariantContent({
                    normal: <PageAsideNewsMenuItem />,
                    guest: <PageAsideWelcomeMenuItem />,
                    'open-day': <PageAsideWelcomeMenuItem />,
                })}
                {appVariantContent({
                    normal: (
                        <>
                            <PageAsideMyProjectsMenuItem />
                            <PageAsideProfileMenuItem />
                            <PageAsideCommunityMenuItem />
                            <PageAsideChallengesMenuItem />
                            <PageAsideEventMenuItem />
                        </>
                    ),
                    'open-day': (
                        <>
                            <PageAsideClassroomMenuItem />
                            <PageAsideProfileMenuItem />
                            <PageAsideCommunityMenuItem />
                            <PageAsideChallengesMenuItem />
                            <PageAsideEventMenuItem />
                        </>
                    ),
                    guest: (
                        <>
                            <PageAsideChallengesMenuItem />
                            <PageAsideCommunityMenuItem />
                            {runtimeEnv.REACT_APP_ONBOARDING_ENABLED && <PageAsideEventMissionMenuItem />}
                        </>
                    ),
                })}
            </StyledList>
            <StyledDivider />
            <StyledList>
                <SecondaryButton icon={<SettingsIcon />} to="/app/settings">
                    {t('settings::label')}
                </SecondaryButton>
                <SecondaryButton icon={<LogOutIcon />} onClick={auth.logout}>
                    {t('general::logout')}
                </SecondaryButton>
            </StyledList>
        </>
    )
}

const PageAsideWelcomeMenuItem: React.FC = () => {
    const t = useTranslate()

    return (
        <PageAsideMenuItem icon={<HomeIcon />} to="/app/welcome">
            {t('welcome::title')}
        </PageAsideMenuItem>
    )
}

const PageAsideCommunityMenuItem: React.FC = () => {
    const t = useTranslate()

    return (
        <PageAsideMenuItem icon={<CommunityIcon />} to="/app/community">
            {t('community::title')}
        </PageAsideMenuItem>
    )
}

const PageAsideChallengesMenuItem: React.FC = () => {
    const t = useTranslate()

    return (
        <PageAsideMenuItem icon={<TrophyIcon />} to="/app/challenges">
            {t('challenges::title')}
        </PageAsideMenuItem>
    )
}

const PageAsideEventMissionMenuItem: React.FC = () => {
    const t = useTranslate()

    return (
        <PageAsideMenuItem icon={<GamePadIcon />} to="/app/events/mylogiscool-mission">
            {t('OnboardingMission::title')}
        </PageAsideMenuItem>
    )
}

const PageAsideProfileMenuItem: React.FC = () => {
    const t = useTranslate()
    const { account } = useUserState()

    return (
        <PageAsideMenuItem icon={<UserIcon />} to={`/app/user/${account.username}`}>
            {t('profile::myProfile')}
        </PageAsideMenuItem>
    )
}

const PageAsideMyProjectsMenuItem: React.FC = () => {
    const t = useTranslate()

    return (
        <PageAsideMenuItem icon={<ProjectIcon />} to="/app/my-projects">
            {t('projects::my')}
        </PageAsideMenuItem>
    )
}

const PageAsideClassroomMenuItem: React.FC = () => {
    const t = useTranslate()

    return (
        <PageAsideMenuItem icon={<ClassroomIcon />} to="/app/classroom">
            {t('classroom::title')}
        </PageAsideMenuItem>
    )
}

const PageAsideEventMenuItem: React.FC = () => {
    const t = useTranslate()
    const { hasEventsGrant } = useEventsData()

    if (!hasEventsGrant) {
        return null
    }

    return (
        <PageAsideMenuItem icon={<EventsIcon />} to="/app/events">
            <Box component="span" pr={3}>
                {t('events::title')}
            </Box>
        </PageAsideMenuItem>
    )
}

const PageAsideNewsMenuItem: React.FC = () => {
    const t = useTranslate()
    const unreadCount = useSelector(getUnreadFeedEntryCount)

    return (
        <PageAsideMenuItem icon={<HomeIcon />} to="/app/welcome">
            <FloatingBadge active={unreadCount > 0} title={`${unreadCount}`}>
                <Box component="span" pr={3}>
                    {t('news::title')}
                </Box>
            </FloatingBadge>
        </PageAsideMenuItem>
    )
}

interface PageAsideMenuItemProps {
    icon: React.ReactElement
    to?: string
    onClick?: () => void
    className?: string
}

const PageAsideMenuItem: React.FC<PageAsideMenuItemProps> = ({ children, to, onClick, icon, className }) => {
    const { height } = useContext(asideContext)

    const body = (
        <>
            <StyledListItemIcon $height={height}>{icon}</StyledListItemIcon>
            {children}
        </>
    )

    if (to) {
        return (
            <StyledListItem
                $height={height}
                className={className}
                component={NavLink}
                activeClassName="Mui-selected"
                to={to}
                onClick={onClick}
            >
                {body}
            </StyledListItem>
        )
    }
    return (
        <StyledListItem $height={height} className={className} onClick={onClick}>
            {body}
        </StyledListItem>
    )
}

const NotificationButtonWrap = styled.div`
    position: relative;
    padding-left: ${props => props.theme.spacing(4)};
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 1px;
        height: 35px;
        background-color: ${props => props.theme.palette.divider};
        ${props => props.theme.extras?.pageAside?.dividers}
    }
`

const PageAsideLogoWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.spacing(2, 4, 0, 6)};
`

const Logo = styled.img`
    display: block;
    max-width: 6.7rem;
`

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
`

const Username = styled(Text)`
    word-break: break-word;
    font-weight: 800;
` as typeof Text

const UserNumber = styled(Text)`
    word-break: break-all;
    opacity: 0.7;
    font-weight: 600;
    ${props => props.theme.extras?.pageAside?.secondary}
` as typeof Text

const StyledListItem = styled(ListItem)<{ $height: HeightVariant }>`
    word-break: break-word;
    /* TODO: types */
    font-size: ${props => {
        if (props.$height === 'small') {
            return '0.9rem'
        }
        if (props.$height === 'extra-small') {
            return '0.8rem'
        }
    }};
    padding-top: ${props => {
        if (props.$height === 'small') {
            return '0.6rem'
        }
        if (props.$height === 'extra-small') {
            return '0.5rem'
        }
    }};
    padding-bottom: ${props => {
        if (props.$height === 'small') {
            return '0.6rem'
        }
        if (props.$height === 'extra-small') {
            return '0.5rem'
        }
    }};
    ${(props: { theme: Theme }) => props.theme.extras?.pageAside?.listItemAndNotificationButton}
`

const SecondaryButton = styled(PageAsideMenuItem)`
    color: ${props => props.theme.palette.text.secondary};
    ${props => props.theme.extras?.pageAside?.secondary}
`

const StyledList = styled(List)`
    padding: ${props => props.theme.spacing(0, 4)};
`

const StyledListItemIcon = styled(ListItemIcon)<{ $height: HeightVariant }>`
    color: inherit;
    svg {
        font-size: ${props => (props.$height === 'extra-small' ? '1.1rem' : undefined)};
    }
`

const StyledDrawer = styled(Drawer)`
    width: ${ASIDE_WIDTH};
    & .MuiPaper-root {
        width: ${ASIDE_WIDTH};
        ${props => props.theme.extras?.pageAside?.paper};
        ${customScrollbar}
        &::-webkit-scrollbar-track {
            ${props => props.theme.extras?.pageAside?.paper};
        }
    }
`

const StyledDivider = styled(Divider)`
    ${props => props.theme.extras?.pageAside?.dividers}
`

import { useOnboardingManager } from 'features/onboarding'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { resetBtnStyle } from 'utils/styleUtils'
import { FeedEntryAction, FeedEntryActionType, FeedEntry } from '../types/feedTypes'
import { FeedEntryVimeoModal } from './FeedEntryVimeoModal'

interface FeedEntryMetaActionProps {
    entry: FeedEntry
}

export const FeedEntryMetaAction: React.FC<FeedEntryMetaActionProps> = ({ entry, children }) => {
    if (!entry.action) {
        return <>{children}</>
    }

    const ActionComponent = actionTypeToComponent[entry.action.actionType]

    if (!ActionComponent) {
        return <>{children}</>
    }

    return (
        <ActionComponent id={entry._id} action={entry.action}>
            {children}
        </ActionComponent>
    )
}

type ActionComponent = React.FC<{
    id: string
    action: FeedEntryAction
}>

const OpenOnboardingAction: ActionComponent = ({ action, children }) => {
    const { open } = useOnboardingManager()

    return (
        <StyledButton
            onClick={() => {
                open(action.to as any)
            }}
        >
            {children}
        </StyledButton>
    )
}

const VimeoAction: ActionComponent = ({ action, children }) => {
    const [isVimeoModelOpen, setIsVimeoModalOpen] = useState(false)

    const openVimeoModal = () => {
        setIsVimeoModalOpen(true)
    }

    const closeVimeoModal = () => {
        setIsVimeoModalOpen(false)
    }

    return (
        <>
            <StyledButton onClick={openVimeoModal}>{children}</StyledButton>
            <FeedEntryVimeoModal vimeoId={action.to!} isOpen={isVimeoModelOpen} onClose={closeVimeoModal} />
        </>
    )
}

const LinkAction: ActionComponent = ({ action, children }) => {
    return (
        <StyledAnchor as={Link} to={action.to ?? '#'}>
            {children}
        </StyledAnchor>
    )
}

const ExternalLinkAction: ActionComponent = ({ action, children }) => {
    return (
        <StyledAnchor href={action.to} target="_blank">
            {children}
        </StyledAnchor>
    )
}

const LongformAction: ActionComponent = ({ id, children }) => {
    return (
        <StyledAnchor as={Link} to={{ pathname: `/app/post/${id}` }}>
            {children}
        </StyledAnchor>
    )
}

const actionTypeToComponent: Record<FeedEntryActionType, ActionComponent> = {
    link: LinkAction,
    'external-link': ExternalLinkAction,
    'open-onboarding': OpenOnboardingAction,
    vimeo: VimeoAction,
    longform: LongformAction,
}

const StyledButton = styled.button`
    ${resetBtnStyle};
    display: block;
    width: 100%;
    cursor: pointer;
`

const StyledAnchor = styled.a`
    color: inherit;
    text-decoration: none;
`

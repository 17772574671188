import { ApiTypes } from 'lib/api'
import { ActionType } from 'typesafe-actions'
import * as projectActions from './projectActions'

export const GIVE_COOKIE_REQUEST = 'CLIENT/UNIVERSE/GIVE_COOKIE_REQUEST'
export const FAVORITE_PROJECT = 'CLIENT/UNIVERSE/LIKE_PROJECT'
export const UNFAVORITE_PROJECT = 'CLIENT/UNIVERSE/UNLIKE_PROJECT'

export const FETCH_SUCCESS = 'PROJECTS/FETCH_SUCCESS'
export const UPDATE_FILE = 'PROJECTS/UPDATE_FILE'
export const UPDATE_METADATA = 'PROJECTS/UPDATE_METADATA'
export const RENAME_PROJECT = 'PROJECTS/RENAME'
export const FAVORITE_OWN_PROJECT = 'PROJECTS/FAVORITE_OWN_PROJECT'
export const UNFAVORITE_OWN_PROJECT = 'PROJECTS/UNFAVORITE_OWN_PROJECT'
export const DELETE_PROJECT = 'PROJECTS/DELETE_PROJECT'
export const RESTORE_PROJECT = 'PROJECTS/RESTORE_PROJECT'
export const UNSHARE_PROJECT = 'PROJECTS/UNSHARE_PROJECT'
export const SHARE_PROJECT_WITHOUT_APPROVE = 'PROJECTS/SHARE_PROJECT_WITHOUT_APPROVE'
export const UPDATE_DETAILS = 'PROJECTS/UPDATE_DETAILS'
export const UPDATE_HAS_USER_THUMBNAIL = 'PROJECTS/UPDATE_HAS_USER_THUMBNAIL'

export interface Project {
    file: Pick<
        ApiTypes['file'],
        | 'id'
        | 'cookieCount'
        | 'goldenCookieCount'
        | 'title'
        | 'owner'
        | 'name'
        | 'shared'
        | 'lang'
        | 'createdAt'
        | 'updatedAt'
        | 'kind'
        | 'metadata'
        | 'favorite'
        | 'deleted'
        | 'deletedAt'
        | 'approvedAt'
        | 'viewedCount'
        | 'likedCount'
        | 'tags'
        | 'shareSpecial'
        | 'daysViewedCount_1'
        | 'daysViewedCount_7'
        | 'daysViewedCount_30'
        | 'hasUserThumbnail'
        | 'publicViewedCount'
        | 'playedCount'
        | 'labels'
    >
    metadata: {
        cookied?: boolean
        goldenCookied?: boolean
        owner?: Pick<ApiTypes['account'], 'id' | 'username' | 'alias' | 'profileImage' | 'fullName' | 'primaryKind'>
        details?: {
            normal?: string
            staging?: string
            production?: string
        }
        shareable: boolean
        favorited?: boolean
        isMiniQuiz: boolean
        seen?: boolean
        numberOfQuestions?: {
            normal?: number
            staging?: number
            production?: number
        }
    }
}

export interface ProjectReducerState {
    all: Record<string, Project>
}

export type ProjectAction = ActionType<typeof projectActions>
export type ProjectLabel = NonNullable<Project['file']['labels']>[number]

import React from 'react'
import styled from 'styled-components/macro'

interface FloatingBadgeProps {
    title: string | number
    active?: boolean
    className?: string
}

export const FloatingBadge: React.FC<FloatingBadgeProps> = ({ children, title, className, active = true }) => {
    return (
        <Root className={className}>
            {active && <Badge>{title}</Badge>}
            {children}
        </Root>
    )
}

const Root = styled.div`
    position: relative;
`

const Badge = styled.div`
    position: absolute;
    top: 0;
    right: -3px;
    z-index: 1;
    font-size: 0.75rem;
    background-color: #f4d003;
    color: #2d3057;
    border-radius: 5px;
    transform: translateY(-55%) rotate(8deg);
    padding: 3px 6px;
    font-weight: 800;
`

import { EmptyState } from 'components/EmptyState'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loader } from 'components/Loader'
import { PublicProjectCard } from 'features/projects'
import { ApiTypes } from 'lib/api'
import { useSelector } from 'lib/store'
import React from 'react'
import styled from 'styled-components/macro'
import { useSharedProjectsQuery } from 'features/projects'
import { canUserCreateShareableProject, useUserState } from 'contexts/UserContext'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Stack } from 'components/layout'
import { GhostIcon, ProjectIcon, WizardHatIcon } from 'components/icons'
import { opacify, transparentize } from 'polished'
import { useMiniQuizAccessLevel } from 'features/mini-quizzes/helpers/miniQuizHelpers'
import { PublicMiniQuizCard } from 'features/mini-quizzes'
import { Panel, PanelActions, PanelHeader, PanelMoreButton, PanelTitle } from 'components/panel'

interface SharedProjectListProps extends PoorMansSuspenseTicker {
    user: ApiTypes['account']
    isOwn?: boolean
}

export const SharedProjectList: React.FC<SharedProjectListProps> = props => {
    const { user, onReady, isOwn } = props
    const allProjects = useSelector(state => state.projects.all)
    const { data: results, status } = useSharedProjectsQuery(user.id)
    const t = useTranslate()

    usePoorMansSuspenseTick(status !== 'loading', onReady)
    if (status === 'error') {
        return <ErrorMessage />
    }

    if (!results) {
        return <Loader />
    }

    const projects = results.data.map(({ id }) => allProjects[id]).filter(project => !!project)

    const normalProjects = projects.filter(project => !project.metadata.isMiniQuiz)
    const miniQuizzes = projects.filter(project => project.metadata.isMiniQuiz)

    if (isOwn && projects.length === 0) {
        return <SharedProjectListEmptyState />
    }

    return (
        <>
            {normalProjects.length > 0 && (
                <Panel>
                    <PanelHeader>
                        <PanelTitle>{t('projects::sharedProjects')}</PanelTitle>
                        {isOwn && (
                            <PanelActions>
                                <PanelMoreButton to="/app/community/share-project">
                                    {t('projects::manageSharedProjects')}
                                </PanelMoreButton>
                            </PanelActions>
                        )}
                    </PanelHeader>
                    <Grid>
                        {normalProjects.map(project => (
                            <PublicProjectCard key={project.file.id} project={project} user={user} />
                        ))}
                    </Grid>
                </Panel>
            )}
            {miniQuizzes.length > 0 && (
                <Panel>
                    <PanelHeader>
                        <PanelTitle>{t('miniQuiz::sharedQuizzes')}</PanelTitle>
                        {isOwn && (
                            <PanelActions>
                                <PanelMoreButton to="/app/community/share-project#quizzes">
                                    {t('miniQuiz::manageSharedQuizzes')}
                                </PanelMoreButton>
                            </PanelActions>
                        )}
                    </PanelHeader>
                    <Stack>
                        {miniQuizzes.map(project => (
                            <PublicMiniQuizCard key={project.file.id} project={project} user={user} />
                        ))}
                    </Stack>
                </Panel>
            )}
        </>
    )
}

type EmptyStateVariant = 'project' | 'mini-quiz'

const SharedProjectListEmptyState: React.FC = () => {
    const t = useTranslate()
    const user = useUserState()
    const canShareProject = canUserCreateShareableProject(user)
    const canShareMiniQuiz = useMiniQuizAccessLevel() === 'full'

    if (!canShareProject && !canShareMiniQuiz) {
        return null
    }

    const variant: EmptyStateVariant = canShareProject ? 'project' : 'mini-quiz'

    const titles: Record<EmptyStateVariant, string> = {
        project: 'projects::sharedProjects',
        'mini-quiz': 'miniQuiz::projects',
    }
    const buttonLabels: Record<EmptyStateVariant, string> = {
        project: 'projects::shareProject',
        'mini-quiz': 'miniQuiz::shareMiniQuiz',
    }
    const descriptions: Record<EmptyStateVariant, string> = {
        project: 'projects::shareEmptyState',
        'mini-quiz': 'projects::shareEmptyStateQuiz',
    }
    const gridComponents: Record<EmptyStateVariant, React.FC> = {
        project: Grid,
        'mini-quiz': Stack,
    }
    const GridComponent = gridComponents[variant]

    return (
        <Panel>
            <PanelHeader>
                <PanelTitle>{t(titles[variant])}</PanelTitle>
                <PanelActions>
                    <PanelMoreButton to="/app/community/share-project">{t(buttonLabels[variant])}</PanelMoreButton>
                </PanelActions>
            </PanelHeader>
            <Stack>
                <EmptyState>{t(descriptions[variant])}</EmptyState>
                <GridComponent>
                    <EmptyStateItem $variant={variant}>
                        <ProjectIcon />
                    </EmptyStateItem>
                    <EmptyStateItem $variant={variant}>
                        <WizardHatIcon />
                    </EmptyStateItem>
                    <EmptyStateItem $variant={variant}>
                        <GhostIcon />
                    </EmptyStateItem>
                </GridComponent>
            </Stack>
        </Panel>
    )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
    gap: ${props => props.theme.spacing(6)};
`

const EmptyStateItem = styled.div<{ $variant: EmptyStateVariant }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${props => (props.$variant === 'project' ? '4rem' : '2rem')};
    height: ${props => (props.$variant === 'project' ? '12.5rem' : '4.5rem')};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    border: solid 2px ${props => transparentize(0.96, opacify(1, props.theme.palette.text.primary))};
    background-color: ${props => transparentize(0.96, opacify(1, props.theme.palette.text.primary))};
    color: ${props => transparentize(0.88, opacify(1, props.theme.palette.text.primary))};
`

import { Text } from 'components/Text'
import { EventDetailsContent } from 'features/events/components/details/EventDetailsContent'
import { EventDetailsBase } from 'features/events/components/EventDetailsBase'
import { EventDefinition } from 'features/events/types/eventTypes'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useEffect, useRef } from 'react'
import { userWonTheSweepstake } from './components/DigitalMission2021Progress'
import { DigitalMission2021Prizes } from './components/DigitalMission2021Prizes'
import { DigitalMission2021Results } from './components/DigitalMission2021Results'
import { useSelector } from 'lib/store'
import { isInventoryReady } from 'lib/liveops'
import { Loader } from 'components/Loader'
import { getDigitalMission2021SeaonsPoints } from './helpers/digitalMission2021Helpers'
import { useLocation } from 'react-router-dom'
import RootRef from '@material-ui/core/RootRef'
import { EventDetailsSubtitle } from 'features/events/components/details/EventDetailsSubtitle'
import { useUserState } from 'contexts/UserContext'
import { GetCustomCertButton } from 'features/certificates/GetCustomCertButton'
import { digitalMissionPhases } from './data/digitalMission2021Data'
import { getDigitalMission2021Cert } from './helper'
import { Flex } from 'components/layout'

interface DigitalMission2021PageProps {
    event: EventDefinition
}

export const DigitalMission2021Page: React.FC<DigitalMission2021PageProps> = ({ event }) => {
    const t = useTranslate()
    const isLoading = !useSelector(
        state => isInventoryReady(state) && getDigitalMission2021SeaonsPoints(state) !== undefined,
    )
    const resultsRef = useRef<HTMLDivElement>(null)
    const { state } = useLocation<{ fromBanner: boolean | undefined }>()
    const points = useSelector(getDigitalMission2021SeaonsPoints)
    const cert = useSelector(getDigitalMission2021Cert)

    useEffect(() => {
        if (!isLoading && state?.fromBanner && resultsRef.current) {
            resultsRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [isLoading, state?.fromBanner])
    const user = useUserState()

    const isWinner = useSelector(state => !!userWonTheSweepstake(state, digitalMissionPhases[3].winnerCatalogItemID))

    return (
        <>
            <EventDetailsBase event={event}>
                <EventDetailsContent>
                    {isLoading || points === undefined ? (
                        <Loader />
                    ) : (
                        <>
                            <Text>{t('digitalMission2021::posteventdescription')}</Text>
                            <RootRef rootRef={resultsRef}>
                                <DigitalMission2021Prizes />
                            </RootRef>
                            {points > 100 && (
                                <>
                                    <EventDetailsSubtitle>{t('general::yourResults')}</EventDetailsSubtitle>
                                    {points < 3500 && (
                                        <Text>
                                            {t('digitalMission2021::progressbar::message15', { count: points })}
                                        </Text>
                                    )}
                                    {points >= 3500 && points < 4000 && (
                                        <Text>{t('digitalMission2021::resultsDescription01', { count: points })}</Text>
                                    )}
                                    {points >= 4000 && !isWinner && (
                                        <Text>{t('digitalMission2021::resultsDescription02', { count: points })}</Text>
                                    )}
                                    {points >= 4000 && isWinner && (
                                        <Text>{t('digitalMission2021::resultsDescription03', { count: points })}</Text>
                                    )}
                                    {points >= 3500 && cert && (
                                        <Flex justifyContent="center">
                                            <GetCustomCertButton courseItem={cert}>
                                                {t('digitalMission2021::title')} {t('certificate::simpletitle')}
                                            </GetCustomCertButton>
                                        </Flex>
                                    )}
                                    <DigitalMission2021Results />
                                </>
                            )}
                        </>
                    )}
                    {user.i18n.country === 'HU' && (
                        <a
                            href="https://www.logiscool.com/hu/docs/digitalmission"
                            target="_blank"
                            style={{ display: 'block', textAlign: 'center' }}
                            rel="noreferrer"
                        >
                            Játékszabályzat és részvételi feltételek
                        </a>
                    )}
                </EventDetailsContent>
            </EventDetailsBase>
        </>
    )
}

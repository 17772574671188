import { Button } from 'components/Button'
import { CardContent } from 'components/card'
import { CheckIcon, LockIcon } from 'components/icons'
import { Stack } from 'components/layout'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { AskForOpenDayButton } from 'features/limited-accounts'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useSelector } from 'lib/store'
import { transparentize, mix, darken, grayscale } from 'polished'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { OnboardingAchievementData } from '../data/onboardingData'
import { isAchievementAcquired } from '../helpers/onboardingHelpers'

interface OnboardingAchievementDetailsProps {
    achievement: OnboardingAchievementData
}

export const OnboardingAchievementDetails: React.FC<OnboardingAchievementDetailsProps> = ({ achievement }) => {
    const t = useTranslate()
    const { title, description, progress, linkTo, imageUrl, color, isLocked } = achievement
    const isAcquired = useSelector(state => isAchievementAcquired(achievement, state.client.inventory))
    const currentProgress = useSelector(state => (progress ? state.client.statistics[progress.statName] : undefined))
    const isReady = progress ? currentProgress !== undefined : true

    return (
        <Root $color={color} $isLocked={isLocked}>
            {isReady ? (
                <Stack textAlign="center">
                    <Stack spacing={2}>
                        <ImgWrap $color={color} $isLocked={isLocked}>
                            <Img src={imageUrl} />
                        </ImgWrap>
                        <Text variant="h6">
                            {t(title)} {isLocked && <LockIcon />}
                            {isAcquired && (
                                <AcquiredBadge>
                                    <CheckIcon />
                                </AcquiredBadge>
                            )}
                        </Text>
                    </Stack>
                    {!isAcquired && progress && !isLocked && (
                        <Text>
                            {currentProgress}/{progress.required}
                        </Text>
                    )}
                    <Text>
                        {isLocked
                            ? t('onboardingMission::achievements::unlockText')
                            : t(description, { count: progress?.required })}
                    </Text>

                    {isLocked && <AskForOpenDayButton buttonProps={{ fullWidth: true }} />}

                    {!isLocked && linkTo && (
                        <Button fullWidth component={Link} to={linkTo}>
                            {t('onboardingMission::headToTheMission')}
                        </Button>
                    )}
                </Stack>
            ) : (
                <Loader />
            )}
        </Root>
    )
}

const Root = styled(CardContent)<{ $color: string; $isLocked: boolean }>`
    align-self: stretch;
    ${props => {
        if (props.theme.palette.type !== 'dark') {
            const backgroundColor = mix(0.75, props.theme.palette.background.paper, props.$color)

            return css`
                color: ${darken(0.75, props.$color)};
                background-color: ${props.$isLocked ? grayscale(backgroundColor) : backgroundColor};
                border-left: solid 1px ${transparentize(0.4, props.$color)};
            `
        }

        return css`
            border-left: solid 1px ${props.theme.palette.divider};
        `
    }}

    ${props => props.theme.breakpoints.down('xs')} {
        border-left: none;
    }
`

const AcquiredBadge = styled.span`
    width: 1em;
    height: 1em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => props.theme.palette.primary.main};
    border-radius: 50%;
    padding: ${props => props.theme.spacing(1)};
    svg {
        width: 100%;
        height: 100%;
    }
`

const ImgWrap = styled.div<{ $color: string; $isLocked: boolean }>`
    display: inline-flex;
    width: 6rem;
    height: 6rem;
    background: ${props => transparentize(0.5, props.$color)};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    padding: ${props => props.theme.spacing(2)};
    box-shadow: ${props => props.theme.shadows[1]};
    text-align: center;
    filter: ${props => (props.$isLocked ? 'grayscale(1)' : undefined)};
`

const Img = styled.img`
    max-width: 100%;
`

import { Loader } from 'components/Loader'
import React, { forwardRef, useEffect, useState } from 'react'
import { useProjectModalContext } from './projectModalContext'
import styled, { keyframes } from 'styled-components/macro'
import { Text } from 'components/Text'
import { Img } from 'components/Img'
import { Stack } from 'components/layout'
import notFoundImage from './not-found.png'
import { ProjectVariant } from '../projectHelpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import { PROJECT_MODAL_MOBILE_BREAKPOINT } from './ProjectModalBase'

interface ProjectModalEmbedProps {
    // null means that we don't know yet which variant to render so let's just show the loader
    projectVariant: ProjectVariant | null
    src: string
}

export const ProjectModalEmbed = forwardRef<HTMLIFrameElement, ProjectModalEmbedProps>(
    ({ projectVariant, src }, ref) => {
        const { project, error } = useProjectModalContext()

        // we need to suspense while translations are not ready
        // because suspense and measuring dom nodes dont really
        // work well together in legacy mode
        // https://github.com/facebook/react/issues/18141#issuecomment-591648972
        useTranslate()

        const [status, setStatus] = useState<'idle' | 'loading'>('loading')

        useEffect(() => {
            const onMessage = (event: MessageEvent<{ type?: string }>) => {
                if (event.origin.indexOf('.scoolcode.') !== -1 && event.data?.type === 'ready') {
                    setStatus('idle')
                }
            }

            window.addEventListener('message', onMessage)
            return () => {
                window.removeEventListener('message', onMessage)
            }
        }, [])

        if (error === 'NOT_FOUND') {
            return (
                <Root>
                    <Wrap>
                        <Stack textAlign="center" spacing={6}>
                            <ErrorImage src={notFoundImage} />
                            <ErrorText variant="h2">Not found.</ErrorText>
                        </Stack>
                    </Wrap>
                </Root>
            )
        }

        if (error === 'GENERAL') {
            return <Root></Root>
        }

        const iframeProps: React.DetailedHTMLProps<React.IframeHTMLAttributes<HTMLIFrameElement>, HTMLIFrameElement> = {
            style: { width: '100%', height: '100%', opacity: status === 'loading' ? 0 : 1 },
            allowFullScreen: true,
            allow: 'camera *; microphone *; geolocation *; fullscreen *; midi *; accelerometer *; gyroscope *; gamepad *;',
            title: project?.file.title || '',
            src,
            frameBorder: '0',
            ref: ref,
        }

        return (
            <Root>
                {(projectVariant === null || status === 'loading') && (
                    <Wrap>
                        <Loader />
                    </Wrap>
                )}
                {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                {projectVariant !== null && <iframe {...iframeProps} />}
            </Root>
        )
    },
)

const notFoundAnimation = keyframes`
    0% {
        transform: rotate(0)
    }
    50% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(10deg);
    }
`

const ErrorImage = styled(Img)`
    animation: ${notFoundAnimation} 5s ease-in-out infinite alternate;
`

const ErrorText = styled(Text)`
    color: #898db1;
`

const Root = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    ${props => props.theme.breakpoints.down(PROJECT_MODAL_MOBILE_BREAKPOINT)} {
        height: 40vh;
        @media (orientation: landscape) {
            height: 100vh;
            height: 100dvh;
        }
    }
`

const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
`

import { Button } from 'components/Button'
import { Dialog, DialogActions, DialogContent } from 'components/dialog'
import { Stack } from 'components/layout'
import { Paper } from 'components/Paper'
import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const Confetti = React.lazy(() => import('react-confetti'))

interface QueuedDialogProps {
    open: boolean
    onClose: VoidFunction
    image?: {
        url: string
        backgroundColor?: string
    }
    title?: string
    subtitle?: string
    description: string
    disableConfetti?: boolean
    button?: {
        label: string
        to: string
    }
}

export const QueuedDialog: React.FC<QueuedDialogProps> = ({
    open,
    onClose,
    image,
    button,
    title,
    subtitle,
    description,
    disableConfetti,
}) => {
    return (
        <Dialog
            fullWidth
            transitionDuration={300}
            open={open}
            onClose={onClose}
            PaperComponent={DialogPaper}
            BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.85)' } }}
        >
            {image && (
                <motion.div
                    animate={{ y: 0, opacity: 1, scale: 1 }}
                    initial={{ y: -25, opacity: 0, scale: 0.7 }}
                    transition={{ delay: 0.25 }}
                >
                    <ImageWrap
                        $disablePadding={!Boolean(image.backgroundColor)}
                        style={{ backgroundColor: image.backgroundColor }}
                    >
                        <img src={image.url} alt="" />
                    </ImageWrap>
                </motion.div>
            )}
            <StyledDialogContent>
                <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ delay: 0.2 }}>
                    <Inner spacing={4}>
                        <div>
                            <Text variant="h1">{title}</Text>
                            {subtitle && (
                                <Text>
                                    <strong>{subtitle}</strong>
                                </Text>
                            )}
                        </div>
                        <Text>{description}</Text>
                    </Inner>
                </motion.div>
            </StyledDialogContent>
            {button && (
                <DialogActions>
                    <ButtonWrap animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ delay: 0.2 }}>
                        <StyledButton onClick={onClose} component={Link} to={button.to}>
                            {button.label}
                        </StyledButton>
                    </ButtonWrap>
                </DialogActions>
            )}
            {!disableConfetti && (
                <ConfettiWrap>
                    <React.Suspense fallback={null}>
                        <Confetti
                            recycle={false}
                            numberOfPieces={150}
                            initialVelocityY={10}
                            colors={['#b10f57', '#c4d703', '#1f9bde']}
                        />
                    </React.Suspense>
                </ConfettiWrap>
            )}
        </Dialog>
    )
}

const ConfettiWrap = styled.div`
    position: fixed;
    top: 0;
    left: 0;
`

const ButtonWrap = styled(motion.div)`
    display: flex;
    width: 100%;
`

const StyledButton = styled(Button)`
    background: ${props => props.theme.palette.primary.contrastText};
    color: ${props => props.theme.palette.primary.main};
    margin-left: auto;
    margin-right: auto;
    &:hover,
    &:focus-visible {
        opacity: 0.9;
        background: ${props => props.theme.palette.primary.contrastText};
    }
    &:focus-visible {
        outline: solid 2px ${props => props.theme.palette.primary.contrastText};
    }
` as typeof Button

const StyledDialogContent = styled(DialogContent)`
    padding: ${props => props.theme.spacing(8, 6)};
`

const Inner = styled(Stack)`
    text-align: center;
    padding-top: 3rem;
`

const DialogPaper = styled(Paper)`
    overflow-y: visible;
    color: ${props => props.theme.palette.primary.contrastText};
    background: linear-gradient(
        ${props => props.theme.palette.primary.dark},
        ${props => props.theme.palette.primary.main}
    );
`

const ImageWrap = styled.div<{ $disablePadding: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin: 0 auto;
    top: 0;
    right: 0;
    left: 0;
    width: 6rem;
    height: 6rem;
    transform: translateY(-50%);
    border-radius: ${props => props.theme.shape.borderRadius}px;
    padding: ${props => (props.$disablePadding ? undefined : props.theme.spacing(2))};
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.5);
    overflow: hidden;

    > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

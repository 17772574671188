import { UserState } from 'contexts/UserContext'
import { api } from 'lib/api'
import { useStore } from 'react-redux'
import { loadEvents } from './store/eventsActions'
import { useCallback } from 'react'

export function useEvents() {
    const store = useStore()

    const initEvents = useCallback(
        async (user: UserState) => {
            try {
                const events = await api.events.getEventsForUser(user)
                store.dispatch(loadEvents(events))
            } catch (e) {
                console.error(e)
            }
        },
        [store],
    )

    return {
        initEvents,
    }
}

import { transparentize } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import footerImg from './space-footer.png'
import backgroundImg from './space-bg.jpg'
import { createTheme } from 'lib/theme/createTheme'

const FooterImage = styled.img`
    display: block;
    bottom: 0;
    max-width: 100%;
    height: auto;
`

const Thumbnail: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
            <defs>
                <clipPath id="prefix__a">
                    <path fill="none" d="M535 812.5h1385V1080H535z" />
                </clipPath>
            </defs>
            <path d="M535 0h1385v1080H535z" fill="#0E1533" />
            <path d="M1445 100.8h344.1v85.3H1445zm-296.5 0h250.9v85.3h-250.9z" fill="#2878f0" />
            <path
                d="M667.6 274.8h344.1v258.5H667.6zm388.7 0h344.1v258.5h-344.1zm388.7 0h344.1v258.5H1445zM667.6 608.3h344.1v258.5H667.6zm388.7 0h344.1v258.5h-344.1zm388.7 0h344.1v258.5H1445z"
                fill="#384a78"
            />
            <path d="M0 0h535v1080H0z" fill="#0d1528" />
            <path
                d="M149.5 89.8h228v213.9h-228zM84.7 424.5h304v43.3h-304v-43.3zm0 97H322v43.3H84.7zm0 97h268v43.3h-268v-43.3zm0 97H382v43.3H84.7zm0 97H342v43.3H84.7z"
                fill="#5c8aaf"
            />
            <g clipPath="url(#prefix__a)">
                <path
                    fill="#1f267f"
                    d="M407.91 963.99l221.32-27.45 319.7 68.63 286.96-34.31 72.52 12.11 132.85 22.2 214.42-58.48 261.46 60.34 9 74-1518.23-3.68V963.99z"
                />
                <path
                    fill="#4649ae"
                    d="M393.14 927.03l376 68.49 297-62.78 256 76.1 112-50.42 200 46.62 254-22.83 114-1.77v60.38l-1637 62.21 28-176z"
                />
                <path
                    fill="#76bbd7"
                    d="M412.26 1035.03l224-14 729 25 217.61-27.92 344.39-.08 9 89-1545.64-6 21.64-66z"
                />
            </g>
        </svg>
    )
}

export const space = createTheme(Thumbnail, {
    palette: {
        text: { primary: '#9ed0f5', secondary: '#5487ad', hint: 'rgba(158, 207, 245, 0.38)' },
        type: 'dark',
        primary: {
            main: '#2878f0',
        },
        divider: '#202d6b',
        background: {
            default: '#140732',
            paper: '#191d50',
        },
    },
    extras: {
        logoType: 'white',
        themeColorMeta: '#121e40',
        global: {
            body: css`
                background: linear-gradient(#0c0e27, #121f42d4), url(${backgroundImg});
                background-attachment: fixed;
                background-size: auto, 1270px;
            `,
        },
        pageHeader: {
            root: css`
                background-color: #121e40;
            `,
        },
        pageAside: {
            paper: css`
                background: linear-gradient(#121f42, #0c0e27);
                border-right: none;
                box-shadow: 6px 0 9px #07091552;
            `,
            listItemAndNotificationButton: css`
                &:hover {
                    background-color: #49a7d714;
                }
                &.Mui-selected {
                    text-shadow: 0 0 6px ${props => transparentize(0.4, props.theme.palette.primary.main)};
                    .MuiListItemIcon-root {
                        filter: drop-shadow(0 0 5px ${props => transparentize(0.4, props.theme.palette.primary.main)});
                    }
                }
            `,
            secondary: css`
                color: #3875a3;
            `,
        },
        page: {
            footerImage: <FooterImage width="3644" height="587" src={footerImg} />,
        },
        tabs: {
            tab: css`
                &:hover {
                    background-color: #49a7d714;
                }
                &.Mui-selected {
                    text-shadow: 0 0 10px ${props => transparentize(0.45, props.theme.palette.primary.main)};
                }
            `,
            indicator: css`
                box-shadow: 0 0 10px ${props => props.theme.palette.primary.main};
            `,
        },
        containedButton: color => {
            if (color === 'primary') {
                return css`
                    &,
                    &:hover,
                    &:focus-visible {
                        box-shadow: 0 0 7px ${props => transparentize(0.6, props.theme.palette.primary.main)};
                    }
                    &.Mui-disabled {
                        background: #1c284a;
                    }
                `
            }
            if (color === 'default') {
                return css`
                    &,
                    &:hover,
                    &:focus-visible {
                        background-color: #2a2d5d;
                        color: #8387be;
                    }
                `
            }
        },
        actionablePaper: {
            root: css`
                &:hover,
                &:focus-visible {
                    background-color: ${props => props.theme.palette.background.paper};
                    filter: brightness(1.2);
                }
            `,
        },
        dialog: {
            backdrop: css`
                background: rgb(3 18 25 / 91%);
            `,
        },
    },
})

import { Dialog, DialogActions, DialogContent, DialogPrimaryButton, DialogTitle } from 'components/dialog'
import { Text } from 'components/Text'
import { isGuestUser, useUserState } from 'contexts/UserContext'
import dayjs from 'dayjs'
import { useOnboardingManager } from 'features/onboarding'
import { useIsFirstRender } from 'hooks/useIsFirstRender'
import { getPasswordChangeOrCreateUrl } from 'lib/auth'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { createContext, useContext, useCallback, useState, useEffect } from 'react'

const DISPLAY_INTERVAL_MINUTES = 60

type Variant = 'login' | 'activity'

interface SecureAccountPopupState {
    displaySecureAccountPopup: (variant: Variant) => void
}

const secureAccountPopupContext = createContext<SecureAccountPopupState | undefined>(undefined)

export const SecureAccountPopupPrivder: React.FC = ({ children }) => {
    const user = useUserState()
    const isEnabled = isGuestUser(user)

    if (!isEnabled) {
        return (
            <secureAccountPopupContext.Provider value={{ displaySecureAccountPopup: noop }}>
                {children}
            </secureAccountPopupContext.Provider>
        )
    }

    return <SecureAccountPopupPrivderInner>{children}</SecureAccountPopupPrivderInner>
}

const SecureAccountPopupPrivderInner: React.FC = ({ children }) => {
    const user = useUserState()
    const [variant, setVariant] = useState<Variant | null>(null)
    const isFirstRender = useIsFirstRender()
    const { isOpen: isOnboardingOpen } = useOnboardingManager()

    const { hasBeenDisplayedInNMinutes, updateLastDisplayed } = useLastDisplayed(user.account.id)

    const displaySecureAccountPopup: SecureAccountPopupState['displaySecureAccountPopup'] = useCallback(
        variant => {
            if (hasBeenDisplayedInNMinutes(DISPLAY_INTERVAL_MINUTES)) {
                return
            }
            setVariant(variant)
            updateLastDisplayed()
        },
        [updateLastDisplayed, hasBeenDisplayedInNMinutes],
    )

    useEffect(() => {
        if (isFirstRender && !isOnboardingOpen) {
            displaySecureAccountPopup('login')
        }
    }, [displaySecureAccountPopup, isFirstRender, isOnboardingOpen])

    return (
        <secureAccountPopupContext.Provider value={{ displaySecureAccountPopup }}>
            {children}
            {variant !== null && (
                <SecureYourAccountDialog
                    variant={variant}
                    onClose={() => {
                        setVariant(null)
                    }}
                />
            )}
        </secureAccountPopupContext.Provider>
    )
}

interface SecureYourAccountDialogProps {
    variant: Variant
    onClose: VoidFunction
}

const SecureYourAccountDialog: React.FC<SecureYourAccountDialogProps> = ({ variant, onClose }) => {
    const t = useTranslate()
    const user = useUserState()

    const titleByVariant: Record<Variant, LocalisationKey> = {
        login: 'secureAccountModal::title',
        activity: 'secureAccountModal::title2',
    }

    const descriptionByVariant: Record<Variant, LocalisationKey> = {
        login: 'secureAccountModal::description',
        activity: 'secureAccountModal::description2',
    }

    return (
        <Dialog maxWidth="xs" fullWidth open={true} onClose={onClose}>
            <DialogTitle>{t(titleByVariant[variant])}</DialogTitle>
            <DialogContent>
                <Text>{t(descriptionByVariant[variant])}</Text>
            </DialogContent>
            <DialogActions>
                <DialogPrimaryButton component="a" fullWidth href={getPasswordChangeOrCreateUrl(user)}>
                    {t('settings::addPassword')}
                </DialogPrimaryButton>
            </DialogActions>
        </Dialog>
    )
}

const LAST_DISPLAYED_KEY = '@my-logiscool/secure-your-account-last-displayed'

const useLastDisplayed = (userId: string) => {
    const [lastDisplayedByUserId, setLastDisplayedByUserId] = useState<Record<string, string>>(() => {
        try {
            const stored = localStorage.getItem(LAST_DISPLAYED_KEY)
            if (stored) {
                return JSON.parse(stored)
            }
            return {}
        } catch (e) {
            return {}
        }
    })

    const updateLastDisplayed = useCallback(() => {
        setLastDisplayedByUserId(value => ({
            ...value,
            [userId]: dayjs().toISOString(),
        }))
    }, [userId])

    const hasBeenDisplayedInNMinutes = useCallback(
        (minutes: number) => {
            const lastDisplayed = lastDisplayedByUserId[userId]

            if (!lastDisplayed) {
                return false
            }

            const lastDisplayedDate = dayjs(lastDisplayed)
            if (!lastDisplayedDate.isValid()) {
                return false
            }

            const displayedMinutesAgo = dayjs().diff(dayjs(lastDisplayed), 'minutes')
            return displayedMinutesAgo < minutes
        },
        [lastDisplayedByUserId, userId],
    )

    useEffect(() => {
        if (Object.entries(lastDisplayedByUserId).length !== 0) {
            localStorage.setItem(LAST_DISPLAYED_KEY, JSON.stringify(lastDisplayedByUserId))
        }
    }, [lastDisplayedByUserId])

    return {
        hasBeenDisplayedInNMinutes,
        updateLastDisplayed,
    }
}

export const useSecureAccountPopup = () => {
    const context = useContext(secureAccountPopupContext)

    if (context === undefined) {
        throw new Error(`'useSecureAccountPopup' must be used within 'SecureAccountPopupPrivder'`)
    }

    return context
}

const noop = () => {}

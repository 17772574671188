import React from 'react'
import styled from 'styled-components/macro'
import LogiscoolLogo from 'media/images/logiscool-logo.png'
import { Img } from './Img'
import { Box, Stack } from 'components/layout'
import { LinearLoader } from 'components/LinearLoader'

export const FullScreenLoader: React.FC = () => {
    return (
        <Root>
            <Stack spacing={3}>
                <Img width="13rem" src={LogiscoolLogo} />
                <Box ml="auto" mr="auto" maxWidth="50%">
                    <LinearLoader />
                </Box>
            </Stack>
        </Root>
    )
}

const Root = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.palette.background.default};
`

import { Grid, Box } from 'components/layout'
import { Loader } from 'components/Loader'
import { useUserState } from 'contexts/UserContext'
import { useTranslate } from 'lib/i18n/useTranslate'
import { InventoryItem } from 'lib/liveops/models'
import { StoreState, useSelector } from 'lib/store'
import React from 'react'
import {
    digitalMission2021achievementDataList,
    digitalMission2021AchievementsInventoryId,
    DigitalMission2021AchievementData,
} from '../data/digitalMission2021Data'
import { DigitalMission2021Achivement } from '../DigitalMission2021Achivement'
import styled from 'styled-components/macro'
import { Text } from 'components/Text'
import { CookieHunt } from 'features/cookie-hunt'
import { getDigitalMission2021SeaonsPoints } from '../helpers/digitalMission2021Helpers'
import { useScrollToHash } from 'hooks/useScrollToHash'

interface DigitalMission2021ResultsProps {}

export const DigitalMission2021Results: React.FC<DigitalMission2021ResultsProps> = () => {
    const achivements = useSelector(getAchievementsFromInventory)
    const store = useSelector(state => state)
    const user = useUserState()
    const t = useTranslate()
    const points = useSelector(getDigitalMission2021SeaonsPoints)

    useScrollToHash()

    if (achivements === undefined || points === undefined) {
        return <Loader />
    }

    function processProgress({ progress }: DigitalMission2021AchievementData) {
        if (progress) {
            const { current, required } = progress
            return {
                required,
                current: typeof current === 'function' ? current(store) : current,
            }
        }
    }

    function isAchievementHidden(achi: DigitalMission2021AchievementData) {
        return typeof achi.hidden === 'function' ? achi.hidden(user) : achi.hidden
    }

    return (
        <>
            <StyledBox textAlign="center">
                {points > 100 ? (
                    <Text variant="h2">{t('digitalMission2021::achievements::title3')}</Text>
                ) : (
                    <Text variant="h2">{t('digitalMission2021::achievements::title2')}</Text>
                )}
            </StyledBox>
            <Grid container spacing={6} justify="center">
                {digitalMission2021achievementDataList
                    .filter(achi => !isAchievementHidden(achi))
                    .map((achivement, i) => {
                        if (!achivements[achivement.internalName]) return null
                        return (
                            <Grid key={achivement.catalogItem} item xs={12} sm={6} lg={4} id={achivement.catalogItem}>
                                <CookieHunt name="dm2021-achi-list" index={i}>
                                    <DigitalMission2021Achivement
                                        achivement={achivement}
                                        acquiredAt={achivements[achivement.internalName]?.createdAt}
                                        progress={processProgress(achivement)}
                                    />
                                </CookieHunt>
                            </Grid>
                        )
                    })}
            </Grid>
        </>
    )
}

const StyledBox = styled(Box)`
    margin-block-start: ${props => props.theme.spacing(8)};
`

const getAchievementsFromInventory = (state: StoreState) => {
    const { inventory } = state.client

    const achievements = inventory
        ? Object.keys(digitalMission2021AchievementsInventoryId).reduce((result, key) => {
              result[key] = inventory.find(
                  ({ item }) =>
                      item ===
                      digitalMission2021AchievementsInventoryId[
                          key as keyof typeof digitalMission2021AchievementsInventoryId
                      ],
              )
              return result
          }, {} as { [key: string]: InventoryItem | undefined })
        : {}

    return achievements
}

import { Button } from 'components/Button'
import { Card } from 'components/card'
import { Flex, Stack, WrappingInline } from 'components/layout'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import dayjs from 'dayjs'
import { useHumanReadableCountdown } from 'hooks/useCountdown'
import { useDateFormat } from 'hooks/useDateFormat'
import { getHighScoreRoom, requestRooms } from 'lib/liveops'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { isNotUndefined } from 'utils/arrayUtilts'
import { HighScoreGameThumbnail } from './HighScoreGameThumbnail'
import { useSelector } from 'lib/store'
import { Loader } from 'components/Loader'
import { useHighScoreLeaderboard } from './HighScoreGameLeaderboard'
import { canUnlockSecretCode, useUserState } from 'contexts/UserContext'
import { useTranslate } from 'lib/i18n/useTranslate'
import { HighScoreRoom } from 'lib/liveops/reducers/shared/leaderboard'
import emptyStateImage from './media/room-empty-state.svg'
import { CreateHighScoreRoomButton } from './CreateHighScoreRoomButton'
import { useHasGrant } from 'lib/grants'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { CreateMultiplayerGeneratedQuizButton } from '../quiz/generated-quiz'
import { Pagination } from '@material-ui/lab'

const TABLET_BREAKPOINT = 'md'
const MOBILE_BREAKPOINT = 'sm'

export const HighScoreRoomList: React.FC = () => {
    const dispatch = useDispatch()
    const t = useTranslate()
    const rooms = useSelector(state =>
        state.shared.stats.highScoreRooms.list
            ?.map(roomId => getHighScoreRoom(state, roomId))
            .filter(isNotUndefined)
            .sort((a, b) => {
                const aExpireAt = dayjs(a.expireAt)
                const bExpireAt = dayjs(b.expireAt)
                const now = dayjs()

                if (aExpireAt.isBefore(now) && bExpireAt.isBefore(now)) {
                    return aExpireAt.diff(bExpireAt)
                } else if (aExpireAt.isBefore(now)) {
                    return 1
                } else {
                    return -1
                }
            }),
    )
    const [page, setPage] = useState(1)
    const pages = rooms ? Math.ceil(rooms.length / 5) : 0
    const paginatedRooms = rooms ? rooms.slice((page - 1) * 5, page * 5) : []

    useEffect(() => {
        dispatch(requestRooms())
    }, [dispatch])

    if (!rooms) {
        return <Loader />
    }

    if (rooms.length === 0) {
        return <EmptyState />
    }

    return (
        <Stack spacing={6}>
            <Stack spacing={2}>
                <Flex direction="row" style={{ gap: '0.5rem' }}>
                    <CreateHighscoreButton />
                    <CreateMultiplayerGeneratedQuizButton />
                </Flex>
            </Stack>
            <Stack spacing={2}>
                <LabelsWrap>
                    <Grid>
                        <Text variant="body2">{t('highScoreRoom::room')}</Text>
                        <span></span>
                        <Text variant="body2">{t('general::rank')}</Text>
                        <Text variant="body2">{t('general::remainingTime')}</Text>
                    </Grid>
                </LabelsWrap>
                <Card>
                    <ListRoot>
                        {paginatedRooms.map(room => (
                            <HighScoreRoomListItem key={room.id} room={room} />
                        ))}
                    </ListRoot>
                </Card>
                {pages > 1 && (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Pagination page={page} count={pages} onChange={(_, value) => setPage(value)} />
                    </div>
                )}
            </Stack>
        </Stack>
    )
}

const HighScoreRoomListItem: React.FC<{ room: HighScoreRoom }> = ({ room }) => {
    const [isActive, setIsActive] = useState(true)
    const { formatDate } = useDateFormat()
    const expireAt = useMemo(() => dayjs(room.expireAt), [room.expireAt])
    const { label: countdownLabel, isEnd } = useHumanReadableCountdown(expireAt)
    const { leaderboards } = useHighScoreLeaderboard(room.target, room.id, room.game, false)
    const { account } = useUserState()
    const t = useTranslate()

    useEffect(() => {
        if (isEnd) {
            setIsActive(false)
        }
    }, [isEnd])

    return (
        <ItemRoot $isActive={isActive}>
            <ItemInner>
                <Grid>
                    <HighScoreGameThumbnail game={room.game} />
                    <Name>{room.name}</Name>
                    <ItemDefinition label={t('general::rank')}>
                        {leaderboards?.map(leaderboard => {
                            const index = leaderboard.allTimeResults?.items.findIndex(
                                item => item.owner.id === account.id,
                            )
                            const rank = index && index !== -1 ? index + 1 : leaderboard.myAllTimeResult?.rank
                            const stat = room.game.stats?.find(stat => stat.name === leaderboard.name)

                            if (!rank) {
                                return null
                            }

                            return (
                                <div>
                                    {leaderboards.length > 1 && (
                                        <Text variant="body2" component="span">
                                            {stat ? t(stat.i18nKey) : leaderboard.name}:
                                        </Text>
                                    )}{' '}
                                    {rank || '-'}/{leaderboard.allTimeResults?.count || '-'}
                                </div>
                            )
                        })}
                    </ItemDefinition>
                    <ItemDefinition label={t('general::remainingTime')}>
                        <Tooltip title={formatDate(expireAt.toDate())}>
                            <span>{countdownLabel}</span>
                        </Tooltip>
                    </ItemDefinition>
                    <ActionsWrap>
                        <Button
                            variant="outlined"
                            component={Link}
                            to={`/app/high-score-room-results/${room.game.projectId}/${room.id}`}
                        >
                            {t('highScoreRoom::openLeaderboard')}
                        </Button>
                        {isActive && (
                            <Button
                                disabled={!isActive}
                                component={Link}
                                to={`/app/high-score/${room.game.projectId}?room=${room.id}`}
                            >
                                {t('general::play')}
                            </Button>
                        )}
                    </ActionsWrap>
                </Grid>
            </ItemInner>
        </ItemRoot>
    )
}

const CreateHighscoreButton = () => {
    const hasGrant = useHasGrant()
    const user = useUserState()
    const canCreateHighScoreRoom =
        hasGrant('create-high-score-room') && canUnlockSecretCode(user, 'scoolcode-live-room')

    return (
        <>
            {canCreateHighScoreRoom ? (
                <ErrorBoundary>
                    <CreateHighScoreRoomButton mode={{ name: 'list-games' }} />
                </ErrorBoundary>
            ) : null}
        </>
    )
}

const EmptyState: React.FC = () => {
    const t = useTranslate()

    return (
        <Stack spacing={6}>
            <Stack spacing={2}>
                <Flex direction="row" style={{ gap: '0.5rem' }}>
                    <CreateHighscoreButton />
                    <CreateMultiplayerGeneratedQuizButton />
                </Flex>
            </Stack>
            <EmptyStateRoot>
                <img src={emptyStateImage} alt="" width="300" height="142" />
                <div>
                    <Text>
                        {t('highScoreRoom::noRooms02')} <br /> {t('highScoreRoom::description')}
                    </Text>
                </div>
            </EmptyStateRoot>
        </Stack>
    )
}

const ItemDefinition: React.FC<{ label: string }> = ({ label, children }) => {
    return (
        <div>
            <InfoLabel variant="body2" component="dt">
                {label}
            </InfoLabel>
            <Text component="dd">
                <strong>{children}</strong>
            </Text>
        </div>
    )
}

const Name = styled(Text)`
    word-break: break-word;
    font-weight: 700;
`

const InfoLabel = styled(Text)`
    color: ${props => props.theme.palette.text.secondary};
    display: none;
    ${props => props.theme.breakpoints.down(TABLET_BREAKPOINT)} {
        display: block;
    }
` as typeof Text

const LabelsWrap = styled.div`
    border-left: solid 4px transparent;
    padding: ${props => props.theme.spacing(0, 4, 0, 4)};
    ${props => props.theme.breakpoints.down(TABLET_BREAKPOINT)} {
        display: none;
    }
`

const ListRoot = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
`

const ItemRoot = styled.li<{ $isActive: boolean }>`
    border-left: solid 4px ${props => (props.$isActive ? props.theme.palette.primary.main : 'transparent')};
    color: ${props => (props.$isActive ? props.theme.palette.text.primary : props.theme.palette.text.secondary)};
    &:not(:last-of-type) {
        border-bottom: solid 1px ${props => props.theme.palette.divider};
    }
`

const ActionsWrap = styled(WrappingInline)`
    justify-content: flex-end;
    ${props => props.theme.breakpoints.down(TABLET_BREAKPOINT)} {
        grid-row: 1;
        grid-column: 3 / 4;
    }
    ${props => props.theme.breakpoints.down(MOBILE_BREAKPOINT)} {
        grid-row: auto;
        justify-self: flex-start;
        grid-column: span 2;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 7rem 12rem 8rem 10rem 1fr;
    grid-gap: ${props => props.theme.spacing(4)};
    align-items: center;
    ${props => props.theme.breakpoints.down(TABLET_BREAKPOINT)} {
        grid-template-columns: 7rem 10rem 1fr;
    }
    ${props => props.theme.breakpoints.down(MOBILE_BREAKPOINT)} {
        grid-template-columns: 7rem 1fr;
    }
`

const ItemInner = styled.div`
    padding: ${props => props.theme.spacing(4)};
`

const EmptyStateRoot = styled(Stack)`
    text-align: center;
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    p {
        font-weight: 600;
    }
    img {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
    }
`

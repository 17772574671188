import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { formatDate, getCertificateTranslations, getSubjectInfo } from './certificateHelpers'
import { FinishedSubject } from './certificateTypes'

interface PdfGeneratorProps {
    subject: FinishedSubject
    fullName: string
    userCountry?: string
    onSuccess: (blob: Blob, imgData: string) => void
    onError: (code: 'NO_COURSE_INFO' | 'GENERAL') => void
    locale?: string
    language: string
}

export const PdfGenerator: React.FC<PdfGeneratorProps> = ({
    subject,
    fullName,
    onSuccess,
    onError,
    locale,
    language,
    userCountry,
}) => {
    const t = useTranslate()
    const id = React.useRef(generateId())

    const subjectInfo = React.useMemo(() => getSubjectInfo(subject.key, subject.type), [subject.key, subject.type])

    React.useEffect(() => {
        let ignore = false
        if (subjectInfo) {
            generatePdf(id.current)
                .then(({ blob, imgData }) => {
                    if (!ignore) {
                        onSuccess(blob, imgData)
                    }
                })
                .catch(e => {
                    if (!ignore) {
                        console.error(e)
                        onError('GENERAL')
                    }
                })
        } else {
            onError('NO_COURSE_INFO')
        }

        return () => {
            ignore = true
        }
    }, [onError, onSuccess, subjectInfo])

    if (!subjectInfo) {
        return null
    }

    const translations = getCertificateTranslations(t, subjectInfo)

    const finishedAt = formatDate(subject.lastDay, language)

    const school = (() => {
        if (userCountry && countriesUsingPortalScoolName.includes(userCountry)) {
            return subject.school_portalName || subject.school_name
        }
        return subject.school_name
    })()

    return (
        <subjectInfo.template
            translations={translations}
            school={school}
            finishedAt={finishedAt}
            name={fullName}
            id={id.current}
            locale={locale ?? language}
        />
    )
}

export const generatePdf = async (id: string) => {
    const html2canvas = (await import('html2canvas')).default
    const jsPDF = (await import('jspdf')).default
    const canvas = await html2canvas(document.querySelector(`#${id}`) as any, {
        scrollY: 0,
        useCORS: true,
    })
    const imgData = canvas.toDataURL('image/jpeg')
    const pdf = new jsPDF('portrait', 'mm', 'a4')
    pdf.addImage(imgData, 'PNG', 0, 0, 210, 297)
    const blob = pdf.output('blob') as Blob
    return { blob, imgData }
}

export const generateId = () =>
    Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(2, 10)

const countriesUsingPortalScoolName: string[] = ['BG']

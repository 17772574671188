import React, { createContext, useContext } from 'react'
import { useRef } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router-dom'
import { modalRoutes } from './modalRoutes'
import { Location } from 'history'

interface ModalState {
    closeModal: () => void
    nonModalLocation: Location
    isModal: boolean
}

const modalContext = createContext<ModalState | undefined>(undefined)

export const ModalProvider: React.FC = ({ children }) => {
    const lastNonModalLocation = useRef<Location | null>(null)
    const location = useLocation()
    const activeModal = modalRoutes.find(route => matchPath(location.pathname, { path: route.path }))
    const history = useHistory()
    const isModal = activeModal !== undefined
    const nonModalLocation = isModal && lastNonModalLocation.current ? lastNonModalLocation.current : location

    useEffect(() => {
        if (!isModal) {
            lastNonModalLocation.current = location
        }
    })

    const closeModal = useCallback(() => {
        if (lastNonModalLocation.current) {
            history.goBack()
        } else {
            history.push('/app')
        }
    }, [history])

    return (
        <modalContext.Provider
            value={{
                nonModalLocation,
                closeModal,
                isModal,
            }}
        >
            {children}
        </modalContext.Provider>
    )
}

export const useModal = () => {
    const context = useContext(modalContext)

    if (context === undefined) {
        throw new Error(`'useModal' must be used within a ModalProvider.`)
    }

    return context
}

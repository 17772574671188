import {
    Dialog,
    DialogErrorMessage,
    DialogForm,
    DialogPrimaryButton,
    DialogSecondaryButton,
    DialogTitle,
} from 'components/dialog'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { SelectBox, SelectBoxItem, TextField } from 'components/form'
import { ClassroomIcon, SwearIcon } from 'components/icons'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import { reportProject } from 'lib/liveops/actions/reportProject'
import { useNotifications } from 'features/notifications'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { FaBug } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useProjectModalContext } from '../modal/projectModalContext'
import { LocalisationKey } from 'lib/i18n'
import { useHasGrant } from 'lib/grants'

interface ReportProjectModalProps {
    isOpen: boolean
    close: VoidFunction
}

export const ReportProjectModal: React.FC<ReportProjectModalProps> = ({ isOpen, close }) => {
    return (
        <Dialog maxWidth="xs" open={isOpen} onClose={close}>
            <ErrorBoundary fallback={<DialogErrorMessage close={close} />}>
                <ReportProjectModalInner close={close} />
            </ErrorBoundary>
        </Dialog>
    )
}

interface FormValues {
    notes: string
    reason: 'offensive' | 'malfunctioning' | 'shared-classroom'
}

const ReportProjectModalInner: React.FC<{ close: VoidFunction }> = ({ close }) => {
    const t = useTranslate()
    const dispatch = useDispatch()
    const { id, project } = useProjectModalContext()
    const { notify } = useNotifications()
    const hasGrant = useHasGrant()
    const { register, handleSubmit, errors, control } = useForm<FormValues>({
        defaultValues: {
            notes: '',
            reason: 'offensive',
        },
    })

    const onSubmit = handleSubmit(({ reason, notes }) => {
        dispatch(reportProject(id, reason, notes))
        close()
        notify(t('projects::report::thankYou'), { variant: 'success' })
    })

    return (
        <>
            <DialogTitle>{t('projects::report::label')}</DialogTitle>
            <DialogForm
                onSubmit={onSubmit}
                actions={
                    <>
                        <DialogSecondaryButton onClick={close}>{t('general::cancel')}</DialogSecondaryButton>
                        <DialogPrimaryButton type="submit">{t('projects::report::label')}</DialogPrimaryButton>
                    </>
                }
            >
                <Stack>
                    <Text>{t('projects::report::description')}</Text>
                    <div>
                        <Controller
                            name="reason"
                            control={control}
                            as={
                                <SelectBox label={t('projects::report::chooseReason')}>
                                    <SelectBoxItem fullWidth value="offensive" icon={<SwearIcon />}>
                                        {t('projects::report::offensive')}
                                    </SelectBoxItem>
                                    <SelectBoxItem
                                        fullWidth
                                        value="malfunctioning"
                                        icon={<FaBug style={{ width: 30 }} />}
                                    >
                                        {t('projects::report::malfunctioning')}
                                    </SelectBoxItem>
                                    {project?.metadata.isMiniQuiz === false && hasGrant('report-classroom') && (
                                        <SelectBoxItem fullWidth value="shared-classroom" icon={<ClassroomIcon />}>
                                            {t('projects::report::classroom')}
                                        </SelectBoxItem>
                                    )}
                                </SelectBox>
                            }
                        />
                    </div>
                    <TextField
                        name="notes"
                        autoFocus
                        errorText={errors.notes ? t(errors.notes.message as LocalisationKey) : undefined}
                        ref={register({
                            required: { value: true, message: t('general::fieldIsRequired') },
                            validate: value => {
                                return value.trim() ? true : t('general::fieldIsRequired')
                            },
                        })}
                        label={t('projects::report::describe')}
                        rows={3}
                        multiline
                    />
                </Stack>
            </DialogForm>
        </>
    )
}

import { Box, BoxProps } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'

interface StackProps extends BoxProps {
    spacing?: number
}

export const Stack: React.FC<StackProps> = ({ spacing = 4, children, ...rest }) => {
    return (
        <Root spacing={spacing} {...rest}>
            {children}
        </Root>
    )
}

const Root = styled(Box)<{ spacing: number }>`
    > * + * {
        margin-block-start: ${props => props.theme.spacing(props.spacing)};
    }
`

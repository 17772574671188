import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'
import { useTranslate } from 'lib/i18n/useTranslate'
import { joinHighScoreRoomDialogTo } from './JoinHighScoreRoomDialog'

export const JoinHighScoreRoomButton = () => {
    const t = useTranslate()

    return (
        <Button component={Link} to={joinHighScoreRoomDialogTo}>
            {t('highScoreRoom::join')}
        </Button>
    )
}

import image0 from './6390b5eee3150f90a41d5ce2.png'
import image1 from './6390b60b56d05765b3fbfb94.png'
import image2 from './6390b62ae6b232b1a323a9de.png'
import image3 from './6390b6368d8f3a0378ee82eb.png'
import image4 from './6390b6468d8f3a3a5cee8420.png'
import image5 from './6390b6ac8d8f3a6b2eee8e0d.png'
import image6 from './6390b6b9be7e645ef097b549.png'
import image7 from './6390b6c37c4c4781cdab7e87.png'
import image8 from './6390b6ced6569515eadc162e.png'
import image9 from './6390b6da83b28b6ac091cb1a.png'

type ImagesRecord = Record<string, string>

export const images: ImagesRecord = {
    '6390b5eee3150f90a41d5ce2': image0,
    '6390b60b56d05765b3fbfb94': image1,
    '6390b62ae6b232b1a323a9de': image2,
    '6390b6368d8f3a0378ee82eb': image3,
    '6390b6468d8f3a3a5cee8420': image4,
    '6390b6ac8d8f3a6b2eee8e0d': image5,
    '6390b6b9be7e645ef097b549': image6,
    '6390b6c37c4c4781cdab7e87': image7,
    '6390b6ced6569515eadc162e': image8,
    '6390b6da83b28b6ac091cb1a': image9,
}

import { CheckIcon, LockIcon } from 'components/icons'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useSelector } from 'lib/store'
import { darken, mix, transparentize } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { visuallyHidden } from 'utils/styleUtils'
import { OnboardingAchievementData } from '../data/onboardingData'
import { isAchievementAcquired } from '../helpers/onboardingHelpers'

interface OnboardingAchievementProps {
    achievement: OnboardingAchievementData
    onClick: VoidFunction
    isSelected: boolean
}

export const OnboardingAchievement: React.FC<OnboardingAchievementProps> = ({ achievement, onClick, isSelected }) => {
    const { imageUrl, title, color, isLocked } = achievement
    const isAcquired = useSelector(state => isAchievementAcquired(achievement, state.client.inventory))
    const t = useTranslate()

    const icon = (() => {
        if (isAcquired) {
            return <StyledCheckIcon $color={color} />
        }
        if (isLocked) {
            return <StyledLockIcon />
        }

        return <Img src={imageUrl} alt={t(title)} />
    })()

    return (
        <Root onClick={onClick} $isSelected={isSelected} $isAcquired={isAcquired} $isLocked={isLocked} $color={color}>
            <Inner>{icon}</Inner>
            <Label>{t(title)}</Label>
        </Root>
    )
}

const Label = styled.span`
    ${visuallyHidden}
`

const Img = styled.img`
    max-width: 100%;
`

const StyledCheckIcon = styled(CheckIcon)<{ $color: string }>`
    width: 80%;
    height: 80%;
    color: ${props => darken(0.6, props.$color)};
    color: ${props => transparentize(0, props.theme.palette.primary.main)};
`

const StyledLockIcon = styled(LockIcon)`
    width: 80%;
    height: 80%;
`

const Inner = styled.div`
    padding: ${props => props.theme.spacing(4)};
    transition: transform ${props => props.theme.transitions.duration.shortest}ms;
`

const Root = styled.button<{ $isSelected: boolean; $isAcquired: boolean; $isLocked: boolean; $color: string }>`
    border: none;
    position: relative;
    cursor: pointer;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    aspect-ratio: 1/1;
    box-shadow: ${props => props.theme.shadows[2]};
    color: ${props => props.theme.palette.primary.contrastText};
    &:hover,
    &:focus-visible {
        ${Inner} {
            transform: scale(1.07);
        }
    }
    background: ${props => {
        if (props.$isLocked) {
            return props.theme.palette.grey[600]
        }
        if (props.$isAcquired) {
            return mix(0.9, props.theme.palette.background.paper, props.theme.palette.primary.main)
        }
        return transparentize(0.5, props.$color)
    }};
    ${Img} {
        filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.3));
    }
    ${props =>
        props.$isSelected &&
        css`
            box-shadow: 0 0 0 3px ${props => props.theme.palette.primary.light};
        `}
`

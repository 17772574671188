import { CardContent } from 'components/card'
import { CheckIcon } from 'components/icons'
import { Inline, Stack } from 'components/layout'
import {
    ProgressBar,
    ProgressBarCenteredText,
    ProgressBarIndicator,
    ProgressBarTick,
    ProgressBarTrack,
} from 'components/progress-bar'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { OnboardingAchievementData } from '../data/onboardingData'
import { getOnboadingPoints } from '../helpers/onboardingHelpers'
import prize1Img from '../media/prize1.svg'
import prize2Img from '../media/prize2.png'

interface OnboardingProgressProps {
    achievements: OnboardingAchievementData[]
}

export const OnboardingProgress: React.FC<OnboardingProgressProps> = ({ achievements }) => {
    const t = useTranslate()
    const points = useSelector(getOnboadingPoints)
    const maxPoints = achievements.length

    if (points === undefined) {
        return null
    }

    return (
        <Root>
            <Stack spacing={6}>
                <ProgressBar
                    value={points}
                    max={maxPoints}
                    ticks={
                        <>
                            <ProgressBarTick requiredValue={3}>
                                <Triangle />
                            </ProgressBarTick>
                            <ProgressBarTick requiredValue={8}>
                                <Triangle />
                            </ProgressBarTick>
                        </>
                    }
                >
                    <ProgressBarTrack>
                        <ProgressBarIndicator />
                        <ProgressBarCenteredText>
                            <Inline spacing={1} display="flex" alignItems="center" justifyContent="center">
                                <span>
                                    {points}/{maxPoints}
                                </span>
                                <CheckIcon />
                            </Inline>
                        </ProgressBarCenteredText>
                    </ProgressBarTrack>
                </ProgressBar>
                <PanelWrap>
                    <OnboardingProgressPanel>
                        <Img src={prize1Img} />
                        <Stack>
                            <Text variant="h3">{t('onboardingMissionCard01::title')}</Text>
                            <Text>
                                <Trans i18nKey="onboardingMissionCard01::description" />
                            </Text>
                        </Stack>
                    </OnboardingProgressPanel>
                    <OnboardingProgressPanel>
                        <Img src={prize2Img} />
                        <Stack>
                            <Text variant="h3">{t('onboardingMissionCard02::title')}</Text>
                            <Text>
                                <Trans i18nKey="onboardingMissionCard02::description" />
                            </Text>
                        </Stack>
                    </OnboardingProgressPanel>
                </PanelWrap>
            </Stack>
        </Root>
    )
}

const Triangle: React.FC = () => {
    return (
        <TriangleRoot xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 14.6" xmlSpace="preserve">
            <path d="M29.9 14.6 16.6 2.7c-.4-.3-.8-.3-1.2 0L2.1 14.6" />
        </TriangleRoot>
    )
}

const TriangleRoot = styled.svg`
    position: absolute;
    bottom: -${props => props.theme.spacing(6)};
    transform: translate(-50%, 3px);
    width: 2.5rem;

    path {
        fill: ${props => props.theme.palette.background.paper};
        stroke: ${props => props.theme.palette.divider};
    }
`

const PanelWrap = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.theme.spacing(4)};
    align-items: flex-start;
`

const OnboardingProgressPanel: React.FC = ({ children }) => {
    return (
        <OnboardingProgressPanelRoot>
            <CardContent>
                <OnboardingProgressPanelInner>{children}</OnboardingProgressPanelInner>
            </CardContent>
        </OnboardingProgressPanelRoot>
    )
}

const OnboardingProgressPanelRoot = styled.div`
    background-color: ${props => props.theme.palette.background.paper};
    border: solid 2px ${props => props.theme.palette.divider};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    height: 100%;
`

const OnboardingProgressPanelInner = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: ${props => props.theme.spacing(4)};

    ${props => props.theme.breakpoints.down('xs')} {
        display: flex;
        flex-direction: column;
    }
`

const Img = styled.img`
    max-width: 100%;
    display: block;
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

const Root = styled.div``

import React from "react";
import {FormControl, Switch} from "components/form";
import {Inline} from "components/layout";
import styled from "styled-components/macro";

export interface BooleanFieldProps {
    label: string,
    value?: number,
    onChange?: (e: any, newValue: string) => any
}
export const BooleanField: React.FC<BooleanFieldProps & any> = ({ value, onChange, children, multiline, endAdornment, ...rest }) => {
    return (
        <FormControl fullWidth>
            <Wrap>
                <Switch checked={value} onChange={e => onChange(e, e.target.checked)} {...rest} />
                {endAdornment}
            </Wrap>
        </FormControl>
    )
}

const Wrap = styled(Inline)`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
`
import { ApiTypes } from 'lib/api'
import { ApiBase } from 'lib/api/ApiBase'
import { LocaleString } from './quizHelpers'

export type QuizQuestion = ApiTypes['quiz-question'] & { _id: string }
export type QuizInstanceWithQuestions = Omit<ApiTypes['quiz-instance'], 'questions'> & { questions: QuizQuestion[] }
export type QuizSessionWithInstance = Omit<ApiTypes['quiz-session'], 'instance'> & {
    instance: QuizInstanceWithQuestions
}
export type NormalizedQuizSetSessions = Record<string, { sessionId?: string; status: 'finished' | 'started' }>
export type QuizResult = QuizInstanceWithQuestions & {
    userResponses: ApiTypes['quiz-response'][]
} & ApiTypes['quiz-result']
export type QuizSetWithMetadata = ApiTypes['quiz-set'] & {
    locked: boolean
}
export type QuizSetWithEmbeddedTemplates = Omit<QuizSetWithMetadata, 'templates'> & {
    templates: ApiTypes['quiz-template'][]
}

export function isQuizsetWithEmbeddedTemplates(
    set: QuizSetWithEmbeddedTemplates | QuizSetWithMetadata,
): set is QuizSetWithEmbeddedTemplates {
    return typeof set.templates[0] !== 'string'
}

export type QuizTemplatesResults = Record<string, number>
export class QuizApi extends ApiBase {
    getClassroomQuizResults = async (skip: number) => {
        const limit = 6
        const res = await this.client.get<ApiTypes['quiz-result'][]>(`/quiz-results/my`, {
            params: {
                sort: '-createdAt',
                limit: limit,
                skip: skip,
                tags: 'visible-on-mylogiscool',
                //'where[nParticipants][gt]': 1,
            },
        })

        const totalCount = res.headers['x-total-count']

        const hasMore = skip + res.data.length < parseInt(totalCount) && res.data.length === limit

        return {
            data: res.data,
            hasLeft: hasMore ? skip + limit : false,
        }
    }

    getLatestClassroomQuizResult = async () => {
        return this.client.get<ApiTypes['quiz-result'][]>(`/quiz-results/my`, {
            params: {
                sort: '-createdAt',
                limit: 2,
                'where[nParticipants][gt]': 1,
            },
        })
    }

    getDetailedQuizResult = async (sessionId: string) => {
        const session = await this.client.get<QuizSessionWithInstance[]>(
            `/quiz-sessions/my?id=${sessionId}&embed=instance`,
        )
        const userResponse = await this.client.get<Required<ApiTypes['quiz-response'][]>>(
            `/quiz-responses/my?session=${sessionId}`,
        )

        return {
            ...session.data[0].instance,
            userResponses: userResponse.data,
        }
    }

    getQuizResult = async (sessionId: string) => {
        const res = await this.client.get<ApiTypes['quiz-result'][]>(`/quiz-results/my?session=${sessionId}`)

        return res.data[0]
    }

    getQuizResultsForSessions = async (sessionIds: string[]) => {
        if (sessionIds.length === 0) {
            return []
        }

        const res = await this.client.get<ApiTypes['quiz-result'][]>(
            `/quiz-results/my?where[session][in]=${sessionIds.join()}`,
        )

        return res.data
    }

    getQuizSessionsForTemplates = async (templates: string[]) => {
        const sessions = await this.client.get<Pick<ApiTypes['quiz-session'], 'template' | 'id' | 'endedAt'>[]>(
            '/quiz-sessions/my',
            {
                params: {
                    'where[template][in]': templates.join(','),
                    fields: 'template,id,endedAt',
                    sort: '-createdAt',
                },
            },
        )

        return templates.reduce<NormalizedQuizSetSessions>((acc, curr) => {
            const session = sessions.data.find(session => session.template === curr)
            if (session) {
                acc[curr] = {
                    sessionId: session.id,
                    status: session?.endedAt ? 'finished' : 'started',
                }
            }
            return acc
        }, {})
    }

    getActiveGeneratedSessions = () => {
        return this.client.get<
            {
                _id: string
                slug: string
                live: { questionIndex: number; nQuestions: number; name: LocaleString; nameKeys?: string[] }
            }[]
        >('/quiz-sessions/my-active-generated-sessions')
    }

    getQuizSetsSessions = async (quizSets: (QuizSetWithEmbeddedTemplates | QuizSetWithMetadata)[]) => {
        const templateIds = extractTemplateIdsFromQuizSets(quizSets)
        return this.getQuizSessionsForTemplates(templateIds)
    }

    getQuizSets = () => {
        return this.client.get<QuizSetWithEmbeddedTemplates[]>('/quiz-sets/my')
    }

    getRecommendedQuizSets = () => {
        return this.client.get<QuizSetWithMetadata[]>('/quiz-sets/my-recommended')
    }

    getFinishedTemplates = async (setId: string) => {
        const response = await this.client.get<QuizTemplatesResults>('/quiz-results/finished-templates-count', {
            params: {
                setId,
            },
        })

        return response.data
    }
}

function extractTemplateIdsFromQuizSets(quizSets: (QuizSetWithEmbeddedTemplates | QuizSetWithMetadata)[]) {
    return quizSets.reduce<string[]>((acc, curr) => {
        if (!curr.templates) {
            return acc
        }
        if (isQuizsetWithEmbeddedTemplates(curr)) {
            acc = [...acc, ...curr.templates.map(t => t.id)]
        } else {
            acc = [...acc, ...curr.templates]
        }
        return acc
    }, [])
}

import { UserState } from 'contexts/UserContext'
import { isProd } from './env'

declare global {
    interface Window {
        dataLayer?: { push: (arg: any) => void }
    }
}

export function initGoogleTagManager() {
    if (isProd) {
        const scriptEl = document.createElement('script')
        scriptEl.src = '/google-tag-manager.js'
        document.head.append(scriptEl)
    }
}

export function setUserInGoogleTagManager(user: UserState) {
    try {
        if (window.dataLayer) {
            window.dataLayer.push({ user_id: user.account.id })
            window.dataLayer.push({ 'user-stage': user.account.stage })
            window.dataLayer.push({ user_stage: user.account.stage })
        }
    } catch (e) {
        console.error(e)
    }
}

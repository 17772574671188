import React, { Dispatch, useCallback, useEffect, useReducer, useState } from 'react'
import { Grid, Stack } from 'components/layout'
import { ManagementModalContentProps, ManagementModalTitle } from './ManagementModal'
import { MutationResult, useMutation, useQueryCache } from 'react-query'
import { ActivityEvent } from '../../events/activity-events/types/activityEventTypes'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { useManagementContext } from '../helpers/managementContext'
import { DialogActions, DialogSecondaryButton } from 'components/dialog'
import { CheckBox } from '../../../components/form'
import { CheckIcon, CloseIcon } from '../../../components/icons'
import { CircularProgress } from '@material-ui/core'
import { useEvents } from '../../events/useEvents'
import { useUserState } from 'contexts/UserContext'

export type ManagementMutationFunction<TData> = (data: TData) => Promise<TData>

export interface ManagementMutationConfiguration {
    primaryField?: string
    displayTargetAs?: string
    displayTargetAsPlural?: string
    crudKind?: string
    allowFiltering?: boolean
    invalidateQueries?: (string | any[])[]
}

export interface ManagementMutation<TData> {
    configuration: ManagementMutationConfiguration
    mutate: ManagementMutationFunction<TData>
}

export interface SingleManagementMutationTask<TData> {
    type: 'single'
    kind: string
    data: TData
}

export interface BatchManagementMutationTask<TData> {
    type: 'batch'
    kind: string
    data: TData[]
}

export type ManagementMutationTask<TData> = SingleManagementMutationTask<TData> | BatchManagementMutationTask<TData>

const MUTATIONS: Record<string, ManagementMutation<any>> = {}

const CRUD_TO_FILTERING: Record<string, boolean> = {
    create: true,
    update: false,
    delete: true,
}
const CRUD_TO_LABEL: Record<string, string> = {
    create: 'created',
    update: 'updated',
    patch: 'updated',
    delete: 'deleted',
}

export const registerManagementMutationTask: <TData>(
    kind: string,
    configuration: ManagementMutationConfiguration,
    mutation: ManagementMutationFunction<TData>,
) => void = (kind, configuration, mutation) => {
    MUTATIONS[kind] = MUTATIONS[kind] || { configuration, mutate: mutation }
}

const AsyncIdentity = async (x: any) => x

const reduceSelected = (selected: boolean[], action: { index: number; value: boolean }) => {
    const newSelected = [...selected]
    newSelected[action.index] = action.value
    return newSelected
}

interface WrappedMutateResult {
    status: 'loading' | 'success' | 'error' | 'idle'
    error?: any
    data?: any
}

const reduceResults = (
    results: WrappedMutateResult[],
    action: { index: number; result: WrappedMutateResult | WrappedMutateResult[] },
) => {
    if (Array.isArray(action.result)) return action.result

    const newResults = [...results]
    newResults[action.index] = { ...action.result }
    return newResults
}

const handleMutate = (
    mutate: Promise<MutationResult<ActivityEvent>>,
    index: number,
    dispatch: Dispatch<{ index: number; result: WrappedMutateResult | WrappedMutateResult[] }>,
) => {
    const result: WrappedMutateResult = {
        status: 'loading',
    }

    dispatch({ index, result })

    mutate
        .then(output => {
            result.status = output.status || 'success'
            result.data = output.data
            result.error = output.error
            dispatch({ index, result })
        })
        .catch(error => {
            result.status = 'error'
            result.error = error
            dispatch({ index, result })
        })
}

export const MutationTaskManagementModalContent: React.FC<ManagementModalContentProps<ManagementMutationTask<any>>> = ({
    modal,
}) => {
    const mutation = MUTATIONS[modal.data.kind]
    const [mutate] = useMutation(mutation?.mutate || AsyncIdentity, {})
    const entries = modal.data.type === 'single' ? [modal.data.data] : modal.data.data
    const [selection, updateSelection] = useReducer(
        reduceSelected,
        entries.map(() => true),
    )
    const [results, updateResults] = useReducer(reduceResults, [])
    const { closeModal } = useManagementContext()
    const [done, setDone] = useState(false)
    const queryCache = useQueryCache()

    const hasSelected = selection.some(x => x)
    const selectedEntries = entries.filter((_, i) => selection[i])

    const inProgress = !!results.length
    const allReady = inProgress && results.every(result => result?.status === 'success' || result?.status === 'error')

    const user = useUserState()
    const { initEvents } = useEvents()

    useEffect(() => {
        if (allReady) {
            ;(async () => {
                if (mutation && mutation.configuration.invalidateQueries?.length) {
                    await Promise.all(
                        mutation.configuration.invalidateQueries.map(query => queryCache.invalidateQueries(query)),
                    )
                    await initEvents(user)
                }
                setDone(true)
            })()
        }
    }, [allReady, queryCache, mutation, initEvents, user])

    const handleConfirm = useCallback(async () => {
        if (!mutation) return
        if (modal.data.type === 'single') {
            handleMutate(mutate(modal.data.data), 0, updateResults)
        } else {
            const filteredData = modal.data.data.filter((_, i) => selection[i])
            for (let i = 0; i < filteredData.length; i++) {
                const entry = filteredData[i]
                handleMutate(mutate(entry), i, updateResults)
            }
        }
    }, [mutate, modal.data.data, modal.data.type, selection, mutation])

    useEffect(() => {
        if (mutation && modal.data.type === 'single') {
            handleConfirm().then(() => {})
        }
    }, [mutation, modal.data.type, handleConfirm])

    if (!mutation)
        return (
            <Stack spacing={5} margin={4}>
                <h2>Missing mutation for {modal.data.kind}</h2>
            </Stack>
        )

    const allowFiltering =
        modal.data.type !== 'single' &&
        !inProgress &&
        (typeof mutation.configuration.allowFiltering === 'boolean'
            ? mutation.configuration.allowFiltering
            : CRUD_TO_FILTERING[mutation.configuration.crudKind || 'update'])

    const activeEntries = inProgress ? selectedEntries : entries

    return (
        <>
            <Stack spacing={5} margin={4}>
                {modal.data.type !== 'single' && (
                    <ManagementModalTitle
                        title={`Confirm Batch ${mutation.configuration.crudKind || 'Operation'}`}
                        subTitle={mutation.configuration.displayTargetAs || modal.data.kind}
                    />
                )}
                {modal.data.type === 'single' && (
                    <ManagementModalTitle
                        title={`${mutation.configuration.crudKind || 'Operation'} ${
                            mutation.configuration.displayTargetAs || modal.data.kind
                        }`}
                    />
                )}
                {!inProgress && (
                    <Text>
                        <strong>
                            Once confirmed, the {allowFiltering ? 'selected' : 'following'}{' '}
                            {mutation.configuration.displayTargetAsPlural ||
                                `${mutation.configuration.displayTargetAs || modal.data.kind} entries`}{' '}
                            will be {CRUD_TO_LABEL[mutation.configuration.crudKind || 'update']}:
                        </strong>
                    </Text>
                )}
                {inProgress && !done && (
                    <Text>
                        <strong>Please wait, processing...</strong>
                    </Text>
                )}
                {done && (
                    <Text>
                        <strong>
                            Operation completed, the following{' '}
                            {mutation.configuration.displayTargetAsPlural ||
                                `${mutation.configuration.displayTargetAs || modal.data.kind} entries`}{' '}
                            have been {CRUD_TO_LABEL[mutation.configuration.crudKind || 'update']}:
                        </strong>
                    </Text>
                )}
                <Grid container spacing={2}>
                    {activeEntries.map((entry, i) => {
                        const result = results[i]
                        const label = mutation.configuration.primaryField
                            ? entry[mutation.configuration.primaryField]
                            : `Entry ${i + 1}`

                        return (
                            <React.Fragment key={`${label}-${i}`}>
                                {allowFiltering && (
                                    <Grid item xs={12}>
                                        <CheckBox
                                            label={label}
                                            checked={selection[i]}
                                            onChange={e => updateSelection({ index: i, value: e.target.checked })}
                                        />
                                    </Grid>
                                )}
                                {!allowFiltering && (
                                    <Grid item xs={inProgress ? 10 : 12}>
                                        <Text>{label}</Text>
                                    </Grid>
                                )}
                                {inProgress && (
                                    <Grid item xs={2}>
                                        {result?.status === 'success' && <CheckIcon />}
                                        {result?.status === 'error' && <CloseIcon />}
                                        {(!result || result.status === 'loading') && (
                                            <CircularProgress size={20} thickness={5} />
                                        )}
                                    </Grid>
                                )}
                            </React.Fragment>
                        )
                    })}
                </Grid>
            </Stack>
            {!inProgress && (
                <DialogActions>
                    <DialogSecondaryButton onClick={() => closeModal(modal.key)}>Cancel</DialogSecondaryButton>
                    <Button onClick={handleConfirm} disabled={!hasSelected}>
                        Confirm Operation
                    </Button>
                </DialogActions>
            )}
            {inProgress && (
                <DialogActions>
                    <Button onClick={() => closeModal(modal.key)} disabled={!done}>
                        Done
                    </Button>
                </DialogActions>
            )}
        </>
    )
}

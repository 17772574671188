import { REQUEST_INVENTORY, REQUEST_LEADERBOARD } from '../constants'
import { client } from 'redux-socket-client'

export const loadInventory = client(() => ({
    type: REQUEST_INVENTORY,
    payload: {},
    subscribe: true,
}))

export const loadLeaderboard = client((group: string, stat: string) => ({
    type: REQUEST_LEADERBOARD,
    payload: { group, stat },
    subscribe: true,
}))

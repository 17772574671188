import { Button } from 'components/Button'
import { ForwardIcon } from 'components/icons'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { useIncrementActivityScore } from '../../../hooks/useIncrementActivityScore'
import { FeedEntry } from '../types/feedTypes'
import { FeedEntryMetaAction } from './FeedEntryMetaAction'

interface FeedEntryCtaProps {
    entry: FeedEntry
}

export const FeedEntryCta: React.FC<FeedEntryCtaProps> = ({ entry }) => {
    const t = useTranslate()
    const incrementActivityScore = useIncrementActivityScore()

    if (!entry.action || entry.action.actionType === 'vimeo') {
        return null
    }

    return (
        <div>
            <FeedEntryMetaAction entry={entry}>
                <Button
                    variant="outlined"
                    onClick={() => {
                        incrementActivityScore('news-feed:post:cta-click')
                        return true
                    }}
                    endIcon={<ForwardIcon />}
                >
                    {t(entry.action?.label as LocalisationKey) || t('general::more')}
                </Button>
            </FeedEntryMetaAction>
        </div>
    )
}

import { Text } from 'components/Text'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import { useProgressBarContext } from './ProgressBar'

interface ProgressBarCenteredTextProps {
    color?: string
    alternativeColor?: string
}

export const ProgressBarCenteredText: React.FC<ProgressBarCenteredTextProps> = ({
    color,
    alternativeColor,
    children,
}) => {
    const { value, max } = useProgressBarContext()

    return (
        <>
            <TextOnBackground $color={color}>{children}</TextOnBackground>
            <TextOnIndicator $color={alternativeColor} $ratio={value / max}>
                {children}
            </TextOnIndicator>
        </>
    )
}

const commonStyles = css`
    position: absolute;
    z-index: 2;
    font-weight: 600;
`

const TextOnIndicator = styled(Text)<{ $ratio: number; $color?: string }>`
    ${commonStyles}
    color: ${props => props.$color ?? props.theme.palette.primary.contrastText};
    text-shadow: 0px 0 3px rgba(0, 0, 0, 0.5);
    text-align: left;
    left: ${props => props.theme.spacing(4)};
    @supports (clip-path: polygon(0% 0%)) {
        left: 0;
        right: 0;
        text-align: center;
        text-shadow: none;
        clip-path: ${props => `polygon(0% 100%, 0% 0%, ${props.$ratio * 100}% 0%, ${props.$ratio * 100}% 100%)`};
    }
`

const TextOnBackground = styled(Text)<{ $color?: string }>`
    ${commonStyles}
    color: ${props => props.$color};
    left: 0;
    right: 0;
    text-align: center;
    display: none;
    @supports (clip-path: polygon(0% 0%)) {
        display: block;
    }
`

import { isGuestOrRegisteredGuestUser, isOpenDayUser, UserState, useUserState } from 'contexts/UserContext'
import { useCallback } from 'react'
import { Optional } from 'utils/typeUtils'

export type AppVariant = 'normal' | 'guest' | 'open-day'

export type AppVariantRecord<T> = Optional<Record<AppVariant, T>, 'guest' | 'open-day'>

export const getAppVariant = (user: UserState): AppVariant => {
    if (user.account.stage === 'user') {
        return isOpenDayUser(user) ? 'open-day' : 'normal'
    }
    if (isGuestOrRegisteredGuestUser(user)) {
        return 'guest'
    }
    throw new Error('Unsupported user type.')
}

const useAppVariant = () => {
    const user = useUserState()
    return getAppVariant(user)
}

export const useAppVariantContent = () => {
    const appVariant = useAppVariant()

    const appVariantContent = useCallback(
        <T>(variants: AppVariantRecord<T>) => {
            if (appVariant in variants) {
                return variants[appVariant]!
            }
            return variants['normal']
        },
        [appVariant],
    )

    return {
        appVariantContent,
    }
}

import { EventDefinition } from 'features/events/types/eventTypes'
import { ActivityEventWithGroupInfo } from '../types/activityEventTypes'

export const activityEventToEventDefinition = (
    event: ActivityEventWithGroupInfo,
    hasShowAdvancedStats: boolean,
): EventDefinition => {
    return {
        slug: event.slug,
        groupSlug: event.group,
        thumbnail: event.thumbnail,
        title: event.titleKey,
        closedTitle: event.closedTitleKey || event.titleKey,
        ongoing: !event.closed || (!!event.hasGroupInfo && (event.closedCount || 0) < (event.groupCount || 0)),
        banner: {
            image: event.banner,
            text: hasShowAdvancedStats ? event.slug : event.bannerTitleKey,
        },
        hasGroupInfo: event.hasGroupInfo,
        closedCount: event.closedCount,
        groupCount: event.groupCount,
    }
}

export const getTotalStatGroupForActivityEvent = (slug: string) => `ActivityEvent/${slug}`

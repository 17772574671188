import React from 'react'
import { CardHeader as MuiCardHeader, CardHeaderTypeMap } from '@material-ui/core'
import styled from 'styled-components/macro'

type CardHeaderProps = CardHeaderTypeMap['props']

export const CardHeader: React.FC<CardHeaderProps> = props => {
    return <Root {...props} />
}

const Root = styled(MuiCardHeader)`
    padding: ${props => props.theme.spacing(4)};
`

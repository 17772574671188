import React from 'react'
import { Popover } from '@material-ui/core'
import { usePopupMenuContext } from './popupMenuContext'
import styled from 'styled-components/macro'

export const PopupMenuList: React.FC<any> = ({ children, ...rest }) => {
    const { anchorEl, close } = usePopupMenuContext()
    return (
        <Root anchorEl={anchorEl} open={!!anchorEl} onClose={close} {...rest} >
            {children}
        </Root>
    )
}

const Root = styled(Popover)`
    .MuiPaper-root {
        box-shadow: ${props => props.theme.shadows[3]};
        padding: ${props => props.theme.spacing(2)};
    }
`

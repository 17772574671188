import { DialogSecondaryButton, DialogPrimaryButton, Dialog, DialogTitle } from 'components/dialog'
import { createHashDialog } from 'hooks/createHashDialog'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { AvatarBrowser } from './AvatarBrowser'

const [avatarBrowserDialogTo, AvatarBrowserDialogController] = createHashDialog('avatar-browser')

export { avatarBrowserDialogTo }

export const AvatarBrowserDialog: React.FC = () => {
    const t = useTranslate()

    return (
        <AvatarBrowserDialogController>
            {({ onClose, open }) => (
                <Dialog
                    fullWidth
                    PaperProps={{ style: { height: '80%' } }}
                    maxWidth={'md'}
                    open={open}
                    onClose={onClose}
                >
                    <DialogTitle>{t('settings::chooseProfilePicture')}</DialogTitle>
                    <AvatarBrowser
                        isOnboarding={false}
                        onClose={onClose}
                        renderActions={({ primaryButtonProps, secondaryButtonProps }) => (
                            <>
                                <DialogSecondaryButton {...secondaryButtonProps}>
                                    {t('general::cancel')}
                                </DialogSecondaryButton>
                                <DialogPrimaryButton {...primaryButtonProps}>{t('general::save')}</DialogPrimaryButton>
                            </>
                        )}
                    />
                </Dialog>
            )}
        </AvatarBrowserDialogController>
    )
}

export const EventsCampaigns = {
    DRAWING_CONTEST_2019: {
        dev: '5de697bec4e11600114125fa',
        beta: '5de697bec4e11600114125fa',
        prod: '5de697bec4e11600114125fa',
    },
    DRAWING_CONTEST_2020: {
        dev: '5fc9136301fe0d0009f7d046',
        beta: '5fc9136301fe0d0009f7d046',
        prod: '5fc9136301fe0d0009f7d046',
    },
    DRAWING_CONTEST_2021: {
        dev: '61ae0a55ae4415796a554671',
        beta: '61ae0a55ae4415796a554671',
        prod: '61ae0a55ae4415796a554671',
    },
    TRAINER_CONTEST_2020: {
        dev: '5f193d5953b1f0000a3a977b',
        beta: '5f193d5953b1f0000a3a977b',
        prod: '5f193d5953b1f0000a3a977b',
    },
}
import { FormLabel, TextField, TextFieldProps } from 'components/form'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { visuallyHidden } from 'utils/styleUtils'

interface SecretCodeInputProps extends TextFieldProps {}

const HAIR_SPACE = ' '

export const SecretCodeInput = React.forwardRef<any, SecretCodeInputProps>((props, ref) => {
    const t = useTranslate()

    return (
        <Stack>
            <Text>{t('secretCode::enter')}</Text>
            <StyledLabel htmlFor="secret-code-input">{t('secretCode::label')}</StyledLabel>
            <StyledTextField
                id="secret-code-input"
                autoFocus
                inputProps={{ maxLength: 6 }}
                placeholder={`${HAIR_SPACE}••••••`}
                inputRef={ref}
                {...props}
            />
        </Stack>
    )
})

const StyledLabel = styled(FormLabel)`
    ${visuallyHidden}
`

const StyledTextField = styled(TextField)`
    font-size: 1.5rem;
    letter-spacing: 0.5em;
    font-weight: 600;

    & input {
        text-align: center;
        text-transform: uppercase;
    }
`

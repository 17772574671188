import React from 'react'
import { Box, BoxProps } from './Box'

interface FlexProps extends BoxProps {
    direction?: BoxProps['flexDirection']
    justify?: BoxProps['justifyContent']
    align?: BoxProps['alignItems']
}

export const Flex: React.FC<FlexProps> = ({ direction, justify, align, ...rest }) => {
    return <Box display="flex" flexDirection={direction} justifyContent={justify} alignItems={align} {...rest} />
}

import { combineReducers } from 'redux'
import { LeaderboardState, leaderboardReducer as stats } from './leaderboard'

interface SharedState {
    stats: LeaderboardState
}

export const sharedReducer = combineReducers<SharedState>({
    stats,
})

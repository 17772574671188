import React from 'react'
import { Img } from 'components/Img'
import styled from 'styled-components/macro'
import { Modal } from 'components/Modal'

type Props = {
    src: string
    size: string
}

export const ImageDialogue: React.FC<Props> = ({ src, size }) => {
    const [isOpen, setOpen] = React.useState<boolean>(false)

    return (
        <>
            <ThumbImg src={src} width={size} height={size} onClick={() => setOpen(true)} />
            <Modal open={isOpen} onClose={() => setOpen(false)} aria-labelledby="quiz-image">
                <Paper onClick={() => setOpen(false)}>
                    <LargeImg src={src} />
                </Paper>
            </Modal>
        </>
    )
}

const ThumbImg = styled(Img)`
    object-fit: cover;
    cursor: pointer;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    ${props => props.theme.breakpoints.down('xs')} {
        width: 100%;
        height: auto;
        max-height: 12rem;
    }
`

const Paper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const LargeImg = styled(Img)`
    cursor: pointer;
    height: auto;
    width: auto;
    max-width: 90vw;
    max-height: 90vh;
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

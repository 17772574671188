import React, { createContext, useContext } from 'react'
import { FormControl, FormLabel } from 'components/form'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import { Stack, Box } from 'components/layout'
import { visuallyHidden } from 'utils/styleUtils'
import { WrappingInline } from 'components/layout/WrappingInline'

interface SelectBoxProps {
    value?: string
    onChange?: (value: string) => void
    name?: string
    disabled?: boolean
    label?: string
    onClick?: (value?: string) => void
}

const SelectBoxContext = createContext<SelectBoxProps | undefined>(undefined)

export const SelectBox: React.FC<SelectBoxProps> = ({
    children,
    value,
    name,
    onChange,
    disabled,
    label,
    onClick,
    ...rest
}) => {
    return (
        <SelectBoxContext.Provider value={{ value, onChange, name, disabled, onClick }}>
            <Root {...rest}>
                <StyledFormControl>
                    {label && <FormLabel as="legend">{label}</FormLabel>}
                    <div>
                        <WrappingInline>{children}</WrappingInline>
                    </div>
                </StyledFormControl>
            </Root>
        </SelectBoxContext.Provider>
    )
}

const StyledFormControl = styled(FormControl)`
    width: 100%;
`

const Root = styled.fieldset`
    margin: 0;
    padding: 0;
    border: none;
`

type SelectBoxItemProps = {
    icon?: JSX.Element
    value: string
    fullWidth?: boolean
    className?: string
    innerClassName?: string
    direction?: 'column' | 'row'
}

export const SelectBoxItem: React.FC<SelectBoxItemProps> = ({
    value,
    children,
    icon,
    fullWidth,
    innerClassName,
    direction = 'column',
    ...props
}) => {
    const context = useSelectBoxContext()

    return (
        <ItemLabel fullwidth={fullWidth} {...props}>
            <input
                type="radio"
                checked={value === context.value}
                value={value}
                disabled={context.disabled}
                name={context.name}
                onChange={e => {
                    context.onChange?.(e.target.value)
                }}
                onClick={() => {
                    context.onClick?.(context.value)
                }}
            />
            <ItemInner $direction={direction} spacing={direction === 'column' ? 2 : 0} className={innerClassName}>
                {icon && (
                    <Box lineHeight="0" fontSize="3rem">
                        {icon}
                    </Box>
                )}
                <Box fontSize="1rem">{children}</Box>
            </ItemInner>
        </ItemLabel>
    )
}

export function useSelectBoxContext() {
    const context = useContext(SelectBoxContext)

    if (!context) {
        throw new Error('useSelectBoxContext must be used within a SelectBox.')
    }

    return context
}

const ItemLabel = styled.label<{ fullwidth?: boolean }>`
    display: inline-flex;
    flex: ${props => (props.fullwidth ? 1 : undefined)};
    input {
        ${visuallyHidden}
    }
`

const ItemInner = styled(Stack)<{ $direction: 'row' | 'column' }>`
    display: flex;
    flex-direction: ${props => props.$direction};
    justify-content: center;
    gap: ${props => (props.$direction === 'row' ? props.theme.spacing(4) : undefined)};
    align-items: center;
    text-align: center;
    width: 100%;
    padding: ${props => props.theme.spacing(2)};
    background-color: ${props => props.theme.palette.background.paper};
    border: solid 1px;
    border-color: ${props => props.theme.palette.divider};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    color: ${props => transparentize(0.3, props.theme.palette.text.primary)};
    cursor: pointer;
    &:hover {
        border-color: ${props => props.theme.palette.text.secondary};
    }
    input:focus-visible + & {
        outline: solid 1px ${props => props.theme.palette.primary.main};
    }
    input:checked + & {
        background-color: ${props => transparentize(0.9, props.theme.palette.primary.main)};
        border-color: ${props => props.theme.palette.primary.main};
        box-shadow: 0 0 0 1px ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.main};
    }
`

import React from 'react'
import { CircularProgress } from '@material-ui/core'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'

interface LoaderProps {
    className?: string
    size?: string
}

export const Loader: React.FC<LoaderProps> = ({ size, ...rest }) => {
    return (
        <Root {...rest}>
            <Bottom variant="determinate" value={100} size={size ?? 35} thickness={5} />
            <CircularProgress size={size ?? 35} thickness={5} />
        </Root>
    )
}

const Root = styled.div`
    display: flex;
`

const Bottom = styled(CircularProgress)`
    position: absolute;
    color: ${props => transparentize(0.7, props.theme.palette.primary.main)};
`

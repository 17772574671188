import { FOLLOW_USER, UNFOLLOW_USER } from '../constants'
import { client } from 'redux-socket-client'

export const followUser = client((target: string) => ({
    type: FOLLOW_USER,
    payload: { target },
}))

export const unFollowUser = client((target: string) => ({
    type: UNFOLLOW_USER,
    payload: { target },
}))

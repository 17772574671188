import { ErrorBoundary } from 'components/ErrorBoundary'
import { ErrorMessage } from 'components/ErrorMessage'
import { Stack } from 'components/layout'
import { PageContent } from 'components/page'
import { useHasShowAdvancedStats } from 'contexts/UserContext'
import dayjs from 'dayjs'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { Suspense } from 'react'
import { useRouteMatch, Switch, Route } from 'react-router-dom'
import { activityEventToEventDefinition } from '../activity-events/helpers/activityEventHelpers'
import { ActivityEvent, ActivityEventWithGroupInfo } from '../activity-events/types/activityEventTypes'
import { EventDefinition } from '../types/eventTypes'
import { EventDetailsBreadcrumb } from './details/EventDetailsBreadcrumb'
import { EventDetailsBase } from './EventDetailsBase'
import { EventList } from './EventList'
import { EventLoader } from './EventLoader'
import { ScrollRestoration } from 'lib/router/Scrollrestoration'
import { isManagableEvent, ManagableEventDefinition } from '../types/eventTypesV2'
import { useEventsData } from '../useEventsData'
import { CampChallengeDynamicPage } from '../camp-challenge/components/CampChallengePage'
import { ActivityEventContextProvider } from '../activity-events/components/ActivityEventContextProvider'

const DigitalMission2020Page = React.lazy(() => import('../pages/digital-misson-2020'))
const TrainerContest2020Page = React.lazy(() => import('../pages/trainer-contest-2020'))
const Digigirlz2020Page = React.lazy(() => import('../pages/digigirlz-2020'))
const DigitalMission2021Page = React.lazy(() => import('../pages/digital-mission-2021'))
const TrainerContest2021Page = React.lazy(() => import('../pages/trainer-contest-2021'))
const OnboardingPage = React.lazy(() => import('../pages/onboarding'))
const ActivityEventPage = React.lazy(() => import('../activity-events/components/ActivityEventDetails'))
const CampChallengePage = React.lazy(() => import('../camp-challenge/components/CampChallengePage'))
const EventTemplateView = React.lazy(() => import('../components/template'))

const eventSlugComponentMap: Record<string, React.FC<{ event: EventDefinition }>> = {
    'digital-mission-2021': DigitalMission2021Page,
    'digital-mission-2020': DigitalMission2020Page,
    'trainer-contest-2020': TrainerContest2020Page,
    'trainer-contest-2021': TrainerContest2021Page,
    'camp-challenge-2023': CampChallengePage,
    'digigirlz-2020': Digigirlz2020Page,
    'mylogiscool-mission': OnboardingPage,
}

const eventTemplateToComponentMap: Record<string, React.FC<{ event: ManagableEventDefinition }>> = {
    'drawing-contest': EventTemplateView,
    'camp-challenge': CampChallengeDynamicPage,
}

const normalizeEventList = (
    events: (EventDefinition | ManagableEventDefinition)[],
    activityEvents: ActivityEvent[],
    isAdvancedStatsTurnedOn: boolean,
) => {
    const groupedActivityEvents: ActivityEventWithGroupInfo[] = []
    for (const activityEvent of activityEvents) {
        if (activityEvent.group) {
            const groupEntry = groupedActivityEvents.find(event => event.group === activityEvent.group)
            if (groupEntry) {
                groupEntry.hasGroupInfo = true
                groupEntry.closedCount = (groupEntry.closedCount || 0) + (activityEvent.closed ? 1 : 0)
                groupEntry.groupCount = (groupEntry.groupCount || 0) + 1
            } else {
                let newEntry: ActivityEventWithGroupInfo = { ...activityEvent }
                newEntry.closedCount = activityEvent.closed ? 1 : 0
                newEntry.groupCount = 1
                groupedActivityEvents.push(newEntry)
            }
        } else {
            groupedActivityEvents.push(activityEvent)
        }
    }

    return [
        ...groupedActivityEvents.map(event => activityEventToEventDefinition(event, isAdvancedStatsTurnedOn)),
        ...events,
    ].sort((a, b) => {
        if (!a.startDate || b.startDate) {
            return 0
        }
        const startDateA = dayjs(a.startDate)
        const startDateB = dayjs(b.startDate)
        if (startDateA.isSame(startDateB)) {
            return 0
        }
        return startDateA.isBefore(startDateB) ? 1 : -1
    })
}

export const FullEventsRouter: React.FC = () => {
    const { path } = useRouteMatch()
    const hasShowAdvancedStats = useHasShowAdvancedStats()
    const events = useEventsData()

    const normalisedEventList = React.useMemo(
        () => (events ? normalizeEventList(events.normalEvents, events.activityEvents, hasShowAdvancedStats) : []),
        [events, hasShowAdvancedStats],
    )

    if (!events) {
        return <EventLoader />
    }

    return (
        <Switch>
            <Route
                path={path}
                exact
                render={() => (
                    <>
                        <ScrollRestoration />
                        <PageContent>
                            <EventList events={normalisedEventList} />
                        </PageContent>
                    </>
                )}
            ></Route>
            {events.activityEvents.map(event => (
                <Route
                    key={event.slug}
                    path={`${path}/${event.slug}`}
                    render={() => {
                        if (event.template) {
                            return (
                                <ActivityEventContextProvider
                                    event={event}
                                    allActivityEvents={events.activityEvents}
                                    fallback={<EventLoader />}
                                >
                                    <ManagableEventDetail event={(event as any).managableEvent} />
                                </ActivityEventContextProvider>
                            )
                        } else {
                            return (
                                <Suspense fallback={<EventLoader />}>
                                    <ActivityEventPage event={event} allActivityEvents={events.activityEvents} />
                                </Suspense>
                            )
                        }
                    }}
                />
            ))}
            {events.normalEvents.map(event => (
                <Route
                    key={event.slug}
                    path={`${path}/${event.slug}`}
                    render={() => {
                        if (isManagableEvent(event)) {
                            return <ManagableEventDetail event={event} />
                        } else {
                            return <EventDetail event={event} />
                        }
                    }}
                />
            ))}
            <Route render={() => <EventNotFound />} />
        </Switch>
    )
}

export const GuestEventsRouter: React.FC = () => {
    const { path } = useRouteMatch()
    const events = useEventsData()

    if (!events) {
        return <EventLoader />
    }

    const onboardingEvent = events.normalEvents.find(event => event.slug === 'mylogiscool-mission')

    return (
        <Switch>
            {onboardingEvent && (
                <Route
                    path={`${path}/${onboardingEvent.slug}`}
                    render={() => {
                        return <EventDetail event={onboardingEvent as EventDefinition} />
                    }}
                />
            )}
            <Route render={() => <EventNotFound />} />
        </Switch>
    )
}

const ManagableEventDetail: React.FC<{ event: ManagableEventDefinition }> = ({ event }) => {
    const Component = eventTemplateToComponentMap[event.template]

    if (!Component) {
        return <EventNotFound />
    }

    return (
        <Suspense fallback={<EventLoader />}>
            <ErrorBoundary
                fallback={
                    <EventDetailsBase event={event}>
                        <ErrorMessage />
                    </EventDetailsBase>
                }
            >
                <Component event={event} />
            </ErrorBoundary>
        </Suspense>
    )
}

const EventDetail: React.FC<{ event: EventDefinition }> = ({ event }) => {
    const Component = eventSlugComponentMap[event.slug]

    if (!Component) {
        return <EventNotFound />
    }

    return (
        <Suspense fallback={<EventLoader />}>
            <ErrorBoundary
                fallback={
                    <EventDetailsBase event={event}>
                        <ErrorMessage />
                    </EventDetailsBase>
                }
            >
                <Component event={event} />
            </ErrorBoundary>
        </Suspense>
    )
}

const EventNotFound: React.FC = () => {
    const t = useTranslate()

    return (
        <PageContent>
            <Stack>
                <EventDetailsBreadcrumb />
                <ErrorMessage>{t('events::notFound')}</ErrorMessage>
            </Stack>
        </PageContent>
    )
}

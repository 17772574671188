import { Authorize } from 'components/Authorize'
import { Stack } from 'components/layout'
import { useHasShowAdvancedStats } from 'contexts/UserContext'
import { useDateFormat } from 'hooks/useDateFormat'
import { useLanguagesQuery } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { useProjectModalContext } from '../modal/projectModalContext'
import { useBlockGalleriesQuery } from '../projectQueries'
import { Project } from '../store'
import { Divider } from 'components/Divider'
import { secondsToHHMMSS } from 'utils/timeUtils'
import {PYTHON_PALETTE_ID} from "../createProjectFormData";
import {useHasGrant} from "../../../lib/grants";
import {LANGUAGE_TO_LABEL} from "../my/MyProjectLanguage";

export const ProjectDetailsStats: React.FC = () => {
    const { project } = useProjectModalContext()
    const { formatDate } = useDateFormat('precise')
    const { data: blockGalleries } = useBlockGalleriesQuery()
    const t = useTranslate()
    const hasGrant = useHasGrant();

    if (!project) {
        return null
    }

    const palette = (() => {
        const blockGallery = blockGalleries?.data.find(gallery => gallery.id === project.file.metadata?.palette)
        return blockGallery?.name
    })()

    const language = LANGUAGE_TO_LABEL[
        project.file.metadata?.language
        || (project.file.metadata?.palette === PYTHON_PALETTE_ID ? 'python' : 'stagescript')
    ];

    const isLanguageEnabled =
        project.file.kind.includes('mix') &&
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-python' }) &&
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-stagescript' });

    return (
        <Stack component="dl">
            {!project.metadata.isMiniQuiz && (
                <>
                    <Stat name={t('projects::type')} value={project.file.kind} />
                    <Authorize grant={{ product: 'edup.scoolcode.ide', feature: 'advanced-project-thumbnail' }}>
                        {!project.file.kind.includes('mix') && <Stat name={t('projects::palette')} value={palette} />}
                    </Authorize>
                    {isLanguageEnabled && <Stat name={t('projects::language::label')} value={language} />}
                </>
            )}
            <Stat name={t('general::createdAt')} value={formatDate(new Date(project.file.createdAt!))} />
            <Stat name={t('general::modifiedAt')} value={formatDate(new Date(project.file.updatedAt!))} />
            <AdvancedProjectMetadata project={project} />
        </Stack>
    )
}

export const SharedProjectDetailsStats: React.FC = () => {
    const { project } = useProjectModalContext()
    const { formatDate } = useDateFormat('long')
    const { data: languages } = useLanguagesQuery()
    const t = useTranslate()
    const hasShowAdvancedView = useHasShowAdvancedStats()

    if (!project) {
        return null
    }

    return (
        <Stack component="dl">
            <Stat
                name={t('general::language')}
                value={languages?.data.find(lang => lang.code === project.file.lang)?.displayName}
            />
            <Stat name={t('projects::favorited')} value={project.file.likedCount} />
            <Stat
                name={t('projects::views')}
                value={(project.file.viewedCount ?? 0) + (project.file.publicViewedCount ?? 0)}
            />
            {hasShowAdvancedView && (
                <>
                    <Stat name={t('projects::playedCount')} value={project.file.playedCount} />
                    <Stat name={t('projects::publicView')} value={project.file.publicViewedCount} />
                </>
            )}
            <Stat name={t('projects::sharedAt')} value={formatDate(new Date(project.file.approvedAt!))} />
            <AdvancedProjectMetadata project={project} />
        </Stack>
    )
}

const defaultStatFormatter = (value: any) => {
    return Array.isArray(value) ? value.join(', ') : value
}

const advancedMetadataFields: Array<{
    name: keyof NonNullable<Project['file']['metadata']>
    formatter?: (value: any) => any
}> = [
    { name: 'userType' },
    { name: 'groupId' },
    { name: 'groupName' },
    { name: 'teacherIds' },
    { name: 'teacherNames' },
    { name: 'lessonNumber' },
    { name: 'subjectKey' },
    { name: 'totalParticipants' },
    { name: 'schoolId' },
    { name: 'createMode' },
    { name: 'createType' },
    { name: 'changeCount' },
    { name: 'timeSpent', formatter: secondsToHHMMSS },
]
const AdvancedProjectMetadata: React.FC<{ project: Project }> = ({ project }) => {
    const hasShowAdvancedView = useHasShowAdvancedStats()

    if (!hasShowAdvancedView) {
        return null
    }

    const stats = advancedMetadataFields
        .map(field => {
            const fieldValue = project.file.metadata?.[field.name]
            return {
                name: field.name,
                value: field.formatter ? field.formatter(fieldValue) : defaultStatFormatter(fieldValue),
            }
        })
        .filter(({ value }) => value !== undefined && value !== '')

    if (stats.length === 0) {
        return null
    }

    return (
        <>
            <Divider />
            {stats.map(({ name, value }) => {
                return <Stat key={name} name={name} value={value} />
            })}
        </>
    )
}

const Stat: React.FC<{ name: string; value?: string | number | boolean }> = ({ name, value }) => {
    return (
        <StatRoot>
            <dt>{name}</dt>
            <dd>{value ?? '-'}</dd>
        </StatRoot>
    )
}

const StatRoot = styled.div`
    font-size: ${props => props.theme.typography.body2.fontSize};
    dt {
        color: ${props => props.theme.palette.text.secondary};
    }
    dd {
        font-weight: 600;
        margin: 0;
        word-break: break-word;
    }
`

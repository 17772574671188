import React from "react";
import {TextField} from "components/form";

export interface NumberFieldProps {
    label: string,
    value?: number,
    onChange?: (e: any, newValue: number) => any
}
export const NumberField: React.FC<NumberFieldProps & any> = ({ value, onChange, children, multiline, ...rest }) => {
    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        let realValue = +e.target.value
        if(typeof rest.min === "number") realValue = Math.max(rest.min, realValue);
        if(typeof rest.max === "number") realValue = Math.min(rest.max, realValue);
        if(onChange) onChange(e, realValue);
    }

    return (
        <TextField value={value} type="number" onChange={handleChange} {...rest} />
    )
}
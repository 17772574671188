import { ActionCard } from 'components/action-card'
import { CardContent } from 'components/card'
import { ErrorMessage } from 'components/ErrorMessage'
import { CheckIcon, FastForwardIcon, LeftAngleIcon, PlayIcon, RightAngleIcon } from 'components/icons'
import { Img } from 'components/Img'
import { Inline, Stack } from 'components/layout'
import { Loader } from 'components/Loader'
import { Text } from 'components/Text'
import { ApiTypes } from 'lib/api'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { useQuizSetResultsQuery, useQuizSetsQuery, useQuizSetSessionsQuery } from './homeQuizQueries'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { useLocalisedQuizValue } from '../quizHelpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Theme, useMediaQuery } from '@material-ui/core'
import { Button } from 'components/Button'
import fallbackThumbnail from './fallback-thumbnail.png'
import { motion } from 'framer-motion'
import { NormalizedQuizSetSessions, QuizSetWithEmbeddedTemplates } from '../QuizApi'
import { LocalisationKey } from 'lib/i18n'
import { useGetFinishedTemplatesStats } from '../quizQueries'
import { useHasShowAdvancedStats } from 'contexts/UserContext'
import { ScrollRestoration } from 'lib/router/Scrollrestoration'

export const HomeQuizDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>()
    const { data: quizSets, status: quizSetStatus } = useQuizSetsQuery()
    const { data: quizSetSessions, status: quizSetSessionsStatus } = useQuizSetSessionsQuery(quizSets)
    const t = useTranslate()
    const set = quizSets?.find(set => set.id === id)

    if (quizSetStatus === 'error' || quizSetSessionsStatus === 'error') {
        return <ErrorMessage />
    }

    if (quizSetStatus === 'success' && (!set || set.locked)) {
        return <ErrorMessage>{t('quiz::notFound')}</ErrorMessage>
    }

    if (!set || !quizSetSessions) {
        return <Loader />
    }

    return <HomeQuizDetailsInner set={set} sessions={quizSetSessions} />
}

interface HomeQuizDetailsInnerProps {
    set: QuizSetWithEmbeddedTemplates
    sessions: NormalizedQuizSetSessions
}

const HomeQuizDetailsInner: React.FC<HomeQuizDetailsInnerProps> = ({ set, sessions }) => {
    const t = useTranslate()
    const relevantSessions = extractRelevantSessionsForSet(set, sessions)

    const { data: results } = useQuizSetResultsQuery(relevantSessions)
    const hasShowAdvancedView = useHasShowAdvancedStats()

    const { data: finishedCount } = useGetFinishedTemplatesStats(set.id, hasShowAdvancedView)

    return (
        <>
            <ScrollRestoration />
            <Stack>
                <Breadcrumbs>
                    <Button variant="text" component={Link} startIcon={<LeftAngleIcon />} to="/app/challenges/quizzes">
                        {t('quiz::all')}
                    </Button>
                </Breadcrumbs>
                <div>
                    <Text variant="h2">{t(set.name as LocalisationKey)}</Text>
                    {set.tags && (
                        <Text variant="body2" color="textSecondary">
                            {set.tags.map(tag => t(tag as LocalisationKey)).join(', ')}
                        </Text>
                    )}
                </div>
                <Grid>
                    <Stack spacing={2}>
                        <Thumbnail src={set.thumbnail || fallbackThumbnail} />
                        <Text>{t(set.description as LocalisationKey)}</Text>
                    </Stack>
                    <Stack>
                        {set.templates.map((template, i) => {
                            const session = sessions[template.id]
                            return (
                                <TemplateItem
                                    key={i}
                                    status={session?.status}
                                    sessionId={session?.sessionId}
                                    score={results?.find(result => result.session === session?.sessionId)?.finalScore}
                                    template={template}
                                    finishedCount={
                                        !hasShowAdvancedView ? undefined : finishedCount && finishedCount[template.id]
                                    }
                                />
                            )
                        })}
                    </Stack>
                </Grid>
            </Stack>
        </>
    )
}

interface TemplateItemProps {
    status?: 'finished' | 'started'
    score?: number
    template: ApiTypes['quiz-template']
    finishedCount?: number
    sessionId?: string
}

const TemplateItem: React.FC<TemplateItemProps> = ({ status, template, score, finishedCount, sessionId }) => {
    const { id } = useParams<{ id: string }>()
    const { translateQuizLocaleString } = useLocalisedQuizValue()
    const t = useTranslate()
    const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('xs'))

    const rootProps =
        status === 'finished'
            ? {
                  type: 'link',
                  to: `/app/challenges/quizzes/${id}/result/${sessionId}`,
              }
            : {
                  type: 'link',
                  to: `/app/quiz/${template.id}`,
              }

    return (
        // @ts-ignore
        <TemplateItemRoot {...rootProps}>
            <Content>
                <Inline display="flex" alignItems="center">
                    <IconWrap>
                        {status === 'finished' && <FinishedIcon />}
                        {status === 'started' && <ContinueIcon />}
                        {status === undefined && <StartIcon />}
                    </IconWrap>
                    <Text>
                        {translateQuizLocaleString(template.name)}
                        <TemplateItemMetadata>
                            <> • </>
                            {status === 'finished' ? (
                                <>
                                    <motion.span
                                        initial={{ opacity: score === undefined ? 0 : 1 }}
                                        animate={{ opacity: score === undefined ? 0 : 1 }}
                                    >
                                        {t('general::nPoints', { count: score })}
                                    </motion.span>
                                </>
                            ) : (
                                t('general::nQuestions', { count: template.questions?.length ?? 0 })
                            )}
                        </TemplateItemMetadata>
                        {finishedCount !== undefined ? (
                            <Text style={{ marginLeft: '0.5rem' }} variant="caption">
                                <strong>{finishedCount}</strong>
                            </Text>
                        ) : null}
                    </Text>
                </Inline>
                {
                    <ActionWrap component="span" spacing={2}>
                        {!isSmallScreen && (
                            <span>
                                {status === 'finished' && t('quiz::myAnswers')}
                                {status === 'started' && t('general::continue')}
                                {status === undefined && t('quiz::startQuiz')}
                            </span>
                        )}{' '}
                        <RightAngleIcon />
                    </ActionWrap>
                }
            </Content>
        </TemplateItemRoot>
    )
}

function extractRelevantSessionsForSet(set: QuizSetWithEmbeddedTemplates, sessions: NormalizedQuizSetSessions) {
    const result: string[] = []
    for (const [templateId, { sessionId }] of Object.entries(sessions)) {
        if (set.templates.find(template => template.id === templateId)) {
            if (sessionId) {
                result.push(sessionId)
            }
        }
    }
    return result
}

const TemplateItemMetadata = styled.span`
    opacity: 0.5;
`

const TemplateItemRoot = styled(ActionCard)`
    display: block;
    width: 100%;
`

const IconWrap = styled.span`
    > * {
        vertical-align: middle;
    }
`

const iconBaseStyles = css`
    font-size: 1.5rem;
`

const FinishedIcon = styled(CheckIcon)`
    ${iconBaseStyles}
    color: ${props => props.theme.palette.success.main};
`

const StartIcon = styled(PlayIcon)`
    ${iconBaseStyles}
    color: ${props => props.theme.palette.primary.main};
`

const ContinueIcon = styled(FastForwardIcon)`
    ${iconBaseStyles}
    color: ${props => props.theme.palette.primary.main};
`

const Content = styled(CardContent)`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing(4)};
    justify-content: space-between;
`

const Thumbnail = styled(Img)`
    border-radius: ${props => props.theme.shape.borderRadius}px;
    ${props => props.theme.breakpoints.down('md')} {
        display: block;
        max-width: 30rem;
    }
    ${props => props.theme.breakpoints.down('sm')} {
        margin-left: auto;
        margin-right: auto;
    }
    ${props => props.theme.breakpoints.down('xs')} {
        max-width: 100%;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 4fr 5fr;
    grid-gap: ${props => props.theme.spacing(6)};
    ${props => props.theme.breakpoints.down('md')} {
        grid-template-columns: 1fr;
    }
`

const ActionWrap = styled(Inline)`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-weight: 600;
    justify-content: flex-end;
    text-align: right;
    color: ${props => props.theme.palette.primary.main};
`

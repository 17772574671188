import { api } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useNotifications } from 'features/notifications'
import React, { useEffect, useState } from 'react'
import { useProjectModalContext } from '../modal/projectModalContext'
import { createProjectThumbnailUrl } from '../projectHelpers'
import { ProjectThumbnailInput } from '../ProjectThumbnailInput'
import { useDispatch } from 'react-redux'
import { updateHasUserThumbnail } from '..'

interface ProjectDetailsThumbnailProps {}

type Status = { name: 'idle'; value: string } | { name: 'loading'; value: string }

export const ProjectDetailsThumbnail: React.FC<ProjectDetailsThumbnailProps> = () => {
    const { iframeRef, id, project } = useProjectModalContext()
    const [status, setStatus] = useState<Status>({ name: 'idle', value: createProjectThumbnailUrl(id, 'normal') })
    const { notify } = useNotifications()
    const t = useTranslate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (status.name === 'loading') {
            let ignore = false
            api.projects
                .updateProjectThumbnail(id, status.value)
                .then(() => {
                    if (ignore) return
                    setStatus(status => ({ ...status, name: 'idle' }))
                    if (!project?.file.hasUserThumbnail) {
                        dispatch(updateHasUserThumbnail(id))
                    }
                })
                .catch(() => {
                    if (ignore) return
                    notify(t('error::somethingWentWrong'), { variant: 'error' })
                    setStatus(status => ({ ...status, name: 'idle' }))
                })
            return () => {
                ignore = true
            }
        }
    }, [status, id, t, notify, dispatch, project?.file.hasUserThumbnail])

    if (!project || project.metadata.isMiniQuiz) {
        return null
    }

    return (
        <ProjectThumbnailInput
            value={status.value}
            isLoading={status.name === 'loading'}
            onChange={value => {
                setStatus({ name: 'loading', value })
            }}
            embedIframeRef={iframeRef}
            buttonText={t('general::update')}
        />
    )
}

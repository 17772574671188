import produce from 'immer'
import { Reducer } from 'redux'
import { loadEvents } from './eventsActions'
import { INIT_EVENTS } from './eventsActionTypes'
import { ActivityEvent } from '../activity-events/types/activityEventTypes'
import { ManagableEventDefinition } from '../types/eventTypesV2'
import { EventDefinition } from '../types/eventTypes'

type EventsState = {
    activityEvents: ActivityEvent[]
    normalEvents: (ManagableEventDefinition | EventDefinition)[]
    hasEventsGrant: boolean
}

type EventsAction = ReturnType<typeof loadEvents>

export const eventsReducer: Reducer<EventsState, EventsAction> = (
    state = {
        activityEvents: [],
        normalEvents: [],
        hasEventsGrant: false,
    },
    action,
) => {
    if (action.type === INIT_EVENTS) {
        return produce(state, draft => {
            draft.activityEvents = action.payload.activityEvents
            draft.normalEvents = action.payload.normalEvents
            draft.hasEventsGrant = action.payload.hasEventsGrant
        })
    }
    return state
}

import React from 'react'
import { Location } from 'history'
import { useHistory, useLocation } from 'react-router-dom'

interface createHashDialogOptions {
    useLocationState?: boolean
}

export function createHashDialog(id: string, options?: createHashDialogOptions) {
    interface ControllerProps {
        children: (context: { open: boolean; onClose: () => void }) => React.ReactNode
    }

    function useDialog() {
        const location = useLocation()
        const history = useHistory()

        return {
            open: location.hash === `#${id}`,
            onClose: () => {
                const newLocation = { ...location, hash: undefined }
                if (options?.useLocationState === true) {
                    newLocation.state = undefined
                }
                history.replace(newLocation)
            },
        }
    }

    const Controller: React.FC<ControllerProps> = ({ children }) => {
        const { open, onClose } = useDialog()

        return <>{children({ open, onClose })}</>
    }

    const createTo = ({ search, state }: Location) => {
        const to: Partial<Location> = {
            hash: id,
            search,
        }

        if (options?.useLocationState === true) {
            to.state = state
        }

        return to
    }

    return [createTo, Controller] as const
}

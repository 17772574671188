import { combineReducers } from 'redux'
import { currencyReducer as currency, CurrencyState } from './currency'
import { inventoryReducer as inventory, InventoryState } from './inventory'
import { statisticReducer as statistics, StatisticState } from './statistic'
import { readyReducer as ready, ReadyState } from './ready'
import { voteReducer as vote, VoteState } from './vote'
import { sessionReducer as session, SessionState } from './session'
import { notificationsReducer as notifications, NotificationsState } from './notifications'
import { achievementsReducer as achievements, AchievementsState } from './achievement'

interface ClientState {
    currency: CurrencyState
    inventory: InventoryState
    statistics: StatisticState
    ready: ReadyState
    vote: VoteState
    session: SessionState
    notifications: NotificationsState
    achievements: AchievementsState
}

export const clientReducer = combineReducers<ClientState>({
    currency,
    inventory,
    statistics,
    ready,
    vote,
    session,
    notifications,
    achievements,
})

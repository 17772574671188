import React from 'react'
import {
    Dialog as MuiDialog,
    DialogProps as MuiDialogProps,
    Backdrop as MuiBackdrop,
    DialogContent,
} from '@material-ui/core'
import styled from 'styled-components/macro'
import { Paper } from '@material-ui/core'
import { IconButton, IconButtonProps } from 'components/IconButton'
import { CloseIcon } from 'components/icons'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { DialogTitle } from './DialogTitle'
import { ErrorMessage } from 'components/ErrorMessage'
import { NBSP } from 'utils/styleUtils'

export interface DialogProps extends MuiDialogProps {
    hideCloseButton?: boolean
    closeButtonProps?: IconButtonProps
    fullHeight?: boolean
}

export const Dialog: React.FC<DialogProps> = ({
    children,
    onClose,
    hideCloseButton,
    closeButtonProps,
    PaperProps,
    fullHeight,
    ...rest
}) => {
    return (
        <MuiDialog
            BackdropComponent={Backdrop}
            transitionDuration={0}
            PaperComponent={StyledPaper}
            onClose={onClose}
            PaperProps={{ ...PaperProps, style: { height: fullHeight ? '100%' : undefined, ...PaperProps?.style } }}
            {...rest}
        >
            <ErrorBoundary fallback={<DialogErrorFallback />}>{children}</ErrorBoundary>
            {!hideCloseButton && (
                <CloseButton
                    onClick={e => {
                        onClose?.(e, 'escapeKeyDown')
                    }}
                    {...closeButtonProps}
                >
                    <CloseIcon />
                </CloseButton>
            )}
        </MuiDialog>
    )
}

const DialogErrorFallback: React.FC = () => {
    return (
        <>
            <DialogTitle>{NBSP}</DialogTitle>
            <DialogContent>
                <ErrorMessage />
            </DialogContent>
        </>
    )
}

const Backdrop = styled(MuiBackdrop)`
    ${props => props.theme.extras?.dialog?.backdrop}
`

const CloseButton = styled(IconButton)`
    position: absolute;
    top: ${props => props.theme.spacing(1)};
    right: ${props => props.theme.spacing(1)};
`

const StyledPaper = styled(Paper)`
    background-color: ${props =>
        props.theme.palette.type === 'light' ? props.theme.palette.background.default : undefined};
`

import {ApiBase} from "../../lib/api/ApiBase";
import {ApiTypes} from "../../lib/api";

export class ManagementApi extends ApiBase {
    async uploadImage(file: File) {
        const formData = new FormData();
        formData.append('name', file.name);
        formData.append('kind', file.type)
        formData.append('metadata', JSON.stringify({ public: true }))
        formData.append('folder', "6111380c1c686e9a2eddabea") //dashboard-data folder
        formData.append('file', file);
        return this.client.post<ApiTypes['file']>(`/files/upload`, formData);
    }
}
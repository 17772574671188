import React from 'react'
import { OpenDayChallenge } from './OpenDayChallenge'
import { Stack } from 'components/layout'
import styled from 'styled-components/macro'
import { Card, CardContent } from 'components/card'
import { Text } from 'components/Text'
import Block04ImageEn from '../media/block_04/en.jpg'
import { useTranslate } from 'lib/i18n/useTranslate'
import { canUnlockSecretCode, useUserState } from 'contexts/UserContext'
import { PoorMansSuspenseRenderer, usePoorMansSuspense } from 'hooks/usePoorMansSuspense'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { MeetingList } from 'features/meetings'

export const OpenDayClassroom: React.FC = () => {
    const t = useTranslate()
    const user = useUserState()
    const lang = user.i18n.language
    const { isReady, tick } = usePoorMansSuspense(2)
    const image = imageBlock04[lang] ?? Block04ImageEn
    const canUnlockSecret = canUnlockSecretCode(user, 'event-participation')

    return (
        <PoorMansSuspenseRenderer isReady={isReady}>
            <Stack spacing={8}>
                <ErrorBoundary silent>
                    <MeetingList onReady={tick} />
                </ErrorBoundary>
                {canUnlockSecret && <OpenDayChallenge onReady={tick} />}
                <ContentBlock03>
                    <CardContent>
                        <Text
                            dangerouslySetInnerHTML={{ __html: t('OpendayClassroom::Topics::title') }}
                            variant="h1"
                            component="h3"
                        />
                    </CardContent>
                    <Image width="1920" height="1634" loading="lazy" src={image} />
                </ContentBlock03>
            </Stack>
        </PoorMansSuspenseRenderer>
    )
}

const imageBlock04: Record<string, string> = {
    hu: Block04ImageEn,
}

const ContentBlock03 = styled(Card)`
    display: flex;
    flex-direction: column;
`

const Image = styled.img`
    background-color: ${props => props.theme.palette.divider};
    display: block;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
    height: 100%;
`

import { ErrorMessage } from 'components/ErrorMessage'
import { useIsElementFullyVisible } from 'hooks/useIsElementFullyVisible'
import React, { useEffect, useState } from 'react'
import { useMyProjectsQuery } from '../projectQueries'
import { MyProjectList } from './MyProjectsList'
import styled from 'styled-components/macro'
import { Flex, Stack } from 'components/layout'
import { Loader } from 'components/Loader'
import { ClassroomIcon, CommunityIcon, DeleteIcon, FavoriteIcon } from 'components/icons'
import {
    QueryParamConfig,
    QueryParamProvider,
    StringParam,
    useQueryParams,
    withDefault,
    ArrayParam,
} from 'use-query-params'
import { MyProjectsFilters } from './MyProjectsFilters'
import { MyProjectsQuery, SHAREABLE_TRAINERCONTEST_PROJECT_TAG } from '../ProjectsApi'
import { addProjectActionListener, removeProjectActionListener } from '../useProjectActions'
import { Route } from 'react-router-dom'
import { useTranslate } from 'lib/i18n/useTranslate'
import { ProjectListTab } from '../ProjectListTab'
import { WrappingInline } from 'components/layout/WrappingInline'
import { useSelector } from 'lib/store'
import { UserState, useUserState } from 'contexts/UserContext'
import { CookieHunt } from 'features/cookie-hunt'
import { getDefaultProjectListSort } from '../projectHelpers'
import { useUpdateUserSettingOnChange } from 'hooks/useUpdateUserSettingOnChange'
import { useHasGrant } from 'lib/grants'

export interface MyProjectsFilterState {
    view: QueryParamConfig<MyProjectsQuery['view']>
    sort: QueryParamConfig<MyProjectsQuery['sort']>
    kind: QueryParamConfig<MyProjectsQuery['kind']>
    language: QueryParamConfig<MyProjectsQuery['language']>
    palette: QueryParamConfig<MyProjectsQuery['palette']>
    labels: QueryParamConfig<MyProjectsQuery['labels']>
    search: QueryParamConfig<string>
    // fixing some weird use-query-params typing issue
    [key: string]: any
}

export const MyProjects: React.FC = () => {
    return (
        <QueryParamProvider ReactRouterRoute={Route}>
            <MyProjectsInner />
        </QueryParamProvider>
    )
}

export type ProjectListLayout = 'grid' | 'list'

const MyProjectsInner = React.memo(() => {
    const { settings } = useUserState()
    const hasGrant = useHasGrant()
    const [filters, setFilters] = useQueryParams<MyProjectsFilterState>({
        view: withDefault<any, MyProjectsQuery['view']>(StringParam, 'all'),
        sort: withDefault<any, MyProjectsQuery['sort']>(StringParam, getDefaultProjectListSort(settings)),
        kind: withDefault<any, MyProjectsQuery['kind']>(StringParam, ''),
        language: withDefault<any, MyProjectsQuery['language']>(StringParam, ''),
        palette: withDefault<any, MyProjectsQuery['palette']>(StringParam, ''),
        labels: withDefault<any, MyProjectsQuery['labels']>(ArrayParam, []),
        search: withDefault(StringParam, ''),
    })
    const [listLayout, setListLayout] = useState<ProjectListLayout>(getDefaultProjectListLayout(settings))
    const [skipCorrection, setSkipCorrection] = useState(0)
    const t = useTranslate()
    const projects = useSelector(state => state.projects.all)

    const query = useMyProjectsQuery({ limit: 32, ...filters }, skipCorrection)

    useUpdateUserSettingOnChange('mylogiscool-project-list-layout', listLayout)
    useUpdateUserSettingOnChange('mylogiscool-project-list-sort', filters.sort)

    useEffect(() => {
        const currentDataIncludesId = (id: string) =>
            !!query.data?.flatMap(data => data.response.data).find(project => project.id === id)

        const onRemovedFromList = (id: string) => {
            if (currentDataIncludesId(id)) {
                setSkipCorrection(skipCorrection => skipCorrection - 1)
            }
        }

        if (filters.view === 'all') {
            addProjectActionListener('delete', onRemovedFromList)
            return () => {
                removeProjectActionListener('delete', onRemovedFromList)
                setSkipCorrection(0)
            }
        }

        if (filters.view === 'favorites') {
            addProjectActionListener('delete', onRemovedFromList)
            addProjectActionListener('unfavorite-own', onRemovedFromList)
            return () => {
                removeProjectActionListener('delete', onRemovedFromList)
                removeProjectActionListener('unfavorite-own', onRemovedFromList)
                setSkipCorrection(0)
            }
        }

        if (filters.view === 'deleted') {
            addProjectActionListener('restore', onRemovedFromList)
            return () => {
                removeProjectActionListener('restore', onRemovedFromList)
                setSkipCorrection(0)
            }
        }
    }, [query, filters.view])

    const { fetchMore, status, isFetchingMore, canFetchMore } = query
    const { ref, isFullyVisible } = useIsElementFullyVisible()

    useEffect(() => {
        if (isFullyVisible === true) {
            fetchMore()
        }
    }, [isFullyVisible, fetchMore])

    const isTriggerVisible = !!(status === 'success' && !isFetchingMore && canFetchMore)
    const isFiltering =
        filters.kind !== '' ||
        filters.language !== '' ||
        filters.search !== '' ||
        filters.palette !== '' ||
        !!(filters.labels && filters.labels.length > 0)

    const extractProjectsFromStore = (files: NonNullable<typeof query.data>) => {
        return files
            .flatMap(file => {
                return file.response.data
            })
            .filter(({ id }) => id in projects)
            .map(file => projects[file.id])
    }

    const filteredProjects = (() => {
        if (!query.data) {
            return undefined
        }
        const projects = extractProjectsFromStore(query.data)
        if (filters.view === 'favorites') {
            return projects.filter(project => {
                return project.file.favorite && !project.file.deleted
            })
        }
        if (filters.view === 'deleted') {
            return projects.filter(project => {
                return project.file.deleted
            })
        }
        return projects.filter(project => {
            return !project.file.deleted
        })
    })()?.filter(projects => !projects.file.tags?.includes(SHAREABLE_TRAINERCONTEST_PROJECT_TAG))

    return (
        <Stack spacing={5}>
            <WrappingInline>
                <ProjectListTab
                    isActive={filters.view === 'all'}
                    onClick={() => {
                        setFilters({ view: 'all' }, 'replaceIn')
                    }}
                >
                    {t('general::all')}
                </ProjectListTab>
                {!hasGrant('share-project-user-consent') && (
                    <>
                        <ProjectListTab
                            isActive={filters.view === 'classroom'}
                            onClick={() => {
                                setFilters({ view: 'classroom' }, 'replaceIn')
                            }}
                            icon={<ClassroomIcon />}
                        >
                            {t('classroom::title')}
                        </ProjectListTab>
                        <ProjectListTab
                            isActive={filters.view === 'community'}
                            onClick={() => {
                                setFilters({ view: 'community' }, 'replaceIn')
                            }}
                            icon={<CommunityIcon />}
                        >
                            {t('projects::community')}
                        </ProjectListTab>
                    </>
                )}
                <ProjectListTab
                    isActive={filters.view === 'favorites'}
                    onClick={() => {
                        setFilters({ view: 'favorites' }, 'replaceIn')
                    }}
                    icon={<FavoriteIcon />}
                >
                    {t('general::favorites')}
                </ProjectListTab>
                <ProjectListTab
                    isActive={filters.view === 'deleted'}
                    onClick={() => {
                        setFilters({ view: 'deleted' }, 'replace')
                    }}
                    icon={<DeleteIcon />}
                >
                    {t('general::deletedItems')}
                </ProjectListTab>
            </WrappingInline>
            <Stack spacing={8}>
                <CookieHunt name="my-projects-filters">
                    <MyProjectsFilters
                        hideSort={filters.view === 'deleted'}
                        listLayout={listLayout}
                        onListLayoutChange={setListLayout}
                        filters={filters}
                        setFilters={setFilters}
                    />
                </CookieHunt>

                <MyProjectList
                    listLayout={listLayout}
                    view={filters.view}
                    projects={filteredProjects}
                    isFiltering={isFiltering}
                />
            </Stack>

            {status === 'error' && <ErrorMessage />}

            <Flex justify="center" mt={5}>
                {(status === 'loading' || isFetchingMore) && <Loader />}
            </Flex>
            <LoadMoreTrigger ref={ref} isVisible={isTriggerVisible}></LoadMoreTrigger>
        </Stack>
    )
})

const getDefaultProjectListLayout = (settings: UserState['settings']): ProjectListLayout => {
    const validValues = new Set<ProjectListLayout>(['grid', 'list'])
    const settingValue = settings['mylogiscool-project-list-layout']
    if (settingValue && validValues.has(settingValue as ProjectListLayout)) {
        return settingValue as ProjectListLayout
    }
    return 'grid'
}

const LoadMoreTrigger = styled.div<{ isVisible: boolean }>`
    height: 2px;
    display: ${props => (props.isVisible ? 'block' : 'none')};
`

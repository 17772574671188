import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useSelector } from 'lib/store'
import { getHighScoreRoom, requestRooms } from 'lib/liveops'
import { HighScoreGame } from './highScoreGames'
import { DialogContent, DialogTitle } from 'components/dialog'
import { HighScoreGameLeaderboard } from 'features/high-score/HighScoreGameLeaderboard'
import { Text } from 'components/Text'
import { Loader } from 'components/Loader'
import { Stack } from 'components/layout'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Card, CardContent } from 'components/card'
import { HighScoreGameThumbnail } from './HighScoreGameThumbnail'
import { HighScoreRoom } from 'lib/liveops/reducers/shared/leaderboard'
import { useState } from 'react'
import { useCallback } from 'react'
import { Button } from 'components/Button'
import { Link } from 'react-router-dom'
import { HighScoreRoomCountdown } from './HighScoreRoomCountdown'
import { transparentize } from 'polished'
import { customScrollbar } from 'utils/styleUtils'
import { useTitle } from 'hooks/useTitle'
import { useDispatch } from 'react-redux'

interface HighScoreRoomResultsProps {
    game: HighScoreGame
    roomId: string
}

export const HighScoreRoomResults: React.FC<HighScoreRoomResultsProps> = ({ game, roomId }) => {
    const t = useTranslate()
    const dispatch = useDispatch()
    const highScoreRoom = useSelector(state => getHighScoreRoom(state, roomId))

    useTitle(highScoreRoom?.name)

    useEffect(() => {
        if (!highScoreRoom) {
            dispatch(requestRooms())
        }
    }, [highScoreRoom, dispatch])

    return (
        <>
            <DialogTitle>{t('highScoreRoom::label')}</DialogTitle>
            <DialogContent>
                <Grid>
                    <Card>
                        <LeaderboardCardContent>
                            <HighScoreGameLeaderboard projectId={game.projectId} room={roomId} />
                        </LeaderboardCardContent>
                    </Card>
                    <div>
                        {!highScoreRoom ? (
                            <Loader />
                        ) : (
                            <HighScoreRoomResultsInner game={game} roomId={roomId} highScoreRoom={highScoreRoom} />
                        )}
                    </div>
                </Grid>
            </DialogContent>
        </>
    )
}

interface HighScoreRoomResultsInnerProps extends HighScoreRoomResultsProps {
    highScoreRoom: HighScoreRoom
}

const HighScoreRoomResultsInner: React.FC<HighScoreRoomResultsInnerProps> = ({ game, highScoreRoom }) => {
    const [isFinished, setIsFinished] = useState(false)
    const t = useTranslate()

    const onEnd = useCallback(() => {
        setIsFinished(true)
    }, [])

    return (
        <Card>
            <HighScoreGameThumbnailWithSecretCode
                game={game}
                isFinished={isFinished}
                secretCode={highScoreRoom.secretCode}
            />
            <CardContent>
                <Stack>
                    <Text variant="h3">
                        <RoomName>{highScoreRoom.name}</RoomName> <GameTitle>({t(game.title)})</GameTitle>
                    </Text>
                    <HighScoreRoomCountdown date={highScoreRoom.expireAt} onEnd={onEnd} />
                    {!isFinished && (
                        <Button
                            component={Link}
                            replace
                            to={`/app/high-score/${highScoreRoom.target}?room=${highScoreRoom.id}`}
                        >
                            {t('general::play')}
                        </Button>
                    )}
                </Stack>
            </CardContent>
        </Card>
    )
}

interface HighScoreGameThumbnailWithSecretCodeProps {
    game: HighScoreGame
    isFinished: boolean
    secretCode: string
}

const HighScoreGameThumbnailWithSecretCode: React.FC<HighScoreGameThumbnailWithSecretCodeProps> = ({
    game,
    isFinished,
    secretCode,
}) => {
    const t = useTranslate()

    return (
        <HighScoreGameThumbnailWithSecretCodeRoot>
            {!isFinished && (
                <HighScoreGameThumbnailWithSecretCodeOverlay $overlayColor={game.overlayColor ?? '#000'}>
                    <div>
                        <Text>{t('secretCode::label')}</Text>
                        <SecretCode>{secretCode}</SecretCode>
                    </div>
                </HighScoreGameThumbnailWithSecretCodeOverlay>
            )}
            <StyledHighScoreGameThumbnail game={game} />
        </HighScoreGameThumbnailWithSecretCodeRoot>
    )
}

const HighScoreGameThumbnailWithSecretCodeRoot = styled.div`
    position: relative;
`

const HighScoreGameThumbnailWithSecretCodeOverlay = styled.div<{ $overlayColor: string }>`
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    padding-bottom: ${props => props.theme.spacing(2)};
    background: ${props =>
        `linear-gradient(to top, ${transparentize(0.15, props.$overlayColor)} 44%, ${transparentize(
            1,
            props.$overlayColor,
        )})`};
    color: white;
`

const SecretCode = styled(Text)`
    font-weight: 800;
    font-size: 4rem;
    line-height: 1;
    letter-spacing: 4px;
`

const StyledHighScoreGameThumbnail = styled(HighScoreGameThumbnail)`
    display: block;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
`

const RoomName = styled.span`
    word-break: break-word;
`

const GameTitle = styled.span`
    font-weight: 400;
    color: ${props => props.theme.palette.text.disabled};
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.theme.spacing(8)};
    height: 100%;
    ${props => props.theme.breakpoints.down('sm')} {
        height: auto;
        grid-template-columns: auto;
    }
`

const LeaderboardCardContent = styled(CardContent)`
    overflow: auto;
    height: 100%;
    ${customScrollbar}
`

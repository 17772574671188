import { Card, CardContent } from 'components/card'
import { DiceIcon, TshirtIcon } from 'components/icons'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import dayjs from 'dayjs'
import { StoreState, useSelector } from 'lib/store'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useEffect, useRef, useState } from 'react'
import { IconType } from 'react-icons/lib'
import styled from 'styled-components/macro'
import {
    DigitalMissionPhase,
    digitalMissionPhases,
    DigitalMissionPhaseType,
    MAX_POINTS,
    MAX_POINTS_WITHOUT_SCOOLCODE,
} from '../data/digitalMission2021Data'
import { getDigitalMission2021SeaonsPoints } from '../helpers/digitalMission2021Helpers'
import { canUserCreateProject, canUserCreateShareableProject, UserState, useUserState } from 'contexts/UserContext'
import { useDateFormat } from 'hooks/useDateFormat'
import { useCountdown } from 'hooks/useCountdown'
import { useLocation } from 'react-router-dom'
import RootRef from '@material-ui/core/RootRef'
import { ProgressBar, ProgressBarIndicator, ProgressBarTick, ProgressBarIndicatorText } from 'components/progress-bar'

const currentPhaseIndex = digitalMissionPhases.findIndex(phase => phase.endDate.isAfter(dayjs()))

const getMaxPoints = (user: UserState) =>
    canUserCreateShareableProject(user) ? MAX_POINTS : MAX_POINTS_WITHOUT_SCOOLCODE

const userReachedSweepstakeLimit = (state: StoreState, sweeepStakeId?: string) => {
    if (state.client.inventory === null) {
        return undefined
    }
    return state.client.inventory.find(item => item.item === sweeepStakeId)
}

export const userWonTheSweepstake = (state: StoreState, winnerItemId?: string) => {
    if (state.client.inventory === null) {
        return undefined
    }
    return state.client.inventory.find(item => item.item === winnerItemId)
}

interface DigitalMission2021ProgressProps {
    inBanner?: boolean
}

export const DigitalMission2021Progress: React.FC<DigitalMission2021ProgressProps> = ({ inBanner }) => {
    const [selectedPhaseIndex, setSelectedPhaseIndex] = useState<number>(Math.max(currentPhaseIndex, 0))
    const points = useSelector(getDigitalMission2021SeaonsPoints)
    const user = useUserState()
    const maxPoints = getMaxPoints(user)
    const { state } = useLocation<{ bubblePhaseIndex: number }>()
    const progressRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (points !== undefined && state?.bubblePhaseIndex !== undefined && progressRef.current) {
            setSelectedPhaseIndex(state.bubblePhaseIndex)
            progressRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [state?.bubblePhaseIndex, points])

    if (points === undefined) {
        return null
    }

    const selectedPhase = digitalMissionPhases[selectedPhaseIndex]
    return (
        <RootRef rootRef={progressRef}>
            <Stack spacing={2}>
                <StyledProgressBar $inBanner={!!inBanner} value={points} max={maxPoints}>
                    {digitalMissionPhases.map((phase, i) => (
                        <ProgressBarTick key={i} requiredValue={phase.requiredPoints} />
                    ))}
                    <ProgressBarIndicator>
                        {inBanner
                            ? points >= 500 && <ProgressBarIndicatorText>{points}</ProgressBarIndicatorText>
                            : points >= 300 && <ProgressBarIndicatorText>{points}</ProgressBarIndicatorText>}
                    </ProgressBarIndicator>
                </StyledProgressBar>
                <ButtonWrap>
                    {digitalMissionPhases.map((phase, i) => (
                        <PhaseButton
                            maxPoints={maxPoints}
                            key={i}
                            phase={phase}
                            isActive={selectedPhaseIndex === i}
                            onClick={() => {
                                setSelectedPhaseIndex(i)
                            }}
                            points={points}
                        />
                    ))}
                </ButtonWrap>
                <PhaseBox
                    phase={selectedPhase}
                    points={points}
                    maxPoints={maxPoints}
                    isCurrentPhase={currentPhaseIndex === selectedPhaseIndex}
                    inBanner={!!inBanner}
                />
            </Stack>
        </RootRef>
    )
}

const phaseToIcon: Record<DigitalMissionPhaseType, IconType> = {
    sweepstake: DiceIcon,
    't-shirt': TshirtIcon,
}

interface PhaseButtonProps {
    isActive: boolean
    phase: DigitalMissionPhase
    onClick: VoidFunction
    maxPoints: number
    points: number
}

const PhaseButton: React.FC<PhaseButtonProps> = ({ phase, onClick, isActive, maxPoints, points }) => {
    const Icon = phaseToIcon[phase.type]

    return (
        <PhaseButtonRoot
            style={{ left: `${(phase.requiredPoints / maxPoints) * 100}%` }}
            onClick={onClick}
            $isActive={isActive}
        >
            <Icon />
        </PhaseButtonRoot>
    )
}

interface PhaseBoxProps {
    phase: DigitalMissionPhase
    points: number
    maxPoints: number
    isCurrentPhase: boolean
    inBanner: boolean
}

const PhaseBox: React.FC<PhaseBoxProps> = ({ points, phase, maxPoints, isCurrentPhase, inBanner }) => {
    const t = useTranslate()
    const reachedSweepstakeLimit = useSelector(state => !!userReachedSweepstakeLimit(state, phase.successCatalogItemId))
    const isWinner = useSelector(state => !!userWonTheSweepstake(state, phase.winnerCatalogItemID))
    const formatDate = useDateFormat('long')
    const { remaining, isEnd } = useCountdown(phase.endDate)
    const user = useUserState()
    const canCreateProject = canUserCreateProject(user) || canUserCreateShareableProject(user)

    const sweepStakeCounter = phase.sweepstakeIsHappend ? null : !isEnd && isCurrentPhase ? (
        <Text>
            {phase.type === 't-shirt'
                ? t('digitalMission2021::progressbar::message09')
                : t('digitalMission2021::progressbar::message01')}{' '}
            <strong>
                {t('general::nDay', { count: remaining.days })}, {t('general::nHour', { count: remaining.hours })},{' '}
                {t('general::nMinute', { count: remaining.minutes })}
            </strong>
        </Text>
    ) : phase.type === 't-shirt' ? null : (
        <Text>
            {t('digitalMission2021::progressbar::message08')}{' '}
            <strong>{formatDate.formatDate(phase.endDate.toDate())}</strong>
        </Text>
    )

    const body = (() => {
        const remainingPoints = phase.requiredPoints - points
        if (phase.type === 'sweepstake') {
            const returnText = (() => {
                if (isWinner) {
                    return t('digitalMission2021::progressbar::message12')
                } else {
                    if (phase.title === 'digitalMission2021::prizes::sweepstake01') {
                        if (reachedSweepstakeLimit) {
                            return t('digitalMission2021::progressbar::message11')
                        } else {
                            return t('digitalMission2021::prizes::description01postevent')
                        }
                    }
                    if (phase.title === 'digitalMission2021::prizes::sweepstake02') {
                        if (reachedSweepstakeLimit) {
                            return t('digitalMission2021::progressbar::message11')
                        } else {
                            return t('digitalMission2021::prizes::description02postevent')
                        }
                    }
                    if (phase.title === 'digitalMission2021::prizes::sweepstake03') {
                        return t('digitalMission2021::prizes::description03postevent')
                    }
                }
            })()
            if (isEnd) {
                if (phase.sweepstakeIsHappend) {
                    return <Text>{returnText}</Text>
                } else {
                    return (
                        <Text>
                            {reachedSweepstakeLimit ? t('digitalMission2021::progressbar::message03') : returnText}
                        </Text>
                    )
                }
            } else {
                return (
                    <Text>
                        {reachedSweepstakeLimit
                            ? inBanner
                                ? t('digitalMission2021::progressbar::message10')
                                : t('digitalMission2021::progressbar::message03')
                            : remainingPoints > 0 && (
                                  <span
                                      dangerouslySetInnerHTML={{
                                          __html: t('digitalMission2021::progressbar::message02', {
                                              points: phase.requiredPoints - points,
                                          }),
                                      }}
                                  />
                              )}
                    </Text>
                )
            }
        }

        if (phase.type === 't-shirt') {
            return (
                <Text>
                    {reachedSweepstakeLimit ? (
                        canCreateProject ? (
                            <>
                                {t('digitalMission2021::progressbar::message06A')}{' '}
                                {t('gallery:Aliens_Universe/Robot Dog')}
                            </>
                        ) : (
                            <>{t('digitalMission2021::progressbar::message06B')}</>
                        )
                    ) : isEnd ? (
                        <span> {t('digitalMission2021::prizes::description04postevent')}</span>
                    ) : (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t('digitalMission2021::progressbar::thsirtmessage01', {
                                    points: phase.requiredPoints - points,
                                }),
                            }}
                        />
                    )}
                </Text>
            )
        }
    })()

    if (inBanner) {
        return <Description>{body}</Description>
    }

    return (
        <PhaseBoxRoot>
            <PhaseBoxTriangle style={{ left: `${(phase.requiredPoints / maxPoints) * 100}%` }} />
            <Card>
                <CardContent>
                    <Stack>
                        <Text variant="h3">{t(phase.title as LocalisationKey)}</Text>
                        {sweepStakeCounter}
                        {body}
                    </Stack>
                </CardContent>
            </Card>
        </PhaseBoxRoot>
    )
}

const PhaseButtonRoot = styled.button<{ $isActive: boolean }>`
    position: absolute;
    z-index: 2;
    background: none;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: solid 2px ${props => props.theme.palette.divider};
    cursor: pointer;
    color: ${props => (props.$isActive ? props.theme.palette.primary.contrastText : props.theme.palette.text.primary)};
    background-color: ${props =>
        props.$isActive ? props.theme.palette.primary.main : props.theme.palette.background.paper};
    transform: translateX(-50%);

    > svg {
        width: 100%;
        height: 100%;
    }
`

const StyledProgressBar = styled(ProgressBar)<{ $inBanner: boolean }>`
    height: ${props => (props.$inBanner ? '2rem' : undefined)};
    border: ${props => (props.$inBanner ? 'none' : undefined)};
`

const ButtonWrap = styled.div`
    position: relative;
    height: 2.5rem;
`

const TRIANGLE_SIZE = 1

const PhaseBoxRoot = styled.div`
    position: relative;
    margin-top: ${TRIANGLE_SIZE + 0.3}rem;
`

const PhaseBoxTriangle = styled.div`
    position: absolute;
    width: 0;
    height: 0;
    border-left: ${TRIANGLE_SIZE}rem solid transparent;
    border-right: ${TRIANGLE_SIZE}rem solid transparent;
    border-bottom: ${TRIANGLE_SIZE}rem solid ${props => props.theme.palette.background.paper};
    filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1));
    transform: translate(-50%, -100%);
`

const Description = styled(Text)`
    margin-block-start: ${props => props.theme.spacing(6)};
    color: #b5c8f7;
`

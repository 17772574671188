import React from 'react'
import styled from 'styled-components/macro'
import { DialogTitle as MuiDialogTitle, DialogTitleProps as MuiDialogTitleProps } from '@material-ui/core'

type DialogTitleProps = MuiDialogTitleProps

export const DialogTitle: React.FC<DialogTitleProps> = props => {
    return <StyledDialogTitle {...props} />
}

const StyledDialogTitle = styled(MuiDialogTitle)`
    background-color: ${props => props.theme.palette.background.paper};
    border-bottom: solid 1px ${props => props.theme.palette.divider};
    padding-right: ${props => props.theme.spacing(10)};
`

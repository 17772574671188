import React from 'react'
import styled from 'styled-components/macro'

export const ProjectModalContent: React.FC = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled.div`
    padding: ${props => props.theme.spacing(4)};
    overflow: auto;
    flex: 1;
`

import { FavoriteIcon, ViewIcon } from 'components/icons'
import { Loader } from 'components/Loader'
import { StatCard } from 'components/stat'
import { currencyStyles } from 'features/projects/currencyStyles'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useSelector } from 'lib/store'
import React from 'react'
import styled from 'styled-components/macro'
import { useUserStatisticQuery } from './useUserStatisticQuery'

interface MyStatsProps extends PoorMansSuspenseTicker {}

export const MyProfilStats: React.FC<MyStatsProps> = ({ onReady }) => {
    const cookies = useSelector(state => state.client.statistics['@SYSTEM/TotalCurrency:cookie-received'])
    const goldenCookies = useSelector(state => state.client.statistics['@SYSTEM/TotalCurrency:golden-cookie-received'])
    const views = useSelector(state => state.client.statistics['@SYSTEM:view-received'])
    const publicViews = useSelector(state => state.client.statistics['@SYSTEM:public-view-received'])
    const favorites = useSelector(state => state.client.statistics['@SYSTEM:like-received'])

    const isReady = [cookies, views, favorites, publicViews, goldenCookies].every(stat => stat !== undefined)

    usePoorMansSuspenseTick(isReady, onReady)

    if (!isReady) {
        return <Loader />
    }

    return (
        <ProfileStats
            cookies={cookies!}
            goldenCookies={goldenCookies!}
            views={views! + publicViews!}
            favorites={favorites!}
        />
    )
}

interface UserProfilStatsProps extends PoorMansSuspenseTicker {
    userId: string
}

export const UserProfilStats: React.FC<UserProfilStatsProps> = ({ onReady, userId }) => {
    const { data: userStat, status: userStatStatus } = useUserStatisticQuery(userId)

    usePoorMansSuspenseTick(userStatStatus !== 'loading', onReady)

    return (
        <ProfileStats
            cookies={userStat?.totalCookiesReceived ?? 0}
            goldenCookies={userStat?.totalGoldenCookiesReceived ?? 0}
            views={(userStat?.totalViewsReceived ?? 0) + (userStat?.totalPublicViewsReceived ?? 0)}
            favorites={userStat?.totalLikesReceived ?? 0}
        />
    )
}

interface ProfileStatsProps {
    cookies: number
    goldenCookies: number
    views: number
    favorites: number
}

const ProfileStats: React.FC<ProfileStatsProps> = ({ cookies, goldenCookies, views, favorites }) => {
    const t = useTranslate()

    const hasNoStats = [cookies, goldenCookies, views, favorites].every(stat => stat === 0)

    if (hasNoStats) {
        return null
    }

    return (
        <Grid>
            <StatCard
                backgroundColor={currencyStyles['golden-cookie'].colors.background.light}
                title={t('profile::receivedGoldenCookies')}
                icon={<CookieImage src={currencyStyles['golden-cookie'].icon.colored} />}
                value={goldenCookies}
            />
            <StatCard
                backgroundColor={currencyStyles.cookie.colors.background.light}
                title={t('profile::receivedCookies')}
                icon={<CookieImage src={currencyStyles.cookie.icon.colored} />}
                value={cookies}
            />
            <StatCard
                color="#2d2add"
                title={t('profile::projectFavorites')}
                icon={<FavoriteIcon fontSize="1.2em" />}
                value={favorites}
            />
            <StatCard
                color="#2cc51e"
                title={t('profile::projectViews')}
                icon={<ViewIcon fontSize="1.2em" />}
                value={views}
            />
        </Grid>
    )
}

const CookieImage = styled.img`
    width: 1.2em;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
    gap: ${props => props.theme.spacing(4)};
`

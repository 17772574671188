import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useScrollToHash = () => {
    const { hash } = useLocation()
    useEffect(() => {
        if (hash) {
            const id = hash.replace('#', '')
            const element = document.getElementById(id)

            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({ behavior: 'smooth' })
                })
            }
        }
    }, [hash])
}

export const ScrollToHash = () => {
    useScrollToHash()
    return null
}

import { PAUSE_QUEUE, PUSH_QUEUE, RESUME_QUEUE, SHIFT_QUEUE } from './dialogQueueActionyTypes'
import { QueuedDialogData } from './dialogQueueReducer'

export function shiftQueue() {
    return {
        type: SHIFT_QUEUE,
    } as const
}

export function pushQueue(dialogData: QueuedDialogData) {
    return {
        type: PUSH_QUEUE,
        payload: {
            dialogData,
        },
    } as const
}

export function pauseQueue() {
    return {
        type: PAUSE_QUEUE,
    } as const
}

export function resumeQueue() {
    return {
        type: RESUME_QUEUE,
    } as const
}

import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import {TextField} from "components/form";

export interface DateFieldProps {
    label: string,
    value?: string,
    onChange?: (e: any, newValue: string) => any
}
export const DateField: React.FC<DateFieldProps & any> = ({ value, onChange, children, multiline, ...rest }) => {
    const dateValue = value ? dayjs(value).format("YYYY-MM-DD") : null;
    const [ fieldValue, setFieldValue ] = useState("");
    const ref = React.useRef<HTMLInputElement>(null);

    useEffect(() => {
        if(ref.current) {
            if(dateValue) {
                if (ref.current.value !== fieldValue) {
                    ref.current.value = dateValue
                }
            }
            else {
                if (ref.current.value !== "") {
                    ref.current.value = ""
                }
            }
        }
    }, [ ref, dateValue, fieldValue ]);

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setFieldValue(e.target.value);
        try {
            const realValue = e.target.value ? new Date(e.target.value).toISOString() : undefined;
            if (onChange) onChange(e, realValue);
        }
        catch (err) {
            //Do not save invalid values
        }
    }

    return (
        <TextField ref={ref} defaultValue={dateValue} type="date" onChange={handleChange} {...rest} />
    )
}
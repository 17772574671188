import produce from 'immer'
import { Reducer } from 'redux'
import { FeedEntryWithMetada, FeedType } from '../types/feedTypes'
import { loadFeedPage, markAllRead } from './feedActions'
import { LOAD_FEED_PAGE, MARK_ALL_READ } from './feedActionTypes'

interface FeedTypeState {
    entries: FeedEntryWithMetada[]
    hasMore: boolean
    nextSkip: number
}

type FeedState = Record<FeedType, FeedTypeState>

type FeedAction = ReturnType<typeof loadFeedPage> | ReturnType<typeof markAllRead>

export const feedReducer: Reducer<FeedState, FeedAction> = (
    state = {
        community: { entries: [], hasMore: false, nextSkip: 0 },
        trainer: { entries: [], hasMore: false, nextSkip: 0 },
    },
    action,
) => {
    if (action.type === LOAD_FEED_PAGE) {
        return produce(state, draft => {
            draft[action.payload.feedType].entries.push(...action.payload.data.entries)
            draft[action.payload.feedType].hasMore = action.payload.data.hasMore
            draft[action.payload.feedType].nextSkip = action.payload.data.nextSkip
        })
    }

    if (action.type === MARK_ALL_READ) {
        return produce(state, draft => {
            draft[action.payload.feedType].entries.forEach(entry => {
                entry.metadata.unread = false
            })
        })
    }

    return state
}

import { css } from 'styled-components/macro'

export const projectListGridStyles = css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    gap: ${props => props.theme.spacing(8, 6)};
    @media (max-width: 400px) {
        gap: ${props => props.theme.spacing(6, 4)};
        grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
    }
`

import React, { useCallback, useState } from 'react'

interface DialogControllerProps {
    children: (open: VoidFunction) => React.ReactNode
    dialog: (context: { isOpen: boolean; close: VoidFunction }) => React.ReactNode
}

export const DialogController: React.FC<DialogControllerProps> = ({ children, dialog }) => {
    const [isOpen, setIsOpen] = useState(false)

    const open = useCallback(() => {
        setIsOpen(true)
    }, [])

    const close = useCallback(() => {
        setIsOpen(false)
    }, [])

    return (
        <>
            {children(open)}
            {dialog({ isOpen, close })}
        </>
    )
}

import { Divider } from 'components/Divider'
import React from 'react'
import { useProjectModalContext } from '../modal/projectModalContext'
import { ProjectModalDescription } from '../modal/ProjectModalDescription'

export const ProjectDetailsDescription: React.FC = () => {
    const { project, variant } = useProjectModalContext()

    if (variant === null) {
        return null
    }

    const details = project?.metadata.details?.[variant]

    return (
        <>
            {!!details && <Divider />}
            <ProjectModalDescription>{details}</ProjectModalDescription>
            <Divider />
        </>
    )
}

import { CertificateType } from 'features/certificates/certificateTypes'
import { QueuedDialogData } from 'features/dialog-queue/store/dialogQueueReducer'

export const NOTIFICATION_TYPES = [
    'GENERAL',
    'NEW_PROJECT_SHARED',
    'PROJECT_UPDATED',
    'UNLOCK_ACHIEVEMENT',
    'DM2021_WINNER',
    'WEEKLY_TOP_100_PRESENCE',
    'NEW_CERTIFICATION',
    'DM2021_CERT',
    'NEW_CERT',
] as const

export type NotificationType = typeof NOTIFICATION_TYPES[number]

export type NotificationDisplayType = 'one-off' | 'silent' | 'normal' | 'unlisted-normal' | 'fancy' | 'tooltip'

export type NotificationCTA =
    | {
          type: 'internal-link'
          link:
              | {
                    pathname: string
                    state?: { modal?: boolean; bubblePhaseIndex?: number; fromNotification?: boolean }
                    hash?: string
                }
              | string
      }
    | { type: 'external-link'; link: string }
    | { type: 'dialog'; data: QueuedDialogData }
    | { type: 'custom'; onClick: VoidFunction }

export const NotificationStatTypes = ['Easy', 'Medium', 'Hard'] as const

type NotificationStat = typeof NotificationStatTypes[number]

export interface Notification {
    id: string
    createdAt: string
    read: boolean
    displayed?: boolean
    message: {
        title?: string
        body?: string
        data: {
            type: NotificationType
            display: NotificationDisplayType
            payload: {
                _image?: string
                _cta?: NotificationCTA
                _button?: string
                achievementId?: string
                seasonPoints?: number
                cookies?: number
                views?: number
                likes?: number
                follows?: number
                favorites?: number
                tooltipId?: string
                projectId?: string
                name?: string
                username?: string
                modalContent?: any
                title?: any
                body?: any
                userId?: string
                project?: { title: string; isMiniQuiz: boolean }
                trophy?: 'platinum' | 'silver' | 'gold' | 'bronze'
                stat?: NotificationStat
                dm2021successCatalogItemId?: string
                subjectId?: string
                subjectFormat?: CertificateType
                variables?: Record<string, any>
            }
        }
    }
}

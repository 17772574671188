import React from 'react'
import { EmptyState } from 'components/EmptyState'
import { useUserState } from 'contexts/UserContext'
import { useTranslate } from 'lib/i18n/useTranslate'
import { newProjetDialogTo, Project } from 'features/projects'
import { MyDeletedProjectCard } from 'features/projects/my/MyDeletedProjectCard'
import { useMyPendingProjectsQuery } from 'features/projects/projectQueries'
import { MyMiniQuizCard } from './MyMiniQuizCard'
import { MyMiniQuizzesQuery } from 'features/mini-quizzes/data/MiniQuizApi'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import styled from 'styled-components/macro'
import emptyStateImg from '../../media/empty-state.svg'
import { Button } from 'components/Button'
import { AddIcon } from 'components/icons'
import { Link } from 'react-router-dom'
import { Location } from 'history'

interface MyMiniQuizListProps {
    isFiltering: boolean
    projects: Project[] | undefined
    view: MyMiniQuizzesQuery['view']
}

export const MyMiniQuizList: React.FC<MyMiniQuizListProps> = ({ isFiltering, projects, view }) => {
    const { account } = useUserState()
    const { data: pendingProjects } = useMyPendingProjectsQuery()
    const t = useTranslate()

    if (!projects) {
        return null
    }

    if (projects.length === 0) {
        if (isFiltering || view !== 'all') {
            return <EmptyState>{t('general::noResults')}</EmptyState>
        }
        return <MyMiniQuizListEmptyState />
    }

    return (
        <Stack>
            {projects.map(project => {
                if (view === 'deleted') {
                    return <MyDeletedProjectCard displayType="list" project={project} key={project.file.id} />
                }

                return (
                    <MyMiniQuizCard
                        user={account}
                        key={project.file.id}
                        project={project}
                        isPending={pendingProjects?.includes(project.file.id)}
                    />
                )
            })}
        </Stack>
    )
}

const MyMiniQuizListEmptyState: React.FC = () => {
    const t = useTranslate()

    return (
        <MyMiniQuizListEmptyStateRoot>
            <img src={emptyStateImg} alt="" width="300" height="142" />
            <Text>{t('miniQuiz::noQuizzes')}</Text>
            <Button
                startIcon={<AddIcon />}
                component={Link}
                to={(location: Location) => newProjetDialogTo({ ...location, state: { type: 'mini-quiz' } })}
            >
                {t('miniQuiz::createFirst')}
            </Button>
        </MyMiniQuizListEmptyStateRoot>
    )
}

const MyMiniQuizListEmptyStateRoot = styled(Stack)`
    text-align: center;
    max-width: 55rem;
    margin-left: auto;
    margin-right: auto;
    p {
        font-weight: 600;
    }
    img {
        width: 100%;
        height: auto;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 60%;
    }
`

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import isoWeek from 'dayjs/plugin/isoWeek'

dayjs.Ls.en.weekStart = 1

export function initDayJs() {
    dayjs.extend(utc)
    dayjs.extend(isoWeek)
}

import { StoreState } from './../../../../../lib/store/store'
import { canUserCreateShareableProject, UserState } from 'contexts/UserContext'
import dayjs, { Dayjs } from 'dayjs'
import cookieCollectorBadge from '../badges/cookieCollector.svg'
import cookieContributorBadge from '../badges/cookieContributor.svg'
import followerBadge from '../badges/follower.svg'
import numberCrushBadge from '../badges/numberCrush.svg'
import profillerBadge from '../badges/profiller.svg'
import cosmicQuizBadge from '../badges/cosmicQuiz.svg'
import laboratoryEscapeBadge from '../badges/laboratoryEscape.svg'
import feedVoterBadge from '../badges/feedVoter.svg'
import wonderQuizBadge from '../badges/wonderQuiz.svg'
import questMasterBadge from '../badges/questMaster.svg'
import projectExplorerBadge from '../badges/projectExplorer.svg'
import highScoreHeroBadge from '../badges/highScoreHero.svg'
import projectCreatorBadge from '../badges/projectCreator.svg'
import marvelBadge from '../badges/marvel.svg'
import geographyexplorerBadge from '../badges/geographyexplorer.svg'
import robotOlympicsBadge from '../badges/RobotOlympics.svg'
import inventionmasterBadge from '../badges/inventionmaster.svg'
import trainerContestVoterBadge from '../badges/trainerContestVoter.svg'
import trainerFollowerBadge from '../badges/trainerFollower.svg'
import cookieHunterBadge from '../badges/cookieHunter.svg'
import gamingExpertBadge from '../badges/gamingExpert.svg'
import planetEvolutionBadge from '../badges/planetEvolution.svg'

export const MAX_POINTS = 5150
export const MAX_POINTS_WITHOUT_SCOOLCODE = 4650

export type DigitalMissionPhaseType = 'sweepstake' | 't-shirt'

export interface DigitalMissionPhase {
    title: string
    endDate: Dayjs
    requiredPoints: number
    type: DigitalMissionPhaseType
    successCatalogItemId: string
    sweepstakeIsHappend: boolean
    winnerCatalogItemID: string
}

// duplicated in: live_universe\index.ts
export const feedVotingItems = [
    '60cafcc027f8913c0c414b5e',
    '60d338398b0c8aec6e0e7b8f',
    '60dd73158cabe73fcaaf3c60',
    '60e6e277145965424bc58eb2',
    '60efd6367044645c64965578',
]

// duplicated in: live_universe\index.ts
export const digitalMissionPhases: ReadonlyArray<DigitalMissionPhase> = [
    {
        title: 'digitalMission2021::prizes::sweepstake01',
        type: 'sweepstake',
        endDate: dayjs('2021-07-05T00:00:00Z'),
        requiredPoints: 700,
        successCatalogItemId: '60b7951e7575f3defd9148eb',
        sweepstakeIsHappend: true,
        winnerCatalogItemID: '60e80d95973513c54b7cc3f2',
    },
    {
        title: 'digitalMission2021::prizes::sweepstake02',
        type: 'sweepstake',
        endDate: dayjs('2021-08-02T00:00:00Z'),
        requiredPoints: 1500,
        successCatalogItemId: '60b795fc7575f313c7914969',
        sweepstakeIsHappend: true,
        winnerCatalogItemID: '610d094497e145c7b77e2adf',
    },
    {
        title: 'digitalMission2021::prizes::sweepstake04',
        type: 't-shirt',
        endDate: dayjs('2021-08-30T06:00:00Z'),
        requiredPoints: 3500,
        successCatalogItemId: '60b7972b8aa07b508fc8f38e',
        sweepstakeIsHappend: false,
        winnerCatalogItemID: 'to be created',
    },
    {
        title: 'digitalMission2021::prizes::sweepstake03',
        type: 'sweepstake',
        endDate: dayjs('2021-08-30T06:00:00Z'),
        requiredPoints: 4000,
        successCatalogItemId: '60b797598aa07b70afc8f39e',
        sweepstakeIsHappend: false,
        winnerCatalogItemID: '6131e819bf26664d62eb9608',
    },
]

export const digitalMission2021AchievementsInventoryId = {
    ProFiller: '60b60261d10358a19804d54a',
    CookieContributor: '60b6028bd10358cc8204d54d',
    CookieCollector: '60b6021c0bbcc12d08be654e',
    NumberCrush: '60b602790bbcc1976cbe6555',
    FollowCollector: '60b6023c0bbcc1a73bbe6553',
    CosmicQuiz: '60c05f3babf30e89bb25982f',
    LaboratoryEscape: '60caf51727f8917138414931',
    FeedVoter: '60caf4bb27f8913b5141491c',
    WonderQuiz: '60d3387e48ccf204b32c23bc',
    QuestMaster: '60e2b02e3f25e0ff707a34a6',
    ProjectExplorer: '60dd74318cabe735ccaf3cac',
    HighScoreHero: '60e57f2ff8b7d29b92ec6d1a',
    marvel: '60efd4e27044642b75965570',
    projectCreator: '60efe4887044647c19965b8b',
    geographyexplorer: '60fa4e65c375634ea89df1b1',
    RobotOlympics: '61038045f26bdf163a1a8115',
    inventionmaster: '610bf7314995c5cc6c1a999a',
    CookieHunter: '6112781a8633ad40280a0f24',
    TrainerContestVoter: '61136eb66716d00e96a18335',
    TrainerFollower: '61136edb8633adcccb0a65a9',
    GamingExpert: '611df712938eaec271a984f5',
    PlanetBuilder: '611df72f4d04f3fcc011ac47',
}

export type DigitalMission2021AchievementData = {
    internalName: keyof typeof digitalMission2021AchievementsInventoryId
    imageUrl: string
    points: number
    catalogItem: string
    badgeContainerBgColor: string
    progress?: {
        current: number | ((props: StoreState) => number)
        required: number
    }
    hidden?: boolean | ((user: UserState) => boolean)
}

export const digitalMission2021achievementDataList: DigitalMission2021AchievementData[] = [
    {
        internalName: 'ProFiller',
        imageUrl: profillerBadge,
        points: 100,
        catalogItem: digitalMission2021AchievementsInventoryId.ProFiller,
        badgeContainerBgColor: '#c9efff',
    },
    {
        internalName: 'CookieContributor',
        imageUrl: cookieContributorBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.CookieContributor,
        badgeContainerBgColor: '#eedcd7',
        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:cookie-given'] ?? 0,
            required: 30,
        },
    },
    {
        internalName: 'CookieCollector',
        imageUrl: cookieCollectorBadge,
        points: 250,
        catalogItem: digitalMission2021AchievementsInventoryId.CookieCollector,
        badgeContainerBgColor: '#f3ffe4',
        hidden: user => !canUserCreateShareableProject(user),
        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:cookie-received'] ?? 0,
            required: 15,
        },
    },
    {
        internalName: 'NumberCrush',
        imageUrl: numberCrushBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.NumberCrush,
        badgeContainerBgColor: '#fff0b5',
    },
    {
        internalName: 'FollowCollector',
        imageUrl: followerBadge,
        points: 100,
        catalogItem: digitalMission2021AchievementsInventoryId.FollowCollector,
        badgeContainerBgColor: '#e1f1d8',
        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:followings'] ?? 0,
            required: 5,
        },
    },
    {
        internalName: 'CosmicQuiz',
        imageUrl: cosmicQuizBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.CosmicQuiz,
        badgeContainerBgColor: '#bad9f9',
    },
    {
        internalName: 'LaboratoryEscape',
        imageUrl: laboratoryEscapeBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.LaboratoryEscape,
        badgeContainerBgColor: '#ffdaf7',
    },
    {
        internalName: 'FeedVoter',
        imageUrl: feedVoterBadge,
        points: 250,
        catalogItem: digitalMission2021AchievementsInventoryId.FeedVoter,
        badgeContainerBgColor: '#f7ffcf',
        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:feed-votes'] ?? 0,
            required: 5,
        },
    },
    {
        internalName: 'WonderQuiz',
        imageUrl: wonderQuizBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.WonderQuiz,
        badgeContainerBgColor: '#ffe1b3',
    },
    {
        internalName: 'QuestMaster',
        imageUrl: questMasterBadge,
        points: 350,
        catalogItem: digitalMission2021AchievementsInventoryId.QuestMaster,
        badgeContainerBgColor: '#d6f5ea',
        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:completed-mini-challenges'] ?? 0,
            required: 10,
        },
    },
    {
        internalName: 'ProjectExplorer',
        imageUrl: projectExplorerBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.ProjectExplorer,
        badgeContainerBgColor: '#dbefff',

        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:view-given'] ?? 0,
            required: 15,
        },
    },
    {
        internalName: 'HighScoreHero',
        imageUrl: highScoreHeroBadge,
        points: 300,
        catalogItem: digitalMission2021AchievementsInventoryId.HighScoreHero,
        badgeContainerBgColor: '#fff9db',

        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:weekly-top100-presence'] ?? 0,
            required: 3,
        },
    },
    {
        internalName: 'marvel',
        imageUrl: marvelBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.marvel,
        badgeContainerBgColor: '#e7d2d2',
    },
    {
        internalName: 'projectCreator',
        imageUrl: projectCreatorBadge,
        points: 250,
        catalogItem: digitalMission2021AchievementsInventoryId.projectCreator,
        badgeContainerBgColor: '#cff1d1',
        hidden: user => !canUserCreateShareableProject(user),
    },
    {
        internalName: 'geographyexplorer',
        imageUrl: geographyexplorerBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.geographyexplorer,
        badgeContainerBgColor: '#ffe5bb',
    },
    {
        internalName: 'RobotOlympics',
        imageUrl: robotOlympicsBadge,
        points: 200,
        catalogItem: digitalMission2021AchievementsInventoryId.RobotOlympics,
        badgeContainerBgColor: '#c3e4e4',
    },
    {
        internalName: 'inventionmaster',
        imageUrl: inventionmasterBadge,
        points: 300,
        catalogItem: digitalMission2021AchievementsInventoryId.inventionmaster,
        badgeContainerBgColor: '#ead7ea',
    },
    {
        internalName: 'TrainerContestVoter',
        imageUrl: trainerContestVoterBadge,
        points: 350,
        catalogItem: digitalMission2021AchievementsInventoryId.TrainerContestVoter,
        badgeContainerBgColor: '#bacbff',

        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:trainer-contest-cookie-given'] ?? 0,
            required: 3,
        },
    },
    {
        internalName: 'CookieHunter',
        imageUrl: cookieHunterBadge,
        points: 250,
        catalogItem: digitalMission2021AchievementsInventoryId.CookieHunter,
        badgeContainerBgColor: '#fff9db',
    },
    {
        internalName: 'TrainerFollower',
        imageUrl: trainerFollowerBadge,
        points: 250,
        catalogItem: digitalMission2021AchievementsInventoryId.TrainerFollower,
        badgeContainerBgColor: '#a6c9ea',
        progress: {
            current: props => props.client.statistics['Seasons/DigitalMission:2021:trainer-followings'] ?? 0,
            required: 5,
        },
    },
    {
        internalName: 'GamingExpert',
        imageUrl: gamingExpertBadge,
        points: 300,
        catalogItem: digitalMission2021AchievementsInventoryId.GamingExpert,
        badgeContainerBgColor: '#ffbbbe',
    },
    {
        internalName: 'PlanetBuilder',
        imageUrl: planetEvolutionBadge,
        points: 300,
        catalogItem: digitalMission2021AchievementsInventoryId.PlanetBuilder,
        badgeContainerBgColor: '#c4ffd9',
    },
]

import { AxiosInstance } from 'axios'
import dayjs from 'dayjs'
import { ApiTypes } from 'lib/api'
import { ApiBase } from 'lib/api/ApiBase'
import { MINI_QUIZ_PROJECT_TAG } from '../helpers/miniQuizHelpers'
import { MiniQuiz, MiniQuizTemplate } from '../types/miniQuizTypes'

export class MiniQuizApi extends ApiBase {
    constructor(client: AxiosInstance, private getUserId: () => string | null) {
        super(client)
    }

    getTemplates = () => {
        return this.client.get<MiniQuizTemplate[]>(`mini-quiz-templates/my`)
    }

    getMiniQuiz = (id: string) => {
        return this.client.get<MiniQuiz>(`files/${id}/mini-quiz`)
    }

    saveMiniQuiz = async (id: string, quiz: MiniQuiz) => {
        return this.client.patch(`/files/${id}/mini-quiz`, quiz)
    }

    getMyMiniQuizzes = async (query: MyMiniQuizzesQuery) => {
        const params: Record<string, any> = {
            skip: query.skip,
            owner: this.getUserId(),
            limit: query.limit,
            sort: query.sort,
            'where[kind][in]': 'project/mix,project/blocks',
            'where[tags][eq]': MINI_QUIZ_PROJECT_TAG,
        }

        if (query.view === 'deleted') {
            params.deleted = true
            params.sort = '-deletedAt'
            params['where[deletedAt][gte]'] = dayjs().subtract(30, 'days').format()
        } else {
            params.deleted = false
        }

        if (query.search) {
            params['where[name][like]'] = query.search
        }

        if (query.view === 'favorites') {
            params.favorite = true
        }

        const res = await this.client.get<ApiTypes['file'][]>(`/files`, {
            params,
        })

        return {
            response: res,
            nextSkip: res.data.length < query.limit ? null : query.skip + query.limit,
            totalCount: parseInt(res.headers['x-total-count'], 10),
        }
    }

    createMiniQuiz = async (name: string) => {
        const res = await this.client.post<ApiTypes['file']>('/files/mini-quiz', {
            name,
        })
        return res
    }
}

export interface MyMiniQuizzesQuery {
    skip: number
    limit: number
    view: 'all' | 'favorites' | 'deleted'
    sort: 'name' | '-name' | 'createdAt' | '-createdAt' | 'updatedAt' | '-updatedAt'
    search?: string
}

import { SET_CURRENCY, SET_CURRENCY_REFILL } from 'lib/liveops/constants'
import { Reducer } from 'redux'
import { PRESENT } from 'redux-socket-client'

export interface CurrencyValue {
    value: number | null
    refillsAt?: string
    refillAfterMinutes?: number
}

export interface CurrencyState {
    cookie: CurrencyValue
    'golden-cookie': CurrencyValue
}

export type CurrencyName = keyof CurrencyState

export const currencyReducer: Reducer<CurrencyState> = (
    state = { cookie: { value: null }, 'golden-cookie': { value: null } },
    { type, payload = {} },
) => {
    switch (type) {
        case SET_CURRENCY:
            return {
                ...state,
                [payload.currency]: { ...((state as any)[payload.currency] || {}), value: payload.value },
            }
        case SET_CURRENCY_REFILL:
            return {
                ...state,
                [payload.currency]: { ...((state as any)[payload.currency] || {}), refillsAt: payload.refillsAt },
            }
        case PRESENT:
            return payload.state.client.currency
        default:
            return state
    }
}

import { CREATE_GENERATED_QUIZ_ROOM } from 'lib/liveops/constants'
import { client } from 'redux-socket-client'

export type GeneratedQuizDifficulty = 'easy' | 'medium' | 'hard'
export type GeneratedQuizMode = 'single-player' | 'multi-player'
export type GeneratedQuizSpeed = 'normal' | 'fast'

export const createGeneratedQuizRoom = client(
    (
        numberOfQuestions: number,
        difficulty: 'easy' | 'medium' | 'hard',
        mode: 'single-player' | 'multi-player',
        speed?: 'normal' | 'fast',
    ) => ({
        type: CREATE_GENERATED_QUIZ_ROOM,
        payload: {
            numberOfQuestions,
            difficulty,
            speed,
            mode,
        },
    }),
)

import React from 'react'
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@material-ui/core'
import styled from 'styled-components/macro'

interface TabsProps extends MuiTabsProps<any> {}

export const Tabs: React.FC<TabsProps> = props => {
    return <Root indicatorColor="primary" {...props} />
}

const Root = styled(MuiTabs)`
    .MuiTabs-indicator {
        display: flex;
        justify-content: center;
        background-color: transparent;
        height: 4px;
        background-color: ${props => props.theme.palette.primary.main};
        border-radius: 10px;
        ${props => props.theme.extras?.tabs?.indicator}
    }
`

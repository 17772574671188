export function countTruthyValues(array: any[]) {
    return array.filter(Boolean).length
}

export function filterNulls<T>(item: T | null): item is T {
    return item !== null
}

export function isNotUndefined<T>(item: T | undefined): item is T {
    return item !== undefined
}

export function getNRandomElements<T>(array: T[], n: number) {
    return [...array].sort(() => 0.5 - Math.random()).slice(0, n)
}

export function shuffleArray<T>(array: T[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[array[i], array[j]] = [array[j], array[i]]
    }
    return array
}

import React from 'react'
import styled from 'styled-components/macro'
import { PROJECT_MODAL_MOBILE_BREAKPOINT } from './ProjectModalBase'

export const ProjectModalHeader: React.FC = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled.div`
    background-color: ${props => props.theme.palette.background.paper};
    border-bottom: solid 1px ${props => props.theme.palette.divider};
    padding: ${props => props.theme.spacing(4, 10, 4, 4)};
    ${props => props.theme.breakpoints.down(PROJECT_MODAL_MOBILE_BREAKPOINT)} {
        padding-right: ${props => props.theme.spacing(4)};
    }
`

import { isMiniQuizProject } from 'features/mini-quizzes/helpers/miniQuizHelpers'
import produce from 'immer'
import { Reducer } from 'redux'
import { UPDATE_HAS_USER_THUMBNAIL } from '.'
import { CLASSROOM_PROJECT_TAG } from '../ProjectsApi'
import {
    ProjectReducerState,
    ProjectAction,
    FETCH_SUCCESS,
    Project,
    UPDATE_METADATA,
    RENAME_PROJECT,
    FAVORITE_PROJECT,
    UNFAVORITE_PROJECT,
    FAVORITE_OWN_PROJECT,
    UNFAVORITE_OWN_PROJECT,
    DELETE_PROJECT,
    RESTORE_PROJECT,
    UNSHARE_PROJECT,
    GIVE_COOKIE_REQUEST,
    UPDATE_DETAILS,
    SHARE_PROJECT_WITHOUT_APPROVE,
    UPDATE_FILE,
} from './projectTypes'

export const projectReducer: Reducer<ProjectReducerState, ProjectAction> = (state = { all: {} }, action) => {
    switch (action.type) {
        case FETCH_SUCCESS:
            return produce(state, draft => {
                for (const project of action.payload.projects) {
                    const id = project.file.id
                    const file: Project['file'] = {
                        id: project.file.id,
                        cookieCount: project.file.cookieCount,
                        goldenCookieCount: project.file.goldenCookieCount ?? 0,
                        owner: project.file.owner,
                        title: project.file.title,
                        name: project.file.name,
                        shared: project.file.shared,
                        favorite: project.file.favorite,
                        lang: project.file.lang,
                        createdAt: project.file.createdAt,
                        updatedAt: project.file.updatedAt,
                        kind: project.file.kind,
                        metadata: project.file.metadata,
                        deleted: project.file.deleted,
                        deletedAt: project.file.deletedAt,
                        approvedAt: project.file.approvedAt,
                        viewedCount: project.file.viewedCount,
                        likedCount: project.file.likedCount,
                        tags: project.file.tags,
                        shareSpecial: project.file.shareSpecial,
                        daysViewedCount_1: project.file.daysViewedCount_1,
                        daysViewedCount_7: project.file.daysViewedCount_7,
                        daysViewedCount_30: project.file.daysViewedCount_30,
                        hasUserThumbnail: project.file.hasUserThumbnail,
                        publicViewedCount: project.file.publicViewedCount,
                        playedCount: project.file.playedCount,
                        labels: project.file.labels,
                    }
                    const owner = project.metadata.owner
                        ? {
                              ...draft.all[id]?.metadata?.owner,
                              id: project.metadata.owner.id,
                              username: project.metadata.owner.username,
                              alias: project.metadata.owner.alias,
                              profileImage: project.metadata.owner.profileImage,
                              fullName: project.metadata.owner.fullName,
                              primaryKind: project.metadata.owner.primaryKind,
                          }
                        : undefined

                    const isMiniQuiz = isMiniQuizProject(project.file)

                    if (draft.all[id] === undefined) {
                        draft.all[id] = {
                            file,
                            metadata: {
                                ...project.metadata,
                                shareable: !project.file.tags?.includes(CLASSROOM_PROJECT_TAG),
                                owner,
                                isMiniQuiz,
                                numberOfQuestions: project.file.metadata?.numberOfQuestions,
                            },
                        }
                        continue
                    }

                    draft.all[id].file = file
                    draft.all[id].metadata.cookied = project.metadata.cookied ?? draft.all[id].metadata.cookied
                    draft.all[id].metadata.goldenCookied =
                        project.metadata.goldenCookied ?? draft.all[id].metadata.goldenCookied
                    draft.all[id].metadata.details = project.metadata.details ?? draft.all[id].metadata.details
                    draft.all[id].metadata.favorited = project.metadata.favorited ?? draft.all[id].metadata.favorited
                    draft.all[id].metadata.owner = owner ?? draft.all[id].metadata.owner
                    draft.all[id].metadata.shareable = !project.file.tags?.includes(CLASSROOM_PROJECT_TAG)
                    draft.all[id].metadata.isMiniQuiz = isMiniQuiz
                    draft.all[id].metadata.numberOfQuestions =
                        project.file.metadata?.numberOfQuestions ?? project.metadata?.numberOfQuestions
                    draft.all[id].metadata.seen = project.metadata.seen ?? draft.all[id].metadata.seen
                }
            })
        case UPDATE_FILE:
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file = {
                        ...project.file,
                        ...action.payload.updates,
                    }
                }
            })
        case UPDATE_METADATA:
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.metadata.cookied = action.payload.metadata.cookied ?? project.metadata.cookied
                    project.metadata.goldenCookied =
                        action.payload.metadata.goldenCookied ?? project.metadata.goldenCookied
                    project.metadata.seen = action.payload.metadata.seen ?? project.metadata.seen
                    project.metadata.details = {
                        ...project.metadata.details,
                        ...action.payload.metadata.details,
                    }
                    project.metadata.favorited = action.payload.metadata.favorited ?? project.metadata.favorited
                    if (project.metadata.numberOfQuestions) {
                        project.metadata.numberOfQuestions.normal =
                            action.payload.metadata.numberOfQuestions?.normal ??
                            project.metadata.numberOfQuestions?.normal
                    }
                }
            })

        case GIVE_COOKIE_REQUEST: {
            const currencyToFileField = { cookie: 'cookieCount', 'golden-cookie': 'goldenCookieCount' } as const
            const currencyToMetadataField = { cookie: 'cookied', 'golden-cookie': 'goldenCookied' } as const

            return handleProjectTransaction(
                state,
                action as any,
                id => draft => {
                    const project = draft.all[id]
                    project.file[currencyToFileField[action.payload.currency]]!++
                    project.metadata[currencyToMetadataField[action.payload.currency]] = true
                },
                id => draft => {
                    const project = draft.all[id]
                    project.file[currencyToFileField[action.payload.currency]]!--
                    project.metadata[currencyToMetadataField[action.payload.currency]] = false
                },
            )
        }

        case RENAME_PROJECT:
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file[action.payload.field] = action.payload.value
                }
            })

        case FAVORITE_PROJECT: {
            return produce(state, draft => {
                const project = draft.all[action.payload.project]
                if (project) {
                    project.metadata.favorited = true
                    if (project.file.likedCount !== undefined) {
                        project.file.likedCount++
                    }
                }
            })
        }

        case UNFAVORITE_PROJECT: {
            return produce(state, draft => {
                const project = draft.all[action.payload.project]
                if (project) {
                    project.metadata.favorited = false
                    if (project.file.likedCount !== undefined) {
                        project.file.likedCount--
                    }
                }
            })
        }

        case FAVORITE_OWN_PROJECT: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file.favorite = true
                }
            })
        }

        case UNFAVORITE_OWN_PROJECT: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file.favorite = false
                }
            })
        }

        case DELETE_PROJECT: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file.deleted = true
                }
            })
        }

        case RESTORE_PROJECT: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file.deleted = false
                }
            })
        }

        case UNSHARE_PROJECT: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file.shared = false
                }
            })
        }

        case SHARE_PROJECT_WITHOUT_APPROVE: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file.shared = true
                }
            })
        }

        case UPDATE_DETAILS: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.metadata.details = {
                        ...project.metadata.details,
                        ...{ [action.payload.variant]: action.payload.details },
                    }
                }
            })
        }
        case UPDATE_HAS_USER_THUMBNAIL: {
            return produce(state, draft => {
                const project = draft.all[action.payload.id]
                if (project) {
                    project.file.hasUserThumbnail = true
                }
            })
        }
    }
    return state
}

const transactionToProject: Record<string, string> = {}
type ProjectTransactionAction = {
    payload: { project: string; status?: string }
    meta: { context: any; transaction: string }
}

function handleProjectTransaction(
    state: ProjectReducerState,
    action: ProjectTransactionAction,
    sucessReceipe: (project: string) => (state: ProjectReducerState) => void,
    errorReceipe: (project: string) => (state: ProjectReducerState) => void,
) {
    let { project, status } = action.payload
    const { transaction, context } = action.meta
    if (status) {
        project = transactionToProject[transaction]
        if (project) {
            if (status === 'done') {
                delete transactionToProject[transaction]
            } else if (status === 'error') {
                delete transactionToProject[transaction]
                console.error(GIVE_COOKIE_REQUEST, context.error)
                //TODO: Handle errors
                return produce(state, errorReceipe(project))
            }
        }
    } else {
        transactionToProject[transaction] = project
        return produce(state, sucessReceipe(project))
    }
    return state
}

import { Skeleton } from '@material-ui/lab'
import { Stack, Inline } from 'components/layout'
import React from 'react'
import { PageHeader } from './PageHeader'

export const PageSkeleton: React.FC = () => {
    return (
        <PageHeader
            title={
                <Stack spacing={8}>
                    <Skeleton width="10rem" height="2rem" />
                    <Inline display="flex">
                        <Skeleton width="8rem" />
                        <Skeleton width="5rem" />
                    </Inline>
                </Stack>
            }
        />
    )
}

import dayjs from 'dayjs'
import {
    ActivityEvent,
    ActivityEventMilestoneTrigger,
    NormalizedActivityEvent,
    NormalizedActivityEventMilestone,
    NormalizedActivityEventSubEvent,
    NormalizedActivityEventTask,
} from '../types/activityEventTypes'
import { getTotalStatGroupForActivityEvent } from './activityEventHelpers'

export const normalizeActivityEvent = (event: ActivityEvent): NormalizedActivityEvent => {
    return {
        ...event,
        startDate: dayjs(event.startDate),
        endDate: dayjs(event.endDate),
        subEvents: normalizeSubEvents(event),
        tasks: normalizeTasks(event),
        milestones: normalizeMilestones(event),
        subEventLayout: event.subEventLayout ?? 'normal',
    }
}

const normalizeTasks = (event: ActivityEvent): NormalizedActivityEventTask[] => {
    const result: NormalizedActivityEventTask[] = []
    const processedGroups = new Set<string>()

    for (const task of event.tasks) {
        if (task.group) {
            if (processedGroups.has(task.group)) {
                continue
            }
            const sameGroupTasks = event.tasks
                .filter(t => t.group === task.group)
                .sort((a, b) => {
                    if (a.groupIndex === undefined || b.groupIndex === undefined) {
                        return 0
                    }
                    return a.groupIndex > b.groupIndex ? 1 : -1
                })
            result.push({
                ...task,
                group: task.group || '',
                variants: sameGroupTasks.map(task => ({
                    activityStat: task.activityStat,
                    point: task.point,
                    label: task.nameKey,
                })),
            })
            processedGroups.add(task.group)
        } else {
            result.push({
                ...task,
                group: '',
                variants: [{ activityStat: task.activityStat, point: task.point, label: task.nameKey }],
            })
        }
    }

    return result
}

const normalizeSubEvents = (event: ActivityEvent): NormalizedActivityEventSubEvent[] => {
    return event.subEvents
        .map(subEvent => {
            const scoreStatGroup =
                subEvent.startDate || subEvent.endDate
                    ? `${getTotalStatGroupForActivityEvent(event.slug)}/${subEvent.key}`
                    : getTotalStatGroupForActivityEvent(event.slug)
            return {
                ...subEvent,
                startDate: subEvent.startDate ? dayjs(subEvent.startDate) : dayjs(event.startDate),
                endDate: subEvent.endDate ? dayjs(subEvent.endDate) : dayjs(event.endDate),
                scoreStatGroup,
            }
        })
        .sort((a, b) => {
            if (a.endDate.isBefore(dayjs()) || a.startDate.isAfter(dayjs())) {
                return 1
            }
            if (b.endDate.isBefore(dayjs()) || b.startDate.isAfter(dayjs())) {
                return -1
            }
            if (a.order === undefined || b.order === undefined || a.order === b.order) {
                return 0
            }
            return a.order > b.order ? 1 : -1
        })
}

const normalizeMilestones = (event: ActivityEvent): NormalizedActivityEventMilestone[] => {
    return event.milestones.map(milestone => ({
        ...milestone,
        tooltip: milestone.trigger ? milestoneTooltipKeyByTriggerAction[milestone.trigger.action] : undefined,
        link: milestone.trigger ? milestoneLinkKeyByTriggerAction[milestone.trigger.action] : undefined,
        titleKey:
            milestone.titleKey || (milestone.trigger ? milestoneTitleKeyByTriggerAction[milestone.trigger.action] : ''),
    }))
}

const milestoneTooltipKeyByTriggerAction: Record<ActivityEventMilestoneTrigger['action'], string> = {
    UNLOCK_PROFILE_PICTURE: 'profilepicture::tooltip::CollectNewbieMissionPoints',
}

const milestoneTitleKeyByTriggerAction: Record<ActivityEventMilestoneTrigger['action'], string> = {
    UNLOCK_PROFILE_PICTURE: 'settings::profilPicture',
}

const milestoneLinkKeyByTriggerAction: Record<ActivityEventMilestoneTrigger['action'], string> = {
    UNLOCK_PROFILE_PICTURE: '/app/settings/profile#avatar-browser',
}

import { CampIcon } from 'components/icons'
import { Panel, PanelHeader, PanelTitle } from 'components/panel'
import { Text } from 'components/Text'
import { useDateFormat } from 'hooks/useDateFormat'
import { CrmUserData } from 'lib/api'
import React from 'react'
import styled from 'styled-components/macro'

interface ProfileSubjectsProps {
    crmData: CrmUserData
}

export const ProfileSubjects: React.FC<ProfileSubjectsProps> = ({ crmData }) => {
    const { formatDate } = useDateFormat('year-month')

    if (!(crmData.subjectData?.completed && crmData.subjectData.completed.length > 0)) {
        return null
    }

    return (
        <Panel>
            <PanelHeader>
                <PanelTitle>Subjects</PanelTitle>
            </PanelHeader>
            <StyledUl>
                {[...crmData.subjectData.completed].reverse().map(subject => (
                    // @ts-ignore Component prop typing not working
                    <Item key={subject.submission_id} component="li" $isCurrent={subject.state === 'accepted'}>
                        {subject.subject_key} {subject.format === 'camp' && <CampIcon />}{' '}
                        <span>(End date: {formatDate(new Date(subject.endDate))})</span>
                    </Item>
                ))}
            </StyledUl>
        </Panel>
    )
}

const Item = styled(Text)<{ $isCurrent: boolean }>`
    font-weight: ${props => (props.$isCurrent ? 800 : undefined)};
`

const StyledUl = styled.ul`
    list-style: none;
    padding: 0;
    margin-bottom: 0;
`

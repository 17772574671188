import { Flex } from 'components/layout'
import { getUserPublicName } from 'contexts/UserContext'
import { Avatar, createAvatarUrl } from 'features/avatar'
import { UserBadge } from 'features/profile'
import { MinimalAccount } from 'lib/api'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

type ProjectCardUserProps = {
    user?: MinimalAccount
    className?: string
}

export const ProjectCardUser: React.FC<ProjectCardUserProps> = ({ user, className }) => {
    if (!user) {
        return null
    }

    return (
        <Flex className={className}>
            <StyledLink to={`/app/user/${user.username}`}>
                <StyledAvatar size="tiny" src={createAvatarUrl(user.profileImage)} />
                <Name>
                    {getUserPublicName(user)}
                    <StyledUserBadge user={user} />
                </Name>
            </StyledLink>
        </Flex>
    )
}

const StyledUserBadge = styled(UserBadge)`
    font-size: ${props => props.theme.typography.body1.fontSize};
    margin-inline-start: ${props => props.theme.spacing(1)};
`

const StyledAvatar = styled(Avatar)`
    flex-shrink: 0;
`

const StyledLink = styled(Link)`
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }
    > * + * {
        margin-inline-start: ${props => props.theme.spacing(2)};
    }
`

const Name = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

import { ActionCard } from 'components/action-card'
import { Card, CardContent } from 'components/card'
import { Img } from 'components/Img'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { LockedChallengeOverlay } from 'features/limited-accounts'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Trans } from 'react-i18next'
import styled, { css } from 'styled-components/macro'
import { QuizSetWithEmbeddedTemplates } from '../QuizApi'
import fallbackThumbnail from './fallback-thumbnail.png'

type NormalizedQuizSets = Omit<QuizSetWithEmbeddedTemplates, 'templates'> & {
    templates: { id: string; isFinished: boolean }[]
}

interface HomeQuizCardProps {
    set: NormalizedQuizSets
}

export const HomeQuizCard: React.FC<HomeQuizCardProps> = ({ set }) => {
    const t = useTranslate()

    const progress = (() => {
        let value = 0
        set.templates.forEach(template => {
            if (template.isFinished) {
                value++
            }
        })
        return value
    })()

    const body = (
        <>
            <LockedChallengeOverlay isLocked={set.locked} grant={set.grantRequiredToUnlock}>
                <ThumbnailWrap>
                    <Thumbnail src={set.thumbnail || fallbackThumbnail} />
                </ThumbnailWrap>
            </LockedChallengeOverlay>
            <CardContent>
                <Stack spacing={2}>
                    <div>
                        {set.tags && (
                            <Text variant="body2" color="textSecondary">
                                {set.tags.map(tag => t(tag as LocalisationKey)).join(', ')}
                            </Text>
                        )}
                        <Text variant="h3">{t(set.name as LocalisationKey)}</Text>
                    </div>
                    <Progress max={set.templates.length} value={progress} />
                    <Text variant="body2">
                        <Trans
                            i18nKey="quiz::nCompleted"
                            values={{
                                value: progress,
                                max: set.templates.length,
                            }}
                            components={{
                                strong: <strong />,
                            }}
                        />
                    </Text>
                </Stack>
            </CardContent>
        </>
    )

    return set.locked ? (
        <StyledCard>{body}</StyledCard>
    ) : (
        <StyledActionCard type="link" to={`/app/challenges/quizzes/${set.id}`}>
            {body}
        </StyledActionCard>
    )
}

const ThumbnailWrap = styled.div`
    overflow: hidden;
`

const Thumbnail = styled(Img)`
    display: block;
    height: 200px;
    width: 100%;
    object-fit: cover;
    transition: transform ${props => props.theme.transitions.duration.standard}ms;
    background-color: ${props => props.theme.palette.divider};
`

const cardStyles = css`
    height: 100%;
`

const StyledCard = styled(Card)`
    ${cardStyles}
`

const StyledActionCard = styled(ActionCard)`
    ${cardStyles}
    &:hover,
    &:focus-visible {
        ${Thumbnail} {
            transform: scale(1.05);
        }
    }
` as typeof ActionCard

const Progress: React.FC<{ max: number; value: number }> = ({ value, max }) => {
    return (
        <ProgressRoot>
            <DotWrap>
                {new Array(max - 1).fill(true).map((_, i) => (
                    <Tick key={i} />
                ))}
            </DotWrap>
            <ProgressInner style={{ width: `${(value / max) * 100}%` }} />
        </ProgressRoot>
    )
}

const ProgressRoot = styled.div`
    position: relative;
    height: 0.75rem;
    background-color: ${props => props.theme.palette.divider};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    overflow: hidden;
`
const ProgressInner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: ${props => props.theme.palette.primary.main};
`

const DotWrap = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
`

const Tick = styled.div`
    width: 1px;
    height: 50px;
    background-color: ${props => props.theme.palette.background.paper};
`

import { Panel, PanelHeader, PanelTitle, PanelActions, PanelMoreButton } from 'components/panel'
import { Skeleton } from 'components/Skeleton'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { HomeQuizCard } from '.'
import { HomeQuizGrid } from './HomeQuizGrid'
import { useQuizSetSessionsQuery, useRecommendedQuizSetsQuery } from './homeQuizQueries'
import { useHasGenerateQuizAccess } from '../quizHelpers'
import { GenerateQuizCard } from '../generated-quiz'

interface RecommendedHomeQuizzesProps extends PoorMansSuspenseTicker {}

export const RecommendedHomeQuizzes: React.FC<RecommendedHomeQuizzesProps> = ({ onReady }) => {
    const t = useTranslate()
    const { data: sets, status: setsStatus } = useRecommendedQuizSetsQuery()
    const { data: sessions, status: sessionsStatus } = useQuizSetSessionsQuery(sets)
    usePoorMansSuspenseTick(sessionsStatus !== 'idle' && sessionsStatus !== 'loading', onReady)
    const hasGenerateQuizAccess = useHasGenerateQuizAccess()

    if (setsStatus === 'error' || sessionsStatus === 'error' || sets?.length === 0) {
        return null
    }

    return (
        <Panel>
            <PanelHeader>
                <PanelTitle>{t('quiz::quizzes')}</PanelTitle>
                <PanelActions>
                    <PanelMoreButton activity="challenges:view-more" to="/app/challenges/quizzes">
                        {t('quiz:moreQuizzes')}
                    </PanelMoreButton>
                </PanelActions>
            </PanelHeader>
            <HomeQuizGrid>
                {hasGenerateQuizAccess && <GenerateQuizCard />}
                {sets && sessions ? (
                    (hasGenerateQuizAccess ? sets.slice(1, 3) : sets).map(set => (
                        <HomeQuizCard
                            key={set.id}
                            set={{
                                ...set,
                                templates: set.templates.map(template => {
                                    return {
                                        id: template,
                                        isFinished: sessions[template]?.status === 'finished' ? true : false,
                                    }
                                }),
                            }}
                        />
                    ))
                ) : (
                    <RecommendedMiniChallengesSkeleton />
                )}
            </HomeQuizGrid>
        </Panel>
    )
}

const RecommendedMiniChallengesSkeleton: React.FC = () => {
    return (
        <>
            {new Array(3).fill(true).map((_, i) => (
                <StyledSkeleton variant="rect" key={i} />
            ))}
        </>
    )
}

const StyledSkeleton = styled(Skeleton)`
    width: 100%;
    height: 0;
    padding-bottom: 87%;
`

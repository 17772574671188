import { ActionCard } from 'components/action-card'
import { Card } from 'components/card'
import React from 'react'
import { useContext } from 'react'
import { LinkProps } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

interface AchievementCardProps {
    to?: LinkProps['to']
    isAcquired: boolean
}

interface AchievementCardContextState {
    isAcquired: boolean
}

const achievementCardContext = React.createContext<AchievementCardContextState | undefined>(undefined)

export const useAchievementCardContext = () => {
    const state = useContext(achievementCardContext)

    if (state === undefined) {
        throw new Error("'useAchievementCardContext' must be used within an 'AchievementCard'.")
    }

    return state
}

export const AchievementCard: React.FC<AchievementCardProps> = ({ to, children, isAcquired }) => {
    return (
        <achievementCardContext.Provider value={{ isAcquired }}>
            {to ? (
                <StyledActionCard type="link" to={to}>
                    {children}
                </StyledActionCard>
            ) : (
                <StyledCard>{children}</StyledCard>
            )}
        </achievementCardContext.Provider>
    )
}

const cardStyles = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 350px;
`

const StyledCard = styled(Card)`
    ${cardStyles}
`

const StyledActionCard = styled(ActionCard)`
    ${cardStyles}
    &:hover,
    &:focus-visible {
        img {
            transform: scale(1.05);
        }
    }
` as typeof ActionCard

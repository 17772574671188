import { Grant, GrantFeature, useHasGrant } from 'lib/grants'
import * as React from 'react'

interface Props {
    grant: Grant | GrantFeature
    fallback?: React.ReactNode
}

export const Authorize: React.FC<Props> = ({ children, grant, fallback }) => {
    const hasGrant = useHasGrant()
    if (grant === undefined) {
        return <>{children}</>
    }

    return <>{hasGrant(grant) ? children : fallback || null}</>
}

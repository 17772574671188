import React from 'react'
import { createProjectThumbnailUrl, doesProjectHaveNewVersion } from '../projectHelpers'
import { Project } from '../store'
import { ProjectCard, ProjectCardImage, ProjectCardCookieCount, ProjectCardContent, ProjectCardBadge } from '../card'
import { MyProjectBlockGallery } from './MyProjectBlockGallery'
import { MyProjectCardActionsMenu } from './MyProjectCardActionsMenu'
import { useBlockGalleriesQuery } from '../projectQueries'
import { ProjectStripe } from '../stripe'
import { Badge, BadgeProps } from 'components/Badge'
import { MyProjectStripeActions } from './MyProjectStripeActions'
import { MyCustomRightClickMenu } from './MyCustomRightClickMenu'
import { ProjectActionsMenu } from '../ProjectActionsMenu'
import { MinimalAccount } from 'lib/api'
import { ProjectCardFavoriteBadge } from '../card/ProjectCardFavoriteBadge'
import { useTranslate } from 'lib/i18n/useTranslate'
import { CommunityIcon } from 'components/icons'
import { ProjectListLayout } from './MyProjects'
import { MyProjectLanguage } from './MyProjectLanguage'
import { useHasGrant } from '../../../lib/grants'

interface MyCommunityProjectCardProps {
    project: Project
    user: MinimalAccount
    blockGalleriesQuery?: ReturnType<typeof useBlockGalleriesQuery>
    isPending?: boolean
    layout: ProjectListLayout
}

export const MyCommunityProjectCard: React.FC<MyCommunityProjectCardProps> = ({
    project,
    blockGalleriesQuery,
    isPending,
    layout,
}) => {
    const t = useTranslate()
    const hasNewVersion = doesProjectHaveNewVersion(project)
    const to = `/app/project/${project.file.id}`
    const title =
        project.file.shared || isPending === true ? project.file.title || project.file.name : project.file.name
    const displayPendingBadge = isPending

    const newVersion = hasNewVersion && isPending === false
    const thumbnail = createProjectThumbnailUrl(project.file.id, project.file.shared ? 'production' : 'normal')

    const hasGrant = useHasGrant()
    const shareableBadge =
        hasGrant('share-project-user-consent') && !project.file.shared ? null : (
            <ShareableBadge layout={layout} shared={project.file.shared} newVersion={newVersion} />
        )
    const languageBadge = <MyProjectLanguage project={project} layout={layout} />
    const blockGalleryBadge = (
        <MyProjectBlockGallery layout={layout} project={project} blockGalleriesQuery={blockGalleriesQuery} />
    )

    if (layout === 'list') {
        return (
            <MyCustomRightClickMenu
                menu={
                    <ProjectActionsMenu location="stripe" isPending={isPending} id={project.file.id} isInContextMenu />
                }
            >
                <ProjectStripe
                    thumbnailUrl={thumbnail}
                    tags={
                        <>
                            {shareableBadge}
                            {displayPendingBadge && <Badge>{t('projects::pendingApproval')}</Badge>}
                        </>
                    }
                    metadata={
                        <>
                            {languageBadge}
                            {blockGalleryBadge}
                        </>
                    }
                    title={title}
                    to={to}
                    actions={<MyProjectStripeActions project={project} isPending={isPending} />}
                    favorite={<ProjectCardFavoriteBadge project={project} inList />}
                />
            </MyCustomRightClickMenu>
        )
    }

    return (
        <ProjectCard>
            <MyCustomRightClickMenu
                menu={<ProjectActionsMenu location="card" isPending={isPending} id={project.file.id} isInContextMenu />}
            >
                <ProjectCardImage
                    to={to}
                    src={thumbnail}
                    badges={
                        <>
                            {shareableBadge}
                            {project.file.shared && (
                                <ProjectCardCookieCount
                                    count={project.file.cookieCount}
                                    goldenCount={project.file.goldenCookieCount}
                                    cookied={false}
                                />
                            )}
                            {displayPendingBadge && (
                                <ProjectCardBadge position="bottom-left">
                                    {t('projects::pendingApproval')}
                                </ProjectCardBadge>
                            )}
                            {blockGalleryBadge}
                            {languageBadge}
                        </>
                    }
                    outerBadges={<ProjectCardFavoriteBadge project={project} />}
                ></ProjectCardImage>
            </MyCustomRightClickMenu>
            <ProjectCardContent
                title={{ label: title, to }}
                action={
                    <MyProjectCardActionsMenu
                        location="card"
                        isPending={isPending}
                        compensatePadding
                        id={project.file.id}
                    />
                }
            ></ProjectCardContent>
        </ProjectCard>
    )
}

const ShareableBadge: React.FC<{ layout: ProjectListLayout; shared?: boolean; newVersion: boolean }> = ({
    shared,
    newVersion,
    layout,
}) => {
    const t = useTranslate()

    const badgeProps: BadgeProps = (() => {
        if (shared) {
            return {
                color: '#2d3057',
                backgroundColor: newVersion ? '#f4a103' : '#f4d003',
                children: newVersion ? t('projects::newVersionAvailable') : t('projects::shared'),
                icon: <CommunityIcon />,
            }
        }
        return {
            color: '#f4d003',
            backgroundColor: '#2a2b35',
            children: t('projects::community'),
            icon: <CommunityIcon />,
        }
    })()

    switch (layout) {
        case 'list':
            return <Badge {...badgeProps} />
        case 'grid':
            return <ProjectCardBadge position="top-right" {...badgeProps} />
    }
}

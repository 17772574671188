import { DigitalMission2021AchievementData } from './data/digitalMission2021Data'
import React from 'react'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useDateFormat } from 'hooks/useDateFormat'
import { getDigitalMission2021AchievementI18nKeys } from './helpers/digitalMission2021Helpers'
import {
    AchievementCard,
    AchievementCardContent,
    AchievementCardDescription,
    AchievementCardFooterText,
    AchievementCardImage,
    AchievementCardImageWrap,
    AchievementCardPoints,
    AchievementCardTitle,
} from 'components/achievement-card'

interface DigitalMission2021AchivementProps {
    achivement: DigitalMission2021AchievementData
    acquiredAt?: Date
    progress?: {
        current: number
        required: number
    }
}

export const DigitalMission2021Achivement: React.FC<DigitalMission2021AchivementProps> = ({
    achivement,
    acquiredAt,
    progress,
}) => {
    const t = useTranslate()

    const dateFormat = useDateFormat('long')
    const { name, description } = getDigitalMission2021AchievementI18nKeys(achivement.internalName)

    return (
        <AchievementCard isAcquired={!!acquiredAt}>
            <AchievementCardImageWrap bgColor={achivement.badgeContainerBgColor}>
                <AchievementCardImage src={achivement.imageUrl} />
                <AchievementCardPoints bgColor={achivement.badgeContainerBgColor}>
                    {achivement.points}
                </AchievementCardPoints>
            </AchievementCardImageWrap>
            <AchievementCardContent>
                <AchievementCardTitle>{t(name)}</AchievementCardTitle>
                <AchievementCardDescription>{t(description)}</AchievementCardDescription>
            </AchievementCardContent>
            {!!acquiredAt ? (
                <AchievementCardFooterText>{dateFormat.formatDate(new Date(acquiredAt))}</AchievementCardFooterText>
            ) : null}
            {!acquiredAt && progress ? (
                <AchievementCardFooterText>
                    {progress.current} / {progress.required}
                </AchievementCardFooterText>
            ) : null}
        </AchievementCard>
    )
}

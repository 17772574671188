import { useUserState } from 'contexts/UserContext'
import dayjs, { Dayjs } from 'dayjs'
import { useMemo } from 'react'
import { useDateFormat } from './useDateFormat'

export const useRelativeTimeFormat = () => {
    const { account } = useUserState()
    const { formatDate } = useDateFormat()

    const formatter = useMemo(() => {
        if ('Intl' in window && 'RelativeTimeFormat' in Intl) {
            return new Intl.RelativeTimeFormat(account?.uiLanguage || 'en-US', { numeric: 'auto', style: 'long' })
        }
    }, [account])

    return {
        getRelativeTime: (date: Dayjs) => {
            if (!formatter) {
                return formatDate(date.toDate())
            }

            const diffInDays = date.diff(dayjs(), 'day')
            if (diffInDays === 0) {
                const diffInHours = date.diff(dayjs(), 'hour')
                return formatter.format(diffInHours, 'hour')
            }
            if (diffInDays < -31) {
                const diffInMonths = date.diff(dayjs(), 'month')
                return formatter.format(diffInMonths, 'month')
            }
            return formatter.format(diffInDays, 'days')
        },
    }
}

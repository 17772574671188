import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { QueuedDialog } from './QueuedDialog'
import image from 'features/events/pages/onboarding/media/prize1.svg'

interface OnboardingPointsReachedDialogProps {
    open: boolean
    onClose: VoidFunction
}

export const OnboardingPointsReachedDialog: React.FC<OnboardingPointsReachedDialogProps> = ({ open, onClose }) => {
    const t = useTranslate()

    return (
        <QueuedDialog
            open={open}
            onClose={onClose}
            image={{ url: image, backgroundColor: '#fff' }}
            title={t('onboardingMission::missionUnlockedPopUp::title')}
            description={t('onboardingMission::missionUnlockedPopUp::description')}
        />
    )
}

import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Badge, BadgeProps } from 'components/Badge'

type Position = 'top-left' | 'top-right' | 'bottom-right' | 'bottom-left' | 'center'

export interface ProjectCardBadgeProps extends BadgeProps {
    position?: Position
    component?: any
    hoverOnly?: boolean
    onClick?: () => void
}

export const ProjectCardBadge: React.FC<ProjectCardBadgeProps> = ({
    children,
    position = 'bottom-right',
    className,
    component,
    hoverOnly,
    onClick,
    ...rest
}) => {
    return (
        <ProjectCardBadgeRoot
            hoverOnly={hoverOnly}
            as={component}
            position={position}
            onClick={() => onClick?.()}
            className={className}
            {...rest}
        >
            {children}
        </ProjectCardBadgeRoot>
    )
}

const SPACING = 2
const positionStyleMap: Record<Position, ReturnType<typeof css>> = {
    'top-left': css`
        top: ${props => props.theme.spacing(SPACING)};
        left: ${props => props.theme.spacing(SPACING)};
    `,
    'top-right': css`
        top: ${props => props.theme.spacing(SPACING)};
        right: ${props => props.theme.spacing(SPACING)};
    `,
    'bottom-right': css`
        bottom: ${props => props.theme.spacing(SPACING)};
        right: ${props => props.theme.spacing(SPACING)};
    `,
    'bottom-left': css`
        bottom: ${props => props.theme.spacing(SPACING)};
        left: ${props => props.theme.spacing(SPACING)};
    `,
    center: css`
        bottom: 50%;
        left: 50%;
    `,
}

export const ProjectCardBadgeRoot = styled(Badge)<{ position: Position; hoverOnly?: boolean }>`
    position: absolute;
    z-index: 1;
    ${props => positionStyleMap[props.position]}
    opacity: ${props => (props.hoverOnly ? 0 : undefined)};
`

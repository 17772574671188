import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { GuestCommunityCloudItem } from './GuestCommunityCloudItem'
import badge1Img from '../media/badge-1.png'
import badge2Img from '../media/badge-2.png'
import badge3Img from '../media/badge-3.png'
import badge4Img from '../media/badge-4.png'
import badge5Img from '../media/badge-5.png'
import badge6Img from '../media/badge-6.png'
import badge7Img from '../media/badge-7.png'
import bgImg from '../media/bg.svg'
import titleBgImg from '../media/title-bg.svg'
import logiRobiImg from '../media/logirobi.png'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'

export const GuestCommunityCloud: React.FC = () => {
    const t = useTranslate()

    return (
        <Stack spacing={4}>
            <TitleWrap>
                <Title variant="h1">{t('guestCommunity::title')}</Title>
            </TitleWrap>
            <CloudWrap>
                <Shadow />
                <Background src={bgImg} width={826} height={322} />
                <Inner spacing={6}>
                    <Row>
                        <GuestCommunityCloudItem image={badge1Img}>
                            {t('guestCommunity::bubble01')}
                        </GuestCommunityCloudItem>
                        <GuestCommunityCloudItem image={badge2Img}>
                            {t('guestCommunity::bubble02')}
                        </GuestCommunityCloudItem>
                    </Row>
                    <Row>
                        <GuestCommunityCloudItem image={badge3Img}>
                            {t('guestCommunity::bubble03')}
                        </GuestCommunityCloudItem>
                    </Row>
                    <Row>
                        <GuestCommunityCloudItem image={badge4Img}>
                            {t('guestCommunity::bubble04')}
                        </GuestCommunityCloudItem>
                        <GuestCommunityCloudItem image={badge5Img}>
                            {t('guestCommunity::bubble05')}
                        </GuestCommunityCloudItem>
                    </Row>
                    <Row>
                        <GuestCommunityCloudItem image={badge6Img}>
                            {t('guestCommunity::bubble06')}
                        </GuestCommunityCloudItem>
                        <GuestCommunityCloudItem image={badge7Img}>
                            {t('guestCommunity::bubble07')}
                        </GuestCommunityCloudItem>
                    </Row>
                </Inner>
                <LogiRobi src={logiRobiImg} />
            </CloudWrap>
        </Stack>
    )
}

const Shadow = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${props => (props.theme.palette.type === 'dark' ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.15)')};
    filter: blur(50px);
    border-radius: 50%;
    z-index: -1;
`

const LogiRobi = styled.img`
    position: absolute;
    right: -8rem;
    bottom: 0;

    @media (max-width: 1350px) {
        width: 7rem;
        right: -2rem;
    }

    @media (max-width: 1100px) {
        width: 5rem;
        right: 0;
        bottom: 2rem;
    }

    @media (max-width: 960px) {
        display: none;
    }
`

const Row = styled.div`
    display: flex;
    justify-content: space-around;
`

const Title = styled(Text)`
    max-width: 30ch;
    font-weight: 900;
`

const TitleWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    min-height: 25vh;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    text-align: center;
    background-image: url(${titleBgImg});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center;
`

const Background = styled.img`
    display: block;
    width: 100%;
    height: auto;
`

const Inner = styled(Stack)`
    position: absolute;
    left: 0;
    right: 0;
`

const CloudWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
`

import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { auth } from './auth'
import { FullScreenLoader } from 'components/FullScreenLoader'

type AuthFlowProps = {
    onSucces: (token: string) => void
    onReceiverFail: () => void
    onSenderFail: () => void
}

export const AuthFlow: React.FC<AuthFlowProps> = ({ onSucces, onReceiverFail, onSenderFail }) => {
    return (
        <BrowserRouter>
            <Switch>
                <Route
                    exact
                    path="/auth/scoolcode"
                    render={() => (
                        <auth.OauthReceiver
                            onStatusChange={status => {
                                if (status.name === 'error') {
                                    onReceiverFail()
                                }
                            }}
                        >
                            {status => {
                                if (status.name === 'done') {
                                    return <Redirect to={status.origin}></Redirect>
                                }
                                return <FullScreenLoader />
                            }}
                        </auth.OauthReceiver>
                    )}
                ></Route>
                <Route
                    path="/"
                    render={() => (
                        <auth.OauthSender
                            onStatusChange={status => {
                                if (status.name === 'has-valid-token') {
                                    onSucces(status.token)
                                }
                                if (status.name === 'no-valid-token') {
                                    onSenderFail()
                                }
                            }}
                        >
                            {() => <FullScreenLoader />}
                        </auth.OauthSender>
                    )}
                ></Route>
            </Switch>
        </BrowserRouter>
    )
}

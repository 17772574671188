import { useUserState } from 'contexts/UserContext'
import { useCallback } from 'react'
import { useMemo } from 'react'

type Format = 'precise' | 'long' | 'short' | 'numeric' | 'year-month'

const formatOptionsMap: Record<Format, Intl.DateTimeFormatOptions> = {
    precise: { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    long: { year: 'numeric', month: 'long', day: 'numeric' },
    numeric: { year: 'numeric', month: 'numeric', day: 'numeric' },
    'year-month': { year: 'numeric', month: '2-digit' },
    short: { month: 'long', day: 'numeric' },
}

export const useDateFormat = (format: Format = 'precise') => {
    const { account } = useUserState()

    if (!account) {
        throw new Error('Account is missing!')
    }

    const formatter = useMemo(
        () => new Intl.DateTimeFormat(account.uiLanguage || 'en-US', formatOptionsMap[format]),
        [format, account.uiLanguage],
    )

    const formatDate = useCallback((date: Date) => formatter.format(date), [formatter])

    return {
        formatDate,
    }
}

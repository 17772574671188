import React, { useEffect } from 'react'
import { Loader } from 'components/Loader'
import { useIsElementFullyVisible } from 'hooks/useIsElementFullyVisible'
import { Flex } from 'components/layout'
import { ErrorMessage } from 'components/ErrorMessage'
import { Stack } from 'components/layout'
import styled from 'styled-components/macro'
import { DiscoverProjectsHeader } from './DiscoverProjectsHeader'
import { DiscoverMiniQuizzesList, DiscoverProjectsList } from './DiscoverProjectsList'
import { useLanguagesQuery } from 'lib/i18n'
import { Text } from 'components/Text'
import { useMyFavoriteProjectsQuery, useProjectDiscoverQuery } from '../projectQueries'
import { getUserProjectLanguages, useUserState } from 'contexts/UserContext'
import { ApiTypes } from 'lib/api'
import {
    DelimitedArrayParam,
    NumberParam,
    QueryParamConfig,
    QueryParamProvider,
    StringParam,
    useQueryParams,
    withDefault,
} from 'use-query-params'
import { Route } from 'react-router-dom'
import { useTranslate } from 'lib/i18n/useTranslate'

export type View = 'all' | 'favorites' | 'only-trainers'
export interface DiscoverProjectsFilterState {
    view: QueryParamConfig<View>
    pattern: QueryParamConfig<string>
    sort: QueryParamConfig<string>
    days: QueryParamConfig<number>
    languages: QueryParamConfig<string[]>
    // fixing some weird use-query-params typing issue
    [key: string]: any
}

interface DiscoverProjectsProps {
    defaultShort?: string
    type: 'normal' | 'mini-quiz'
}

export const DiscoverProjects = React.memo<DiscoverProjectsProps>(({ defaultShort, type }) => {
    const { data: languages } = useLanguagesQuery()

    if (!languages) {
        return (
            <Flex justify="center">
                <Loader />
            </Flex>
        )
    }

    return (
        <QueryParamProvider ReactRouterRoute={Route}>
            <DiscoverProjectsInner languages={languages.data} defaultShort={defaultShort} type={type} />
        </QueryParamProvider>
    )
})

interface DiscoverProjectsInnerProps extends DiscoverProjectsProps {
    languages: ApiTypes['language'][]
}

const DiscoverProjectsInner: React.FC<DiscoverProjectsInnerProps> = ({ languages, defaultShort, type }) => {
    const user = useUserState()
    const t = useTranslate()

    const [filters, setFilters] = useQueryParams<DiscoverProjectsFilterState>({
        view: withDefault<any, View>(StringParam, 'all'),
        pattern: withDefault<any, string>(StringParam, ''),
        sort: withDefault<any, string>(StringParam, defaultShort ?? '-approvedAt'),
        days: withDefault<any, number>(NumberParam, 1),
        languages: withDefault<any, string[]>(DelimitedArrayParam, getUserProjectLanguages(user, languages)),
    })

    const { data: favorites } = useMyFavoriteProjectsQuery(filters.view === 'favorites')

    const query = useProjectDiscoverQuery({
        query: {
            ...filters,
            limit: 36,
            ids: filters.view === 'favorites' ? favorites?.data.map(f => f.file) : undefined,
            type,
        },
        enabled: filters.view === 'favorites' ? !!favorites : true,
    })
    const { fetchMore, status, isFetchingMore, canFetchMore } = query
    const { ref, isFullyVisible } = useIsElementFullyVisible()

    useEffect(() => {
        if (isFullyVisible === true) {
            fetchMore()
        }
    }, [isFullyVisible, fetchMore])

    if (!languages) {
        return <Loader />
    }

    const isTriggerVisible = !!(status === 'success' && !isFetchingMore && canFetchMore)
    const countText = (() => {
        if (!query.data) {
            return null
        }
        const count = query.data[0].totalCount
        if (count !== 0) {
            return t('general::nResults', { count })
        }
        return t('general::noResults')
    })()

    return (
        <div>
            <Stack spacing={5}>
                <DiscoverProjectsHeader filters={filters} setFilters={setFilters} languages={languages} />
                <Stack>
                    <Text color="textSecondary">{countText}</Text>
                    {type === 'mini-quiz' ? (
                        <DiscoverMiniQuizzesList query={query} filters={filters} />
                    ) : (
                        <DiscoverProjectsList query={query} filters={filters} />
                    )}
                </Stack>
                <Flex justify="center" mt={5}>
                    {(status === 'loading' || isFetchingMore) && <Loader />}
                </Flex>
                {status === 'error' && <ErrorMessage />}
                <LoadMoreTrigger ref={ref} isVisible={isTriggerVisible}></LoadMoreTrigger>
            </Stack>
        </div>
    )
}

const LoadMoreTrigger = styled.div<{ isVisible: boolean }>`
    height: 2px;
    display: ${props => (props.isVisible ? 'block' : 'none')};
`

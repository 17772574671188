import { api } from 'lib/api'
import { useQuery, useQueryCache } from 'react-query'

const MY_CHALLENGE_SETS = 'my-challenge-sets'
const MY_CHALLENGE_SET_GROUPS = 'my-challenge-set-groups'

export function useMyChallengeSetGroupsQuery() {
    return useQuery(MY_CHALLENGE_SET_GROUPS, () => api.scoolcodeChallenges.getMyChallengeSetGroups(), {
        refetchOnWindowFocus: true,
    })
}

export function useMyChallengeSetsQuery() {
    return useQuery(MY_CHALLENGE_SETS, () => api.scoolcodeChallenges.getMyChallengeSets(), {
        staleTime: Infinity,
    })
}

export function useMiniChallengeSetsQuery(groupId?: string) {
    return useQuery(
        ['mini-challenge-sets', groupId],
        async () => {
            const data = await api.scoolcodeChallenges.getMiniChallengeSets(groupId)

            return data.data.sort((a, b) => a.internalName?.localeCompare(b.internalName!) || a.index! - b.index!)
        },

        {
            refetchOnWindowFocus: true,
        },
    )
}

export function useRecommendedMiniChallengesQuery() {
    return useQuery(
        ['recommended-mini-challenges'],
        async () => {
            return api.scoolcodeChallenges.getRecommendedMiniChallengeSets()
        },

        {
            staleTime: Infinity,
        },
    )
}

export function useInvalidateMyChallengeSetsQuery() {
    const queryCache = useQueryCache()

    return {
        invalidateMyChallengeSetsQuery: () => {
            queryCache.invalidateQueries(MY_CHALLENGE_SETS)
        },
    }
}

export function useMyOFCsets() {
    return useQuery('ofc-challenge-sets', () => api.scoolcodeChallenges.getMyOFCsets(), {
        staleTime: Infinity,
    })
}

export function useMyChallengeSetResults(challengeSetId: string) {
    return useQuery(
        [challengeSetId, 'challenge-set-results'],
        () => api.scoolcodeChallenges.getMyChallengeSetResultById(challengeSetId),
        {
            refetchOnWindowFocus: true,
        },
    )
}

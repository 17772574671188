import { Inline } from 'components/layout'
import { Avatar } from 'features/avatar'
import { lighten } from 'polished'
import React from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

interface LeaderboardItemProps {
    place: number
    icon?: React.ReactNode
    score: number
    highlighted?: boolean
    to: string
    avatarSrc?: string
    label: React.ReactNode
}

export const LeaderboardItem: React.FC<LeaderboardItemProps> = ({
    place,
    score,
    highlighted,
    to,
    avatarSrc,
    label,
    icon,
}) => {
    return (
        <Root $highlighted={highlighted} $hasIcon={icon !== undefined}>
            <IconWrap spacing={2}>
                {icon}
                <span>{place}. </span>
            </IconWrap>
            <StyledLink to={to}>
                <Avatar src={avatarSrc} size="tiny" />
                <Name>{label}</Name>
            </StyledLink>
            <Score>{score}</Score>
        </Root>
    )
}

const IconWrap = styled(Inline)`
    display: flex;
    align-items: center;
    > svg {
        flex-shrink: 0;
    }
`

const StyledLink = styled(Link)`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: ${props => props.theme.spacing(4)};
    align-items: center;
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus-visible {
        text-decoration: underline;
    }
`

const Name = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Score = styled.span`
    font-family: monospace;
    font-size: ${props => props.theme.typography.h6.fontSize};
    font-weight: 600;
    text-align: right;
`

const highlightedStyles = css`
    padding: ${props => props.theme.spacing(2, 3)};
    margin-left: -${props => props.theme.spacing(3)};
    margin-right: -${props => props.theme.spacing(3)};
    background-color: ${props => lighten(0.05, props.theme.palette.background.paper)};
    box-shadow: ${props => props.theme.shadows[3]};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    position: relative;
    top: -1px;
    font-weight: 800;
`

const Root = styled.li<{ $highlighted?: boolean; $hasIcon: boolean }>`
    display: grid;
    grid-template-columns: minmax(${props => (props.$hasIcon ? '2.6rem' : '1.5rem')}, auto) auto 1fr;
    grid-gap: ${props => props.theme.spacing(2)};
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.theme.spacing(2, 0)};
    ${props => props.$highlighted && highlightedStyles}
    &:not(:last-of-type) {
        border-bottom: solid 1px ${props => props.theme.palette.divider};
    }
`

import { Card } from 'components/card'
import { PlayIcon } from 'components/icons'
import { Img } from 'components/Img'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { createFeedImageUrl } from 'features/feed/data/feedhelper'
import { FeedEntryComponent } from 'features/feed/types/feedTypes'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { FeedEntryCta } from '../FeedEntryCta'
import { FeedEntryMetaAction } from '../FeedEntryMetaAction'
import { FeedEntryBase } from './FeedEntryBase'

export const AnnouncementEntry: FeedEntryComponent = ({ entry }) => {
    const t = useTranslate()

    return (
        <FeedEntryBase entry={entry}>
            <Card>
                <Content>
                    <Inner>
                        <FeedEntryMetaAction entry={entry}>
                            <ImageWrap>
                                <Image src={createFeedImageUrl(entry.image)} alt={entry.title} />
                                {entry.action?.actionType === 'vimeo' && (
                                    <VimeoButtonOverlay>
                                        <PlayIcon />
                                    </VimeoButtonOverlay>
                                )}
                            </ImageWrap>
                        </FeedEntryMetaAction>
                        <Stack>
                            <div>
                                <FeedEntryMetaAction entry={entry}>
                                    <Text>
                                        <strong>{t(entry.title as LocalisationKey)}</strong>
                                    </Text>
                                </FeedEntryMetaAction>
                                <Text>{t(entry.body as LocalisationKey)}</Text>
                            </div>
                            <FeedEntryCta entry={entry} />
                        </Stack>
                    </Inner>
                </Content>
            </Card>
        </FeedEntryBase>
    )
}

const VimeoButtonOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    font-size: 2rem;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.15);
    svg {
        transition: transform ${props => props.theme.transitions.duration.short}ms;
        filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
    }
`

const Content = styled.div`
    padding: ${props => props.theme.spacing(2, 4)};
`

const ImageWrap = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    &:hover {
        ${VimeoButtonOverlay} {
            svg {
                transform: scale(1.13);
            }
        }
    }
`

const Image = styled(Img)`
    display: block;
    width: 5rem;
`

const Inner = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: ${props => props.theme.spacing(4)};
`

import React from 'react'
import { MenuItem, MenuItemTypeMap } from '@material-ui/core'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import { Tooltip } from 'components/Tooltip'

type Props = MenuItemTypeMap['props'] & {
    disabledTooltip?: string
}

export const PopupMenuItem: OverridableComponent<
    MenuItemTypeMap & { props: MenuItemTypeMap['props'] & { disabledTooltip?: string } }
> = (React.forwardRef as any)(({ button, disabledTooltip, ...rest }: Props, ref: any) => {
    if (disabledTooltip) {
        return (
            <Tooltip title={disabledTooltip}>
                <span>
                    <Root disabled ref={ref} {...rest} />
                </span>
            </Tooltip>
        )
    }
    return <Root ref={ref} {...rest} />
})

export const CompactPopupMenuItem: OverridableComponent<
    MenuItemTypeMap & { props: MenuItemTypeMap['props'] & { disabledTooltip?: string } }
> = (React.forwardRef as any)(({ button, disabledTooltip, ...rest }: Props, ref: any) => {
    if (disabledTooltip) {
        return (
            <Tooltip title={disabledTooltip}>
                <span>
                    <CompactRoot disabled ref={ref} {...rest} />
                </span>
            </Tooltip>
        )
    }
    return <CompactRoot ref={ref} {...rest} />
})

const Root = styled(MenuItem)`
    padding: ${props => props.theme.spacing(3)};
    border-radius: ${props => props.theme.shape.borderRadius}px;

    .MuiListItemIcon-root {
        min-width: 1.8rem;
        color: ${props => transparentize(0.2, props.theme.palette.text.primary)};
    }
`

const CompactRoot = styled(MenuItem)`
    padding-top: ${props => props.theme.spacing(1)};
    padding-bottom: ${props => props.theme.spacing(1)};
    padding-left: ${props => props.theme.spacing(3)};
    padding-right: ${props => props.theme.spacing(3)};
    border-radius: ${props => props.theme.shape.borderRadius}px;

    .MuiListItemIcon-root {
        min-width: 1.8rem;
        color: ${props => transparentize(0.2, props.theme.palette.text.primary)};
    }
`

import { EditIcon } from 'components/icons'
import { AvatarBrowserDialog, avatarBrowserDialogTo, AvatarProps, CurrentUserAvatar } from 'features/avatar'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { visuallyHidden } from 'utils/styleUtils'

export const EditableAvatar: React.FC<Omit<AvatarProps, 'src'>> = props => {
    const t = useTranslate()

    return (
        <>
            <Root>
                <CurrentUserAvatar {...props} />
                <Overlay to={avatarBrowserDialogTo}>
                    <Label>{t('settings::changeProfilePicture')}</Label>
                    <EditIcon color="#fff" />
                </Overlay>
            </Root>
            <AvatarBrowserDialog />
        </>
    )
}

const Label = styled.span`
    ${visuallyHidden}
`

const Overlay = styled(Link)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: ${props => props.theme.shape.borderRadius}px;
    font-size: ${props => props.theme.typography.h3.fontSize};
    transition: opacity ${props => props.theme.transitions.duration.short}ms;
    opacity: 0;
    &:hover,
    &:focus-visible {
        opacity: 1;
    }
`

const Root = styled.div`
    position: relative;
`

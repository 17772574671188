import { StringMap, TOptions } from 'i18next'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { runtimeEnv } from 'utils/env'
import { LocalisationKey } from './generated/LocalisationKeys'

// loose keys:
type LiteralUnion<T extends U, U = string> = T | (U & { IGNORED?: never })
export type Key = LiteralUnion<LocalisationKey>

// strict keys:
//export type Key = LocalisationKey

const DEBUG_MISSING_TRANSLATIONS: boolean = false
const DEBUG_LONG_WORDS: boolean = false

export function useTranslate() {
    const { t } = useTranslation()

    return useCallback(
        (key?: Key, options?: TOptions) => {
            if (runtimeEnv.NODE_ENV === 'development') {
                if (DEBUG_MISSING_TRANSLATIONS === true) {
                    return t(key as string, { defaultValue: null }) ? 'TRANSLATED' : `_MISSING TRANSLATION ${key}_`
                }
                if (DEBUG_LONG_WORDS === true) {
                    const translation = t(key as string)
                    return translation + translation
                }
            }
            return t(key as string, options) as string
        },
        [t],
    )
}

export type TypedTFunction = (key?: Key | undefined, options?: TOptions<StringMap> | undefined) => string

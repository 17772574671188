import { ErrorMessage } from 'components/ErrorMessage'
import { useIsElementFullyVisible } from 'hooks/useIsElementFullyVisible'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import { Flex, Stack } from 'components/layout'
import { Loader } from 'components/Loader'
import { DeleteIcon, FavoriteIcon } from 'components/icons'
import { QueryParamConfig, QueryParamProvider, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { Route } from 'react-router-dom'
import { useTranslate } from 'lib/i18n/useTranslate'
import { WrappingInline } from 'components/layout/WrappingInline'
import { useSelector } from 'lib/store'
import { useUserState } from 'contexts/UserContext'
import { MyMiniQuizList } from './MyMiniQuizListList'
import { MyMiniQuizzesQuery } from 'features/mini-quizzes/data/MiniQuizApi'
import { ProjectListTab } from 'features/projects/ProjectListTab'
import { addProjectActionListener, removeProjectActionListener } from 'features/projects/useProjectActions'
import { getDefaultProjectListSort } from 'features/projects'
import { useMyMiniQuizzesQuery } from 'features/mini-quizzes/data/miniQuizQueries'
import { MyMiniQuizFilters } from './MyMiniQuizFilters'
import { useUpdateUserSettingOnChange } from 'hooks/useUpdateUserSettingOnChange'

export interface MyQuizProjectsFilterState {
    view: QueryParamConfig<MyMiniQuizzesQuery['view']>
    sort: QueryParamConfig<MyMiniQuizzesQuery['sort']>
    search: QueryParamConfig<string>
    // fixing some weird use-query-params typing issue
    [key: string]: any
}

export const MyMiniQuizzes: React.FC = () => {
    return (
        <QueryParamProvider ReactRouterRoute={Route}>
            <MyMiniQuizzesInner />
        </QueryParamProvider>
    )
}

const MyMiniQuizzesInner = React.memo(() => {
    const { settings } = useUserState()
    const [filters, setFilters] = useQueryParams<MyQuizProjectsFilterState>({
        view: withDefault<any, MyMiniQuizzesQuery['view']>(StringParam, 'all'),
        sort: withDefault<any, MyMiniQuizzesQuery['sort']>(StringParam, getDefaultProjectListSort(settings)),
        search: withDefault(StringParam, ''),
    })
    const [skipCorrection, setSkipCorrection] = useState(0)
    const t = useTranslate()
    const projects = useSelector(state => state.projects.all)

    const query = useMyMiniQuizzesQuery({ limit: 32, ...filters }, skipCorrection)

    useUpdateUserSettingOnChange('mylogiscool-project-list-sort', filters.sort)

    useEffect(() => {
        const currentDataIncludesId = (id: string) =>
            !!query.data?.flatMap(data => data.response.data).find(project => project.id === id)

        const onRemovedFromList = (id: string) => {
            if (currentDataIncludesId(id)) {
                setSkipCorrection(skipCorrection => skipCorrection - 1)
            }
        }

        if (filters.view === 'all') {
            addProjectActionListener('delete', onRemovedFromList)
            return () => {
                removeProjectActionListener('delete', onRemovedFromList)
                setSkipCorrection(0)
            }
        }

        if (filters.view === 'favorites') {
            addProjectActionListener('delete', onRemovedFromList)
            addProjectActionListener('unfavorite-own', onRemovedFromList)
            return () => {
                removeProjectActionListener('delete', onRemovedFromList)
                removeProjectActionListener('unfavorite-own', onRemovedFromList)
                setSkipCorrection(0)
            }
        }

        if (filters.view === 'deleted') {
            addProjectActionListener('restore', onRemovedFromList)
            return () => {
                removeProjectActionListener('restore', onRemovedFromList)
                setSkipCorrection(0)
            }
        }
    }, [query, filters.view])

    const { fetchMore, status, isFetchingMore, canFetchMore } = query
    const { ref, isFullyVisible } = useIsElementFullyVisible()

    useEffect(() => {
        if (isFullyVisible === true) {
            fetchMore()
        }
    }, [isFullyVisible, fetchMore])

    const isTriggerVisible = !!(status === 'success' && !isFetchingMore && canFetchMore)
    const isFiltering = filters.search !== ''

    const extractProjectsFromStore = (files: NonNullable<typeof query.data>) => {
        return files
            .flatMap(file => {
                return file.response.data
            })
            .filter(({ id }) => id in projects)
            .map(file => projects[file.id])
    }

    const filteredProjects = (() => {
        if (!query.data) {
            return undefined
        }
        const projects = extractProjectsFromStore(query.data)
        if (filters.view === 'favorites') {
            return projects.filter(project => {
                return project.file.favorite && !project.file.deleted
            })
        }
        if (filters.view === 'deleted') {
            return projects.filter(project => {
                return project.file.deleted
            })
        }
        return projects.filter(project => {
            return !project.file.deleted
        })
    })()

    return (
        <Stack spacing={5}>
            <WrappingInline>
                <ProjectListTab
                    isActive={filters.view === 'all'}
                    onClick={() => {
                        setFilters({ view: 'all' }, 'replaceIn')
                    }}
                >
                    {t('general::all')}
                </ProjectListTab>
                <ProjectListTab
                    isActive={filters.view === 'favorites'}
                    onClick={() => {
                        setFilters({ view: 'favorites' }, 'replaceIn')
                    }}
                    icon={<FavoriteIcon />}
                >
                    {t('general::favorites')}
                </ProjectListTab>
                <ProjectListTab
                    isActive={filters.view === 'deleted'}
                    onClick={() => {
                        setFilters({ view: 'deleted' }, 'replace')
                    }}
                    icon={<DeleteIcon />}
                >
                    {t('general::deletedItems')}
                </ProjectListTab>
            </WrappingInline>
            <Stack spacing={8}>
                <MyMiniQuizFilters filters={filters} setFilters={setFilters} />

                <MyMiniQuizList view={filters.view} projects={filteredProjects} isFiltering={isFiltering} />
            </Stack>

            {status === 'error' && <ErrorMessage />}

            <Flex justify="center" mt={5}>
                {(status === 'loading' || isFetchingMore) && <Loader />}
            </Flex>
            <LoadMoreTrigger ref={ref} isVisible={isTriggerVisible}></LoadMoreTrigger>
        </Stack>
    )
})

const LoadMoreTrigger = styled.div<{ isVisible: boolean }>`
    height: 2px;
    display: ${props => (props.isVisible ? 'block' : 'none')};
`

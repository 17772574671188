import React from 'react'
import { Fade, Theme, useMediaQuery } from '@material-ui/core'
import styled from 'styled-components/macro'
import { PageContainer } from './PageContainer'
import { Box } from 'components/layout'
import { MOBILE_MENU_BREAKPOINT } from './Page'
import { useIsElementFullyHidden } from 'hooks/useIsElementFullyHidden'
import { PageCookie } from 'features/projects'
import { ActivityEventPageCurrency } from 'features/events'
import { ASIDE_WIDTH } from './PageAside'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { useHasGrant } from 'lib/grants'
import {useManagementContext} from "../../features/management";
import {Button} from "../Button";

interface PageHeaderProps {
    title?: React.ReactNode
    actions?: React.ReactNode
    tabs?: React.ReactNode
    managementModeButton?: boolean
}

export const PageHeader: React.FC<PageHeaderProps> = ({ tabs, title, actions, managementModeButton }) => {
    const { ref, isFullyHidden } = useIsElementFullyHidden()
    const { enabled: managementModeEnabled, setEnabled: setManagementMode } = useManagementContext();
    const isMobileMenu = useMediaQuery<Theme>(theme => theme.breakpoints.down(MOBILE_MENU_BREAKPOINT), { noSsr: true })
    const isMedium = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'), { noSsr: true })

    return (
        <>
            <Root ref={ref}>
                <PageContainer>
                    <TopRow>
                        {title}
                        <TopRowRight>
                            <CurrenciesWrap>
                                <CurrencyBadges />
                                {managementModeButton && !managementModeEnabled && (
                                    <Button onClick={() => setManagementMode(true)}>
                                        Manage Mode
                                    </Button>
                                )}
                            </CurrenciesWrap>
                            {!tabs && actions && !isMedium && <ActionsWrap $isInTopRow>{actions}</ActionsWrap>}
                        </TopRowRight>
                    </TopRow>
                    <BottomRow>
                        <TabsWrap>{tabs}</TabsWrap>
                        {(tabs || isMedium) && actions && <ActionsWrap>{actions}</ActionsWrap>}
                    </BottomRow>
                </PageContainer>
            </Root>
            {!isMobileMenu && (
                <Fade in={isFullyHidden} unmountOnExit>
                    <StickyRoot>
                        <PageContainer>
                            <StickyInner>
                                <TabsWrap>{tabs}</TabsWrap>
                                <ActionsWrap $sticky>
                                    {actions}
                                    <CurrencyBadges />
                                </ActionsWrap>
                            </StickyInner>
                        </PageContainer>
                    </StickyRoot>
                </Fade>
            )}
        </>
    )
}

const CurrencyBadges: React.FC = () => {
    const hasGrant = useHasGrant()

    return (
        <ErrorBoundary silent>
            <ActivityEventPageCurrency />
            {hasGrant('give-golden-cookie') && <PageCookie currency="golden-cookie" />}
            <PageCookie currency="cookie" />
        </ErrorBoundary>
    )
}

const TopRowRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: stretch;
    flex-shrink: 0;
`

const BottomRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    overflow: hidden;
    ${props => props.theme.breakpoints.down('md')} {
        flex-direction: column-reverse;
        align-items: stretch;
        > * + * {
            margin-block-start: ${props => props.theme.spacing(4)};
        }
    }
`

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: ${props => props.theme.spacing(4, 0)};
    gap: ${props => props.theme.spacing(2)};
`

const CurrenciesWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${props => props.theme.spacing(4)};
`

const StickyInner = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: ${props => props.theme.spacing(4)};
    grid-row-gap: ${props => props.theme.spacing(4)};

    ${props => props.theme.breakpoints.down('xs')} {
        flex-direction: column;
        > * + * {
            margin-inline-start: 0;
            margin-block-start: ${props => props.theme.spacing(4)};
        }
    }
`

const TabsWrap = styled.div`
    min-width: 0;
`

const ActionsWrap = styled.div<{ $sticky?: boolean; $isInTopRow?: boolean }>`
    display: flex;
    align-items: center;
    padding: ${props => {
        if (props.$isInTopRow) {
            return undefined
        }
        if (props.$sticky) {
            return props.theme.spacing(1, 0)
        }
        return props.theme.spacing(0, 0, 4, 0)
    }};
    flex-shrink: 0;
    > * + * {
        margin-inline-start: ${props => props.theme.spacing(props.$sticky ? 2 : 4)};
    }
    ${props => props.theme.breakpoints.down('md')} {
        display: ${props => (props.$sticky ? 'none' : undefined)};
    }
    ${props => props.theme.breakpoints.down('sm')} {
        width: 100%;
        flex-direction: column;
        align-items: stretch;
        > * + * {
            margin-inline-start: 0;
            margin-block-start: ${props => props.theme.spacing(4)};
        }
    }
`

const Root = styled.div`
    border-bottom: solid 1px ${props => props.theme.palette.divider};
    ${props => props.theme.extras?.pageHeader?.root}
`

const StickyRoot = styled(Root)`
    position: fixed;
    z-index: 3;
    top: 0;
    background-color: ${props => props.theme.palette.background.paper};
    left: ${ASIDE_WIDTH};
    right: 0;
    box-shadow: ${props => props.theme.shadows[3]};
    border-bottom: none;
    ${props => props.theme.extras?.pageHeader?.stickyRoot}
`

import { UserState, useUserState } from 'contexts/UserContext'
import { Project } from 'features/projects'
import { ApiTypes } from 'lib/api'
import { hasGrant } from 'lib/grants'

export const MINI_QUIZ_PROJECT_TAG = 'mini-quiz'
const MINI_QUIZ_MIN_NUMBER_OF_QUESTIONS_TO_SHARE = 5

export const isMiniQuizProject = (file: ApiTypes['file']) => {
    return file.tags?.includes(MINI_QUIZ_PROJECT_TAG) ?? false
}

export const isMiniQuizLongEnoughToShare = (project: Project) => {
    return (project.metadata.numberOfQuestions?.normal ?? 0) >= MINI_QUIZ_MIN_NUMBER_OF_QUESTIONS_TO_SHARE
}

export const useMiniQuizAccessLevel = () => {
    const user = useUserState()

    return getMiniQuizAccessLevel(user)
}

export const getMiniQuizAccessLevel = (user: UserState) => {
    if (hasGrant(user.grants)('mini-quiz-create-and-share')) {
        return 'full'
    }
    if (hasGrant(user.grants)('mini-quiz-discover')) {
        return 'read'
    }
    return false
}

import { ErrorBoundary } from 'components/ErrorBoundary'
import { IconButton } from 'components/IconButton'
import { NotificationIcon } from 'components/icons'
import { useTranslate } from 'lib/i18n/useTranslate'
import { markAllNotificationAsDisplayed } from 'lib/liveops'
import { StoreState, useSelector } from 'lib/store'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled, { keyframes } from 'styled-components/macro'
import { NotificationPanel } from './NotificationPanel'
import { useIncrementActivityScore } from '../../hooks/useIncrementActivityScore'

interface NotificationButtonProps {}

export const NotificationButton: React.FC<NotificationButtonProps> = () => {
    const [isNotiPanelOpen, setNotiPanelOpen] = React.useState(false)
    const notiIcon = React.useRef(null)
    const unreadNotiCount = useSelector(getUnreadNotiCount)
    const dispatch = useDispatch()
    const incrementActivityScore = useIncrementActivityScore()
    const t = useTranslate()

    const handleNotiPanelOpen = () => {
        if (isNotiPanelOpen) {
            setNotiPanelOpen(false)
            dispatch(markAllNotificationAsDisplayed())
        } else {
            setNotiPanelOpen(true)
            incrementActivityScore('notifications:open-list')
        }
    }

    return (
        <>
            <StyledNotificationButton
                aria-label={t('notification::label')}
                color="inherit"
                onClick={() => handleNotiPanelOpen()}
                ref={notiIcon}
            >
                <NotificationIcon />
                {unreadNotiCount && unreadNotiCount > 0 ? <StyledNotiCount>{unreadNotiCount}</StyledNotiCount> : null}
            </StyledNotificationButton>
            <ErrorBoundary silent>
                <NotificationPanel
                    isOpen={isNotiPanelOpen}
                    refObject={notiIcon}
                    close={() => {
                        setNotiPanelOpen(false)
                        dispatch(markAllNotificationAsDisplayed())
                    }}
                />
            </ErrorBoundary>
        </>
    )
}

const getUnreadNotiCount = (state: StoreState) => {
    const notifications = state.client.notifications.allIds

    return notifications?.filter(id => {
        const notification = state.client.notifications.byId[id]
        return (
            !notification.displayed &&
            notification.message.data.display !== 'one-off' &&
            notification.message.data.display !== 'tooltip' &&
            notification.message.data.display !== 'unlisted-normal'
        )
    }).length
}

const bellAnimation = keyframes`
    0% {
        transform: rotate(0)
    }
    50% {
        transform rotate(-10deg);
    }
    100% {
        transform rotate(10deg);
    }
`

const StyledNotificationButton = styled(IconButton)`
    &:hover,
    &:focus-visible {
        svg {
            animation: ${bellAnimation} 0.5s ease-in-out 2 alternate;
        }
    }
    ${props => props.theme.extras?.pageAside?.listItemAndNotificationButton}
`

const StyledNotiCount = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    color: ${props => props.theme.palette.primary.contrastText};
    background-color: ${props => props.theme.palette.primary.main};
    font-size: ${props => props.theme.typography.body2.fontSize};
    border-radius: 50%;
    width: 1.4rem;
    height: 1.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    ${props => props.theme.extras?.notificationCount?.root}
`

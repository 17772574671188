import { api } from 'lib/api'
import { useHasGrant } from 'lib/grants'
import { useQuery } from 'react-query'

export function useLanguagesQuery() {
    const hasGrant = useHasGrant()
    const publishedOnly = !hasGrant({ product: 'edup', feature: 'all-languages' })
    const query = useQuery(['languages', publishedOnly], () => api.languages.getLanguages(publishedOnly), {
        staleTime: Infinity,
    })
    return query
}

import { Breadcrumbs } from 'components/Breadcrumbs'
import { Button } from 'components/Button'
import { LeftAngleIcon } from 'components/icons'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'

export interface EventDetailsBreadcrumbProps {
    children?: React.ReactNode
}

export const EventDetailsBreadcrumb: React.FC = ({ children }) => {
    const t = useTranslate()

    return (
        <Breadcrumbs>
            <Button component={Link} variant="text" startIcon={children ? null : <LeftAngleIcon />} to="/app/events">
                {t('events::allEvents')}
            </Button>
            {children}
        </Breadcrumbs>
    )
}

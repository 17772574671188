import React from 'react'
import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core'
import styled from 'styled-components/macro'

interface TooltipProps extends MuiTooltipProps {}

export const Tooltip = styled<React.FC<TooltipProps>>(({ className, ...rest }) => (
    <MuiTooltip classes={{ popper: className }} {...rest} />
))`
    & .MuiTooltip-tooltip {
        padding: ${props => props.theme.spacing(2, 3)};
        background-color: ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.contrastText};
        font-size: ${props => props.theme.typography.caption.fontSize};
    }
`

import React from 'react'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'

export const AvatarOverlay = React.forwardRef<any, any>((props, ref) => {
    return <AvatarOverlayRoot ref={ref} {...props}></AvatarOverlayRoot>
})

const AvatarOverlayRoot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: ${props => transparentize(0.9, props.theme.palette.common.black)};
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

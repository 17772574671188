import { StatisticName } from 'lib/liveops/actions'
import { SET_STATISTIC, STATISTIC } from 'lib/liveops/constants'
import { Reducer } from 'redux'
import { PRESENT } from 'redux-socket-client'
import { PartialRecord } from 'utils/typeUtils'

export type StatisticState = PartialRecord<StatisticName, number>

function statName({ group, stat }: any) {
    const statName = `${group}:${stat}` as StatisticName
    return statName
}

export const statisticReducer: Reducer<StatisticState> = (state = {}, { type, payload = {} }) => {
    switch (type) {
        case STATISTIC:
            return { ...state, [statName(payload)]: payload.value }
        case SET_STATISTIC:
            return { ...state, [statName(payload)]: payload.value }
        case PRESENT:
            return {}
        default:
            return state
    }
}

import React from 'react'
import { ListItem as MuiListItem, ListItemTypeMap } from '@material-ui/core'
import styled from 'styled-components/macro'
import { transparentize } from 'polished'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

type ListItemProps = ListItemTypeMap<any, 'li'>['props']

export const ListItem: OverridableComponent<ListItemTypeMap<any, 'li'>> = (props: ListItemProps) => {
    return <Root button {...props} />
}

export const Root = styled(MuiListItem)`
    font-size: ${props => props.theme.typography.body1.fontSize};
    padding: ${props => props.theme.spacing(3, 3)};
    margin: ${props => props.theme.spacing(2, 0)};
    border-radius: ${props => props.theme.shape.borderRadius}px;
    font-weight: 600;
    &.Mui-selected,
    &.Mui-selected:hover {
        color: ${props => props.theme.palette.primary.main};
        background-color: ${props => transparentize(0.8, props.theme.palette.primary.main)};
    }
    & svg {
        font-size: 1.3rem;
    }
`

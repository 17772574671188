import { CurrencyName } from 'lib/liveops/reducers/client/currency'
import { client } from 'redux-socket-client'
import { action } from 'typesafe-actions'
import { UPDATE_HAS_USER_THUMBNAIL } from '.'
import { ProjectVariant } from '../projectHelpers'
import {
    DELETE_PROJECT,
    FAVORITE_OWN_PROJECT,
    FAVORITE_PROJECT,
    FETCH_SUCCESS,
    GIVE_COOKIE_REQUEST,
    Project,
    RENAME_PROJECT,
    RESTORE_PROJECT,
    UNFAVORITE_OWN_PROJECT,
    UNFAVORITE_PROJECT,
    UNSHARE_PROJECT,
    UPDATE_METADATA,
    UPDATE_DETAILS,
    SHARE_PROJECT_WITHOUT_APPROVE,
    UPDATE_FILE,
} from './projectTypes'

export interface FetchSuccessPayload {
    file: Project['file'] & { metadata?: any }
    metadata: Omit<Project['metadata'], 'shareable' | 'isMiniQuiz'>
}

export const fetchProjectsSucces = (projects: FetchSuccessPayload[]) => action(FETCH_SUCCESS, { projects })

export const updateFile = (id: string, updates: Partial<Project['file']>) => action(UPDATE_FILE, { id, updates })

export const updateMetadata = (id: string, metadata: Omit<FetchSuccessPayload['metadata'], 'owner'>) =>
    action(UPDATE_METADATA, { id, metadata })

const giveCookieRequestAction = (project: string, currency: CurrencyName) =>
    action(GIVE_COOKIE_REQUEST, {
        project,
        currency,
    })
export const giveCookieRequest: typeof giveCookieRequestAction = client(giveCookieRequestAction)

const createPrivateHighScoreRoomAction = (project: string, name: string, expireAfterHours: number) =>
    action('CLIENT/SCOOLCODE/CREATE_ROOM', {
        target: project,
        name,
        kind: 'high-score',
        expireAfterHours,
    })
export const createPrivateHighScoreRoom: typeof createPrivateHighScoreRoomAction = client(
    createPrivateHighScoreRoomAction,
)

const joinHighScoreRoomAction = (room: string) =>
    action('CLIENT/SCOOLCODE/JOIN_ROOM', {
        room,
        role: 'guest',
    })
export const joinHighScoreRoom: typeof joinHighScoreRoomAction = client(joinHighScoreRoomAction)

const leaveHighScoreRoomAction = (room: string) =>
    action('CLIENT/SCOOLCODE/LEAVE_ROOM', {
        room,
    })
export const leaveHighScoreRoom: typeof leaveHighScoreRoomAction = client(leaveHighScoreRoomAction)

const favoriteProjectAction = (project: string) =>
    action(FAVORITE_PROJECT, {
        project,
    })
export const favoriteProject: typeof favoriteProjectAction = client(favoriteProjectAction)

const unfavoriteProjectAction = (project: string) => {
    return action(UNFAVORITE_PROJECT, {
        project,
    })
}
export const unfavoriteProject: typeof unfavoriteProjectAction = client(unfavoriteProjectAction)

export const renameProject = (id: string, field: 'name' | 'title', value: string) =>
    action(RENAME_PROJECT, { id, field, value })

export const favoriteOwnProject = (id: string) => action(FAVORITE_OWN_PROJECT, { id })
export const unFavoriteOwnProject = (id: string) => action(UNFAVORITE_OWN_PROJECT, { id })
export const deleteProject = (id: string) => action(DELETE_PROJECT, { id })
export const restoreProject = (id: string) => action(RESTORE_PROJECT, { id })

export const unshareProjet = (id: string) => action(UNSHARE_PROJECT, { id })
export const shareProjectWithoutApprove = (id: string) => action(SHARE_PROJECT_WITHOUT_APPROVE, { id })

export const updateDetails = (id: string, details: string, variant: ProjectVariant) =>
    action(UPDATE_DETAILS, { id, details, variant })

export const unshareProject = (id: string) => action(UNSHARE_PROJECT, { id })
export const updateHasUserThumbnail = (id: string) => action(UPDATE_HAS_USER_THUMBNAIL, { id })

import { Loader } from 'components/Loader'
import React from 'react'
import styled from 'styled-components/macro'

export const DialogLoader: React.FC = () => {
    return (
        <DialogLoaderRoot>
            <Loader />
        </DialogLoaderRoot>
    )
}

const DialogLoaderRoot = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

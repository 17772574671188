import { ErrorBoundary } from 'components/ErrorBoundary'
import { ErrorMessage } from 'components/ErrorMessage'
import { Page, PageContent, PageHeader, PageSkeleton } from 'components/page'
import { useUserState } from 'contexts/UserContext'
import { auth, AuthStatus } from 'lib/auth'
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom'
import { Dialog, DialogContent, DialogTitle } from 'components/dialog'
import { ProfileCardSkeleton } from 'features/profile'
import { demoGamePaths } from '../../features/demo-game/demoGameCodes'

import { NBSP } from 'utils/styleUtils'
import { modalRoutes } from './modals/modalRoutes'
import { ModalProvider, useModal } from './modals/modalContext'
import { SecureAccountPopupPrivder } from 'features/limited-accounts'
import { DialogQueueManager } from 'features/dialog-queue'
import { useAppVariantContent } from 'lib/app-variant'
import { OnboardingManagerProvider } from 'features/onboarding'
import { runtimeEnv } from 'utils/env'
import { FullScreenLoader } from 'components/FullScreenLoader'
import { TopLevelErrorBoundary } from 'components/TopLevelErrorBoundary'
import { useInvalidatePendingProjectsOnApprove } from 'features/projects/projectQueries'
import { ManagementContextProvider } from '../../features/management'

const WelcomePage = React.lazy(() => import('pages/welcome/WelcomePage'))
const OpenDayWelcomePage = React.lazy(() => import('pages/welcome/OpenDayWelcomePage'))
const GuestWelcomePage = React.lazy(() => import('pages/welcome/GuestWelcomePage'))
const ProfilePage = React.lazy(() => import('pages/profile'))
const ProjectsPage = React.lazy(() => import('pages/projects/ProjectsPage'))
const OpenDayClassroomPage = React.lazy(() => import('pages/open-day/OpenDayClassroomPage'))
const ChallengesPage = React.lazy(() => import('pages/challenges/ChallengesPage'))
const LimitedChallengesPage = React.lazy(() => import('pages/challenges/LimitedChallengesPage'))
const CommunityPage = React.lazy(() => import('pages/community/CommunityPage'))
const LimitedCommunityPage = React.lazy(() => import('pages/community/LimitedCommunityPage'))
const SettingsPage = React.lazy(() => import('pages/settings'))
const EventsPage = React.lazy(() => import('pages/events/EventsPage'))
const GuestEventsPage = React.lazy(() => import('pages/events/GuestEventsPage'))
const MassCertificatesPage = React.lazy(() => import('features/certificates/MassCertificates'))
const DemoGamePage = React.lazy(() => import('pages/demo-game'))
const LogiterraLoginPage = React.lazy(() => import('pages/logiterra-login'))
const YearInReviewPage = React.lazy(() => import('features/year-in-review/components/YearInReviewPage'))

export const AppRouter: React.FC<{ authStatus: AuthStatus }> = ({ authStatus }) => {
    return (
        <BrowserRouter>
            <ModalProvider>
                <Switch>
                    <Route path="/user/settings" exact render={() => <Redirect to="/app/settings" />} />
                    <Route
                        path="/internal-guest"
                        exact
                        render={() => {
                            window.location.href = `${runtimeEnv.REACT_APP_OAUTH_URL}/internal-guest`
                            return null
                        }}
                    />
                    <Route path="/group-certificates/:id" render={() => <MassCertificatesPage />} />
                    {demoGamePaths.map(path => (
                        <Redirect key={path.id} path={`/code/${path.name}`} to={`/demo-game/${path.id}`} />
                    ))}
                    <Route path="/demo-game/:id" render={() => <DemoGamePage />} />
                    {authStatus === 'logged-in' && <Route path="/app" render={() => <LoggedInRouter />} />}
                    {authStatus === 'logged-out' && (
                        <Route
                            render={() => {
                                window.location.href = auth.createAuthorizeUrl()
                                return null
                            }}
                        />
                    )}
                    <Route path="/native-app-login/logiterra" render={() => <LogiterraLoginPage />} />
                    {authStatus === 'logged-in' && <Redirect to="/app"></Redirect>}
                </Switch>
            </ModalProvider>
        </BrowserRouter>
    )
}

const LoggedInRouter = () => {
    const { path } = useRouteMatch()
    const user = useUserState()
    const { account } = user
    const { isModal, nonModalLocation } = useModal()
    const { appVariantContent } = useAppVariantContent()
    useInvalidatePendingProjectsOnApprove()

    const welcomeContent = appVariantContent({
        normal: <WelcomePage />,
        guest: <GuestWelcomePage />,
        'open-day': <OpenDayWelcomePage />,
    })

    return (
        <OnboardingManagerProvider>
            <SecureAccountPopupPrivder>
                <ManagementContextProvider>
                    <PageSuspense>
                        <Switch location={nonModalLocation}>
                            <Route path={`${path}/welcome`} render={() => welcomeContent} />
                            <Route
                                path={`${path}/user/:username`}
                                render={() => (
                                    <ProfilePageSuspense>
                                        <ProfilePage />
                                    </ProfilePageSuspense>
                                )}
                            />
                            {appVariantContent({
                                normal: <Route path={`${path}/my-projects`} render={() => <ProjectsPage />} />,
                                'open-day': (
                                    <Route path={`${path}/classroom`} render={() => <OpenDayClassroomPage />} />
                                ),
                                guest: null,
                            })}
                            <Route
                                path={`${path}/community`}
                                render={() =>
                                    appVariantContent({
                                        normal: <CommunityPage />,
                                        'open-day': <LimitedCommunityPage />,
                                        guest: <LimitedCommunityPage />,
                                    })
                                }
                            />
                            <Route
                                path={`${path}/challenges`}
                                render={() =>
                                    appVariantContent({
                                        normal: <ChallengesPage />,
                                        guest: <LimitedChallengesPage />,
                                        'open-day': <LimitedChallengesPage />,
                                    })
                                }
                            />
                            <Route path={`${path}/settings`} render={() => <SettingsPage />} />
                            <Route
                                path={`${path}/events`}
                                render={() =>
                                    appVariantContent({
                                        normal: <EventsPage />,
                                        guest: <GuestEventsPage />,
                                    })
                                }
                            />

                            <Route
                                path={`${path}/2022-wrap-up/:username?`}
                                render={({ match }) => (
                                    <TopLevelErrorBoundary>
                                        <React.Suspense fallback={<FullScreenLoader />}>
                                            <YearInReviewPage year={2022} username={match.params.username} />
                                        </React.Suspense>
                                    </TopLevelErrorBoundary>
                                )}
                            />

                            <Route
                                path={`${path}/2023-wrap-up/:username?`}
                                render={({ match }) => (
                                    <TopLevelErrorBoundary>
                                        <React.Suspense fallback={<FullScreenLoader />}>
                                            <YearInReviewPage year={2023} username={match.params.username} />
                                        </React.Suspense>
                                    </TopLevelErrorBoundary>
                                )}
                            />

                            <Route path={`${path}/classroom`} render={() => <Redirect to={`${path}/my-projects`} />} />
                            <Route
                                path={`${path}/classroom-project/:id`}
                                render={({ match }) => <Redirect to={`${path}/project/${match.params.id}`} />}
                            />
                            <Route
                                render={() => {
                                    if (isModal) {
                                        return welcomeContent
                                    }
                                    return <Redirect to={`${path}/welcome`} />
                                }}
                            />
                        </Switch>
                    </PageSuspense>
                </ManagementContextProvider>
                {modalRoutes.map(route => (
                    <Route
                        key={route.path}
                        path={route.path}
                        render={() => {
                            const Component =
                                typeof route.Component === 'object'
                                    ? appVariantContent(route.Component)
                                    : route.Component
                            return (
                                <ErrorBoundary fallback={<ModalRouteError />}>
                                    <Component />
                                </ErrorBoundary>
                            )
                        }}
                    />
                ))}
                <DialogQueueManager />
            </SecureAccountPopupPrivder>
        </OnboardingManagerProvider>
    )
}

const ModalRouteError: React.FC = () => {
    const { closeModal } = useModal()

    return (
        <Dialog open onClose={closeModal}>
            <DialogTitle>{NBSP}</DialogTitle>
            <DialogContent>
                <ErrorMessage></ErrorMessage>
            </DialogContent>
        </Dialog>
    )
}

const ProfilePageSuspense: React.FC = ({ children }) => {
    return (
        <React.Suspense
            fallback={
                <Page>
                    <PageHeader title={<ProfileCardSkeleton />} />
                </Page>
            }
        >
            <PageErrorBoundary>{children}</PageErrorBoundary>
        </React.Suspense>
    )
}

const PageSuspense: React.FC = ({ children }) => {
    return (
        <React.Suspense fallback={<PageLoader />}>
            <PageErrorBoundary>{children}</PageErrorBoundary>
        </React.Suspense>
    )
}

const PageLoader: React.FC = () => {
    return (
        <Page>
            <PageSkeleton />
        </Page>
    )
}

const PageErrorBoundary: React.FC = ({ children }) => {
    const history = useHistory()
    const [hasError, setHasError] = useState(false)
    const [key, setKey] = useState(0)

    useEffect(() => {
        if (hasError) {
            const removeListener = history.listen(() => {
                setHasError(false)
                setKey(key => key + 1)
            })
            return () => {
                removeListener()
            }
        }
    }, [history, hasError])

    return (
        <ErrorBoundary
            key={key}
            onError={() => {
                setHasError(true)
            }}
            fallback={
                <Page>
                    <PageContent>
                        <ErrorMessage />
                    </PageContent>
                </Page>
            }
        >
            {children}
        </ErrorBoundary>
    )
}

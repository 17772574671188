import io from 'socket.io-client'
import { Store } from 'redux'
import { connectToSharedStore } from 'redux-socket-client'
import { StoreState } from 'lib/store'
import { runtimeEnv } from 'utils/env'

let WSURL = runtimeEnv.REACT_APP_LIVEOPS_URL || 'https://live.dev.scoolcode.com'
if (WSURL === 'https://live.scoolcode.com') {
    WSURL =
        window.location.origin === 'https://my.logiscool.com'
            ? 'https://live.logiscool.com'
            : 'https://live.scoolcode.com'
}

export function initLiveops(accessToken: string, store: Store<StoreState>) {
    try {
        // NOTE: unfortunately, we have no pingInterval neither pingTimeout at client-side
        // those options are set at server side only
        const socketManager = new io.Manager(WSURL, {
            transports: ['websocket'],
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection (1000). Affected by +/- randomizationFactor, for example the default initial delay will be between 500 to 1500ms.
            reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnections (5000). Each attempt increases the reconnection delay by 2x along with a randomization as above
            randomizationFactor: 0.5, // 0 <= randomizationFactor <= 1
            timeout: 10000, // connection timeout before a connect_error and connect_timeout events are emitted
            autoConnect: true, // by setting this false, you have to call manager.open whenever you decide it’s appropriate
        })
        const socket = socketManager.socket('/') // TODO what is this namespace for??
        socket.on('connect', () => {
            // includes reconnect
            console.log('socket connected')
            socket.emit('authentication', {
                accessToken,
                segments: 'universe',
                sessionId: store.getState()?.client?.session?.id ?? null,
            })
        })
        socket.on('reconnect', () => {
            // includes reconnect
            console.log('socket reconnected')
        })
        socket.on('disconnect', () => {
            // includes reconnect
            console.log('socket disconnected')
        })
        socket.on('error', (err: string) => {
            console.error('socket error', err)
        })

        store.dispatch(connectToSharedStore(socket))
    } catch (e) {
        console.error(e)
    }
}

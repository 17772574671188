import React, {createContext, useCallback, useContext} from 'react'
import {ManagementVariableAction} from "../components/ManagementContextProvider";

export interface ManagementModalEntry<TData> {
    key: string
    kind: string
    data: TData
    wide?: boolean
}

interface ManagementContextState {
    enabled: boolean
    modalStack: ManagementModalEntry<any>[]
    setEnabled: (value: boolean) => void
    variables: Record<string, any>
    dispatchVariablesAction: React.Dispatch<ManagementVariableAction>
    openModal: <TData>(key: string, kind: string, data: TData) => void
    updateModal: <TData>(key: string, kind: string, data: TData) => void
    widenModal: (key: string) => void
    closeModal: (key: string) => void
}

export const managementContext = createContext<ManagementContextState | undefined>(undefined)

export const useManagementContext = () => {
    const state = useContext(managementContext)

    if (state === undefined) {
        throw new Error("'useManagementContext' must be used within a 'Page'.")
    }

    return state
}
export const useManagementVariable: <T>(key: string, fallbackValue?: T) => [ T, (value: T) => void ] = (key, fallbackValue) => {
    const state = useContext(managementContext)

    const setVariable = useCallback((value: any) => {
        if (state === undefined) {
            throw new Error("'useManagementVariable' must be used within a 'Page'.")
        }

        state.dispatchVariablesAction({
            type: 'set',
            key,
            value
        })
    }, [key, state]);

    if (state === undefined) {
        throw new Error("'useManagementVariable' must be used within a 'Page'.")
    }

    return [ state.variables[key] || fallbackValue, setVariable ]
}

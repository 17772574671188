import { Card } from 'components/card'
import React from 'react'
import styled from 'styled-components/macro'
import { Stat, StatProps } from './Stat'

interface StatCardProps extends StatProps {}

export const StatCard: React.FC<StatCardProps> = props => {
    return (
        <Card>
            <Inner>
                <Stat {...props} />
            </Inner>
        </Card>
    )
}

const Inner = styled.div`
    padding: ${props => props.theme.spacing(4)};

    ${props => props.theme.breakpoints.down('xs')} {
        padding: ${props => props.theme.spacing(2)};
    }
`

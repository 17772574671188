import React from 'react'
import styled from 'styled-components/macro'
import { ellipsis } from 'polished'
import { Text } from 'components/Text'
import { Stack } from 'components/layout'
import { Link } from 'react-router-dom'
import { Tooltip } from 'components/Tooltip'

interface ProjectCardContentProps {
    title?:
        | {
              label?: string
              to?: string
              href?: never
          }
        | {
              label?: string
              href?: string
              to?: never
          }
    action?: React.ReactNode
    className?: string
}

export const ProjectCardContent: React.FC<ProjectCardContentProps> = ({ title, children, action, ...rest }) => {
    const titleBody = (() => {
        const body = (
            <Title>
                <strong>{title?.label}</strong>
            </Title>
        )

        if (!title) {
            return null
        }

        if (!title.to && !title.href) {
            return body
        }

        if (title.to) {
            return <StyledLink to={title.to}>{body}</StyledLink>
        }

        if (title.href) {
            return (
                <StyledLink as="a" href={title.href} target="_blank">
                    {body}
                </StyledLink>
            )
        }
    })()

    return (
        <Root spacing={1} {...rest}>
            <Inner>
                <Tooltip title={title?.label ?? ''}>
                    <TitleWrap>{titleBody}</TitleWrap>
                </Tooltip>
                <div>{action}</div>
            </Inner>
            {children}
        </Root>
    )
}

const StyledLink = styled(Link)`
    color: inherit;
    text-decoration: none;
`

const Inner = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Root = styled(Stack)`
    padding: ${props => props.theme.spacing(2, 1, 0, 1)};
    color: ${props => props.theme.palette.text.primary};
`

const TitleWrap = styled.div`
    overflow: hidden;
    padding-inline-end: 3px;
`

const Title = styled(Text)`
    ${ellipsis()};
    line-height: 1.2;
`

import React, { useState } from 'react'
import { ButtonProps } from './Button'
import { Dialog, DialogProps } from './dialog'

type ConfirmStatus = 'closed' | 'open' | 'loading' | 'error' | 'success'

export interface ConfirmDialogContext {
    positiveButtonProps: {
        onClick: ButtonProps['onClick']
    }
    negativeButtonProps: {
        onClick: ButtonProps['onClick']
    }
    status: ConfirmStatus
}

interface ConfirmProps {
    action: () => Promise<void> | void
    dialog: (context: ConfirmDialogContext) => React.ReactNode
    children: (confirm: () => void, rest: any) => React.ReactNode
    dialogProps?: Partial<DialogProps>
    keepOpenAfterSuccess?: boolean
}

export const Confirm: React.FC<ConfirmProps> = ({
    children,
    dialog,
    action,
    dialogProps,
    keepOpenAfterSuccess,
    ...rest
}) => {
    const [status, setStatus] = useState<ConfirmStatus>('closed')

    const open = () => {
        if (status === 'closed') {
            setStatus('open')
        }
    }

    const confirm = async () => {
        if (status === 'open' || status === 'error') {
            setStatus('loading')
            try {
                await action()
                if (keepOpenAfterSuccess) {
                    setStatus('success')
                } else {
                    setStatus('closed')
                }
            } catch (e) {
                console.error(e)
                setStatus('error')
            }
        }
    }

    const deny = () => {
        if (status === 'open' || status === 'error' || status === 'success') {
            setStatus('closed')
        }
    }

    const positiveButtonProps = {
        onClick: confirm,
        isLoading: status === 'loading',
    }

    const negativeButtonProps = {
        onClick: deny,
        disabled: status === 'loading',
    }

    return (
        <>
            <Dialog onClose={deny} open={status !== 'closed'} {...dialogProps}>
                {dialog({ status, positiveButtonProps, negativeButtonProps })}
            </Dialog>
            {children(open, rest)}
        </>
    )
}

import React from 'react'
import styled from 'styled-components/macro'

interface ActionCardContentProps {
    className?: string
}

export const ActionCardContent: React.FC<ActionCardContentProps> = props => {
    return <Root {...props} />
}

const Root = styled.div`
    padding: ${props => props.theme.spacing(6, 5)};
`

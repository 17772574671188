import { useUserState } from 'contexts/UserContext'
import { useHasGrant } from 'lib/grants'
import { useTranslate } from 'lib/i18n/useTranslate'

export interface LocaleString {
    key?: string
    values?: {
        locale?: string
        value?: string
    }[]
}

export function useLocalisedQuizValue() {
    const { i18n } = useUserState()
    const t = useTranslate()

    const translateQuizLocaleString = (key?: LocaleString, keys?: string[]) => {
        if (keys && keys.length) {
            const [firstKey, ...restKeys] = keys;
            return t(firstKey, { value: restKeys.map(key => t(key)).join(', ') })
        }

        if (key === undefined) {
            return ''
        }

        const localisedName =
            key.values?.find(value => value.locale === i18n.locale) ||
            key.values?.find(value => value.locale === 'en-US')

        return localisedName?.value || ''
    }

    return {
        translateQuizLocaleString,
    }
}

export function useHasQuizAccess() {
    const hasGrant = useHasGrant()
    return hasGrant({ product: 'edup.quiz', feature: 'login' })
}

export function useHasGenerateQuizAccess() {
    const hasGrant = useHasGrant()
    return hasGrant('generate-quiz')
}

import { ErrorBoundary } from 'components/ErrorBoundary'
import { EyeIcon } from 'components/icons'
import { Inline, Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useHasShowAdvancedStats } from 'contexts/UserContext'
import { ProjectModalContent } from 'features/projects/modal/ProjectModalContent'
import { useProjectModalContext } from 'features/projects/modal/projectModalContext'
import { ProjectModalDescription } from 'features/projects/modal/ProjectModalDescription'
import { ProjectModalHeader } from 'features/projects/modal/ProjectModalHeader'
import { useIncrementHGViewCount } from 'features/projects/useIncrementHGViewCount'
import { useTitle } from 'hooks/useTitle'
import { useHasGrant } from 'lib/grants'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { CreateHighScoreRoomButton } from './CreateHighScoreRoomButton'
import { HighScoreGameLeaderboard } from './HighScoreGameLeaderboard'
import { highScoreGames } from './highScoreGames'
import { HighScoreRoomPanel } from './HighScoreRoomPanel'

interface Params {
    room?: string
}

export const HighScoreDetails: React.FC<Params> = ({ room }) => {
    const { id, project } = useProjectModalContext()
    const game = highScoreGames.find(game => game.projectId === id)
    const t = useTranslate()
    useTitle(t(game?.title))
    const hasGrant = useHasGrant()
    const canCreateHighScoreRoom = hasGrant('create-high-score-room')
    useIncrementHGViewCount(id)
    const hasShowAdvancedStas = useHasShowAdvancedStats()

    if (!game) {
        return null
    }

    return (
        <>
            <ProjectModalHeader>
                <Text variant="h3">{t(game.title)}</Text>
            </ProjectModalHeader>
            <ProjectModalContent>
                <Stack>
                    {canCreateHighScoreRoom && !room && (
                        <ErrorBoundary>
                            <CreateHighScoreRoomButton
                                mode={{ name: 'preselected-game', projectId: game.projectId }}
                                buttonProps={{ fullWidth: true }}
                            />
                        </ErrorBoundary>
                    )}
                    <ProjectModalDescription>{t(game.description)}</ProjectModalDescription>
                    {room && <HighScoreRoomPanel room={room} />}
                    <ErrorBoundary>
                        <HighScoreGameLeaderboard projectId={id} room={room} />
                    </ErrorBoundary>
                    {hasShowAdvancedStas ? (
                        <>
                            <Text variant="caption">
                                <Inline spacing={1} display="flex" alignItems="center">
                                    <EyeIcon /> <strong>{project?.file.viewedCount ?? 0}</strong>
                                </Inline>
                            </Text>{' '}
                        </>
                    ) : null}
                </Stack>
            </ProjectModalContent>
        </>
    )
}

import { Button, ButtonProps } from 'components/Button'
import { IconButton, IconButtonProps } from 'components/IconButton'
import React from 'react'
import { usePopupMenuContext } from './popupMenuContext'

interface PopupMenuButtonProps extends ButtonProps {}

export const PopupMenuButton: React.FC<PopupMenuButtonProps> = ({ onClick, ...rest }) => {
    const { open } = usePopupMenuContext()
    return (
        <Button
            {...rest}
            onClick={e => {
                onClick?.(e)
                open(e)
            }}
        />
    )
}

interface PopupMenuIconButtonProps extends IconButtonProps {}
// @ts-ignore
export const PopupMenuIconButton: React.FC<PopupMenuIconButtonProps> = ({ onClick, ...rest }) => {
    const { open } = usePopupMenuContext()
    return (
        <IconButton
            {...rest}
            onClick={e => {
                onClick?.(e)
                open(e)
            }}
        />
    )
}

import { Box, Flex } from 'components/layout'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Project, ProjectCardBadge } from '..'
import { useBlockGalleriesQuery } from '../projectQueries'
import styled from 'styled-components/macro'
import { BloxIcon, MixIcon } from 'components/icons'
import { motion } from 'framer-motion'
import { useHasGrant } from 'lib/grants'
import { NBSP } from 'utils/styleUtils'
import { ProjectListLayout } from './MyProjects'

interface MyProjectBlockGalleryProps {
    project: Project
    blockGalleriesQuery?: ReturnType<typeof useBlockGalleriesQuery>
    layout: ProjectListLayout
}

export const MyProjectBlockGallery: React.FC<MyProjectBlockGalleryProps> = ({
    project,
    blockGalleriesQuery,
    layout,
}) => {
    const t = useTranslate()
    const blockGallery = blockGalleriesQuery?.data?.data.find(
        gallery => gallery.id === project.file.metadata?.palette,
    )?.name

    const isMix = project.file.kind.includes('mix')
    const hasGrant = useHasGrant()
    const hasAdvancedThumbnail = hasGrant({ product: 'edup.scoolcode.ide', feature: 'advanced-project-thumbnail' })
    const isEnabled =
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'create-project.mix' }) ||
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'offboarding.mix' })

    const isMultiLanguageUser =
        isMix &&
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-python' }) &&
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-stagescript' });

    if (!blockGalleriesQuery) {
        return null
    }

    const displayBlockGallery = hasAdvancedThumbnail && !!blockGallery && !isMix

    if (!isEnabled) {
        return null
    }

    return (
        <>
            {layout === 'grid' ? (
                <>
                    {!isMultiLanguageUser && <BadgeWrapper initial="hidden" whileHover="hover" animate="hidden">
                        <ProjectCardBadge position="bottom-left">
                            <Container>
                                {isMix ? <MixIcon /> : <BloxIcon />}
                                {displayBlockGallery ? (
                                    <BlockGalleryText variants={textMotion}>{blockGallery}</BlockGalleryText>
                                ) : (
                                    NBSP
                                )}
                            </Container>
                        </ProjectCardBadge>
                    </BadgeWrapper>}
                </>
            ) : (
                <>
                    {displayBlockGallery && (
                        <StripeRoot>
                            <Text color="textSecondary" variant="caption">
                                {t('projects::palette')}
                            </Text>
                            <Text variant="body2">{blockGallery}</Text>
                        </StripeRoot>
                    )}
                    <Flex align="center" fontSize="1.25em">
                        {isMix ? <MixIcon /> : <BloxIcon />}
                    </Flex>
                </>
            )}
        </>
    )
}

const BadgeWrapper = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
`

const textMotion = {
    hidden: {
        opacity: 0,
        transform: 'translateX(-10px)',
        width: 0,
        marginInlineStart: '0px',
    },
    hover: {
        opacity: 1,
        transform: 'translateX(0px)',
        width: 'auto',
        marginInlineStart: '8px',
    },
}

const BlockGalleryText = styled(motion.span)`
    transform-origin: left;
`

const Container = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const StripeRoot = styled(Box)`
    white-space: pre;
    ${props => props.theme.breakpoints.down('xs')} {
        display: none;
    }
`

import { Reducer } from 'redux'
import { PRESENT } from 'redux-socket-client'

export interface ReadyState {
    app: number
}

export const readyReducer: Reducer<ReadyState> = (state = { app: 0 }, { type }) => {
    switch (type) {
        case PRESENT:
            return { ...state, app: state.app + 1 }

        default:
            return state
    }
}

import React from 'react'
import { InfoIcon } from 'components/icons'
import styled from 'styled-components/macro'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Text } from './Text'
import { Stack } from './layout'

interface EmptyStateProps {
    action?: React.ReactNode
}

export const EmptyState: React.FC<EmptyStateProps> = ({ children, action }) => {
    const t = useTranslate()

    return (
        <Stack spacing={2}>
            <StyledText>
                <InfoIcon size="1.5em" /> {children ? <>{children}</> : t('general::noResults')}
            </StyledText>
            <div>{action}</div>
        </Stack>
    )
}

const StyledText = styled(Text)`
    display: inline-flex;
    align-items: flex-start;
    max-width: 45rem;
    color: ${props => props.theme.palette.text.secondary};
    line-height: 1.4;
    > svg {
        flex-shrink: 0;
        vertical-align: text-bottom;
        margin-inline-end: ${props => props.theme.spacing(2)};
    }
`

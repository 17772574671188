import { Link, useLocation } from 'react-router-dom'

export function useLocationTabs(id: string, defaultValue: string | number = 0) {
    const location = useLocation<any>()
    const value = location.state?.[id] ?? defaultValue

    return {
        value,
        tabProps: (index: number | string) => ({
            value: index,
            component: Link,
            to: {
                ...location,
                state: {
                    ...location.state,
                    [id]: index,
                },
            },
        }),
    }
}

import { CookieHuntSpawnPointName, CookieHuntSpawnPoint } from '../types/cookieHuntTypes'

export const cookieHuntSpawnPoints: Record<CookieHuntSpawnPointName, CookieHuntSpawnPoint> = {
    'aside-avatar': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'bottom-right',
    },
    feed: {
        type: 'cookie',
        probability: 0.1,
        index: 7,
        layer: 'front',
        position: 'bottom-right',
    },
    'digital-mission-banner': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'bottom-left',
    },
    'new-project': {
        type: 'cookie',
        probability: 0.1,
        layer: 'back',
        position: 'bottom-left',
    },
    'quiz-result-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'bottom-right',
    },
    'my-projects-list': {
        index: 7,
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-right',
    },
    'notification-list': {
        index: 2,
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-left',
        offset: {
            x: 20,
            y: 20,
        },
    },
    'certificate-list': {
        index: 1,
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-right',
    },
    'profile-trophy': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-left',
    },
    'popular-shared-projects': {
        type: 'cookie',
        probability: 0.1,
        index: 3,
        layer: 'back',
        position: 'top-right',
    },
    'new-project-dialog': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-right',
    },
    'my-projects-filters': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'bottom-right',
    },
    'discover-projects-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-right',
    },
    'home-quiz-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'back',
        index: 5,
        position: 'bottom-left',
    },
    'coding-challenge': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'bottom-left',
    },
    'highlighted-high-score-game': {
        type: 'cookie',
        probability: 0.1,
        layer: 'back',
        position: 'top-right',
    },
    'high-score-game-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        index: 3,
        position: 'bottom-right',
    },
    'mini-challenge-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        index: 7,
        position: 'top-left',
    },
    'avatar-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        index: 16,
        position: 'bottom-left',
    },
    'dm2021-achi-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        index: 6,
        position: 'bottom-right',
    },
    'theme-list': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        index: 5,
        position: 'bottom-right',
    },
    'secret-code-unlocker': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-right',
    },
    'prev-event-list': {
        index: 0,
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'top-right',
    },
    'bio-input': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'bottom-right',
    },
    'community-page-title': {
        type: 'cookie',
        probability: 0.1,
        layer: 'front',
        position: 'bottom-right',
        offset: {
            x: 7,
        },
    },
    'easter-footer': {
        type: 'easter-egg',
        probability: 0.1,
        position: 'top-left',
        layer: 'front',
    },
    'halloween-footer': {
        type: 'cookie',
        probability: 0.3,
        position: 'top-left',
        layer: 'front',
    },
}

const MIN_NUMBER_OF_COOKIES = 5

export const generateRandomEnabledSpawnPoints = () => {
    const output = new Set<CookieHuntSpawnPointName>()
    while (output.size < MIN_NUMBER_OF_COOKIES) {
        for (const key of Object.keys(cookieHuntSpawnPoints)) {
            if (Math.random() < cookieHuntSpawnPoints[key as CookieHuntSpawnPointName].probability) {
                output.add(key as CookieHuntSpawnPointName)
            }
        }
    }
    return output
}

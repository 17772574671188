import { api } from 'lib/api'
import { useQuery } from 'react-query'
import { Wallpaper, WallpaperKind } from '../types/wallpaperTypes'

export const useWallpapersQuery = () => {
    return useQuery(
        'wallpapers',
        async () => {
            const wallpapers = await api.wallpapers.getWallpapers()
            const wallpapersByKind = wallpapers.data.reduce<Record<WallpaperKind, Wallpaper[]>>(
                (acc, curr) => {
                    acc[curr.kind].push(curr)
                    return acc
                },
                { desktop: [], mobile: [] },
            )
            return wallpapersByKind
        },
        { staleTime: Infinity },
    )
}

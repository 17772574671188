import React from 'react'
import { ProjectCardBadge } from './ProjectCardBadge'
import { CookieCount, CookieCountProps } from '../CookieCount'

export const ProjectCardCookieCount: React.FC<CookieCountProps> = props => {
    return (
        <ProjectCardBadge>
            <CookieCount {...props} />
        </ProjectCardBadge>
    )
}

import { Card, CardContent } from 'components/card'
import { Stack } from 'components/layout'
import { useUserState } from 'contexts/UserContext'
import Block01ImageEn from '../media/block_01/en.jpg'
import Block02Image from '../media/block_02/default.png'
import React from 'react'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Text } from 'components/Text'
import styled from 'styled-components/macro'
import Block03Image_bg_BG from '../media/block_03/bg-BG.jpg'
import Block03Image_cz_CZ from '../media/block_03/cz-CZ.jpg'
import Block03Image_de_CH from '../media/block_03/de-CH.jpg'
import Block03Image_de_DE from '../media/block_03/de-DE.jpg'
import Block03Image_en from '../media/block_03/en.jpg'
import Block03Image_es_ES from '../media/block_03/es-ES.jpg'
import Block03Image_es_MX from '../media/block_03/es-MX.jpg'
import Block03Image_hu_HU from '../media/block_03/hu-HU.jpg'
import Block03Image_ro_RO from '../media/block_03/ro-RO.jpg'
import Block03Image_fr_BE from '../media/block_03/fr-BE.jpg'
import Block03Image_es_CO from '../media/block_03/es-CO.jpg'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { MeetingList } from 'features/meetings'
import { PoorMansSuspenseRenderer, usePoorMansSuspense } from 'hooks/usePoorMansSuspense'
import { OpenDayCarousel } from './OpenDayCarousel'

export const OpenDayWelcome: React.FC = () => {
    const user = useUserState()
    const t = useTranslate()
    const locale = user.i18n.locale
    const { isReady, tick } = usePoorMansSuspense(1)

    const contentBlock03Image = imageBlock03[locale] ?? Block03Image_en

    return (
        <PoorMansSuspenseRenderer isReady={isReady}>
            <Stack spacing={8}>
                <Stack maxWidth={800} margin="0 auto" spacing={8}>
                    <ErrorBoundary silent>
                        <MeetingList onReady={tick} />
                    </ErrorBoundary>
                    <ContentBlock01>
                        <CardContent>
                            <Stack spacing={4}>
                                <Text
                                    dangerouslySetInnerHTML={{ __html: t('OpendayWhatsnew::Welcome::title') }}
                                    variant="h1"
                                    component="h3"
                                ></Text>
                                <Text>{t('OpendayWhatsnew::Welcome::content1')}</Text>
                            </Stack>
                        </CardContent>
                        <Image width="1920" height="1080" loading="lazy" src={Block01ImageEn} />
                    </ContentBlock01>
                    <ContentBlock02>
                        <Image loading="lazy" src={Block02Image} />
                        <CardContent>
                            <Stack spacing={4}>
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: t('OpendayWhatsnew::Extraordinaryschool::title'),
                                    }}
                                    variant="h1"
                                    component="h3"
                                />
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: t('OpendayWhatsnew::Extraordinaryschool::content1'),
                                    }}
                                />
                            </Stack>
                        </CardContent>
                    </ContentBlock02>
                    <OpenDayCarousel />
                    <ContentBlock03>
                        <CardContent>
                            <Text
                                dangerouslySetInnerHTML={{ __html: t('OpendayWhatsnew::Trainers::title') }}
                                variant="h1"
                                component="h3"
                            />
                        </CardContent>
                        <Image loading="lazy" src={contentBlock03Image} />
                    </ContentBlock03>
                </Stack>
            </Stack>
        </PoorMansSuspenseRenderer>
    )
}

const imageBlock03: Record<string, string> = {
    'bg-BG': Block03Image_bg_BG,
    'cz-CZ': Block03Image_cz_CZ,
    'de-CH': Block03Image_de_CH,
    'de-DE': Block03Image_de_DE,
    'es-ES': Block03Image_es_ES,
    'es-MX': Block03Image_es_MX,
    'hu-HU': Block03Image_hu_HU,
    'en-US': Block03Image_en,
    'ro-RO': Block03Image_ro_RO,
    'fr-BE': Block03Image_fr_BE,
    'es-CO': Block03Image_es_CO,
}

const Image = styled.img`
    max-width: 100%;
    background-color: ${props => props.theme.palette.divider};
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
`

const ContentBlock01 = styled(Card)`
    display: flex;
    flex-direction: column;
`

const ContentBlock03 = styled(Card)`
    display: flex;
    flex-direction: column;
`

const ContentBlock02 = styled(Card)`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: ${props => props.theme.spacing(4)};

    ul {
        margin: 0;
        padding-left: ${props => props.theme.spacing(4)};
    }

    li:nth-of-type(n + 1)::marker {
        color: #249ddf;
    }
    li:nth-of-type(n + 2)::marker {
        color: #c4d706;
    }
    li:nth-of-type(n + 3)::marker {
        color: #ae0550;
    }

    ${props => props.theme.breakpoints.down('xs')} {
        display: flex;
        flex-direction: column;
    }
`

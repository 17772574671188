import React, { useContext } from 'react'
import styled from 'styled-components/macro'

interface ProgressBarProps {
    value: number
    max: number
    ticks?: React.ReactNode
    className?: string
}

interface ProgressBarContextState extends ProgressBarProps {}

const progressBarContext = React.createContext<ProgressBarContextState | undefined>(undefined)

export const useProgressBarContext = () => {
    const state = useContext(progressBarContext)

    if (state === undefined) {
        throw new Error("'useProgressBarContext' must be used within an 'ProgressBar'.")
    }

    return state
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ value, max, children, className, ticks }) => {
    return (
        <progressBarContext.Provider value={{ value, max }}>
            <Root className={className}>
                {children}
                {ticks}
            </Root>
        </progressBarContext.Provider>
    )
}

const Root = styled.div`
    position: relative;
    width: 100%;
`

import { Dialog, DialogContent, DialogTitle } from 'components/dialog'
import { EmbeddedVideo } from 'components/EmbeddedVideo'
import React from 'react'
import styled from 'styled-components/macro'
import { NBSP } from 'utils/styleUtils'

interface FeedEntryVimeoModalProps {
    vimeoId: string
    isOpen: boolean
    onClose: VoidFunction
}

export const FeedEntryVimeoModal: React.FC<FeedEntryVimeoModalProps> = ({ vimeoId, isOpen, onClose }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{NBSP}</DialogTitle>
            <StyledDialogContent>
                <EmbeddedVideo type="vimeo" id={vimeoId} autoPlay />
            </StyledDialogContent>
        </Dialog>
    )
}

const StyledDialogContent = styled(DialogContent)`
    padding: 0;
`

import React from 'react'
import { Project } from 'features/projects'
import { CookieCount } from 'features/projects/CookieCount'
import { Badge } from 'components/Badge'

interface MiniQuizCardCookieCountProps {
    project: Project
}

export const MiniQuizCardCookieCount: React.FC<MiniQuizCardCookieCountProps> = ({ project }) => {
    return (
        <Badge>
            <CookieCount
                count={project.file.cookieCount ?? 0}
                cookied={project.metadata.cookied}
                goldenCount={project.file.goldenCookieCount ?? 0}
                goldenCookied={project.metadata.goldenCookied}
            />
        </Badge>
    )
}

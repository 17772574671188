import { Dialog, DialogActions, DialogPrimaryButton, DialogSecondaryButton } from 'components/dialog'
import { Paper } from 'components/Paper'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css'
import { useMediaQuery, Theme } from '@material-ui/core'
import { useRegisterActivityOnLoad } from '../../hooks/useRegisterActivityOnLoad'

const InnerImageZoom = React.lazy(() => import('react-inner-image-zoom'))

interface CertificateDialogProps {
    close: VoidFunction
    open: boolean
    imgData: string
    title: string
    download: VoidFunction
}

export const CertificateDialog: React.FC<CertificateDialogProps> = ({ close, open, imgData, title, download }) => {
    return (
        <React.Suspense fallback={null}>
            <StyledDialog open={open} maxWidth={false} onClose={close} PaperComponent={StyledPaper} hideCloseButton>
                <DialogContainer imgData={imgData} title={title} close={close} download={download} />
            </StyledDialog>
        </React.Suspense>
    )
}
const StyledDialog = styled(Dialog)`
    ${props => props.theme.breakpoints.down('xs')} {
        width: 100vw;
    }
`

const StyledPaper = styled(Paper)`
    overflow-y: hidden;
    height: 100%;
    display: block;

    ${props => props.theme.breakpoints.down('xs')} {
        height: auto;
    }
`

interface DialogContentProps {
    imgData: string
    title: string
    close: VoidFunction
    download: VoidFunction
}

const DialogContainer: React.FC<DialogContentProps> = ({ imgData, title, close, download }) => {
    const t = useTranslate()
    const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), { noSsr: true })

    useRegisterActivityOnLoad('certificates:owned:view')

    return (
        <>
            <ImgContainer>
                <InnerImageZoom src={imgData} alt="img" zoomScale={isSmallScreen ? 0.2 : 0.5} />
            </ImgContainer>
            <StyledActions>
                <DialogSecondaryButton onClick={close}>{t('general::cancel')}</DialogSecondaryButton>
                <DialogPrimaryButton onClick={download}>{t('certificate::download')}</DialogPrimaryButton>
            </StyledActions>
        </>
    )
}

const StyledActions = styled(DialogActions)`
    border-top: solid 1px ${props => props.theme.palette.divider};
    display: flex;
    align-items: center;
    height: 5rem;
    padding: ${props => props.theme.spacing(5)};
`

const ImgContainer = styled.div`
    height: calc(100% - 5rem);
    ${props => props.theme.breakpoints.down('xs')} {
        height: auto;
    }

    width: auto;
    & .iiz,
    & .iiz div {
        height: 100%;
    }
    & .iiz__img {
        height: 100%;
        object-fit: cover;
        width: auto;
        display: block;
        ${props => props.theme.breakpoints.down('xs')} {
            width: 100%;
            height: auto;
        }
    }
`

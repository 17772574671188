import React, { useReducer, useEffect, useRef } from 'react';
import { IconButton, CircularProgress, Tooltip } from "@material-ui/core";
import { UploadIcon } from 'components/icons'
import {api} from "lib/api";

type SrcType = 'url' | 'reference'

type ImageOrVideoUploadProps = {
    onUpload?: (value: string) => void
    srcType?: SrcType
    type?: 'image' | 'video'
    disabled?: boolean
}

export const ImageUploadButton: React.FC<ImageOrVideoUploadProps> = ({
                                                                         onUpload = () => {},
                                                                          srcType = 'reference',
                                                                          type = 'image',
                                                                         disabled = false
                                                                      }) => {
    const [state, dispatch] = useReducer(uploadReducer, { name: 'idle' });
    const inputRef = useRef<HTMLInputElement>(null)
    const id = React.useMemo(() => Math.random().toString(36).substr(2, 9), [])

    useEffect(() => {
        let ignore = false

        if (state.name === 'uploading') {
            api.management.uploadImage(state.file).then(res => {
                if (ignore) return;
                if (srcType === 'url') {
                    onUpload(`${api.baseUrl}/files/${res.data.id}/data`)
                }
                else {
                    onUpload(res.data.id)
                }
                dispatch({ type: 'UPLOAD_SUCCESS' })
            }).catch(() => {
                if (ignore) return;
                dispatch({ type: 'UPLOAD_ERROR' })
            }).finally(() => {
                if (ignore) return;
                if (inputRef.current) {
                    inputRef.current.value = ''
                }
            })
        }

        return () => {
            ignore = true
        }
    }, [state, onUpload, srcType])

    return (<>
        {state.name === 'uploading' ? (
            <CircularProgress />
        ) : (
            <label htmlFor={id}>
                <Tooltip title='Upload media'>
                    <IconButton
                        component='span'
                        color='primary'
                        disabled={disabled}
                    >
                        <UploadIcon />
                    </IconButton>
                </Tooltip>
            </label>
        )}
        <input
            ref={inputRef}
            disabled={disabled || state.name === 'uploading'}
            onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                    const maxAllowedSize = 5 * 1024 * 1024;

                    if (e.target.files[0].size > maxAllowedSize) {
                        dispatch({
                            type: 'MAXSIZE_ERROR',
                        });
                    } else {
                        dispatch({
                            type: 'START_UPLOAD',
                            file: e.target.files[0],
                        });
                    }
                }
            }}
            style={{ display: 'none' }}
            accept={type === 'image' ? 'image/*' : 'video/*'}
            id={id}
            type='file'
        ></input>
    </>);
};

type UploadReducerState =
    | { name: 'idle'; message?: string }
    | { name: 'uploading'; file: File }

type UploadReducerAction =
    {
        type: 'START_UPLOAD';
        file: File;
    }
    | {
    type: 'UPLOAD_SUCCESS';
}
    | {
    type: 'UPLOAD_ERROR';
}
    | {
    type: 'MAXSIZE_ERROR';
};

const uploadReducer: React.Reducer<UploadReducerState, UploadReducerAction> = (state, action) => {
    switch (state.name) {
        case 'idle':
            if (action.type === 'START_UPLOAD') {
                return { name: 'uploading', file: action.file }
            }
            if (action.type === 'MAXSIZE_ERROR') {
                return {
                    name: 'idle',
                    message: 'Maximum size is 5mb'
                }
            }
            return state

        case 'uploading':
            if (action.type === 'UPLOAD_SUCCESS') {
                return {
                    name: 'idle'
                }
            }
            if (action.type === 'UPLOAD_ERROR') {
                return {
                    name: 'idle',
                    message: 'Failed to upload.'
                }
            }

            return state
    }
}

import React from 'react'
import styled from 'styled-components/macro'
import bgImg from '../media/space-bg.jpg'

export const GuestWelcomePageWrapper: React.FC = ({ children }) => {
    return <Root>{children}</Root>
}

const Root = styled.div`
    background-image: url(${bgImg});
    background-size: cover;
    background-attachment: fixed;
    min-height: 100%;
    padding: ${props => props.theme.spacing(8, 0, 8, 0)};
    color: #fff;
`

import React from 'react'
import { Dialog, DialogContent, DialogTitle } from 'components/dialog'
import { useSelector } from 'lib/store'
import { TextBody } from 'components/layout/TextBody'
import { NBSP } from 'utils/styleUtils'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useFeedEntryQuery } from '../useFeed'
import { Loader } from 'components/Loader'
import { ErrorMessage } from 'components/ErrorMessage'
import { useModal } from 'lib/router/modals/modalContext'
import { useParams } from 'react-router-dom'
import { getFeedEntryById } from '../store/feedSelectors'

export const LongformDialog: React.FC = () => {
    const { id } = useParams<{ id: string }>()

    const entryFromRedux = useSelector(state => getFeedEntryById(state, id))

    const t = useTranslate()
    const { data: entryFromQuery, status } = useFeedEntryQuery(id, !entryFromRedux)
    const { closeModal } = useModal()

    const entry = entryFromRedux ?? entryFromQuery

    const body = (() => {
        if (status === 'error') {
            return (
                <>
                    <DialogTitle>{NBSP}</DialogTitle>
                    <DialogContent>
                        <ErrorMessage />
                    </DialogContent>
                </>
            )
        }

        if (!entry) {
            return (
                <>
                    <DialogTitle>{NBSP}</DialogTitle>
                    <DialogContent>
                        <Loader />
                    </DialogContent>
                </>
            )
        }

        if (entry?.action?.actionType !== 'longform') {
            return (
                <>
                    <DialogTitle>{NBSP}</DialogTitle>
                    <DialogContent>
                        <ErrorMessage />
                    </DialogContent>
                </>
            )
        }

        return (
            <>
                <DialogTitle>{entry.title ? t(entry.title) : NBSP}</DialogTitle>
                <DialogContent>
                    {entry.action.details && <TextBody dangerouslySetInnerHTML={{ __html: entry.action?.details }} />}
                </DialogContent>
            </>
        )
    })()

    return (
        <Dialog fullWidth maxWidth="md" open={true} PaperProps={{ style: { height: '100%' } }} onClose={closeModal}>
            {body}
        </Dialog>
    )
}

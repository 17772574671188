import { AnyAction } from 'redux'

type Listener = (action: AnyAction) => void

type ListenerWithMetadata = {
    fn: Listener
    once: boolean
}

class StoreActionListener {
    private listeners: Record<string, ListenerWithMetadata[]> = {}

    private add = (actionType: string, listener: Listener, once: boolean) => {
        if (this.listeners[actionType]) {
            this.listeners[actionType].push({ once, fn: listener })
        } else {
            this.listeners[actionType] = [{ once, fn: listener }]
        }
    }

    emit = (action: AnyAction) => {
        if (this.listeners[action.type]) {
            for (const listener of this.listeners[action.type]) {
                listener.fn(action)
                if (listener.once) {
                    this.removeListener(action.type, listener.fn)
                }
            }
        }
    }

    on = (actionType: string, listener: Listener) => {
        this.add(actionType, listener, false)
    }

    once = (actionType: string, listener: Listener) => {
        this.add(actionType, listener, true)
    }

    removeListener = (actionType: string, listener: Listener) => {
        if (this.listeners[actionType]) {
            this.listeners[actionType] = this.listeners[actionType].filter(({ fn }) => fn !== listener)
        }
    }
}

export const storeActionListener = new StoreActionListener()

import { useState } from 'react'
import { useNotifications } from '../../notifications'
import { useTranslate } from '../../../lib/i18n/useTranslate'
import { useHistory } from 'react-router-dom'
import { useQueryCache } from 'react-query'
import { useDispatch } from 'react-redux'
import {
    createGeneratedQuizRoom,
    GeneratedQuizDifficulty,
    GeneratedQuizMode,
    GeneratedQuizSpeed,
} from './generatedQuizHelpers'
import { storeActionListener } from '../../../lib/store/store-action-listener'
import { QUIZ_SESSION_READY } from '../../../lib/liveops/constants'

export function useGeneratedQuizCreator() {
    const [isLoading, setIsLoading] = useState(false)
    const { notify } = useNotifications()
    const t = useTranslate()
    const history = useHistory()
    const queryCache = useQueryCache()
    const dispatch = useDispatch()

    const generateQuiz = (
        numberOfQuestions: number,
        difficulty: GeneratedQuizDifficulty,
        mode: GeneratedQuizMode,
        speed?: GeneratedQuizSpeed,
    ) => {
        if (!isLoading) {
            const timeout = setTimeout(() => {
                storeActionListener.removeListener(QUIZ_SESSION_READY, onSessionReady)
                setIsLoading(false)
                notify(t('error::somethingWentWrong'), { variant: 'error' })
            }, 15000)

            const onSessionReady = ({ payload }: any) => {
                clearTimeout(timeout)
                history.push(
                    mode === 'single-player' ? `/app/quiz-mania/${payload.slug}` : `/app/host-quiz/${payload.slug}`,
                )
                queryCache.invalidateQueries('latest-generated-quiz-session')
                setIsLoading(false)
            }

            setIsLoading(true)
            dispatch(createGeneratedQuizRoom(numberOfQuestions, difficulty, mode, speed))
            storeActionListener.once(QUIZ_SESSION_READY, onSessionReady)
        }
    }

    return { isLoading, generateQuiz }
}

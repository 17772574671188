import { useNotifications } from 'features/notifications'
import { useProjectQueryInvalidation } from 'features/projects'
import { useInvalidateMyChallengeSetsQuery } from 'features/scoolcode-challenge'
import { getIdeUrl } from 'lib/api/helpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import { requestRooms } from 'lib/liveops'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { SecretCodeRedeemResponse } from './SecretCodesApi'

export function useProcessSecretCodeRedeemResponse() {
    const invalidate = useProjectQueryInvalidation()
    const history = useHistory()
    const { notify } = useNotifications()
    const { invalidateMyChallengeSetsQuery } = useInvalidateMyChallengeSetsQuery()
    const t = useTranslate()
    const dispatch = useDispatch()

    return function processSecretCodeRedeemResponse(response: SecretCodeRedeemResponse, close: VoidFunction) {
        switch (response.kind) {
            case 'quiz': {
                history.push(`/app/classroom-quiz/${response.quizId}`)
                break
            }
            case 'scoolcode-live-room': {
                const targetList = response.redirectUri.split('/')
                const target = targetList[targetList.length - 1]
                history.push(`/app/high-score/${target}?room=${response.roomId}`)
                setTimeout(() => {
                    dispatch(requestRooms())
                }, 1000)
                break
            }
            case 'project': {
                const path = `/ide/${response.projectId}`
                window.open(getIdeUrl(path))
                invalidate('MY_PROJECTS')
                invalidate('MY_LATEST_PROJECTS')
                close()
                break
            }
            case 'event-participation': {
                invalidateMyChallengeSetsQuery()
                notify(t('scChallenges::unlocked'), { variant: 'success' })
                history.push('/app/my-projects/special-projects')
                if (response.challengeSetId) {
                    const idePath = `/challenge-sets/${response.challengeSetId}/challenges/1`
                    window.open(getIdeUrl(idePath))
                }
                break
            }
            default: {
                throw new Error('Unsupported secret code type.')
            }
        }
    }
}

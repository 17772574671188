import React from 'react'
import { Authorize } from 'components/Authorize'
import { DeleteButton } from 'components/Button'
import { Collapse } from 'components/Collapse'
import { Confirm, ConfirmDialogContext } from 'components/Confirm'
import { DialogActions, DialogContent, DialogSecondaryButton, DialogTitle } from 'components/dialog'
import { ErrorMessage } from 'components/ErrorMessage'
import { MoreIcon, ResetIcon } from 'components/icons'
import { ListItemIcon } from 'components/list'
import { PopupMenu, PopupMenuIconButton, PopupMenuItem, PopupMenuList } from 'components/popup-menu'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useNotifications } from 'features/notifications'
import { ProjectCard, ProjectCardContent, ProjectCardImage } from 'features/projects'
import { api, ApiTypes } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import fallbackCoverImg from '../media/fallback-cover.png'
import { LocalisationKey } from 'lib/i18n'
import { getIdeUrl } from 'lib/api/helpers'

interface ScoolcodeChallengeCardProps {
    challengeSet: ApiTypes['challenge-set']
}

export const ScoolcodeChallengeCard: React.FC<ScoolcodeChallengeCardProps> = ({ challengeSet }) => {
    const t = useTranslate()
    const { notify } = useNotifications()

    const title = t(`Challenge::${challengeSet.internalName}.title` as LocalisationKey, {
        defaultValue: challengeSet.title,
    })

    const reset = async () => {
        await api.scoolcodeChallenges.resetChallengeSet(challengeSet.id)
        notify(t('scChallenges::resetSuccess'))
    }

    const href = getIdeUrl(`/challenge-sets/${challengeSet.id}/challenges/1`)

    return (
        <ProjectCard>
            <ProjectCardImage href={href} alt={title} src={challengeSet.coverUrl || fallbackCoverImg} />
            <ProjectCardContent
                title={{ label: title, href }}
                action={
                    <Authorize grant={{ product: 'edup.scoolcode.ide', feature: 'reset-challenge-set' }}>
                        <Actions reset={reset} />
                    </Authorize>
                }
            ></ProjectCardContent>
        </ProjectCard>
    )
}

const Actions: React.FC<{ reset: VoidFunction }> = ({ reset }) => {
    const t = useTranslate()

    return (
        <PopupMenu>
            <PopupMenuIconButton size="small">
                <MoreIcon />
            </PopupMenuIconButton>
            <PopupMenuList>
                <Confirm dialog={ResetDialog} action={reset}>
                    {openResetConfirm => (
                        <PopupMenuItem onClick={openResetConfirm}>
                            <ListItemIcon>
                                <ResetIcon />
                            </ListItemIcon>
                            {t('scChallenges::reset')}
                        </PopupMenuItem>
                    )}
                </Confirm>
            </PopupMenuList>
        </PopupMenu>
    )
}

const ResetDialog: React.FC<ConfirmDialogContext> = ({ status, positiveButtonProps, negativeButtonProps }) => {
    const t = useTranslate()

    return (
        <>
            <DialogTitle>{t('scChallenges::reset')}</DialogTitle>
            <DialogContent>
                <Stack>
                    <Text>{t('scChallenges::resetConfirm')}</Text>
                    <Collapse in={status === 'error'}>
                        <ErrorMessage />
                    </Collapse>
                </Stack>
            </DialogContent>
            <DialogActions>
                <DialogSecondaryButton {...negativeButtonProps}>{t('general::cancel')}</DialogSecondaryButton>
                <DeleteButton startIcon={<ResetIcon />} {...positiveButtonProps}>
                    {t('general::reset')}
                </DeleteButton>
            </DialogActions>
        </>
    )
}

import React from 'react'
import { ClassroomProjectDetails, CommunityProjectDetails, ProjectModalBase } from 'features/projects'
import { useProjectModalContext } from 'features/projects/modal/projectModalContext'

export const ProjectDetailsPage = () => {
    return (
        <ProjectModalBase
            embedOwnerInFileRequest={true}
            projectVariant={project => {
                if (project.file.shared) {
                    return 'production'
                }
                return 'normal'
            }}
            isNotFound={({ project, isOwnProject }) => {
                if (!project || isOwnProject) {
                    return false
                }
                return !(project.metadata.shareable && project.file.shared)
            }}
        >
            <Details />
        </ProjectModalBase>
    )
}

const Details: React.FC = () => {
    const { isOwnProject, project } = useProjectModalContext()

    if (isOwnProject === undefined || project === undefined) {
        return null
    }

    return project.metadata.shareable ? <CommunityProjectDetails /> : <ClassroomProjectDetails />
}

import { Box } from 'components/layout'
import { TextBody } from 'components/layout/TextBody'
import React from 'react'

interface EventDetailsContentProps {}

export const EventDetailsContent: React.FC<EventDetailsContentProps> = ({ children }) => {
    return (
        <Box maxWidth="55rem" margin="0 auto">
            <TextBody>{children}</TextBody>
        </Box>
    )
}

import { createStore, applyMiddleware, combineReducers, Middleware } from 'redux'
import { sharedStoreMiddleware } from 'redux-socket-client'
import { composeWithDevTools } from 'redux-devtools-extension'
import { liveopsMiddleware, sharedReducer, clientReducer } from 'lib/liveops'
import { projectReducer } from 'features/projects'
import { feedReducer } from 'features/feed'
import { storeActionListenerMiddleware } from 'lib/store/store-action-listener/storeActionListenerMiddleware'
import { isLiveopsRecording } from 'lib/recording/recordingDevToolUtils'
import { dialogQueueReducer } from 'features/dialog-queue'
import { eventsReducer } from 'features/events'

export const sharedStoreRecorderMiddleware: Middleware =
    ({ dispatch, getState }) =>
    next =>
    action => {
        if (
            isLiveopsRecording() &&
            action.payload &&
            action.payload.type &&
            action.payload.type.startsWith('CLIENT/')
        ) {
            if (!window.records) window.records = []
            const payloadCopy = { ...action.payload }
            delete payloadCopy.socket
            window.records.push({ timestamp: Date.now(), action: payloadCopy })
        }
        next(action)
    }

export interface StoreState {
    client: ReturnType<typeof clientReducer>
    shared: ReturnType<typeof sharedReducer>
    projects: ReturnType<typeof projectReducer>
    feed: ReturnType<typeof feedReducer>
    events: ReturnType<typeof eventsReducer>
    dialogQueue: ReturnType<typeof dialogQueueReducer>
}

const reducer = combineReducers<StoreState>({
    shared: sharedReducer,
    client: clientReducer,
    projects: projectReducer,
    feed: feedReducer,
    events: eventsReducer,
    dialogQueue: dialogQueueReducer,
})

export const store = createStore(
    reducer,
    composeWithDevTools(
        applyMiddleware(
            sharedStoreMiddleware({ clientFirst: false }),
            sharedStoreRecorderMiddleware,
            liveopsMiddleware,
            storeActionListenerMiddleware,
        ),
    ),
)

import React from 'react'
import { Inline } from 'components/layout'
import styled from 'styled-components/macro'
import { currencyStyles } from './currencyStyles'
import { useHasGrant } from 'lib/grants'

export interface CookieCountProps {
    count?: number
    goldenCount?: number
    displayCount?: boolean
    cookied?: boolean
    goldenCookied?: boolean
    className?: string
}

export const CookieCount: React.FC<CookieCountProps> = ({
    count = 0,
    goldenCookied = false,
    displayCount = true,
    cookied = false,
    goldenCount = 0,
    className,
}) => {
    const hasGrant = useHasGrant()
    const hasGiveGoldenCookieGrant = hasGrant('give-golden-cookie')
    const isGolenCookieColored = !hasGiveGoldenCookieGrant || goldenCookied

    return (
        <Root className={className}>
            {goldenCount > 0 && (
                <>
                    <Inner spacing={1} $isColored={isGolenCookieColored}>
                        <span>{goldenCount}</span>
                        <Image src={currencyStyles['golden-cookie'].icon[isGolenCookieColored ? 'colored' : 'solid']} />
                    </Inner>
                    <Divider />
                </>
            )}
            <Inner spacing={1} $isColored={cookied}>
                {displayCount && <span>{count}</span>}
                <Image src={currencyStyles.cookie.icon[cookied ? 'colored' : 'solid']} />
            </Inner>
        </Root>
    )
}

const Root = styled.div`
    display: flex;
    grid-gap: ${props => props.theme.spacing(2)};
    font-size: ${props => props.theme.typography.body1.fontSize};
`

const Divider = styled.div`
    width: 1px;
    background-color: #ffffff4d;
`

const Image = styled.img`
    width: 1.3em;
`

const Inner = styled(Inline)<{ $isColored: boolean }>`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${props => (props.$isColored ? currencyStyles.cookiedColor : props.theme.palette.common.white)};
`

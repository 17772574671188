import React, { useEffect, useReducer } from 'react'
import { Grid, Stack, Box } from 'components/layout'
import { ManagementModalContentProps, ManagementModalTitle } from './ManagementModal'
import { Text } from 'components/Text'
import { Button } from 'components/Button'
import { useManagementContext } from '../helpers/managementContext'
import { BatchManagementMutationTask } from './MutationTask'
import { Switch } from 'components/form'
import { WarningIcon } from 'components/icons'
import { DialogActions, DialogSecondaryButton } from 'components/dialog'
import { ImageField } from './editors/ImageField'
import { DateField } from './editors/DateField'
import { NumberField } from './editors/NumberField'
import { BooleanField } from './editors/BooleanField'
import { TextMultiEditField } from './editors/TextMultiEditField'
import { JSONMultiEditField } from './editors/JSONMultiEditField'

export interface ManagementMultiEditFieldConfiguration {
    label: string
    type: 'text' | 'number' | 'date' | 'boolean' | 'image' | 'json'
    editorProps?: Record<string, any>
}

const TYPE_TO_EDITOR = {
    text: TextMultiEditField,
    json: JSONMultiEditField,
    number: NumberField,
    date: DateField,
    boolean: BooleanField,
    image: ImageField,
}

export interface ManagementMultiEditConfiguration {
    primaryField: string
    displayTargetAs?: string
    displayTargetAsPlural?: string
    mutationTaskKind: string
    hidePrimaryField?: boolean
    mutateUnchanged?: boolean
    wideModal?: boolean
    description?: string
    fields: Record<string, ManagementMultiEditFieldConfiguration>
}

export interface ManagementMultiEdit {
    configuration: ManagementMultiEditConfiguration
}

export interface ManagementMultiEditStepBasePath {
    path: string
    fieldPrefix: string
}

export interface ManagementMultiEditStep<TData> {
    kind: string
    editedField: string
    mutationTaskKind?: string
    hidePrimaryField?: boolean
    groupEntriesByValue?: boolean
    mutateUnchanged?: boolean
    wideModal?: boolean
    description?: string
    basePath?: string | ManagementMultiEditStepBasePath
    data: TData[]
    dirtyData?: TData[]
}

const EDITS: Record<string, ManagementMultiEdit> = {}

export const registerManagementMultiEdit: (kind: string, configuration: ManagementMultiEditConfiguration) => void = (
    kind,
    configuration,
) => {
    EDITS[kind] = EDITS[kind] || { configuration }
}

export const useManagementMultiEdit: (kind: string) => ManagementMultiEditConfiguration | undefined = kind => {
    const edit = EDITS[kind]
    return React.useMemo(() => (edit ? { ...edit.configuration } : edit), [edit])
}

export function setDeep(obj: any, path: string[], value: any): any {
    const newLevel = Array.isArray(obj) ? [...obj] : { ...obj }
    const [field, ...newPath] = path
    if (!field) return newLevel
    newLevel[field] = newPath.length ? setDeep(newLevel[field] || {}, newPath, value) : value
    return newLevel
}

export function getDeep(obj: any, path: string[]): any {
    if (!obj) return obj
    const [field, ...newPath] = path
    if (!field) return obj
    return getDeep(obj[field], newPath)
}

export const useMultiEditField = (
    fieldName: string,
    configuration: ManagementMultiEditConfiguration,
    basePath?: string | ManagementMultiEditStepBasePath,
) => {
    const field = React.useMemo(() => {
        const fieldPrefix = basePath && typeof basePath === 'object' ? basePath.fieldPrefix : basePath
        const fullFieldName = `${fieldPrefix ? `${fieldPrefix}.` : ''}${fieldName}`
        const field = configuration.fields[fullFieldName] || {
            label: fieldName,
            type: 'text',
        }
        field.editorProps = field.editorProps || {}
        return field
    }, [fieldName, configuration.fields, basePath])

    const EditorComponent = TYPE_TO_EDITOR[field.type]

    return {
        field,
        EditorComponent,
    }
}

function reduceResults(results: any[], action: { index: number | number[]; field: string[]; newValue: any }) {
    const newResults = [...results]
    if (Array.isArray(action.index)) {
        action.index.forEach(i => {
            newResults[i] = setDeep(newResults[i], action.field, action.newValue)
        })
        return newResults
    } else {
        newResults[action.index] = setDeep(newResults[action.index], action.field, action.newValue)
    }
    return newResults
}

export const MultiEditStepManagementModalContent: React.FC<ManagementModalContentProps<ManagementMultiEditStep<any>>> =
    ({ modal }) => {
        const multiEdit = EDITS[modal.data.kind]
        const entries = modal.data.data
        const [results, changeResult] = useReducer(reduceResults, modal.data.dirtyData || modal.data.data)
        const { closeModal, updateModal, widenModal } = useManagementContext()
        const [groupEntriesByValue, setGroupEntriesByValue] = React.useState(modal.data.groupEntriesByValue || false)

        const wideModal =
            typeof modal.data.wideModal === 'boolean' ? modal.data.wideModal : multiEdit?.configuration?.wideModal

        useEffect(() => {
            if (wideModal && !modal.wide) widenModal(modal.key)
        }, [wideModal, widenModal, modal.key, modal.wide])

        const editedField = modal.data.editedField
        const realBasePath = typeof modal.data.basePath === 'string' ? modal.data.basePath : modal.data.basePath?.path
        const basePathParts = realBasePath ? realBasePath.split('.') : []
        const editedFieldParts = [...basePathParts, ...editedField.split('.')]

        const handleNext = async () => {
            const mutateUnchanged =
                typeof modal.data.mutateUnchanged === 'boolean'
                    ? modal.data.mutateUnchanged
                    : multiEdit.configuration.mutateUnchanged

            let finalResults = results
            if (!mutateUnchanged) {
                finalResults = results.filter(
                    (result, i) => getDeep(result, editedFieldParts) !== getDeep(entries[i], editedFieldParts),
                )
                if (!finalResults.length) {
                    closeModal(modal.key)
                    return
                }
            }

            const task: BatchManagementMutationTask<any> = {
                type: 'batch',
                kind: modal.data.mutationTaskKind || multiEdit.configuration.mutationTaskKind,
                data: finalResults,
            }
            updateModal(modal.key, 'mutation-task', task)
        }

        if (!multiEdit)
            return (
                <Stack spacing={5} margin={4}>
                    <h2>Missing multiedit config for {modal.data.kind}</h2>
                </Stack>
            )

        const hidePrimaryField =
            typeof modal.data.hidePrimaryField === 'boolean'
                ? modal.data.hidePrimaryField
                : multiEdit.configuration.hidePrimaryField

        const description =
            typeof modal.data.description === 'string' ? modal.data.description : multiEdit.configuration.description

        const fieldPrefix =
            modal.data.basePath && typeof modal.data.basePath === 'object'
                ? modal.data.basePath.fieldPrefix
                : modal.data.basePath
        const fullFieldName = `${fieldPrefix ? `${fieldPrefix}.` : ''}${editedField}`
        const field = multiEdit.configuration.fields[fullFieldName] || {
            label: editedField,
            type: 'text',
        }
        field.editorProps = field.editorProps || {}
        const EditorComponent = TYPE_TO_EDITOR[field.type]

        const entriesByValue = results.reduce<{ value: any; entries: any[]; indices: number[] }[]>((acc, entry, i) => {
            const value = getDeep(entry, editedFieldParts)
            const group = acc.find(group => group.value === value)
            if (group) {
                group.entries.push(entry)
                group.indices.push(i)
            } else {
                acc.push({
                    value,
                    indices: [i],
                    entries: [entry],
                })
            }
            return acc
        }, [])

        return (
            <>
                <Stack spacing={5} margin={4}>
                    <ManagementModalTitle
                        title={`Edit ${
                            multiEdit.configuration.displayTargetAsPlural ||
                            `${multiEdit.configuration.displayTargetAs || modal.data.kind} Entries`
                        }`}
                    />
                    {description && <Text>{description}</Text>}
                    <Switch
                        label="Group entries by value"
                        checked={groupEntriesByValue}
                        onChange={(_, checked) => setGroupEntriesByValue(checked)}
                    />
                    <Grid container spacing={2}>
                        {!hidePrimaryField && <Grid item xs={4}></Grid>}
                        <Grid item xs={hidePrimaryField ? 12 : 8}>
                            <Text>
                                <strong style={{ textTransform: 'capitalize' }}>{field.label}</strong>
                            </Text>
                        </Grid>
                        {groupEntriesByValue &&
                            !!multiEdit.configuration.primaryField &&
                            entriesByValue.map(({ value, entries, indices }, i) => {
                                return (
                                    <React.Fragment key={`${i}-${value}`}>
                                        {!hidePrimaryField && (
                                            <Grid item xs={4}>
                                                <Stack marginTop={0} marginBottom={4} spacing={1}>
                                                    {entries.map((entry, i) => (
                                                        <Text key={i}>
                                                            {entry[multiEdit.configuration.primaryField]}
                                                            {value !==
                                                                getDeep(
                                                                    modal.data.data[indices[i]],
                                                                    editedFieldParts,
                                                                ) && (
                                                                <>
                                                                    &nbsp;
                                                                    <WarningIcon />
                                                                </>
                                                            )}
                                                        </Text>
                                                    ))}
                                                </Stack>
                                            </Grid>
                                        )}
                                        <Grid item xs={hidePrimaryField ? 12 : 8}>
                                            <Box marginTop={0} marginBottom={4}>
                                                <EditorComponent
                                                    value={value}
                                                    multiline
                                                    onChange={(_: any, newValue: any) =>
                                                        changeResult({
                                                            index: indices,
                                                            field: editedFieldParts,
                                                            newValue,
                                                        })
                                                    }
                                                    {...field.editorProps}
                                                />
                                            </Box>
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}
                        {!groupEntriesByValue &&
                            entries.map((entry, i) => {
                                const result = results[i]
                                const dirty = getDeep(result, editedFieldParts) !== getDeep(entry, editedFieldParts)
                                return (
                                    <React.Fragment key={i}>
                                        {!hidePrimaryField && (
                                            <Grid item xs={4}>
                                                <Text>
                                                    {multiEdit.configuration.primaryField
                                                        ? entry[multiEdit.configuration.primaryField]
                                                        : `Entry ${i + 1}`}
                                                </Text>
                                            </Grid>
                                        )}
                                        <Grid item xs={hidePrimaryField ? 12 : 8}>
                                            <EditorComponent
                                                value={getDeep(result, editedFieldParts)}
                                                multiline
                                                endAdornment={dirty ? <WarningIcon /> : undefined}
                                                onChange={(_: any, newValue: any) =>
                                                    changeResult({
                                                        index: i,
                                                        field: editedFieldParts,
                                                        newValue,
                                                    })
                                                }
                                                {...field.editorProps}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}
                    </Grid>
                </Stack>
                <DialogActions>
                    <DialogSecondaryButton onClick={() => closeModal(modal.key)}>Cancel</DialogSecondaryButton>
                    <Button onClick={handleNext}>Next</Button>
                </DialogActions>
            </>
        )
    }

import React from 'react'
import { useTranslate } from '../../../lib/i18n/useTranslate'
import { useGeneratedQuizSessionsQuery } from './generatedQuizQueries'
import { useLocalisedQuizValue } from '../quizHelpers'
import { ErrorMessage } from '../../../components/ErrorMessage'
import { Loader } from '../../../components/Loader'
import { Flex, Stack } from '../../../components/layout'
import { Button } from '../../../components/Button'
import { Link } from 'react-router-dom'
import { GeneratedQuizDifficulty } from './generatedQuizHelpers'
import { useGeneratedQuizCreator } from './useGeneratedQuizCreator'
import { Box } from '@material-ui/core'
import { Text } from '../../../components/Text'
import { EasyQuizIcon, FastForwardIcon, HardQuizIcon, MediumQuizIcon, PlayIcon } from '../../../components/icons'
import styled, { css } from 'styled-components/macro'

interface QuizKind {
    icon: any
    difficulty: GeneratedQuizDifficulty
    nQuestions: number
}

const QUIZ_KINDS: QuizKind[] = [
    { icon: EasyQuizIcon, difficulty: 'easy', nQuestions: 3 },
    { icon: MediumQuizIcon, difficulty: 'medium', nQuestions: 7 },
    { icon: HardQuizIcon, difficulty: 'hard', nQuestions: 12 },
]

const GeneratedQuizCardContent: React.FC = () => {
    const t = useTranslate()
    const { data: activeSessions, status } = useGeneratedQuizSessionsQuery()
    const { translateQuizLocaleString } = useLocalisedQuizValue()

    if (status === 'error') {
        return <ErrorMessage />
    }

    if (!activeSessions) {
        return <Loader />
    }

    const activeSession = activeSessions.data[0]

    return (
        <Stack m={4} style={{ width: '100%' }}>
            {activeSession ? (
                <Button component={LinkRoot} to={`/app/quiz-mania/${activeSession.slug}`}>
                    <Content>
                        <Text style={{ justifySelf: 'start', textAlign: 'left' }}>
                            {translateQuizLocaleString(activeSession.live.name, activeSession.live.nameKeys)}
                            <br />
                            <TemplateItemMetadata>
                                {t('general::nQuestions', {
                                    count: activeSession.live.nQuestions - activeSession.live.questionIndex,
                                })}
                            </TemplateItemMetadata>
                        </Text>
                        <IconWrap style={{ justifySelf: 'end' }}>
                            <ContinueIcon />
                        </IconWrap>
                    </Content>
                </Button>
            ) : (
                <SinglePlayerCreator />
            )}
        </Stack>
    )
}

const SinglePlayerCreator: React.FC = () => {
    const { isLoading, generateQuiz } = useGeneratedQuizCreator()
    const t = useTranslate()

    return (
        <Flex direction="column" align="stretch" style={{ gap: '0.5rem' }}>
            {QUIZ_KINDS.map(({ difficulty, nQuestions, icon }) => (
                <StartQuizButton
                    key={difficulty}
                    icon={icon}
                    title={t(`general::${difficulty}`)}
                    isLoading={isLoading}
                    subTitle={t('general::nQuestions', { count: nQuestions })}
                    onClick={() => generateQuiz(nQuestions, difficulty, 'single-player')}
                />
            ))}
        </Flex>
    )
}

const StartQuizButton: React.FC<{ title: string; subTitle: string; onClick: Function; isLoading: boolean; icon: any }> =
    ({ title, subTitle, onClick, isLoading, icon: Icon }) => {
        return (
            // @ts-ignore
            <ButtonRoot onClick={onClick} isLoading={isLoading}>
                <Content>
                    <IconWrap>
                        <Icon />
                    </IconWrap>
                    <Text style={{ justifySelf: 'start' }}>
                        {title}
                        <TemplateItemMetadata>
                            <> • </>
                            {subTitle}
                        </TemplateItemMetadata>
                    </Text>
                    <IconWrap style={{ justifySelf: 'end' }}>
                        <StartIcon />
                    </IconWrap>
                </Content>
            </ButtonRoot>
        )
    }

const ButtonRoot = styled(Button)`
    background: ${props => props.theme.palette.background.paper};
    border: 3px solid ${props => props.theme.palette.background.paper};
    transition: border ${props => props.theme.transitions.duration.standard}ms;
    color: ${props => props.theme.palette.text.primary};
    justify-content: stretch;
    &:hover {
        background: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
        border: 3px solid ${props => props.theme.palette.primary.main};
        transition: border ${props => props.theme.transitions.duration.standard}ms;
    }
`

const LinkRoot = styled(Link)`
    background: ${props => props.theme.palette.background.paper};
    border: 3px solid ${props => props.theme.palette.background.paper};
    transition: border ${props => props.theme.transitions.duration.standard}ms;
    color: ${props => props.theme.palette.text.primary};
    justify-content: stretch;
    &:hover {
        background: ${props => props.theme.palette.background.paper};
        color: ${props => props.theme.palette.text.primary};
        border: 3px solid ${props => props.theme.palette.primary.main};
        transition: border ${props => props.theme.transitions.duration.standard}ms;
    }
`

const TemplateItemMetadata = styled.span`
    opacity: 0.5;
`

const IconWrap = styled.span`
    > * {
        vertical-align: middle;
        font-size: 1.5rem;
    }
`

const iconBaseStyles = css`
    font-size: 1.5rem;
`

const StartIcon = styled(PlayIcon)`
    ${iconBaseStyles}
    color: ${props => props.theme.palette.primary.main};
`

const ContinueIcon = styled(FastForwardIcon)`
    ${iconBaseStyles}
    color: ${props => props.theme.palette.primary.main};
`

const Content = styled(Box)`
    display: grid;
    grid-template-columns: auto 1fr auto;
    width: 100%;
    align-items: center;
    gap: ${props => props.theme.spacing(4)};
    justify-content: space-between;
`

export { GeneratedQuizCardContent }

import { MoreIcon } from 'components/icons'
import { PopupMenuIconButton } from 'components/popup-menu'
import React from 'react'
import { ProjectActionsMenu } from '../ProjectActionsMenu'
import styled from 'styled-components/macro'
import { ICON_BUTTON_SMALL_PADDING } from 'components/IconButton'
import { useTranslate } from 'lib/i18n/useTranslate'

interface MyProjectCardActionsMenuProps {
    id: string
    compensatePadding?: boolean
    isPending?: boolean
    location: 'card' | 'stripe'
}

export const MyProjectCardActionsMenu: React.FC<MyProjectCardActionsMenuProps> = ({
    id,
    compensatePadding,
    isPending,
    location,
}) => {
    const t = useTranslate()

    return (
        <ProjectActionsMenu
            id={id}
            location={location}
            isPending={isPending}
            popupMenuButton={
                <StyledPopupMenuIconButton
                    $compensatePadding={compensatePadding}
                    size="small"
                    aria-label={t('general::more')}
                >
                    <MoreIcon />
                </StyledPopupMenuIconButton>
            }
        />
    )
}

const StyledPopupMenuIconButton = styled(PopupMenuIconButton)<{ $compensatePadding?: boolean }>`
    margin: ${props => (props.$compensatePadding ? `-${ICON_BUTTON_SMALL_PADDING}` : undefined)};
`

import { Button } from 'components/Button'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { FloatingBadge } from 'components/FloatingBadge'
import { SelectBox, SelectBoxItem } from 'components/form'
import { CommunityIcon, TrainerIcon } from 'components/icons'
import { Box, Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useUserState } from 'contexts/UserContext'
import { CookieHunt } from 'features/cookie-hunt'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { useHasGrant } from 'lib/grants'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useSelector } from 'lib/store'
import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'
import { getUnreadFeedEntryCountByType } from '../store/feedSelectors'
import { FeedType } from '../types/feedTypes'
import { useFeed } from '../useFeed'
import { AnnouncementEntry } from './entries/AnnouncementEntry'
import { GeneralEntry } from './entries/GeneralEntry'
import { MinorEntry } from './entries/MinorEntry'
import { VotingEntry } from './entries/VotingEntry'
import { WelcomeStats } from './WelcomeStats'
import { useDispatch } from 'react-redux'
import { useUserActions } from 'contexts/UserContext'
import * as actions from '../store/feedActions'
import dayjs from 'dayjs'
import { IconType } from 'react-icons/lib'

interface FeedListProps extends PoorMansSuspenseTicker {}

export const FeedList: React.FC<FeedListProps> = ({ onReady }) => {
    const t = useTranslate()
    const hasGrant = useHasGrant()
    const unreadCommunityCount = useSelector(state => getUnreadFeedEntryCountByType(state, 'community'))
    const unreadTrainerCount = useSelector(state => getUnreadFeedEntryCountByType(state, 'trainer'))
    const hasTrainerNewsGrant = hasGrant({ product: 'edup.trainer-portal', feature: 'news' })
    const defaultFeedType: FeedType = hasTrainerNewsGrant ? 'trainer' : 'community'
    const [feedType, setFeedType] = useQueryParam('view', withDefault(StringParam, defaultFeedType))

    return (
        <Stack maxWidth={600} marginLeft="auto" marginRight="auto">
            {hasTrainerNewsGrant && (
                <SelectBox
                    value={feedType}
                    onChange={value => {
                        setFeedType(value as FeedType, 'replaceIn')
                    }}
                >
                    <FeedSelectBoxItem unreadCount={unreadCommunityCount} value="community" Icon={CommunityIcon}>
                        {t('news::community')}
                    </FeedSelectBoxItem>
                    <FeedSelectBoxItem unreadCount={unreadTrainerCount} value="trainer" Icon={TrainerIcon}>
                        {t('news::trainer')}
                    </FeedSelectBoxItem>
                </SelectBox>
            )}
            {feedType === 'community' && <FeedListTab feedType={feedType} onReady={onReady} />}
            {hasTrainerNewsGrant && feedType === 'trainer' && <FeedListTab feedType={feedType} onReady={onReady} />}
        </Stack>
    )
}

interface FeedSelectBoxItemProps {
    unreadCount: number
    Icon: IconType
    value: string
}

const FeedSelectBoxItem: React.FC<FeedSelectBoxItemProps> = ({ unreadCount, Icon, value, children }) => {
    return (
        <StyledFloatingBadge title={unreadCount} active={unreadCount > 0}>
            <SelectBoxItem direction="row" fullWidth icon={<Icon fontSize="1.75rem" />} value={value}>
                {children}
            </SelectBoxItem>
        </StyledFloatingBadge>
    )
}

interface FeedListItemProps extends PoorMansSuspenseTicker {
    feedType: FeedType
}

const FeedListTab: React.FC<FeedListItemProps> = ({ feedType, onReady }) => {
    const t = useTranslate()
    const feedState = useSelector(state => state.feed)
    const { loadNextFeedPage } = useFeed()
    const [isLoading, setIsLoading] = React.useState(false)
    const user = useUserState()
    usePoorMansSuspenseTick(feedType === 'trainer', onReady)

    const dispatch = useDispatch()
    const { updateJSONUserSetting } = useUserActions()
    useEffect(() => {
        return () => {
            try {
                updateJSONUserSetting(
                    'mylogiscool-newsfeed',
                    feedType === 'community' ? 'lastReadAt' : 'trainerLastReadAt',
                    dayjs().toISOString(),
                )
                dispatch(actions.markAllRead(feedType))
            } catch (e) {
                console.error(e)
            }
        }
    }, [dispatch, updateJSONUserSetting, feedType])

    const loadMore = async () => {
        if (!isLoading) {
            setIsLoading(true)
            await loadNextFeedPage(user, feedType)
            setIsLoading(false)
        }
    }

    return (
        <Stack spacing={5}>
            {feedType === 'community' && (
                <ErrorBoundary silent>
                    <WelcomeStats onReady={onReady} />
                </ErrorBoundary>
            )}
            {feedState[feedType].entries.map((entry, i) => {
                const item = (() => {
                    switch (entry.entryType) {
                        case 'general':
                            return <GeneralEntry entry={entry} />
                        case 'announcement':
                            return <AnnouncementEntry entry={entry} />
                        case 'minor':
                            return <MinorEntry entry={entry} />
                        case 'voting':
                            return <VotingEntry entry={entry} />
                        default:
                            return null
                    }
                })()

                return (
                    <CookieHunt key={i} name="feed" index={i}>
                        {item}
                    </CookieHunt>
                )
            })}
            {feedState[feedType].hasMore ? (
                <Box textAlign="center">
                    <Button variant="outlined" onClick={loadMore} isLoading={isLoading}>
                        {t('general::loadMore')}
                    </Button>
                </Box>
            ) : (
                <Text style={{ textAlign: 'center' }}>{t('feed::noMoreEntries')}</Text>
            )}
        </Stack>
    )
}

const StyledFloatingBadge = styled(FloatingBadge)`
    display: flex;
    flex: 1;
`

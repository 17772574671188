import { useUserState } from 'contexts/UserContext'
import { api } from 'lib/api'
import { useQueryCache } from 'react-query'
import { useDispatch } from 'react-redux'
import { ProjectLanguage, ProjectType } from './createProjectFormData'
import { cacheBustThumbnail, ProjectVariant, serializeProjectLabel } from './projectHelpers'
import { useInvalidateProjectData, usePendingProjectCache, useProjectQueryInvalidation } from './projectQueries'
import * as projectActions from './store/projectActions'
import { Project } from './store'

const NAME_NOT_UNIQUE_ERROR = {
    success: false,
    error: 'NAME_NOT_UNIQUE',
} as const

interface ShareProjectPayload {
    id: string
    title: string
    details: string
    thumbnail: string
    lang: string
    needsApprove: boolean
    isMiniQuiz: boolean
}

export function useProjectActions() {
    const pendingProjectsCache = usePendingProjectCache()
    const invalidate = useProjectQueryInvalidation()
    const invalidateProjectData = useInvalidateProjectData()
    const dispatch = useDispatch()
    const { account } = useUserState()
    const queryCache = useQueryCache()

    async function createProject(
        name: string,
        type: ProjectType,
        palette: string,
        labels: string[] = [],
        language: ProjectLanguage,
    ) {
        const isNameUnique = await api.projects.isProjectNameUnique(name)
        if (!isNameUnique) {
            return NAME_NOT_UNIQUE_ERROR
        }
        const project = await api.projects.createEmptyProject({
            name,
            type,
            palette: type === 'blox' ? palette : undefined,
            language: type === 'mix' ? language : undefined,
            labels: labels.map(serializeProjectLabel),
        })
        invalidate('MY_PROJECTS')
        invalidate('MY_LATEST_PROJECTS')
        invalidate('MY_CUSTOM_PROJECT_LABELS')
        return {
            success: true,
            project,
        } as const
    }

    async function createMiniQuiz(name: string) {
        const isNameUnique = await api.projects.isProjectNameUnique(name)
        if (!isNameUnique) {
            return NAME_NOT_UNIQUE_ERROR
        }
        const project = await api.miniQuizzes.createMiniQuiz(name)
        queryCache.invalidateQueries('my-mini-quizzes')
        return {
            success: true,
            project,
        } as const
    }

    async function renameProject(id: string, field: 'name' | 'title', value: string) {
        if (field === 'name') {
            const isNameUnique = await api.projects.isProjectNameUnique(value)
            if (!isNameUnique) {
                return NAME_NOT_UNIQUE_ERROR
            }
        }
        const project = await api.projects.renameProject(id, field, value)
        dispatch(projectActions.renameProject(id, field, value))
        return {
            success: true,
            project,
        }
    }

    async function duplicateProject(id: string, name?: string) {
        if (name) {
            const isNameUnique = await api.projects.isProjectNameUnique(name)
            if (!isNameUnique) {
                return NAME_NOT_UNIQUE_ERROR
            }
        }
        const project = await api.projects.duplicateProject(id, name)
        invalidate('MY_PROJECTS')
        invalidate('MY_LATEST_PROJECTS')
        queryCache.invalidateQueries('my-mini-quizzes')
        return { success: true, project }
    }

    async function deleteProject(id: string) {
        await api.projects.deleteProject(id)
        dispatch(projectActions.deleteProject(id))

        emitProjectAction('delete', id)

        invalidate('MY_LATEST_PROJECTS')
        invalidate('MY_SHARED_CONTEST_PROJECTS')
        invalidate('MY_SHAREABLE_CONTEST_PROJECTS')
        invalidate('MY_CUSTOM_PROJECT_LABELS')
    }

    async function restoreProject(id: string) {
        dispatch(projectActions.restoreProject(id))
        await api.projects.restoreProject(id)

        emitProjectAction('restore', id)

        invalidate('MY_LATEST_PROJECTS')
        invalidate('MY_CUSTOM_PROJECT_LABELS')
    }

    async function shareProject({
        id,
        title,
        details,
        thumbnail,
        lang,
        needsApprove,
        isMiniQuiz,
    }: ShareProjectPayload) {
        // TODO: reduce api calls
        await api.projects.updateProjectData(id, title, details)
        const promises: Promise<any>[] = [renameProject(id, 'title', title), api.projects.updateProjectLang(id, lang)]
        if (!isMiniQuiz) {
            promises.push(api.projects.updateProjectThumbnail(id, thumbnail))
        }
        await Promise.all(promises)

        await api.projects.shareProject(id)
        invalidateProjectData(id, 'normal')
        if (needsApprove) {
            pendingProjectsCache.add(id)
        } else {
            dispatch(projectActions.shareProjectWithoutApprove(id))
            await Promise.all([
                invalidateProjectData(id, 'production'),
                invalidate('MY_SHARED_PROJECTS'),
                invalidate('DISCOVER_PROJECTS'),
            ])
            if (isMiniQuiz) {
                cacheBustThumbnail(id)
            }
        }
    }

    async function nominateProject(id: string, title: string, details: string, thumbnail: string, lang: string) {
        // TODO: reduce api calls
        await api.projects.updateProjectData(id, title, details)
        await Promise.all([
            renameProject(id, 'title', title),
            api.projects.updateProjectLang(id, lang),
            api.projects.updateProjectThumbnail(id, thumbnail),
        ])

        await api.events.nominateProject(id)
        invalidateProjectData(id, 'production')
        invalidateProjectData(id, 'normal')
        await Promise.all([invalidate('MY_SHARED_CONTEST_PROJECTS'), invalidate('MY_SHAREABLE_CONTEST_PROJECTS')])
    }

    async function unshareProject(id: string) {
        await api.projects.unshareProject(id)
        dispatch(projectActions.unshareProject(id))
        await Promise.all([invalidate('MY_SHARED_PROJECTS'), invalidate('DISCOVER_PROJECTS')])
        pendingProjectsCache.remove(id)
    }

    async function updateProjectDetails(id: string, details: string, variant: ProjectVariant) {
        await api.projects.updateProjectDetails(id, details, variant)
        dispatch(projectActions.updateDetails(id, details, variant))
        invalidate('MY_PROJECTS')
    }

    async function unshareNominatedProject(id: string) {
        await api.events.unshareContestProject(id)
        await Promise.all([invalidate('MY_SHARED_CONTEST_PROJECTS'), invalidate('MY_SHAREABLE_CONTEST_PROJECTS')])
    }

    async function toggleProjectFavorite(project: Project) {
        if (project.file.owner === account.id) {
            if (project.file.favorite) {
                dispatch(projectActions.unFavoriteOwnProject(project.file.id))

                emitProjectAction('unfavorite-own', project.file.id)

                await api.projects.unFavoriteOwnProject(project.file.id)
            } else {
                dispatch(projectActions.favoriteOwnProject(project.file.id))
                await api.projects.favoriteOwnProject(project.file.id)
            }
        } else {
            if (project.metadata.favorited) {
                dispatch(projectActions.unfavoriteProject(project.file.id))
            } else {
                dispatch(projectActions.favoriteProject(project.file.id))
            }
            setTimeout(() => {
                invalidate('MY_FAVORITE_PROJECTS')
            }, 1000)
        }
    }

    async function moveToProduction(project: Project | undefined) {
        if (!project) {
            return {
                success: false,
            }
        }

        const isNameUnique = await api.projects.isProductionUserProjectsNameUnique(project.file.name)
        if (!isNameUnique) {
            return NAME_NOT_UNIQUE_ERROR
        }

        const response = await api.projects.moveToProduction(project.file.id)

        if (!response) {
            return {
                success: false,
            }
        }
        dispatch(projectActions.deleteProject(project.file.id))
        emitProjectAction('delete', project.file.id)

        invalidate('MY_LATEST_PROJECTS')
        return { success: true }
    }

    async function cloneProject(projectId: string, type?: 'blox' | 'mix', blockGallery?: string, name?: string) {
        if (type === 'blox' && blockGallery === undefined) {
            throw new Error(`Palette must be defined if type is 'blox'.`)
        }

        const project = await api.projects.getProject(projectId)

        const projectName = name ?? `${project.data.name} clone`

        const isNameUnique = await api.projects.isProjectNameUnique(projectName)

        let convertedKind: 'blox' | 'mix'
        if (project.data.kind === 'project/blocks') {
            convertedKind = 'blox'
        } else if (project.data.kind === 'project/mix') {
            convertedKind = 'mix'
        } else {
            throw new Error()
        }
        const kind = type ?? convertedKind

        if (!isNameUnique) {
            return {
                success: false,
                error: 'NAME_NOT_UNIQUE',
                projectName: project.data.name,
            }
        }

        const clonedProject = await api.projects.cloneProject(projectName, kind, projectId, blockGallery)

        if (!clonedProject) {
            return {
                success: false,
                error: '',
            }
        }

        invalidate('MY_PROJECTS')
        invalidate('MY_LATEST_PROJECTS')
        return { success: true, clonedProject } as const
    }

    async function updateProjectLabels(projectId: string, labels: string[]) {
        const normalizedLabels = labels.map(serializeProjectLabel)
        dispatch(projectActions.updateFile(projectId, { labels: normalizedLabels }))
        await api.projects.updateProjectLabels(projectId, normalizedLabels)
        invalidate('MY_CUSTOM_PROJECT_LABELS')
    }

    return {
        createProject,
        renameProject,
        deleteProject,
        shareProject,
        unshareProject,
        duplicateProject,
        toggleProjectFavorite,
        restoreProject,
        updateProjectDetails,
        moveToProduction,
        cloneProject,
        nominateProject,
        unshareNominatedProject,
        createMiniQuiz,
        updateProjectLabels,
    }
}

type ProjectAction = 'delete' | 'unfavorite-own' | 'restore'

let projectActionListener: Record<ProjectAction, ((id: string) => void)[]> = {
    delete: [],
    restore: [],
    'unfavorite-own': [],
}

const emitProjectAction = (action: ProjectAction, id: string) => {
    projectActionListener[action].forEach(listener => {
        listener(id)
    })
}

export const addProjectActionListener = (action: ProjectAction, listener: (id: string) => void) => {
    projectActionListener[action].push(listener)
}

export const removeProjectActionListener = (action: ProjectAction, listener: (id: string) => void) => {
    projectActionListener[action] = projectActionListener[action].filter(l => l !== listener)
}

import React from 'react'
import styled from 'styled-components/macro'
import { useProgressBarContext } from './ProgressBar'

interface ProgressBarTickProps {
    requiredValue: number
}

export const ProgressBarTick: React.FC<ProgressBarTickProps> = ({ requiredValue, children }) => {
    const { max, value } = useProgressBarContext()
    const left = `${(requiredValue / max) * 100}%`
    const reached = value >= requiredValue

    return (
        <Root style={{ left }}>
            <Line $reached={reached}>{children}</Line>
        </Root>
    )
}

const Root = styled.div`
    position: absolute;
    top: 1px;
    bottom: 1px;
`

const Line = styled.div<{ $reached: boolean }>`
    position: relative;
    height: 100%;
    border-left: solid 3px ${props => props.theme.palette.divider};
`

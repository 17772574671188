import { Select, Option } from 'components/form'
import { TextField } from 'components/form'
import React, { useEffect, useState } from 'react'
import { DiscoverProjectsFilterState } from './DiscoverProjects'
import styled from 'styled-components/macro'
import { FavoriteIcon, Searchicon, TrainerIcon } from 'components/icons'
import { InputAdornment } from 'components/form'
import { ApiTypes } from 'lib/api'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { useDebounce } from 'use-debounce/lib'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Stack } from 'components/layout'
import { ProjectListTab } from '../ProjectListTab'
import { WrappingInline } from 'components/layout/WrappingInline'
import { Card, CardContent } from 'components/card'

interface DiscoverProjectsHeaderProps {
    filters: DecodedValueMap<DiscoverProjectsFilterState>
    setFilters: SetQuery<DiscoverProjectsFilterState>
    languages: ApiTypes['language'][]
}

export const DiscoverProjectsHeader: React.FC<DiscoverProjectsHeaderProps> = ({ filters, setFilters, languages }) => {
    const t = useTranslate()

    return (
        <Stack spacing={5}>
            <WrappingInline>
                <ProjectListTab
                    isActive={filters.view === 'all'}
                    onClick={() => {
                        setFilters({ view: 'all' }, 'replaceIn')
                    }}
                >
                    {t('general::all')}
                </ProjectListTab>
                <ProjectListTab
                    isActive={filters.view === 'favorites'}
                    onClick={() => {
                        setFilters({ view: 'favorites' }, 'replaceIn')
                    }}
                    icon={<FavoriteIcon />}
                >
                    {t('general::favorites')}
                </ProjectListTab>
                <ProjectListTab
                    isActive={filters.view === 'only-trainers'}
                    onClick={() => {
                        setFilters({ view: 'only-trainers' }, 'replaceIn')
                    }}
                    icon={<TrainerIcon />}
                >
                    {t('general::instructor')}
                </ProjectListTab>
            </WrappingInline>
            <Card elevation={1}>
                <CardContent>
                    <Stack spacing={6}>
                        <Grid>
                            <Search filters={filters} setFilters={setFilters} />
                            <InnerGrid>
                                <LanguageSelect
                                    fullWidth={false}
                                    multiple
                                    value={filters.languages}
                                    onChange={e => {
                                        setFilters({ languages: e.target.value as string[] }, 'replaceIn')
                                    }}
                                >
                                    {languages.map(language => (
                                        <Option value={language.code} key={language.code}>
                                            {language.displayName}
                                        </Option>
                                    ))}
                                </LanguageSelect>
                                {filters.sort === '-daysViewedCount' && (
                                    <Select
                                        fullWidth={false}
                                        value={filters.days}
                                        onChange={e => {
                                            setFilters({ days: e.target.value as number }, 'replaceIn')
                                        }}
                                    >
                                        <Option value={1}>{t('timeRanges::day')}</Option>
                                        <Option value={7}>{t('timeRanges::week')}</Option>
                                        <Option value={30}>{t('timeRanges::month')}</Option>
                                        <Option value={-1}>{t('timeRanges::all')}</Option>
                                    </Select>
                                )}
                                <Select
                                    fullWidth={false}
                                    value={filters.sort}
                                    onChange={e => {
                                        setFilters({ sort: e.target.value as string }, 'replaceIn')
                                    }}
                                >
                                    <Option value="-approvedAt">{t('projects::sort::latest')}</Option>
                                    <Option value="-cookieCount">{t('projects::sort::mostCookies')}</Option>
                                    <Option value="-goldenCookieCount">{t('projects::sort::mostGoldenCookies')}</Option>
                                    <Option value="-daysViewedCount">{t('projects::sort::mostViews')}</Option>
                                </Select>
                            </InnerGrid>
                        </Grid>
                    </Stack>
                </CardContent>
            </Card>
        </Stack>
    )
}

const Search: React.FC<Omit<DiscoverProjectsHeaderProps, 'languages'>> = ({ filters, setFilters }) => {
    const [search, setSearch] = useState(filters.pattern)
    const [debouncedSearch] = useDebounce(search, 500)
    const t = useTranslate()

    useEffect(() => {
        setSearch(filters.pattern)
    }, [filters.pattern])

    useEffect(() => {
        setFilters({ pattern: debouncedSearch }, 'replaceIn')
    }, [debouncedSearch, setFilters])

    return (
        <TextField
            placeholder={t('projects::discoverSearchPlaceholder')}
            type="search"
            startAdornment={
                <InputAdornment position="start">
                    <Searchicon />
                </InputAdornment>
            }
            value={search}
            onChange={e => {
                setSearch(e.target.value)
            }}
        />
    )
}

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${props => props.theme.spacing(2)};
    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: 1fr;
    }
`

const InnerGrid = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: ${props => props.theme.spacing(2)};
    ${props => props.theme.breakpoints.down('sm')} {
        justify-content: flex-start;
        flex-wrap: wrap;
        > * {
            flex: 1;
        }
    }
    ${props => props.theme.breakpoints.down('xs')} {
        display: grid;
        grid-template-columns: 1fr;
        > * {
            flex: 1;
        }
    }
`

const LanguageSelect = styled(Select)`
    width: 12rem;
    ${props => props.theme.breakpoints.down('sm')} {
        width: auto;
    }
`

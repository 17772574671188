import { dialogFromNotification, pushQueue } from 'features/dialog-queue'
import { NotificationImage, UnlockAchievementNotificationHandler } from 'features/notifications'
import { LocalisationKey } from 'lib/i18n'
import { InventoryState } from 'lib/liveops/reducers/client/inventory'
import { StatisticState } from 'lib/liveops/reducers/client/statistic'
// import { ATTENDED_OPEN_DAY_ITEM_ID } from 'lib/liveops/selectors'
import { StoreState } from 'lib/store'
import React from 'react'
import {
    OnboardingAchievementData,
    onboardingAchievements,
    ONBOARDING_REACHED_3_POINTS_CATALOG_ITEM,
} from '../data/onboardingData'
import prize1Img from '../media/prize1.svg'

export const getOnboadingPoints = (state: StoreState) => state.client.statistics['OpenDayOnboarding:points']

export const isOnboardingMissionFinished = (statistics: StatisticState) => {
    if (statistics['OpenDayOnboarding:points'] === undefined) {
        return null
    }

    return statistics['OpenDayOnboarding:points'] >= onboardingAchievements.length
}

export const onboardingUnlockAcihevementNotificationHandler: UnlockAchievementNotificationHandler = ({
    notification,
    t,
    dispatch,
}) => {
    // if (notification.message.data.payload.achievementId === ATTENDED_OPEN_DAY_ITEM_ID) {
    //     return {
    //         img: <NotificationImage notiMediaSrc="https://via.placeholder.com/150" />,
    //         title: 'Unlocked content',
    //         body: 'Challenges unlocked.',
    //         cta: {
    //             type: 'custom',
    //             onClick: () => {
    //                 const dialog = dialogFromNotification(notification)
    //                 if (dialog) {
    //                     dispatch(pushQueue(dialog))
    //                 }
    //             },
    //         },
    //     }
    // }

    if (notification.message.data.payload.achievementId === ONBOARDING_REACHED_3_POINTS_CATALOG_ITEM) {
        return {
            img: <NotificationImage notiMediaSrc={prize1Img} />,
            title: t('onboardingMission::missionUnlockedPopUp::title'),
            body: t('onboardingMission::missionUnlockedPopUp::description'),
            cta: {
                type: 'custom',
                onClick: () => {
                    const dialog = dialogFromNotification(notification)
                    if (dialog) {
                        dispatch(pushQueue(dialog))
                    }
                },
            },
        }
    }

    const achievementData = onboardingAchievements.find(
        item => item.catalogItem === notification.message.data.payload.achievementId,
    )

    if (!achievementData) {
        return null
    }

    return {
        img: <NotificationImage notiMediaSrc={achievementData.imageUrl} />,
        title: t('notification::unlockAchievement::title', {
            name: t(achievementData.title as LocalisationKey),
        }),
        body: t('notification::unlockAchievement::body'),
        cta: {
            type: 'custom',
            onClick: () => {
                const dialog = dialogFromNotification(notification)
                if (dialog) {
                    dispatch(pushQueue(dialog))
                }
            },
        },
    }
}

export const getFirstNotCompletedAchievement = (
    achievements: OnboardingAchievementData[],
    inventory: InventoryState,
) => {
    return (
        achievements.find(achi => inventory?.find(item => item.item === achi.catalogItem) === undefined) ??
        achievements[0]
    )
}

export const isAchievementAcquired = (achievement: OnboardingAchievementData, inventory: InventoryState) =>
    !!inventory?.find(item => item.item === achievement.catalogItem)

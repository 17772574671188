import React from 'react'
import { MinimalAccount } from 'lib/api'
import { Project, createProjectThumbnailUrl } from 'features/projects'
import { ProjectCardUser } from 'features/projects/card/ProjetCardUser'
import styled from 'styled-components/macro'
import { MiniQuizCardMetadata } from './MiniQuizCardMetadata'
import { ProjectStripe } from 'features/projects/stripe'
import { Theme, useMediaQuery } from '@material-ui/core'

interface MyMiniQuizCardProps {
    project: Project
    user?: MinimalAccount
}

export const PublicMiniQuizCard: React.FC<MyMiniQuizCardProps> = ({ project, user }) => {
    const isSmallScreen = useMediaQuery<Theme>(theme => theme.breakpoints.down('sm'), { noSsr: true })

    return (
        <ProjectStripe
            thumbnailUrl={createProjectThumbnailUrl(project.file.id, 'production')}
            title={project.file.title ?? ''}
            actions={
                isSmallScreen ? null : (
                    <>
                        <StyledProjectCardUser user={user} />
                        <MiniQuizCardMetadata project={project} variant="production" />
                    </>
                )
            }
            to={`/app/project/${project.file.id}`}
        />
    )
}

const StyledProjectCardUser = styled(ProjectCardUser)`
    text-align: left;
    padding-right: ${props => props.theme.spacing(6)};
    border-right: ${props => `1px solid ${props.theme.palette.divider}`};
`

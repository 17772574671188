import { ErrorMessage } from 'components/ErrorMessage'
import * as React from 'react'
import { DialogSecondaryButton } from '.'
import { DialogContent } from './DialogContent'
import { DialogActions } from './DialogActions'
import { useTranslate } from 'lib/i18n/useTranslate'

interface DialogErrorMessageProps {
    close: () => void
}

export const DialogErrorMessage: React.FC<DialogErrorMessageProps> = ({ close, children }) => {
    const t = useTranslate()

    return (
        <>
            <DialogContent>
                <ErrorMessage>{children}</ErrorMessage>
            </DialogContent>
            <DialogActions>
                <DialogSecondaryButton onClick={close}>{t('general::cancel')}</DialogSecondaryButton>
            </DialogActions>
        </>
    )
}

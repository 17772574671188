import React, { useState } from 'react'
import { AuthFlow, AuthStatus } from 'lib/auth'
import { AppRouter } from 'lib/router'
import { api } from 'lib/api'
import { useUserActions } from 'contexts/UserContext'
import { useTheming } from 'lib/theme'
import { initLiveops } from 'lib/liveops'
import { useStore } from 'react-redux'
import { i18n } from 'lib/i18n'
import { useFeed } from 'features/feed'
//import * as Sentry from '@sentry/react'
import { setUserInGoogleTagManager } from 'utils/gtm'
import { useEvents } from './features/events/useEvents'

export const App = () => {
    const [authStatus, setAuthStatus] = useState<AuthStatus>('loading')
    const userActions = useUserActions()
    const { initFeed } = useFeed()
    const { initEvents } = useEvents()
    const { setThemeFromUserSettings } = useTheming()
    const store = useStore()

    function onAuthError() {
        if (!i18n.isInitialized) {
            i18n.setLocale('en')
        }
        setAuthStatus('error')
    }

    async function init(token: string) {
        try {
            api.init(token)
            initLiveops(token, store)
            const user = await userActions.init()
            setUserInGoogleTagManager(user)
            //Sentry.setUser({ id: user.account.id })
            await Promise.all([initFeed(user), initEvents(user)])
            api.setUserId(user.account.id)
            setThemeFromUserSettings(user)
            setAuthStatus('logged-in')
        } catch (e) {
            console.error(e)
            onAuthError()
        }
    }

    if (authStatus === 'error') {
        throw new Error('Authentication error.')
    }

    if (authStatus === 'loading') {
        return (
            <AuthFlow
                onSucces={init}
                onReceiverFail={() => {
                    onAuthError()
                }}
                onSenderFail={() => {
                    setAuthStatus('logged-out')
                }}
            />
        )
    }

    return <AppRouter authStatus={authStatus} />
}

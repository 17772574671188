import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'
import { joinQuizDialogTo } from './JoinQuizDialog'
import { useTranslate } from 'lib/i18n/useTranslate'

export const JoinQuizButton: React.FC = () => {
    const t = useTranslate()

    return (
        <>
            <Button component={Link} to={joinQuizDialogTo}>
                {t('quiz::join')}
            </Button>
        </>
    )
}

import React from 'react'
import { Box } from 'components/layout'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Loader } from 'components/Loader'

export function usePoorMansSuspense(count: number) {
    const [readyCount, setReadyCount] = useState(0)
    const [timedOut, setTimedOut] = useState(false)

    useEffect(() => {
        if (!timedOut) {
            const timeout = setTimeout(() => {
                setTimedOut(true)
            }, 4000)
            return () => {
                clearTimeout(timeout)
            }
        }
    }, [timedOut])

    const tick = useCallback(() => {
        setReadyCount(readyCount => readyCount + 1)
    }, [])

    const isReady = timedOut || readyCount >= count

    return {
        tick,
        isReady,
    }
}

interface PoorMansSuspenseRendererProps {
    isReady: boolean
    loader?: React.ReactNode
}

export const PoorMansSuspenseRenderer: React.FC<PoorMansSuspenseRendererProps> = ({ children, isReady, loader }) => {
    const defaultLoader = loader ?? <Loader />

    return (
        <>
            {!isReady && defaultLoader}
            <Box visibility={isReady ? 'visible' : 'hidden'}>{children}</Box>
        </>
    )
}

export function usePoorMansSuspenseTick(isReady: boolean, onReady?: VoidFunction) {
    useLayoutEffect(() => {
        if (isReady) {
            onReady?.()
        }
    }, [isReady, onReady])
}

export interface PoorMansSuspenseTicker {
    onReady?: VoidFunction
}

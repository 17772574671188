import { Theme } from '@material-ui/core'
import { Text } from 'components/Text'
import { Tooltip } from 'components/Tooltip'
import dayjs from 'dayjs'
import { useHumanReadableCountdown } from 'hooks/useCountdown'
import { useDateFormat } from 'hooks/useDateFormat'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useMemo, useEffect } from 'react'
import styled, { keyframes, css } from 'styled-components/macro'

interface HighScorePanelCountdownProps {
    date: string
    onEnd?: VoidFunction
}

export const HighScoreRoomCountdown: React.FC<HighScorePanelCountdownProps> = ({ date, onEnd }) => {
    const t = useTranslate()
    const { formatDate } = useDateFormat()
    const dayjsDate = useMemo(() => dayjs(date), [date])
    const { remaining, isEnd, label } = useHumanReadableCountdown(dayjsDate)

    useEffect(() => {
        if (isEnd) {
            onEnd?.()
        }
    }, [isEnd, onEnd])

    const isLastMinute = isEnd === false && remaining.days <= 0 && remaining.hours <= 0 && remaining.minutes <= 0
    const isLast20Seconds = isLastMinute && remaining.seconds <= 20

    return (
        <div>
            <Text>{t('general::remainingTime')}</Text>
            <Tooltip title={formatDate(dayjsDate.toDate())}>
                <span>
                    <CountdownValue $pulse={isLast20Seconds}>{label}</CountdownValue>
                </span>
            </Tooltip>
        </div>
    )
}

const pulseAnimation = (theme: Theme) => {
    return keyframes`
    0% {
        color: ${theme.palette.error.light};
        transform: scale(1)
    }
    100% {
        color: ${theme.palette.error.main};
        transform: scale(1.05)
    }
`
}

const CountdownValue = styled(Text)<{ $pulse: boolean }>`
    font-weight: 700;
    display: inline-block;
    position: relative;
    animation: ${props =>
        props.$pulse
            ? css`
                  ${pulseAnimation(props.theme)} 0.5s infinite alternate
              `
            : undefined};
`

import { Theme } from '@material-ui/core'
import { css } from 'styled-components/macro'

export const visuallyHidden = css`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
`

export const NBSP = '\u00A0'

export const resetBtnStyle = css`
    font-family: inherit;
    font-size: 100%;
    line-height: inherit;
    color: inherit;
    text-transform: none;
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    text-align: inherit;
    border-radius: 0;
`

export const actionablePaperStyles = css`
    background-color: ${props => props.theme.palette.background.paper};
    transition: all ${props => props.theme.transitions.duration.shortest}ms;
    &:focus {
        outline: none;
    }
    &:hover,
    &:focus-visible {
        background-color: ${props => props.theme.palette.action.hover};
    }
    &:focus-visible {
        outline: solid 1px ${props => props.theme.palette.primary.main};
    }

    ${props => props.theme.extras?.actionablePaper?.root}
`

export const customScrollbar = css`
    &::-webkit-scrollbar-track {
        background-color: ${props => props.theme.palette.background.paper};
    }

    &::-webkit-scrollbar {
        width: 8px;
        background-color: ${props => props.theme.palette.background.paper};
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: ${props => props.theme.palette.divider};
    }
`

export interface LightDarkColor {
    light: string
    dark: string
}

export function pickLightDarkColor(theme: Theme): undefined
export function pickLightDarkColor(theme: Theme, color?: LightDarkColor): string
export function pickLightDarkColor(theme: Theme, color?: LightDarkColor) {
    return theme.palette.type === 'dark' ? color?.dark : color?.light
}

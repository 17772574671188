import { Inline } from 'components/layout'
import { MenuIcon } from 'components/icons'
import { Img } from 'components/Img'
import React from 'react'
import styled, { useTheme } from 'styled-components/macro'
import LogiscoolLogo from 'media/images/logiscool-logo.png'
import LogiscoolLogoWhite from 'media/images/logiscool-logo-white.png'
import { Link } from 'react-router-dom'
import { IconButton } from 'components/IconButton'
import { NotificationButton } from 'features/notifications'
import { ErrorBoundary } from 'components/ErrorBoundary'

type PageMobileHeaderProps = {
    onMenuButtonClick: () => void
}

export const PageMobileHeader: React.FC<PageMobileHeaderProps> = ({ onMenuButtonClick }) => {
    const theme = useTheme()

    return (
        <Root component="header">
            <Inline display="flex" alignItems="center">
                <IconButton color="inherit" onClick={onMenuButtonClick}>
                    <MenuIcon />
                </IconButton>
                <LogoLink to="/app">
                    <Img
                        display="block"
                        maxWidth="7rem"
                        src={theme.extras?.logoType === 'white' ? LogiscoolLogoWhite : LogiscoolLogo}
                    ></Img>
                </LogoLink>
            </Inline>
            <ErrorBoundary silent>
                <NotificationButton />
            </ErrorBoundary>
        </Root>
    )
}

const LogoLink = styled(Link)`
    /* improve visual alignment */
    margin-top: 4px;
`

const Root = styled(Inline)`
    position: sticky;
    top: 0;
    z-index: ${props => props.theme.zIndex.appBar};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.theme.spacing(2, 3)};
    background-color: ${props => props.theme.palette.background.paper};
    box-shadow: ${props => props.theme.shadows[3]};
    ${props => props.theme.extras?.mobileHeader?.root}
`

import image0 from './656ef0f76607f0129e79ffe8.png'
import image1 from './656ef1afa3ce9b42dd25a15f.png'
import image2 from './656ef1bea3ce9b42dd25a210.png'
import image3 from './656ef1cc6607f0129e7a084d.png'
import image4 from './656ef18f6607f0129e7a05db.png'
import image5 from './656ef19ca3ce9b42dd25a103.png'
import image6 from './656ef120a3ce9b42dd259c4f.png'
import image7 from './656ef1636607f0129e7a03e8.png'
import image8 from './656ef1786607f0129e7a04f5.png'
import image9 from './6568a364d89bd678473bea16.png'

type ImagesRecord = Record<string, string>

export const images: ImagesRecord = {
    '656ef0f76607f0129e79ffe8': image0,
    '656ef1afa3ce9b42dd25a15f': image1,
    '656ef1bea3ce9b42dd25a210': image2,
    '656ef1cc6607f0129e7a084d': image3,
    '656ef18f6607f0129e7a05db': image4,
    '656ef19ca3ce9b42dd25a103': image5,
    '656ef120a3ce9b42dd259c4f': image6,
    '656ef1636607f0129e7a03e8': image7,
    '656ef1786607f0129e7a04f5': image8,
    '6568a364d89bd678473bea16': image9,
}

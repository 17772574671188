import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'
import { EventDefinition } from '../types/eventTypes'
import { Link } from 'react-router-dom'
import { useTranslate } from 'lib/i18n/useTranslate'
import { LocalisationKey } from 'lib/i18n'
import { Badge } from 'components/Badge'
import { Stack } from 'components/layout'
import { ManagableEventDefinition } from '../types/eventTypesV2'

interface EventThumbnailProps {
    event: EventDefinition | ManagableEventDefinition
}

export const EventThumbnail: React.FC<EventThumbnailProps> = ({ event }) => {
    const t = useTranslate()
    const title = (event.ongoing ? event.title : event.closedTitle) || event.title

    return (
        <Root to={`/app/events/${event.slug}`}>
            <Thumbnail width="400" height="400" src={event.thumbnail} />
            {event.hasGroupInfo && event.ongoing && (
                <BadgeWrap>
                    <Badge>
                        Ongoing: {(event.groupCount || 0) - (event.closedCount || 0)} / {event.groupCount || 0}
                    </Badge>
                </BadgeWrap>
            )}
            <Title variant="h1" component="h2">
                {t(title as LocalisationKey)}
            </Title>
        </Root>
    )
}

const Title = styled(Text)`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(32, 35, 35, 0.7), rgba(32, 35, 35, 0));
    padding: ${props => props.theme.spacing(12, 6, 6, 6)};
    color: white;
    font-weight: 800;
    line-height: 1.3;
` as typeof Text

const BadgeWrap = styled(Stack)`
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 0;
`

const Root = styled(Link)`
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    transition: filter ${props => props.theme.transitions.duration.shorter}ms;
    &:hover,
    &:focus-visible {
        filter: brightness(0.9);
    }
    ${props => props.theme.breakpoints.down('md')} {
        ${Title as any} {
            padding: ${props => props.theme.spacing(6, 3, 3, 3)};
            font-size: ${props => props.theme.typography.h5.fontSize};
        }
    }
    ${props => props.theme.breakpoints.down('sm')} {
        ${Title as any} {
            font-size: ${props => props.theme.typography.h6.fontSize};
        }
    }
    ${props => props.theme.breakpoints.down('xs')} {
        ${Title as any} {
            padding: ${props => props.theme.spacing(3, 2, 2, 2)};
            font-size: ${props => props.theme.typography.body1.fontSize};
        }
    }
`

const Thumbnail = styled.img`
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
    background-color: ${props => props.theme.palette.divider};
`

import { UserState } from 'contexts/UserContext'

interface CampChallengeStaticData {
    quizzes: {
        easy: string
        medium: string
        hard: string
    }
    videoId?: string
    camps: { key: string; name: string; image: string }[]
}

interface CampChallengeCountryData {
    startDate: string
    endDate: string
    rulesURL?: string
    ctaURL?: string
}

interface CampChallengeDefinition {
    countries: Record<string, CampChallengeCountryData>
    static: CampChallengeStaticData
}

export type CampChallengeData = CampChallengeStaticData & CampChallengeCountryData

export const campChallengeData: Record<number, CampChallengeDefinition> = {
    2023: {
        countries: {
            HU: {
                startDate: '2023-05-08T06:00:00Z',
                endDate: '2023-06-04T06:00:00Z',
                rulesURL: 'https://www.logiscool.com/hu/docs/camp-challenge',
                ctaURL: 'https://www.logiscool.com/hu/programs/camp-challenge',
            },
            CZ: {
                startDate: '2023-05-08T06:00:00Z',
                endDate: '2023-05-31T06:00:00Z',
                rulesURL: 'https://www.logiscool.com/cz/docs/camp-challenge',
                ctaURL: 'https://www.logiscool.com/cz/programs/camp-challenge',
            },
            SG: {
                startDate: '2023-05-08T06:00:00Z',
                endDate: '2023-05-31T06:00:00Z',
                rulesURL: 'https://www.logiscool.com/sg/docs/camp-challenge',
                ctaURL: 'https://www.logiscool.com/sg/programs/camp-challenge',
            },
            RS: {
                startDate: '2023-05-11T06:00:00Z',
                endDate: '2023-06-20T06:00:00Z',
                rulesURL: 'https://www.logiscool.com/rs/docs/camp-challenge',
                ctaURL: 'https://www.logiscool.com/rs/programs/camp-challenge',
            },
            AE: {
                startDate: '2023-05-15T06:00:00Z',
                endDate: '2023-06-10T06:00:00Z',
                rulesURL: 'https://www.logiscool.com/ae/docs/camp-challenge',
                ctaURL: 'https://www.logiscool.com/ae/programs/camp-challenge',
            },
            RO: {
                startDate: '2023-05-15T06:00:00Z',
                endDate: '2023-06-08T06:00:00Z',
                rulesURL: 'https://www.logiscool.com/ro/docs/camp-challenge',
                ctaURL: 'https://www.logiscool.com/ro/programs/camp-challenge',
            },
            BG: {
                startDate: '2023-05-15T06:00:00Z',
                endDate: '2023-06-30T06:00:00Z',
                ctaURL: 'https://www.logiscool.com/bg/programs/camp-challenge',
            },
            ES: {
                startDate: '2023-05-29T06:00:00Z',
                endDate: '2023-06-23T06:00:00Z',
                ctaURL: 'https://www.logiscool.com/es/programs/camp-challenge',
            },
            ZA: {
                startDate: '2023-05-29T06:00:00Z',
                endDate: '2023-08-31T06:00:00Z',
                ctaURL: 'https://www.logiscool.com/za/programs/camp-challenge',
            },
        },
        static: {
            quizzes: {
                easy: '644bbc3751fe8c00e29360a9',
                medium: '644bc1d851fe8ca960937501',
                hard: '644bbbc358ac8c382251c5c6',
            },
            videoId: 'v5GzkHrSEKQ',
            camps: [
                {
                    key: 'programming',
                    name: 'campPromoMission2023::programmingCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b93f3f78c89704d9c7941/data',
                },
                {
                    key: 'minecraft',
                    name: 'campPromoMission2023::minecraftCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b95015f4467a39eb70813/data',
                },
                {
                    key: 'roblox',
                    name: 'campPromoMission2023::robloxCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b9513f78c89689b9c8077/data',
                },
                {
                    key: 'digital-creator',
                    name: 'campPromoMission2023::digitalCreatorCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b95235f44677a22b708af/data',
                },
                {
                    key: 'robotics',
                    name: 'campPromoMission2023::RoboticsCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b95355f4467bc12b7092b/data',
                },
                {
                    key: 'explorer',
                    name: 'campPromoMission2023::explorerCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b953ff78c893fd49c819f/data',
                },
            ],
        },
    },
    2024: {
        countries: {},
        static: {
            quizzes: {
                easy: '6601727a848d9e8d5231f637',
                medium: '66017260f21e93aac9d5f7de',
                hard: '66016f4a848d9e8d5231ec6d',
            },
            videoId: 'v5GzkHrSEKQ',
            camps: [
                {
                    key: 'programming',
                    name: 'campPromoMission2023::programmingCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b93f3f78c89704d9c7941/data',
                },
                {
                    key: 'minecraft',
                    name: 'campPromoMission2023::minecraftCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b95015f4467a39eb70813/data',
                },
                {
                    key: 'roblox',
                    name: 'campPromoMission2023::robloxCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b9513f78c89689b9c8077/data',
                },
                {
                    key: 'digital-creator',
                    name: 'campPromoMission2023::digitalCreatorCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b95235f44677a22b708af/data',
                },
                {
                    key: 'robotics',
                    name: 'campPromoMission2023::RoboticsCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b95355f4467bc12b7092b/data',
                },
                {
                    key: 'explorer',
                    name: 'campPromoMission2023::explorerCamps',
                    image: 'https://api.prod.scoolcode.com/files/644b953ff78c893fd49c819f/data',
                },
            ],
        },
    },
}

export function getCampChallengeData(user: UserState, year: keyof typeof campChallengeData): CampChallengeData | null {
    const data = campChallengeData[year]

    const countryData = user.account.country ? data.countries[user.account.country] : {}

    return {
        ...data.static,
        ...countryData,
    } as CampChallengeData
}

import React from 'react'
import styled from 'styled-components/macro'

interface PanelHeaderProps {
    id?: string
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({ children, ...rest }) => {
    return <Root {...rest}>{children}</Root>
}

const Root = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    ${props => props.theme.breakpoints.down('sm')} {
        align-items: center;
    }
`

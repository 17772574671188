import { Text } from 'components/Text'
import { ActionCard } from 'components/action-card'
import { Card, CardContent } from 'components/card'
import { Inline, Stack } from 'components/layout'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { CheckCircleIcon, PlayIcon, RightAngleIcon } from 'components/icons'
import { useTranslate } from 'lib/i18n/useTranslate'
import { SelectBox, SelectBoxItem, useSelectBoxContext } from 'components/form'
import { Button } from 'components/Button'
import { EmbeddedVideo } from 'components/EmbeddedVideo'
import ctaImg from '../media/cta.jpg'
import { useUserState } from 'contexts/UserContext'
import { CampChallengeData, getCampChallengeData } from '../data/campChallengeData'
import { useQuizTemplatesSessionQuery } from 'features/quiz/home-quiz/homeQuizQueries'
import { ErrorMessage } from 'components/ErrorMessage'
import { Loader } from 'components/Loader'
import { Dialog } from 'components/dialog'
import { resetBtnStyle } from 'utils/styleUtils'
import { PageContent } from 'components/page'
import { useQuery, useQueryCache } from 'react-query'
import { api } from 'lib/api'
import bannerImg from '../media/banner.jpg'
import { Collapse } from 'components/Collapse'
import { useCountdown, useHumanReadableCountdown } from 'hooks/useCountdown'
import dayjs, { Dayjs } from 'dayjs'
import { useNotifications } from 'features/notifications'
import { transparentize } from 'polished'
import { ScrollRestoration } from 'lib/router/Scrollrestoration'
import { ManagableEventDefinition } from '../../types/eventTypesV2'
import { ActivityEvent } from '../../activity-events/types/activityEventTypes'
import { useActivityEventContext } from '../../activity-events/helpers/activityEventContext'

export const CampChallengeDynamicPage: React.FC<{ event: ManagableEventDefinition }> = ({ event }) => {
    const year = event.templateData.year

    const t = useTranslate()
    const user = useUserState()
    const campChallengeData = getCampChallengeData(user, year)
    const { currentFakeDate } = useActivityEventContext()

    const modifiedData = useMemo(() => {
        if (!campChallengeData) return null
        const original = (event as any).original as ActivityEvent
        return {
            ...campChallengeData,
            startDate: original.startDate,
            endDate: original.endDate,
            rulesURL: event.templateData.rulesURL,
            ctaURL: event.templateData.ctaURL,
            currentFakeDate,
            ongoing: !original.closed,
        }
    }, [campChallengeData, event, currentFakeDate])

    if (!modifiedData) {
        throw new Error()
    }

    return (
        <>
            <ScrollRestoration />
            <Banner $backgroundImage={event.detailsBanner || bannerImg}>
                <Text variant="h1">{t(event.title)}</Text>
            </Banner>
            <PageContent>
                <CampChallengePageInner campChallengeData={modifiedData} year={year} />
            </PageContent>
        </>
    )
}

const CampChallengePage: React.FC = () => {
    const t = useTranslate()
    const user = useUserState()
    const campChallengeData = getCampChallengeData(user, 2023)

    if (!campChallengeData) {
        throw new Error()
    }

    return (
        <>
            <ScrollRestoration />
            <Banner $backgroundImage={bannerImg}>
                <Text variant="h1">{t('campPromoMission2023::pageTitle')}</Text>
            </Banner>
            <PageContent>
                <CampChallengePageInner campChallengeData={campChallengeData} year={2023} />
            </PageContent>
        </>
    )
}

const CampChallengePageInner: React.FC<{
    campChallengeData: CampChallengeData & { currentFakeDate?: Dayjs; ongoing?: boolean }
    year: number
}> = ({ campChallengeData, year }) => {
    const t = useTranslate()
    const queryCache = useQueryCache()
    const [isVideoOpen, setIsVideoOpen] = useState(false)

    const { data: selectedCampName, status: linkQueryStatus } = useSelectedCampQuery(year)
    const { data: quizSessions, status: quizSessionQueryStatus } = useQuizTemplatesSessionQuery(
        Object.values(campChallengeData.quizzes),
    )
    const { isEnd } = useCountdown(
        useMemo(() => dayjs(campChallengeData.endDate), [campChallengeData.endDate]),
        campChallengeData.currentFakeDate,
    )

    if (quizSessionQueryStatus === 'error' || linkQueryStatus === 'error') {
        return <ErrorMessage />
    }

    if (!quizSessions || selectedCampName === undefined) {
        return <Loader />
    }

    const selectedCampKey: string = campChallengeData.camps.find(camp => camp.key === selectedCampName)?.key ?? ''

    const hasSelectedCamp = selectedCampKey !== ''
    const hasCompletedQuiz = Object.values(quizSessions).some(({ status }) => status === 'finished')
    const isQualified = hasSelectedCamp && hasCompletedQuiz

    return (
        <>
            <Stack spacing={8} maxWidth="55rem" margin="0 auto">
                {campChallengeData.ongoing && <QualifiedBox isQualified={isQualified} />}
                <Stack spacing={2}>
                    <Text>
                        {isEnd
                            ? t('campPromoMission2023::pageDescriptionEnd')
                            : t('campPromoMission2023::pageDescription')}
                    </Text>
                    <Countdown
                        endDate={campChallengeData.endDate}
                        currentFakeDate={campChallengeData.currentFakeDate}
                    />
                </Stack>

                {!isEnd && (
                    <>
                        <Stack>
                            <Text variant="h2">{t('campPromoMission2023::pageTitle01')}</Text>
                            <Text>{t('campPromoMission2023::pageDescription01')}</Text>
                            <QuizGrid>
                                <img src="https://api.prod.scoolcode.com/files/645521aa3b256745949cefcc/data" alt="" />
                                <Stack>
                                    {(['easy', 'medium', 'hard'] as const).map(level => {
                                        const isFinished =
                                            campChallengeData.quizzes[level] in quizSessions
                                                ? quizSessions[campChallengeData.quizzes[level]].status === 'finished'
                                                : false

                                        return (
                                            <TemplateItemRoot
                                                key={level}
                                                type={isFinished ? 'button' : 'link'}
                                                to={`/app/quiz/${campChallengeData.quizzes[level]}`}
                                            >
                                                <Content>
                                                    <Inline display="flex" alignItems="center">
                                                        <IconWrap>
                                                            {isFinished ? <QuizFinishedIcon /> : <StartIcon />}
                                                        </IconWrap>
                                                        <Text>{t(`general::${level}`)}</Text>
                                                    </Inline>
                                                    {!isFinished && (
                                                        <ActionWrap component="span" spacing={2}>
                                                            {t('quiz::startQuiz')}
                                                            <RightAngleIcon />
                                                        </ActionWrap>
                                                    )}
                                                </Content>
                                            </TemplateItemRoot>
                                        )
                                    })}
                                </Stack>
                            </QuizGrid>
                        </Stack>
                        <Stack>
                            <Text variant="h2">{t('campPromoMission2023::pageTitle02')}</Text>
                            <Text>{t('campPromoMission2023::pageDescription02')}</Text>
                            <div>
                                <SelectBox
                                    value={selectedCampKey}
                                    onChange={value => {
                                        api.accounts.setCrmLink(`camp-challenge-${year}-camp`, value)
                                        queryCache.setQueryData(
                                            ['crm-account-link-flag', { type: `camp-challenge-${year}-camp` }],
                                            () => value,
                                        )
                                    }}
                                >
                                    <CampSelectGrid>
                                        {campChallengeData.camps.map(camp => (
                                            <CampSelectBoxItem value={camp.key} img={camp.image} name={t(camp.name)} />
                                        ))}
                                    </CampSelectGrid>
                                </SelectBox>
                            </div>
                        </Stack>
                        <Stack>
                            <CtaGrid>
                                <Stack>
                                    <Text variant="h2">{t('campPromoMission2023::pageTitle03')}</Text>
                                    <Text>{t('campPromoMission2023::pageDescription03')}</Text>
                                    {campChallengeData.ctaURL && (
                                        <Button component="a" href={campChallengeData.ctaURL} target="_blank" fullWidth>
                                            {t('campPromoMission2023::askYourParentsToRegisterCTA')}
                                        </Button>
                                    )}
                                </Stack>
                                <VideoWrap>
                                    {campChallengeData.videoId && (
                                        <VideoOverlay
                                            onClick={() => {
                                                setIsVideoOpen(true)
                                            }}
                                        >
                                            <span>
                                                <PlayIcon />
                                            </span>
                                        </VideoOverlay>
                                    )}
                                    <img src={ctaImg} alt="" />
                                </VideoWrap>
                            </CtaGrid>
                        </Stack>
                    </>
                )}
                {campChallengeData.rulesURL && (
                    <Button variant="text" component="a" href={campChallengeData.rulesURL} target="_blank">
                        {t('digitalMission2022::rulesAndConditions')}
                    </Button>
                )}
            </Stack>
            {campChallengeData.videoId && (
                <Dialog
                    maxWidth="md"
                    fullWidth
                    open={isVideoOpen}
                    onClose={() => {
                        setIsVideoOpen(false)
                    }}
                >
                    <EmbeddedVideo autoPlay type="youtube" id={campChallengeData.videoId} />
                </Dialog>
            )}
        </>
    )
}

const Countdown: React.FC<{ endDate: string; currentFakeDate?: Dayjs }> = ({ endDate, currentFakeDate }) => {
    const t = useTranslate()
    const { label: countdownLabel, isEnd } = useHumanReadableCountdown(
        useMemo(() => dayjs(endDate), [endDate]),
        '',
        currentFakeDate,
    )

    if (isEnd) {
        return (
            <Text>
                <strong>{t('campPromoMission2023::challengeIsOver')}</strong>
            </Text>
        )
    }

    return (
        <ConutdownRoot>
            {t('general::remainingTime')}: <strong>{countdownLabel}</strong>
        </ConutdownRoot>
    )
}

const ConutdownRoot = styled(Text)`
    border-left: solid 3px ${props => props.theme.palette.primary.main};
    padding-left: ${props => props.theme.spacing(4)};
`

const QualifiedBox: React.FC<{ isQualified: boolean }> = ({ isQualified }) => {
    const t = useTranslate()
    const { notify } = useNotifications()
    const shouldDisplayNotification = useRef(false)

    useEffect(() => {
        if (isQualified && shouldDisplayNotification.current) {
            notify(t('campPromoMission2023::completedMission'), { variant: 'success' })
        }
    }, [isQualified, notify, t])

    useEffect(() => {
        shouldDisplayNotification.current = true
    }, [])

    return (
        <Collapse in={isQualified} unmountOnExit>
            <QualifiedBoxRoot>
                <CardContent>
                    <QualifiedBoxInner>
                        <CheckCircleIcon fontSize="2rem" />
                        <Text>
                            <strong>{t('campPromoMission2023::completedMission')}</strong>
                        </Text>
                    </QualifiedBoxInner>
                </CardContent>
            </QualifiedBoxRoot>
        </Collapse>
    )
}

const QualifiedBoxRoot = styled(Card)`
    background: #2e7d32;
    color: #fff;
`

const QualifiedBoxInner = styled.div`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing(4)};
`

const useSelectedCampQuery = (year: number) => {
    return useQuery(
        ['crm-account-link-flag', { type: `camp-challenge-${year}-camp` }],
        async () => {
            const link = await api.accounts.getCrmLink(`camp-challenge-${year}-camp`)
            return link.data?.value ?? null
        },
        { staleTime: Infinity },
    )
}

const VideoWrap = styled.div`
    position: relative;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    overflow: hidden;
    img {
        display: block;
        max-width: 100%;
    }
`

const VideoOverlay = styled.button`
    ${resetBtnStyle}
    background-color: rgba(32, 33, 42, 0.25);
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${props => props.theme.palette.primary.main};
    &:hover,
    &:focus-visible {
        span {
            transform: scale(1.1);
        }
    }
    span {
        width: 5rem;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: ${props => props.theme.spacing(4)};
        border-radius: 50%;
        background-color: #fff;
        filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.6));
        transition: ${props => props.theme.transitions.duration.standard}ms;
        svg {
            position: relative;
            left: 3px;
            width: 100%;
            height: 100%;
        }
    }
`

const CtaGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.theme.spacing(8)};
    width: 100%;
    align-items: flex-start;
    ${props => props.theme.breakpoints.down('xs')} {
        grid-template-columns: auto;
        grid-gap: ${props => props.theme.spacing(4)};
    }
`

const CampSelectBoxItem: React.FC<{ value: string; img: string; name: string }> = ({ value, img, name }) => {
    const context = useSelectBoxContext()
    const selected = context.value === value
    const hasValue = context.value !== undefined && context.value !== ''

    return (
        <StyledSelectBoxItem
            fullWidth
            value={value}
            $img={img}
            innerClassName="inner"
            $selected={selected}
            $hasValue={hasValue}
        >
            <Text style={{ fontWeight: 600 }}>
                {name}
                {selected && <CheckCircleIcon />}
            </Text>
        </StyledSelectBoxItem>
    )
}

const StyledSelectBoxItem = styled(SelectBoxItem)<{ $img: string; $selected: boolean; $hasValue: boolean }>`
    position: relative;
    .inner {
        position: relative;
        overflow: hidden;
        aspect-ratio: 1/1;
        background: url('${props => props.$img}');
        background-size: cover;
        background-origin: border-box;
        transition: all ${props => props.theme.transitions.duration.short}ms;
        filter: ${props => (!props.$hasValue || props.$selected ? undefined : `brightness(1) grayscale(1)`)};
        box-shadow: ${props =>
            props.$selected ? `0 0 0 3px ${props.theme.palette.primary.light} !important` : undefined};
        &:hover,
        &:focus-visible {
            border-color: ${props => props.theme.palette.divider};
            filter: ${props =>
                !props.$hasValue || props.$selected ? `brightness(0.85)` : `brightness(0.85) grayscale(1)`};
        }
    }
    svg {
        position: absolute;
        top: ${props => props.theme.spacing(2)};
        right: ${props => props.theme.spacing(2)};
        color: white;
        font-size: 2rem;
        filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.3));
    }
    p {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 60%;
        color: #fff;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
        padding: ${props => props.theme.spacing(4)};
        text-shadow: 0 3px 4px rgba(0, 0, 0, 1);
        font-size: ${props => props.theme.typography.h3.fontSize};
        line-height: 1.2;
        word-break: break-word;
        ${props =>
            props.$selected &&
            css`
                height: 100%;
                background: linear-gradient(
                    ${props => transparentize(1, props.theme.palette.primary.dark)},
                    ${props => transparentize(0, props.theme.palette.primary.dark)}
                );
                text-shadow: none;
            `}
    }
`

const CampSelectGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${props => props.theme.spacing(4)};
    width: 100%;
    ${props => props.theme.breakpoints.down('xs')} {
        grid-template-columns: repeat(2, 1fr);
    }
`

const StartIcon = styled(PlayIcon)`
    font-size: 1.5rem;
    color: ${props => props.theme.palette.primary.main};
`

const QuizFinishedIcon = styled(CheckCircleIcon)`
    font-size: 1.5rem;
    color: #2e7d32;
`

const ActionWrap = styled(Inline)`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    font-weight: 600;
    justify-content: flex-end;
    text-align: right;
    color: ${props => props.theme.palette.primary.main};
`

const IconWrap = styled.span`
    > * {
        vertical-align: middle;
    }
`

const TemplateItemRoot = styled(ActionCard)`
    display: block;
    width: 100%;
` as typeof ActionCard

const Content = styled(CardContent)`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing(4)};
    justify-content: space-between;
`

const QuizGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.theme.spacing(4)};
    > img {
        display: block;
        max-width: 100%;
        border-radius: ${props => props.theme.shape.borderRadius}px;
    }
    ${props => props.theme.breakpoints.down('xs')} {
        grid-template-columns: auto;
    }
`

const Banner = styled.div<{ $backgroundImage: string }>`
    position: relative;
    background-image: ${props => `url("${props.$backgroundImage}")`};
    background-size: cover;
    background-position: right;
    padding-left: 3rem;
    min-height: 300px;
    display: flex;
    align-items: center;
    h1 {
        color: #003b64;
        font-size: 3.4rem;
        font-weight: 800;
        line-height: 1.3;
        max-width: 50%;
    }
    ${props => props.theme.breakpoints.down('sm')} {
        background-image: linear-gradient(#082336, #00000000), ${props => `url("${props.$backgroundImage}")`};
        min-height: 200px;
        padding: 2rem 1rem 1rem 1rem;
        justify-content: center;
        align-items: flex-start;
        h1 {
            text-align: center;
            font-size: 2.5rem;
            max-width: none;
            color: #fff;
        }
    }
`

export default CampChallengePage

import React from 'react'
import { createTheme } from 'lib/theme/createTheme'
import { css } from 'styled-components/macro'
import { EasterFooter } from './EasterFooter'

const Thumbnail: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 1920 1080"
            xmlSpace="preserve"
        >
            <style>{'.st5{clip-path:url(#SVGID_00000115504942659658466400000016243759574444086462_)}'}</style>
            <path
                d="M535 0h1385v1080H535V0z"
                style={{
                    fill: '#6bd9e1',
                }}
            />
            <path
                d="M1445 100.8h344.1v85.3H1445v-85.3zm-296.5 0h250.9v85.3h-250.9v-85.3z"
                style={{
                    fill: '#b70c91',
                }}
            />
            <path
                d="M667.6 274.8h344.1v258.5H667.6V274.8zm388.7 0h344.1v258.5h-344.1V274.8zm388.7 0h344.1v258.5H1445V274.8zM667.6 608.3h344.1v258.5H667.6V608.3zm388.7 0h344.1v258.5h-344.1V608.3zm388.7 0h344.1v258.5H1445V608.3z"
                style={{
                    fill: '#384a78',
                }}
            />
            <path
                d="M0 0h535v1080H0V0z"
                style={{
                    fill: '#e4f3ff',
                }}
            />
            <path
                d="M149.5 89.8h228v213.9h-228V89.8zM84.7 424.5h304v43.3h-304v-43.3zm0 97H322v43.3H84.7v-43.3zm0 97h268v43.3h-268v-43.3zm0 97H382v43.3H84.7v-43.3zm0 97H342v43.3H84.7v-43.3z"
                style={{
                    fill: '#5c8aaf',
                }}
            />
            <defs>
                <path id="SVGID_1_" d="M535 812.5h1385V1080H535z" />
            </defs>
            <clipPath id="SVGID_00000163034904263225060590000010433834774941332358_">
                <use
                    xlinkHref="#SVGID_1_"
                    style={{
                        overflow: 'visible',
                    }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000163034904263225060590000010433834774941332358_)',
                }}
            >
                <path
                    d="m407.9 964 221.3-27.5 319.7 68.6 287-34.3 72.5 12.1 132.8 22.2 214.4-58.5 261.5 60.3 9 74-1518.2-3.7V964z"
                    style={{
                        fill: '#79eab9',
                    }}
                />
                <path
                    d="m393.1 927 376 68.5 297-62.8 256 76.1 112-50.4 200 46.6 254-22.8 114-1.8v60.4l-1637 62.2 28-176z"
                    style={{
                        fill: '#1fdb8a',
                    }}
                />
                <path
                    d="m412.3 1035 224-14 729 25 217.6-27.9 344.4-.1 9 89-1545.6-6 21.6-66z"
                    style={{
                        fill: '#1cc67a',
                    }}
                />
            </g>
        </svg>
    )
}

export const easter = createTheme(Thumbnail, {
    palette: {
        background: {
            default: '#f5f5fb',
        },
        primary: {
            main: '#b70c91',
        },
        text: {
            primary: '#314a51',
            disabled: '#a2a2a3',
        },
    },
    overrides: {
        MuiPaper: {
            root: {
                background: 'linear-gradient(45deg, #f7eaff, #cdfdff)',
            },
        },
    },
    extras: {
        themeColorMeta: '#a50050',
        global: {
            body: css`
                background: linear-gradient(hsl(184deg 66% 65%), hsl(183deg 73% 95%));
                background-attachment: fixed;
            `,
        },

        pageHeader: {
            root: css`
                background: linear-gradient(45deg, #cdfdff, #f7eaff);
            `,
        },
        page: {
            footerImage: <EasterFooter />,
        },
        containedButton: color => {
            if (color === 'default') {
                return css`
                    background-color: #c9dade;
                    &:hover,
                    &:focus-visible {
                        background-color: #c1d5da;
                    }
                `
            }
        },
        pageAside: {
            listItemAndNotificationButton: css`
                color: #314a51;
                &.Mui-selected,
                &.Mui-selected:hover {
                    color: #06b1ba;
                    background-color: rgba(7, 220, 213, 0.11);
                }
            `,
        },
    },
})

import { runtimeEnv } from 'utils/env'
import { api } from 'lib/api'

export const getIdeUrl = (pathWithParams: string) => {
    const url = new URL(`${runtimeEnv.REACT_APP_SCOOLCODE_URL}${pathWithParams}`)

    url.searchParams.append('origin', window.location.href);
    if(api.accessToken) url.searchParams.append('access_token', api.accessToken);

    return url.href
}


export const getIdeCreateUrl = (pathWithParams: string) => {
    const url = new URL(`${runtimeEnv.REACT_APP_SCOOLCODE_CREATE_URL || runtimeEnv.REACT_APP_SCOOLCODE_URL}${pathWithParams}`)

    url.searchParams.append('origin', window.location.href);
    if(api.accessToken) url.searchParams.append('access_token', api.accessToken);

    return url.href
}

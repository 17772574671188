import styled from 'styled-components/macro'

export const QuizResultsGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(24rem, 1fr));
    gap: ${props => props.theme.spacing(6)};
    ${props => props.theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
    }
`

import { AnimatePresence, motion, useIsPresent } from 'framer-motion'
import { useFloatingText } from 'lib/floating-text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useRef } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { visuallyHidden } from 'utils/styleUtils'
import { useCookieHunt } from '../context/CookieHuntContext'
import { cookieHuntSpawnPoints } from '../data/cookieHuntData'
import { CookieHuntSpawnPoint, CookieHuntSpawnPointName } from '../types/cookieHuntTypes'
import cookieImg from '../media/cookie.svg'
import easterEggImg from '../media/easter-egg.svg'
import { useSelector } from 'lib/store'
import { isLiveopsReady } from 'lib/liveops'

const imgByType: Record<CookieHuntSpawnPoint['type'], string> = {
    cookie: cookieImg,
    'easter-egg': easterEggImg,
}

const sizeByType: Record<CookieHuntSpawnPoint['type'], string> = {
    cookie: '1.75rem',
    'easter-egg': '1.25rem',
}

const plusCookiesByType: Record<CookieHuntSpawnPoint['type'], number> = {
    cookie: 1,
    'easter-egg': 3,
}

const floatingTextColorByType: Record<CookieHuntSpawnPoint['type'], string> = {
    cookie: '#e3b742',
    'easter-egg': '#fff',
}

interface CookieHuntProps {
    name: CookieHuntSpawnPointName
    index?: number
    disabled?: boolean
}

export const CookieHunt: React.FC<CookieHuntProps> = props => {
    const spawnPoint = cookieHuntSpawnPoints[props.name]
    if (spawnPoint.index !== props.index || props.disabled) {
        return <>{props.children}</>
    }
    return <CookieHuntInner spawnPoint={spawnPoint} {...props} />
}

const CookieHuntInner: React.FC<CookieHuntProps & { spawnPoint: CookieHuntSpawnPoint }> = ({
    children,
    name,
    spawnPoint,
}) => {
    const { isSpawnPointVisible, collect } = useCookieHunt()
    const { showFloatingText } = useFloatingText()
    const buttonRef = useRef<HTMLDivElement>(null)
    const t = useTranslate()
    const isReady = useSelector(isLiveopsReady)

    const collectCookie = () => {
        collect(name)
        if (buttonRef.current) {
            const { x, y } = buttonRef.current.getBoundingClientRect()
            showFloatingText({
                label: t('cookieHunt::plusN', { count: plusCookiesByType[spawnPoint.type] }),
                x,
                y,
                color: floatingTextColorByType[spawnPoint.type],
            })
        }
    }

    return (
        <Root>
            <ChildrenWrap $spawnPoint={spawnPoint}>{children}</ChildrenWrap>
            <AnimatePresence>
                {isReady && isSpawnPointVisible(name) && (
                    <CookieButton ref={buttonRef} spawnPoint={spawnPoint} onClick={collectCookie} />
                )}
            </AnimatePresence>
        </Root>
    )
}

interface CookieButtonProps {
    onClick: VoidFunction
    spawnPoint: CookieHuntSpawnPoint
}

const CookieButton = React.forwardRef<any, CookieButtonProps>(({ onClick, spawnPoint }, ref) => {
    const isPresent = useIsPresent()
    const t = useTranslate()

    return (
        <Button
            ref={ref}
            $spawnPoint={spawnPoint}
            type="button"
            onClick={() => {
                if (isPresent) {
                    onClick()
                }
            }}
        >
            <motion.div animate={{ opacity: 1 }} transition={{ duration: 0.7 }} exit={{ scale: 1.5, opacity: 0 }}>
                <ButtonLabel>{t('cookieHunt::collect')}</ButtonLabel>
                <img src={imgByType[spawnPoint.type]} alt="" />
            </motion.div>
        </Button>
    )
})

const Root = styled.div`
    position: relative;
`

const ChildrenWrap = styled.div<{ $spawnPoint: CookieHuntSpawnPoint }>`
    position: relative;
    z-index: ${props => (props.$spawnPoint.layer === 'back' ? 2 : 1)};
    > * {
        width: 100%;
    }
`

const getButtonPositionStyles = ({ offset, position }: CookieHuntSpawnPoint) => {
    switch (position) {
        case 'top-right':
            return css`
                top: ${offset?.y ?? 0}px;
                right: -${offset?.x ?? 0}px;
            `
        case 'bottom-right':
            return css`
                bottom: ${offset?.y ?? 0}px;
                right: -${offset?.x ?? 0}px;
            `
        case 'bottom-left':
            return css`
                bottom: ${offset?.y ?? 0}px;
                left: ${offset?.x ?? 0}px;
            `
        case 'top-left':
            return css`
                top: ${offset?.y ?? 0}px;
                left: ${offset?.x ?? 0}px;
            `
    }
}

const cookieButtonTransformStyles: Record<CookieHuntSpawnPoint['position'], string> = {
    'top-right': 'translate(40%, -40%)',
    'bottom-right': 'translate(40%, 40%) rotate(90deg)',
    'bottom-left': 'translate(-40%, 40%) rotate(180deg)',
    'top-left': 'translate(-40%, -40%) rotate(270deg)',
}

const wiggle = keyframes`
    2.5% {
        transform: rotate(-10deg) scale(0.95);
        
    }
    5% {
        transform: rotate(25deg) scale(1.1);
        
    }
    7.5% {
        transform: rotate(-10deg) scale(0.95);
        
    }
    10% {
        transform: rotate(25deg) scale(1.1);
        
    }
    12.5% {
        transform: rotate(0) scale(1);
    }
`

const Button = styled.button<{ $spawnPoint: CookieHuntSpawnPoint }>`
    border: none;
    background: none;
    color: #ffb100;
    cursor: pointer;
    padding: 0;
    position: absolute;
    ${props => getButtonPositionStyles(props.$spawnPoint)};
    transform: ${props =>
        props.$spawnPoint.type === 'cookie' ? cookieButtonTransformStyles[props.$spawnPoint.position] : undefined};
    width: ${props => sizeByType[props.$spawnPoint.type]};
    height: ${props => sizeByType[props.$spawnPoint.type]};
    transition: filter ${props => props.theme.transitions.duration.short}ms;
    z-index: ${props => (props.$spawnPoint.layer === 'back' ? 1 : 2)};
    &:hover,
    &:focus-visible {
        filter: drop-shadow(0px 2px 10px #e3b842ea);
    }
    img {
        position: relative;
        animation: ${wiggle} 10s ease-in-out infinite;
        animation-delay: 5s;
        width: 100%;
        height: 100%;
    }
`

const ButtonLabel = styled.span`
    ${visuallyHidden}
`

import React from 'react'
import { Drawer } from '@material-ui/core'
import styled from 'styled-components/macro'
import { Stack } from 'components/layout'
import { customScrollbar } from 'utils/styleUtils'
import {ManagementModalEntry} from "../helpers/managementContext";
import {Text} from "../../../components/Text";
export interface ManagementModalContentProps<TData> {
    modal: ManagementModalEntry<TData>
}

export type ManagementModalContentComponent<TData> = React.ElementType<ManagementModalContentProps<TData>>;

const MANAGEMENT_MODAL_WIDTH_NUMBER = 24
const MANAGEMENT_MODAL_WIDE_WIDTH_NUMBER = 54
const MODALS: Record<string, ManagementModalContentComponent<any>> = {};

export const registerManagementModal: <TData>(kind: string, component: ManagementModalContentComponent<TData>) => void = (kind, component) => {
    MODALS[kind] = MODALS[kind] || component
}

const MissingModalContentComponent: React.FC<ManagementModalContentProps<any>> = ({ modal }) => {
    return (
        <Stack spacing={5} margin={4}>
            <h2>Missing modal content component for {modal.kind}</h2>
            <pre>{JSON.stringify(modal, null, 2)}</pre>
        </Stack>
    )
}

export const ManagementModal: React.FC<{ modal: ManagementModalEntry<any>, level?: number }> = ({ modal, level = 0 }) => {
    const BASE_WIDTH = modal.wide ? MANAGEMENT_MODAL_WIDE_WIDTH_NUMBER : MANAGEMENT_MODAL_WIDTH_NUMBER;
    const ContentComponent = MODALS[modal.kind] || MissingModalContentComponent;

    return (
        <StyledDrawerModal width={BASE_WIDTH - level} variant="permanent" anchor="right" PaperProps={{
            elevation: 8
        }}>
            <Stack spacing={5} margin={4}>
                <ContentComponent modal={modal} />
            </Stack>
        </StyledDrawerModal>
    )
}

const StyledDrawerModal = styled(Drawer)<{ width: number }>`
        width: ${props => props.width}rem;
        & .MuiPaper-root {
            width: ${props => props.width}rem;
            ${customScrollbar}
        }
    `;

export const ManagementModalTitle: React.FC<{ title: string, subTitle?: string }> = ({ title, subTitle }) => {
    return (<>
        <Text variant={"h2"} style={{ textTransform: "capitalize" }}>
            {title}
        </Text>
        {subTitle && <Text variant={"subtitle1"} style={{ marginTop: 2 }}>{subTitle}</Text>}
    </>)
}
import { transparentize, desaturate } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { useAchievementCardContext } from './AchievementCard'

interface AchievementCardImageWrapProps {
    bgColor: string
}

export const AchievementCardImageWrap: React.FC<AchievementCardImageWrapProps> = ({ bgColor, children }) => {
    const { isAcquired } = useAchievementCardContext()

    return (
        <Root $isAcquired={isAcquired} $bgcolor={bgColor}>
            {children}
        </Root>
    )
}

const Root = styled.div<{ $bgcolor: string; $isAcquired: boolean }>`
    height: 10rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: ${props =>
        props.$isAcquired
            ? transparentize(props.theme.palette.type === 'dark' ? 0.8 : 0, props.$bgcolor)
            : desaturate(
                  1,
                  transparentize(props.theme.palette.type === 'dark' ? 0.8 : 0, props.theme.palette.grey[600]),
              )};
    width: 100%;
`

import { createTheme } from '../createTheme'

export const light3 = createTheme(
    { base: '#fafafa', aside: '#fff', primary: '#a50050', asideContent: '#ccc' },
    {
        palette: {
            background: {
                default: '#f5f5fb',
            },
            primary: {
                main: '#a50050',
            },
        },
    },
)

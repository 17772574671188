import { Dialog } from 'components/dialog'
import { useModal } from 'lib/router/modals/modalContext'
import React from 'react'
import { MiniQuizEditorSkeleton } from './MiniQuizEditorSkeleton'

const MiniQuizEditor = React.lazy(() => import('./MiniQuizEditor'))

export const MiniQuizEditorModal = () => {
    const { closeModal } = useModal()

    return (
        <Dialog PaperProps={{ style: { height: '100%' } }} open fullWidth onClose={closeModal} maxWidth="md">
            <React.Suspense fallback={<MiniQuizEditorSkeleton />}>
                <MiniQuizEditor close={closeModal} />
            </React.Suspense>
        </Dialog>
    )
}

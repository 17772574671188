import { CookieHunt } from 'features/cookie-hunt'
import React from 'react'
import styled from 'styled-components/macro'
import footerImg from './easter-footer.webp'

interface EasterFooterProps {}

export const EasterFooter: React.FC<EasterFooterProps> = () => {
    return (
        <Root>
            <EggWrap>
                <CookieHunt name="easter-footer" />
            </EggWrap>
            <Image width="2644" height="1038" src={footerImg} />
        </Root>
    )
}

const EggWrap = styled.div`
    position: absolute;
    top: 77.8%;
    left: 78.3%;
`

const Root = styled.div`
    position: relative;
`

const Image = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
`

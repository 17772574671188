import { Button } from 'components/Button'
import { Stack, WrappingInline } from 'components/layout'
import { useSelector } from 'lib/store'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useProjectModalContext } from '../modal/projectModalContext'
import { giveCookieRequest, Project } from '../store'
import styled from 'styled-components/macro'
import { useTranslate } from 'lib/i18n/useTranslate'
import { AnimationControls, motion, useAnimation } from 'framer-motion'
import { CurrencyName } from 'lib/liveops/reducers/client/currency'
import { currencyStyles } from '../currencyStyles'
import { useHasGrant } from 'lib/grants'
import { darken, lighten } from 'polished'
import { LightDarkColor, pickLightDarkColor } from 'utils/styleUtils'
import { Tooltip } from 'components/Tooltip'

interface CommunityProjectCookiesProps {
    // TODO: remove once we have give-cookie grant
    canBeCookied?: boolean
}

export const CommunityProjectCookies: React.FC<CommunityProjectCookiesProps> = ({ canBeCookied = true }) => {
    const { project, isOwnProject } = useProjectModalContext()
    const hasGrant = useHasGrant()
    const cookieAnimationControl = useAnimation()
    const goldenCookieAnimationControl = useAnimation()
    const hasGiveGoldenCookieGrant = hasGrant('give-golden-cookie')

    if (!project || isOwnProject === undefined) {
        return null
    }

    const isGoldenCookieColored =
        !hasGiveGoldenCookieGrant || Boolean(project.metadata[cookiedFieldByCurrency['golden-cookie']])
    const isCookied = Boolean(project.metadata[cookiedFieldByCurrency.cookie])

    return (
        <Stack>
            <WrappingInline spacing={6}>
                <CookieCount
                    colored={isGoldenCookieColored}
                    animationControl={cookieAnimationControl}
                    currency="golden-cookie"
                    project={project}
                />
                <CookieCount
                    colored={isCookied}
                    animationControl={goldenCookieAnimationControl}
                    currency="cookie"
                    project={project}
                />
            </WrappingInline>
            {!isOwnProject && canBeCookied && (
                <div>
                    <WrappingInline spacing={2}>
                        {hasGiveGoldenCookieGrant && (
                            <CookieButton
                                animationControl={cookieAnimationControl}
                                currency="golden-cookie"
                                project={project}
                            />
                        )}
                        <CookieButton
                            animationControl={goldenCookieAnimationControl}
                            currency="cookie"
                            project={project}
                        />
                    </WrappingInline>
                </div>
            )}
        </Stack>
    )
}

const cookiedFieldByCurrency = {
    cookie: 'cookied',
    'golden-cookie': 'goldenCookied',
} as const

const countFieldByCurrency = {
    cookie: 'cookieCount',
    'golden-cookie': 'goldenCookieCount',
} as const

const tooltipByCurrency = {
    cookie: 'projects::cookies',
    'golden-cookie': 'projects::goldenCookies',
} as const

interface CountAndProps {
    currency: CurrencyName
    project: Project
    animationControl: AnimationControls
    colored: boolean
}

const CookieCount: React.FC<CountAndProps> = ({ currency, project, animationControl, colored }) => {
    const t = useTranslate()
    const styles = currencyStyles[currency]

    return (
        <Tooltip title={t(tooltipByCurrency[currency])}>
            <CookieCountWrap>
                <IconWrap $backgroundColor={colored ? styles.colors.background : undefined}>
                    <CountImage
                        animate={animationControl}
                        variants={{ active: { scale: 1.75 } }}
                        transition={{ repeatType: 'mirror', repeat: 1, duration: 0.25 }}
                        src={styles.icon[colored ? 'colored' : 'solid']}
                    />
                </IconWrap>
                <span>{project.file[countFieldByCurrency[currency]]}</span>
            </CookieCountWrap>
        </Tooltip>
    )
}

interface CountButtonProps {
    currency: CurrencyName
    project: Project
    animationControl: AnimationControls
}

const CookieButton: React.FC<CountButtonProps> = ({ currency, project, animationControl }) => {
    const styles = currencyStyles[currency]
    const userCookieCount = useSelector(state => state.client.currency[currency].value)

    const cookied = !!project.metadata[cookiedFieldByCurrency[currency]]

    const dispatch = useDispatch()
    const t = useTranslate()

    if (userCookieCount === null || cookied) {
        return null
    }

    const labelKeyByCurrency = {
        cookie: 'projects::giveCookie',
        'golden-cookie': 'projects::giveGoldenCookie',
    }

    const outOfCurrencyKeyByCurrency = {
        cookie: 'projects::noMoreCookies',
        'golden-cookie': 'projects::noMoreGoldenCookies',
    }

    const remainingKeyByCurrency = {
        cookie: 'projects::nCookiesRemaining',
        'golden-cookie': 'projects::nGoldenCookiesRemaining',
    }

    const isOutOfCurrency = userCookieCount <= 0

    return (
        <Tooltip
            enterDelay={isOutOfCurrency ? 0 : 1000}
            title={
                isOutOfCurrency
                    ? t(outOfCurrencyKeyByCurrency[currency])
                    : t(remainingKeyByCurrency[currency], { count: userCookieCount })
            }
        >
            <motion.span layout>
                <CookieButtonRoot
                    variant="contained"
                    startIcon={<img src={styles.icon.colored} alt="" />}
                    $color={styles.colors.color}
                    $backgroundColor={styles.colors.background}
                    onClick={() => {
                        dispatch(giveCookieRequest(project.file.id, currency))
                        animationControl.start('active')
                    }}
                    disabled={isOutOfCurrency}
                >
                    {t(labelKeyByCurrency[currency])}
                </CookieButtonRoot>
            </motion.span>
        </Tooltip>
    )
}

const CookieButtonRoot = styled(Button)<{ $color: LightDarkColor; $backgroundColor: LightDarkColor }>`
    background: ${props => pickLightDarkColor(props.theme, props.$backgroundColor)};
    color: ${props => pickLightDarkColor(props.theme, props.$color)};
    box-shadow: none;
    img {
        width: 1em;
        transition: transform ${props => props.theme.transitions.duration.standard}ms;
    }
    &[disabled] {
        filter: grayscale(1);
    }
    &:hover,
    &:focus-visible {
        filter: none;
        box-shadow: none;
        background: ${props =>
            props.theme.palette.type === 'light'
                ? darken(0.08, pickLightDarkColor(props.theme, props.$backgroundColor))
                : lighten(0.08, pickLightDarkColor(props.theme, props.$backgroundColor))};
        img {
            transform: scale(1.2) rotate(15deg);
        }
    }
`

const CountImage = styled(motion.img)`
    width: 100%;
`

const CookieCountWrap = styled.div`
    display: inline-flex;
    align-items: center;
    gap: ${props => props.theme.spacing(2)};
    font-size: ${props => props.theme.typography.body1.fontSize};
    font-weight: 600;
`

const IconWrap = styled.div<{ $backgroundColor?: LightDarkColor }>`
    position: relative;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background-color: ${props =>
        props.$backgroundColor
            ? pickLightDarkColor(props.theme, props.$backgroundColor)
            : props.theme.palette.grey['800']};
    width: 2.3rem;
    height: 2.3rem;
    padding: 0.4em;
    svg {
        width: 100%;
        height: 100%;
    }
`

import { InputAdornment, Option, Select, TextField } from 'components/form'
import { Searchicon } from 'components/icons'
import React, { useEffect, useState } from 'react'
import { DecodedValueMap, SetQuery } from 'use-query-params'
import { MyQuizProjectsFilterState } from './MyMiniQuizzes'
import { useDebounce } from 'use-debounce'
import styled from 'styled-components/macro'
import { Inline } from 'components/layout'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Card, CardContent } from 'components/card'
import { LocalisationKey } from 'lib/i18n'
import { MyMiniQuizzesQuery } from 'features/mini-quizzes/data/MiniQuizApi'

interface MyMiniQuizFiltersProps {
    filters: DecodedValueMap<MyQuizProjectsFilterState>
    setFilters: SetQuery<MyQuizProjectsFilterState>
}

const sorts: MyMiniQuizzesQuery['sort'][] = ['-name', 'name', '-updatedAt', 'updatedAt', '-createdAt', 'createdAt']
const sortLabels: Record<MyMiniQuizzesQuery['sort'], LocalisationKey> = {
    '-createdAt': 'general::sort::recentlyCreated',
    createdAt: 'general::sort::lastCreated',
    '-name': 'general::sort::abcDesc',
    name: 'general::sort::abcAsc',
    '-updatedAt': 'general::sort::recentlyModified',
    updatedAt: 'general::sort::lastModified',
}

export const MyMiniQuizFilters: React.FC<MyMiniQuizFiltersProps> = ({ filters, setFilters }) => {
    const t = useTranslate()

    return (
        <Card elevation={1}>
            <CardContent>
                <Grid>
                    <SearchWrap>
                        <Search filters={filters} setFilters={setFilters} />
                    </SearchWrap>
                    <InnerGrid spacing={3}>
                        {filters.view !== 'deleted' && (
                            <SortSelect
                                value={filters.sort}
                                fullWidth={false}
                                onChange={e => {
                                    setFilters({ sort: e.target.value as MyMiniQuizzesQuery['sort'] }, 'replaceIn')
                                }}
                            >
                                {sorts.map(sort => (
                                    <Option key={sort} value={sort}>
                                        {t(sortLabels[sort])}
                                    </Option>
                                ))}
                            </SortSelect>
                        )}
                    </InnerGrid>
                </Grid>
            </CardContent>
        </Card>
    )
}

const Search: React.FC<Pick<MyMiniQuizFiltersProps, 'filters' | 'setFilters'>> = ({ filters, setFilters }) => {
    const t = useTranslate()
    const [search, setSearch] = useState(filters.search)
    const [debouncedSearch] = useDebounce(search, 500)

    useEffect(() => {
        setSearch(filters.search)
    }, [filters.search])

    useEffect(() => {
        setFilters({ search: debouncedSearch }, 'replaceIn')
    }, [debouncedSearch, setFilters])

    return (
        <TextField
            fullWidth={false}
            placeholder={t('general::search')}
            type="search"
            startAdornment={
                <InputAdornment position="start">
                    <Searchicon />
                </InputAdornment>
            }
            value={search}
            onChange={e => {
                setSearch(e.target.value)
            }}
        />
    )
}

const SortSelect = styled(Select)`
    ${props => props.theme.breakpoints.down('sm')} {
        width: 100%;
    }
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr minmax(max-content, 1fr);
    gap: ${props => props.theme.spacing(6)};
    ${props => props.theme.breakpoints.down('sm')} {
        grid-template-columns: auto;
    }
`

const InnerGrid = styled(Inline)`
    display: flex;
    justify-content: flex-end;
`

const SearchWrap = styled(Inline)`
    display: flex;
    align-items: center;
    > .MuiFormControl-root {
        flex-grow: 1;
    }
`

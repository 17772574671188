import { UserState } from 'contexts/UserContext'
import { Gender } from 'lib/api'
import { Grant } from 'lib/grants'
import { Dayjs } from 'dayjs'

export interface TimelineItemWithDate {
    title: string
    startDate: Dayjs
    endDate: Dayjs
}

interface TimelineWithDate {
    items: TimelineItemWithDate[]
}

interface StateMachineEntry {
    until?: Dayjs
    state: string
    metadata?: any
    title?: string
}

export interface ManagableEventDefinition {
    slug: string
    groupSlug?: string
    ongoing: boolean
    title: string
    closedTitle?: string
    thumbnail: string
    startDate?: string
    template: string
    templateData?: any
    timeline?: TimelineWithDate
    state: StateMachineEntry[]
    enabled?: boolean
    detailsBanner?: string
    filters?: {
        countries?: string[]
        gender?: Gender
        grant?: Grant
    }
    banner?: {
        image: string
        text?: string
        background?: string
    }
    hideBreadCrumb?: boolean

    hasGroupInfo?: boolean
    closedCount?: number
    groupCount?: number
}

export type DynamicManagableEventDefinition = (user: UserState) => ManagableEventDefinition | null

export function isManagableEvent(event: any): event is ManagableEventDefinition {
    return !!(event as ManagableEventDefinition).template
}

export function isManagableNonActivityEvent(event: any): event is ManagableEventDefinition {
    return !!(event as ManagableEventDefinition).template && !event.original
}

export function isManagableActivityEvent(event: any): event is ManagableEventDefinition {
    return !!(event as ManagableEventDefinition).template && event.original
}

import React from 'react'
import styled from 'styled-components/macro'
import { useAchievementCardContext } from './AchievementCard'

interface AchievementCardImageProps {
    src: string
}

export const AchievementCardImage: React.FC<AchievementCardImageProps> = ({ src }) => {
    const { isAcquired } = useAchievementCardContext()

    return <Img $isAcquired={isAcquired} src={src} />
}

const Img = styled.img<{ $isAcquired: boolean }>`
    height: 6rem;
    width: 6rem;
    filter: ${props =>
        props.$isAcquired
            ? 'drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5))'
            : `brightness(0) invert(${props.theme.palette.type === 'dark' ? 0.5 : 0.2})`};
    transition: all 0.1s;
`

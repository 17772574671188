import { canUserCreateShareableProject, UserState, canUserMoveToProduction } from 'contexts/UserContext'
import { getMiniQuizAccessLevel } from 'features/mini-quizzes/helpers/miniQuizHelpers'
import { api } from 'lib/api'
import { getIdeUrl } from 'lib/api/helpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import { FieldError, ValidationRules } from 'react-hook-form'
import { MyProjectsQuery, SHAREABLE_TRAINERCONTEST_PROJECT_TAG } from './ProjectsApi'
import { Project, ProjectLabel } from './store'

export type ProjectVariant = 'normal' | 'staging' | 'production'

const thumbnailCacheBusts = new Map()

export function cacheBustThumbnail(id: string) {
    thumbnailCacheBusts.set(id, Math.random())
}

export function getProjectEditLink(
    project: Project,
    user: UserState,
): { type: 'internal'; to: string } | { type: 'external'; href: string } {
    const isOwn = project.file.owner === user.account.id

    if (project.metadata.isMiniQuiz) {
        return {
            type: 'internal',
            to: `/app/quiz-editor/${project.file.id}`,
        }
    } else {
        return {
            type: 'external',
            href: getIdeUrl(`/ide/${project.file.id}${isOwn ? '' : '?variant=production'}`),
        }
    }
}

export function createProjectThumbnailUrl(id: string, variant: ProjectVariant) {
    let url = `${api.baseUrl}/files/${id}/thumbnail`

    if (variant !== 'normal') {
        url = url + `.${variant}`
    }

    if (thumbnailCacheBusts.has(id)) {
        url = url + `?cachebust=${thumbnailCacheBusts.get(id)}`
    }

    return url
}

export const MIN_PROJECT_NAME_LENGTH = 3
export const MAX_PROJECT_NAME_LENGTH = 32
export const PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR = 'notUnique'
export const projectNameRules: ValidationRules = {
    required: true,
    minLength: MIN_PROJECT_NAME_LENGTH,
    maxLength: MAX_PROJECT_NAME_LENGTH,
}

export const PROJECT_TITLE_MAX_LENGTH = 32
export const PROJECT_DETAILS_MAX_LENGTH = 3000

export function getProjectNameValidationMessage(
    fieldError: FieldError | undefined,
    t: ReturnType<typeof useTranslate>,
) {
    if (fieldError?.type === 'minLength' || fieldError?.type === 'required') {
        return t('projects::nameTooShort', { length: MIN_PROJECT_NAME_LENGTH })
    }
    if (fieldError?.type === 'maxLength') {
        return t('projects::nameTooLong', { length: MAX_PROJECT_NAME_LENGTH })
    }
    if (fieldError?.type === PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR) {
        return t('projects::nameNotUnique')
    }

    return undefined
}

type ProjectActionType =
    | 'rename'
    | 'duplicate'
    | 'delete'
    | 'share'
    | 'report-incorrect-tag'
    | 'unshare'
    | 'share-new-version'
    | 'edit'
    | 'enable-on-mobile'
    | 'move-to-production'
    | 'unshare-nominated'
    | 'nominate'
    | 'share-new-version-contest'
    | 'edit-details'

export function getProjectActions(
    project: Project,
    isPending: boolean | undefined,
    user: UserState,
): Set<ProjectActionType> | null {
    const actions = new Set<ProjectActionType>(['edit', 'duplicate'])
    const canShareProject = canUserCreateShareableProject(user)
    const canMoveToProduction = canUserMoveToProduction(user)
    const miniQuizAccessLevel = getMiniQuizAccessLevel(user)

    if (project.file.shareSpecial) {
        actions.add('unshare-nominated')
    }

    if (project.file.shareSpecial && doesContestProjectHaveNewVersion(project)) {
        actions.add('share-new-version-contest')
    }

    if (project.metadata.shareable) {
        if (isPending === undefined) {
            return null
        }

        if (!project.file.shared && !project.file.deleted && !isPending) {
            actions.add('delete')
            actions.add('rename')
            actions.add('edit-details')
        }
        if (project.metadata.isMiniQuiz ? miniQuizAccessLevel === 'full' : canShareProject) {
            const hasNewVersion = doesProjectHaveNewVersion(project)

            if (!project.file.shared && !isPending && !project.file.shareSpecial) {
                if (canMoveToProduction) {
                    actions.add('move-to-production')
                }
                actions.add('share')
            }
            if (hasNewVersion && !isPending) {
                actions.add('share-new-version')
            }
            if (project.file.shared || isPending) {
                actions.add('unshare')
            }
        }
    } else {
        !project.file.shareSpecial && actions.add('rename')
        if (canMoveToProduction && !project.file.tags?.includes(SHAREABLE_TRAINERCONTEST_PROJECT_TAG)) {
            actions.add('move-to-production')
        }
        if (!project.file.deleted && !project.file.shareSpecial) {
            actions.add('delete')
            if (canShareProject) {
                actions.add('report-incorrect-tag')
            }
        }
    }

    return actions
}

export function doesProjectHaveNewVersion(project: Project) {
    return !!(
        project.file.shared &&
        project.file.updatedAt &&
        project.file.approvedAt &&
        project.file.updatedAt > project.file.approvedAt
    )
}

export function doesContestProjectHaveNewVersion(project: Project) {
    return !!(project.file.updatedAt && project.file.approvedAt && project.file.updatedAt > project.file.approvedAt)
}

export function getRemainingShareSlotCount(user: UserState, sharedProjects: Project[], pendingProjects: Project[]) {
    const projects = [...sharedProjects, ...pendingProjects]
    const normalProjectIds = projects.filter(project => !project.metadata.isMiniQuiz).map(project => project.file.id)
    const minQuizIds = projects.filter(project => project.metadata.isMiniQuiz).map(project => project.file.id)

    const normalLength = Array.from(new Set(normalProjectIds)).length
    const miniQuizLength = Array.from(new Set(minQuizIds)).length

    return {
        normal: Math.max((user.account.sharedProjectLimit ?? 0) - normalLength, 0),
        miniQuiz: Math.max((user.account.sharedMiniQuizLimit ?? 0) - miniQuizLength, 0),
    }
}

export const getDefaultProjectListSort = (settings: UserState['settings']): MyProjectsQuery['sort'] => {
    const validValues = new Set<MyProjectsQuery['sort']>([
        '-createdAt',
        'createdAt',
        '-name',
        'name',
        '-updatedAt',
        'updatedAt',
    ])
    const settingValue = settings['mylogiscool-project-list-sort']
    if (settingValue && validValues.has(settingValue as MyProjectsQuery['sort'])) {
        return settingValue as MyProjectsQuery['sort']
    }
    return '-updatedAt'
}

export const deserializeProjectLabel = (label: ProjectLabel) => label.value
export const serializeProjectLabel = (label: string) => ({ kind: 'custom', value: label } as const)
export const isCustomProjectLabel = ({ kind }: ProjectLabel) => kind === 'custom'

import { createTheme } from '../createTheme'

export const light2 = createTheme(
    { base: '#fafafa', aside: '#fff', primary: '#388e3c', asideContent: '#ccc' },
    {
        palette: {
            background: {
                default: '#f5f5fb',
            },
            primary: {
                main: '#388e3c',
            },
        },
    },
)

import { AnyAction, Reducer } from 'redux'
import { produce } from 'immer'
import { PRESENT } from 'redux-socket-client'
import { ACHIEVEMENTS, ACHIEVEMENT_CHANGED } from 'lib/liveops/constants'

export type AchievementKind = 'sticker' | 'tiered'

export interface AchievementWithMetadata {
    achievement: {
        id: string
        kind: AchievementKind
        name: string
        description: string
        image: string
        group: string
        color?: string
        unlock: {
            group?: string
            stat?: string
        }
    }
    metadata: {
        level?: number
        requiredForCurrentLevel?: number
        requiredForNextLevel?: number
        unlocked?: boolean
    }
}

export type AchievementsState = AchievementWithMetadata[] | null

export const achievementsReducer: Reducer<AchievementsState, AnyAction> = (state = null, action) => {
    switch (action.type) {
        case ACHIEVEMENTS:
            return action.payload.achievements

        case ACHIEVEMENT_CHANGED:
            return produce(state, draft => {
                if (draft) {
                    const achievement = draft.find(item => item.achievement.id === action.payload.achievementId)
                    if (achievement) {
                        achievement.metadata = action.payload.metadata
                    }
                }
            })

        case PRESENT:
            return null

        default:
            return state
    }
}

import React from 'react'
import styled from 'styled-components/macro'
import { opacify } from 'polished'
import { useMediaQuery, Theme } from '@material-ui/core'
import { MOBILE_MENU_BREAKPOINT } from 'components/page/Page'

export const BirthdayGerland: React.FC = () => {
    const isMobileMenu = useMediaQuery<Theme>(theme => theme.breakpoints.down(MOBILE_MENU_BREAKPOINT), { noSsr: true })

    return isMobileMenu ? <MobileGerland /> : <DesktopGerland />
}

const DesktopGerland: React.FC = () => {
    return (
        <Root>
            <svg
                id="R\xE9teg_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 5442.5 250"
                xmlSpace="preserve"
            >
                <path d="M0,0c0,0,1554,81.7,2721.3,81.7S5442.5,0,5442.5,0" />
                <polygon points="162.4,101.7 220.6,12 113.7,6.4 " />
                <polygon points="407.5,113.1 464.9,22.8 358,18.3 " />
                <polygon points="652.2,124.5 709.4,34.1 602.5,29.8 " />
                <polygon points="896.9,133.9 953.9,43.3 847,39.3 " />
                <polygon points="1141.7,143.3 1198.4,52.5 1091.4,48.8 " />
                <polygon points="1386.3,150.7 1442.9,59.9 1336,56.3 " />
                <polygon points="1631.4,158.1 1687.2,66.8 1580.2,64.1 " />
                <polygon points="1876.4,164.5 1931.6,72.8 1824.6,70.8 " />
                <polygon points="2121,169.9 2176.1,78.2 2069.2,76.2 " />
                <polygon points="2365.5,173.2 2420.7,81.6 2313.7,79.6 " />
                <polygon points="2611.2,175.6 2664.7,83 2557.7,83 " />
                <polygon points="2831.1,176 2777.6,83.3 2884.6,83.3 " />
                <polygon points="3076.6,172.6 3021.7,80.7 3128.7,79.1 " />
                <polygon points="3322,169.2 3265.9,78 3372.9,75.1 " />
                <polygon points="3566.6,163.8 3510.5,72.7 3617.4,69.7 " />
                <polygon points="3811.6,157.4 3754.8,66.7 3861.8,62.9 " />
                <polygon points="4057.2,150 3998.9,60.2 4105.8,54.7 " />
                <polygon points="4301.7,141.6 4243.5,51.8 4350.4,46.3 " />
                <polygon points="4546.3,133.2 4488.1,43.4 4594.9,37.9 " />
                <polygon points="4790.9,123.8 4732.6,34.1 4839.5,28.5 " />
                <polygon points="5035.4,113.4 4977.2,23.7 5084.1,18.1 " />
                <polygon points="5280,102.1 5221.8,12.3 5328.6,6.7 " />
            </svg>
        </Root>
    )
}

const MobileGerland: React.FC = () => {
    return (
        <Root>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 2267.7 283.5"
                xmlSpace="preserve"
            >
                <path d="M0,0c0,0,934.4,66.9,1133.9,66.9S2267.7,0,2267.7,0" />
                <polygon points="110.5,102 168.8,12.3 61.9,6.7 " />
                <polygon points="396.5,122.4 454.7,32.6 347.8,27 " />
                <polygon points="682.4,140.7 740.7,50.9 633.8,45.3 " />
                <polygon points="968.3,156 1026.6,66.2 919.7,60.6 " />
                <polygon points="2113.9,102.7 2055.7,12.9 2162.5,7.3 " />
                <polygon points="1828,123 1769.7,33.2 1876.6,27.6 " />
                <polygon points="1542,141.3 1483.8,51.5 1590.7,46 " />
                <polygon points="1256.1,156.6 1197.8,66.8 1304.7,61.3 " />
            </svg>
        </Root>
    )
}

const Root = styled.div`
    position: absolute;
    top: -0.9rem;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
    z-index: ${props => props.theme.zIndex.drawer + 1};
    pointer-events: none;
    fill: #009cdf;
    path {
        fill: none;
        stroke: ${props => opacify(0.1, props.theme.palette.divider)};
        stroke-width: 3;
        stroke-miterlimit: 10;
    }
    polygon {
        filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.35));
    }
    polygon:nth-of-type(2n) {
        fill: #e94e1b;
    }
    polygon:nth-of-type(3n) {
        fill: #e5007d;
    }
`

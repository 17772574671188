import { Box } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'
import { BoxProps } from './'

interface InlineProps extends BoxProps {
    spacing?: number
}

export const Inline: React.FC<InlineProps> = ({ spacing = 4, ...rest }) => {
    return <Root spacing={spacing} {...rest} />
}

const Root = styled(Box)<{ spacing: number }>`
    > * + * {
        margin-inline-start: ${props => props.theme.spacing(props.spacing)};
    }
`

import { IconButton } from 'components/IconButton'
import { FavoriteIcon, FavoriteOutlineIcon } from 'components/icons'
import { Tooltip } from 'components/Tooltip'
import React from 'react'
import { Project } from '../store'
import { useProjectActions } from '../useProjectActions'
import styled from 'styled-components/macro'
import { ProjectCardBadge } from './ProjectCardBadge'
import { useUserState } from 'contexts/UserContext'
import { useTranslate } from 'lib/i18n/useTranslate'

interface ProjectCardFavoriteBadgeProps {
    project: Project
    inList?: boolean
}

export const ProjectCardFavoriteBadge: React.FC<ProjectCardFavoriteBadgeProps> = ({ project, inList = false }) => {
    const { toggleProjectFavorite } = useProjectActions()
    const { account } = useUserState()
    const t = useTranslate()

    const isFavorited = project.file.owner === account.id ? !!project.file.favorite : !!project.metadata.favorited

    const body = (
        <Tooltip title={t('projects::favorite')}>
            <ProjectFavoriteButtonRoot $isFavorited={isFavorited} inList={inList}>
                {isFavorited ? (
                    <FavoriteIcon data-testid="favoritedIcon" />
                ) : (
                    <FavoriteOutlineIcon data-testid="favoriteIcon" />
                )}
            </ProjectFavoriteButtonRoot>
        </Tooltip>
    )

    if (inList) {
        return <Wrap onClick={() => toggleProjectFavorite(project)}>{body}</Wrap>
    }

    return (
        <ProjectCardBadge position="top-left" component={Wrap} onClick={() => toggleProjectFavorite(project)}>
            {body}
        </ProjectCardBadge>
    )
}

const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`

const ProjectFavoriteButtonRoot = styled(IconButton)<{ $isFavorited: boolean; inList: boolean }>`
    width: 100%;
    padding: 0;
    vertical-align: text-top;
    color: ${props => {
        if (props.inList) {
            return props.$isFavorited ? props.theme.palette.primary.main : props.theme.palette.text.hint
        }
        return props.$isFavorited ? props.theme.palette.primary.main : 'rgba(160, 160, 160, 0.35)'
    }};
    &:hover {
        background: none;
    }
`

import React from 'react'
import { CheckCircleIcon, TimesCircleIcon, MinusCircleIcon } from 'components/icons'
import { StyledCardContainer, StyledCard, StyledCardContent } from './ChoiceResponse'
import { Text } from 'components/Text'

type Props = {
    correctValue: number | undefined
    response: number | undefined
}

export const NumberResponse: React.FC<Props> = ({ correctValue, response }) => {
    const isCorrect = response === correctValue

    return (
        <StyledCardContainer>
            {isCorrect && (
                <StyledCard $selectedCorrectAnswer={isCorrect} $isIncorrect={!isCorrect}>
                    <StyledCardContent>
                        <Text variant="body1">{response}</Text>
                        {isCorrect ? <CheckCircleIcon /> : <TimesCircleIcon />}
                    </StyledCardContent>
                </StyledCard>
            )}
            {!isCorrect && (
                <StyledCard $selectedCorrectAnswer={!isCorrect} $isIncorrect={isCorrect}>
                    <StyledCardContent>
                        <Text variant="body1">{correctValue}</Text>
                        <MinusCircleIcon />
                    </StyledCardContent>
                </StyledCard>
            )}
        </StyledCardContainer>
    )
}

import { CssBaseline } from '@material-ui/core'
import { App } from 'App'
import { FullScreenLoader } from 'components/FullScreenLoader'
import { TopLevelErrorBoundary } from 'components/TopLevelErrorBoundary'
import { GlobalStyles } from 'GlobalStyles'
import { initDayJs } from 'lib/dayjs'
import { GrantDevToolDialog } from 'lib/grants/grant-dev-tool/GrantDevToolDialog'
import React from 'react'
import ReactDOM from 'react-dom'
import { Providers } from './Providers'
import { initGoogleTagManager } from 'utils/gtm'
//import * as Sentry from '@sentry/react'
//import { isProd } from 'utils/env'
import { ThemeGlobalComponent } from 'lib/theme'
import { RecordingDevToolDialog } from 'lib/recording/RecordingDevToolDialog'

initDayJs()
initGoogleTagManager()

/*
if (isProd) {
    Sentry.init({
        dsn: 'https://018ffa97a5244cd39f67453187e92a66@o228284.ingest.sentry.io/5695218',
    })
}
*/

ReactDOM.render(
    <React.StrictMode>
        <TopLevelErrorBoundary>
            <Providers>
                <React.Suspense fallback={<FullScreenLoader />}>
                    <GrantDevToolDialog />
                    <RecordingDevToolDialog />
                    <CssBaseline />
                    <GlobalStyles />
                    <App />
                </React.Suspense>
                <ThemeGlobalComponent />
            </Providers>
        </TopLevelErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root'),
)

import { Dialog, DialogContent, DialogLoader, DialogTitle } from 'components/dialog'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import { useModal } from 'lib/router/modals/modalContext'
import React from 'react'
import { useWallpapersQuery } from '../data/wallpaperQueries'
import { WallpaperList } from './WallpaperList'

export const WallpapersDialog: React.FC = () => {
    const { data: wallpapers } = useWallpapersQuery()
    const t = useTranslate()
    const { closeModal } = useModal()

    return (
        <Dialog open onClose={closeModal} maxWidth="lg" fullWidth fullHeight>
            <DialogTitle>{t('newbieMissionCourse::backgroundimages::title')}</DialogTitle>
            <DialogContent>
                {wallpapers ? (
                    <Stack spacing={8}>
                        <Text>{t('newbieMissionCourse::backgroundimages::description')}</Text>
                        <Stack spacing={8}>
                            <WallpaperList
                                title={t('newbieMissionCourse::backgroundimages::Desktopwallpapers')}
                                wallpapers={wallpapers.desktop}
                                imageWidth={1920}
                                imageHeight={1080}
                            />
                            <WallpaperList
                                title={t('newbieMissionCourse::backgroundimages::Mobilewallpapers')}
                                wallpapers={wallpapers.mobile}
                                imageWidth={415}
                                imageHeight={897}
                            />
                        </Stack>
                    </Stack>
                ) : (
                    <DialogLoader />
                )}
            </DialogContent>
        </Dialog>
    )
}

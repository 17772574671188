import { motion, Variants } from 'framer-motion'
import React, { createContext, useContext, useCallback, useState } from 'react'
import styled from 'styled-components/macro'

interface FloatingTextState {
    showFloatingText: (data: { label: string; x: number; y: number; color: string }) => void
}

interface FloatingText {
    id: string
    label: string
    color: string
    x: number
    y: number
}

const FloatingTextContext = createContext<FloatingTextState | undefined>(undefined)

export const FloatingTextProvider: React.FC = ({ children }) => {
    const [texts, setTexts] = useState<FloatingText[]>([])

    const showFloatingText: FloatingTextState['showFloatingText'] = useCallback(({ label, x, y, color }) => {
        setTexts(texts => [...texts, { id: Math.random().toString(16).slice(2), label, x, y, color }])
    }, [])

    return (
        <FloatingTextContext.Provider value={{ showFloatingText }}>
            {children}
            {texts.map((text, i) => (
                <Text
                    key={text.id}
                    style={{
                        left: text.x,
                        top: text.y,
                    }}
                    $color={text.color}
                    variants={animationVariants}
                    initial="start"
                    animate="end"
                    transition={{ default: { duration: 1.25 }, opacity: { duration: 1.05, delay: 0.2 } }}
                    onAnimationComplete={() => {
                        setTexts(texts => texts.filter(({ id }) => text.id !== id))
                    }}
                >
                    {text.label}
                </Text>
            ))}
        </FloatingTextContext.Provider>
    )
}

const animationVariants: Variants = {
    start: { opacity: 0, y: 5 },
    end: { opacity: [1, 0], y: -45 },
}

const Text = styled(motion.div)<{ $color: string }>`
    position: fixed;
    z-index: 1000000;
    font-size: 1rem;
    font-weight: 600;
    color: ${props => props.$color};
`

export const useFloatingText = () => {
    const context = useContext(FloatingTextContext)

    if (context === undefined) {
        throw new Error(`'useFloatingText' must be used within 'FloatingTextProvider'`)
    }

    return context
}

import { IconButton, Paper } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components/macro'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Text } from 'components/Text'
import { useSelector } from 'lib/store'
import { Notification } from 'lib/liveops/models/notification'
import { Loader } from 'components/Loader'
import { ErrorBoundary } from 'components/ErrorBoundary'
import { NotificationBlock } from './NotificationBlock'
import { Popover } from 'components/Popover'
import { MOBILE_MENU_BREAKPOINT } from 'components/page/Page'
import { CloseIcon } from 'components/icons'
import { customScrollbar } from 'utils/styleUtils'
import { CookieHunt } from 'features/cookie-hunt'

type NotificationPanelProps = {
    isOpen: boolean
    refObject: React.RefObject<HTMLElement>
    close: VoidFunction
}

export const NotificationPanel: React.FC<NotificationPanelProps> = ({ refObject, close, isOpen }) => {
    return (
        <Popover
            open={isOpen}
            onClose={close}
            anchorEl={refObject.current}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <StyledNotiWrapper>
                <NotiPopoverContent close={close} />
            </StyledNotiWrapper>
        </Popover>
    )
}

const NotiPopoverContent: React.FC<{ close: VoidFunction }> = ({ close }) => {
    const notificationsById = useSelector(state => state.client.notifications.byId)
    const notifications = useSelector(state => state.client.notifications.allIds)
    const visibleNotifications = notifications?.filter(notification => {
        return !isUnlistedNotification(notificationsById[notification])
    })
    const t = useTranslate()

    return (
        <StyledNotiContent>
            <StyledNotiHeader>
                <Text variant="h3">{t('notification::label')}</Text>
                <CloseButton onClick={close} size="small">
                    <CloseIcon />
                </CloseButton>
            </StyledNotiHeader>
            {!visibleNotifications && <StyledLoader />}
            {visibleNotifications?.length === 0 ? (
                <StyledNoNotiText variant="body1">{t('notification::noNotification')}</StyledNoNotiText>
            ) : null}
            <OverflowWrap>
                {visibleNotifications &&
                    visibleNotifications.length > 0 &&
                    visibleNotifications.map((id, i) => {
                        const notification = notificationsById[id]
                        return (
                            <ErrorBoundary key={i} silent>
                                <CookieHunt name="notification-list" index={i}>
                                    <NotificationBlock
                                        data={notification}
                                        isRead={notification.read}
                                        closePanel={close}
                                    />
                                </CookieHunt>
                            </ErrorBoundary>
                        )
                    })}
            </OverflowWrap>
        </StyledNotiContent>
    )
}

const StyledLoader = styled(Loader)`
    margin: ${props => props.theme.spacing(2)} auto;
`

const CloseButton = styled(IconButton)`
    display: none;
    ${props => props.theme.breakpoints.down(MOBILE_MENU_BREAKPOINT)} {
        display: block;
    }
`

const StyledNoNotiText = styled(Text)`
    padding: ${props => props.theme.spacing(4)};
`

const StyledNotiHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.theme.spacing(4)};
    border-bottom: solid 1px ${props => props.theme.palette.divider};
`

const StyledNotiWrapper = styled.div`
    width: 25rem;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    box-shadow: ${props => props.theme.shadows[3]};
    overflow: hidden;
    z-index: 1;
    ${props => props.theme.breakpoints.down(MOBILE_MENU_BREAKPOINT)} {
        width: auto;
    }
`

const OverflowWrap = styled.div`
    overflow-y: auto;

    ${customScrollbar}
`

const StyledNotiContent = styled(Paper)`
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 150px);
`

function isUnlistedNotification(notification: Notification) {
    return notification.message.data.display === 'one-off' || notification.message.data.display === 'unlisted-normal'
}

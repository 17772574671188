import { Box } from 'components/layout'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Project, ProjectCardBadge } from '..'
import { useBlockGalleriesQuery } from '../projectQueries'
import styled from 'styled-components/macro'
import { motion } from 'framer-motion'
import { useHasGrant } from 'lib/grants'
import { ProjectListLayout } from './MyProjects'
import {ProjectLanguage, PYTHON_PALETTE_ID} from "../createProjectFormData";
import {MixIcon} from "../../../components/icons";

interface MyProjectLanguageProps {
    project: Project
    blockGalleriesQuery?: ReturnType<typeof useBlockGalleriesQuery>
    layout: ProjectListLayout
}

export const LANGUAGE_TO_LABEL: Record<ProjectLanguage, string> = {
    'python': 'Python',
    'stagescript': 'StageScript'
}

export const MyProjectLanguage: React.FC<MyProjectLanguageProps> = ({
    project,
    layout,
}) => {
    const t = useTranslate()

    const hasGrant = useHasGrant();
    const isEnabled =
        project.file.kind.includes('mix') &&
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-python' }) &&
        hasGrant({ product: 'edup.scoolcode.ide', feature: 'language-stagescript' });

    if (!isEnabled) {
        return null
    }

    const language = LANGUAGE_TO_LABEL[
        project.file.metadata?.language
        || (project.file.metadata?.palette === PYTHON_PALETTE_ID ? 'python' : 'stagescript')
    ];

    return (
        <>
            {layout === 'grid' ? (
                <BadgeWrapper initial="hidden" whileHover="hover" animate="hidden">
                    <ProjectCardBadge position="bottom-left">
                        <Container>
                            <MixIcon />
                            <LanguageText variants={textMotion}>{language}</LanguageText>
                        </Container>
                    </ProjectCardBadge>
                </BadgeWrapper>
            ) : (
                <StripeRoot>
                    <Text color="textSecondary" variant="caption">
                        {t('projects::language::label')}
                    </Text>
                    <Text variant="body2">{language}</Text>
                </StripeRoot>
            )}
        </>
    )
}

const BadgeWrapper = styled(motion.div)`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
`

const textMotion = {
    hidden: {
        opacity: 0,
        transform: 'translateX(-10px)',
        width: 0,
        marginInlineStart: '0px',
    },
    hover: {
        opacity: 1,
        transform: 'translateX(0px)',
        width: 'auto',
        marginInlineStart: '8px',
    },
}

const LanguageText = styled(motion.span)`
    transform-origin: left;
`

const Container = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const StripeRoot = styled(Box)`
    white-space: pre;
    ${props => props.theme.breakpoints.down('xs')} {
        display: none;
    }
`

import profillerImg from '../media/badges/profiller.svg'
import projectExplorerImg from '../media/badges/projectExplorer.svg'
import followCollectorImg from '../media/badges/follower.svg'
import cookieContributorImg from '../media/badges/cookieContributor.svg'
import trainerFollowerImg from '../media/badges/trainerFollower.svg'
import cookieHunterImg from '../media/badges/cookieHunter.svg'
import questExplorerImg from '../media/badges/questExplorer.svg'
import miniQuestExplorerImg from '../media/badges/miniQuestExplorer.svg'
import highScoreExplorerImg from '../media/badges/highScoreHero.svg'
import quizExplorerImg from '../media/badges/quizExplorer.svg'
import { StatisticName } from 'lib/liveops'
import { UserState } from 'contexts/UserContext'
import { runtimeEnv } from 'utils/env'
import { InventoryState } from 'lib/liveops/reducers/client/inventory'
import { hasGrant } from 'lib/grants'
import { LinkProps } from 'react-router-dom'
import { avatarBrowserDialogTo } from 'features/avatar'
import { getAppVariant } from 'lib/app-variant'

export interface OnboardingAchievementDefinition {
    title: string
    description: string
    tooltip?: string
    imageUrl: string
    catalogItem: string
    color: string
    isLocked?: (user: UserState) => boolean
    progress?: {
        statName: StatisticName
        required: number
    }
    linkTo?: LinkProps['to']
}

export const onboardingAchievements: Array<OnboardingAchievementDefinition> = [
    {
        title: 'onboardingMission::achivements::ProFiller::name',
        description: 'onboardingMission::achivements::ProFiller::description01',
        catalogItem: '6123a2314b2cb6d8dc516654',
        imageUrl: profillerImg,
        color: '#c9efff',
        linkTo: avatarBrowserDialogTo,
    },
    {
        title: 'onboardingMission::achievements::QuizExplorer::title',
        description: 'onboardingMission::achievements::QuizExplorer::description01',
        imageUrl: quizExplorerImg,
        catalogItem: '6123a2adf409cf76226418ad',
        color: '#d6f5ed',
        linkTo: '/app/challenges/quizzes',
    },
    {
        title: 'onboardingMission::achievements::HighscoreExplorer::name',
        description: 'onboardingMission::achievements::HighscoreExplorer::description',
        imageUrl: highScoreExplorerImg,
        catalogItem: '6123a2d5f409cf69866418f7',
        color: '#fff9db',
        progress: {
            statName: '@SYSTEM:high-score-game-participation',
            required: 2,
        },
        linkTo: '/app/challenges/high-score',
    },
    {
        title: 'onboardingMission::achievements::QuestExplorer::name',
        description: 'onboardingMission::achievements::QuestExplorer::description',
        imageUrl: questExplorerImg,
        catalogItem: '61f023d200b8a94c018f2c21',
        color: '#d6f5ea',
        linkTo: '/app/challenges/coding-challenges',
    },
    {
        title: 'onboardingMission::achievements::MiniQuestExplorer::name',
        description: 'onboardingMission::achievements::MiniQuestExplorer::description',
        imageUrl: miniQuestExplorerImg,
        catalogItem: '6123a2bdf409cf05ec6418c9',
        color: '#f8e3c1',
        progress: {
            statName: '@SYSTEM:completed-mini-challenges',
            required: 2,
        },
        linkTo: '/app/challenges/mini-quests',
    },
    {
        title: 'onboardingMission::achievements::CookieHunter::name',
        description: 'onboardingMission::achievement::CookieHunter::description',
        imageUrl: cookieHunterImg,
        catalogItem: '6123a2e2cb9d56e691a795c9',
        color: '#fff9db',
        progress: {
            statName: '@SYSTEM:cookie-treasures-collected',
            required: 2,
        },
    },
    {
        title: 'onboardingMission::achivements::ProjectExplorer::name',
        description: 'onboardingMission::achievements::ProjectExplorer::description',
        imageUrl: projectExplorerImg,
        catalogItem: '6123a264cb9d5651bea794e8',
        color: '#dbefff',
        isLocked: user =>
            !(getAppVariant(user) === 'normal' || hasGrant(user.grants)('limited-app.view-community-projects')),
        progress: {
            statName: '@SYSTEM:view-given',
            required: 2,
        },
        linkTo: '/app/community/discover',
    },
    {
        title: 'onboardingMission::achivements::CookieContributor::name',
        description: 'onboardingMission::achivements::CookieContributor::description',
        imageUrl: cookieContributorImg,
        catalogItem: '6123a279f409cf7a66641851',
        color: '#eedcd7',
        isLocked: user =>
            !(getAppVariant(user) === 'normal' || hasGrant(user.grants)('limited-app.view-community-projects')),
        progress: {
            statName: '@SYSTEM/TotalCurrency:cookie-given',
            required: 2,
        },
        linkTo: '/app/community/discover',
    },
    {
        title: 'onboardingMission::achievements::Follower::title',
        description: 'onboardingMission::achievements::Follower::description',
        imageUrl: followCollectorImg,
        catalogItem: '6123a292f409cf93b6641878',
        color: '#e1f1d8',
        isLocked: ({ grants }) => !hasGrant(grants)('follow-users'),
        progress: {
            statName: '@SYSTEM:followings',
            required: 2,
        },
        linkTo: '/app/community/discover',
    },
    {
        title: 'onboardingMission::achievement::TrainerFollower::name',
        description: 'onboardingMission::achievements::TrainerFan::description',
        imageUrl: trainerFollowerImg,
        catalogItem: '6123a29ea3bcb760ad027436',
        color: '#a6c9ea',
        isLocked: ({ grants }) => !hasGrant(grants)('follow-users'),
        progress: {
            statName: '@SYSTEM:trainer-followings',
            required: 2,
        },
        linkTo: '/app/community/discover?view=only-trainers',
    },
]

export const ONBOARDING_REACHED_3_POINTS_CATALOG_ITEM = '61f00e13bc74a8c345db1601'

export interface OnboardingAchievementData extends Omit<OnboardingAchievementDefinition, 'isLocked'> {
    isLocked: boolean
}

export const getOnboardingAchievements = (user: UserState, inventory: InventoryState): OnboardingAchievementData[] => {
    return onboardingAchievements
        .map(achievement => {
            if (achievement.isLocked) {
                if (inventory?.find(item => item.item === achievement.catalogItem)) {
                    return {
                        ...achievement,
                        isLocked: false,
                    }
                }
                return {
                    ...achievement,
                    isLocked: achievement.isLocked(user),
                }
            }
            return {
                ...achievement,
                isLocked: false,
            }
        })
        .sort((a, b) => (a.isLocked === b.isLocked ? 0 : a.isLocked ? 1 : -1))
}

export const ONBOARDING_ENABLED = runtimeEnv.REACT_APP_ONBOARDING_ENABLED === 'true'

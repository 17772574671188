import { Grant, GrantFeature } from 'lib/grants'
import React from 'react'
const FirstTimeOnboarding = React.lazy(() => import('../variants/first-time-onboarding/components/FirstTimeOnboarding'))
const NextBigReleaseOnboarding = React.lazy(
    () => import('../variants/next-big-relase-onboarding/components/NextBigReleaseOnboarding'),
)
const MiniQuizOnboarding = React.lazy(() => import('../variants/mini-quiz-onboarding/components/MiniQuizOnboarding'))

export type OnboardingVariant = '2022-APR' | 'FIRST-TIME' | 'MINI-QUIZ'

export type OnboardingUserSettings = Partial<Record<OnboardingVariant, boolean>>

export interface OnboardingDefinition<T extends OnboardingVariant> {
    userCreatedBefore?: string
    Component: React.FC
    enabledForGuest: boolean
    variant: T
    enabled: boolean
    requiredGrant?: Grant | GrantFeature
}

export const onboardingDefinitionsByVariant: { [Key in OnboardingVariant]: OnboardingDefinition<Key> } = {
    'FIRST-TIME': {
        variant: 'FIRST-TIME',
        Component: FirstTimeOnboarding,
        enabledForGuest: true,
        enabled: true,
    },
    '2022-APR': {
        variant: '2022-APR',
        userCreatedBefore: '2022-03-30T06:00:00Z',
        Component: NextBigReleaseOnboarding,
        enabledForGuest: false,
        enabled: true,
    },
    'MINI-QUIZ': {
        variant: 'MINI-QUIZ',
        Component: MiniQuizOnboarding,
        enabledForGuest: false,
        enabled: true,
        userCreatedBefore: '2022-09-13T06:00:00Z',
        requiredGrant: 'mini-quiz-create-and-share',
    },
}

import { ErrorBoundary } from 'components/ErrorBoundary'
import { ErrorMessage } from 'components/ErrorMessage'
import { Skeleton } from 'components/Skeleton'
import { useMyOFCsets } from 'features/scoolcode-challenge/data/scoolcodeChallengeQueries'
import { PoorMansSuspenseTicker } from 'hooks/usePoorMansSuspense'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { OnlinefirstChallengeCard } from '..'
import { OnlinefirstChallengeDialog } from './OnlinefirstChallengeDialog'

interface OnlinefirstChallengeListProps extends PoorMansSuspenseTicker {
    limit?: number
}

export const OnlinefirstChallengeList: React.FC<OnlinefirstChallengeListProps> = ({ limit, onReady }) => {
    const { data, status } = useMyOFCsets()
    const { path } = useRouteMatch()

    if (status === 'error') {
        return <ErrorMessage />
    }

    if (!data) {
        return <Skeleton variant="rect" width="100%" height="18rem" />
    }

    return (
        <>
            {data.slice(0, limit).map(set => {
                return <OnlinefirstChallengeCard set={set} key={set.id} onReady={onReady} />
            })}
            <Route
                path={`${path}/:setId/:id`}
                render={() => (
                    <ErrorBoundary>
                        <OnlinefirstChallengeDialog />
                    </ErrorBoundary>
                )}
            />
        </>
    )
}

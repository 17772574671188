import { Divider } from 'components/Divider'
import { IconButton } from 'components/IconButton'
import { EditIcon } from 'components/icons'
import { Box } from 'components/layout'
import React from 'react'
import { useProjectModalContext } from '../modal/projectModalContext'
import { ProjectModalDescription } from '../modal/ProjectModalDescription'
import { useForm } from 'react-hook-form'
import { TextField } from 'components/form'
import { PROJECT_DETAILS_MAX_LENGTH } from '../projectHelpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import { DialogPrimaryButton, DialogSecondaryButton, DialogForm } from 'components/dialog'
import { useProjectActions } from '../useProjectActions'
import { ErrorMessage } from 'components/ErrorMessage'
import styled from 'styled-components/macro'
import { darken } from 'polished'

export const ProjectDetailsEditableDescription: React.FC = () => {
    const t = useTranslate()

    const { state, errors, register, setEdit, setIdle, onSubmit, defaultDetails } = useEditProjectDetails()

    if (state === 'null') {
        return null
    }

    return (
        <>
            <Divider />

            {state === 'idle' && (
                <StyledBox onClick={setEdit}>
                    <ProjectModalDescription>
                        <IconButton size="small" color="primary">
                            <EditIcon />
                        </IconButton>
                        {defaultDetails || t('projects::addDescription')}
                    </ProjectModalDescription>
                </StyledBox>
            )}

            {(state === 'edit' || state === 'loading') && (
                <DialogForm
                    onSubmit={onSubmit}
                    actions={
                        <>
                            <DialogSecondaryButton onClick={setIdle}>{t('general::cancel')}</DialogSecondaryButton>
                            <DialogPrimaryButton isLoading={state === 'loading'} type="submit">
                                {t('general::save')}
                            </DialogPrimaryButton>
                        </>
                    }
                >
                    <TextField
                        errorText={errors.details?.message}
                        name="details"
                        helperText={t('projects::detailsHelper')}
                        multiline
                        rows={3}
                        ref={register({
                            maxLength: {
                                value: PROJECT_DETAILS_MAX_LENGTH,
                                message: t('general::maxLength', { value: PROJECT_DETAILS_MAX_LENGTH }),
                            },
                        })}
                        inputProps={{ maxLength: PROJECT_DETAILS_MAX_LENGTH }}
                        autoFocus
                    />
                </DialogForm>
            )}
            {state === 'error' && <ErrorMessage />}
            <Divider />
        </>
    )
}

const StyledBox = styled(Box)`
    padding: ${props => props.theme.spacing(1)};
    &:hover {
        background-color: ${props =>
            darken(props.theme.palette.type === 'light' ? 0.1 : 0.03, props.theme.palette.background.paper)};
        border-radius: ${props => props.theme.shape.borderRadius}px;
    }
`

type Status = 'idle' | 'loading' | 'edit' | 'null' | 'error'

function useEditProjectDetails() {
    const { updateProjectDetails } = useProjectActions()
    const { project, id, variant } = useProjectModalContext()
    const defaultDetails = variant ? project?.metadata.details?.[variant] : ''
    const [state, setState] = React.useState<Status>(() => {
        if (variant === null) return 'null'
        return 'idle'
    })

    const defaultValues = React.useMemo(
        () => ({
            details: variant ? project?.metadata.details?.[variant] : '',
        }),
        [variant, project],
    )

    const { register, handleSubmit, errors, reset } = useForm<{ details: string }>({
        defaultValues,
    })

    React.useEffect(() => {
        reset(defaultValues)
    }, [reset, defaultValues])

    const setEdit = () => {
        if ((window?.getSelection()?.toString()?.length ?? 0) <= 0) setState('edit')
    }

    const setIdle = () => {
        setState('idle')
    }

    const onSubmit = handleSubmit(async ({ details }) => {
        if (state !== 'null' && state !== 'loading') {
            setState('loading')
            try {
                await updateProjectDetails(id, details, variant!)

                setIdle()
            } catch (e) {
                setState('error')
            }
        }
    })

    return {
        register,
        errors,
        handleSubmit,
        state,
        setEdit,
        setIdle,
        onSubmit,
        defaultDetails,
    }
}

import { UserStage, UserSubStage } from 'lib/api'
import { allGrants, Grant } from '..'

export type userPreSets = {
    title: string
    neededGrants?: Grant[]
    userStage?: UserStage
    userSubStage?: UserSubStage
}

const guestGrants: Grant[] = [
    { product: 'edup.quiz', feature: 'login' },
    { product: 'edup.universe', feature: 'content.certificates' },
    { product: 'edup.universe', feature: 'content.onboarding-event' },
    { product: 'edup', feature: 'change-ui-language' },
]

export const preDefinedUserSchemas: userPreSets[] = [
    {
        title: 'Guest',
        userStage: 'lead',
        neededGrants: guestGrants,
    },
    {
        title: 'Registered Guest',
        userStage: 'registered-lead',
        neededGrants: guestGrants,
    },
    {
        title: 'Guest with unlocked content',
        userStage: 'lead',
        neededGrants: [{ product: 'edup.universe', feature: 'content.challenges.onboarding-points' }, ...guestGrants],
    },
    {
        title: 'Open day applicant',
        userStage: 'user',
        userSubStage: 'open-day',
        neededGrants: [{ product: 'edup.universe', feature: 'content.challenges.onboarding-points' }, ...guestGrants],
    },
    {
        title: 'Open day participant',
        userStage: 'user',
        userSubStage: 'open-day',
        neededGrants: [
            { product: 'edup.universe', feature: 'content.challenges.onboarding-points' },
            { product: 'edup.universe', feature: 'content.challenges.open-day-participation' },
            { product: 'edup.universe', feature: 'limited-app.latest-challenges' },
            { product: 'edup.universe', feature: 'limited-app.view-community-projects' },
            { product: 'edup.universe', feature: 'follow-users' },
            ...guestGrants,
        ],
    },
    {
        title: 'Normal user',
        userStage: 'user',
        neededGrants: [
            { product: 'edup.scoolcode.ide', feature: 'create-project.blox' },
            { product: 'edup.universe', feature: 'share-project' },
            { product: 'edup.universe', feature: 'content.digital-mission' },
            { product: 'edup.universe', feature: 'content.certificates' },
            { product: 'edup.universe', feature: 'content.onboarding-event' },
            { product: 'edup.universe', feature: 'content.challenges.onboarding-points' },
            { product: 'edup.universe', feature: 'content.challenges.open-day-participation' },
            { product: 'edup.quiz', feature: 'login' },
            { product: 'edup', feature: 'change-ui-language' },
            { product: 'edup.universe', feature: 'follow-users' },
        ],
    },
    {
        title: 'License user',
        userStage: 'user',
        neededGrants: [
            { product: 'edup.scoolcode.ide', feature: 'create-project.blox' },
            { product: 'edup.universe', feature: 'share-project' },
            { product: 'edup.universe', feature: 'share-project-user-consent' },
            { product: 'edup.universe', feature: 'content.digital-mission' },
            { product: 'edup.universe', feature: 'content.certificates' },
            { product: 'edup.universe', feature: 'content.onboarding-event' },
            { product: 'edup.universe', feature: 'content.challenges.onboarding-points' },
            { product: 'edup.universe', feature: 'content.challenges.open-day-participation' },
            { product: 'edup.quiz', feature: 'login' },
            { product: 'edup', feature: 'change-ui-language' },
            { product: 'edup.universe', feature: 'follow-users' },
        ],
    },
    // {
    //     title: 'Trainer User',
    //     neededGrants: [
    //         { product: 'edup.universe', feature: 'share-project' },
    //         { product: 'edup.universe', feature: 'content.digital-mission' },
    //         { product: 'edup.universe', feature: 'content.certificates' },
    //         { product: 'edup.universe', feature: 'content.trainer-contest-nomination' },
    //         { product: 'edup.universe', feature: 'content.onboarding-event' },
    //         { product: 'edup.universe', feature: 'view-community-projects' },
    //         { product: 'edup.universe', feature: 'view-recommended-projects' },
    //         // wait for feature release
    //         // { product: 'edup.universe', feature: 'create-high-score-room' },
    //         // { product: 'edup.universe', feature: 'join-high-score-room' },
    //         { product: 'edup.quiz', feature: 'login' },
    //         { product: 'edup.universe', feature: 'change-bio-without-approval' },
    //         { product: 'edup.scoolcode.ide', feature: 'create-project.blox' },
    //         { product: 'edup.scoolcode.ide', feature: 'create-project.mix' },
    //         { product: 'edup.scoolcode.ide', feature: 'offboarding.mix' },
    //         { product: 'edup.scoolcode.ide', feature: 'reset-challenge-set' },
    //         { product: 'edup', feature: 'change-ui-language' },
    //         { product: 'edup', feature: 'change-email' },
    //     ],
    // },
    // {
    //     title: 'Translator User',
    //     neededGrants: [
    //         { product: 'edup.universe', feature: 'share-project' },
    //         { product: 'edup.universe', feature: 'content.digital-mission' },
    //         { product: 'edup.universe', feature: 'content.certificates' },
    //         { product: 'edup.universe', feature: 'content.trainer-contest-nomination' },
    //         { product: 'edup.universe', feature: 'content.onboarding-event' },
    //         { product: 'edup.universe', feature: 'view-community-projects' },
    //         { product: 'edup.universe', feature: 'view-recommended-projects' },
    //         // wait for feature release
    //         // { product: 'edup.universe', feature: 'create-high-score-room' },
    //         // { product: 'edup.universe', feature: 'join-high-score-room' },
    //         { product: 'edup.quiz', feature: 'login' },
    //         { product: 'edup.universe', feature: 'change-bio-without-approval' },
    //         { product: 'edup.scoolcode.ide', feature: 'create-project.blox' },
    //         { product: 'edup.scoolcode.ide', feature: 'create-project.mix' },
    //         { product: 'edup.scoolcode.ide', feature: 'offboarding.mix' },
    //         { product: 'edup.scoolcode.ide', feature: 'reset-challenge-set' },
    //         { product: 'edup', feature: 'change-ui-language' },
    //         { product: 'edup', feature: 'all-languages' },
    //         { product: 'edup', feature: 'change-email' },
    //         { product: 'edup', feature: 'change-default-school' },
    //     ],
    // },
    {
        title: 'All Grants',
        userStage: 'user',
        neededGrants: [...allGrants],
    },
]

import React from 'react'
import Menu from '@material-ui/core/Menu'
import styled from 'styled-components/macro'

interface MyCustomRightClickMenuProps {
    menu: React.ReactNode
}

export const MyCustomRightClickMenu: React.FC<MyCustomRightClickMenuProps> = ({ children, menu }) => {
    const [contextMenu, setContextMenu] = React.useState<{
        mouseX: number
        mouseY: number
    } | null>(null)

    const handleContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        setContextMenu(
            contextMenu === null
                ? {
                      mouseX: event.clientX - 2,
                      mouseY: event.clientY - 4,
                  }
                : null,
        )
    }

    const handleClose = () => {
        setContextMenu(null)
    }

    return (
        <div onContextMenu={handleContextMenu}>
            {children}
            <StyledMenu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
                }
            >
                {menu}
            </StyledMenu>
        </div>
    )
}

const StyledMenu = styled(Menu)`
    .MuiMenu-list {
        padding: ${props => props.theme.spacing(2)};
    }
`

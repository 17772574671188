import { Card } from 'components/card'
import React from 'react'
import styled from 'styled-components/macro'

interface GuestCommunityCloudItemProps {
    image: string
}

export const GuestCommunityCloudItem: React.FC<GuestCommunityCloudItemProps> = ({ children, image }) => {
    return (
        <Root>
            <Badge src={image} /> <span>{children}</span>
        </Root>
    )
}

const Badge = styled.img`
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    transform: scale(1.25);
`

const Root = styled(Card)`
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    display: inline-grid;
    grid-template-columns: auto 1fr;
    grid-gap: ${props => props.theme.spacing(2)};
    align-items: center;
    padding-inline-end: ${props => props.theme.spacing(4)};
    overflow: visible;
    font-weight: 600;
    margin: 0.2rem;
`

import { api } from 'lib/api'
import { useMemo } from 'react'
import { useInfiniteQuery, useQuery, useQueryCache } from 'react-query'

export function useLatestClassroomQuizResultsQuery() {
    return useQuery(['latest-classroom-quiz-result'], () => api.quizzes.getLatestClassroomQuizResult(), {
        staleTime: 60 * 1000 * 5,
    })
}

export function useQuizResultsQuery() {
    return useInfiniteQuery(
        'classrom-quiz-results',
        (_, skip: number = 0) => api.quizzes.getClassroomQuizResults(skip as number),
        {
            getFetchMore: lastGroup => lastGroup.hasLeft,
        },
    )
}

export function useGetFinishedTemplatesStats(quizSetId: string, enabled: boolean) {
    return useQuery(quizSetId, () => api.quizzes.getFinishedTemplates(quizSetId), {
        staleTime: 60 * 1000 * 5,
        enabled,
    })
}

export const useInvalidateClassroomQuizResults = () => {
    const queryCache = useQueryCache()

    return useMemo(
        () => ({
            invalidateClassroomQuizResults: () => {
                queryCache.invalidateQueries('latest-classroom-quiz-result')
                queryCache.invalidateQueries('classrom-quiz-results')
            },
        }),
        [queryCache],
    )
}

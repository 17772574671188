import { CurrencyBadge } from 'components/CurrencyBadge'
import { Stack } from 'components/layout'
import { Tooltip } from 'components/Tooltip'
import dayjs from 'dayjs'
import { useTranslate } from 'lib/i18n/useTranslate'
import { CurrencyName } from 'lib/liveops/reducers/client/currency'
import { useSelector } from 'lib/store'
import React, { useEffect, useState } from 'react'
import { currencyStyles } from './currencyStyles'

interface PageCookieProps {
    currency: CurrencyName
}

export const PageCookie: React.FC<PageCookieProps> = ({ currency }) => {
    const { refillsAt, refillAfterMinutes, value } = useSelector(state => state.client.currency[currency])
    const t = useTranslate()
    const [refill, setRefill] = useState({
        progress: 1,
        minutesLeft: 0,
    })

    useEffect(() => {
        const tick = () => {
            if (!refillAfterMinutes) {
                return
            }
            const minutesLeft = dayjs(refillsAt).diff(dayjs(), 'minutes')
            const progress = (() => {
                if (refillAfterMinutes <= 0 || refillAfterMinutes < minutesLeft) {
                    return 1
                }
                return 1 - minutesLeft / refillAfterMinutes
            })()
            setRefill({
                progress,
                minutesLeft,
            })
        }

        if (refillsAt) {
            const interval = setInterval(tick, 1000 * 60)
            tick()

            return () => {
                clearInterval(interval)
            }
        } else {
            setRefill({
                progress: 1,
                minutesLeft: 0,
            })
        }
    }, [refillsAt, refillAfterMinutes])

    if (value === null) {
        return null
    }

    const tooltipTitle = (() => {
        if (refill.minutesLeft <= 0) {
            return t(currencyTooltips[currency])
        }
        const text =
            refill.minutesLeft < 60
                ? t('projects::nextCookieMinutes', { count: refill.minutesLeft })
                : t('projects::nextCookieHours', { count: Math.round(refill.minutesLeft / 60) })
        return (
            <Stack spacing={2}>
                <div>
                    <strong>{text}</strong>
                </div>
                <div>{t(currencyTooltips[currency])}</div>
            </Stack>
        )
    })()

    return (
        <Tooltip PopperProps={{ style: { whiteSpace: 'pre-line' } }} title={tooltipTitle}>
            <CurrencyBadge
                backgroundColor={currencyStyles[currency].colors.background}
                color={currencyStyles[currency].colors.color}
                iconUrl={currencyStyles[currency].icon.colored}
                to="/app/community/discover"
                value={value}
            />
        </Tooltip>
    )
}

const currencyTooltips = {
    cookie: 'projects::cookiesDescription',
    'golden-cookie': 'projects::goldenCookiesDescription',
}

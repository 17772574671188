import React, { forwardRef } from 'react'
import { SwitchProps as MuiSwitchProps, Switch as MuiSwitch } from '@material-ui/core'
import { FormLabel, FormHelperText } from 'components/form'

interface SwitchProps extends MuiSwitchProps {
    label?: string
    helperText?: string
    errorText?: string
}

export const Switch = forwardRef<any, SwitchProps>(({ id, label, helperText, errorText, ...rest }, ref) => {
    return (
        <div>
            <MuiSwitch color="primary" id={rest.name} inputRef={ref} {...rest} />
            {label && <FormLabel htmlFor={rest.name}>{label}</FormLabel>}
            {errorText && <FormHelperText error>{errorText}</FormHelperText>}
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </div>
    )
})

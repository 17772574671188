import { transparentize } from 'polished'
import React from 'react'
import styled, { css } from 'styled-components/macro'
import backgroundImg from './xmas-bg.webp'
import footerImg from './xmas-footer.webp'
import { createTheme } from 'lib/theme/createTheme'
import { Snowfall } from './Snowfall'
import { BIRTHDAY_ENABLED } from 'features/birthday'
const XmasLights = React.lazy(() => import('./XmasLights'))

const FooterImage = styled.img`
    display: block;
    bottom: 0;
    max-width: 100%;
    height: auto;
`

const Thumbnail: React.FC = () => {
    return (
        <svg
            id="R\xE9teg_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x={0}
            y={0}
            viewBox="0 0 1920 1080"
            xmlSpace="preserve"
        >
            <style>{'.st9{fill:#fff}'}</style>
            <path
                d="M535 0h1385v1080H535V0z"
                style={{
                    fill: '#15375e',
                }}
            />
            <path
                d="M1445 100.8h344.1v85.3H1445v-85.3zm-296.5 0h250.9v85.3h-250.9v-85.3z"
                style={{
                    fill: '#e85656',
                }}
            />
            <path
                d="M667.6 274.8h344.1v258.5H667.6V274.8zm388.7 0h344.1v258.5h-344.1V274.8zm388.7 0h344.1v258.5H1445V274.8zM667.6 608.3h344.1v258.5H667.6V608.3zm388.7 0h344.1v258.5h-344.1V608.3zm388.7 0h344.1v258.5H1445V608.3z"
                style={{
                    fill: '#0c2135',
                }}
            />
            <path
                d="M0 0h535v1080H0V0z"
                style={{
                    fill: '#00502e',
                }}
            />
            <path
                d="M149.5 89.8h228v213.9h-228V89.8zM84.7 424.5h304v43.3h-304v-43.3zm0 97H322v43.3H84.7v-43.3zm0 97h268v43.3h-268v-43.3zm0 97H382v43.3H84.7v-43.3zm0 97H342v43.3H84.7v-43.3z"
                style={{
                    fill: '#cfece0',
                }}
            />
            <defs>
                <path id="SVGID_1_" d="M535 812.5h1385V1080H535z" />
            </defs>
            <clipPath id="SVGID_00000154383876184233390970000005019030986168625538_">
                <use
                    xlinkHref="#SVGID_1_"
                    style={{
                        overflow: 'visible',
                    }}
                />
            </clipPath>
            <g
                style={{
                    clipPath: 'url(#SVGID_00000154383876184233390970000005019030986168625538_)',
                }}
            >
                <path
                    d="m407.9 964 221.3-27.5 319.7 68.6 287-34.3 72.5 12.1 132.8 22.2 214.4-58.5 261.5 60.3 9 74-1518.2-3.7V964z"
                    style={{
                        fill: '#2053a5',
                    }}
                />
                <path
                    d="m393.1 927 376 68.5 297-62.8 256 76.1 112-50.4 200 46.6 254-22.8 114-1.8v60.4l-1637 62.2 28-176z"
                    style={{
                        fill: '#efeff4',
                    }}
                />
                <path
                    d="m412.3 1035 224-14 729 25 217.6-27.9 344.4-.1 9 89-1545.6-6 21.6-66z"
                    style={{
                        fill: '#b3d6f4',
                    }}
                />
            </g>
            <circle className="st9" cx={468.2} cy={231} r={20} />
            <circle className="st9" cx={448.3} cy={834.2} r={20} />
            <circle className="st9" cx={468.2} cy={231} r={20} />
            <circle className="st9" cx={747.1} cy={458.2} r={20} />
            <circle className="st9" cx={1227.5} cy={749.7} r={20} />
            <circle className="st9" cx={1379.4} cy={478.2} r={20} />
            <circle className="st9" cx={1011.7} cy={242.2} r={20} />
            <circle className="st9" cx={84.7} cy={89.8} r={20} />
            <circle className="st9" cx={1227.5} cy={749.7} r={20} />
            <circle className="st9" cx={1809.1} cy={196.8} r={20} />
            <circle className="st9" cx={727.1} cy={834.2} r={20} />
            <circle className="st9" cx={1227.5} cy={749.7} r={20} />
            <circle className="st9" cx={1717.5} cy={834.2} r={20} />
        </svg>
    )
}

export const xmas = createTheme(Thumbnail, {
    palette: {
        text: { primary: '#9ed0f5', secondary: '#6ba3cc' },
        type: 'dark',
        primary: {
            main: '#e85656',
            dark: '#b82121',
        },
        divider: '#174679',
        background: {
            default: '#15375e',
            paper: '#0f3157',
        },
    },

    extras: {
        logoType: 'white',
        themeColorMeta: '#121e40',

        global: {
            body: css`
                background: url(${backgroundImg}), linear-gradient(#1d3f6e, #1f222b);
                background-attachment: fixed;
                background-size: 600px auto;
            `,
        },

        tabs: {
            tab: css`
                text-shadow: 0 0 6px ${props => transparentize(0.2, props.theme.palette.text.primary)};
                &.Mui-selected {
                    text-shadow: 0 0 10px ${props => transparentize(0.2, props.theme.palette.primary.main)};
                }
            `,
            indicator: css`
                box-shadow: 0 0 10px ${props => props.theme.palette.primary.main};
            `,
        },

        pageAside: {
            extraComponent: (
                <React.Suspense fallback={null}>
                    <XmasLights />
                </React.Suspense>
            ),
            paper: css`
                background: #00502e;
                color: #cfece0;
                border-right: none;
                box-shadow: 6px 0 9px #07091552;
            `,
            dividers: css`
                background-color: #083321;
            `,
            listItemAndNotificationButton: css`
                text-shadow: 0 0 6px #cfece066;
                .MuiListItemIcon-root {
                    filter: drop-shadow(0 0 5px #cfece066);
                }
                &.Mui-selected {
                    color: #2cce6a;
                    background-color: #2cce6a40;
                    text-shadow: 0 0 6px #4dcd7ea3;
                    &:hover,
                    focus-visible {
                        color: #2cce6a;
                        background-color: #2cce6a61;
                    }
                    .MuiListItemIcon-root {
                        filter: drop-shadow(0 0 5px #4dcd7ea3);
                    }
                }
            `,
            secondary: css`
                color: #49b587;
                text-shadow: none;
                .MuiListItemIcon-root {
                    filter: none;
                }
            `,
        },

        actionablePaper: {
            root: css`
                &:hover,
                &:focus-visible {
                    background-color: ${props => props.theme.palette.background.paper};
                    filter: brightness(1.2);
                }
            `,
        },

        pageHeader: {
            root: css`
                background-color: #0f3157;
                border-bottom: solid 1px #174679;
            `,
        },
        page: {
            footerImage: <FooterImage width="3644" height="587" src={footerImg} />,
        },
        globalComponent: BIRTHDAY_ENABLED ? undefined : <Snowfall />,
        dialog: {
            backdrop: css`
                background: rgb(3 18 25 / 91%);
            `,
        },
        textButton: color => {
            if (color === 'primary') {
                return css`
                    text-shadow: 0 0 5px ${props => transparentize(0.1, props.theme.palette.primary.main)};
                `
            }
        },

        containedButton: color => {
            if (color === 'primary') {
                return css`
                    &,
                    &:hover,
                    &:focus-visible {
                        box-shadow: 0 0 7px ${props => transparentize(0.4, props.theme.palette.primary.main)};
                    }
                    &.Mui-disabled {
                        background: #1d4654;
                    }
                `
            }
        },
    },
})

import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { LightDarkColor, pickLightDarkColor } from 'utils/styleUtils'

interface CurrencyBadgeProps {
    value: number
    iconUrl: string
    to: string
    backgroundColor?: LightDarkColor
    color?: LightDarkColor
}

export const CurrencyBadge = React.forwardRef<any, CurrencyBadgeProps>(
    ({ backgroundColor, color, iconUrl, to, value, ...rest }, ref) => {
        return (
            <Root ref={ref} to={to} $backgroundColor={backgroundColor} $color={color} {...rest}>
                <Image width="24" height="24" src={iconUrl} alt="" />
                <span>{value}</span>
            </Root>
        )
    },
)

const Root = styled(Link)<{
    $backgroundColor?: LightDarkColor
    $color?: LightDarkColor
}>`
    display: flex;
    align-items: center;
    gap: ${props => props.theme.spacing(2)};
    padding: ${props => props.theme.spacing(2, 3)};
    background-color: ${props => pickLightDarkColor(props.theme, props.$backgroundColor)};
    color: ${props => pickLightDarkColor(props.theme, props.$color)};
    font-weight: 800;
    border-radius: 20px;
    font-size: ${props => props.theme.typography.body2.fontSize};
    text-decoration: none;
    transition: filter ${props => props.theme.transitions.duration.short}ms;

    &:hover,
    &:focus-visible {
        filter: brightness(0.95);
    }
`

const Image = styled.img`
    width: 1.5rem;
    max-width: 100%;
    height: auto;
`

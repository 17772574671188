import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { HighScoreGame } from './highScoreGames'
import { HighScoreGameThumbnail } from './HighScoreGameThumbnail'

interface HighScoreGameThumbnailLinkProps {
    highlighted?: boolean
    game: HighScoreGame
}

export const HighScoreGameThumbnailLink: React.FC<HighScoreGameThumbnailLinkProps> = ({
    highlighted = false,
    game,
}) => {
    return (
        <Root $highlighted={highlighted} to={`/app/high-score/${game.projectId}`}>
            <HighScoreGameThumbnail game={game} />
        </Root>
    )
}

const Root = styled(Link)<{ $highlighted?: boolean }>`
    display: inline-block;
    text-decoration: none;
    color: inherit;
    &:hover,
    &:focus-visible {
        video,
        image {
            filter: grayscale(0) brightness(1.25);
            transform: ${props => (props.$highlighted ? undefined : 'scale(1.05)')};
        }
    }
`

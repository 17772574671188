import { api } from 'lib/api'
import { useMemo } from 'react'
import { useQuery, useQueryCache } from 'react-query'

const KEY = 'latest-generated-quiz-session'

export function useInvalidateGeneratedQuizSessions() {
    const queryCache = useQueryCache()

    return useMemo(
        () => ({
            invalidateGeneratedQuizSessions: () => {
                queryCache.invalidateQueries(KEY)
            },
        }),
        [queryCache],
    )
}

export function useGeneratedQuizSessionsQuery() {
    return useQuery(KEY, async () => api.quizzes.getActiveGeneratedSessions())
}

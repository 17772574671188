import React from 'react'
import styled from 'styled-components/macro'
import { Avatar, AvatarProps, AvatarRoot, AvatarImg } from './Avatar'
import { CheckIcon, LockIcon } from 'components/icons'
import { AvatarOverlay } from './AvatarOverlay'
import { AvatarBadge } from './AvatarBadge'
import { visuallyHidden } from 'utils/styleUtils'
import { Tooltip } from 'components/Tooltip'
import { useTranslate } from 'lib/i18n/useTranslate'
import { LocalisationKey } from 'lib/i18n'

interface SelectableAvatarProps extends AvatarProps {
    selected: boolean
    onChange: (checked: boolean) => void
    locked?: boolean
    lockedMessage?: string[]
}

export const SelectableAvatar: React.FC<SelectableAvatarProps> = ({
    selected,
    onChange,
    locked,
    lockedMessage,
    ...rest
}) => {
    const t = useTranslate()

    return (
        <SelectableAvatarRoot locked={locked}>
            <label>
                <input
                    id={rest.src}
                    type="radio"
                    checked={selected}
                    onChange={e => {
                        if (!locked) {
                            onChange(e.target.checked)
                        }
                    }}
                />
                <Avatar {...rest}>
                    <>
                        {selected && (
                            <AvatarBadge>
                                <CheckIcon />
                            </AvatarBadge>
                        )}
                        {locked && (
                            <Tooltip
                                title={lockedMessage?.map(message => t(message as LocalisationKey)).join(' ') ?? ''}
                            >
                                <AvatarOverlay>
                                    <StyledLockIcon />
                                </AvatarOverlay>
                            </Tooltip>
                        )}
                    </>
                </Avatar>
            </label>
        </SelectableAvatarRoot>
    )
}

const StyledLockIcon = styled(LockIcon)`
    position: absolute;
    top: ${props => props.theme.spacing(2)};
    right: ${props => props.theme.spacing(2)};
    color: #fff;
`

const SelectableAvatarRoot = styled.div<{ locked?: boolean }>`
    label {
        cursor: ${props => (props.locked ? 'default' : 'pointer')};
        &:hover {
            ${AvatarImg} {
                filter: ${props => (props.locked ? '' : 'brightness(1.1)')};
            }
        }
    }
    input {
        ${visuallyHidden}
    }
    ${AvatarImg} {
        filter: ${props => (props.locked ? 'grayscale(1) contrast(0.4)' : '')};
    }
    input:checked {
        + ${AvatarRoot} ${AvatarImg} {
            box-shadow: 0 0 0 3px ${props => props.theme.palette.primary.main};
        }
    }
`

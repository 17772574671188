import { Inline } from 'components/layout'
import React from 'react'
import styled from 'styled-components/macro'

export const PanelActions: React.FC = ({ children }) => {
    return <StyledInline spacing={2}>{children}</StyledInline>
}

const StyledInline = styled(Inline)`
    margin-inline-start: ${props => props.theme.spacing(2)};
`

import React from 'react'
import styled from 'styled-components/macro'
const ReactSnowfall = React.lazy(() => import('react-snowfall'))

export const Snowfall: React.FC = () => {
    return (
        <Root>
            <React.Suspense fallback={null}>
                <ReactSnowfall speed={[0.5, 1.5]} wind={[-2, 2]} />
            </React.Suspense>
        </Root>
    )
}

const Root = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${props => props.theme.zIndex.drawer + 1};
    pointer-events: none;
`

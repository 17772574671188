import React from 'react'
import styled from 'styled-components/macro'
import { Skeleton } from 'components/Skeleton'
import { useQuery } from 'react-query'
import { api } from 'lib/api'
import { NotFoundError } from 'utils/errors'
import { createAvatarUrl } from 'features/avatar'
import { NotificationIcon } from 'components/icons'
import { Flex } from 'components/layout'

type ImageProps = {
    notiMediaSrc: string | undefined
}

export const NotificationImage: React.FC<ImageProps> = ({ notiMediaSrc }) => {
    const [state, setState] = React.useState<'loading' | 'loaded' | 'error'>('loading')

    if (state === 'error')
        return (
            <Flex justifyContent="center" alignItems="center">
                <StyledNotificationIcon fontSize="2rem" />
            </Flex>
        )

    return (
        <>
            {state === 'loading' && <Skeleton variant="circle" width={'4rem'} height={'4rem'} />}
            <StyledNotiImg
                src={notiMediaSrc}
                onLoad={() => setState('loaded')}
                onError={() => setState('error')}
                style={state === 'loading' ? { display: 'none' } : {}}
            />
        </>
    )
}

type UserAvatarProps = {
    userId: string | undefined
}

export const NotificationUserAvatar: React.FC<UserAvatarProps> = ({ userId }) => {
    const { data: user, status } = useQuery(userId, api.accounts.getUser, {
        retry: (retryCount, error) => (error instanceof NotFoundError ? false : retryCount <= 2),
        staleTime: Infinity,
        enabled: userId,
    })

    if (!userId || status === 'error')
        return (
            <Flex justifyContent="center" alignItems="center">
                <StyledNotificationIcon fontSize="2rem" />
            </Flex>
        )
    return <NotificationImage notiMediaSrc={createAvatarUrl(user?.profileImage)} />
}

const StyledNotiImg = styled.img`
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

const StyledNotificationIcon = styled(NotificationIcon)`
    opacity: 0.3;
`

import { ApiBase } from 'lib/api/ApiBase'

interface SecretCodeRedeemResponseBase {
    success: boolean
}

interface SecretCodeRedeemQuizResponse extends SecretCodeRedeemResponseBase {
    kind: 'quiz'
    quizId: string
    redirectUri: string
}

interface SecretCodeRedeemScoolcodeLiveRoomResponse extends SecretCodeRedeemResponseBase {
    kind: 'scoolcode-live-room'
    roomId: string
    ownerId: string
    redirectUri: string
}

interface SecretCodeRedeemProjectResponse extends SecretCodeRedeemResponseBase {
    kind: 'project'
    projectId: string
    redirectUri: string
}

interface SecretCodeRedeemEventParticipationResponse extends SecretCodeRedeemResponseBase {
    kind: 'event-participation'
    challengeSetId?: string
}

export type SecretCodeRedeemResponse =
    | SecretCodeRedeemQuizResponse
    | SecretCodeRedeemScoolcodeLiveRoomResponse
    | SecretCodeRedeemProjectResponse
    | SecretCodeRedeemEventParticipationResponse

export class SecretCodesApi extends ApiBase {
    redeem = (code: string) => {
        return this.client.post<SecretCodeRedeemResponse>('secret-codes/redeem', { code, processAt: 'backend' })
    }
}

import React from 'react'
import { Skeleton as MuiSkeleton, SkeletonProps as MuiSkeletonProps } from '@material-ui/lab'
import styled from 'styled-components/macro'

interface SkeletonProps extends MuiSkeletonProps {}

export const Skeleton: React.FC<SkeletonProps> = props => {
    return <StyledSkeleton {...props} />
}

const StyledSkeleton = styled(MuiSkeleton)`
    border-radius: ${props => props.theme.shape.borderRadius}px;
`

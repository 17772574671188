import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'

export const PanelTitle: React.FC = ({ children }) => {
    return (
        <Root variant="h3" component="h2">
            {children}
        </Root>
    )
}

const Root = styled(Text)`
    text-transform: uppercase;
    line-height: 1.2;
` as typeof Text

import { AxiosInstance, AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { MINI_QUIZ_PROJECT_TAG } from 'features/mini-quizzes'
import { api, ApiTypes, PrimaryKind } from 'lib/api'
import { removeTokenFromParams } from 'lib/api/Api'
import { ApiBase } from 'lib/api/ApiBase'
import { View } from './discover/DiscoverProjects'
import {
    cacheBustThumbnail,
    deserializeProjectLabel,
    isCustomProjectLabel,
    ProjectVariant,
    serializeProjectLabel,
} from './projectHelpers'
import { ProjectLabel } from './store'
import { ProjectLanguage } from './createProjectFormData'

export const CLASSROOM_PROJECT_TAG = 'classroom'
export const SHAREABLE_TRAINERCONTEST_PROJECT_TAG = 'trainerContest2021'

interface CreateEmptyProjectParams {
    name: string
    type: 'blox' | 'mix'
    palette?: string
    template?: string
    language?: ProjectLanguage
    labels?: ProjectLabel[]
}

const typeRecord: Record<'blox' | 'mix', string> = {
    blox: 'project/blocks',
    mix: 'project/mix',
}

export class ProjectsApi extends ApiBase {
    constructor(client: AxiosInstance, private getUserId: () => string | null) {
        super(client)
    }

    getBlockGalleries = async () => {
        const res = await this.client.get<Pick<ApiTypes['block-gallery'], 'id' | 'name'>[]>('block-galleries/my')
        res.data.sort((a, b) => (a.name && b.name ? a.name?.localeCompare(b.name) : 0))
        return res
    }

    createEmptyProject = ({ name, type, palette, labels, language }: CreateEmptyProjectParams) => {
        if (type === 'blox' && palette === undefined) {
            throw new Error(`Palette must be defined if type is 'blox'.`)
        }

        return this.client.post<ApiTypes['file']>('/files/create-project', {
            name,
            kind: typeRecord[type],
            blockPalette: palette,
            labels,
            language,
        })
    }

    cloneProject = (name: string, type: 'blox' | 'mix', sourceId: string, palette?: string) => {
        const typeRecord: Record<'blox' | 'mix', string> = {
            blox: 'project/blocks',
            mix: 'project/mix',
        }

        return this.client.post<ApiTypes['file']>('/files/create-project', {
            name,
            kind: typeRecord[type],
            blockPalette: palette,
            template: sourceId,
            createMode: 'foreign-project-clone',
        })
    }

    getProject = (id: string) => {
        return this.client.get<ApiTypes['file']>(`files/${id}`)
    }

    getProjectWithEmbeddedOwner = (id: string) => {
        type FileWithEmbeddedOwner = Omit<ApiTypes['file'], 'owner'> & { owner: ApiTypes['account'] }
        return this.client.get<FileWithEmbeddedOwner>(`files/${id}`, { params: { embed: 'owner' } })
    }

    getMyPendingProjects = async () => {
        return this.client.get<ApiTypes['file'][]>('/files/mypending')
    }

    getMyShareableProjects = async (type: 'normal' | 'mini-quiz') => {
        const params: Record<string, any> = {
            owner: this.getUserId(),
            'where[kind][in]': 'project/mix,project/blocks',
            deleted: false,
            sort: '-updatedAt',
            shared: false,
        }

        if (type === 'mini-quiz') {
            params['where[tags][elemMatch]'] = { $eq: MINI_QUIZ_PROJECT_TAG, $ne: CLASSROOM_PROJECT_TAG }
        } else {
            params['where[tags][nin]'] = `${CLASSROOM_PROJECT_TAG},${MINI_QUIZ_PROJECT_TAG}`
        }

        return this.client.get<ApiTypes['file'][]>('/files', {
            params,
        })
    }

    updateProjectData = async (id: string, title: string, details: string, shareSpecial?: string) => {
        const res = await api.projects.getProjectData(id, 'normal')
        const newProjectData = {
            ...res.data,
            details,
            title,
            shareSpecial,
        }
        const blob = new Blob([JSON.stringify(newProjectData)], {
            type: 'application/json',
        })
        const formData = new FormData()
        formData.append('id', id)
        formData.append('file', blob)
        return this.client.post(`/files/replace`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    updateProjectDetails = async (id: string, details: string, variant: ProjectVariant) => {
        const res = await api.projects.getProjectData(id, variant)
        const newProjectData = {
            ...res.data,
            details,
        }
        const blob = new Blob([JSON.stringify(newProjectData)], {
            type: 'application/json',
        })
        const formData = new FormData()
        formData.append('id', id)
        formData.append('file', blob)
        return this.client.post(`/files/replace`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    }

    updateMetadata = async (id: string, metadata: Pick<ApiTypes['file'], 'metadata'>) => {
        return this.client.patch(`/files/${id}`, metadata)
    }

    moveToProduction = async (id: string) => this.client.post(`/files/${id}/move-to-production`)

    updateProjectThumbnail = async (id: string, base64image: string) => {
        const file = await fetch(base64image)
        const blob = await file.blob()
        const formData = new FormData()
        formData.append('file', blob)
        const res = await this.client.put(`/files/${id}/thumbnail`, formData)
        cacheBustThumbnail(id)
        return res
    }

    shareProject = async (id: string) => {
        return this.client.post(`/files/${id}/share`)
    }

    unshareProject = async (id: string) => {
        return this.client.delete(`/files/${id}/share`)
    }

    getMyProjects = async (query: MyProjectsQuery) => {
        const params: Record<string, any> = {
            skip: query.skip,
            owner: this.getUserId(),
            limit: query.limit,
            sort: query.sort,
        }

        if (query.palette) {
            params['metadata.palette'] = query.palette
        }

        if (query.language) {
            params['metadata.language'] = query.language;
            params['where[kind][eq]'] = query.kind === 'blox' ? 'invalid' : 'project/mix'
        }
        else {
            if (query.kind === 'blox') {
                params['where[kind][eq]'] = 'project/blocks'
            } else if (query.kind === 'mix') {
                params['where[kind][eq]'] = 'project/mix'
            } else {
                params['where[kind][in]'] = 'project/mix,project/blocks'
            }
        }

        if (query.view === 'deleted') {
            params.sort = '-deletedAt'
            params['where[deletedAt][gte]'] = dayjs().subtract(30, 'days').format()
        }

        if (query.search) {
            params['where[name][like]'] = query.search
        }

        if (query.view === 'all' || query.view === 'deleted' || query.view === 'favorites') {
            params['where[tags][ne]'] = MINI_QUIZ_PROJECT_TAG
        }

        params.deleted = query.view === 'deleted';

        if (query.view === 'favorites') {
            params.favorite = true
        }

        if (query.view === 'community') {
            params['where[tags][nin]'] = `${CLASSROOM_PROJECT_TAG},${MINI_QUIZ_PROJECT_TAG}`
        }
        if (query.view === 'classroom') {
            params['where[tags][elemMatch]'] = { $eq: CLASSROOM_PROJECT_TAG, $ne: MINI_QUIZ_PROJECT_TAG }
        }

        if (query.labels && query.labels.length > 0) {
            params['where[labels][elemMatch]'] = { $or: query.labels.map(serializeProjectLabel) }
        }

        const res = await this.client.get<ApiTypes['file'][]>(`/files`, {
            params,
        })

        return {
            response: res,
            nextSkip: res.data.length < query.limit ? null : query.skip + query.limit,
            totalCount: parseInt(res.headers['x-total-count'], 10),
        }
    }

    getSharedProjects = async (query: GetSharedProjectsQuery) => {
        const params: Record<string, any> = {
            limit: query.limit ?? 100,
            deleted: false,
            shared: true,
            skip: query.skip,
            pattern: query.pattern,
            owner: query.owner,
            sort: query.sort,
            'where[kind][in]': 'project/blocks,project/mix',
            access_token: '' // no need of token for this endpoint
        }

        if (query.ids) {
            if (query.ids.length) {
                params['where[id][in]'] = query.ids.join()
            } else {
                // if ids array is empty let's just send a fake axios response
                // because the api can't handle that
                const response: AxiosResponse<FilterSharedByOwnerOrTitleResponse> = {
                    data: [],
                    headers: {
                        'x-total-count': 0,
                    },
                    status: 200,
                    statusText: '',
                    config: {},
                }
                return response
            }
        }

        if (query.languages) {
            params['where[lang][in]'] = query.languages.join()
        }

        if (query.type === 'mini-quiz') {
            params['tags'] = MINI_QUIZ_PROJECT_TAG
        } else if (query.type === 'normal') {
            params['where[tags][ne]'] = MINI_QUIZ_PROJECT_TAG
        }

        if (query.view === 'only-trainers') {
            params['where[owner_primaryKind][ne]'] = 'student'
        }

        return this.client.get<FilterSharedByOwnerOrTitleResponse>('/files/filter-shared-by-owner-or-title', {
            params,
        })
    }

    getRecommendedProjects = (type: 'coding' | 'mini-quiz', languages: string[]) => {
        return this.client.get<FilterSharedByOwnerOrTitleResponse>('/files/recommended-projects', {
            params: {
                type,
                languages: languages.join(','),
            },
        })
    }

    getMyFavoriteProjects = () => {
        return this.client.get<Pick<ApiTypes['like'], 'file'>[]>('/likes', {
            params: {
                user: this.getUserId(),
                fields: 'file',
            },
        })
    }

    getProjectsForDisovery = async (query: DiscoverProjectsQuery) => {
        const { days, sort, ...getSharedProjectsQuery } = query
        const normalizedSort = (() => {
            if (query.sort === '-daysViewedCount') {
                if (query.days < 0) {
                    return '-viewedCount'
                }
                return `${query.sort}_${days}`
            }
            return query.sort
        })()

        const res = await this.getSharedProjects({
            sort: `${normalizedSort},-id`,
            ...getSharedProjectsQuery,
        })

        return {
            response: res,
            nextSkip: res.data.length < query.limit ? null : query.skip + query.limit,
            totalCount: parseInt(res.headers['x-total-count'], 10),
        }
    }

    getMyViewedFiles = async (files: string[]) => {
        const res = await this.client.get<ApiTypes['fileview'][]>('/fileviews/my', {
            params: {
                'where[file][in]': files.join(','),
                fields: 'file',
            },
        })
        const normalizeViewedFiles = (viewedFiles: ApiTypes['fileview'][]) =>
            Array.from(new Set(viewedFiles.map(({ file }) => file)))

        return normalizeViewedFiles(res.data)
    }

    getCommunityProjectDetails = async (id: string, variant: ProjectVariant) => {
        const [fileData, cookied, favorited] = await Promise.all([
            this.getProjectData(id, variant),
            this.isProjectCookied(id),
            this.isProjectFavorited(id),
        ])
        return {
            fileData,
            cookied,
            favorited,
        }
    }

    getClassromProjectDetails = async (id: string) => {
        const fileData = await this.getProjectData(id, 'normal')
        return {
            fileData,
        }
    }

    isProjectFavorited = async (id: string) => {
        const res = await this.client.get<any[]>('likes', {
            params: {
                file: id,
                fields: 'id',
                limit: 1,
                user: this.getUserId(),
            },
        })

        return res.data.length > 0
    }

    private isProjectCookied = async (id: string) => {
        const res = await this.client.get<any[]>('cookies/my', {
            params: {
                file: id,
                fields: 'id,currency',
            },
        })
        const normal = !!res.data.find(cookie => cookie.currency === 'cookie')
        const golden = !!res.data.find(cookie => cookie.currency === 'golden-cookie')

        return { normal, golden }
    }

    getSharedProjectsOfUser = (owner: string) => {
        return this.getSharedProjects({
            owner,
            type: 'both',
        })
    }

    private getProjectData = (id: string, variant: ProjectVariant) => {
        let url = `files/${id}/data`

        if (variant !== 'normal') {
            url = url + `?variant=${variant}`
        }

        return this.client.get<{ details?: string }>(url, { params: removeTokenFromParams() })
    }

    deleteProject = (id: string) => {
        return this.client.patch(`files/${id}`, {
            deleted: true,
            deletedAt: dayjs().format(),
        })
    }

    restoreProject = (id: string) => {
        return this.client.patch(`files/${id}`, {
            deleted: false,
            deletedAt: null,
        })
    }

    renameProject = (id: string, field: 'name' | 'title', value: string) => {
        return this.client.patch(`files/${id}`, {
            [field]: value,
        })
    }

    favoriteOwnProject(id: string) {
        return this.client.patch(`files/${id}`, {
            favorite: true,
        })
    }

    unFavoriteOwnProject(id: string) {
        return this.client.patch(`files/${id}`, {
            favorite: false,
        })
    }

    updateProjectLang = (id: string, lang: string) => {
        return this.client.patch(`files/${id}`, {
            lang,
        })
    }

    duplicateProject = async (sourceId: string, name?: string) => {
        return this.client.post<ApiTypes['file']>('/files/create-project', {
            template: sourceId,
            name,
            createMode: 'own-project-clone',
        })
    }

    isProjectNameUnique = async (name: string) => {
        const { data } = await this.client.get(`/files`, {
            params: {
                owner: this.getUserId(),
                'where[kind][in]': 'project/mix,project/blocks',
                deleted: false,
                name,
                fields: 'name',
            },
        })
        return data.length === 0
    }

    isProductionUserProjectsNameUnique = async (name: string) => {
        const { data } = await this.client.get(`/files`, {
            params: {
                owner: '56c337e4ebf655db226bebe1',
                'where[kind][in]': 'project/mix,project/blocks',
                deleted: false,
                name,
                fields: 'name',
            },
        })
        return data.length === 0
    }

    incrementProjectViewCount = (projectId: string, isPublic?: boolean) => {
        return this.client.post(`files/${projectId}/notifyviewed`, { isPublic })
    }

    getMyActiveIncorrectlyTaggedModerationReports = () => {
        return this.client.get<ApiTypes['moderation-report'][]>(`moderation-reports/my`, {
            params: {
                reason: 'incorrectly-tagged',
                'where[status][in]': 'created,in-review',
            },
        })
    }

    getMyCustomProjectLabels = async () => {
        const labels = await this.client.get<ProjectLabel[]>(`files/my-project-labels`)
        return labels.data.filter(isCustomProjectLabel).map(deserializeProjectLabel) ?? []
    }

    updateProjectLabels = (id: string, labels: ProjectLabel[]) => {
        return this.client.patch(`files/${id}`, {
            labels,
        })
    }
}

interface GetSharedProjectsQuery {
    sort?: string
    skip?: number
    pattern?: string
    limit?: number
    languages?: string[]
    owner?: string
    ids?: string[]
    view?: View
    type: 'normal' | 'mini-quiz' | 'both'
}

export interface DiscoverProjectsQuery {
    sort: string
    skip: number
    pattern: string
    limit: number
    languages: string[]
    days: number
    ids?: string[]
    view?: View
    type: 'normal' | 'mini-quiz'
}
export interface MyProjectsQuery {
    skip: number
    limit: number
    language: '' | ProjectLanguage
    view: 'all' | 'favorites' | 'deleted' | 'community' | 'classroom'
    sort: 'name' | '-name' | 'createdAt' | '-createdAt' | 'updatedAt' | '-updatedAt'
    search?: string
    kind?: '' | 'mix' | 'blox'
    palette?: string
    labels?: string[]
}

export type FilterSharedByOwnerOrTitleResponse = (ApiTypes['file'] & {
    owner_alias?: string
    owner_profileImage?: string
    owner_primaryKind?: PrimaryKind
    owner_username: string
    cookied: boolean
    goldenCookied: boolean
})[]

import { Card, CardContent } from 'components/card'
import { LockIcon } from 'components/icons'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { createProjectThumbnailUrl } from 'features/projects'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import styled from 'styled-components/macro'
import { AskForOpenDayButton } from './AskForOpenDayButton'

interface LimitedProjectModalContentProps {
    id: string
}

export const LimitedProjectModalContent: React.FC<LimitedProjectModalContentProps> = ({ id }) => {
    const t = useTranslate()

    return (
        <Root>
            <Thumbnail src={createProjectThumbnailUrl(id, 'production')} alt="" />
            <Overlay>
                <CardContent>
                    <Stack spacing={6} display="flex" flexDirection="column" alignItems="center">
                        <Stack spacing={2} display="flex" flexDirection="column" alignItems="center">
                            <StyledLockIcon />
                            <Text variant="h3">{t('onboarding::limitedproject::title')}</Text>
                            <Text>{t('onboarding::limitedproject::description')} </Text>
                        </Stack>
                        <AskForOpenDayButton variant="coding" />
                    </Stack>
                </CardContent>
            </Overlay>
        </Root>
    )
}

const StyledLockIcon = styled(LockIcon)`
    font-size: ${props => props.theme.typography.h1.fontSize};
    opacity: 0.2;
`

const Overlay = styled(Card)`
    position: absolute;
    max-width: 35rem;
    text-align: center;
`

const Thumbnail = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: blur(5px) brightness(0.7);
`

const Root = styled.div`
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`

import React from 'react'
import styled from 'styled-components/macro'
import fallbackImg from './fallback-avatar.jpg'

export type AvatarSize = 'tiny' | 'small' | 'small-plus' | 'medium' | 'big' | 'large' | 'fullWidth'

export type AvatarProps = {
    src?: string
    size?: AvatarSize
    className?: string
}

export const Avatar: React.FC<AvatarProps> = ({ src, children, size = 'medium', ...rest }) => {
    return (
        <AvatarRoot size={size} {...rest}>
            <AvatarInner>
                <AvatarImg loading="lazy" size={size} alt="" src={src || fallbackImg} />
                {children}
            </AvatarInner>
        </AvatarRoot>
    )
}

const sizeMap: Record<AvatarSize, string> = {
    tiny: '1.75rem',
    small: '3.5rem',
    'small-plus': '5.5rem',
    medium: '6.5rem',
    big: '7.5rem',
    large: '8rem',
    fullWidth: '100%',
}

export const AvatarRoot = styled.div<{ size: AvatarSize }>`
    width: ${props => sizeMap[props.size]};
`

const AvatarInner = styled.div`
    position: relative;
    height: 0;
    padding-bottom: 100%;
`

export const AvatarImg = styled.img<{ size: AvatarSize }>`
    position: absolute;
    object-fit: contain;
    width: 100%;
    height: 100%;
    border-radius: ${props => (props.size === 'tiny' ? 5 : props.theme.shape.borderRadius)}px;
    background-color: ${props => props.theme.palette.divider};
`

interface RuntimeEnv {
    REACT_APP_API_URL: string
    REACT_APP_OAUTH_URL: string
    REACT_APP_PLAY_URL: string
    REACT_APP_SHARE_URL: string
    REACT_APP_SCOOLCODE_URL: string
    REACT_APP_SCOOLCODE_CREATE_URL: string
    REACT_APP_QUIZ_URL: string
    REACT_APP_LIVEOPS_URL: string
    REACT_APP_SITE_URL: string
    REACT_APP_CRM_URL: string
    REACT_APP_DASHBOARD_URL: string
    REACT_APP_ONBOARDING_ENABLED: string
    REACT_APP_EXTRA_SLOT_IS_ENABLED: string
    NODE_ENV: 'development' | 'production'
    REACT_APP_DEPLOY: 'dev' | 'beta' | 'prod'
    REACT_APP_OAUTH_OVERRIDE: string
}

export const runtimeEnv: RuntimeEnv = (window as any)._env_ ? (window as any)._env_ : process.env
export const isProd = runtimeEnv.REACT_APP_DEPLOY === 'prod'

interface Values {
    dev: string
    beta: string
    prod: string
}

export function createEnvironmentVariable(values: Values) {
    const DEPLOY = runtimeEnv.REACT_APP_DEPLOY as keyof Values
    return values[DEPLOY]
}

runtimeEnv.REACT_APP_OAUTH_URL = (runtimeEnv.REACT_APP_OAUTH_OVERRIDE || runtimeEnv.REACT_APP_OAUTH_URL) as string

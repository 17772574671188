import { Button } from 'components/Button'
import { Card, CardContent } from 'components/card'
import { Img } from 'components/Img'
import { Inline, Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useMyChallengeSetResults } from 'features/scoolcode-challenge/data/scoolcodeChallengeQueries'
import { getIdeUrl } from 'lib/api/helpers'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { OfcSetWithChallenges } from 'features/scoolcode-challenge/data/ScoolcodeChallengeApi'
import { Skeleton } from 'components/Skeleton'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'

interface OnlinefirstChallengeItemProps extends PoorMansSuspenseTicker {
    set: OfcSetWithChallenges
}

export const OnlinefirstChallengeCard: React.FC<OnlinefirstChallengeItemProps> = ({ set, onReady }) => {
    const url = (index: number = 1) => getIdeUrl(`/challenge-sets/${set.id}/challenges/${index}`)
    const t = useTranslate()
    const { data, status } = useMyChallengeSetResults(set.id)
    usePoorMansSuspenseTick(status !== 'loading', onReady)

    const buttons = (() => {
        if (!data) {
            return <Skeleton variant="rect" width="8rem" height="3rem" />
        }
        const lastChallenge = set.challenges[set.challenges.length - 1]
        const lastResults = data.find(
            challenge => challenge.challenge === lastChallenge.id && (challenge?.lastScore ?? 0) > 0,
        )

        if (data.length === 0) {
            return (
                <Button component="a" target="_blank" href={url()}>
                    {t('general::start')}
                </Button>
            )
        }

        if (!lastResults) {
            return (
                <Button component="a" target="_blank" href={url(data.length)}>
                    {t('general::continue')}
                </Button>
            )
        }
        return (
            <>
                <Button component={Link} to={{ pathname: `/app/coding-challenge/${set.id}/${lastResults?.project}` }}>
                    {t('general::playGame')}
                </Button>
                <Button component="a" target="_blank" href={url(set.challenges.length)}>
                    {t('general::editGame')}
                </Button>
            </>
        )
    })()

    return (
        <Card>
            <CardContent>
                <Grid>
                    <Stack display="flex" flexDirection="column">
                        <Text variant="h3">{t(`ofc::${set.internalName}::title`)}</Text>
                        <Text>{t(`ofc::${set.internalName}::description`)}</Text>
                        <Inline display="flex" alignItems="flex-end" flexBasis="1 1 auto" height="100%">
                            {buttons}
                        </Inline>
                    </Stack>
                    <MediaWrapper>
                        <Media src={set.coverUrl} />
                    </MediaWrapper>
                </Grid>
            </CardContent>
        </Card>
    )
}

const MediaWrapper = styled.div`
    border-radius: ${props => props.theme.shape.borderRadius}px;
    overflow: hidden;
`

const Media = styled(Img)`
    height: 100%;
    object-fit: cover;
`

const Grid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: ${props => props.theme.spacing(4)};
    grid-gap: ${props => props.theme.spacing(4)};
    ${props => props.theme.breakpoints.down('sm')} {
        display: flex;
        flex-direction: column;
    }
`

import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import React from 'react'
import styled from 'styled-components/macro'
import { EventDefinition } from '../types/eventTypes'
import { Link } from 'react-router-dom'
import { Theme, useMediaQuery } from '@material-ui/core'
import { useTranslate } from 'lib/i18n/useTranslate'
import {Badge} from "components/Badge";
import {ManagableEventDefinition} from "../types/eventTypesV2";

interface CurrentEventProps {
    event: EventDefinition|ManagableEventDefinition
}

export const OngoingEventBanner: React.FC<CurrentEventProps> = ({ event }) => {
    const isSmallDevice = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'), { noSsr: true })
    const t = useTranslate()

    return (
        <Root to={`/app/events/${event.slug}`}>
            <Image src={event.banner?.image} width="1600" height="400" />
            {event.hasGroupInfo && <BadgeContainer>
                <BadgeWrap>
                    <Badge>{event.groupSlug}</Badge>
                    <Badge forceNoTopMargin>Ongoing: {(event.groupCount||0)-(event.closedCount||0)} / {event.groupCount||0}</Badge>
                </BadgeWrap>
            </BadgeContainer>}
            {!!event.banner?.text && (
                <TextWrap spacing={isSmallDevice ? 2 : 4} $background={event.banner?.background}>
                    <TextInner>
                        <Title variant="h1">{t(event.banner.text)}</Title>
                    </TextInner>
                </TextWrap>
            )}
        </Root>
    )
}

const Description = styled(Text)``

const TextInner = styled.div`
    max-width: 66%;
`

const TextWrap = styled(Stack)<{ $background?: string }>`
    position: absolute;
    width: 66%;
    top: 0;
    left: 0;
    bottom: 0;
    background: ${props => props.$background ?? 'linear-gradient(to right, rgba(32, 35, 35, 0.7), transparent)'};
    padding: ${props => props.theme.spacing(6)};
    color: #fff;
`

const BadgeContainer = styled(Stack)`
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 0;
`

const BadgeWrap = styled(Stack)`
    display: flex;
    flex-shrink: 0;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 3px;
`

const Title = styled(Text)`
    font-weight: 800;
    line-height: 1.3;
`

const Image = styled.img`
    display: block;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    width: 100%;
    max-width: 100%;
    height: auto;
    background-color: ${props => props.theme.palette.divider};
`

const Root = styled(Link)`
    display: block;
    position: relative;
    overflow: hidden;
    transition: filter ${props => props.theme.transitions.duration.shorter}ms;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    text-decoration: none;
    &:hover,
    &:focus-visible {
        filter: brightness(0.9);
    }
    ${props => props.theme.breakpoints.down('md')} {
        ${TextWrap} {
            padding: ${props => props.theme.spacing(4)};
            max-width: 70%;
        }
        ${Title} {
            font-size: ${props => props.theme.typography.body2.fontSize};
        }
        ${Description} {
            font-size: ${props => props.theme.typography.body2.fontSize};
            line-height: 1.45;
        }
    }
`

import { Loader } from 'components/Loader'
import { PageContent } from 'components/page'
import React from 'react'

export const EventLoader: React.FC = () => {
    return (
        <PageContent>
            <Loader />
        </PageContent>
    )
}

import { FeedEntryWithMetada, FeedType, NewsFeeds } from '../types/feedTypes'
import { LOAD_FEED_PAGE, MARK_ALL_READ } from './feedActionTypes'

export type NewsFeedEntriesWithMetaData = Omit<NewsFeeds, 'entries'> & { entries: FeedEntryWithMetada[] }

export function loadFeedPage(payload: { data: NewsFeedEntriesWithMetaData; feedType: FeedType }) {
    return {
        type: LOAD_FEED_PAGE,
        payload,
    } as const
}

export function markAllRead(feedType: FeedType) {
    return {
        type: MARK_ALL_READ,
        payload: { feedType },
    } as const
}

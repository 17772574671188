import React from 'react'
import styled from 'styled-components/macro'
import { useProgressBarContext } from './ProgressBar'

interface ProgressBarIndicatorProps {
    className?: string
}

export const ProgressBarIndicator: React.FC<ProgressBarIndicatorProps> = ({ children, ...rest }) => {
    const { value, max } = useProgressBarContext()

    return (
        <Root style={{ width: `${(value / max) * 100}%` }} $isCompleted={value >= max} {...rest}>
            {children}
        </Root>
    )
}

const Root = styled.div<{ $isCompleted: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: ${props =>
        props.$isCompleted
            ? `${props.theme.shape.borderRadius}px`
            : `${props.theme.shape.borderRadius}px 0 0 ${props.theme.shape.borderRadius}px`};
    height: calc(100% + 1px);
    font-size: ${props => props.theme.typography.body1.fontSize};
    font-weight: 600;
    color: ${props => props.theme.palette.primary.contrastText};
    transition: width ${props => props.theme.transitions.duration.standard}ms;
    background: linear-gradient(
        ${props => props.theme.palette.primary.light} 10%,
        ${props => props.theme.palette.primary.main} 15%,
        ${props => props.theme.palette.primary.main} 90%,
        ${props => props.theme.palette.primary.dark} 95%
    );
`

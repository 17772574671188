import { useState, useRef, useCallback } from 'react'

export function useIsElementFullyHidden() {
    const [isFullyHidden, setIsFullyHidden] = useState<boolean | undefined>()
    const observer = useRef<IntersectionObserver>()
    const ref = useCallback((node: HTMLDivElement) => {
        if (!node) {
            observer.current?.disconnect()
            return
        }
        observer.current = new IntersectionObserver(entries => {
            if (entries[0]?.intersectionRatio === 0) {
                setIsFullyHidden(true)
            } else {
                setIsFullyHidden(false)
            }
        })
        observer.current.observe(node)
    }, [])

    return {
        ref,
        isFullyHidden,
    }
}

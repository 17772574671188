import React from 'react'
import styled from 'styled-components/macro'
import { CheckCircleIcon, TimesCircleIcon, MinusCircleIcon } from 'components/icons'
import { Text } from 'components/Text'
import { Card, CardProps } from 'components/card'
import { useLocalisedQuizValue } from './quizHelpers'
import { lighten } from 'polished'

type Props = {
    correctIndices: number[] | undefined
    response: number | number[] | undefined
    options: { value: 'string'; locale: 'string' }[]
}

enum ABCD {
    A,
    B,
    C,
    D,
}

export const ChoiceResponse: React.FC<Props> = ({ correctIndices, response, options }) => {
    const { translateQuizLocaleString } = useLocalisedQuizValue()

    const isResponseIncorrect = (i: number) => {
        return (
            response !== undefined &&
            ((typeof response == 'number' && response === i) ||
                (Array.isArray(response) && response.indexOf(i) !== -1)) &&
            correctIndices &&
            correctIndices.indexOf(i) === -1
        )
    }

    return (
        <StyledCardContainer>
            {options &&
                options.map((option, i) => {
                    const selectedCorrectAnswer =
                        correctIndices?.indexOf(i) !== -1 &&
                        (Array.isArray(response) ? response.indexOf(i) !== -1 : response === i)

                    const notSelectedCorrectAnswer =
                        correctIndices?.indexOf(i) !== -1 &&
                        (Array.isArray(response) ? response.indexOf(i) === -1 : response !== i)

                    return (
                        <StyledCard
                            elevation={0}
                            $isIncorrect={isResponseIncorrect(i)}
                            $selectedCorrectAnswer={selectedCorrectAnswer}
                            $notSelectedCorrectAnswer={notSelectedCorrectAnswer}
                            key={i}
                        >
                            <StyledCardContent>
                                <Option variant="body1">
                                    <strong>{ABCD[i] + '. '}</strong>
                                    {translateQuizLocaleString(option as any)}
                                </Option>
                                <IconContainer>
                                    {selectedCorrectAnswer && <CheckCircleIcon />}
                                    {notSelectedCorrectAnswer && <MinusCircleIcon />}
                                    {isResponseIncorrect(i) && <TimesCircleIcon />}
                                </IconContainer>
                            </StyledCardContent>
                        </StyledCard>
                    )
                })}
        </StyledCardContainer>
    )
}

const IconContainer = styled.span`
    line-height: 1;
`

const Option = styled(Text)`
    padding-inline-end: ${props => props.theme.spacing(4)};
`

type StyledCardProps = CardProps & {
    $isIncorrect?: boolean
    $selectedCorrectAnswer?: boolean
    $notSelectedCorrectAnswer?: boolean
}

export const StyledCard = styled(({ $isIncorrect, $selectedCorrectAnswer, ...props }: StyledCardProps) => (
    <Card {...props} />
))`
    background: ${props => {
        if (props.$selectedCorrectAnswer) return '#43a047'
        if (props.$isIncorrect) return '#f44336'
        if (props.$notSelectedCorrectAnswer) return lighten(0.15, '#43a047')

        return undefined
    }};
    border: none;
    color: ${props =>
        props.$selectedCorrectAnswer || props.$isIncorrect || props.$notSelectedCorrectAnswer ? '#FFF' : undefined};
`

export const StyledCardContent = styled.div`
    padding: ${props => props.theme.spacing(2, 3)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`

export const StyledCardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${props => props.theme.spacing(4)};
    ${props => props.theme.breakpoints.down('xs')} {
        grid-template-columns: 1fr;
    }
`

import { Button } from 'components/Button'
import { Card, CardContent } from 'components/card'
import { Box, Inline, Stack } from 'components/layout'
import { Text } from 'components/Text'
import { Loader } from 'components/Loader'
import { ForwardIcon } from 'components/icons'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { api } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { useQuery } from 'react-query'
import styled, { css } from 'styled-components/macro'
import { useDateFormat } from 'hooks/useDateFormat'
import { darken } from 'polished'
import { NBSP } from 'utils/styleUtils'
import surveyImage from '../media/survey-icon.svg'
import { useUserState, isTrainerUser } from 'contexts/UserContext'

interface SurveyListProps extends PoorMansSuspenseTicker {}

export const SurveyList: React.FC<SurveyListProps> = ({ onReady }) => {
    const t = useTranslate()
    const { formatDate } = useDateFormat('short')
    const user = useUserState()
    const isTrainer = isTrainerUser(user)

    const { data: surveys, status } = useQuery('surveys', () => api.surveys.getUserSurveys(), {
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: 'always',
    })
    usePoorMansSuspenseTick(status !== 'loading', onReady)

    if (status === 'error') {
        return null
    }

    if (!surveys) {
        return <Loader />
    }

    if (surveys.length === 0) {
        return null
    }

    const hasOnlyOneSurvey = surveys.length === 1

    return (
        <Box maxWidth={800} marginLeft="auto" marginRight="auto">
            <StyledCard>
                <CardContent>
                    <Grid spacing={hasOnlyOneSurvey ? 0 : 4} $hasOnlyOneSurvey={hasOnlyOneSurvey}>
                        <Inner spacing={5}>
                            <Image src={surveyImage}></Image>
                            <Label>
                                {t(
                                    isTrainer
                                        ? 'survey::surveysTodayDescription'
                                        : 'survey::surveysTodayDescriptionStudent2',
                                )}
                            </Label>
                        </Inner>
                        <ButtonGrid $hasOnlyOneSurvey={hasOnlyOneSurvey}>
                            {surveys.map(({ groupName, url, subjectName, startDate, endDate }) => (
                                <StyledButton
                                    key={url}
                                    component="a"
                                    fullWidth
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    endIcon={<ForwardIcon />}
                                >
                                    {t(isTrainer ? 'survey::openDetailedDates' : 'survey::openStudentType', {
                                        groupName,
                                        startDate: formatDate(new Date(startDate)).replaceAll(' ', NBSP),
                                        endDate: formatDate(new Date(endDate)).replaceAll(' ', NBSP),
                                        subject: t(subjectName),
                                        interpolation: { escapeValue: false },
                                    })}
                                </StyledButton>
                            ))}
                        </ButtonGrid>
                    </Grid>
                </CardContent>
            </StyledCard>
        </Box>
    )
}

const StyledCard = styled(Card)`
    overflow: visible;
    position: relative;
    color: ${props => props.theme.palette.primary.contrastText};
    background: linear-gradient(
        45deg,
        ${props => props.theme.palette.primary.main},
        ${props => darken(0.15, props.theme.palette.primary.main)}
    );
`

const Grid = styled(Stack)<{ $hasOnlyOneSurvey: boolean }>`
    ${props =>
        props.$hasOnlyOneSurvey &&
        css`
            display: grid;
            grid-template-columns: 1fr 14rem;
            align-items: center;
            grid-gap: ${props.theme.spacing(4)};
            ${props.theme.breakpoints.down('sm')} {
                grid-template-columns: auto;
            }
        `}
`

const ButtonGrid = styled.div<{ $hasOnlyOneSurvey: boolean }>`
    ${props =>
        !props.$hasOnlyOneSurvey &&
        css`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: ${props.theme.spacing(4)};
            ${props.theme.breakpoints.down('sm')} {
                grid-template-columns: auto;
            }
        `}
`

const StyledButton = styled(Button)`
    background: #fff;
    color: #000;
    text-align: center;
    line-height: 1.5;
    &:hover,
    &:focus-visible {
        background: #fff;
        filter: brightness(0.9);
    }
` as typeof Button

const Image = styled.img`
    position: relative;
    left: -1.65rem;
    top: -1.75rem;
    width: 5.75rem;
    margin-right: -1.65rem;
    margin-bottom: -1.75rem;
    flex-shrink: 0;
    filter: drop-shadow(2px 4px 2px rgba(0, 0, 0, 0.1));
    ${props => props.theme.breakpoints.down('xs')} {
        width: 5rem;
        left: -1.5rem;
        margin-right: -1.5rem;
    }
`

const Label = styled(Text)`
    font-weight: 600;
`

const Inner = styled(Inline)`
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
`

import { Box } from 'components/layout'
import { Loader } from 'components/Loader'
import { Panel, PanelTitle } from 'components/panel'
import { useUserState } from 'contexts/UserContext'
import { PoorMansSuspenseTicker, usePoorMansSuspenseTick } from 'hooks/usePoorMansSuspense'
import { api } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'
import { MeetingItem } from './MeetingItem'

interface MeetingListProps extends PoorMansSuspenseTicker {}

export const MeetingList: React.FC<MeetingListProps> = ({ onReady }) => {
    const t = useTranslate();
    const user = useUserState();
    const { data: meetings, status } = useQuery('meetings', () => api.meetings.getTodaysMeetings(user.account.primaryKind), {
        staleTime: 5 * 60 * 1000,
    })
    usePoorMansSuspenseTick(status !== 'loading', onReady)

    if (status === 'error') {
        return null
    }

    if (!meetings) {
        return <Loader />
    }

    if (meetings.length === 0) {
        return null
    }

    return (
        <Box maxWidth={600} marginLeft="auto" marginRight="auto">
            <Panel>
                <PanelTitle>{t('meeting::meetingsToday')}</PanelTitle>
                <div>
                    <Grid>
                        {meetings.map(meeting => (
                            <MeetingItem meeting={meeting} />
                        ))}
                    </Grid>
                </div>
            </Panel>
        </Box>
    )
}

const Grid = styled.div`
    display: grid;
    grid-gap: ${props => props.theme.spacing(3)};
`

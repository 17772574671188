import { Button, DeleteButton } from 'components/Button'
import { Collapse } from 'components/Collapse'
import { Confirm, ConfirmDialogContext } from 'components/Confirm'
import {
    DialogSecondaryButton,
    DialogForm,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogPrimaryButton,
} from 'components/dialog'
import { ErrorMessage } from 'components/ErrorMessage'
import { TextField } from 'components/form'
import {
    ShareIcon,
    RenameIcon,
    DuplicateIcon,
    DeleteIcon,
    RefreshIcon,
    EditIcon,
    MoveProductionIcon,
} from 'components/icons'
import { Stack } from 'components/layout'
import { ListItemIcon } from 'components/list'
import { Loader } from 'components/Loader'
import { PopupMenu, PopupMenuList, PopupMenuItem } from 'components/popup-menu'
import { PopupMenuDivider } from 'components/popup-menu/PopupMenuDivider'
import { Prompt, PromptActionResponse, PromptDialogContext } from 'components/Prompt'
import { Text } from 'components/Text'
import { useUserState } from 'contexts/UserContext'
import { isMiniQuizLongEnoughToShare, isMiniQuizProject } from 'features/mini-quizzes/helpers/miniQuizHelpers'
import { useNotifications } from 'features/notifications'
import { useIncrementActivityScore } from 'hooks/useIncrementActivityScore'
import { getIdeUrl } from 'lib/api/helpers'
import { LocalisationKey } from 'lib/i18n'
import { useTranslate } from 'lib/i18n/useTranslate'
import { reportProject } from 'lib/liveops/actions/reportProject'
import { useSelector } from 'lib/store'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getProjectActions, projectNameRules } from '.'
import {
    getProjectEditLink,
    getProjectNameValidationMessage,
    PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR,
} from './projectHelpers'
import { useMyActiveIncorrectyTaggedModerationReportsQuery } from './projectQueries'
import { Project } from './store'
import { useProjectActions } from './useProjectActions'

interface ProjectActionsProps {
    id: string
    popupMenuButton?: React.ReactNode
    onDelete?: VoidFunction
    onDuplicate?: VoidFunction
    isPending?: boolean
    isInContextMenu?: boolean
    location: 'card' | 'stripe' | 'details'
}

export const ProjectActionsMenu: React.FC<ProjectActionsProps> = ({
    id,
    popupMenuButton,
    onDelete,
    onDuplicate,
    isPending,
    isInContextMenu = false,
    location,
}) => {
    const t = useTranslate()
    const projectActions = useProjectActions()
    const project = useSelector(state => state.projects.all[id]) as Project | undefined
    const { notify } = useNotifications()
    // TODO: is there a performance problem with this when rendering many project cards?
    // if there is, user should be passed as prop
    const user = useUserState()
    const incrementActivityScore = useIncrementActivityScore()
    const dispatch = useDispatch()
    const history = useHistory()

    const deleteProject = async () => {
        await projectActions.deleteProject(id)
        onDelete?.()
    }

    const unshareProject = async () => {
        await projectActions.unshareProject(id)
    }

    const renameProject = async (name: string): Promise<PromptActionResponse> => {
        const renameResponse = await projectActions.renameProject(id, 'name', name)
        if (renameResponse.success) {
            return {
                success: true,
            }
        }
        return {
            success: false,
            error: { type: PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR },
        }
    }

    const unshareNominatedProject = async () => {
        await projectActions.unshareNominatedProject(id)
    }

    const moveToProduction = async (): Promise<PromptActionResponse> => {
        const response = await projectActions.moveToProduction(project)

        if (response.success) {
            notify(t('projects::movedToProduction'), { variant: 'success' })
            return {
                success: true,
            }
        }
        return {
            success: false,
            error: { type: PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR },
        }
    }

    const duplicateProject = async (name: string): Promise<PromptActionResponse> => {
        const cloneResponse = await projectActions.duplicateProject(id, name)
        if (cloneResponse.success) {
            if (isMiniQuizProject(cloneResponse.project.data)) {
                history.push(`/app/quiz-editor/${cloneResponse.project.data.id}`)
            } else {
                window.open(getIdeUrl(`/ide/${cloneResponse.project.data.id}`))
            }
            onDuplicate?.()
            return {
                success: true,
            }
        }
        return {
            success: false,
            error: { type: PROJECT_NAME_NOT_UNIQUE_FIELD_ERROR },
        }
    }

    const reportIncorrectlyTaggedProject = () => {
        dispatch(reportProject(id, 'incorrectly-tagged'))
    }

    if (!project) {
        return null
    }

    const actions = getProjectActions(project, isPending, user)

    const MenuType = isInContextMenu ? 'div' : PopupMenuList

    const editLink = getProjectEditLink(project, user)

    return (
        <PopupMenu>
            {popupMenuButton}
            {actions ? (
                <MenuType>
                    {location !== 'details' && actions.has('edit') && (
                        <>
                            {editLink.type === 'external' ? (
                                <PopupMenuItem
                                    component="a"
                                    target="_blank"
                                    href={editLink.href}
                                    onClick={() => {
                                        incrementActivityScore('classroom:owned-project:open')
                                        return true
                                    }}
                                >
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    {t('general::edit')}
                                </PopupMenuItem>
                            ) : (
                                <PopupMenuItem
                                    component={Link}
                                    to={editLink.to}
                                    onClick={() => {
                                        incrementActivityScore('classroom:owned-project:open')
                                        return true
                                    }}
                                >
                                    <ListItemIcon>
                                        <EditIcon />
                                    </ListItemIcon>
                                    {t('general::edit')}
                                </PopupMenuItem>
                            )}
                        </>
                    )}
                    {actions.has('rename') && (
                        <Prompt
                            action={renameProject}
                            dialog={props => <ProjectPromptDialog {...props} variant="rename" />}
                            initialValue={project.file.name}
                            rules={projectNameRules}
                        >
                            {(promptRename, rest) => (
                                <PopupMenuItem onClick={promptRename} {...rest}>
                                    <ListItemIcon>
                                        <RenameIcon />
                                    </ListItemIcon>
                                    {t('general::rename')}
                                </PopupMenuItem>
                            )}
                        </Prompt>
                    )}
                    {actions.has('duplicate') && (
                        <Prompt
                            action={duplicateProject}
                            dialog={props => <ProjectPromptDialog variant="clone" {...props} />}
                            initialValue={project.file.name + ' clone'}
                            rules={projectNameRules}
                        >
                            {(promptRename, rest) => (
                                <PopupMenuItem onClick={promptRename} {...rest}>
                                    <ListItemIcon>
                                        <DuplicateIcon />
                                    </ListItemIcon>
                                    {t('general::duplicate')}
                                </PopupMenuItem>
                            )}
                        </Prompt>
                    )}
                    {actions.has('delete') && (
                        <Confirm
                            action={deleteProject}
                            dialog={props => (
                                <ProjectConfirmDialog projectName={project.file.name} variant="delete" {...props} />
                            )}
                        >
                            {(openDeleteDialog, rest) => {
                                return (
                                    <PopupMenuItem onClick={openDeleteDialog} {...rest}>
                                        <ListItemIcon>
                                            <DeleteIcon />
                                        </ListItemIcon>
                                        {t('general::delete')}
                                    </PopupMenuItem>
                                )
                            }}
                        </Confirm>
                    )}
                    {actions.has('move-to-production') && (
                        <Prompt
                            action={moveToProduction}
                            dialog={props => <ProjectPromptDialog {...props} variant="move-production" />}
                            initialValue={project.file.name}
                            rules={projectNameRules}
                        >
                            {(moveToProd, rest) => (
                                <PopupMenuItem onClick={moveToProd} {...rest}>
                                    <ListItemIcon>
                                        <MoveProductionIcon />
                                    </ListItemIcon>
                                    {t('projects::moveToProduction')}
                                </PopupMenuItem>
                            )}
                        </Prompt>
                    )}
                    <SecondaryActionsWrap>
                        {actions.has('share-new-version') && (
                            <PopupMenuItem
                                disabledTooltip={
                                    project.metadata.isMiniQuiz && !isMiniQuizLongEnoughToShare(project)
                                        ? t('miniQuiz::errors::atLeastFiveQuestions')
                                        : undefined
                                }
                                component={Link}
                                to={{ pathname: `/app/share-project/${project.file.id}` }}
                            >
                                <ListItemIcon>
                                    <RefreshIcon />
                                </ListItemIcon>
                                {t('projects::shareNewVersion')}
                            </PopupMenuItem>
                        )}
                        {actions.has('share-new-version-contest') && (
                            <PopupMenuItem
                                component={Link}
                                to={{
                                    pathname: `/app/events/trainer-contest-2021/nominate/share-contest-project/${project.file.id}`,
                                }}
                            >
                                <ListItemIcon>
                                    <RefreshIcon />
                                </ListItemIcon>
                                {t('projects::shareNewVersion')}
                            </PopupMenuItem>
                        )}
                        {actions.has('unshare') && (
                            <Confirm
                                action={unshareProject}
                                dialog={props => (
                                    <ProjectConfirmDialog
                                        projectName={project.file.name}
                                        variant="unshare"
                                        {...props}
                                    />
                                )}
                            >
                                {(openDeleteDialog, rest) => {
                                    return (
                                        <PopupMenuItem onClick={openDeleteDialog} {...rest}>
                                            <ListItemIcon>
                                                <ShareIcon />
                                            </ListItemIcon>
                                            {t('projects::unshare')}
                                        </PopupMenuItem>
                                    )
                                }}
                            </Confirm>
                        )}
                        {actions.has('unshare-nominated') && (
                            <Confirm
                                action={unshareNominatedProject}
                                dialog={props => (
                                    <ProjectUnnominateDialog
                                        projectName={project.file.name}
                                        variant="unnominate"
                                        {...props}
                                    />
                                )}
                            >
                                {(openDeleteDialog, rest) => {
                                    return (
                                        <PopupMenuItem onClick={openDeleteDialog} {...rest}>
                                            <ListItemIcon>
                                                <ShareIcon />
                                            </ListItemIcon>
                                            {t('projects::unshareNominated')}
                                        </PopupMenuItem>
                                    )
                                }}
                            </Confirm>
                        )}
                        {actions.has('share') && (
                            <PopupMenuItem
                                disabledTooltip={
                                    project.metadata.isMiniQuiz && !isMiniQuizLongEnoughToShare(project)
                                        ? t('miniQuiz::errors::atLeastFiveQuestions')
                                        : undefined
                                }
                                component={Link}
                                to={{ pathname: `/app/share-project/${project.file.id}` }}
                            >
                                <ListItemIcon>
                                    <ShareIcon />
                                </ListItemIcon>
                                {t('projects::share')}
                            </PopupMenuItem>
                        )}
                        {actions.has('report-incorrect-tag') && (
                            <Confirm
                                dialogProps={{ fullWidth: true }}
                                action={reportIncorrectlyTaggedProject}
                                keepOpenAfterSuccess
                                dialog={props => <IncorrectlyTaggedDialog projectId={id} {...props} />}
                            >
                                {(openDialog, rest) => {
                                    return (
                                        <>
                                            <PopupMenuItem onClick={openDialog} {...rest}>
                                                <ListItemIcon>
                                                    <ShareIcon />
                                                </ListItemIcon>
                                                {t('projects::share')}
                                            </PopupMenuItem>
                                        </>
                                    )
                                }}
                            </Confirm>
                        )}
                    </SecondaryActionsWrap>
                </MenuType>
            ) : (
                <PopupMenuList>
                    <Loader size="1rem" />
                </PopupMenuList>
            )}
        </PopupMenu>
    )
}

const ProjectConfirmDialog: React.FC<ConfirmDialogContext & { variant: 'delete' | 'unshare'; projectName: string }> = ({
    status,
    positiveButtonProps,
    negativeButtonProps,
    variant,
    projectName,
}) => {
    const t = useTranslate()

    return (
        <>
            <DialogTitle>{variant === 'delete' ? t('projects::delete') : t('projects::unshareProject')}</DialogTitle>
            <DialogContent>
                <Stack>
                    <Text>
                        {variant === 'delete'
                            ? t('projects::deleteConfirm', { name: projectName })
                            : t('projects::unshareConfirm')}
                    </Text>
                    <Collapse in={status === 'error'}>
                        <ErrorMessage />
                    </Collapse>
                </Stack>
            </DialogContent>
            <DialogActions>
                <DialogSecondaryButton {...negativeButtonProps}>{t('general::cancel')}</DialogSecondaryButton>
                <DeleteButton
                    data-testid="project-confirm-positive-button"
                    color="default"
                    startIcon={variant === 'delete' ? <DeleteIcon /> : undefined}
                    {...positiveButtonProps}
                >
                    {variant === 'delete' ? t('general::delete') : t('projects::unshare')}
                </DeleteButton>
            </DialogActions>
        </>
    )
}

const SecondaryActionsWrap: React.FC = ({ children }) => {
    const isEmpty = React.Children.toArray(children).every(child => !child)

    if (isEmpty) {
        return null
    }

    return (
        <>
            <PopupMenuDivider />
            {children}
        </>
    )
}

const IncorrectlyTaggedDialog: React.FC<ConfirmDialogContext & { projectId: string }> = ({
    status,
    positiveButtonProps,
    negativeButtonProps,
    projectId,
}) => {
    const t = useTranslate()
    const isProjectAlreadyReported = useIsProjectAlreadyReportedForIncorrectTagging(projectId)

    const body = (() => {
        if (isProjectAlreadyReported.status === 'error') {
            return <ErrorMessage />
        }

        if (isProjectAlreadyReported.status === 'loading') {
            return <Loader />
        }

        if (isProjectAlreadyReported.value === true) {
            return <Text>{t('classroomShareDialog::alreadyReported')}</Text>
        }

        if (status === 'success') {
            return <Text>{t('classroomShareDialog::success1')}</Text>
        }

        return (
            <Stack>
                <Text>
                    <strong>{t('classroomShareDialog::description1')}</strong>
                </Text>
                <Text>{t('classroomShareDialog::description3')}</Text>
                <Button variant="text" {...positiveButtonProps}>
                    {t('classroomShareDialog::action1')}
                </Button>
                <Collapse in={status === 'error'}>
                    <ErrorMessage />
                </Collapse>
            </Stack>
        )
    })()

    return (
        <>
            <DialogTitle>{t('projects::shareProject')}</DialogTitle>
            <DialogContent>{body}</DialogContent>
            <DialogActions>
                <DialogSecondaryButton {...negativeButtonProps}>{t('general::close')}</DialogSecondaryButton>
            </DialogActions>
        </>
    )
}

const ProjectUnnominateDialog: React.FC<ConfirmDialogContext & { variant: 'unnominate'; projectName: string }> = ({
    status,
    positiveButtonProps,
    negativeButtonProps,
}) => {
    const t = useTranslate()

    return (
        <>
            <DialogTitle>{t('projects::unshareNominated')}</DialogTitle>
            <DialogContent>
                <Stack>
                    <Text>{t('projects::confirmunshareNominated')}</Text>
                    <Collapse in={status === 'error'}>
                        <ErrorMessage />
                    </Collapse>
                </Stack>
            </DialogContent>
            <DialogActions>
                <DialogSecondaryButton {...negativeButtonProps}>{t('general::cancel')}</DialogSecondaryButton>
                <DeleteButton data-testid="project-confirm-positive-button" color="default" {...positiveButtonProps}>
                    {t('projects::unshareNominated')}
                </DeleteButton>
            </DialogActions>
        </>
    )
}

type ProjectPromptDialogVariant = 'rename' | 'clone' | 'move-production'

const ProjectPromptDialog: React.FC<PromptDialogContext & { variant: ProjectPromptDialogVariant }> = ({
    status,
    positiveButtonProps,
    negativeButtonProps,
    formProps,
    inputProps,
    variant,
    error,
}) => {
    const t = useTranslate()

    const title: Record<ProjectPromptDialogVariant, LocalisationKey | string> = {
        rename: t('projects::rename'),
        clone: t('projects::duplicate'),
        'move-production': t('projects::moveToProduction'),
    }

    const confirmBtn: Record<ProjectPromptDialogVariant, LocalisationKey | string> = {
        rename: t('general::rename'),
        clone: t('general::duplicate'),
        'move-production': t('general::move'),
    }

    return (
        <>
            <DialogTitle>{title[variant]}</DialogTitle>
            <DialogForm
                {...formProps}
                actions={
                    <>
                        <DialogSecondaryButton {...negativeButtonProps}>{t('general::cancel')}</DialogSecondaryButton>
                        <DialogPrimaryButton {...positiveButtonProps}>{confirmBtn[variant]}</DialogPrimaryButton>
                    </>
                }
            >
                <Stack>
                    {variant !== 'move-production' && (
                        <TextField {...inputProps} errorText={getProjectNameValidationMessage(error, t)} />
                    )}
                    <Collapse in={status === 'error'}>
                        <ErrorMessage />
                    </Collapse>
                </Stack>
            </DialogForm>
        </>
    )
}

const useIsProjectAlreadyReportedForIncorrectTagging = (projectId: string) => {
    const { data: moderationReports, status: moderationQueryStatus } =
        useMyActiveIncorrectyTaggedModerationReportsQuery()

    if (moderationQueryStatus === 'error') {
        return { status: 'error' } as const
    }

    if (!moderationReports) {
        return { status: 'loading' } as const
    }

    if (moderationReports.data.find(report => report.target === projectId)) {
        return { status: 'ready', value: true } as const
    } else {
        return { status: 'ready', value: false } as const
    }
}

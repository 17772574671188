import { Button, ButtonProps } from 'components/Button'
import { Dialog, DialogForm, DialogPrimaryButton, DialogSecondaryButton, DialogTitle } from 'components/dialog'
import { TextField } from 'components/form'
import { Stack } from 'components/layout'
import { Text } from 'components/Text'
import { useTranslate } from 'lib/i18n/useTranslate'
import illustrationImg from '../media/open-day-email.svg'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { isGuestOrRegisteredGuestUser, useUserState } from 'contexts/UserContext'
import { useForm } from 'react-hook-form'
import { api } from 'lib/api'
import { ErrorMessage } from 'components/ErrorMessage'
import { SuccessMessage } from 'components/SuccessMessage'

type AskForOpenDayButtonVariant = 'classroom' | 'general' | 'coding'

interface AskForOpenDayButtonProps {
    buttonProps?: ButtonProps
    variant?: AskForOpenDayButtonVariant
    onClose?: VoidFunction
}

export const AskForOpenDayButton: React.FC<AskForOpenDayButtonProps> = ({
    buttonProps,
    onClose,
    variant = 'general',
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const t = useTranslate()
    const user = useUserState()
    const isGuestOrRegisteredGuest = isGuestOrRegisteredGuestUser(user)

    if (!isGuestOrRegisteredGuest) {
        return null
    }

    return (
        <>
            <Button
                onClick={() => {
                    setIsOpen(true)
                }}
                {...buttonProps}
            >
                {t(titleByVariant[variant])}
            </Button>
            {isOpen && (
                <AskForOpenDayDialog
                    variant={variant}
                    onClose={() => {
                        onClose?.()
                        setIsOpen(false)
                    }}
                />
            )}
        </>
    )
}

interface AskForOpenDayDialogProps {
    onClose: VoidFunction
    variant: AskForOpenDayButtonVariant
}

const AskForOpenDayDialog: React.FC<AskForOpenDayDialogProps> = ({ onClose, variant }) => {
    const t = useTranslate()
    const { handleSubmit, register } = useForm<{ email: string }>()
    const [status, setStatus] = useState<'idle' | 'loading' | 'error' | 'success'>('idle')

    const onSubmit = handleSubmit(async ({ email }) => {
        if (status !== 'loading') {
            setStatus('loading')
            try {
                await api.accounts.updateLeadEmail(email)
                setStatus('success')
            } catch (e) {
                console.error(e)
                setStatus('error')
            }
        }
    })

    return (
        <Dialog maxWidth="sm" fullWidth open={true} onClose={onClose}>
            <DialogTitle>{t(titleByVariant[variant])}</DialogTitle>
            <DialogForm
                onSubmit={onSubmit}
                actions={
                    status === 'success' ? null : (
                        <>
                            <DialogSecondaryButton onClick={onClose}>{t('general::cancel')}</DialogSecondaryButton>
                            <DialogPrimaryButton isLoading={status === 'loading'} type="submit">
                                {t('general::submit')}
                            </DialogPrimaryButton>
                        </>
                    )
                }
            >
                <Stack>
                    {status === 'success' ? (
                        <SuccessMessage>{t('openDayDialog::thankYou')}</SuccessMessage>
                    ) : (
                        <>
                            <Illustration width="200" height="160" src={illustrationImg} alt="" />
                            <Text>{t(descriptionByVariant[variant])}</Text>
                            <TextField
                                ref={register()}
                                autoFocus
                                label={t('general::parentemail')}
                                name="email"
                                type="email"
                                inputProps={{ maxLength: 100, required: true }}
                            />
                        </>
                    )}
                    {status === 'error' && <ErrorMessage />}
                </Stack>
            </DialogForm>
        </Dialog>
    )
}

const Illustration = styled.img`
    display: block;
    margin-left: auto;
    margin-right: auto;
`

const titleByVariant: Record<AskForOpenDayButtonVariant, string> = {
    classroom: 'openDayDialog::classroom::title',
    general: 'openDayDialog::general::title',
    coding: 'openDayDialog::generalAndCoding::title',
}

const descriptionByVariant: Record<AskForOpenDayButtonVariant, string> = {
    classroom: 'openDayDialog::classroom::description',
    general: 'openDayDialog::generalAndCoding::description',
    coding: 'openDayDialog::generalAndCoding::description',
}

import React from 'react'
import { CardContent as MuiCardContent, CardContentProps as MuiCardContentProps } from '@material-ui/core'
import styled from 'styled-components/macro'

interface CardContentProps extends MuiCardContentProps {}

export const CardContent: React.FC<CardContentProps> = props => {
    return <Root {...props} />
}

const Root = styled(MuiCardContent)`
    &,
    &:last-child {
        padding: ${props => props.theme.spacing(4)};
    }
`

import styled, { css } from 'styled-components/macro'
import { createTheme } from '../../createTheme'
import React from 'react'
import footerImg from './scooler-romanian-footer.png'

const FooterImage = styled.img`
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: -30%;
    pointer-events: none;
    z-index: -1;
`

export const scoolerRomanian = createTheme(
    { base: '#003e63', aside: '#053a57', primary: '#009CF9', asideContent: '#9f9faf', baseContent: '#145a83' },
    {
        palette: {
            type: 'dark',
            background: {
                default: '#003e63',
                paper: '#24243a',
            },
            divider: '#155c85',
            text: {
                primary: '#d9d9f0',
            },
            primary: { main: '#009CF9', contrastText: '#c0eaff' },
        },
        extras: {
            logoType: 'white',
            themeColorMeta: '#003e63',
            page: {
                footerImage: <FooterImage width="3581" height="2000" src={footerImg} />,
            },
            pageHeader: {
                root: css`
                    background-color: #053a57;
                    border-bottom-color: #1f5576;
                `,
                stickyRoot: css`
                    background-color: #053a57;
                    border-bottom-color: #1f5576;
                `,
            },
            pageAside: {
                paper: css`
                    background-color: #053a57;
                    border-right-color: #1f5576;
                `,
            },
            containedButton: color => {
                if (color === 'default') {
                    return css`
                        &,
                        &:hover,
                        &:focus-visible {
                            background-color: #2a2d5d;
                            color: #8387be;
                        }
                    `
                }
            },
            dialog: {
                backdrop: css`
                    background: rgb(34 44 52 / 87%);
                `,
                projectDialogPaper: css`
                    background: #06090f;
                `,
            },
            actionablePaper: {
                root: css`
                    &:hover,
                    &:focus-visible {
                        background-color: ${props => props.theme.palette.background.paper};
                        filter: brightness(1.2);
                    }
                `,
            },
        },
    },
    ['content.scooler-mission-2022-themes-ro'],
)

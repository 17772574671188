import React from 'react'
import styled from 'styled-components/macro'
import { ScoolcodeButton } from '../details/ProjectDetailsActions'
import { Project } from '../store'
import { MyProjectCardActionsMenu } from './MyProjectCardActionsMenu'

interface MyProjectStripeActionsProps {
    project: Project
    isPending?: boolean
    favorite?: React.ReactNode
}

export const MyProjectStripeActions: React.FC<MyProjectStripeActionsProps> = ({ project, isPending, favorite }) => {
    return (
        <>
            {favorite}
            <HideOnMobile>
                <ScoolcodeButton variant="text" project={project} />
            </HideOnMobile>
            <MyProjectCardActionsMenu location="stripe" id={project.file.id} isPending={isPending} />
        </>
    )
}

const HideOnMobile = styled.div`
    ${props => props.theme.breakpoints.down('xs')} {
        display: none;
    }
`

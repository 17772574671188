import { Stack, WrappingInline } from 'components/layout'
import React from 'react'
import styled from 'styled-components/macro'
import { Inline } from 'components/layout'
import { ApiTypes } from 'lib/api'
import { Avatar } from 'features/avatar'
import { createAvatarUrl } from 'features/avatar'
import { Skeleton } from 'components/Skeleton'
import { Text } from 'components/Text'
import { LocationIcon } from 'components/icons'
import { getUserPrivateName, getUserPublicName, useHasShowAdvancedStats } from 'contexts/UserContext'
import { UserBadge } from './UserBadge'
import { EditableAvatar } from './EditableAvatar'

type ProfileCardProps = {
    user: ApiTypes['account']
    defaultSchool?: string
    follow: React.ReactNode
    isOwn: boolean
}

export const ProfileCard: React.FC<ProfileCardProps> = ({ user, children, follow, isOwn, defaultSchool }) => {
    const name = isOwn ? getUserPrivateName(user) : getUserPublicName(user)
    const hasShowAdvancedStats = useHasShowAdvancedStats()

    return (
        <Root>
            <Inner spacing={6}>
                <div>
                    {isOwn ? (
                        <EditableAvatar size="large" />
                    ) : (
                        <Avatar size="large" src={createAvatarUrl(user.profileImage)} />
                    )}
                </div>
                <Stack>
                    <div>
                        <Username variant="h3" component="h2">
                            <WrappingInline spacing={1} alignItems="center">
                                <Inline spacing={1}>
                                    <span>{name}</span> {<UserBadge user={user} />}
                                </Inline>
                                {!isOwn && hasShowAdvancedStats && (
                                    <Username component="span" color="textSecondary">
                                        ({user.username})
                                    </Username>
                                )}
                            </WrappingInline>
                        </Username>

                        {!!defaultSchool && (
                            <Text variant="caption">
                                <StyledLocationIcon /> {defaultSchool}
                            </Text>
                        )}
                    </div>
                    <Description>{isOwn ? user.descriptionPending || user.description : user.description}</Description>
                    {follow}
                </Stack>
            </Inner>
            {children}
        </Root>
    )
}

export const ProfileCardSkeleton: React.FC = () => {
    return (
        <Root>
            <Inner spacing={6}>
                <Skeleton variant="rect" width="8rem" height="8rem"></Skeleton>
                <Stack flexGrow="1">
                    <Skeleton height="2rem" width="8rem"></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                </Stack>
            </Inner>
        </Root>
    )
}

const Inner = styled(Inline)`
    display: flex;
    align-items: flex-start;
`

const Username = styled(Text)`
    line-height: 1.2;
    word-break: break-word;
` as typeof Text

const Description = styled(Text)`
    word-break: break-word;
    white-space: pre-line;
`

const Root = styled.div`
    width: 100%;
    max-width: 40rem;
`

const StyledLocationIcon = styled(LocationIcon)`
    vertical-align: middle;
    color: ${props => props.theme.palette.text.hint};
`

import { Button } from 'components/Button'
import { Stack } from 'components/layout'
import { useUserState, canUserCreateProject } from 'contexts/UserContext'
import { useNotifications } from 'features/notifications'
import { ProjectModalDescription } from 'features/projects'
import { useProjectModalContext } from 'features/projects/modal/projectModalContext'
import { useProcessSecretCodeRedeemResponse } from 'features/secret-code'
import { api } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import React, { useState } from 'react'
import { isValidXmasProjectId, getXmasProjectSecretCode } from '../data/xmasData'

export const XmasProjectDetails: React.FC = () => {
    const processSecretCodeRedeemResponse = useProcessSecretCodeRedeemResponse()
    const t = useTranslate()
    const user = useUserState()
    const { id } = useProjectModalContext()
    const [isLoading, setIsLoading] = useState(false)
    const { notify } = useNotifications()

    if (!isValidXmasProjectId(id)) {
        return null
    }

    const unlockProject = async () => {
        if (!isLoading) {
            try {
                setIsLoading(true)
                const { data } = await api.secretCodes.redeem(getXmasProjectSecretCode(user))
                if (data.success) {
                    processSecretCodeRedeemResponse(data, () => {})
                } else {
                    throw new Error()
                }
            } catch (e) {
                notify(t('error::somethingWentWrong'), { variant: 'error' })
                console.error(e)
            } finally {
                setIsLoading(false)
            }
        }
    }

    if (!canUserCreateProject(user)) {
        return <ProjectModalDescription>{t('XmasGiftBanner::QuizProject::description')}</ProjectModalDescription>
    }

    return (
        <Stack>
            <ProjectModalDescription>{t('XmasGiftBanner::QuizProject::description')}</ProjectModalDescription>
            <ProjectModalDescription>{t('XmasGiftBanner::QuizProject::description02')}</ProjectModalDescription>
            <Button isLoading={isLoading} onClick={unlockProject}>
                {t('Xmas2021::PersonalizeQuizProject::button')}
            </Button>
        </Stack>
    )
}

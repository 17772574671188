import { ThemeOptions } from '@material-ui/core'
import { Grant, GrantFeature } from 'lib/grants'

const spacing = {
    0: 0,
    1: 0.25,
    2: 0.5,
    3: 0.75,
    4: 1,
    5: 1.25,
    6: 1.5,
    7: 1.75,
    8: 2,
    9: 2.5,
    10: 3,
    11: 4,
    12: 5,
}

const themeBase: ThemeOptions = {
    spacing: factor => `${spacing[factor as keyof typeof spacing]}rem`,
    typography: {
        fontFamily: 'Poppins, sans-serif',
        h1: { fontSize: '2.125rem', fontWeight: 800, lineHeight: 1.235 },
        h2: { fontSize: '1.5rem', fontWeight: 600, lineHeight: 1.334 },
        h3: { fontSize: '1.25rem', fontWeight: 800, lineHeight: 1.6 },
        h4: { fontSize: '1rem', fontWeight: 600, lineHeight: 1.3 },
        h5: { fontWeight: 600 },
        h6: { fontWeight: 800 },
        body1: { lineHeight: 1.7 },
    },
    shape: {
        borderRadius: 16,
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
}

export type ThemeThumbnail =
    | {
          base: string
          primary: string
          aside: string
          asideContent: string
          baseContent?: string
      }
    | React.FC

// TODO: do a deep merge if neccesary
export function createTheme(
    thumbnail: ThemeThumbnail,
    options: Omit<ThemeOptions, 'spacing' | 'typography' | 'shape' | 'props'>,
    grantRequiredToDisplay?: (Grant | GrantFeature)[],
) {
    return {
        thumbnail,
        data: {
            ...themeBase,
            ...options,
        },
        grantRequiredToDisplay,
    }
}

import { Reducer } from 'redux'
import { PRESENT } from 'redux-socket-client'

export interface SessionState {
    id: string | null
}

export const sessionReducer: Reducer<SessionState> = (state = { id: null }, { type, payload }) => {
    switch (type) {
        case PRESENT:
            return { ...state, id: payload.state.sessionId }

        default:
            return state
    }
}

import styled from 'styled-components/macro'

type Size = 'small' | 'big'
const columns: Record<Size, number> = {
    small: 3,
    big: 2,
}
const mobileColumns: Record<Size, number> = {
    small: 2,
    big: 1,
}

export const EventGrid = styled.div<{ size: Size }>`
    display: grid;
    grid-template-columns: repeat(${props => columns[props.size]}, 1fr);
    grid-gap: ${props => props.theme.spacing(8)};
    ${props => props.theme.breakpoints.down('sm')} {
        grid-gap: ${props => props.theme.spacing(4)};
    }
    @media (max-width: 400px) {
        grid-template-columns: repeat(${props => mobileColumns[props.size]}, 1fr);
    }
`

import { useUserState } from 'contexts/UserContext'
import React from 'react'
import { Avatar, AvatarProps } from './Avatar'
import { createAvatarUrl } from './avatarHelpers'

export const CurrentUserAvatar: React.FC<Omit<AvatarProps, 'src'>> = props => {
    const user = useUserState()

    return <Avatar src={createAvatarUrl(user.account.profileImage)} {...props} />
}

import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/Button'
import { useTranslate } from 'lib/i18n/useTranslate'
import { Stack } from 'components/layout'
import { useSelector } from 'lib/store'
import { getHighScoreRoom } from 'lib/liveops'
import { Text } from 'components/Text'
import { useHistory } from 'react-router'
import { Card, CardContent } from 'components/card'
import { HighScoreRoomCountdown } from './HighScoreRoomCountdown'
import styled from 'styled-components/macro'

export const HighScoreRoomPanel: React.FC<{ room: string }> = ({ room }) => {
    const t = useTranslate()
    const highScoreRoom = useSelector(state => getHighScoreRoom(state, room))
    const history = useHistory()

    const onEnd = useCallback(() => {
        history.replace(`/app/high-score-room-results/${highScoreRoom?.target}/${highScoreRoom?.id}`)
    }, [history, highScoreRoom])

    if (!highScoreRoom) {
        return null
    }
    const { target, name, expireAt, secretCode } = highScoreRoom

    return (
        <Card>
            <CardContent>
                <Stack>
                    <RoomName variant="h4">{name}</RoomName>
                    <div>
                        <Text>{t('secretCode::label')}</Text>
                        <Text>
                            <strong>{secretCode}</strong>
                        </Text>
                    </div>
                    <HighScoreRoomCountdown date={expireAt} onEnd={onEnd} />
                    <Button component={Link} replace to={`/app/high-score-room-results/${target}/${room}`}>
                        {t('highScoreRoom::openLeaderboard')}
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}

const RoomName = styled(Text)`
    word-break: break-word;
    font-weight: 700;
`

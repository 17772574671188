import React from 'react'
import { useProjectModalContext } from '../modal/projectModalContext'
import styled from 'styled-components/macro'
import { Avatar, createAvatarUrl } from 'features/avatar'
import { Inline } from 'components/layout'
import { Link } from 'react-router-dom'
import { getUserPrivateName, getUserPublicName, useUserState } from 'contexts/UserContext'
import { UserBadge } from 'features/profile'

export const ProjectDetailsUser: React.FC = () => {
    const { project, isOwnProject } = useProjectModalContext()
    const { account } = useUserState()

    if (!project) {
        return null
    }

    const displayName = (() => {
        if (isOwnProject) {
            return getUserPrivateName(account)
        }
        if (project.metadata.owner) {
            return getUserPublicName(project.metadata.owner)
        }
        return null
    })()

    return (
        <ProjectDetailsUserAvatarWrap component={Link} to={`/app/user/${project.metadata.owner?.username}`}>
            <StyledAvatar size="small" src={createAvatarUrl(project.metadata.owner?.profileImage)} />
            <Name>
                {displayName} {project.metadata.owner && <StyledUserBadge user={project.metadata.owner} />}
            </Name>
        </ProjectDetailsUserAvatarWrap>
    )
}

export const StyledUserBadge = styled(UserBadge)`
    font-size: ${props => props.theme.typography.body1.fontSize};
    margin-inline-start: ${props => props.theme.spacing(1)};
`

export const StyledAvatar = styled(Avatar)`
    flex-shrink: 0;
`

export const Name = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`

export const ProjectDetailsUserAvatarWrap = styled(Inline)`
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    font-weight: 600;
    &:hover {
        text-decoration: underline;
    }
` as typeof Inline & Link

import { useUserState } from 'contexts/UserContext'
import { api } from 'lib/api'
import * as React from 'react'
import { Project } from './store'

const TIMEOUT = 3000

export const useIncrementProjectViewCount = (project?: Project) => {
    const { account } = useUserState()
    const { id: userId } = account
    const [isUpdated, setIsUpdated] = React.useState<boolean>(false)

    React.useEffect(() => {
        let ignore = false
        if (project?.file.id && project.file.owner !== undefined && project.file.owner !== userId) {
            setTimeout(() => {
                if (!ignore) {
                    api.projects.incrementProjectViewCount(project.file.id)
                    setIsUpdated(true)
                }
            }, TIMEOUT)
        }
        return () => {
            ignore = true
        }
    }, [userId, project?.file.id, project?.file.owner, project?.file.shared])

    return isUpdated
}

import React from 'react'
import { StylesProvider } from '@material-ui/core'
import { ThemeProvider } from 'lib/theme'
import { UserProvider } from 'contexts/UserContext'
import { SnackbarProvider } from 'notistack'
import { StoreProvider } from 'lib/store'
import { QueryCache, ReactQueryCacheProvider } from 'react-query'
import { CookieHuntProvider } from 'features/cookie-hunt'
import { FloatingTextProvider } from 'lib/floating-text'

const queryCache = new QueryCache({ defaultConfig: { queries: { refetchOnWindowFocus: false } } })

export const Providers: React.FC = ({ children }) => {
    return (
        <StylesProvider>
            <ReactQueryCacheProvider queryCache={queryCache}>
                <ThemeProvider>
                    <StoreProvider>
                        <SnackbarProvider>
                            <UserProvider>
                                <FloatingTextProvider>
                                    <CookieHuntProvider>{children}</CookieHuntProvider>
                                </FloatingTextProvider>
                            </UserProvider>
                        </SnackbarProvider>
                    </StoreProvider>
                </ThemeProvider>
            </ReactQueryCacheProvider>
        </StylesProvider>
    )
}

export const demoGamePaths: { name: string; id: string }[] = [
    {
        name: 'robot',
        id: '652515c759e2537a9fb190a2',
    },
    {
        name: 'pizza',
        id: '652d064a9cfd15340780ae0d',
    },
    {
        name: 'star',
        id: '6525485259e253623eb3038d',
    },
    {
        name: 'logirobi',
        id: '65254fee59e2532bcfb3a6fd',
    },
]

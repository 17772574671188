import { Card } from 'components/card'
import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components/macro'
import { actionablePaperStyles } from 'utils/styleUtils'

interface BaseActionCardProps {
    className?: string
}

interface LinkVariantProps extends LinkProps, BaseActionCardProps {
    type?: 'link'
}

interface ButtonVariantProps extends React.ButtonHTMLAttributes<any>, BaseActionCardProps {
    type?: 'button'
}

interface ExternalLinkVariantProps extends React.AnchorHTMLAttributes<any>, BaseActionCardProps {
    type?: 'external-link'
    to: string
}

type ActionCardProps = LinkVariantProps | ButtonVariantProps | ExternalLinkVariantProps

export const ActionCard: React.FC<ActionCardProps> = props => {
    const type = props.type ?? 'button'

    if (type === 'link') {
        const { type: _, ...rest } = props as LinkVariantProps
        // @ts-ignore
        return <Root $hasAction={rest.href !== '#'} component={Link} {...rest} />
    }
    if (type === 'external-link') {
        const { to, ...rest } = props as ExternalLinkVariantProps
        // @ts-ignore
        return <Root $hasAction={to !== '#'} component="a" href={to} target="_blank" {...rest} />
    } else {
        const { type: _, ...rest } = props as ButtonVariantProps
        return (
            <Root $hasAction={rest.onClick !== undefined} component="button" {...rest}>
                {props.children}
            </Root>
        )
    }
}

const Root = styled(Card)<{ $hasAction?: boolean }>`
    ${actionablePaperStyles}

    display: block;
    width: 100%;
    text-align: left;
    padding: 0;
    text-decoration: none;
    cursor: ${props => (props.$hasAction ? 'pointer' : undefined)};
`
